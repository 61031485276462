
import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';

import * as CurrencyFormat from 'react-currency-format';

class AccordionAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invItems:[]
         }
         this._excludeItem=this._excludeItem.bind(this);
    }
    _excludeItem(index) { 
        let invItems = this.state.invItems;
        invItems.splice(index,1);
         this.setState({invItems:invItems});
      }


_Submit(){
    let invItems = this.state.invItems;
    let access_url = this.props.api_url+'/insert/com_orderform_acces_lines';
    let d = new Date();  
    let date = d.toISOString();
    let c_date  = date.split('T')[0];
    let c_time = d.toLocaleTimeString('tr-TR');

    try{ 
    invItems.map((row,index)=>{
       let insert_line=  
            'text='+row.text+
            '&id_com_orderform='+this.props.id_com_orderform+
            '&id_com_stock='+row.id_com_stock+
            '&price='+row.price+
            '&c_date='+ c_date+
            '&c_time='+ c_time+
            '&token='+this.props.loginToken+
            '&type=user';

       console.log(insert_line);
            fetch(access_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
            body: insert_line,
        }).catch(e=>console.log(e)).then(res=>window.location.reload())
    })
} 
catch(error){console.log(error)}

}


    render() { 
        return ( 
    <React.Fragment>
        <a className={!this.state.clicked?"btn btn-primary btn-lg btn-block":"btn btn-success btn-lg btn-block"}
            data-toggle="collapse" 
            href={`#${this.props.id}`}
            role="button" 
            aria-expanded="false" 
            aria-controls={this.props.id} 
            // style={{width: '100%'}}
            onClick={()=>this.setState({clicked:!this.state.clicked})}>
 <i className={!this.state.clicked?"fas fa-plus-circle pull-left":"fas fa-minus-circle pull-left"}  > </i>   {this.props.title} :  <CurrencyFormat value={Math.round(parseFloat(this.props.summary)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/> </a>
    <div className="collapse" id={this.props.id}>
        <div className="panel">
        <div className="  panel-heading">
        <div className="row">            
        <div className="col-md-6 text-left">
                <div className="panel-title">{this.props.title}</div>
            </div>
            <div className="col-md-6 text-right">
                <a type="button" className="btn btn-secondary" onClick={()=> { 
                    let array = this.state.invItems;
                    array.push([]);this.setState({invItems:array})}}><i className="fas fa-plus-circle pull-left"  > </i>  Ekle</a>
            </div>
        </div>

        </div>
        <div className="  panel-body">
                <div className="row"> 
      {this.state.invItems.length>0?
       <table className="table table-bordered table-striped">
            <thead>
                <tr>
                    <th>Araç</th>
                    <th>Aksesuar Adı</th>
                    <th width='150'>Fiyatı</th>
                    <th>Kontrol</th>

                </tr>
            </thead>
            <tbody>
            {this.state.invItems.map((row,index)=>{   
            return  (
                    <tr key={index}>
                    <td>
                    <select className="form-control"   onChange={(x)=>this.state.invItems[index].id_com_stock=x.target.value} value={this.state.invItems[index].id_com_stock} data-live-search="true">
                            <option key={0} value={0}>{'Seçiniz'}</option>
                        {this.props.cars_data.map((row,index)=>
                            <option key={index+1} value={row.id_com_stock}>{row.chasis+' / '+row.id_com_brand_name+' - '+row.id_com_model_name+' '+row.spec}</option>
                        )}
                    </select>
                    </td>
                    <td><input type="text" className="form-control"  value={this.state.invItems[index].text} onChange={(x)=>this.state.invItems[index].text =x.target.value} required/></td>
                    <td>
                    <input type="text" className="form-control"  value={this.state.invItems[index].price} onChange={(x)=>this.state.invItems[index].price =x.target.value} required/>
                    </td>
                      <td><a disabled={row.status==='1'?true:false} role='button' className='btn btn-danger btn-xs' onClick={()=>this._excludeItem(index)}>Cikar</a></td>
                </tr>
                )})}
            </tbody>
            </table>  
            :<></>}
                </div>
            
                <div className="  panel-footer">
        <button className="btn btn-primary pull-right" 
                                // disabled={this.state.id_com_sale_type===''||this.state.id_com_customer==='0'}
                                  onClick={()=>this._Submit()}
                                // onClick={()=>console.log(this.state)}
                                    >Kaydet</button>
        </div>
        </div>
        

        </div>
    </div>       
    </React.Fragment>
)
}
}

export default connect(mapStateToProps)(AccordionAccess);