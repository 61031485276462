import React,{useState,useEffect} from 'react';
import {  Col, Row, Divider, message,Form,Collapse ,Button,Transfer,Input,Drawer, Spin} from 'antd';
import {connect} from 'react-redux';
import mapStateToProps from '../../../redux/mapStateToProps';
import SelectList from '../../../components/utilities/SelectList';
import 'moment/locale/tr'; 
import moment from 'moment';
 
const  formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 2
  })
   
 
 const {Panel} = Collapse;

const CostGroupEdit = ({api_url,loginToken,visible,OnClose,id_com_user,data})=>{
    const [formEdit] = Form.useForm();
    const [MuhPlans,setMuhPlans] = useState([])
    const [Line,SetLine]= useState([])
    const [TargetKeys,SetTargetKeys]= useState([])
    const [InitalKeys,SetInitalKeys]= useState([])
    const [Loading,setLoading] =useState(true);


    const ExtractJson = ()=>{
        let result =''
    
           TargetKeys.map((row,index)=>{
    
                   if(index===0){
                       result +=row;
                   } else {
                       result += `,${row}`;
                   }
                   })
                return result;
    
         
    }


    const _Update = async ()=>{
        const form_data = formEdit.getFieldsValue();
    
        let insert_url=api_url+'/update/com_cost_plan';
        let d = new Date(); 
        let c_date  = moment(d).format('YYYY-MM-DD');
        let c_time = d.toLocaleTimeString('tr-TR');
        const codes = ExtractJson();

        let body ='token='+loginToken+'&type=user&m_id_com_user='+id_com_user+'&m_date='+c_date+'&m_time='+c_time+'&cost_codes='+codes+'&id='+data.id
    
        for (let [key,value] of Object.entries(form_data)) { 
            if(key!=='btn'&&key!=='groups'){
                switch(key) {
                    default : 
                     body += `&${key}=${value}`;
                    break;
                }
    
            }
        
        }

         
        const config = {method:'POST',cahce:'no-cache',headers:{'Content-Type':'application/x-www-form-urlencoded'},body:body}
        const result = await (await fetch(insert_url,config)).json()
         
       if(result) {
            message.loading('Kayıt Ediliyor',0.3).then(()=>message.success('Kayıt Edildi',1)).then(e=>window.location.reload())
       } else {
            message.loading('Kayıt Ediliyor',0.3).then(()=>message.error('Hata Oluştu',1))
       }
     
    }


    useEffect(() => {
        const OnLoad =()=>{
            _GetMuhPlan();
            _SetFormData()
         }       
        return OnLoad();
    }, [visible])


    const HandleChange = (targetKeys) => { 
        SetTargetKeys(targetKeys);
      }



      const _SetFormData = () =>{
        const inital_values = {}
        const targetkeys=[]

        for (let [key,value] of Object.entries(data)) {  
            if(key==='id_com_company'||key==='text'||key==='cost_codes'){
                switch(key) {
                    case 'cost_codes':
                        value.split(',').map(lines=>targetkeys.push(lines));
                    default : 
                    Object.assign(inital_values,{[`${key}`]:value}) 
                    break;
                }
    
            }
        }
        SetTargetKeys(targetkeys);

         formEdit.setFieldsValue(inital_values);
           
      }

    const _GetMuhPlan =  async () =>{
 
        let url= api_url+'/logoselectrows/ZZZ_ST_120_EMUHACC_700';
        let body = '&token='+loginToken+'&type=user';
        let config = {  method:'POST',    cahce: 'no-cache',    headers:{     'Content-Type':'application/x-www-form-urlencoded'     },   body:body}
        let response = await (await fetch(url,config)).json() 
        
 
        setMuhPlans(response)
        const MockData = []

         response.map((row)=>MockData.push(
             {
                 key: row.CODE,
                 title: ` ${row.CODE} - ${row.DEFINITION_}`,
                 chosen: 0,
               }
        ))
         
         SetLine(MockData) 
         setLoading(false);
       }

       const _Delete  = async () =>  {
        let insert_url=api_url+'/update/com_cost_plan';
        let d = new Date(); 
        let c_date  = moment(d).format('YYYY-MM-DD');
        let c_time = d.toLocaleTimeString('tr-TR');
        const codes = ExtractJson();

        let body ='token='+loginToken+'&type=user&hidden=1&m_id_com_user='+id_com_user+'&m_date='+c_date+'&m_time='+c_time+'&id='+data.id;

        const config = {method:'POST',cahce:'no-cache',headers:{'Content-Type':'application/x-www-form-urlencoded'},body:body}
        const result = await (await fetch(insert_url,config)).json()
         
    if(result) {
            message.loading('Kayıt Siliniyor',0.3).then(()=>message.success('Kayıt Silindi',1)).then(e=>window.location.reload())
       } else {
            message.loading('Kayıt Siliniyor',0.3).then(()=>message.error('Hata Oluştu',1))
       }

       }
    
    

return ( 
    <Drawer
        title='Hesap Grubu Düzenle'
        visible={visible}
        onClose={OnClose}
        width='70%'
        style={{marginTop:30}}
         >
            <Form layout='vertical' form={formEdit} onFinish={_Update}>
                <Form.Item name='id_com_company' label='Firma Adı'>
                    <SelectList table='view_company_sl'/>
                </Form.Item>
                <Form.Item name='text' label='Gider Grup Adı'>
                    <Input type='text'  />
                </Form.Item>
                
                <Form.Item    label='Hesap Kodları'>
                <Divider>Sınıflama</Divider>
                    {Loading?
                        <Spin >Veri Yukleniyor. Bekleyiniz</Spin>
                    : 
                  
                <Transfer
                    locale={{itemUnit:'Adet',itemsUnit:'Adet',searchPlaceholder:'Sayfa Ara',selectCurrent:'Sayfayı Seç',selectAll:'Tümünü Seç',selectInvert:'Tersini Seç'}}
                    dataSource={Line}
                    showSearch
                    listStyle={{
                        width: '100%',
                        height: '500px',
                        justifyContent:'space-between'
                        }}
                    pagination={true}
                    operations={['Gruba Ekle ','Gruptan Çıkar ']}
                    targetKeys={TargetKeys}
                    onChange={HandleChange}
                    render={item => `${item.title}`}
                    pagination={{pageSize:20}}
                    />

                    }
                </Form.Item>

                <Divider></Divider>
                <Form.Item name='btn'  >
                    <Row align='middle' justify='end' gutter={12}>
                        <Col>
                            <Button danger onClick={OnClose} htmlType='reset' >Vazgeç</Button>
                        </Col> 
                        <Col>
                            <Button type='primary' danger onClick={_Delete} htmlType='button' >Sil</Button>
                        </Col> 
                        <Col>
                            <Button type='primary' htmlType='submit' >Kaydet</Button>
                        </Col> 
                    </Row>

                </Form.Item>

            </Form>
        </Drawer>
)
}

export default connect(mapStateToProps)(CostGroupEdit);