import React,{useState,useEffect} from 'react';
import {  Col, Row, Spin,Typography,Button,Divider,Empty,Menu,Dropdown ,Modal,Tabs,Table,PageHeader ,Input,DatePicker,Select} from 'antd'; 
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import 'moment/locale/tr';
import moment from 'moment'
import Chart from "react-apexcharts";
import ExportFromJson from '../utilities/jsontoexport';

import DataTable from 'react-data-table-component'; 

const { Title ,Text} = Typography;
const { TabPane} = Tabs;
const { Option } = Select;


const  ReportCrmT3S2 =({api_url,loginToken})=> {
    const [Data,SetData] = useState([]);
    const [SearchItem,SetSearchItem] = useState('');
    const [Users,setUsers] = useState([]);
    const [SelectedUser,setSelectedUser] = useState('Tümü')

    const [Month,setMonth] = useState([1,2,3,4,5,6,7,8,9,10,11,12]);
    const [SelectedMonth,setSelectedMonth] = useState('')
    const [Year,setYear] = useState([2020,2021,2022,2023,2024,2025]);
    const [SelectedYear,setSelectedYear] = useState('')
    const [Status,setStatus] = useState([]);
    const [Meet,setMeet] = useState([]);
    const [Subject,setSubject] = useState([]);
    const [MonthlyData,setMonthlyData] =useState([]);
    const [MeetSummary,setMeetSummary] = useState([]);
    const [StatusSummary,setStatusSummary] = useState([]);
    const [UserSummary,setUserSummary] = useState([]);
    const [SubjectSummary,setSubjectSummary] = useState([]);



    
    const Columns = [
  
        {
            title:'No',
            dataIndex:'interview_number',
            align:'left',
        },
        {
            title:'Durum',
            dataIndex:'status_name',
            align:'left',
        },

        {
            title:'Konu',
            dataIndex:'id_com_interview_subject_name',
            align:'center',
         },
        {
            title:'Tarih',
             align:'center',
            render:(row,record,index)=>moment(record.c_date).format('DD.MM.YYYY')
        },
        {
            title:'Temas',
            dataIndex:'id_com_meet_name',
            align:'center',
         },
         {
            title:'Temsilci',
            dataIndex:'id_com_user_name',
            align:'center',
         },
         {
            title:'Yönlendirildi',
             align:'center',
            render:(row,record,index)=> record.forwarded==='1'?'Evet':'Hayır'
         },
         {
            title:'Müşteri',
            dataIndex:'username',
            align:'left',
         },
         {
            title:'Sonuç',
            dataIndex:'id_com_interview_status_name',
            align:'center',
         },
         {
            title:'Detay',
             align:'center',
            render:(row,record,index)=> <Button type='primary' shape='round' size='small' target='_blank' href={'/interview/Detail/'+record.id}>Git</Button>

         },
    ]

    const columns =[
          
        {
            name:'No',
            selector:'interview_number',
            align:'left',
        },
        {
            name:'Durum',
            selector:'status_name',
            align:'left',
        },

        {
            name:'Konu',
            selector:'id_com_interview_subject_name',
            align:'center',
         },
        {
            name:'Tarih',
            selector:'c_date',
             align:'center',
         },
        {
            name:'Temas',
            selector:'id_com_meet_name',
            align:'center',
         },
         {
            name:'Temsilci',
            selector:'id_com_user_name',
            align:'center',
         },
         {
            name:'Yönlendirildi',
            selector:'forwarded',
             align:'center',
          },
         {
            name:'Müşteri',
            selector:'username',
            align:'left',
         },
         {
            name:'Sonuç',
            selector:'id_com_interview_status_name',
            align:'center',
         },
          
    ]
 

    const  bar_options = {
        chart: {
             type: 'bar',
        },
        plotOptions: {
          bar: {
            columnWidth:'50%',
            endingShape: 'rounded',  
            dataLabels: {
              position: 'top', // top, center, bottom
            },  
            stroke: {
              width: 2
            },
          }
        },
        xaxis: {
          tickPlacement: 'on',
            categories: [
              'OCA', 'ŞUB', 'MAR', 'NIS', 'MAY', 'HAZ', 'TEM', 'AGU', 
                'EYL', 'EKI', 'KAS','ARA'],
            position: 'top',
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                }
              }
            },

        },
 
          dataLabels: { 
            enabled: false,
          }, 
         
        }


const meetColumns = [
    {
        title:'',
        dataIndex:'name',
        align:'left',
     },
    {
        title:'Adet',
        dataIndex:'count_',
        align:'center',
    },
]
 
const SubjectColumn = [
    {
        title:'',
        dataIndex:'name',
        align:'left',
    },
    {
        title:'Yenilenecek',
        dataIndex:'count_',
        align:'center',
    },
    {
        title:'Yenilenen',
        dataIndex:'ins_renew',
        align:'center',
    },
    {
        title:'Yenileme Oranı',
         align:'center',
         render: (row,record,index)=> `% ${(Math.round(parseInt(record.ins_renew)/parseInt(record.count_)*10000)/100)}` 
    }
]



    useEffect(() => {
        const FetchData = ()=>{

            const year = moment(new Date()).format('YYYY');
            const month =  new Date().getMonth()+1
            _GetData(year,'Tümü',month)
        }
       return FetchData();
   }, [])

   const _Sort = (a, b) => {
       var nameA = a.c_date.toUpperCase();  
       var nameB = b.c_date.toUpperCase();  
       if (nameA < nameB) {
         return -1;
       }
       if (nameA > nameB) {
         return 1;
       }
        return 0;
     }

     const _GetData =  async (years,user,month) =>{
        setSelectedYear(years);
        setSelectedMonth(month);
        setSelectedUser(user);

        let url= api_url+'/selectrows/view_interview_type3_s7';
        let body = 'token='+loginToken+'&type=user';
 
        let config = {  method:'POST',    cahce: 'no-cache',    headers:{     'Content-Type':'application/x-www-form-urlencoded'     },   body: body }
        let result = await (await fetch(url,config)).json(); 

 
        let data = user==='Tümü'?result.filter(row=>parseInt(row.month_)===parseInt(month)&&parseInt(row.year_)===parseInt(years)):result.filter(row=>parseInt(row.month_)===parseInt(month)&&parseInt(row.year_)===parseInt(years)&&row.id_com_user_name===user)
        
         SetData(result)

        let users = ['Tümü'];
        let status = [];
        let meet  = []
        let subject = []
         data.map(line=>{
            users.push(line.id_com_user_name);
            status.push(line.id_com_interview_status_name)
            meet.push(line.id_com_meet_name)
            subject.push(line.id_com_interview_subject_name)
        })            

        let meets =[...new Set(meet)]
        let statuss= [...new Set(status)]
        let subjects= [...new Set(subject)]

        let userss  = [...new Set(users)]
        setUsers(userss)
        setStatus(statuss)
        setMeet(meets)
        setSubject(subjects)

        let meetsummary  = []

        meets.map(meet=>{
           let filtered = data.filter(row=>row.id_com_meet_name===meet);

            meetsummary.push({
                name:meet,
                count_ : filtered.length
            })
        })
        meetsummary.push({
            name:'Toplam',
            count_:meetsummary.reduce((a,b)=>a+parseInt(b.count_),0)
        })
        setMeetSummary(meetsummary)

        let statussummary  = []

        statuss.map(status=>{
           let filtered = data.filter(row=>row.id_com_interview_status_name===status);

           statussummary.push({
                name:status,
                count_ : filtered.length
            })
        })
        statussummary.push({
            name:'Toplam',
            count_:statussummary.reduce((a,b)=>a+parseInt(b.count_),0)
        })
        setStatusSummary(statussummary)


        let usersummary  = []

        userss.filter(line=>line!=='Tümü').map(user=>{
           let filtered = data.filter(row=>row.id_com_user_name===user);

           usersummary.push({
                name:user,
                count_ : filtered.length
            })
        })
        usersummary.push({
            name:'Toplam',
            count_:usersummary.reduce((a,b)=>a+parseInt(b.count_),0)
        })

        
        setUserSummary(usersummary);
        let subjectsummary  = []

        subjects.map(subject=>{
           let filtered = data.filter(row=>row.id_com_interview_subject_name===subject);

           subjectsummary.push({
                name:subject,
                count_ : filtered.length,
                ins_renew: filtered.reduce((a,b)=>a+parseInt(b.ins_renew),0)
            })
        })
        subjectsummary.push({
            name:'Toplam',
            count_:subjectsummary.reduce((a,b)=>a+parseInt(b.count_),0),
            ins_renew:subjectsummary.reduce((a,b)=>a+parseInt(b.ins_renew),0)
        })

        setSubjectSummary(subjectsummary);

        let monthly_sale_data = [];
        let current_year_data =   user==='Tümü'?
        result.filter(row=>parseInt(row.year_)===parseInt(years)):
        result.filter(row=>parseInt(row.year_)===parseInt(years)&&row.id_com_user_name===user)
        Month.map(month=>{
            monthly_sale_data.push(current_year_data.filter(row=>parseInt(row.month_)===parseInt(month)).length );
        })
        setMonthlyData([{'name':'Arama Adedi', 'data':monthly_sale_data}])
     }  


     const SelecPicker = ({selectData,Change,disabled,Value}) => { 

        return (
        <Select style={{width:'200px'}} onChange={Change}   disabled={disabled} defaultValue={Value}  >
              {
                selectData.map((row,index)=>{
                    return ( 
                        <Option key={index}   value={row}>{row}</Option>
                    )
                })
             }
    </Select>
        )
    }


    const rowStyle = {
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
      };
    const colStyle = {
        marginBottom: '16px',
      };


    const gutter = 12;

 return(
    <div className="main">
        <div className="main-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="panel">
                            <Row>
                                <Col span={24}>
                                    <PageHeader title='Crm Raporlar |' subTitle='Sigorta Yenileme'
                                     extra={[                     
                                            <SelecPicker key='select1' selectData={Month} Change={(x)=>{_GetData(SelectedYear,SelectedUser,x)}} Value={SelectedMonth}/>,
                                            <SelecPicker key='select2' selectData={Year} Change={(x)=>{_GetData(x,SelectedUser,SelectedMonth)}} Value={SelectedYear}/>,
                                            <SelecPicker key='select3' selectData={Users} Change={(x)=>{_GetData(SelectedYear,x,SelectedMonth)}} Value={SelectedUser}/>

                                    ]}/>
                                </Col>
                             </Row>
                        </div>
                              <Row gutter={12}  align='middle' justify='start'>
                                <Col span={24} >
                                <div className="panel">
                                    <Row>
                                        <Col span={24}>
                                            <PageHeader title='Özet Raporlar' subTitle={`${SelectedMonth}. Ay`}  />
                                        </Col>
                                    </Row>
                                    <Row style={{padding:10}} gutter={12}>
                                        <Col span={8}>
                                            <Table  title={()=>'Konuya Göre'  } dataSource={SubjectSummary} columns={SubjectColumn} size='small' rowKey={row=>row.name} pagination={false} bordered/>
                                        </Col>
                                        <Col span={8}>
                                            <Table title={()=>'Sonuçlarına Göre'} dataSource={StatusSummary} columns={meetColumns} size='small' rowKey={row=>row.name} pagination={false} bordered  />
                                        </Col>
                                        <Col span={8}>
                                            <Table title={()=>'Kullanıcılara Göre'} dataSource={UserSummary} columns={meetColumns} size='small' rowKey={row=>row.name} pagination={false} bordered/>
                                        </Col>

                                        
                                    </Row>
                                    <Divider></Divider>
                                 </div>
                                </Col>
                                {/* <Col span={6}  >
                                <div className="panel">
                                <Row>
                                    <Col span={24}>
                                        <PageHeader title='Ay Bazında Adetler |' subTitle='Sigorta'/>
                                    </Col>
                                </Row>
                                <Row style={{padding:10}} gutter={12}>
                                    <Col span={24}>
                                          <Chart   options={bar_options} series={MonthlyData} type="bar" />
                                     </Col>

                                </Row>
                                </div>
                                </Col> */}
                             </Row>
                             <div className="panel">
                                <Row gutter={12}  align='middle' justify='start'>

                                        <Col span={24}>
                                            <PageHeader title='Tüm Görüşmeler' subTitle={`${SelectedMonth}. Ay`}  
                                            extra={[
                                                 <ExportFromJson data={Data.filter(row=>
                                                 SelectedUser==='Tümü'?
                                                 parseInt(row.month_)===parseInt(SelectedMonth)&&parseInt(row.year_)===parseInt(SelectedYear):
                                                 parseInt(row.month_)===parseInt(SelectedMonth)&&parseInt(row.year_)===parseInt(SelectedYear)&&row.id_com_user_name===SelectedUser).reverse()} fileName={`Gunluk_Gorusmeler_${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}`} exportType={'xls'}/>

                                            ]}
                                             />
                                        </Col>

                                        <Col span={24}>

                                        </Col>

                                        <Col span={24}  style={{padding:30}}>
                                                <Table  dataSource={Data.filter(row=>
                                                SelectedUser==='Tümü'?
                                                parseInt(row.month_)===parseInt(SelectedMonth)&&parseInt(row.year_)===parseInt(SelectedYear):
                                                parseInt(row.month_)===parseInt(SelectedMonth)&&parseInt(row.year_)===parseInt(SelectedYear)&&row.id_com_user_name===SelectedUser).reverse()}
                                                columns={Columns} size='small' rowKey={row=>row.id} pagination={{pageSize:10}} bordered 
                                                footer={()=><Text code>{Data.filter(row=>
                                                SelectedUser==='Tümü'?
                                                parseInt(row.month_)===parseInt(SelectedMonth)&&parseInt(row.year_)===parseInt(SelectedYear) :
                                                parseInt(row.month_)===parseInt(SelectedMonth)&&parseInt(row.year_)===parseInt(SelectedYear)&&row.id_com_user_name===SelectedUser).length} adet kayıt bulunmaktadır.</Text>}/>
                                        </Col>
                                </Row>
                             </div>

                     </div>
                </div>
            </div>
        </div>
    </div>

)
}

export default connect(mapStateToProps)(ReportCrmT3S2)