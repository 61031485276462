import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../redux/mapStateToProps';
import mapDispatchToProps from '../redux/mapDispatchToProps';


class LoginForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            password_required: false,
            email_required: false
        }
        this._Login = this._Login.bind(this)
    }
    _Login() {
        let email = this.state.email;
        let password = this.state.password;
        let api_url = this.props.api_url;
        let url = api_url + '/login';
        if (email === '' || password === '') {
            email === '' ?
                this.setState({email_required: true})
            :
                this.setState({email_required: false});
            password === '' ?
                this.setState({password_required: true})
            :
                this.setState({password_required: false});
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("email", email);
            urlencoded.append("password", password);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };
            fetch(url, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status) {
                        this.props._Add_Login_Token(result.token);
                        this.props._Add_Id_Com_User(result.id);
                        this.props._Add_Username(result.username);
                        this.props._Add_Email(result.email);
                        this.props._Add_Password(result.password);
                        this.props._Log_Status_Change();
                        let user_url=this.props.api_url+'/selectRows/view_user';

                        fetch(user_url,{
                                method:'POST',
                                cahce: 'no-cache',
                                headers:{ 
                                  'Content-Type':'application/x-www-form-urlencoded'
                                },
                              body:'token='+this.props.loginToken+'&type=user&id='+result.id,
                            })
                            .catch(error=>console.log(error)).then(x=> x.json()).then(data=>{
                                this.props._Add_Id_Com_User_Type(data[0].id_com_usertype);
                                this.props._Add_Id_Com_Brand(data[0].id_com_brand);
                                this.props._Add_Id_Com_Department(data[0].id_com_department);
                                // window.location.reload();
                            })
                    } else {
                        alert('Kullanıcı Bilgilerinizi ve şifrenizi kontrol ediniz')
                    }
                })
                .catch(error => console.log('error', error));
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="vertical-align-wrap">
                    <div className="vertical-align-middle">
                        <div className="auth-box ">
                            <div className="left">
                                <div className="content">
                                    <div className="header">
                                        <div className="logo text-center"><img src="assets/img/logo.png" height="80" alt="Şentürk CRM"/>
                                        </div>
                                        {/* <p className="lead">Oturum Açın</p> */}
                                    </div>
                                    <form className="form-auth-small">
                                        <div className="form-group">
                                            <label htmlFor="signin-email"
                                                   className="control-label sr-only">E-Posta</label>
                                            <input
                                                className={this.state.email_required ? "form-control text-danger" : "form-control"}
                                                type="email" placeholder="E-Posta"
                                                onKeyPress={(x) => x.key === 'Enter' ? this._Login() : ''}
                                                onChange={(x) => this.setState({email: x.target.value})}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="signin-password"
                                                   className="control-label sr-only">Şifre</label>
                                            <input
                                                className={this.state.password_required ? "form-control text-danger" : "form-control"}
                                                type="password" placeholder="Şifre"
                                                onKeyPress={(x) => x.key === 'Enter' ? this._Login() : ''}
                                                onChange={(x) => this.setState({password: x.target.value})}/>
                                        </div>
                                        <button type="button" className="btn btn-primary btn-lg btn-block" onClick={() => this._Login()}>Giriş</button>
                                        <div className="bottom">
                                            <span className="helper-text"><i className="fa fa-lock"></i> <a href="#">Şifremi unuttum?</a></span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="right">
                                <div className="overlay"></div>
                                <div className="content text">
                                    <h1 className="heading">Şentürk CRM</h1>
                                    <p>Mitrotech</p>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);