import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component';
const columns = [
    {
        name: 'Tarih',
        cell : row =>(
            row.invoice_date.split('-')[2] + '.' +
            row.invoice_date.split('-')[1] + '.' +
            row.invoice_date.split('-')[0]
        ),
        selector: 'invoice_date',
        left:true,
        width: "100px"
    },
    {
        name: 'Belge No',
        selector: 'doc_number',
        left:true
    },
    {
        name: 'Fatura No',
        selector: 'inv_number',
        left:true
    },
    {
        name: 'Fatura Tipi',
        selector: 'id_com_invoice_type_name',
        left:true
    },
    {
        name: 'Firma Adı',
        selector: 'company_name',
        left:true
    },
    {
        name: 'Müşteri',
        selector: 'username',
        left:true
    },
    {
        name: 'Durum',
        selector: 'id_com_invoice_status_name',
        left:true,
        width: "200px"
    },  
    {
        name: 'Kontrol',
        cell : row =>(
            <a className="btn btn-primary btn-sm btn-block" type="button" href={'/InvCard/'+row.id}>Seç</a>
        ),
        left:true,
        width: "100px"
    }
    ]
class InvList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            SearchItem:''
        };
        this._Filter_Data=this._Filter_Data.bind(this)
        this._Update=this._Update.bind(this)
    }
    componentDidMount(){
        this._Update()
    }
    _Update() {
        let select_url=this.props.api_url+'/selectRows/view_invoice';
        let user_body = 'id_com_company='+this.props.id_com_company+'&token='+this.props.loginToken+'&type=user';
        fetch(select_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body: user_body
        }).then(res=>res.json())
            .then(result=>{
                this.setState({data:result,pageloader:true})
            })
    }
    _Filter_Data(value) {
        this.setState({SearchItem:value})
        let data = this.state.data;
        let filtered_data = data.filter(function(row){
            if(row.inv_number!==null){
                return row.inv_number.includes(value.toUpperCase())
            } else {
                return null
            }
        })
        this.setState({filtered:filtered_data})
    }
    render () {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                {!this.state.pageloader?
                                    <div className="col-md-12">
                                        <div className="alert alert-info text-center"><i className="fas fa-spinner fa-spin fa-2x"></i><p className="lead">Lütfen bekleyiniz</p></div>
                                    </div>
                                :
                                    !this.state.data.length>0?
                                        <div className="col-md-12">
                                            <h3 className="page-title">Randevu Listesi {this.props.id_com_company}</h3>
                                            <div className="alert alert-info text-center"><i className="fas fa-warning"></i><p className="lead">Kayıt bulunamadı.</p></div>
                                        </div>
                                    :
                                        <div>
                                            <div className="col-md-6">
                                                <h3 className="page-title">Randevular</h3>
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <a href="/InvAdd" className="btn btn-primary">Fatura / Fiş Oluştur</a>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="panel">
                                                    <div className="panel-heading">
                                                        <h3 className="panel-title">Randevu Listesi</h3>
                                                    </div>
                                                    <DataTable
                                                        columns={columns}
                                                        data={this.state.SearchItem===''?this.state.data:this.state.filtered}
                                                        fixedHeader
                                                        noHeader={true}
                                                        overflowY={false}
                                                        overflowYOffset={'100px'}
                                                        highlightOnHover
                                                        responsive
                                                        striped
                                                        // dense
                                                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                                        progressPending={!this.state.pageloader}
                                                        pagination={true}
                                                        paginationPerPage={20}
                                                        defaultSortField={'invoice_date'}
                                                        defaultSortAsc={false}
                                                        subHeader={true}
                                                        subHeaderAlign={'right'}
                                                        subHeaderComponent={
                                                            <React.Fragment>
                                                                <form className="input-group">
                                                                    {this.state.SearchItem!==''?
                                                                        <span className="input-group-btn"><button className="btn btn-primary" onClick={()=>this.setState({SearchItem:'',filtered_data:[]})}><i className="fas fa-list"></i></button></span>
                                                                        :
                                                                        <span className="input-group-addon"><i className="fas fa-search"></i></span>
                                                                    }
                                                                    <input className="form-control" value={this.state.SearchItem} type="search" placeholder="Fatura / Fiş Arama..." onChange={(x)=>this._Filter_Data(x.target.value)}/>
                                                                </form>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(InvList);