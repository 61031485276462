import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
import DataTable from 'react-data-table-component';
const columns =[
    {
        name: 'Kod',
        selector: 'code',
        left:true
    },
    {
      name: 'Adı',
      selector: 'product_name',
      left:true
    },
    {
        name: 'Tip',
        selector: 'id_com_product_type_name',
        left:true
    },
    {
        name: 'Grup',
        selector: 'id_com_product_class_name',
        left:true
    },
    {
        name: 'Adet',
        selector: 'line_quantity',
        right:true
    },
    {
        name: 'Tutarı',
        selector: 'list_price',
        right:true
    },
    {
        name: 'İndirim %',
        selector: 'disc_rate',
        right:true
    },
    {
        name: 'İndirim',
        selector: 'disc_amount',
        right:true
    },
    {
        name: 'Net Tutar',
        selector: 'net_price',
        right:true
    },
    {
        name: 'KDV Tutar',
        selector: 'tax_amount',
        right:true
    },
    {
        name: 'Satır Toplamı',
        selector: 'line_amount',
        right:true
    }
]
class InvCard extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            pageLoader:false,
            id_com_invoice:'',
            invoice_line:[],
            invoice_body:[],
            id_com_acc_nr:'',
            id_com_invoice_status:'',
            id_com_customer:'',
            id_com_depot:''
        }
        this._Update=this._Update.bind(this)
        this._Submit=this._Submit.bind(this)
    }
    _Submit(){
        let invoice_line = this.state.invoice_line;
        let invoice_body = this.state.invoice_body;
        let line_url = this.props.api_url+'/insert/com_account_lines';
        let stock_url =  this.props.api_url+'/insert/com_stock_lines';
        let d = new Date();
        let date = d.toISOString();
        let comment = this.state.invoice_body.inv_number+' / '+this.state.invoice_body.id_com_invoice_type_name+' / '+this.state.invoice_body.company_name+' - '+this.state.invoice_body.invoice_date;
        let c_date  = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let c_id_com_user =   this.props.id_com_user;
        let url_acc_line=this.props.api_url+'/selectRows/view_account_plan_group_level4  ';
        let acc_line = 'id='+this.state.id_com_acc_nr+'&token='+this.props.loginToken+'&type=user&id_com_company='+this.props.id_com_company;
        fetch(url_acc_line,{
            method:'POST',
            cahce: 'no-cache',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body: acc_line
        })
            .catch(error=>console.log(error))
            .then(res=>res.json())
            .then(result=>{
                let insert_120 = 'sign=1&class_code=120&id_com_account_plan_group_level4='+result[0].id+'&credit='+(parseFloat(invoice_body.net_price)+parseFloat(invoice_body.tax_amount))+'&debit=0&comment='+comment+'&doc_number='+this.state.invoice_body.doc_number+'&c_date='+c_date+'&c_time='+c_time+'&c_id_com_user='+c_id_com_user+'&id_com_invoice='+this.state.id_com_invoice+'&id_com_company='+this.props.id_com_company+'&token='+this.props.loginToken+'&type=user';
                let insert_320 = 'sign=0&class_code=320&id_com_account_plan_group_level4='+result[0].id+'&debit='+(parseFloat(invoice_body.net_price)+parseFloat(invoice_body.tax_amount))+'&credit=0&comment='+comment+'&doc_number='+this.state.invoice_body.doc_number+'&c_date='+c_date+'&c_time='+c_time+'&c_id_com_user='+c_id_com_user+'&id_com_invoice='+this.state.id_com_invoice+'&id_com_company='+this.props.id_com_company+'&token='+this.props.loginToken+'&type=user';
                fetch(line_url,{
                    method: 'POST',
                    headers:  {
                        'Content-Type':  'application/x-www-form-urlencoded'
                    },
                    body:  this.state.invoice_body.id_com_invoice_type==='1'? insert_120:this.state.invoice_body.id_com_invoice_type==='2'?insert_320: null,
                }).catch(error=>console.log('line_120_320:' + error))
                if (this.state.invoice_body.id_com_invoice_type==='5') {
                    let insert_120_6 = 'sign=1&class_code=120&id_com_account_plan_group_level4='+result[0].id+'&debit='+(parseFloat(invoice_body.net_price)+parseFloat(invoice_body.tax_amount))+'&credit=0&comment='+comment+'&doc_number='+this.state.invoice_body.doc_number+'&c_date='+c_date+'&c_time='+c_time+'&c_id_com_user='+c_id_com_user+'&id_com_invoice='+this.state.id_com_invoice+'&id_com_company='+this.props.id_com_company+'&token='+this.props.loginToken+'&type=user';
                    fetch(line_url,{
                        method: 'POST',
                        headers:  {
                            'Content-Type':  'application/x-www-form-urlencoded'
                        },
                        body:  insert_120_6
                    }).catch(error=>console.log('line_120_320:' + error))
                }
                if (this.state.invoice_body.id_com_invoice_type==='6') {
                    let insert_320_6 = 'sign=1&class_code=320&id_com_account_plan_group_level4='+result[0].id+'&credit='+(parseFloat(invoice_body.net_price)+parseFloat(invoice_body.tax_amount))+'&debit=0&comment='+comment+'&doc_number='+this.state.invoice_body.doc_number+'&c_date='+c_date+'&c_time='+c_time+'&c_id_com_user='+c_id_com_user+'&id_com_invoice='+this.state.id_com_invoice+'&id_com_company='+this.props.id_com_company+'&token='+this.props.loginToken+'&type=user';
                    fetch(line_url,{
                        method: 'POST',
                        headers:  {
                            'Content-Type':  'application/x-www-form-urlencoded'
                        },
                        body:  insert_320_6
                    }).catch(error=>console.log('insert_320_6:' + error))
                }
                let insert_191 = 'sign=1&class_code=191&id_com_account_plan_group_level4=87&credit=0'+parseFloat(invoice_body.tax_amount)+'&debit=0&comment='+comment+'&doc_number='+this.state.invoice_body.doc_number+'&c_date='+c_date+'&c_time='+c_time+'&c_id_com_user='+c_id_com_user+'&id_com_invoice='+this.state.id_com_invoice+'&id_com_company='+this.props.id_com_company+'&token='+this.props.loginToken+'&type=user';
                let insert_391 =  'sign=0&class_code=391&id_com_account_plan_group_level4=85&debit=0'+parseFloat(invoice_body.tax_amount)+'&credit=0&comment='+comment+'&doc_number='+this.state.invoice_body.doc_number+'&c_date='+c_date+'&c_time='+c_time+'&c_id_com_user='+c_id_com_user+'&id_com_invoice='+this.state.id_com_invoice+'&id_com_company='+this.props.id_com_company+'&token='+this.props.loginToken+'&type=user';
                fetch(line_url, {
                    method: 'POST',
                    headers:  {
                        'Content-Type':  'application/x-www-form-urlencoded'
                    },
                    body:  this.state.invoice_body.id_com_invoice_type==='1'? insert_391:this.state.invoice_body.id_com_invoice_type==='2'?insert_191:null,
                }).catch(error=>console.log('line_391_191:' + error))
                console.log(this.state.invoice_body.id_com_invoice_type)
                switch(this.state.invoice_body.id_com_invoice_type) {
                    case '1' :
                        invoice_line.map((row)=>{
                            let insert_600 = 'sign=0&class_code=600&id_com_account_plan_group_level4='+row.line_acc_nr_sale+'&debit='+parseFloat(row.list_price)*parseFloat(row.line_quantity)+'&credit=0&comment='+comment+'&doc_number='+this.state.invoice_body.doc_number+'&c_date='+c_date+'&c_time='+c_time+'&c_id_com_user='+c_id_com_user+'&id_com_invoice='+this.state.id_com_invoice+'&id_com_company='+this.props.id_com_company+'&token='+this.props.loginToken+'&type=user';
                            let insert_611 =  'sign=1&class_code=611&id_com_account_plan_group_level4='+row.line_acc_nr_disc+'&credit='+parseFloat(row.disc_amount)+'&debit=0&comment='+comment+'&doc_number='+this.state.invoice_body.doc_number+'&c_date='+c_date+'&c_time='+c_time+'&c_id_com_user='+c_id_com_user+'&id_com_invoice='+this.state.id_com_invoice+'&id_com_company='+this.props.id_com_company+'&token='+this.props.loginToken+'&type=user';
                            fetch(line_url, {
                                method: 'POST',
                                headers:  {
                                    'Content-Type':  'application/x-www-form-urlencoded'
                                },
                                body: insert_600,
                            }).catch(error=>console.log('line_600:' + error))
                            if(parseFloat(row.disc_amount)>0)  {
                                fetch(line_url, {
                                    method: 'POST',
                                    headers:  {
                                        'Content-Type':  'application/x-www-form-urlencoded'
                                    },
                                    body: insert_611,
                                }).catch(error=>console.log('line_611:' + error))
                            }
                return null
                        })
                        break;
                        case '2':
                            invoice_line.map((row)=>{
                                switch(row.id_com_product_type){
                                    case '1':
                                        let insert_153 = 'sign=1&class_code=153&id_com_account_plan_group_level4='+row.line_acc_nr_purchase+'&credit='+parseFloat(row.net_price)+'&debit=0&comment='+comment+'&doc_number='+this.state.invoice_body.doc_number+'&c_date='+c_date+'&c_time='+c_time+'&c_id_com_user='+c_id_com_user+'&id_com_invoice='+this.state.id_com_invoice+'&id_com_company='+this.props.id_com_company+'&token='+this.props.loginToken+'&type=user';
                                        fetch(line_url, {
                                            method: 'POST',
                                            headers:  {
                                                'Content-Type':  'application/x-www-form-urlencoded'
                                            },
                                            body: insert_153,
                                        }).catch(error=>console.log('line_153:' + error))
                                        return null
                                        break;
                                        default:
                                            return null
                                }
                            })
                            break;
                            case '5' :
                                invoice_line.map((row)=>{
                                    let insert_100 = 'sign=0&class_code=&id_com_account_plan_group_level4='+row.line_acc_nr_income+'&credit='+parseFloat(row.net_price)+'&debit=0&comment='+comment+'&doc_number='+this.state.invoice_body.doc_number+'&c_date='+c_date+'&c_time='+c_time+'&c_id_com_user='+c_id_com_user+'&id_com_invoice='+this.state.id_com_invoice+'&id_com_company='+this.props.id_com_company+'&token='+this.props.loginToken+'&type=user';
                                    fetch(line_url, {
                                        method: 'POST',
                                        headers:  {
                                            'Content-Type':  'application/x-www-form-urlencoded'
                                        },
                                        body: insert_100,
                                    }).catch(error=>console.log('line_100:' + error))
                                        .then(res=> console.log('success 100'))
                                })
                                break;
                                case '6' :
                                    invoice_line.map((row)=>{
                                        let insert_100_6 = 'sign=0&class_code=&id_com_account_plan_group_level4='+row.line_acc_nr_income+'&debit='+parseFloat(row.net_price)+'&credit=0&comment='+comment+'&doc_number='+this.state.invoice_body.doc_number+'&c_date='+c_date+'&c_time='+c_time+'&c_id_com_user='+c_id_com_user+'&id_com_invoice='+this.state.id_com_invoice+'&id_com_company='+this.props.id_com_company+'&token='+this.props.loginToken+'&type=user';
                                        fetch(line_url, {
                                            method: 'POST',
                                            headers:  {
                                                'Content-Type':  'application/x-www-form-urlencoded'
                                            },
                                            body: insert_100_6,
                                        }).catch(error=>console.log('insert_100_6:' + error))
                                            .then(res=> console.log('insert_100_6 700'))
                                    })
                                    break;
                                    default:
                                        return null
                }
                let invoice_update = 'id='+this.state.id_com_invoice+'&m_date='+c_date+'&m_time='+c_time+'&acc_nr='+this.state.id_com_acc_nr+'&m_id_com_user='+c_id_com_user+'&id_com_invoice_status=2&token='+this.props.loginToken+'&type=user';
                let url_invoice=this.props.api_url+'/update/com_invoice';
                fetch(url_invoice, {
                    method: 'POST',
                    headers:  {
                        'Content-Type':  'application/x-www-form-urlencoded'
                    },
                    body: invoice_update,
                }).catch(error=>console.log('invoice_update:' + error)).then(res=>
                {
                    return null
                })
                switch(this.state.invoice_body.id_com_invoice_type){
                    case '1' :
                        invoice_line.map((row)=> {
                            if(row.id_com_product_type==='1'){
                                fetch(stock_url, {
                                    method: 'POST',
                                    headers:  {
                                        'Content-Type':  'application/x-www-form-urlencoded'
                                    },
                                    body: 'c_date='+ c_date+
                                        '&doc_date='+ row.invoice_date+
                                        '&id_com_depot='+ this.state.id_com_depot+
                                        '&c_id_com_user='+ c_id_com_user+
                                        '&c_time='+ c_time+
                                        '&id_com_invoice='+row.id_com_invoice+
                                        '&id_com_product='+ row.id_com_product+
                                        '&id_com_company='+ this.props.id_com_company+
                                        '&sale_quantity='+ row.line_quantity+
                                        '&sale_price='+ (parseFloat(row.net_price)/parseFloat(row.line_quantity))+
                                        '&token='+this.props.loginToken+
                                        '&type=user&purchase_quantity=0&purchase_price=0&stored=0&id_depot_part_number=0',
                                })
                                    .catch(error=> console.log(error))
                                    .then(res=> window.location.href='/InvList')
                                return null
                            } else {
                                return null
                            }
                        })
                        break;
                        case '2' :
                            invoice_line.map((row)=> {
                                if(row.id_com_product_type==='1'){
                                    fetch(stock_url, {
                                        method: 'POST',
                                        headers:  {
                                            'Content-Type':  'application/x-www-form-urlencoded'
                                        },
                                        body: 'c_date='+ c_date+
                                            '&doc_date='+ row.invoice_date+
                                            '&c_id_com_user='+ c_id_com_user+
                                            '&id_com_depot='+ this.state.id_com_depot+
                                            '&c_time='+ c_time+
                                            '&id_com_product='+ row.id+
                                            '&id_com_invoice='+row.id_com_invoice  +
                                            '&id_com_product='+ row.id_com_product+
                                            '&id_com_company='+ this.props.id_com_company+
                                            '&purchase_quantity='+ row.line_quantity+
                                            '&purchase_price='+ (parseFloat(row.net_price)/parseFloat(row.line_quantity))+
                                            '&token='+this.props.loginToken+
                                            '&type=user&sale_quantity=0&sale_price=0&stored=0&id_depot_part_number=0',
                                    })
                                        .catch(error=> console.log(error))
                                        .then(res=> window.location.href='/InvList')
                                    return null
                                } else {
                                    return null
                                }
                            })
                            break;
                            default:
                                return null
                }
                return null
            })
    }
    componentDidMount() {
        this._Update()
    }
    _Update() {
        let id_com_invoice = this.props.match.params.InvId
        this.setState({id_com_invoice:this.props.match.params.InvId})
        let url_invoice_body=this.props.api_url+'/selectRows/view_invoice';
        let invoice_body = 'id='+id_com_invoice+'&token='+this.props.loginToken+'&type=user&id_com_company='+this.props.id_com_company;
        let url_invoice_line=this.props.api_url+'/selectRows/view_invoice_line';
        let invoice_line = 'id_com_invoice='+id_com_invoice+'&token='+this.props.loginToken+'&type=user&id_com_company='+this.props.id_com_company;
        fetch(url_invoice_body,{
            method:'POST',
            cahce: 'no-cache',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body: invoice_body
        })
            .catch(error=>console.log(error))
            .then(res=>res.json())
            .then(result=>{
                fetch(url_invoice_line,{
                    method:'POST',
                    cahce: 'no-cache',
                    headers:{
                        'Content-Type':'application/x-www-form-urlencoded'
                    },
                    body: invoice_line
                })
                    .catch(error=>console.log(error))
                    .then(res=>res.json())
                    .then(result=>{
                        console.log(result)
                        this.setState({invoice_line:result})
                    })
                this.setState({
                    invoice_body:result[0],
                    id_com_customer:result[0].id_com_customer,
                    id_com_invoice_status:result[0].id_com_invoice_status,
                    id_com_acc_nr:result[0].acc_nr,
                    pageloader:true
                })
                console.log(result[0])
            })
    }
    render () {
        return(
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="page-title">Fatura Detayı</h3>
                            </div>
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">Detay</h3>
                                    </div>
                                    <div className="panel-body">
                                        <table className="table table-bordered table-striped">
                                            <tbody>
                                                <tr>
                                                    <th width="200">Fatura Tipi</th>
                                                    <td>{this.state.invoice_body.id_com_invoice_type_name}</td>
                                                </tr>
                                                <tr>
                                                    <th width="200">Fatura No</th>
                                                    <td>{this.state.invoice_body.invoice_no}</td>
                                                </tr>
                                                <tr>
                                                    <th width="200">Belge No</th>
                                                    <td>{this.state.invoice_body.doc_number}</td>
                                                </tr>
                                                <tr>
                                                    <th width="200">Fatura Tarihi</th>
                                                    <td>{this.state.invoice_body.invoice_date}</td>
                                                </tr>
                                                <tr>
                                                    <th width="200">Vade Tarihi</th>
                                                    <td>{this.state.invoice_body.due_date}</td>
                                                </tr>
                                                <tr>
                                                    <th width="200">Müşteri Bilgileri</th>
                                                    <td>{this.state.invoice_body.company_name+' / '+this.state.invoice_body.id_com_customer_type_name}</td>
                                                </tr>
                                                <tr>
                                                    <th width="200">Vergi Dairesi / Vergi No</th>
                                                    <td>{this.state.invoice_body.tax_office+' / '+this.state.invoice_body.taxno}</td>
                                                </tr>
                                                <tr>
                                                    <th width="200">Adres</th>
                                                    <td>{this.state.invoice_body.adress+', Posta Kodu: '+this.state.invoice_body.postcode+' / '+this.state.invoice_body.id_com_town_name+' - '+ this.state.invoice_body.id_com_city_name}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="panel">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">Fatura Bilgisi</h3>
                                    </div>
                                    <div className="panel-body">
                                        <table className="table table-bordered table-striped">
                                            <tbody>
                                            <tr>
                                                <th width="200">Fatura Tutarı</th>
                                                <td>{this.state.invoice_body.list_amount+' TL'}</td>
                                            </tr>
                                            <tr>
                                                <th width="200">İndirim Tutarı</th>
                                                <td>{this.state.invoice_body.disc_amount+' TL'}</td>
                                            </tr>
                                            <tr>
                                                <th width="200">KDV Matrahı</th>
                                                <td>{this.state.invoice_body.net_price+' TL'}</td>
                                            </tr>
                                            <tr>
                                                <th width="200">KDV Tutarı</th>
                                                <td>{this.state.invoice_body.tax_amount+' TL'}</td>
                                            </tr>
                                            <tr>
                                                <th width="200">Toplam Tutar</th>
                                                <td>{this.state.invoice_body.line_amount+' TL'}</td>
                                            </tr>
                                            <tr>
                                                <th width="200">Fatura Açıklaması</th>
                                                <td><textarea value={this.state.invoice_body.comment} disabled={true} className="form-control" placeholder="Fatura Açıklaması" rows="5"></textarea></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="panel">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">Fatura Detay</h3>
                                    </div>
                                    <div className="panel-body">
                                        <DataTable
                                            columns={columns}
                                            data={this.state.invoice_line}
                                            fixedHeader
                                            noHeader={true}
                                            overflowY={false}
                                            overflowYOffset={'200px'}
                                            highlightOnHover
                                            responsive
                                            striped
                                            paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                            // dense
                                            pagination={true}
                                            paginationPerPage={20}
                                            defaultSortField={'id_com_product_type'}
                                            defaultSortAsc={true}
                                        />
                                    </div>
                                </div>
                                <div className="panel">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">Kontrol</h3>
                                    </div>
                                    <div className="panel-body">
                                        <SelectList table="com_account_plan_group_level4" Label={"Cari Kart"} value={this.state.id_com_acc_nr} accnr={this.state.invoice_body.id_com_invoice_type==='1'||this.state.invoice_body.id_com_invoice_type==='5'?'120':'320'} disabled={this.state.id_com_acc_nr==='0'?false:true} customer_id={this.state.id_com_customer} onChange={(x)=>this.setState({id_com_acc_nr: x.id})} />
                                        <SelectList table="com_depot" Label={this.state.invoice_body.id_com_invoice_type==='1'?'Depo Çıkışı':'Depo Girişi'} value={this.state.id_com_depot} id_com_company={this.props.id_com_company} onChange={(x)=>this.setState({id_com_depot: x.id})} />
                                        <SelectList value={this.state.id_com_invoice_status} Label={"Fatura Durumu"} table="com_invoice_status" onChange={(x)=>this.setState({id_com_invoice_status: x.id})} disabled={parseInt(this.state.invoice_body.id_com_invoice_status)>1?true:false} />
                                    </div>
                                </div>
                                <a className="btn btn-danger" href="/InvList">İptal</a> <button disabled={this.state.id_com_acc_nr==='0'?true:this.state.invoice_body.id_com_invoice_status!=='1'?true:false} className="btn btn-primary" onClick={()=>this._Submit()}>Kaydet</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(mapStateToProps)(InvCard);