import React ,{useState,useEffect}from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import Wrapper from '../utilities/Wrapper';
import {Row,Col,Button,Descriptions,Modal,Form,Input, DatePicker,Divider, Checkbox,message} from 'antd';
import { EditOutlined,FileDoneOutlined,CheckOutlined,CloseOutlined} from '@ant-design/icons';
import moment from 'moment';
import SelectList from '../utilities/SelectList';

import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import newSelectlist from '../utilities/newSelectlist';



const  ExpertiseDetail = ({match,api_url,loginToken,id_com_user})=> {
const [stockForm] = Form.useForm()

const [Data,setData] = useState([])
const [Loading,setLoading] = useState(false)
const [Id,setId] = useState('')
const [addStockModal,setaddStockModal] = useState(false)
const [IdComBrand,setIdComBrand] = useState('')



useEffect(() => {
    const _onLoad = ()=>{

        _getData()
    }
   return _onLoad();
}, [])


const _getData = async ()=>{
    const id = match.params.id;
    setId(id)
    const view_body_url= api_url+'/selectRows/view_expertise';
    const body = 'id='+id+'&token='+loginToken+'&type=user'
    const config = { method:'POST',  cahce: 'no-cache', headers:{ 'Content-Type':'application/x-www-form-urlencoded' },body:body}

    try { 
        const response = await(await fetch(view_body_url,config)).json();
        stockForm.setFieldsValue(response[0])
        setIdComBrand(response[0].id_com_brand)
        console.log(response[0])
        setData(response[0])
    }   

    catch(e){
        console.log(e)
    }
}  
 

const _Selector =(e)=>{
const key = e[0].name[0];
const value= e[0].value;

switch(key) {
        case 'id_com_brand':
            setIdComBrand(value)
        break;


    default:
        break
}
}


const _addToStock = async ()=>{
    setLoading(true)
    const form_data = stockForm.getFieldsValue()
    let d = new Date(); 
    let date = moment(d).format('YYYY-MM-DD')
    let time = d.toLocaleTimeString('tr-TR');

    const view_body_url= api_url+'/insert/com_stock';
    let body = 'type=user&id_com_stock_type=2&token='+loginToken+'&id_com_expertise='+Id+'&id_com_user='+id_com_user+'&c_time='+time+'&c_date='+date;
    for ( const [ key,value] of Object.entries(form_data)){ 

        switch(key){
            case 'date':
                body +=`&${key}=${moment(value).format('YYYY-MM-DD')}`
                break;
            case 'legal_date':
                body +=`&${key}=${moment(value).format('YYYY-MM-DD')}`
                break;
            case 'max_sale_date':
                body +=`&${key}=${moment(value).format('YYYY-MM-DD')}`
                break;
            case 'is_invoiced':
                body +=`&${key}=${value?1:0}`
                break;
            case 'is_in_warranty':
                body +=`&${key}=${value?1:0}`
                break;
            case 'spare_key':
                body +=`&${key}=${value?1:0}`
                break;
            default :
                body +=`&${key}=${value}`
            break;
        }
    }

 
    const config = { method:'POST',  cahce: 'no-cache', headers:{ 'Content-Type':'application/x-www-form-urlencoded' },body:body}

    try { 
        const response = await(await fetch(view_body_url,config)).json();

        if(response.status){
  
            const expertise_url= api_url+'/update/com_expertise';
            let body2 = 'type=user&id_com_expertise_status=5&token='+loginToken+'&id='+Id+'&m_id_com_user='+id_com_user+'&m_time='+time+'&m_date='+date;
            const config2 = { method:'POST',  cahce: 'no-cache', headers:{ 'Content-Type':'application/x-www-form-urlencoded' },body:body2}
            const response2 = await(await fetch(expertise_url,config2)).json();

            if(response2.status){
                message.success('Araç Stoğa Eklenmiştir',1).then(()=>window.location.href='/usedcars/detail/'+response.id)
            } else { 
                message.error('Bir Hata Oluştu',1).then(()=>window.location.reload())
            }
        }
        else { 
            message.error('Bir Hata Oluştu',1).then(()=>window.location.reload())
        } 

    }   

    catch(e){
        console.log(e)
    }

}
        return ( 
            <Wrapper subtitle={`Ekspertiz No : ${Data.id}`} title={'Ekspertiz Notları |'} extra={[
                !['3'].includes(Data.id_com_expertise_status) && <Button type='default' key='edit'  href={'/Expertise/Edit/' +Data.id} icon={<EditOutlined/>} >Düzenle</Button>,
                ['3'].includes(Data.id_com_expertise_status)  && <Button type='primary' key='tostock'  onClick={()=>setaddStockModal(true)}  icon={<FileDoneOutlined/>} >Stoğa Ekle</Button>
            ]}>
                <Row>
                    <Col span={24} style={{padding:20}}>
                            <Descriptions size='small' bordered  layout='vertical' labelStyle={{fontWeight:'bold'}} column={4} >
                                <Descriptions.Item span={2} label='Talep Tarih Saati'>
                                    {moment(Data.c_date).format('DD.MM.YYYY')+' - '+Data.c_time}
                                </Descriptions.Item>
                                <Descriptions.Item span={2} label='Talep Eden'>
                                    {Data.id_com_user_name}
                                </Descriptions.Item>
                                <Descriptions.Item span={4} label='Müşteri Bilgisi '>
                                    {Data.id_com_customer_name+' /  Tel : '+Data.phone1+' / Email : '+Data.email}
                                </Descriptions.Item>
                                <Descriptions.Item span={2} label='Plaka '>
                                    {Data.license_plate}
                                </Descriptions.Item>
                                <Descriptions.Item span={2} label='Kilometre '>
                                    {Data.km}
                                </Descriptions.Item>
                                <Descriptions.Item span={2} label='Araç Bilgileri'>
                                    {Data.id_com_brand_name+' - '+Data.id_com_model_name+' / '+Data.spec +' / Şase no : '+Data.chasis+' / model yılı :'+Data.model_year} 
                                </Descriptions.Item>
                                <Descriptions.Item span={2} label='Araç Bilgileri 2 '>
                                     {' Vites Tipi : '+Data.id_com_gear_name+' / Yakıt Tipi: '+Data.id_com_fuel_name+' / Renk: '+Data.id_com_color_name}
                                </Descriptions.Item>
                                <Descriptions.Item span={4} label='Hasar Bilgisi '>
                                    {Data.comment} 
                                </Descriptions.Item>
                                <Descriptions.Item span={1} label='Talep Edilen Fiyat'>
                                    {Data.demand_price} 
                                </Descriptions.Item>
                                <Descriptions.Item span={1} label='Teklif Edilen Fiyat'>
                                    {Data.offer_price} 
                                </Descriptions.Item>
                                <Descriptions.Item span={1} label='Teklif Veren '>
                                    {Data.m_id_com_user_name} 
                                </Descriptions.Item>
                                <Descriptions.Item span={1} label='Durumu '>
                                    {Data.id_com_expertise_status_name} 
                                </Descriptions.Item>
                            </Descriptions>
                    </Col>  
                </Row>
                <Modal
                    width='90%'
                    title='İkinci El Stoğa Ekle'
                    visible={addStockModal}
                    onCancel={()=>setaddStockModal(false)}
                    footer={null}
                >
                    <Form form={stockForm} onFinish={_addToStock} size='small'  labelAlign='left' onFieldsChange={_Selector}  labelCol={{span:9}}>
                        <Row gutter={20}>
                            <Col xl={8} lg={8} md={12} xs={24} sm={24} >
                                <Form.Item name='doc_number'   label={'Araç Dosya No'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input type='text'/>
                                </Form.Item>
                                <Form.Item name='id_com_stock_class'   label={'Araç Sınıfı'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                                    <SelectList table='com_stock_class'  noLabel/>
                                </Form.Item>
                                <Form.Item name='id_com_stock_group'   label={'Kasa Tipi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <SelectList table='com_stock_group'  noLabel/>
                                </Form.Item>
                                <Form.Item name='id_com_brand'   label={'Araç Markası'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <SelectList table='com_brand'  noLabel/>
                                </Form.Item>
                                <Form.Item name='id_com_model'   label={'Araç Modeli'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <SelectList table='com_model' id_com_brand={IdComBrand}  noLabel/>
                                </Form.Item>
                                <Form.Item name='model_year'   label={'Model Yılı'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input type='number'/>
                                </Form.Item>
                                <Form.Item name='spec'   label={'Spec - Donanım'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input type='text'/>
                                </Form.Item>
                                <Form.Item name='engine_power'   label={'Silindir Hacmi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input type='number'/>
                                </Form.Item>
                                <Form.Item name='hp'   label={'Beygir Gücü'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input type='number'/>
                                </Form.Item>
                                <Form.Item name='is_in_warranty'   label={'Garantili '} valuePropName='checked' >
                                    <Checkbox >Evet</Checkbox>
                                </Form.Item>
                                <Form.Item name='spare_key'   label={'Yedek Anahtar'} valuePropName='checked' >
                                    <Checkbox >Var</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col xl={8} lg={8} md={12} xs={24} sm={24} >

                                <Form.Item name='chasis'   label={'Şase No'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input type='text'/>
                                </Form.Item>
                                <Form.Item name='engine_no'   label={'Motor No'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                                    <Input type='text'/>
                                </Form.Item>
                                <Form.Item name='license_plate'   label={'Plaka'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                                    <Input type='text'/>
                                </Form.Item>
                                <Form.Item name='km'   label={'Kilometre'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                                    <Input type='number' min={0}/>
                                </Form.Item>
                                <Form.Item name='id_com_color'   label={'Renk'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                                    <SelectList table='com_color'   noLabel/>
                                </Form.Item>
                                <Form.Item name='id_com_gear'   label={'Vites Tipi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <SelectList table='com_gear'   noLabel/>
                                </Form.Item>
                                <Form.Item name='id_com_fuel'   label={'Yakıt Tipi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <SelectList table='com_fuel'   noLabel/>
                                </Form.Item>
                        
                                <Form.Item name='consumption_1'   label={'Şehir içi YT'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                                    <Input type='number' min={0} step={0.01}/>
                                </Form.Item>
                                <Form.Item name='consumption_2'   label={'Şehir Dışı YT'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                                    <Input type='number' min={0} step={0.01}/>
                                </Form.Item>
                                <Form.Item name='trunk_capacity'   label={'Bagaj Hacmi (m3)'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                                    <Input type='number' min={0} step={0.01}/>
                                </Form.Item>
                            </Col>
                            <Col xl={8} lg={8} md={12} xs={24} sm={24} >
                                <Form.Item name='damage_report'   label={'Hasar Bilgileri'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input.TextArea rows={10}  />
                                </Form.Item>
                                <Form.Item name='comment'   label={'Notlar'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input.TextArea rows={10}  />
                                </Form.Item>
                            </Col>
                            <Divider>Fiyat Bilgileri</Divider>
                            <Col xl={8} lg={8} md={12} xs={24} sm={24} >
                                <Form.Item name='id_com_stock_purchase_type'   label={'Alım Tipi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <SelectList table='com_stock_purchase_type'   noLabel/>
                                </Form.Item>
                                <Form.Item name='id_com_stock_purchase_reason'   label={'Alım Sebebi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <SelectList table='com_stock_purchase_reason'   noLabel/>
                                </Form.Item>
                                <Form.Item name='id_com_kdv_rate'   label={'Kdv Oranı'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <SelectList table='com_kdv_rate'    noLabel/>
                                </Form.Item>
                            </Col>
                            <Col xl={8} lg={8} md={12} xs={24} sm={24} >
                                <Form.Item name='date'   label={'Alış Tarihi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <DatePicker  style={{width:'100%'}} locale={locale} format='DD.MM.YYYY' />
                                </Form.Item>
                                <Form.Item name='legal_date'   label={'Noter Tarihi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <DatePicker  style={{width:'100%'}} locale={locale} format='DD.MM.YYYY' />
                                </Form.Item>
                                <Form.Item name='max_sale_date'   label={'Max Satış Tarihi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <DatePicker  style={{width:'100%'}} locale={locale} format='DD.MM.YYYY' />
                                </Form.Item>
                                <Form.Item name='id_com_purchase_customer'   label={'Satın Alınan'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <newSelectlist table='view_customer_sl'/>
                                </Form.Item>
                            </Col>
                            <Col xl={8} lg={8} md={12} xs={24} sm={24} >
                                <Form.Item name='inv_number'   label={'Noter Belge No'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input type='text'/>
                                </Form.Item>
                                <Form.Item name='inv_price'   label={'Alış Fiyatı'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input type='number' min={0} step={0.01}/>
                                </Form.Item>
                                <Form.Item name='list_price'   label={'Listeleme Fiyatı'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input type='number' min={0} step={0.01}/>
                                </Form.Item>
                                <Form.Item name='is_invoiced'   label={'Faturalı '} valuePropName='checked' >
                                    <Checkbox >Evet</Checkbox>
                                </Form.Item>
              
                            </Col>

                        </Row>
                        <Divider></Divider>
                        <Row gutter={20} justify='end'>
                            <Col >
                                <Button type='primary ' size='middle' icon={<CloseOutlined/>} onClick={()=>setaddStockModal(false)} danger loading={Loading}> Vazgeç</Button>
                            </Col>
                            <Col >
                                <Button type='primary ' htmlType='submit' size='middle' icon={<CheckOutlined/>} loading={Loading}> Kaydet</Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </Wrapper>
         

        )
    }
 

export default connect(mapStateToProps)(ExpertiseDetail);
 
