import React from 'react';

import {connect} from 'react-redux';
import mapStateToProps from '../redux/mapStateToProps';

class SideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <React.Fragment>
                <div id="sidebar-nav" className="sidebar">
                    <div className="sidebar-scroll">
                        <nav>
                            <ul className="nav">
                                <li><a href="/" className="active"><i className="fas fa-home"></i>
                                    <span>Dashboard</span></a></li>
                                <li>
                                    <a href="#musteriKartlari" data-toggle="collapse" className="collapsed"><i
                                        className="fas fa-users"></i> <span>Müşteri Kartları</span> <i
                                        className="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="musteriKartlari" className="collapse ">
                                        <ul className="nav">
                                            <li><a href="/Customer/Add"><i
                                                className="fas fa-plus"></i><span>Oluştur</span></a></li>
                                            <li><a href="/Customer/List"><i className="fas fa-list"></i><span>Müşteri Listesi</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <a href="#randevular" data-toggle="collapse" className="collapsed"><i
                                        className="far fa-list-alt"></i> <span>Randevular</span> <i
                                        className="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="randevular" className="collapse ">
                                        <ul className="nav">
                                            <li><a href="/ServiceApp/Add/0"><i className="fas fa-plus"></i><span>Oluştur</span></a></li>
                                            <li><a href="/ServiceApp/List"><i className="fas fa-list"></i><span>Listele</span></a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <a href="#feedback" data-toggle="collapse" className="collapsed"><i
                                        className="fas fa-book"></i> <span>MIY Başvurular</span> <i
                                        className="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="feedback" className="collapse ">
                                        <ul className="nav">
                                            <li><a href="/feedback/List"><i className="fas fa-list"></i><span>Başvuru Listesi</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <a href="#webform" data-toggle="collapse" className="collapsed"><i
                                        className="fas fa-book"></i> <span>Web Formlar</span> <i
                                        className="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="webform" className="collapse ">
                                        <ul className="nav">
                                            <li><a href="/webform/list/1"><i className="fab fa-wpforms"></i><span>Sıfır Satış</span></a></li>
                                            <li><a href="/webform/list/2"><i className="fab fa-wpforms"></i><span>İkinci El</span></a></li>
                                            <li><a href="/webform/list/3"><i className="fab fa-wpforms"></i><span>Sigorta</span></a></li>
                                            <li><a href="/webform/list/4"><i className="fab fa-wpforms"></i><span>Servis Randevu</span></a></li>
                                         </ul>
                                    </div>
                                </li>

                                <li>
                                    <a href="#interview" data-toggle="collapse" className="collapsed"><i
                                        className="fas fa-book"></i> <span>Görüşme Notları</span> <i
                                        className="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="interview" className="collapse ">
                                        <ul className="nav">
                                            <li><a href="/interview/Add/0"><i className="fas fa-user-plus"></i><span>Görüşme Oluştur</span></a></li>
                                            <li><a href="/interview/List/1"><i className="fas fa-list"></i><span>Sıfır Satış</span></a></li>
                                            <li><a href="/interview/List/2"><i className="fas fa-list"></i><span>İkinci El</span></a></li>
                                            <li><a href="/interview/List/3"><i className="fas fa-list"></i><span>Sigorta</span></a></li>
                                            <li><a href="/interview/List/4"><i className="fas fa-list"></i><span>Filo Kiralama</span></a></li>
                                            <li><a href="/interview/List/5"><i className="fas fa-list"></i><span>Servis Hasar</span></a></li>
                                            <li><a href="/interview/List/6"><i className="fas fa-list"></i><span>Servis Mekanik</span></a></li>
                                            <li><a href="/interview/List/7"><i className="fas fa-list"></i><span>Müşteri İlişkileri</span></a></li>
                                            <li><a href="/interview/List/8"><i className="fas fa-list"></i><span>Yedek Parça</span></a></li>
                                            {/*<li><a href="/interview/List"><i className="fas fa-list"></i><span>Görüşme Listesi</span></a></li>*/}
                                            <li><a href="/interview/List/All"><i className="fas fa-list"></i><span>Tüm Görüşmelerim</span></a></li>
                                            <li><a href="/interview/List/AllOpen"><i className="fas fa-list"></i><span>Açık Görüşmelerim</span></a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <a href="#sale" data-toggle="collapse" className="collapsed"><i
                                        className="fas fa-car-side"></i> <span>Sıfır Satış</span> <i
                                        className="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="sale" className="collapse ">
                                        <ul className="nav">
                                            <li><a href="/stock/list"><i className="fas fa-list"></i><span>Stok Yönetimi</span></a>
                                            </li>
                                            <li><a href="/orderform/list"><i className="fas fa-list"></i><span>Satış Dosyası</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <a href="#sale2" data-toggle="collapse" className="collapsed"><i
                                        className="fas fa-car-side"></i> <span>İkinci El</span> <i
                                        className="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="sale2" className="collapse ">
                                        <ul className="nav">
                                            <li><a href="/expertise/list"><i className="fas fa-list"></i><span>Ekspertiz Listesi</span></a>
                                            </li>
                                        </ul>
                                        <ul className="nav">
                                            <li><a href="/usedcars/list"><i className="fas fa-list"></i><span>Stok Listesi</span></a>
                                            </li>
                                        </ul> 
                                    </div>
                                </li>
                                <li>
                                    <a href="#finance" data-toggle="collapse" className="collapsed">
                                    
                                    <i className="fas fa-donate"></i>
                                     <span>Muhasebe - Finans </span> <i
                                        className="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="finance" className="collapse ">
                                        <ul className="nav">
                                            <li>  <a href="/bank/bankcacclist"><i className="fas fa-list"></i><span>Bankalar</span></a>  </li>
                                        </ul>
                                        <ul className="nav">
                                            <li>  <a href="/check/list"><i className="fas fa-list"></i><span>Çekler</span></a> </li>
                                        </ul>
                                        <ul className="nav">
                                            <li> <a href="/financialcost/list"><i className="fas fa-list"></i><span>Giderler</span></a>  </li>
                                        </ul>
                                        <ul className="nav">
                                            <li> <a href="/financialcost/list"><i className="fas fa-list"></i><span>Aylık Gider</span></a>  </li>
                                        </ul>
                                        <ul className="nav">
                                            <li> <a href="/costgroup/list"><i className="fas fa-list"></i><span>Gider Grupları</span></a>  </li>
                                        </ul>
                                        <ul className="nav">
                                            <li>  <a href="/credit/list"><i className="fas fa-list"></i><span>Krediler</span></a> </li>
                                        </ul>
                                        <ul className="nav">
                                            <li>  <a href="/debits/list"><i className="fas fa-list"></i><span>Tahsilatlar</span></a> </li>
                                        </ul>
                                        <ul className="nav">
                                            <li>  <a href="/financepayment/list"><i className="fas fa-list"></i><span>Ödemeler</span></a> </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <a href="#insurance" data-toggle="collapse" className="collapsed">
                                    <i className="fas fa-house-damage"></i>
                                       <span>Sigorta</span> <i
                                        className="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="insurance" className="collapse ">
                                        <ul className="nav">
                                            <li>
                                                <a href="/insurance/insurancepolupload"><i className="fas fa-list"></i><span>Toplu Yükleme</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <a href="#reports" data-toggle="collapse" className="collapsed"><i
                                        className="fas fa-file-contract"></i> <span>Raporlar</span> <i
                                        className="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="reports" className="collapse ">
                                        <ul className="nav">
                                            <li><a href="/reports/crmreport"><i className="fas fa-file-contract"></i><span>Müşteri İlişkileri</span></a>
                                            </li>
                                            <li><a href="/reports/salereport"><i className="fas fa-file-contract"></i><span>Sıfır Satış</span></a>
                                            </li>
                                            <li><a href="/reports/ReportCrmT1Forward"><i className="fas fa-file-contract"></i><span>Sıfır Satış Yönlendirme</span></a>
                                            </li> 
                                            <li><a href="/reports/reception"><i className="fas fa-file-contract"></i><span>Resepsiyon Takip</span></a>
                                            </li>
                                            <li><a href="/reports/phonelist"><i className="fas fa-file-contract"></i><span>Santral Telefon</span></a>
                                            </li>
                                            <li><a href="/reports/serviceapp"><i className="fas fa-file-contract"></i><span>Günlük Randevular</span></a>
                                            </li>
                                            <li><a href="/reports/reportserviceappmonthly"><i className="fas fa-file-contract"></i><span>Aylık Randevular</span></a>
                                            </li>
                                            <li><a href="/reports/reportcrmt1"><i className="fas fa-file-contract"></i><span>Sıfır Satış Raporlar</span></a>
                                            </li>
                                            <li><a href="/reports/reportcrmt2"><i className="fas fa-file-contract"></i><span>İkinci El Raporlar</span></a>
                                            </li>
                                            <li><a href="/reports/reportcrmt3"><i className="fas fa-file-contract"></i><span>Sigorta Raporlar</span></a>
                                            </li>
                                            <li><a href="/reports/reportcrmt3s7"><i className="fas fa-file-contract"></i><span>Sigorta Yenileme</span></a>
                                            </li>

                                        </ul>
                                    </div>
                                </li>

                                <li>
                                    <a href="#transfer" data-toggle="collapse" className="collapsed"><i
                                        className="fas fa-file-excel"></i> <span>Aktarımlar</span> <i
                                        className="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="transfer" className="collapse ">
                                        <ul className="nav">
                                            <li><a href="/transfer/crmall"><span>Görüşme Notları İndir</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
 
                                <li>
                                    <a href="#task" data-toggle="collapse" className="collapsed"><i
                                        className="fas fa-tasks"></i> <span>Görevler </span> <i
                                        className="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="task" className="collapse ">
                                        <ul className="nav">
                                            {this.props.id_com_user_type==='2'||this.props.id_com_user_type==='4'||this.props.id_com_user_type==='1'?
                                            <>
                                            <li><a href="/task/list"><i className="fas fa-list"></i><span>Görev Listesi</span></a></li>
                                            <li><a href="/task/manager"><i className="fas fa-list"></i><span>Görev Listesi(S)</span></a></li>
                                            </>
                                            :<></>
                                            }
                                            <li><a href="/task/list_personel"><i className="fas fa-list"></i><span>Görevlerim</span></a></li>

                                        </ul>
                                    </div>
                                </li>
                                {/* <li>
                                    <a href="#yedekParca" data-toggle="collapse" className="collapsed"><i
                                        className="fas fa-cogs"></i> <span>Yedek Parça</span> <i
                                        className="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="yedekParca" className="collapse ">
                                        <ul className="nav">
                                            <li><a href="/ProductSearch"><i className="fas fa-search"></i><span>Parça Sorgula</span></a>
                                            </li>
                                            <li><a href="/DepotStore"><i
                                                className="fas fa-plus"></i><span>Depo Kabul</span></a></li>
                                            <li><a href="/DepotSale"><i
                                                className="fas fa-minus"></i><span>Depo Çıkış</span></a></li>
                                            <li><a href="/"><i className="fas fa-paperclip"></i><span>Teklif Oluştur</span></a></li>
                                            <li><a href="/"><i className="fas fa-cart-plus"></i><span>Sipariş Oluştur</span></a></li>
                                        </ul>
                                    </div>
                                </li> */}
                                {/* <li>
                                    <a href="#evrakGiris" data-toggle="collapse" className="collapsed"><i
                                        className="far fa-folder-open"></i> <span>Evraklar</span> <i
                                        className="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="evrakGiris" className="collapse ">
                                        <ul className="nav">
                                            <li><a href="/InvList"><i className="far fa-file-alt"></i><span>Fiş / Faturalar</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </li> */}
                                <li>
                                    <a href="#tanimlamalar" data-toggle="collapse" className="collapsed"><i
                                        className="far fa-flag"></i> <span>Tanımlamalar</span> <i
                                        className="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="tanimlamalar" className="collapse ">
                                        <ul className="nav">
                                            <li><a href="/brand">Markalar</a></li>
                                            <li><a href="/model">Modeller</a></li>
                                            <li><a href="/fuel">Yakıt Tipleri</a></li>
                                            <li><a href="/gear">Vites Tipleri</a></li>
                                            <li><a href="/color">Renk Seçenekleri</a></li>
                                            <li><a href="/job">Meslek</a></li>
                                            <li><a href="/sector">Sektör</a></li>
                                            <li><a href="/location">Lokasyonlar</a></li>
                                            <li><a href="/department">Departmanlar</a></li>
                                            <li><a href="/meet">Temas Tipleri</a></li>
                                            <li><a href="/interview-subject">Görüşme Notu Konuları</a></li>
                                            <li><a href="/interview-status">Görüşme Notu Durumları</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <a href="#users" data-toggle="collapse" className="collapsed"><i className="fas fa-users"></i> <span>Kullanıcılar</span> <i className="icon-submenu lnr lnr-chevron-left"></i></a>
                                    <div id="users" className="collapse ">
                                        <ul className="nav">
                                            <li><a href="/user">Kullanıcı Listesi</a></li>
                                            <li><a href="/user-type">Kullanıcı Grupları</a></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(SideBar);