

import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';


class fileUpload extends React.Component{

    constructor(props) {
        super(props);
        this.state = {}
        this._UploadDocument=this._UploadDocument.bind(this)

    }

    _UploadDocument(e){
        e.preventDefault();
        let d = new Date(); 
        // let date = d.toISOString();
        // let c_date  = date.split('T')[0];
        let image_url = this.props.api_url+'/fileUpload';
      //  console.log(e.target)
       const data = new FormData(e.target);  
       data.append('token', this.props.loginToken);
       data.append('id_com_user', this.props.id_com_user);
       // data.append('created', c_date);
       data.append('id_com_interview',this.props.id_com_interview );
       data.append('type','user' );
       data.append('status','0' );
       data.append('id_com_document_type',this.state.id_com_document_type );

       var urlencoded = new URLSearchParams();
       urlencoded = data;
    
       var requestOptions = {
           header: {'Content-Type': 'multipart/form-data'},
           method: 'POST',
           body: urlencoded,
       };
       fetch(image_url, requestOptions)
           .then(response => response.text())
          .then(result => { window.location.reload()})
           .catch(error => console.log(error));
    }



    handleChange(event,selectlist,name) {        
                if(selectlist) { 
                    this.setState({ [`${name}`]:event.id})
                } 
                else { 
        
                 if(event.target.type==='checkbox'){ 
                       event.target.checked?this.setState({[`${event.target.name}`]:'1'}):this.setState({[`${event.target.name}`]:'0'})
                                } else {
                    const value = event.target.value;
                    const key = event.target.name;
                    this.setState({ [`${key}`]:value})
                 } }
            }
    render(){
        return(
            <React.Fragment>

            <a className={!this.state.clicked?"btn btn-primary btn-lg btn-block":"btn btn-success btn-lg btn-block"}
            data-toggle="collapse" 
            href={`#${this.props.id}`}
            role="button" 
            aria-expanded="false" 
            aria-controls={this.props.id} 
            // style={{width: '100%'}}
            onClick={()=>this.setState({clicked:!this.state.clicked})}>
 <i className={!this.state.clicked?"fas fa-plus-circle pull-left":"fas fa-minus-circle pull-left"}  > </i>   Dosya Yükle  </a>
 <div className="collapse" id={this.props.id}>
        <div className="panel-body">                  
              <form className="form-check" onSubmit={this._UploadDocument}>
                <input   className="form-check-input btn btn-primary" type="file" name='doc'  placeholder="Dosya Seçin"/>
                <SelectList table='com_document_type'   label={'Dosya Tipi'} name={'id_com_document_type'} onChange={(x)=>this.handleChange(x)} />
                <div className="form-group" >
                        <label>Açıklama : </label>
                        <textarea type="text" style={{height:50}} row='10' className="form-control" name="comment" onChange={(x) => this.handleChange(x)} />
                </div>
                <button  disabled={this.state.id_com_document_type?false:true} className="btn btn-success"  type="submit" tabIndex="-1">Foto Yukle</button>
              </form>
          </div>
          </div>

          </React.Fragment>

        )
    }
     
}

export default connect(mapStateToProps)(fileUpload);
