import React, {Component} from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import {alertMessage, dateTime, datePrint} from '../../assets.js';
import Options from "./Options";
import Option from "./Option";
class PollQuestionsAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: false,
            data: [],
            options: [],
            id_com_poll_questions: '',
            actionResult: null
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.optionsSubmit = this.optionsSubmit.bind(this);
    }
    optionAddRow = (e) => {
        let options = this.state.options
        const option = {
            option: '',
            value: ''
        }
        options = options.concat(option)
        this.setState({
            options: options
        })
        console.log(options)
    }
    optionRemoveRow = (key) => {
        console.log(key)
        let options = this.state.options
        this.setState(state => {
            options.splice(key, 1);
            return {
                options
            }
        })
        console.log(this.state.options)
    }
    optionChange = (optKey,e) => {
        const target = e.target;
        const value = target.value;
        const name = target.id;
        this.setState(state => {
            const newData = state.options[optKey][name] = value;
            return {
                newData
            }
        })
    }
    async optionsSubmit (option,id_com_poll_questions) {
        let data =
            'token=' + this.props.loginToken +
            '&option=' + option.option +
            '&value=' + option.value +
            '&id_com_poll_questions=' + id_com_poll_questions +
            '&id_com_user='+this.props.id_com_user+
            '&created=' + dateTime();
        fetch(this.props.api_url + "/insert/com_poll_options", {
            method:'POST',
            cahce: 'no-cache',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body:data,
        })
            .then(response => response.json())
            .then(result =>
            {
                console.log(result)
                result.status === true ?
                    // this.setState({questionsInsert: true})
                    this.setState({actionResult: alertMessage('insertSuccess')})
                    :
                    this.setState({actionResult: alertMessage('insertError')})
            })
            .catch(error => console.log('error', error));

    }
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState(state => {
            const newData = state.data[name] = value;
            return {
                newData
            };
        });
    }
    handleSubmit(event) {
        this.setState({actionResult: alertMessage('loadingData')});
        event.preventDefault();
        const data = new FormData(event.target);
        data.append('token', this.props.loginToken);
        data.append('id_com_user', this.props.id_com_user);
        data.append('created', dateTime());
        var urlencoded = new URLSearchParams();
        urlencoded = data;
        var requestOptions = {
            method: 'POST',
            body: urlencoded
        };
        fetch(this.props.api_url + "/insert/com_poll_questions", requestOptions)
            .then(response => response.json())
            .then(result =>
            {
                result.status === true ?
                    this.setState({id_com_poll_questions: result.id})
                //    this.setState({actionResult: alertMessage('insertSuccess')})
                :
                    this.setState({actionResult: alertMessage('insertError')})
            })
            .catch(error => console.log('error', error));

        if(this.state.id_com_poll_questions != '' && this.state.options.length > 0)
            this.state.options.forEach(option => this.optionsSubmit(option,this.state.id_com_poll_questions));
    }
    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3 className="page-title">Müşteri İlişkileri Anketleri</h3>
                                </div>
                                <div className="col-md-6 text-right">
                                    <a href="/poll" className="btn btn-default">Anket Listesi</a>
                                </div>
                                {this.state.actionResult ?
                                    <div className="col-md-12" dangerouslySetInnerHTML={{__html: this.state.actionResult}}></div>
                                    :
                                    null
                                }
                                <div className="col-md-12">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="panel">
                                            <div className="panel-heading">
                                                <div className="panel-title">Soru Ekle</div>
                                            </div>
                                            <div className="panel-body">
                                                <div className="form-group">
                                                    <label>Soru</label>
                                                    <input type="text" className="form-control" name="question" onChange={(e) => this.handleChange(e)} placeholder="Soru" required/>
                                                </div>
                                            </div>
                                            <div className="panel-footer"></div>
                                        </div>
                                        <div className={"panel"}>
                                            <div className={"panel-heading"}>
                                                <div className={"panel-title"}>Yanıtlar</div>
                                            </div>
                                            <div className={"panel-body"}>
                                                {
                                                    this.state.options.length > 0?
                                                        <Options options={this.state.options} optionChange={this.optionChange} optionRemoveRow={this.optionRemoveRow} />
                                                    :
                                                        null
                                                }
                                                <div className={"form-group"}>
                                                    <button type={"button"} className={"btn btn-primary btn-sm"} onClick={this.optionAddRow}>Yanıt Ekle</button>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn btn-danger">Kaydet</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default connect(mapStateToProps)(PollQuestionsAdd);