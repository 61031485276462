import React from 'react';
import {Row,Col,PageHeader} from 'antd'
const Wrapper = ({children,title,extra,subtitle})=>{

      
    return ( 

        <React.Fragment>
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <Row>
                                        <Col span={24}>
                                            <PageHeader title={title}  subTitle={subtitle}
                                                extra={extra}
                                            />  
                                        </Col> 
                                    </Row>
                                    {children}
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>   


    )
}

export default Wrapper; 

