import React,{useState,useEffect} from 'react';
import {  Col, Row, Spin,Typography,Button,Divider,Empty,Menu,Dropdown ,Modal,Tabs,Form,PageHeader ,Input,DatePicker} from 'antd'; 
import DataTable from 'react-data-table-component';
import  { RightOutlined ,DownOutlined, EditOutlined, CloseCircleOutlined  } from '@ant-design/icons';
 import {connect} from 'react-redux';
import * as CurrencyFormat from 'react-currency-format';
 import SelectList from '../../../components/utilities/SelectList'
 import mapStateToProps from '../../../redux/mapStateToProps';

import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import moment from 'moment'

const { Title ,Text} = Typography;
const { TabPane} = Tabs;


class CreditList extends React.Component {

    constructor(props){
        super(props);
    this.state={
        SearchItem:'',
        modal_visible:false,
        modal_visible_edit:false,
      data:[],
      columns:[
 
          {
            name:'Banka',
            selector:'id_com_bank_name',
            wrap:true,
            left:true,
            width:'12em'
          },
          {
            name:'Sözleşme No',
            selector:'credit_number',
            left:true,
            wrap:true,
            width:'8em'
        },
          {
            name:'Kredi Tipi',
            selector:'id_com_credit_type_name',
            left:true,
            wrap:true,
             width:'8em'
        },
        {
          name:'Açıklama',
          selector:'comment',
          left:true,
          wrap:true,
          width:'12em'
      },
        {
            name:'Başlangıç',
            selector:'start_date',
            center:true,
            cell : row=>moment(row.start_date).format('DD.MM.YYYY')
        },
        {
          name:'Bitiş',
          selector:'end_date',
          center:true,
          cell : row=>moment(row.end_date).format('DD.MM.YYYY')
      },
        {
            name:'Ana Para',
            selector:'amount',
            right:true,
            cell:(row)=>
             <CurrencyFormat value={Math.round(parseFloat(row.amount)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' '+row.id_com_currency_name}/>
        },
        {
            name:'Faiz Oranı (Aylık)',
            selector:'interest',
            right:true,
        },
        {
            name:'Vade',
            selector:'period',
            right:true,
        },
 
        {
            name:'Kalan Taksit',
            selector:'remaining_period',
            right:true,
        },
       
        {
          name:'Kalan Taksit Tutarı',
          selector:'remaining_payment',
          right:true,
          cell:(row)=>
           <CurrencyFormat value={Math.round(parseFloat(row.remaining_payment)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' '+row.id_com_currency_name}/>
      },

        {
        name: 'Detay',
        right:'true',
        cell:(row)=>  
        <Dropdown overlay={
            <Menu >
            <Menu.Item key="1" >
            <Button     size='small' href={`/creditsdetail/${row.id}`} style={{textAlign:"right"}}>Detay<RightOutlined /> </Button> 

            </Menu.Item>
            <Menu.Item key="2"     >
              <Button     size='small' onClick={()=>this.setState({modal_visible:true,selected_id:row.id})}  block danger type='primary' shape='round'  style={{textAlign:"right"}}>Sil<CloseCircleOutlined /> </Button> 
            </Menu.Item>
            <Menu.Item key="3"  >
              <Button   size='small' onClick={()=>this.setState({modal_visible_edit:true,selected_id:row.id})}  block type='primary' shape='round'  style={{textAlign:"right"}} >Güncelle<EditOutlined /> </Button> 
            </Menu.Item>
        </Menu>
         }
         
         
         >
            <Button  shape="round" size='small'>
                İşlemler <DownOutlined />
            </Button>
            </Dropdown>
        
        
        }    
      ],
      filtered_data:[],

    }
    this._Filter_Data=this._Filter_Data.bind(this);
}

componentDidMount(){
    let select_url=this.props.api_url+'/selectRows/view_credits';
    fetch(select_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body:  'token='+this.props.loginToken+'&type=user'
        })
        .catch(error => console.log(error))
        .then(res=>res.json())
        .then(result=>{
            this.setState({data:result,pageloader:true})
        })

}


_Filter_Data(value) { 
    this.setState({SearchItem:value})
    let match_str = new RegExp('('+value+')','i');
    let data = this.state.data;
    let filtered_data = data.filter((row)=>{
      if(this.state.SearchItem===''){ 
          return row
      }     else  {
      return row.id_com_bank_name.match(match_str)||row.id_com_credit_type_name.match(match_str) ||row.start_date.match(match_str) ||row.id_com_currency_name.match(match_str) ||row.period.match(match_str)||row.credit_number.match(match_str)
      }})
       this.setState({filtered_data:filtered_data})
  
   
  }

render(){
    const rowStyle = {
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
        padding:20
      };
      const colStyle = {
        marginBottom: '16px',
      };
      const gutter = 16;
    
        return (
          <div className="main">
          <div className="main-content">
              <div className="container-fluid">
                  <div className="row">
                  <div className="col-md-12">
                                    <div className="panel">
                      <Row style={rowStyle} gutter={gutter} justify="start">
                          <Col md={24} sm={24} xs={24} xl={24} lg={24} style={{marginBottom: '5px',}}>
                              <PageHeader title={'Krediler  |' }  subTitle='Liste'
                              extra={[
                                <Button href='/credit/add' type="primary" shape='round' size='small' style={{margin:5}}>Kredi Ekle</Button>
                              ]}
                              />  
                          </Col>
                          <Col md={24} sm={24} xs={24} style={colStyle}>
                            <Input value={this.state.SearchItem} type="search" placeholder="Banka Adı /  Kredi Tipi  /  Vade /  Tarih  /  Sözleşme No ile arama" onChange={(x)=>this._Filter_Data(x.target.value)}/>
                          <Divider></Divider>
                          <Tabs   type="card" style={{marginTop:10}}>
                                <TabPane tab="Aktif Krediler" key="1">
                                <DataTable
                                dense 
                                noHeader
                                highlightOnHover    
                                selectableRowsHighlight	
                                pagination
                                paginationPerPage={10}
                                onRowClicked={(data)=>window.location.href='/creditsdetail/'+data.id}
                                paginationComponentOptions={ {rowsPerPageText: 'Sayfa Başına Satır Sayısı:', rangeSeparatorText: ' -', noRowsPerPage: false, selectAllRowsItem: true, selectAllRowsItemText: 'Tümü' }}
                                columns={this.state.columns}
                                data=  {this.state.SearchItem===''?this.state.data.filter(row=>parseFloat(row.remaining_period)>0) :this.state.filtered_data.filter(row=>parseFloat(row.remaining_period)>0)}
                                progressPending={!this.state.pageloader}
                                progressComponent={<Spin size='large' style={{margin:20}}/>}
                                sortIcon={<DownOutlined />}
                                noDataComponent={  <Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{  height: 60,  }} description={ <span>  Kredi Bulunamamıştır.  </span> }  > <Button href='/credit/add' type="primary" shape='round' size='small' >Kredi Ekle</Button></Empty>  }
                                />
                              </TabPane>
                              <TabPane tab="Pasif Krediler" key="2">
                              <DataTable
                                dense 
                                noHeader
                                highlightOnHover    
                                selectableRowsHighlight	
                                pagination
                                paginationPerPage={10}
                                onRowClicked={(data)=>window.location.href='/creditsdetail/'+data.id}
                                paginationComponentOptions={ {rowsPerPageText: 'Sayfa Başına Satır Sayısı:', rangeSeparatorText: ' -', noRowsPerPage: false, selectAllRowsItem: true, selectAllRowsItemText: 'Tümü' }}
                                columns={this.state.columns}
                                data=  {this.state.SearchItem===''?this.state.data.filter(row=>parseFloat(row.remaining_period)===0) :this.state.filtered_data.filter(row=>parseFloat(row.remaining_period)===0)}
                                progressPending={!this.state.pageloader}
                                progressComponent={<Spin size='large' style={{margin:20}}/>}
                                sortIcon={<DownOutlined />}
                                noDataComponent={  <Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{  height: 60,  }} description={ <span>  Kredi Bulunamamıştır.  </span> }  > <Button href='/credit/add' type="primary" shape='round' size='small' >Kredi Ekle</Button></Empty>  }
                                />
                              </TabPane>
                            </Tabs>

                            </Col>

                            <DeleteModal 
            close={()=>this.setState({modal_visible:false,selected_id:''})} 
            selected_id={this.state.selected_id}
            api_url={this.props.api_url}
            modal_visible={this.state.modal_visible} 
            id={this.state.selected_id}
             loginToken={this.props.loginToken}/>

            <EditModal 
            close={()=>this.setState({modal_visible_edit:false,selected_id:''})} 
            api_url={this.props.api_url}
            id_com_user={this.props.id_com_user} 
            modal_visible={this.state.modal_visible_edit} 
            id={this.state.selected_id}
             loginToken={this.props.loginToken}/>

                          </Row>
                          
</div>
 </div>
</div>
</div>
</div>
</div>
    
  )
  }
}


export default connect(mapStateToProps)(CreditList);


class DeleteModal extends React.Component{
    constructor ( props ) { 
        super(props);
        this.state={}
        this.handleOk=this.handleOk.bind(this)
    }


    handleChange(event,selectlist,name) {
        if(selectlist) { 
            this.setState({ [`${name}`]:event})
        } else { 
            const value = event.target.value;
            const key = event.target.name;
            this.setState({ [`${key}`]:value})
        }
      }


  handleOk(){
    let sel_credit_body=this.props.api_url+'/selectRows/com_credits_line';
    let del_credit_body=this.props.api_url+'/delete/com_credits';
    let del_credit_line=this.props.api_url+'/delete/com_credits_line';

    console.log('id_com_credits='+this.props.selected_id+'&token='+this.props.loginToken+'&type=user');

    try{
    fetch(sel_credit_body,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body:'id_com_credits='+this.props.selected_id+'&token='+this.props.loginToken+'&type=user',
        })
        .catch(error=>console.log(error)).then(x=>x.json()).then(res=>{
            res.map(id=>{
                fetch(del_credit_line,{
                    method:'POST',
                    cahce: 'no-cache',
                    headers:{ 
                      'Content-Type':'application/x-www-form-urlencoded'
                    },
                  body:'id='+id+'&token='+this.props.loginToken+'&type=user',
                })
                .catch(error=>console.log(error))
                return null
            })
        })
    }
        catch(e){console.log(e)}
        finally {
            fetch(del_credit_body,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
              body:'id='+this.props.selected_id+'&token='+this.props.loginToken+'&type=user',
            })
            .catch(error=>console.log(error)).then(x=>x.json()).then(res=>{
                    window.location.reload();
            })
        }
            
}
  


    render(){
        return ( 
            <Modal
            visible={this.props.modal_visible}
            title="UYARI - Silme İşlemi"
            onOk={this.props.close}
            onCancel={this.props.close}
            footer={[
              <Button key="back" onClick={this.props.close}>
                Hayır
              </Button>,
              <Button key="submit" 
              //disabled={this.state.payment_no&&this.state.paid_amount?false:true}
              type="primary" onClick={()=>this.handleOk()}>
                Evet
              </Button>,
            ]}
          >
    <Title level={4} type="danger" style={{textAlign:"center"}}>Kredi Kaydını Tamamen Silmek İstediğinizden Emin misiniz ?</Title>
          </Modal>
        )
    }
}




const  EditModal = ({api_url,loginToken,id,modal_visible,close,id_com_user})=>{
 
 const [Editform] = Form.useForm(); 
  useEffect(() => {
    const Onload=()=> {
      _Update()
    }
    return Onload();
  }, [id])

    

  const _Update =  async () => {
 if(id!==''){

   let com_credits_line_url=api_url+'/selectRows/com_credits';
      const data = await (await (fetch(com_credits_line_url,{
              method:'POST',
              cahce: 'no-cache',
              headers:{ 
                'Content-Type':'application/x-www-form-urlencoded'
              },
            body: 'token='+loginToken+'&id='+id+'&type=user',
          }))).json();

 
          let initale_values = {}
  if(data.length>0) {

     for (let [key, value] of Object.entries(data[0])) {

                      if(key==='end_date'||key==='start_date') { 
                        Object.assign(initale_values,{ [`${key}`]:moment(value)})
                      } else { 
                        Object.assign(initale_values,{ [`${key}`]:value})
                      }
                  }
            }

            Editform.setFieldsValue(initale_values)
       }
  } 


const handleOk = () =>{

  const form_data = Editform.getFieldsValue();
 
  let d = new Date(); 
  let m_date  =moment(d).format('YYYY-MM-DD');
  let m_time = d.toLocaleTimeString('tr-TR')

  let update_body = 
  'token='+loginToken+
  '&id='+id+
  '&m_id_com_user='+id_com_user+
  '&m_date='+m_date+
  '&m_time='+m_time+
  '&type=user'



  for (let [key,value] of Object.entries(form_data)) {
    if  (key==='end_date'||key==='start_date') {
      update_body +=`&${key}=${moment(value).format('YYYY-MM-DD')}`
   } else { 
      update_body +=`&${key}=${value}`
   }
}
 

 let com_credits_line_url=api_url+'/update/com_credits';
fetch(com_credits_line_url,{
      method:'POST',
      cahce: 'no-cache',
      headers:{ 
        'Content-Type':'application/x-www-form-urlencoded'
      },
    body:update_body,
  })
  .catch(error=>console.log(error)).then(x=>{ 
  window.location.reload()
})

}  


      return ( 
          <Modal
          visible={modal_visible}
          title={"Taksit Detayı  : "+id}
          onOk={close}
          onCancel={close}
          footer={[
            <Button key="back" onClick={close}>  Kapat   </Button>,
            <Button key="submit"     type="primary" onClick={handleOk}>      Güncelle </Button>,
          ]}
        >
 
          <Form form={Editform} colon labelCol={{span:8}} labelAlign='left' >
              <Form.Item    name={'id_com_bank'}    label={'Banka Seçiniz'}      >
                <SelectList  table={'com_bank'}  />
              </Form.Item>
              <Form.Item    name={'id_com_credit_type'}    label={'Kredi Tipi Seçiniz'}     >
                <SelectList   table={'com_credit_type'} />
              </Form.Item>
              <Form.Item    name={'id_com_credit_payment_type'}    label={'Ödeme Tipi Seçiniz'}     >
                <SelectList   table={'com_credit_payment_type'} />
              </Form.Item>
              <Form.Item    name={'id_com_currency'}    label='Kredi Para Birimi'      >
                <SelectList   table={'com_currency'} />
              </Form.Item>
              <Form.Item    name={'credit_number'}    label='Kredi Sözleşme No'      >
                <Input size="small"     placeholder={'Kredi Sözleşme No Giriniz'}  />
              </Form.Item>
              <Form.Item    name={'amount'}      label='Kredi Tutarı'      >
                <Input size="small"    placeholder={'Kredi Tutarı Giriniz'}  />
              </Form.Item>
              <Form.Item      name={'interest'}      label='Faiz Oranı'      >
                <Input size="small"  placeholder={'Faiz Oranı Giriniz'}  />
              </Form.Item>
              {/* <Form.Item     name={'bsmv'}    label='BSMV'      >
                <Input size="small"    placeholder={'BSMV Giriniz'}  />
              </Form.Item>
              <Form.Item     name={'kkdf'}      label='KKDF'      >
                <Input size="small"   placeholder={'KKDF Giriniz'} />
              </Form.Item> */}
              <Form.Item     name={'commision'}     label='Banka Komisyonu'      >
                <Input size="small"   placeholder={'TBanka Komisyon Tutarı Giriniz'}  />
              </Form.Item>          
              <Form.Item     name='start_date'    label='Başlangıç Tarihi'      >
                <DatePicker   format='DD.MM.YYYY' style={{width:'100%'}} locale={locale} />
              </Form.Item>
              <Form.Item    name='end_date'    label='Bitiş Tarihi'     locale={locale}  >
                <DatePicker   format='DD.MM.YYYY' style={{width:'100%'}}/>
              </Form.Item>
              <Form.Item    name='comment'    label='Açıklama'   name='comment'   >
                  <Input.TextArea       rows={7}     placeholder={'Var ise notunuzu ekleyiniz.'} />
              </Form.Item>
          </Form>
         </Modal>
      )
  
}
