import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
import DatePicker from '../utilities/DatePicker';
class FeedBackEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_com_feedback: '',
            body: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(event, selectlist, name) {
        // console.log(event.target)
        const target = event.target;
        if (selectlist) {
            this.setState({[`${name}`]: event.id})
        } else {
            if (event.target.type === 'checkbox') {
                event.target.checked ? this.setState({[`${event.target.name}`]: '1'}) : this.setState({[`${event.target.name}`]: '0'})
            } else {
                const value = event.target.value;
                const key = event.target.name;
                // this.setState({[`${key}`]: value})
                this.setState(state => {
                    const newData = state.body[target.name] = value;
                    return {
                        newData
                    };
                });
            }
        }

    }
    handleSubmit() {
        let d = new Date();
        let date = d.toISOString();
        let m_date = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');
        let updateBody =
            'token=' + this.props.loginToken +
            '&m_date=' + m_date +
            '&m_time=' + m_time +
            '&m_id_com_user=' + this.props.id_com_user +
            '&comment=' + this.state.body.comment +
            '&id_com_feedback_type=' + this.state.body.id_com_feedback_type +
            '&id_com_importance=' + this.state.body.id_com_importance +
            '&id_com_meet=' + this.state.body.id_com_meet +
            '&dist_date=' + this.state.dist_date +
            '&dist_time=' + this.state.dist_time +
            '&id_com_cars=' + this.state.body.id_com_cars +
            '&id_com_feedback_subject1=' + this.state.body.id_com_feedback_subject1 +
            '&id_com_feedback_subject2=' + this.state.body.id_com_feedback_subject2 +
            '&id_com_feedback_subject3=' + this.state.body.id_com_feedback_subject3 +
            '&id_com_brand=' + this.state.body.id_com_brand +
            '&id_com_department=' + this.state.body.id_com_department +
            '&r_id_com_user1=' + this.state.body.r_id_com_user1 +
            '&r_id_com_user2=' + this.state.body.r_id_com_user2 +
            '&r_id_com_user3=' + this.state.body.r_id_com_user3 +
            '&id=' + this.state.body.id +
            '&type=user&id_com_company=1';
        let updateURL = this.props.api_url + '/update/com_feedback';
        fetch(updateURL, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: updateBody,
        })
            .catch(error => console.log(error)).then(e => e.json()).then(result => {
            if (result.status) {
                window.location.href = '/Feedback/Details/' + this.state.body.id
            }
        })
    }
    DatePick(value, type) {
        switch (type) {
            case 'date':
                let newdate = new Date(value)
                newdate.setDate(newdate.getDate())
                let dist_date = newdate.toISOString().split('T')[0]
                this.setState({dist_date: dist_date})
                return null
            case 'time':
                this.setState({dist_time: value.target.value})
                return null
            default:
                return null
        }
    }
    componentDidMount() {
        let id_com_feedback = this.props.match.params.id_com_feedback;
        let body_url = this.props.api_url + '/selectRow/view_feedback';
        fetch(body_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'token=' + this.props.loginToken + '&id=' + id_com_feedback + '&type=user',
        })
            .catch(error => console.log(error)).then(res => res.json()).then(result => {
            // console.log(result)
            this.setState({
                body:result,
                pageloader: true,
                id_com_feedback: id_com_feedback,
                id_com_feedback_status: result.id_com_feedback_status
            })
        })
    }
    render() {
        return (
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="page-title">Başvuru Güncelle</h3>
                            </div>
                            <div className="col-md-12">
                                {this.state.body?
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h3 className="panel-title">Müşteri Bilgileri</h3>
                                        </div>
                                        <div className={"panel-body"}>
                                            <table className={"table table-bordered"}>
                                                <thead>
                                                <tr>
                                                    <th>Müşteri Adı</th>
                                                    <th width={200}>Telefon</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{this.state.body.id_com_customer_name}</td>
                                                    <td>{this.state.body.phone1}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div className={"form-group"}>
                                                <label>Başvuru Metni</label>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    name="comment"
                                                    value={this.state.body.comment || ''}
                                                    rows={10}
                                                    onChange={(x) => this.handleChange(x)}
                                                    placeholder="Başlık..." required
                                                />
                                            </div>
                                            <SelectList
                                                table='com_feedback_type'
                                                label={'Başvuru Tipi'}
                                                name={'id_com_feedback_type'}
                                                value={this.state.body.id_com_feedback_type}
                                                onChange={(x) => this.handleChange(x)}
                                            />
                                            <SelectList
                                                table='com_importance'
                                                label={'Önem Derecesi'}
                                                name={'id_com_importance'}
                                                value={this.state.body.id_com_importance}
                                                onChange={(x) => this.handleChange(x)}
                                            />
                                            <SelectList
                                                table='com_meet'
                                                label={'Temas Türü'}
                                                name={'id_com_meet'}
                                                value={this.state.body.id_com_meet}
                                                onChange={(x) => this.handleChange(x)}
                                            />
                                            {this.state.body.id_com_meet === '15' ?
                                                <React.Fragment>
                                                    <div className="form-group">
                                                        <label>Distributor Aktarım Tarihi</label>
                                                        <DatePicker onChange={(x) => this.DatePick(x, 'date')} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Distributor Aktarım Saati</label>
                                                        <input type='time' className={'form-control'} onChange={(x) => this.DatePick(x, 'time')} />
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <></>
                                            }
                                            <SelectList
                                                table='view_cars_sl'
                                                id_com_customer={this.state.body.id_com_customer}
                                                label={'Araç'}
                                                name={'id_com_cars'}
                                                value={this.state.body.id_com_cars || ''}
                                                onChange={(x) => this.handleChange(x)}
                                            />
                                            <SelectList
                                                table='com_feedback_subject'
                                                label={'Konu 1'}
                                                name={'id_com_feedback_subject1'}
                                                value={this.state.body.id_com_feedback_subject1}
                                                onChange={(x) => this.handleChange(x)}
                                            />
                                            <SelectList
                                                table='com_feedback_subject'
                                                label={'Konu 2'}
                                                name={'id_com_feedback_subject2'}
                                                value={this.state.body.id_com_feedback_subject2 || ''}
                                                onChange={(x) => this.handleChange(x)}
                                            />
                                            <SelectList
                                                table='com_feedback_subject'
                                                label={'Konu 3'}
                                                name={'id_com_feedback_subject3'}
                                                value={this.state.body.id_com_feedback_subject3 || ''}
                                                onChange={(x) => this.handleChange(x)}
                                            />
                                            <SelectList
                                                table='view_brand_sl'
                                                label={'Marka'}
                                                name={'id_com_brand'}
                                                value={this.state.body.id_com_brand || ''}
                                                onChange={(x) => this.handleChange(x)}
                                            />
                                            <SelectList
                                                table='com_department'
                                                label={'Departman'}
                                                name={'id_com_department'}
                                                value={this.state.body.id_com_department || ''}
                                                onChange={(x) => this.handleChange(x)}
                                            />
                                            <SelectList
                                                table='view_user_sl'
                                                label={'Atanan Yetkili 1'}
                                                name={'r_id_com_user1'}
                                                id_com_brand={this.state.body.id_com_brand}
                                                id_com_department={this.state.body.id_com_department}
                                                value={this.state.body.r_id_com_user1 || ''}
                                                onChange={(x) => this.handleChange(x)}
                                            />
                                            <SelectList
                                                table='view_user_sl'
                                                label={'Atanan Yetkili 2'}
                                                name={'r_id_com_user2'}
                                                id_com_brand={this.state.body.id_com_brand}
                                                id_com_department={this.state.body.id_com_department}
                                                value={this.state.body.r_id_com_user2 || ''}
                                                onChange={(x) => this.handleChange(x)}
                                            />
                                            <SelectList
                                                table='view_user_sl'
                                                label={'Atanan Yetkili 3'}
                                                name={'r_id_com_user3'}
                                                id_com_brand={this.state.body.id_com_brand}
                                                id_com_department={this.state.body.id_com_department}
                                                value={this.state.body.r_id_com_user3 || ''}
                                                onChange={(x) => this.handleChange(x)}
                                            />
                                        </div>
                                        <div className={"panel-footer"}>
                                            <a className="btn btn-danger" href="/Feedback/List">İptal</a>
                                            {this.props.id_com_user == this.state.body.c_id_com_user?
                                                <button className="btn btn-primary pull-right" onClick={() => this.handleSubmit()}>Güncelle</button>
                                            :
                                                <button className="btn btn-primary pull-right" disabled={true}>Güncelle</button>
                                            }
                                        </div>
                                    </div>
                                :
                                    <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(mapStateToProps)(FeedBackEdit);