export function datePrint(value) {
    if (value){
        var dateTime = '';
        var result = '';
        var date = '';
        dateTime = value.split(' ');
        if (dateTime.length > 1) {
            date = dateTime[0].split('-');
            result = date[2] + '.' + date[1] + '.' + date[0] + ' ' + dateTime[1];
        } else {
            date = dateTime[0].split('-');
            result = date[2] + '.' + date[1] + '.' + date[0];
        }
        return result;
    }
}
export function dateSql(value) {
    var result = '';
    var date = '';
        date = value.split('.');
        result = date[2] + '-' + date[1] + '-' + date[0];
    return result;
}

export function dateTime() {
    var fullDate = new Date();
    var result = fullDate.getFullYear() + '-' + fullDate.getMonth() + '-' + fullDate.getDate() + ' ' + fullDate.getHours() + ':' + fullDate.getMinutes() + ':' + fullDate.getSeconds();
    return result;
}

export function alertMessage(type) {
    var result = '';
    if (type === 'insertSuccess') {
        result = '<div class="alert alert-success text-center"><i class="fa fa-check fa-2x"></i><p class="lead">İşlem başarılı kayıt başarıyla eklenmiştir.</p></div>';
    } else if (type === 'insertError') {
        result = '<div class="alert alert-danger text-center"><i class="fa fa-times fa-2x"></i><p class="lead">Hata! İşlem başarısız kayıt eklenemedi. Lütfen tekrar deneyiniz.</p></div>';
    } else if (type === 'updateSuccess') {
        result = '<div class="alert alert-success text-center"><i class="fa fa-check fa-2x"></i><p class="lead">İşlem başarılı kayıt başarıyla güncellenmiştir.</p></div>';
    } else if (type === 'updateError') {
        result = '<div class="alert alert-danger text-center"><i class="fa fa-times fa-2x"></i><p class="lead">Hata! İşlem başarısız kayıt güncellenemedi. Lütfen tekrar deneyiniz.</p></div>';
    } else if (type === 'loadingData') {
        result = '<div class="alert alert-info text-center"><i class="fa fa-spin fa-spinner fa-2x"></i><p class="lead">Lütfen bekleyiniz</p></div>';
    }else if (type === 'requestConfirm') {
        result = '<div class="alert alert-success text-center"><i class="fa fa-check fa-2x"></i><p class="lead">Ekspertiz talebinin gönderilmesi için lütfen Onaylayınız.</p></div>';
    }
    else if (type ==='alertConfirm') {
        result = '<div class="alert alert-success text-center"><i class="fa fa-check fa-2x"></i><p class="lead">Hatırlatma notunuz kayıt edilmiştir.</p></div>';
    }else if (type === 'NoData') {
        result = '<div class="alert alert-danger text-center"><i class="fa fa-times fa-2x"></i><p class="lead">Daha Önce Ekspertiz Yapılmamıştır</p></div>';
    }else if (type === 'NoFreeTime') {
        result = '<div class="alert alert-danger text-center"><i class="fa fa-times fa-2x"></i><p class="lead">İlgili Günde Müsait Saat Bulunmamaktadır. Lütfen Farklı Bir Tarih Seçiniz.</p></div>';
    }else if (type ==='NoCarData') {
        result = '<div class="alert alert-danger text-center"><i class="fa fa-times fa-2x"></i><p class="lead">Plaka daha önce kayıt edilmemiştir. Lütfen müşteri kartından araç kaydı yapınız.</p></div>';
    }else if (type === 'NoCarListed') {
        result = '<div class="alert alert-danger text-center"><i class="fa fa-times fa-2x"></i><p class="lead">Müşteriye ait araç kayıt edilmemiştir. Önce Müşteri Kartından Araç Bilgisi Ekleyiniz.</p></div>';
    }
    
    return result;

}

export function randomPassword() {
    var password = Math.random().toString(36).slice(-8);
    return password;
}