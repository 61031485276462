import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component';

import ExportFromJson from '../utilities/jsontoexport';
import * as CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import {Row,Col,PageHeader,Table,Divider,Button} from 'antd';
 

class StockList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: false,
            allStocks:false,
            SearchItem:'',
            data: [],
            filtered:[],
            filtered:false,
            sqlStock:[],
            excel_columns:[
                
                {
                    title: 'Durum',
                    selector:'status_name'
                 },
                {
                    name: 'Şasi',
                    selector: 'chasis',
                },
                {
                    name: 'Model Yılı',
                    selector: 'model_year',
                 },
                {
                    name: 'Model',
                    selector: 'id_com_model_name',
                 },
                {
                    name: 'Spec',
                    selector: 'spec',
                },
                {
                    name: 'Yakıt',
                    selector: 'id_com_fuel_name',
 
                },
                {
                    name: 'Vites',
                    selector: 'id_com_gear_name',
                  },
                {
                    name: 'Renk',
                    selector: 'id_com_color_name',
 
                },

                {
                    name: 'Liste Fıyatı',
                    selector: 'list_price',
                },

                {
                    name: 'Fatura Tarihi',
                    selector: 'FATURA_TARIHI',

                 },

            ],
            columns:[
                {
                    title: 'Kontrol',
                    width:'140px',
                     render : (row,record) => (
                            <Row gutter={6} justify='center'>
                                <Col span={12}>
                                <a className="btn btn-primary btn-sm" type="button" href={'/stock/edit/'+record.id} style={{margin:5}}><i className="fas fa-edit"></i></a>
                                </Col>
                                {record.status==='1' &&  <Col span={12}>
                               <a className="btn btn-success btn-sm" type="button" href={'/orderform/edit/'+record.id_com_orderform} target='blank_' style={{margin:5}}><i className="fas fa-clipboard-check"></i></a> 
                                </Col>}
                            </Row>
                         
                ),
                },
                {
                    title: 'Durum',
                    render : (row,record) => (  <p   style={{margin:5,color:record.status==='3'?'red':record.status==='1'?'orange':'green'}}>{record.status_name}</p>  ),
                },
                {
                    title: 'Şasi',
                    dataIndex: 'chasis',
                },
                {
                    title: 'Model Yılı',
                    dataIndex: 'model_year',
                    align:'center'
                },
                {
                    title: 'Model',
                    dataIndex: 'id_com_model_name',
                    align:'center',
                },
                {
                    title: 'Spec',
                    dataIndex: 'spec',
                },
                {
                    title: 'Yakıt',
                    dataIndex: 'id_com_fuel_name',
                    align:'center'

                },
                {
                    title: 'Vites',
                    dataIndex: 'id_com_gear_name',
                    align:'center'

                },
                {
                    title: 'Renk',
                    dataIndex: 'id_com_color_name',
                    align:'center'
                },

                {
                    title: 'Liste Fıyatı',
                    dataIndex: 'list_price',
                    align:'right',
                    width:'100px',
                    sorter: (a,b) => parseInt(a.list_price)-parseInt(b.list_price),
                    render : (row,record) =>  <CurrencyFormat value={Math.round(parseFloat(record.list_price)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/>
                },

                {
                    title: 'Fatura Tarihi',
                      render : (row,record) => (  record.FATURA_TARIHI!=='1900-01-01'?<p   style={{margin:5}}>{moment(record.FATURA_TARIHI).format('DD.MM.YYYY')}</p>:<></> ), 
                },
                                       
               
            ]
        }

        this.getSqlStock=this.getSqlStock.bind(this)
     }
    componentDidMount(){
        let stock_url=this.props.api_url+'/selectRows/view_stock';
        fetch(stock_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
              body:'token='+this.props.loginToken+'&id_com_brand='+this.props.id_com_brand+'&type=user&id_com_stock_type=1',
            })
            .catch(error=>console.log(error)).then(x=> x.json()).then(result=>{
                this.setState({data:result,pageloader:true})
            })
       this.getSqlStock()
    }

getSqlStock(){
    let stock_sql=this.props.api_url+'/logoSelectRows/ZZZ_ST_ARAC_STOK'
    console.log(this.props.loginToken)
    fetch(stock_sql,{
        method:'POST',
        cahce: 'no-cache',
        headers:{ 
          'Content-Type':'application/x-www-form-urlencoded',
          'Accept':'application/json'
        },
      body:'token='+this.props.loginToken+'&ID_COM_BRAND='+this.props.id_com_brand,
    })
    .catch(error=>console.log(error)).then(x=> x.json()).then(result=>{
        if (result!==null){
            let  cardata= result.filter(row=>row.ID_COM_BRAND===this.props.id_com_brand)
            this.setState({sqlStock:cardata})
        }  
        })
}


_Filter_Data(value) {
    let match_str = new RegExp('('+value+')','i');
    this.setState({SearchItem:value})
    let data = this.state.data;
    let filtered_data = data.filter(function(row){

        if(value===''){ 
            return row
        }       {
          return row.chasis.match(match_str)||row.id_com_model_name.match(match_str)||row.id_com_brand_name.match(match_str)||row.id_com_fuel_name.match(match_str)||row.id_com_gear_name.match(match_str)//||row.id_com_color_name.match(match_str)//||

        }
    })
    this.setState({filtered:filtered_data.reverse()})
}


    render(){
        return ( 
            <div className="main">
            <div className="main-content">
                <div className="container-fluid">
                <div className="panel">
                    <Row>
                        <Col span={24}>
                            <PageHeader  title='Tüm Stok Listesi'
                                extra={[
                                    <ExportFromJson data={this.state.SearchItem===''?this.state.allStocks?this.state.data:this.state.data.filter(row=>row.status!=='3'):this.state.allStocks?this.state.filtered:this.state.filtered.filter(row=>row.status!=='3')}  fileName={`Arac_stoklari_${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}`} exportType={'xls'}/>,
                                     (this.props.id_com_user_type==='2'||this.props.id_com_user_type==='4'||this.props.id_com_user_type==='12') && <a key='add' className='btn btn-info btn-sm pull-right' href='/stock/add'    style={{margin:5}}>Stok Ekle</a>,
                                     (this.props.id_com_user_type==='2'||this.props.id_com_user_type==='4'||this.props.id_com_user_type==='12')?<a type='button' key='hide' className={!this.state.allStocks?'btn btn-success btn-sm pull-right' :'btn btn-danger  btn-sm pull-right' }  onClick={()=>this.setState({allStocks:!this.state.allStocks})}  style={{margin:5}}>{!this.state.allStocks?'Tüm Stokları  Listele':' Tüm Stokları Gizle'}</a> :<></>
                                ]}

                            />
                        </Col>
                    </Row>
                     
                    <div className="panel-body"> 

                    <div className="row">
                    <div className="col-md-12">

                        <input className="form-control" value={this.state.SearchItem} type="search" placeholder="Şasi / Fatura No / Marka / Model Arama..." onChange={(x)=>this._Filter_Data(x.target.value)}/>
                        <Divider></Divider>
                        <Table dataSource={this.state.SearchItem===''?this.state.allStocks?this.state.data:this.state.data.filter(row=>row.status!=='3'):this.state.allStocks?this.state.filtered:this.state.filtered.filter(row=>row.status!=='3') } 
                            columns={this.state.columns}  bordered  loading={!this.state.pageloader}  size='small' />
                        </div>  
                        </div>                    
                        </div>  

                    </div>
                </div>
            </div>
        </div>
        )}
}



export default connect(mapStateToProps)(StockList);