import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import $ from 'jquery';
import moment from "moment";
import {PageHeader,Divider} from 'antd';
 
class FeedBackList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: false,
            data: []
        }
        this._Update = this._Update.bind(this);
    }
    componentDidMount() {
        this._Update();
    }
    _Update() {
        let service_app_url = this.props.api_url + '/selectRows/view_feedback';
        fetch(service_app_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'token=' + this.props.loginToken + '&id_com_brand=' + this.props.id_com_brand + '&type=user',
        })
            .catch(error => console.log(error)).then(x => x.json()).then(result => {
            this.setState({data: result, pageloader: true})
        })
    }
    componentDidUpdate() {
        $(document).ready( function () {
            $('#datatable').DataTable( {
                "language": {
                    "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Turkish.json",
                },
                "ordering": false
            } );
        });
    }
    render() {
        return (
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <PageHeader title='Başvuru Listesi'
                                            extra={[
                                                <a href="/FeedBack/add" key='add' className="btn btn-primary">Ekle</a>
                                            ]}
                                        />
                                        <Divider></Divider>
                                     <div className="panel-body">
                                        <table className="table table-bordered restable" id={"datatable"}>
                                            <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Müşteri Adı</th>
                                                <th>Başvuru Tipi</th>
                                                <th>Başvuru Konusu</th>
                                                <th>Departman</th>
                                                <th>Plaka</th>
                                                <th>Durum</th>
                                                <th>Temas Tipi</th>
                                                <th>Danışman</th>
                                                <th>Tarih Saat</th>
                                                <th width="100">Kontrol</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.data.map((row,index)=>
                                                <tr key={index}>
                                                    <td><a href={'/FeedBack/Details/' + row.id} className="btn btn-primary btn-block btn-sm">Seç</a></td>
                                                    <td className='text-center'>{row.id}</td>
                                                    <td>{row.id_com_customer_name} {row.id_com_customer_company_name? '(' + row.id_com_customer_company_name + ')' : null}</td>
                                                    <td>{row.id_com_feedback_type_name}</td>
                                                    <td>{row.subjects}</td>
                                                    <td>{row.id_com_department_name}</td>
                                                    <td>{row.license_plate}</td>
                                                    <td><span className={row.id_com_feedback_status === '1' ? 'label label-warning' : row.id_com_feedback_status === '4' ? 'label label-success' : 'label label-danger'}> {row.id_com_feedback_status_name}</span></td>
                                                    <td>{row.id_com_meet_name}</td>
                                                    <td>{row.r_id_com_user1_name}</td>
                                                    <td>{moment(row.c_date,'YYYY-MM-DD').format('DD.MM.YYYY')} {row.c_time}</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(mapStateToProps)(FeedBackList);