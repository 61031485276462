import React from 'react';
import { Input, Col, Row, Spin,Typography,Button,Divider,Empty,Menu,Dropdown ,Modal,Form, Checkbox, DatePicker,PageHeader } from 'antd';
import DataTable from 'react-data-table-component';
import  {  DownOutlined, EditOutlined, CloseCircleOutlined  } from '@ant-design/icons';
import {connect} from 'react-redux';
import * as CurrencyFormat from 'react-currency-format';
import SelectList from '../../../components/utilities/SelectList'
import mapStateToProps from '../../../redux/mapStateToProps';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import moment from 'moment'; 

const { Title ,Text} = Typography;
 
class FinancePaymentList extends React.Component {

    constructor(props){
        super(props);
    this.state={
        SearchItem:'',
        modal_visible:false,
        modal_visible_update:false,
      data:[],
      sum:0,
      columns:[
          {
            name:'Tarih',
            selector:'doc_date',
            left:true,
            sortable:true,
            cell : row => moment(row.doc_date).format('DD.MM.YYYY')
          },
          {
            name:'Fiş No',
            selector:'doc_number',
            left:true,
            wrap :true,
            width:'200px'
        },
        {
            name:'Ödeme Türü',
            selector:'id_com_finance_payment_type_name',
            left:true,
            width:'200px'

        },
        {
            name:'Açıklama',
            selector:'comment',
            left:true,
            wrap :true,
            width:'200px'
        },


        { 
             name:'Borç',
            selector:'amount',
            right:true,
            cell:(row)=>
            row.id_com_cash_pay_type==='2'?
            <CurrencyFormat value={Math.round(parseFloat(row.amount)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/>
            : 
            <CurrencyFormat value={0} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/>
           
        },
        {
            name:'Alacak',
            selector:'amount',
            right:true,
            cell:(row)=>
            row.id_com_cash_pay_type==='1'?
            <CurrencyFormat value={Math.round(parseFloat(row.amount)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/>
            : 
            <CurrencyFormat value={0} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/>
        },
        {
            name:'Bakiye',
            selector:'cumulative_sum',
            right:true,
            cell:(row)=>
          
            <CurrencyFormat value={Math.round(parseFloat(row.cumulative_sum)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/>
        },
        {
        name: 'Detay',
        right:'true',
        cell:(row)=>  
        <Dropdown overlay={
            <Menu >
            <Menu.Item key="1"     >
              <Button     size='small' type='primary' shape='round'  danger  block onClick={()=>this.setState({modal_visible:true,selected_id:row.id})}   style={{textAlign:"right"}}>Sil<CloseCircleOutlined /> </Button> 
            </Menu.Item>
            <Menu.Item key="2"  >
            <Button   size='small' type='primary' shape='round' block onClick={()=>this.setState({modal_visible_update:true,selected_id:row.id})}   style={{textAlign:"right"}} >Güncelle<EditOutlined /> </Button> 
            </Menu.Item>
        </Menu>
         }
         >
            <Button  shape="round" size='small'>
                İşlemler <DownOutlined />
            </Button>
            </Dropdown>
        
        
        }    
      ],
      filtered_data:[],

    }
    this._Filter_Data=this._Filter_Data.bind(this);
}

componentDidMount(){

        let TL_select_url=this.props.api_url+'/selectRows/view_finance_payment';
        fetch(TL_select_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                    'Content-Type':'application/x-www-form-urlencoded'
                },
                body:  'token='+this.props.loginToken+'&type=user'
            })
            .catch(error => console.log(error))
            .then(res=>res.json())
            .then(result=>{
                  let sum = 0;
                  result.map(row=> sum +=parseFloat(row.bakiye))
                   this.setState({data:result,pageloader:true,sum:sum})
            })
}


_Filter_Data(value) { 
    this.setState({SearchItem:value})
    let match_str = new RegExp('('+value+')','i');
    let data = this.state.data;
    let filtered_data = data.filter((row)=>{
      if(this.state.SearchItem===''){ 
          return row
      }    else   {
        if (row.amount!==null&&row.comment!==null&&row.doc_date!==null&&row.doc_number!==null) { 
          return row.amount.match(match_str)||row.comment.match(match_str) ||row.doc_date.match(match_str) ||row.doc_number.match(match_str)
        } else {
          return row
        }      }})
 

       this.setState({filtered_data:filtered_data})

  }

render(){
    const rowStyle = {
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
      };
      const colStyle = {
        marginBottom: '16px',
        padding:20,
      };
      const gutter = 16;
    
        return (
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <Row>
                                        <Col md={24} sm={24} xs={24} style={{marginBottom: '5px'}}>
                                        <PageHeader title='Ödeme Hareketleri' 
                                          extra={[
                                            <Button key='1' href='/financepayment/add' type="primary" shape='round' size='small' style={{margin:5}}>Kayıt Ekle</Button>
                                          ]}
                                        />  
                                        </Col>
                                    </Row>
                                    <Divider></Divider>
                                     <Row style={rowStyle} gutter={gutter} justify="start">

                                        <Col md={24} sm={24} xs={24} style={colStyle}>
                                                <DataTable
                                                dense 
                                                noHeader
                                                highlightOnHover    
                                                selectableRowsHighlight	
                                                pagination
                                                paginationPerPage={10}
                                                paginationComponentOptions={ {rowsPerPageText: 'Sayfa Başına Satır Sayısı:', rangeSeparatorText: ' -', noRowsPerPage: false, selectAllRowsItem: true, selectAllRowsItemText: 'Tümü' }}
                                                columns={this.state.columns}
                                                data=  {this.state.data}
                                                noDataComponent={
                                                  <Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"  imageStyle={{   height: 60, }} description={  <span> Kayıt Bulunamamıştır. </span>  }  >
                                                      <Button href='/financepayment/add' type="primary" shape='round' size='small' >Kayıt Ekle</Button>
                                                  </Empty>
                                                }
                                                progressPending={!this.state.pageloader}
                                                progressComponent={<Spin size='large' style={{margin:20}}/>}
                                                sortIcon={<DownOutlined />}

                                                />
                                        </Col>
                                    </Row>
                                    <Divider />
                                    <DeleteModal 
                                        close={()=>this.setState({modal_visible:false,selected_id:''})} 
                                        selected_id={this.state.selected_id}
                                        api_url={this.props.api_url}
                                        modal_visible={this.state.modal_visible} 
                                        id={this.state.selected_id}
                                        loginToken={this.props.loginToken}/>
                                    <UpdateModal 
                                        close={()=>this.setState({modal_visible_update:false,selected_id:''})} 
                                        selected_id={this.state.selected_id}
                                        api_url={this.props.api_url}
                                        modal_visible_update={this.state.modal_visible_update} 
                                        id={this.state.selected_id}
                                        id_com_user={this.props.id_com_user}
                                        loginToken={this.props.loginToken}/>
            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    
  )
  }
}


export default connect(mapStateToProps)(FinancePaymentList);

class  DeleteModal extends React.Component{
    constructor ( props ) { 
        super(props);
        this.state={}
        this.handleOk=this.handleOk.bind(this)
    }


    handleChange(event,selectlist,name) {
        if(selectlist) { 
            this.setState({ [`${name}`]:event})
        } else { 
            const value = event.target.value;
            const key = event.target.name;
            this.setState({ [`${key}`]:value})
        }
      }


  handleOk(){
    let del_cash=this.props.api_url+'/delete/com_finance_payment';
 
    console.log('id='+this.props.selected_id+'&token='+this.props.loginToken+'&type=user');

    try{
    fetch(del_cash,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body:'id='+this.props.selected_id+'&token='+this.props.loginToken+'&type=user',
        }).then(e=>   window.location.reload() )
        
    }
        catch(e){console.log(e)}
       
            
}
  


    render(){
        return ( 
            <Modal
            visible={this.props.modal_visible}
            title="UYARI - Silme İşlemi"
            onOk={this.props.close}
            onCancel={this.props.close}
            footer={[
              <Button key="back" onClick={this.props.close}>
                Hayır
              </Button>,
              <Button key="submit" 
              //disabled={this.state.payment_no&&this.state.paid_amount?false:true}
              type="primary" onClick={()=>this.handleOk()}>
                Evet
              </Button>,
            ]}
          >
    <Title level={4} type="danger" style={{textAlign:"center"}}>Hareket Kaydını Tamamen Silmek İstediğinizden Emin misiniz ?</Title>
          </Modal>
        )
    }
}


class  UpdateModal extends React.Component{
    constructor ( props ) { 
        super(props);
        this.state={
            disabled:true,
        }
        this.handleOk=this.handleOk.bind(this)
    }


    handleChange(event,selectlist,name) {
        if(selectlist) { 
            this.setState({ [`${name}`]:event,disabled:false})
        } else { 
            const value = event.target.value;
            const key = event.target.name;
            this.setState({ [`${key}`]:value,disabled:false})
        }
      }


  handleOk(){
    let update_cash=this.props.api_url+'/update/com_finance_payment';
    let id = this.props.id
    let d = new Date(); 
    let date = d.toISOString();
    let m_date  = date.split('T')[0];
    let m_time = d.toLocaleTimeString('tr-TR');


    let update_body='token='+this.props.loginToken+'&id='+id+'&m_date='+m_date+'&m_time='+m_time+'&m_id_com_user='+this.props.id_com_user+'&type=user';

    for (let [key] of Object.entries(this.state)) {
       if (key!=='disabled') {
          if(key==='doc_date'){
            update_body +=`&${key}=`+ moment(this.state[`${key}`]).format('YYYY-MM-DD')

          } else  {
            update_body +=`&${key}=`+ this.state[`${key}`]
          }
      }
   }
   
     
    try{
    fetch(update_cash,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body:update_body,
        }).then(e=> 
            
            window.location.reload() )
        
    }
        catch(e){console.log(e)}
}

componentDidUpdate(prevProps, prevState) {
    if (this.props.id !== prevProps.id) {
      this._Update();
    } 
}

_Update() {

    let id = this.props.id
if(id!==''){

 let com_credits_line_url=this.props.api_url+'/selectRows/com_finance_payment';


    fetch(com_credits_line_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body: 'token='+this.props.loginToken+'&id='+id+'&type=user',
        })
        .catch(error=>console.log(error)).then(x=>x.json() ).then(data=>{
             if(data!==undefined) {
   for (let [key, value] of Object.entries(data[0])) {
              if(key==='doc_date') {
                  this.setState({ [`${key}`]:moment(value)  });
              } else { 
                  this.setState({ [`${key}`]:value  });
              }
            }
            }
        })
    }
} 


    render(){
        return ( 
            <Modal
            visible={this.props.modal_visible_update}
            title="Güncelleme  İşlemi"
            onOk={this.props.close}
            onCancel={this.props.close}
            footer={[
              <Button key="back" onClick={this.props.close}>  İptal </Button>,
              <Button key="submit"   disabled={this.state.disabled}  type="primary" onClick={()=>this.handleOk()}>  Güncelle </Button>,
            ]}
          >
           
  
            <Form.Item  colon labelAlign='left' labelCol={{span:7 }}    hasFeedback label='İşlem Tarihi'      >
                <DatePicker locale={locale} onChange={(x)=>{this.setState({doc_date:x,disabled:false})}} value={this.state.doc_date} style={{width:'100%'}} format='DD.MM.YYYY'/>
            </Form.Item>
            <SelectList label={'Vergi Türü Seçiniz'}  table={'com_finance_payment_type'} onChange={(x)=>this.handleChange(x,true,'id_com_finance_payment_type')}    value={this.state.id_com_finance_payment_type} />
            <SelectList label={'Hareket Türü Seçiniz'}  table={'com_cash_pay_type'} onChange={(x)=>this.handleChange(x,true,'id_com_cash_pay_type')}     value={this.state.id_com_cash_pay_type}  />
            <Form.Item  colon labelAlign='left' labelCol={{span:7 }}    hasFeedback label='İşlem Tutarı'      >
                   <Input size="small"  name={'amount'}    onChange={(x)=>this.handleChange(x)} value={this.state.amount}/>
            </Form.Item>
                 <Text strong={true}  >Açıklama</Text>
                <Input.TextArea  name={'comment'}     rows={4}        placeholder={'Var ise notunuzu ekleyiniz.'} onChange={(x)=>this.setState({comment:x.target.value,disabled:false})}  value={this.state.comment}/>
             <Checkbox onChange={(x)=>this.setState({show: x.target.checked?'1':'0',disabled:false})} style={{margin:5}} checked={this.state.show==='1'?true:false}>Rapor Göster</Checkbox>
         
         
          </Modal>
        )
    }
}



