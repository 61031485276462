
import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from './SelectList';
import DataTable from 'react-data-table-component';
import * as CurrencyFormat from 'react-currency-format';



class AccordionCars extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:[],
            id_com_orderform:'',
            clicked:false,
            add_btn:false,
            search_loaded:false,
            result_data:[],
            invItems:[],
            campaigns:[],
            columns:[
                {
                name: 'Saşe',
                selector: 'chasis',
                width:'180px',
                left:true,
                 sortable:true
                },
                {
                name: 'Model Yılı',
                selector: 'model_year',
                width:'75px',
                left:true,
                 sortable:true
                },
                {
                name: 'Marka',
                selector: 'id_com_brand_name',
                left:true,
                width:'100px',

                 sortable:true
                },
                {
                name: 'Model',
                selector: 'id_com_model_name',
                
                width:'100px',

                left:true,
                 sortable:true
                },
                {
                name: 'Spec',
                selector: 'spec',
                left:true,
                width:'250px',
                wrap:true,
                sortable:true
                },
                {
                name: 'Yakit / Vites',
                width:'100px',
                cell : row =>(
                        <p   style={{margin:5}}>{row.id_com_fuel_name+' / '+row.id_com_gear_name}</p>
                ),
                left:true,
                },
                {
                name: 'Renk',
                selector: 'id_com_color_name',
                left:true,
                sortable:true,
                wrap:true
                },
                {
                name: 'Kontrol',
                cell : row =>(
                    <>
                        <a disabled={row.status==='1'?true:false}  type='button' className='btn btn-primary btn-xs' onClick={()=>this._includeItem(row.id)}>Sec</a>
                    </>
                ),
                right:true,
                }
            ],
        };
    this.handleChange=this.handleChange.bind(this);
    this.Search=this.Search.bind(this);
    this._includeItem=this._includeItem.bind(this);
    this._excludeItem=this._excludeItem.bind(this);
    this._Submit=this._Submit.bind(this);

 
    
    }

    componentDidMount(){
        this.setState({data:this.props.data,id_com_orderform:this.props.id_com_orderform})
        let url = this.props.api_url+'/selectRows/view_campaign';
        fetch(url, {
        method: 'POST',
         cache: 'no-cache',
        headers:  { 
        'Content-Type':  'application/x-www-form-urlencoded'
                },
        body:'token='+this.props.loginToken+'&type=user',
        })
        .then(response => response.json() )
        .then(result=> { this.setState({campaigns:result})})
    }

    handleChange(event,selectlist,name) {
        if(selectlist) { 
            this.setState({ [`${name}`]:event.id})
        } 
        else { 

         if(event.target.type==='checkbox'){ 
               event.target.checked?this.setState({[`${event.target.name}`]:'1'}):this.setState({[`${event.target.name}`]:'0'})
                        } else {
            const value = event.target.value;
            const key = event.target.name;
            this.setState({ [`${key}`]:value})
         } }
         
    }

    async  Search() {
console.log('token='+this.props.loginToken+'&id_com_brand='+this.props.id_com_brand+'&type='+this.props.id_com_sale_type+'&type=user')
        let url = this.props.api_url+'/selectRows/'+this.props.table;
        fetch(url, {
        method: 'POST',
         cache: 'no-cache',
        headers:  { 
        'Content-Type':  'application/x-www-form-urlencoded'
                },
        body:'token='+this.props.loginToken+'&id_com_brand='+this.props.id_com_brand+'&type='+this.props.id_com_sale_type+'&type=user',
        })

        .then(response => response.json() )
        .then(result=>
            {
               let match_str = new RegExp(this.state.search_text,'i');
               let filtered1_result = result.filter((row)=>{
                if(this.state.search_text===''){ 
                        return null 
                    }       {
                    return row.chasis.match(match_str) ||row.id_com_brand_name.match(match_str)||row.id_com_model_name.match(match_str)||row.spec.match(match_str)||row.id_com_gear_name.match(match_str)||row.id_com_fuel_name.match(match_str)||row.id_com_color_name.match(match_str)
               }})
               let  difference1 =filtered1_result.filter(row => !this.state.invItems.find(row2 => row.id === row2.id))
               this.setState({result_data:difference1.filter(row=>row.id_com_stock_type===this.props.id_com_sale_type&&row.status==='0' ),search_loaded:true});
            }
            )
    }
 
    _excludeItem(index) { 
        let invItems = this.state.invItems;
        invItems.splice(index,1);
         this.setState({invItems:invItems});
      }



      _includeItem(id) { 
        let invItems = this.state.invItems;
        let product_url = this.props.api_url+'/selectRows/'+this.props.table;
        fetch(product_url,{
          method:'POST',
          cahce: 'no-cache',
          headers:{ 
            'Content-Type':'application/x-www-form-urlencoded'
          },
          body: 'token='+this.props.loginToken+'&type=user&id='+id,
      }).then(res=>res.json())
      .then(result=>{
       invItems.push(result[0]);
        this.setState({invItems:invItems,search_text:'',result_data:[],add_btn:!this.state.add_btn})
      })
     }

_Submit(){
    let invItems = this.state.invItems;
    let orderform_url = this.props.api_url+'/insert/com_orderform_lines';
    let stock_url = this.props.api_url+'/update/com_stock';
    try{ 
    invItems.map((row,index)=>{
        let id_com_campaigns = row.id_com_campaign===undefined?0:row.id_com_campaign

       let insert_line=  
            'token='+this.props.loginToken+
            '&id_com_orderform='+this.state.id_com_orderform+
            '&id_com_stock='+row.id+
            '&price='+row.price+
            '&id_com_campaigns='+id_com_campaigns+
            '&otv_status='+row.otv_status+
            '&type=user';
            console.log(insert_line)
        fetch(orderform_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
            body: insert_line,
        }).catch(e=>console.log(e)) 
        let update_stock=  
        'token='+this.props.loginToken+
        '&id_com_orderform='+this.state.id_com_orderform+
        '&id='+row.id+
        '&status=1&type=user';
        fetch(stock_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
            body: update_stock,
        }).catch(e=>console.log(e)).then(res=>window.location.reload())
    })
} 
catch(error){console.log(error)}

}
    render() { 
        return ( 
    <React.Fragment>
        <a className={!this.state.clicked?"btn btn-primary btn-lg btn-block":"btn btn-success btn-lg btn-block"}
            data-toggle="collapse" 
            href={`#${this.props.id}`}
            role="button" 
            aria-expanded="false" 
            aria-controls={this.props.id} 
            onClick={()=>this.setState({clicked:!this.state.clicked})}>
 <i className={!this.state.clicked?"fas fa-plus-circle pull-left":"fas fa-minus-circle pull-left"}  > </i>   {this.props.title } :   <CurrencyFormat value={Math.round(parseFloat(this.props.summary)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/></a>
    <div className="collapse" id={this.props.id}>
        <div className="panel">
        <div className="  panel-heading">
        <div className="row">            
        <div className="col-md-6 text-left">
                <div className="panel-title">{this.props.title}</div>
            </div>
            <div className="col-md-6 text-right">
                <a type="button" className="btn btn-secondary" onClick={()=>this.setState({add_btn:!this.state.add_btn,search_text:'',result_data:[]})}><i className={!this.state.add_btn?"fas fa-plus-circle pull-left":"fas fa-minus-circle pull-left"} > </i>  Ekle</a>
            </div>
        </div>
        {this.state.add_btn?
        <>
        <div className="row"> 
            <div className="form-group margin">
                <input value={this.state.search_text} name='search_text' className="form-control" type="text" placeholder="Arama" onChange={(event)=> {this.handleChange(event);this.Search()}} />
            </div>
        </div>
       
        <div className="row">  
            <DataTable
                        columns={this.state.columns}
                        data={this.state.result_data}
                        fixedHeader
                        noHeader
                        overflowY={false}
                        overflowYOffset={'100px'}
                        highlightOnHover
                        responsive
                        striped
                        dense
                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                        pagination={true}
                        paginationPerPage={4}
                        defaultSortField={'chasis'}
                        defaultSortAsc={true}
                    />

        </div>

        </>
        :  <></>
        }
        </div>
        <div className="  panel-body">
        {this.state.invItems.length>0?
                <div className="row"> 
                <table className="table table-bordered table-striped">
            <thead>
                <tr>
                    <th>Araç Bilgisi</th>
                    <th>Ötv Muaf ?</th>
                    <th width='150'>Kampanya</th>
                    <th>Satış Bedeli</th>
                    <th>Kontrol</th>

                </tr>
            </thead>
            <tbody>
            {this.state.invItems.map((row,index)=>{   
            return  (
                    <tr key={index}>
                    <td>{row.chasis+' - '+row.id_com_brand_name+' '+row.id_com_model_name+' '+row.spec}</td>
                    <td>
                        <select className="form-control" name='otv_status'   onChange={(x)=>this.state.invItems[index].otv_status=x.target.value} value={this.state.invItems[index].otv_status} data-live-search="true">
                            <option key={0} value={0}>{'Seçiniz'}</option>
                            <option key={1} value={0}>{'Hayır'}</option>
                            <option key={2} value={1} >{'Evet'} </option>
                        </select>
                    </td>
                    <td >

                    <SelectList table='view_campaign'  id_com_brand={row.id_com_brand} noLabel={true}  multiple={true}  value={this.state.invItems[index].id_com_campaign}  
                    onChange={(x)=>
                    {
                        let str = [];
                        x.map((row,index)=>{str.push({'id':row.id,'title':row.title})})
                        let json = JSON.stringify(str);
                    //         if(index===0){str.concat({id:row.id,title:row.title})} else { str +=','+row.id}});
                        this.state.invItems[index].id_com_campaign = json;
                    // }
                    }} />
                    </td>
                    <td>
                    <input type="number" className="form-control" name="price" value={this.state.invItems[index].price} onChange={(x)=>this.state.invItems[index].price =x.target.value} required/>
                    </td>
                      <td><a disabled={row.status==='1'?true:false} role='button' className='btn btn-danger btn-xs' onClick={()=>this._excludeItem(index)}>Cikar</a></td>
                </tr>
                )})}
            </tbody>
            </table>  
                </div>
                :<></>
                }
                <div className="  panel-footer">
        <button className="btn btn-primary pull-right" 
                                // disabled={this.state.id_com_sale_type===''||this.state.id_com_customer==='0'}
                                  onClick={()=>this._Submit()}
                                    >Kaydet</button>
        </div>
        </div>
        

        </div>
    </div>       
    </React.Fragment>

)
}
}

export default connect(mapStateToProps)(AccordionCars);