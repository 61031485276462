import React ,{useState,useEffect} from 'react';

import {Divider, Drawer,Typography,Descriptions,Input ,Button,Table,Row,Col} from 'antd';
import { SearchOutlined,PlaySquareOutlined,StopOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import moment from 'moment';

const {Title,Text} = Typography

const PhoneDrawer = ({visible,OnClose,row_data,api_url,loginToken})=>{
    const [ListData,setListData]  = useState([]);
    const [Phone1,setPhone1] = useState('');
    const [Phone2,setPhone2] = useState('');
    const [SearchItem,setSearchItem]= useState('');
    const [Loading,setLoading]= useState(false);
    const [SelectedRow,setSelectedRow]= useState('');
    const [LookItem,setLookItem]= useState('');
    const [FilteredData,setFilteredData]= useState([]);

    const Columns =[
        {
            title:'Tarih',
            // dataIndex:'date',
            render: (row,record)=>row.file_name.split('_')[0],
            sorter: (a, b) => a.file_name.split('_')[0]  - b.file_name.split('_')[0],
        },

        {
            title:'Saat',
            dataIndex:'start_time',
            render: (row,record)=>    moment().seconds(record.start_time).format('H:mm:ss'),
            sorter: (a, b) => a.start_time   - b.start_time ,
         },
         {
            title:'Arama Tipi',
            dataIndex:'call_type',
            render: (row,record)=>record.call_type==='1'?'İç Arama':'Dış Arama'
        },
         {
            title:'Cevaplayan',
            dataIndex:'channel_caption',
            sorter: (a, b) => a.channel_caption   - b.channel_caption ,

         },
         {
            title:'Süre (Sn)',
            dataIndex:'duration',
            align:'center',
            sorter: (a, b) => a.duration   - b.duration ,

         },
         {
            title:'Dinle',
            align:'center',

            render: (row,record)=>
             SelectedRow!==record.file_name ?

                    <Button size='small' shape='round' type='primary'   onClick={()=>setSelectedRow(record.file_name)}><PlaySquareOutlined/></Button>
                :
                <Row justify='space-between' gutter={6} align='middle'>
                    <Col >
                        <audio controls autoPlay >
                            <source src={'https://senturkotomotiv.com.tr/api/VRXApi/getRecord/'+record.file_name+'?token='+loginToken} type="audio/ogg"/>
                        </audio>
                    </Col>
                    <Col    >
                        <Button size='small' shape='round' type='primary' danger onClick={()=>setSelectedRow('')}><StopOutlined/></Button>
                    </Col>
                </Row>



         },

    ]



// "id": "1",
// "call_type": "1",
// "channel_caption": "580381-03",
// "channel_id": "3",
// "duration": "16",
// "extension": "",
// "file_name": "20210408_164852_580381_03.ogg",
// "flag": "0",
// "note": "",
// "number": "05414500070",
// "phonebook_id": "0",
// "phonebook_name": "",
// "pickup_duration": "0",
// "start_time": "1617889732",
// "server": "127.0.0.1",
// "extra": ""


    const {
        company_name,
        username,
        phone1,
        phone2
    }  = row_data;


    useEffect(() => {
        const _GetData = ()=>{
            setPhone1(phone1);
            setPhone2(phone2);

        }
       return _GetData();
   }, [visible])


   const _SendRequest = async (phone)=>{
         setSearchItem(phone)
        setLoading(true);
    let url = 'https://senturkotomotiv.com.tr/api/VRXApi/getRecordList/';
    let body = '&token='+loginToken+'&type=user&phone='+phone;
    let config = {  method:'POST',    cahce: 'no-cache',    headers:{     'Content-Type':'application/x-www-form-urlencoded'     },   body:body}
    try{
        let list = await (await fetch(url,config)).json();
        console.log(list)
        setListData(list)
        setLoading(false);
    }
    catch {
        setListData([]);
         setLoading(false);
    }


    }

    const _Filter= (e)=>{
        setLookItem(e)
       let match_str = new RegExp('('+e+')','i');
       let filteredData = ListData.filter((row)=>{ return row.number.match(match_str)||row.file_name.match(match_str)||row.channel_caption.match(match_str) })
       setFilteredData(filteredData)

   }



    return  (

        <Drawer
        title='Santral Kayıtları | '
        placement='right'
        onClose={OnClose}
        visible={visible}
        width='55%'
        footer={
            <Row justify='end'>
                <Col>
                    <Button type='primary' danger  onClick={OnClose}>Kapat</Button>
                </Col>
            </Row>
        }
        >
        <Divider> </Divider>
        <Divider>Telefon Kayıtları </Divider>
            <Descriptions column={1} bordered size='small'>
                <Descriptions.Item label='Firma' >
                {company_name}
                </Descriptions.Item>
                <Descriptions.Item label='Müşteri'>
                {username}
                </Descriptions.Item>
                <Descriptions.Item label='Telefon 1'>
                <Input.Search type='number' value={Phone1} onChange={(x)=>setPhone1(x.target.value)}  onSearch={_SendRequest} enterButton={<Button type='primary'><SearchOutlined/></Button>}/>
                </Descriptions.Item>
                <Descriptions.Item label='Telefon 2'>
                 <Input.Search type='number' value={Phone2} onChange={(x)=>setPhone1(x.target.value)}  onSearch={_SendRequest} enterButton={<Button type='primary'><SearchOutlined/></Button>}/>
                </Descriptions.Item>
            </Descriptions>
            <Divider></Divider>
            {ListData.length>0 &&<Input.Search onChange={(x)=>_Filter(x.target.value)}/>}
            <Divider></Divider>

            <Table  size='small' bordered  columns={Columns} loading={Loading} dataSource={LookItem===''?ListData:FilteredData} pagination={{pageSize:6}} footer={()=>SearchItem!==''&&ListData.length===0?
            <Text type='danger' strong code>Kayıt Bulunamamıştır. Aranan Tel: {SearchItem}</Text>
            :
            <Text type='danger'  code > {ListData.length} Adet Kayıt Bulunamamıştır.</Text>
            }/>


        </Drawer>
    )
}

export default connect(mapStateToProps)(PhoneDrawer)  ;

// adress: "test"
// birthday: "2020-06-11"
// c_date: "2020-06-09"
// c_time: "05:27:28"
// comment: "TEST DUZENLEME "
// company_name: "Mitrotech Digital Transformation Consultancy"
// email: "sunaytuna@mitrotech.net"
// id: "9474"
// id_com_city: "16"
// id_com_city_name: "Bursa"
// id_com_customer_type: "2"
// id_com_customer_type_name: "Firma"
// id_com_education: "6"
// id_com_education_name: "Lisans"
// id_com_etk: "0"
// id_com_etk_status: "0"
// id_com_gender: "1"
// id_com_gender_name: "Erkek"
// id_com_kvkk: "2"
// id_com_kvkk_status: "1"
// id_com_meet: "1"
// id_com_meet_name: "Showroom"
// id_com_sector: "1"
// id_com_sector_name: "Akademisyen"
// id_com_town: "201"
// id_com_town_name: "Mudanya"
// id_com_user_createdby: null
// kvkk_comfirmation: "2021-02-18 15:44:14"
// kvkk_status: "1"
// name: "Sunay"
// phone1: "05338971290"
// phone2: "1"
// postcode: "34000"
// status: "1"
// status_name: "Aktif"
// surname: "Tuna"
// tax_office: "Mudanya"
// taxno: "2147483642"
// username: "Sunay Tuna"

