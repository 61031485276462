import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import moment from "moment";
import $ from "jquery";
import * as CurrencyFormat from "react-currency-format";
class ExpertiseList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: false,
            data: []
        }
    }
    componentDidMount() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("token", this.props.loginToken);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        fetch(this.props.api_url + "/selectRows/view_expertise", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                this.setState({data: result.reverse(), pageloader: true})
            })
            .catch(error => console.log('error', error));
    }
    componentDidUpdate() {
        $(document).ready( function () {
            $('#datatable').DataTable( {
                "language": {
                    "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Turkish.json",
                },
                "ordering": false
            } );
        });
    }
    render() {
        return (
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-heading">
                                        <div className="col-md-6  "  >
                                             <div className="panel-title">Ekspertiz Listesi</div>
                                        </div>
                                        <div className="col-md-5 "  >
                                         </div>
                                        <div className="col-md-1 "  >
                                            <a href={'/Expertise/Add/0'} className="btn btn-danger  btn-sm ">Oluştur</a>
                                        </div>
                                       
                                     </div>
                                     <br/>
                                    <div className="panel-body">
                                        <table className="table table-bordered restable" id={"datatable"}>
                                            <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Araç Sahibi</th>
                                                <th>Talep Eden Müşteri</th>
                                                <th>Plaka</th>
                                                <th>Marka</th>
                                                <th>Model</th>
                                                <th>Model Yılı</th>
                                                <th>Spec</th>
                                                <th>Talep Fiyatı</th>
                                                <th>Teklif</th>
                                                <th>Durum</th>
                                                <th>Tarih</th>
                                                <th width="100">Kontrol</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.data.map((row,index)=>
                                                <tr key={index}>
                                                    <td>{row.id}</td>
                                                    <td>{row.id_com_customer_name} {row.id_com_customer_company_name? '(' + row.id_com_customer_company_name + ')' : null}</td>
                                                    <td>{row.id_com_requested_customer_name} </td>
                                                    <td>{row.license_plate}</td>
                                                    <td>{row.id_com_brand_name}</td>
                                                    <td>{row.id_com_model_name}</td>
                                                    <td>{row.model_year}</td>
                                                    <td>{row.spec}</td>
                                                    <td><CurrencyFormat value={Math.round(parseFloat(row.demand_price)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/></td>
                                                    <td><CurrencyFormat value={Math.round(parseFloat(row.offer_price)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/></td>
                                                    <td>{row.id_com_expertise_status_name}</td>
                                                    <td>{moment(row.c_date,'YYYY-MM-DD').format('DD.MM.YYYY')} {row.c_time}</td>
                                                    <td><a href={'/Expertise/Detail/' + row.id} className="btn btn-primary btn-block btn-sm">Seç</a></td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="panel-footer text-center">Toplam {this.state.data.length} adet kayıt bulundu.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(mapStateToProps)(ExpertiseList);