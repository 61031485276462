import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
import {alertMessage} from '../../assets.js';

class FeedBackDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_com_feedback: '',
            body: [],
            line: [],
            update_comment: '',
            pageloader: false,
            isSolution: '0',
            waitingConfirmation: false,
            Decline: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.Confirm = this.Confirm.bind(this);
        this.Decline = this.Decline.bind(this);
    }
    Confirm() {
        let d = new Date();
        let date = d.toISOString();
        let m_date = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');
        let url = this.props.api_url + '/update/com_feedback';
        fetch(url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'token=' + this.props.loginToken + '&id=' + this.state.id_com_feedback + '&m_date=' + m_date + '&m_time=' + m_time + '&m_id_com_user=' + this.props.id_com_user + '&id_com_feedback_status=4&type=user',
        }).catch(e => console.log(e)).then(res => window.location.reload())
    }
    Decline() {
        let d = new Date();
        let date = d.toISOString();
        let m_date = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');
        let last = this.state.line.pop();
        let id = last.id;
        let url = this.props.api_url + '/update/com_feedback_lines';
        let update_url = this.props.api_url + '/update/com_feedback';
        fetch(url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'token=' + this.props.loginToken + '&id=' + id + '&m_date=' + m_date + '&m_time=' + m_time + '&m_id_com_user=' + this.props.id_com_user + '&response=' + this.state.Response + '&type=user&isSolution=0',
        }).catch(e => console.log(e)).then(res => {
            let update_body =
                'token=' + this.props.loginToken +
                '&id=' + this.state.id_com_feedback +
                '&id_com_feedback_status=5' +
                '&type=user';
            fetch(update_url, {
                method: 'POST',
                cahce: 'no-cache',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: update_body,
            }).catch(e => console.log(e)).then(res => window.location.reload())
        })
    }
    handleChange(event, selectlist, name) {
        // console.log(event.target)
        if (selectlist) {
            this.setState({[`${name}`]: event.id})
        } else {
            if (event.target.type === 'checkbox') {
                event.target.checked ? this.setState({[`${event.target.name}`]: '1'}) : this.setState({[`${event.target.name}`]: '0'})
            } else {
                const value = event.target.value;
                const key = event.target.name;
                this.setState({[`${key}`]: value})
            }
        }
    }
    handleClick(id) {
        this.setState({updateLine : id})
        let getURL = this.props.api_url + '/selectRow/com_feedback_lines';
        fetch(getURL, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'token=' + this.props.loginToken + '&id=' + id + '&type=user',
        })
            .catch(error => console.log(error)).then(res => res.json()).then(result => {
            this.setState({
                update_comment: result.comment
            })
        })
    }
    updateComment(value){
        let d = new Date();
        let date = d.toISOString();
        let m_date = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');
        let url = this.props.api_url + '/update/com_feedback_lines';
        fetch(url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'token=' + this.props.loginToken + '&id=' + this.state.updateLine + '&comment=' + this.state.update_comment + '&m_date=' + m_date + '&m_time=' + m_time + '&m_id_com_user=' + this.props.id_com_user + '&type=user',
        }).catch(e => console.log(e)).then(res => window.location.reload())
    }
    handleSubmit() {
        let d = new Date();
        let date = d.toISOString();
        let c_date = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let insert_url = this.props.api_url + '/insert/com_feedback_lines';
        let update_url = this.props.api_url + '/update/com_feedback';
        let insert_body = 'token=' + this.props.loginToken +
            '&id_com_feedback=' + this.state.id_com_feedback +
            '&comment=' + this.state.comment +
            '&id_com_user=' + this.props.id_com_user +
            '&isSolution=' + this.state.isSolution +
            '&c_date=' + c_date +
            '&c_time=' + c_time +
            '&type=user&status=0';
        fetch(insert_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: insert_body,
        }).catch(e => console.log(e)).then(res => {
            let update_body1 =
                'token=' + this.props.loginToken +
                '&id=' + this.state.id_com_feedback +
                '&id_com_feedback_status=3&type=user';
            let update_body2 =
                'token=' + this.props.loginToken +
                '&id=' + this.state.id_com_feedback +
                '&id_com_feedback_status=2&type=user';
            fetch(update_url, {
                method: 'POST',
                cahce: 'no-cache',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: this.state.isSolution === '1' ? update_body1 : update_body2,
            }).catch(e => console.log(e)).then(res => window.location.reload())
        })
    }
    componentDidMount() {
        let id_com_feedback = this.props.match.params.id_com_feedback;
        let body_url = this.props.api_url + '/selectRow/view_feedback';
        fetch(body_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'token=' + this.props.loginToken + '&id=' + id_com_feedback + '&type=user',
        })
            .catch(error => console.log(error)).then(res => res.json()).then(result => {
            this.setState({
                body: result,
                pageloader: true,
                id_com_feedback: id_com_feedback,
                id_com_feedback_status: result.id_com_feedback_status
            })
        })
        let line_url = this.props.api_url + '/selectRows/view_feedback_lines';
        fetch(line_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'token=' + this.props.loginToken + '&id_com_feedback=' + id_com_feedback + '&type=user',
        })
            .catch(error => console.log(error)).then(res => res.json()).then(result => {
            let isSolution = 0
            result.map((row) => {
                isSolution += parseInt(row.isSolution)
            });
            if (isSolution > 0) {
                this.setState({line: result, waitingConfirmation: true})

            } else {
                this.setState({line: result})
            }
        })
    }
    render() {
        console.log(this.state)
        return (
            <React.Fragment>
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className={"row"}>
                                        <div className={"col-md-6 col-xs-12"}>
                                            <div className="panel-heading">
                                                <h4 className="page-title">Başvuru Detayı - Önem
                                                    Derecesi: {this.state.body.id_com_importance_name}</h4>
                                            </div>
                                        </div>
                                        <div className={"col-md-6 col-xs-12 text-right"}>
                                            {this.props.id_com_user == this.state.body.c_id_com_user?
                                                <a href={"/Feedback/edit/" + this.state.id_com_feedback} className={"btn btn-default"}  style={{marginRight: 4, marginTop: 30}}>Güncelle</a>
                                                :
                                                <a href={"#"} className={"btn btn-default disable"}  style={{marginRight: 4, marginTop: 30}} disabled={true}>Güncelle</a>
                                            }
                                            <a href={"https://senturkotomotiv.com.tr/api/pdf/feedback/" + this.state.id_com_feedback + "/" + this.props.loginToken}
                                               className={"btn btn-danger"} style={{marginRight: 30, marginTop: 30}}><i
                                                className={"fas fa-file-pdf"}></i> PDF İndir</a>
                                        </div>
                                    </div>
                                    <div className="panel-body">
                                        <table className="table table-bordered table-striped">
                                            <tbody>
                                            {this.state.body.id_com_feedback_type_name != null ?
                                                <tr>
                                                    <th width={200}>Başvuru Tipi</th>
                                                    <td>{this.state.body.id_com_feedback_type_name}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <th>Başvuru Tipi</th>
                                                    <td>-</td>
                                                </tr>
                                            }
                                            {this.state.body.c_date != null ?
                                                <tr>
                                                    <th>Başvuru Tarih - Saati</th>
                                                    <td>{this.state.body.c_date + ' / ' + this.state.body.c_time + ' - Oluşturan :  ' + this.state.body.c_id_com_user_name}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <th>Başvuru Tarih - Saati</th>
                                                    <td>-</td>
                                                </tr>
                                            }
                                            {this.state.body.id_com_meet === '15' ?
                                                <tr>
                                                    <th style={{color: 'red'}}>Distributor Aktarım Tarih - Saati</th>
                                                    <td>{this.state.body.dist_date + ' / ' + this.state.body.dist_time}</td>
                                                </tr>
                                                :
                                                <></>
                                            }
                                            {this.state.body.id_com_customer_name != null ?
                                                <tr>
                                                    <th>Müşteri Bilgileri</th>
                                                    <td>{this.state.body.id_com_customer_name + ' / ' + this.state.body.phone1 + ' / ' + this.state.body.email}</td>
                                                </tr>
                                                :
                                                <></>
                                            }
                                            {this.state.body.id_com_cars != null ?
                                                <tr>
                                                    <th>Araç Bilgleri</th>
                                                    <td>{this.state.body.license_plate + ' / ' + this.state.body.id_com_brand_name + ' - ' + this.state.body.id_com_model_name + ' ,  Model Yılı: ' + this.state.body.model_year}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <th>Araç Bilgleri</th>
                                                    <td>-</td>
                                                </tr>
                                            }
                                            {this.state.body.chasis != null ?
                                                <tr>
                                                    <th>Şase</th>
                                                    <td>{this.state.body.chasis}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <th>Şase</th>
                                                    <td>-</td>
                                                </tr>
                                            }

                                            {this.state.body.id_com_department_name != null ?
                                                <tr>
                                                    <th>İlgili Departman</th>
                                                    <td>{this.state.body.id_com_department_name}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <th>İlgili Departman</th>
                                                    <td>-</td>
                                                </tr>
                                            }
                                            {this.state.body.r_id_com_user1_name != null ?
                                                <tr>
                                                    <th>Atanan Yetkili</th>
                                                    <td>{this.state.body.r_id_com_user1_name}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <th>Atanan Yetkili</th>
                                                    <td>-</td>
                                                </tr>
                                            }
                                            {this.state.body.subjects != null ?
                                                <tr>
                                                    <th>Başvuru Konusu</th>
                                                    <td>{this.state.body.subjects}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <th>Başvuru Konusu</th>
                                                    <td>-</td>
                                                </tr>
                                            }
                                            {this.state.body.comment != null ?
                                                <tr>
                                                    <th>Başvuru Metni</th>
                                                    <td>{this.state.body.comment}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <th>Başvuru Metni</th>
                                                    <td>-</td>
                                                </tr>
                                            }
                                            {this.state.body.id_com_feedback_status_name != null ?
                                                <tr>
                                                    <th>Durum</th>
                                                    <td>{this.state.body.id_com_feedback_status_name}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <th>Durum</th>
                                                    <td>-</td>
                                                </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="panel-body">
                                        <h4 className='text-center'>Notlar </h4>
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr className={"active"}>
                                                <th width="50">No</th>
                                                <th width="150">Tarih / Saat</th>
                                                <th>Not</th>
                                                <th width="200">Kullanıcı</th>
                                                <th width="120">Çözüm Yanıtı?</th>
                                                <th>Yönetici Yorumu</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.line.map((row, index) =>
                                                <tr key={index}>
                                                    <td>{index + 1} </td>
                                                    <td>{row.c_date + ' / ' + row.c_time} </td>
                                                    <td>
                                                        <div className={"pull-left"}>
                                                        {row.comment}
                                                        </div>
                                                        <div className={"pull-right"}>
                                                            <button
                                                                type="button"
                                                                className="btn btn-default btn-sm"
                                                                data-toggle="modal"
                                                                data-target="#lineEditModal"
                                                                onClick={(event => this.handleClick(row.id))}
                                                            >
                                                                <i className={"fas fa-edit"}></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td>{row.id_com_user_name} </td>
                                                    <td className={"text-center"}>{row.isSolution === '1' ? 'Evet' : 'Hayır'}</td>
                                                    <td>{row.response} </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                    {this.props.id_com_user === this.state.body.id_com_confirm_user && this.state.waitingConfirmation ?
                                        <div className="panel-body">
                                            {this.state.Decline ?
                                                <>
                                                    <div className="form-group">
                                                        <label>Red Yanıtı : </label>
                                                        <textarea type="text" style={{height: 200}} row='20'
                                                                  className="form-control" name="Response"
                                                                  onChange={(x) => this.handleChange(x)}/>
                                                    </div>
                                                    <button className="btn btn-danger pull-left"
                                                            disabled={this.state.disabled}
                                                            onClick={() => this.setState({
                                                                Response: '',
                                                                Decline: false
                                                            })}
                                                    >İptal
                                                    </button>
                                                    <button className="btn btn-primary pull-right"
                                                            disabled={this.state.disabled}
                                                            style={{margin: 5}}
                                                            onClick={() => this.Decline()}
                                                        // onClick={()=>console.log(this.state)}
                                                    >Kaydet
                                                    </button>
                                                </>
                                                : <>
                                                    <a className="btn btn-danger pull-left"
                                                       disabled={this.state.disabled}
                                                       href="/Feedback/List"
                                                        // onClick={()=>console.log(this.state)}
                                                    >Geri</a>

                                                    {this.state.id_com_feedback_status === '4' ?
                                                        <></>
                                                        :
                                                        <>
                                                            <button className="btn btn-success pull-right"
                                                                    disabled={this.state.disabled}
                                                                    style={{margin: 5}}
                                                                    onClick={() => this.Confirm()}
                                                                // onClick={()=>console.log(this.state)}
                                                            >Onayla
                                                            </button>
                                                            <button className="btn btn-danger pull-right"
                                                                    disabled={this.state.disabled}
                                                                    style={{margin: 5}}
                                                                    onClick={() => this.setState({Decline: true})}
                                                                // onClick={()=>console.log(this.state)}
                                                            >Red Gönder
                                                            </button>
                                                        </>}
                                                </>}
                                        </div>
                                        :
                                        this.state.waitingConfirmation ?
                                            <div className="panel-body">
                                                <div className="col-md-12"
                                                     dangerouslySetInnerHTML={{__html: alertMessage('WaitingResponse')}}></div>
                                            </div>
                                            :
                                            <div className="panel-body">
                                                <div className="form-group">
                                                    <label>Not : </label>
                                                    <textarea type="text" style={{height: 200}} row='20'
                                                              className="form-control" name="comment"
                                                              onChange={(x) => this.handleChange(x)}/>
                                                </div>

                                                <div className="form-check">
                                                    <input className="form-check-input" name='isSolution'
                                                           type="checkbox" id='isSolution'
                                                           onChange={(x) => this.handleChange(x)}/>
                                                    <label className="form-check-label" style={{marginLeft: 5}}>Çözüm
                                                        Yanıtıdır. </label>
                                                </div>
                                                <div className="panel-footer">

                                                    <a className="btn btn-danger pull-left"
                                                       href="/Feedback/List"
                                                    >Geri</a>
                                                    <button className="btn btn-primary pull-right"
                                                            disabled={this.state.disabled}
                                                            onClick={() => this.handleSubmit()}
                                                        // onClick={()=>console.log(this.state)}
                                                    >Not Kaydet
                                                    </button>
                                                </div>

                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="lineEditModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title" id="myModalLabel">Yanıt Metni Güncelle</h4>
                        </div>
                        <div className="modal-body">
                            {this.state.update_comment != null?
                                <textarea
                                    className={"form-control"}
                                    id={"update_comment"}
                                    placeholder={"Yanıt Metni"}
                                    rows={5}
                                    name={"update_comment"}
                                    onChange={(x) => this.handleChange(x)}
                                    defaultValue={this.state.update_comment}
                                >
                                </textarea>
                            :
                                <></>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">İptal</button>
                            <button type="button" className="btn btn-primary" onClick={(x) => this.updateComment(this.state.update_comment)}>Kaydet</button>
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(FeedBackDetails);
