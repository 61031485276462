import React,{useState,useEffect} from 'react';
import { Input, Col, Row, Typography,Divider, PageHeader,Collapse ,Button,Table,Tabs, Select, Spin, DatePicker} from 'antd';
import {connect} from 'react-redux';
import mapStateToProps from '../../../redux/mapStateToProps'; 
import 'moment/locale/tr';
import moment from 'moment'; 
import Chart from "react-apexcharts"; 
import locale from 'antd/es/date-picker/locale/tr_TR';
import ExportFromJson from '../../../components/utilities/jsontoexport';




const  formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 2
  })

  const  formatter_num = new Intl.NumberFormat('tr-TR', {
    style: 'decimal',
    minimumFractionDigits: 2
  }) 
  const  formatter_eur = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  })

  const  formatter_usd = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

 const { Title ,Text} = Typography;
 const {Panel} = Collapse;
 const  {TabPane} =Tabs;
const {Option} =Select;

 const DebitsList = ({api_url,loginToken}) =>{
    const [LogoData,setLogoData] =useState([])
    const Months = [
        { value: 0,text:'Ocak' },
        { value: 1,text:'Şubat' },
        { value: 2,text:'Mart' },
        { value: 3,text:'Nisan' },
        { value: 4,text:'Mayıs' },
        { value: 5,text:'Haziran' },
        { value: 6,text:'Temmuz' },
        { value: 7,text:'Ağustos' },
        { value: 8,text:'Eylül' },
        { value: 9,text:'Ekim' },
        { value: 10,text:'Kasım' },
        { value: 11,text:'Aralık' },
    ]
    const [Month,setMonth]=useState(0);
    const [Year,setYear]=useState(0);
    const [Data,setData] =useState([])
    const [Years,setYears] =useState([]);
    const [MonthlyData,setMonthlyData] = useState([])
    const [SelectedDate,setSelectedDate] =useState('');
    const [Banks,setBanks]=useState([]);
    const [Departments,setDepartments]=useState([]);


    const [TableData,setTableData]=useState([])
    const [DetailModal,setDetailModal] = useState(false);
    const [Loading,setLoading] =useState(true)
    const [FilteredData,SetFilteredData]=useState([]);
    const [SearchItem,setSearchItem]=useState('');

    const  bar_options = {
        chart: {
             type: 'bar',
             height:'350px'
        },
        plotOptions: {
          bar: {
            columnWidth:'50%',
            endingShape: 'rounded',  
            dataLabels: {
              position: 'top', // top, center, bottom
            },  
            stroke: {
              width: 2
            },
          }
        },
        xaxis: {
          tickPlacement: 'on',
            categories: [
              'OCA', 'ŞUB', 'MAR', 'NIS', 'MAY', 'HAZ', 'TEM', 'AGU', 
                'EYL', 'EKI', 'KAS','ARA'],
            position: 'top',
            axisBorder: {
              show: false
            },

            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                }
              }
            },

        },
 
          dataLabels: { 
            enabled: false,                   
          }, 
         tooltip:{
            enabled: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                return '<Text>'+ formatter.format(series[seriesIndex][dataPointIndex])+'</Text>'
              }
         }
        }


    useEffect(() => {
        const OnLoad =()=>{
            const date = moment(new Date())
            setMonth(date.month());
            setYear(date.year());
            setSelectedDate(date.format('YYYY-MM-DD'));
            _GetLogoLines();
         }       
        return OnLoad();
    }, [])

    const _GetLogoLines= async ()=>{
        setLoading(true)

        let url =  api_url+'/logoSelectRows/ZZZ_ST_TAHSILATLAR';
        let response  = await (await   fetch(url,{
                method:'POST',
                cache: 'no-cache',
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                body: 'token='+loginToken+'&type=user',
            })).json();

            _Transpose(response);
            setLogoData(response)
            return response
    }

 const _Transpose = (data) => {

        let department = [];
        let banks = [];
        let curr = [];
        let month = [];
        let year = [];
 
    data.map(row=>{
        department.push(row.GRUP);
        banks.push(row.BANKA);
        month.push(moment(row.TARIH).month());
        year.push(moment(row.TARIH).year());
         curr.push(row.DVZ);
    });

    let u_department = [...new Set(department)];
    let u_banks = [...new Set(banks)];
    let u_curr = [...new Set(curr)];
    let u_month = [...new Set(month)];
    let u_year = [...new Set(year)];

    const select_year = []
    u_year.map(year=> select_year.push({value:year,text:year}))
    setYears(select_year)
    setBanks(u_banks)
    setDepartments(u_department)
    const sum_data = []
    const chart_data =[]

    u_year.map(year=>{
        const year_filtered = data.filter(row=>moment(row.TARIH).year()===year&&row.DVZ==='TL')
        const year_total = year_filtered.reduce((a,b)=>a+parseFloat(b.TAHSİLATLAR),0)
        const year_row ={
            "year":year,
            "year_total":year_total,
        }

        const monthly_data = [];
  
        u_month.map(month=>{
            const month_filtered = data.filter(row=>moment(row.TARIH).year()===year&&row.DVZ==='TL'&&moment(row.TARIH).month()===month);
            const month_row ={
                [`${year}_${month}`]:{
                    month_total: month_filtered.reduce((a,b)=>a+parseFloat(b.TAHSİLATLAR),0),
                    month_data:month_filtered
                }
            }

            monthly_data.push( Math.round(month_filtered.reduce((a,b)=>a+parseFloat(b.TAHSİLATLAR),0)))

            // u_banks.map(bank=>{
            //     const bank_filtered = data.filter(row=>moment(row.TARIH).year()===year&&row.DVZ==='TL'&&moment(row.TARIH).month()===month&&row.BANKA===bank);
            //     const bank_row ={
            //         [`${year}_${month}_${bank}`]:{
            //             bank_total:bank_filtered.reduce((a,b)=>a+parseFloat(b.TAHSİLATLAR),0),
            //             bank_data:bank_filtered
            //         }}
            //     Object.assign(month_row,bank_row);
            //   })
        
            // u_department.map(department=>{
            //     const dept_filtered = data.filter(row=>moment(row.TARIH).year()===year&&row.DVZ==='TL'&&moment(row.TARIH).month()===month&&row.GRUP===department);
            //     const dept_row ={
            //         [`${year}_${month}_${department}`]:{
            //             dept_total:dept_filtered.reduce((a,b)=>a+parseFloat(b.TAHSİLATLAR),0),
            //             dept_data:dept_filtered
            //         }}
            //     Object.assign(month_row,dept_row);
            //   })

            Object.assign(year_row,month_row)
        })
        sum_data.push(year_row)
        chart_data.push({name:year,data:monthly_data})
    })
     setData(sum_data);
    setLogoData(data);
    setLoading(false);
    setMonthlyData(chart_data)


 }
 const SelecPicker = ({selectData,Change,disabled,Value,width}) => { 

    return (
    <Select style={{width:width}} onChange={Change}   disabled={disabled} defaultValue={Value}  >
          {
            selectData.map((row,index)=>{
                return ( 
                    <Option key={index}   value={row.value} style={{textAlign:'center'}}>{row.text}</Option>
                )
            })
         }
    </Select>
    )
}

const _RowTable = ()=>{

        const row_data = Data.filter(row=>row.year===Year)[0][`${Year}_${Month}`].month_data
        const bank_data =[];
        const dept_data =[];
        const today_data=LogoData.filter(row=>moment(row.TARIH).format('YYYY-MM-DD')===moment(SelectedDate).format('YYYY-MM-DD'))
             Banks.map(bank=>{
                const bank_filtered = row_data.filter(row=>moment(row.TARIH).year()===Year&&row.DVZ==='TL'&&moment(row.TARIH).month()===Month&&row.BANKA===bank);
                 bank_data.push({  name: bank,  value :bank_filtered.reduce((a,b)=>a+parseFloat(b.TAHSİLATLAR),0)   })

               })
        
            Departments.map(department=>{
                const dept_filtered = row_data.filter(row=>moment(row.TARIH).year()===Year&&row.DVZ==='TL'&&moment(row.TARIH).month()===Month&&row.GRUP===department);
                dept_data.push({ name: department, value:dept_filtered.reduce((a,b)=>a+parseFloat(b.TAHSİLATLAR),0)   })
               })
        
               const Columns =[
                   {
                       title:'Başlık',
                       dataIndex:'name'
                   },
                   {
                    title:'Toplam Tutar',
                    dataIndex:'value',
                    align:'right',
                    render: (row,record) => formatter.format(record.value)
                }
               ]


               const Columns2 =[
                {
                    title:'Tarih',
                    render: (row,record) => moment(record.TARIH).format('DD.MM.YYYY'),
                    align:'center',
                },
                {
                    title:'Banka',
                    dataIndex:'BANKA',
                     align:'center',
                },
                {
                    title:'Grup',
                    dataIndex:'GRUP',
                     align:'center',
                },
                {
                    title:'Satır Açıklaması',
                    dataIndex:'CH UNVANI/DETAY',
                    align:'left',
                },
                {
                    title:'Toplam Tutar',
                    align:'right',
                    render: (row,record) => formatter.format(record.TAHSİLATLAR)
                }
            ]

            const match_str = new RegExp('('+SearchItem+')','i');
           
    return (   
         <Row gutter={24}>
            
             <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Table dataSource={bank_data} columns={Columns} size='small' pagination={false} rowKey={row=>row.name}
                summary={(pageData)=>
                        <Table.Summary.Row>
                            <Table.Summary.Cell align='right'><Text strong>Toplam</Text></Table.Summary.Cell>
                            <Table.Summary.Cell align='right'>{formatter.format(pageData.reduce((a,b)=>a+ parseFloat(b.value),0))}</Table.Summary.Cell>
                        </Table.Summary.Row>
                    }
                    />
             </Col>
             <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Row>   
                        <Col xl={24} lg={24} md={12} sm={24} xs={24}>
                            <Chart   options={bar_options} series={MonthlyData} type="bar" height='250px' />
                        </Col>
                        <Col xl={24} lg={24} md={12} sm={24} xs={24}>
                            <Table dataSource={dept_data} columns={Columns} size='small' pagination={false}  rowKey={row=>row.name}
                                summary={(pageData)=>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell align='right'><Text strong>Toplam</Text></Table.Summary.Cell>
                                        <Table.Summary.Cell align='right'>{formatter.format(pageData.reduce((a,b)=>a+ parseFloat(b.value),0))}</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                }
                            />
                        </Col>
                    </Row>
             </Col>
             <Divider>Günlük Tahsilatlar</Divider>
             <Divider><DatePicker value={moment(SelectedDate)} onChange={(x)=>setSelectedDate(moment(x).format('YYYY.MM.DD'))} format={'DD.MM.YYYY'} locale={locale} allowClear={false}/></Divider>
             <Col xl={24} lg={24} md={12} sm={24} xs={24}>
                <Input.Search placeholder={'Arama Yapınız.'} onChange={(x)=>setSearchItem(x.target.value)}/>
                <Divider></Divider>
                <Table dataSource={today_data.filter((row)=>row.BANKA.match(match_str)||row.GRUP.match(match_str)||row['CH UNVANI/DETAY'].match(match_str))} columns={Columns2}  size='small' pagination={{pageSize:10}} />
             </Col>
         </Row>
     )
}



    return (
        <div className="main">
            <div className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel" style={{padding:20}}>
                                <Row>
                                    <Col span={24}>
                                        <PageHeader
                                            title={'Tahsilatlar |'}
                                            subTitle={'Liste'}
                                            extra={
                                                [
                                                        <SelecPicker key='select1' selectData={Months} Change={(x)=>setMonth(x)} width='150px' Value={Month}/>,
                                                        <SelecPicker key='select2' selectData={Years} Change={(x)=>setYear(x)} width='150px' Value={Year}/>,
                                                     // <Button key='add' shape='round'  onClick={()=>setAddModalVisible(true)}   type='default'  ><PlusCircleOutlined /></Button>
                                                    <ExportFromJson data={SearchItem===''?Data:FilteredData} fileName={`firma_cekleri_${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}`} exportType={'xls'}/>

                                                ]
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        {Loading ? <Spin>Veri Yukleniyor</Spin>:_RowTable()
                                         }
                                    
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
 }



export default connect(mapStateToProps)(DebitsList);