import React from 'react';
import {connect} from 'react-redux';
import {alertMessage} from '../../assets';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
import Axios from "axios";
import {Row,Col,Divider} from 'antd';
 class CustomerAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            advance: '0',
            forward: '0',
            content: 'Otomatik olarak tarafınıza atama yapılmıştır. Lütfen en kısa sürede müşteriye dönüş sağlayınız.',
            smsid: null,
            smscode: null,
            kvkkConfResult:null,
            kvkkConfStatus: false,
            kvkk_confirmation: null,
            id_com_kvkk_status: null,
            id_com_kvkk: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.Verification = this.Verification.bind(this)
    }
    async Verification(e) {
        const name = e.target.name;
        const value = e.target.value;
        var element = document.getElementsByName(name)[0];

        switch (name) {
            case 'taxno':
                let taxno = value.trim()
                let url_taxno = this.props.api_url + '/selectRows/com_customer';
                await fetch(url_taxno, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&taxno=' + taxno,
                }).then(res => res.json())
                    .then(result => {
                        if (result.length === 0 && taxno.length > 9) {
                            this.setState({[`${name}`]: taxno, disabled: false});
                            element.setAttribute("style", "border-color: green;border-width: 2;")
                        } else {
                            this.setState({[`${name}`]: '', disabled: true});
                            element.setAttribute("style", "border-color: red;");
                        }
                    })
                break;
            case 'phone1':
                let phone1 = value.trim();
                this.setState({phone1: phone1})
                let url_phone1 = this.props.api_url + '/selectRows/com_customer';
                await fetch(url_phone1, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&phone1=' + phone1,
                }).then(res => res.json())
                    .then(result => {
                         if (result.length === 0 && phone1.length === 11) {
                            this.setState({[`${name}`]: phone1, disabled: false});
                            element.setAttribute("style", "border-color: green;border-width: 2;");
                        } else {
                            this.setState({[`${name}`]: '', disabled: true});
                            element.setAttribute("style", "border-color: red;");
                        }
                    })
                break;
            case 'email':
                let email = value.toLowerCase().trim()
                this.setState({email: email})
                let url_email = this.props.api_url + '/selectRows/com_customer';
                await fetch(url_email, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&email=' + email,
                }).then(res => res.json())
                    .then(result => {
                        if (result.length === 0 && email.includes('@')) {
                            this.setState({[`${name}`]: email, disabled: false});
                            element.setAttribute("style", "border-color: green;border-width: 2;");
                        } else {
                            this.setState({[`${name}`]: '', disabled: true});
                            element.setAttribute("style", "border-color: red;");

                        }
                    })
                break;
            default:
        }
    }
    handleChange(event, selectlist, name) {
        if (selectlist) {
            this.setState({[`${name}`]: event.id})
        } else {
            const value = event.target.value;
            const key = event.target.name;
            const type = event.target.type;
            if (type === 'checkbox') {
                this.setState({[`${key}`]: event.target.checked ? '1' : '0'})
                console.log(event.target.checked)
            } else {
                this.setState({[`${key}`]: value})
            }

        }
    }
    kvkkSmsSend = async () => {
        try {
            let postData = {
                // token: this.props.loginToken,
                phone: this.state.phone1,
                id_com_user: this.props.id_com_user
            }
            const response = await Axios.post(this.props.api_url + '/kvkkSms', postData)
            // console.log(response.data);
            this.setState({
                smsid: response.data.smsid
            })
        } catch (error) {
            console.error(error);
        }
    }
    kvkkConfirmation = async () => {
        try {
            let postData = {
                // token: this.props.loginToken,
                smscode: this.state.smscode
            }
            const response = await Axios.post(this.props.api_url + '/kvkkConfirmation', postData)
            console.log(response.data);
            if (response.data.status === true){
                this.setState({
                    id_com_kvkk_status: 1,
                    id_com_kvkk: 2
                })
            }
            this.setState({
                kvkkConfResult: response.data.message,
                kvkkConfStatus: response.data.status,
                kvkk_confirmation: response.data.kvkk_confirmation
            })
        } catch (error) {
            console.error(error);
        }
    }

    handleSubmit(event) {
        let d = new Date();
        let date = d.toISOString();
        let c_date = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let insert_body =
            'token=' + this.props.loginToken +
            '&c_date=' + c_date +
            '&c_time=' + c_time +
            '&id_com_user=' + this.props.id_com_user +
            '&type=user&id_com_company=1&status=1';
        for (let [key] of Object.entries(this.state)) {
            if (
                key !== 'content' &&
                key !== 'disabled' &&
                key !== 'forward' &&
                key !== 'advance' &&
                key !== 'r_id_com_user' &&
                key !== 'id_com_interview_type' &&
                key !== 'id_com_interview_subject' &&
                key !== 'id_com_brand' &&
                key !== 'id_com_model' &&
                key !== 'r_id_com_user' &&
                key !== 'id_com_department' &&
                key !== 'smsid' &&
                key !== 'smscode' &&
                key !== 'kvkkConfResult' &&
                key !== 'kvkkConfStatus'
            ) {
                insert_body += `&${key}=` + this.state[`${key}`]
            }
        }
        let com_customer_url = this.props.api_url + '/insert/com_customer';
       
        fetch(com_customer_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: insert_body,
        })
            .catch(error => console.log(error)).then(res => res.json()).then(res => {
            if (res.status) {
                        if( this.state.forward==='1') {

                            let id_com_customer = res.id;
                            let d = new Date();
                            let date = d.toISOString();
                            let c_date = date.split('T')[0];
                            let c_time = d.toLocaleTimeString('tr-TR');
                            let interview_number = Math.floor(Math.random() * 10000000) + 1000000;
            
                            let insert_interview_body =
                                'token=' + this.props.loginToken +
                                '&interview_number=' + interview_number +
                                '&c_date=' + c_date +
                                '&c_time=' + c_time +
                                '&id_com_user=' + this.state.r_id_com_user +
                                '&id_com_meet=' + this.state.id_com_meet +
                                '&id_com_customer=' + id_com_customer +
                                '&id_com_interview_subject=' + this.state.id_com_interview_subject +
                                '&id_com_interview_type=' + this.state.id_com_interview_type +
                                '&id_com_activity=' + this.state.id_com_activity +
                                '&id_com_brand=' + this.state.id_com_brand +
                                '&id_com_model=' + this.state.id_com_model +
                                '&type=user&status=0&id_com_interview_status=1&forwarded=1';
            
                            let com_interview_url = this.props.api_url + '/insert/com_interview';
                            fetch(com_interview_url, {
                                method: 'POST',
                                cahce: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                },
                                body: insert_interview_body,
                            })
                                .catch(error => console.log(error)).then(res => res.json()).then(response => {
                                let id_com_interview = response.id;
                                let insert_lines =
                                    'token=' + this.props.loginToken +
                                    '&id_com_interview=' + id_com_interview +
                                    '&c_date=' + c_date +
                                    '&c_time=' + c_time +
                                    '&id_com_meet=' + this.state.id_com_meet +
                                    '&id_com_user=' + this.props.id_com_user +
                                    '&content=' + this.state.content +
                                    '&type=user&testdrive=0&id_com_expertise=0';
                                 let com_interview_lines_url = this.props.api_url + '/insert/com_interview_lines';
                                fetch(com_interview_lines_url, {
                                    method: 'POST',
                                    cahce: 'no-cache',
                                    headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded'
                                    },
                                    body: insert_lines,
                                }).catch(e => console.log(e))
                                    .then(res => window.location.href = '/')
                            })
                        } 
                        else {
                            window.location.href='/Customer/List'
                        }

              
            } else {
                alertMessage('Hata oluşmuştur. Lütfen Tekrar Deneyiniz.')
            }
            // window.location.href='/Customer/List'
        });
    }


    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h3 className="panel-title">Müşteri Ekle</h3>
                                        </div>
                                        <div className="panel-body">
                                            <Row gutter={24}>
                                                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                                        <SelectList table='com_customer_type' label={'Müşteri Tipi'}   color= 'red'
                                                                name={'id_com_customer_type'}
                                                                onChange={(x) => this.handleChange(x)}/>
                                                        <div className="form-group">
                                                            <label>Firma Adı</label>
                                                            <input type="text" className="form-control" name="company_name"
                                                                onChange={(x) => this.handleChange(x)} placeholder="Başlık..."
                                                                required/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label style={{color: 'red'}}>Adı</label>
                                                            <input type="text" className="form-control" name="name"
                                                                onChange={(x) => this.handleChange(x)} placeholder="Başlık..."
                                                                required/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label style={{color: 'red'}}>Soyadı</label>
                                                            <input type="text" className="form-control" name="surname"
                                                                onChange={(x) => this.handleChange(x)} placeholder="Başlık..."
                                                                required/>
                                                        </div>
                                                        <div className="form-group">
                                                        <label style={{color: 'red'}}>Tel 1</label>
                                                        {this.state.kvkkConfStatus === true?
                                                            <input type="text" className="form-control" name="phone1"
                                                                onChange={(x) => this.Verification(x)} placeholder="Başlık..."
                                                                required disabled
                                                            />
                                                        :
                                                            <input type="text" className="form-control" name="phone1"
                                                                onChange={(x) => this.Verification(x)} placeholder="Başlık..."
                                                                required
                                                            />
                                                        }

                                                    </div>


                                                </Col>
                                                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                                    <SelectList table='com_city' label={'İl'} name={'id_com_city'}
                                                        onChange={(x) => this.handleChange(x)}/>
                                                    <SelectList table='com_town' id_com_city={this.state.id_com_city}
                                                                label={'İlçe'} name={'id_com_town'}
                                                                onChange={(x) => this.handleChange(x)}/>
                                                    <SelectList table='com_meet' label={'Temas Türü'} name={'id_com_meet'}
                                                                onChange={(x) => this.handleChange(x)}/>
                                                    <div className="form-group">
                                                            <label>Email</label>
                                                            <input type="email" className="form-control" name="email"
                                                                onChange={(x) => this.Verification(x)} placeholder="Başlık..."
                                                                required/>
                                                        </div>
                                                 
                                                </Col>
                                            </Row>
                                            {this.state.advance === '1' &&  
                                            <Row gutter={24}>
                                                    <Divider>Detaylı Kart Bilgileri</Divider>
                                                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                                <div className="form-group">
                                                        <label>Tel 2</label>
                                                        <input type="text" className="form-control" name="phone2"
                                                            onChange={(x) => this.handleChange(x)}
                                                            placeholder="Başlık..." required/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Vergi Dairesi</label>
                                                        <input type="text" className="form-control" name="tax_office"
                                                            onChange={(x) => this.handleChange(x)}
                                                            placeholder="Başlık..." required/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Vergi No / TCK No</label>
                                                        <input type="number" className="form-control" name="taxno"
                                                            onChange={(x) => this.Verification(x)}
                                                            placeholder="Başlık..." required/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Doğum Tarihi</label>
                                                        <input type="date" className="form-control" name="birthday"
                                                            onChange={(x) => this.handleChange(x)}
                                                            placeholder="Başlık..." required/>
                                                    </div>



                                                    <div className="form-group">
                                                        <label>Açıklama</label>
                                                        <textarea type="text" row='10' className="form-control"
                                                                name="comment" onChange={(x) => this.handleChange(x)}
                                                                placeholder="Başlık..." required/>
                                                    </div>
                                                </Col>
                                                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                                    <div className="form-group">
                                                        <label>Adres</label>
                                                        <textarea type="text" row='10' className="form-control"
                                                                name="adress" onChange={(x) => this.handleChange(x)}
                                                                placeholder="Başlık..." required/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Posta Kodu</label>
                                                        <input type="number" className="form-control" name="postcode"
                                                            onChange={(x) => this.handleChange(x)}
                                                            placeholder="Başlık..." required/>
                                                    </div>
                                                    <SelectList table='com_meet' label={'Temas Türü'} name={'id_com_meet'}
                                                                onChange={(x) => this.handleChange(x)}/>
                                                    <SelectList table='com_gender' label={'Cinsiyeti'}
                                                                name={'id_com_gender'}
                                                                onChange={(x) => this.handleChange(x)}/>
                                                    <SelectList table='com_education' label={'Eğitim Seviyesi'}
                                                                name={'id_com_education'}
                                                                onChange={(x) => this.handleChange(x)}/>
                                                    <SelectList table='com_sector' label={'Mesleği / Görevi'}
                                                                name={'id_com_sector'}
                                                                onChange={(x) => this.handleChange(x)}/>
                                                  

                                                </Col>
                                            </Row>
                                            }
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="check2"
                                                       name='advance' onChange={(x) => this.handleChange(x)}/>
                                                <label className="form-check-label" htmlFor="check2" style={{marginLeft: 10,}}>Detaylı Kayıt</label>
                                            </div>
 
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="check1"
                                                       name='forward' onChange={(x) => this.handleChange(x)}
                                                       disabled={!this.state.phone1 || !this.state.id_com_customer_type || !this.state.name || !this.state.surname}/>
                                                <label className="form-check-label" htmlFor="check1" style={{marginLeft: 10,}}>Görüşme
                                                    Atama Yap</label>
                                            </div>

                                            {this.state.forward === '1' ?
                                                <Row gutter={24}>
                                                    <Divider>Yönlendirilen Görüşme Notu Bilgileri</Divider>
                                                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                                    <SelectList table='com_interview_type' label={'Görüşme Tipi'}
                                                                name={'id_com_interview_type'}
                                                                onChange={(x) => this.handleChange(x)}/>
                                                    <SelectList table='com_interview_subject'
                                                                id_com_interview_type={this.state.id_com_interview_type}
                                                                label={'Görüşme Konusunu Seçiniz'}
                                                                name={'id_com_interview_subject'}
                                                                onChange={(x) => this.handleChange(x)}/>

                                                    <SelectList table='com_department' label={'Ilgili Departman'}
                                                                name={'id_com_department'}
                                                                onChange={(x) => this.handleChange(x)}/>
                                                    <SelectList table='view_user_sl' 
                                                    id_com_brand={this.state.id_com_interview_type==='4'?'61':this.state.id_com_interview_type==='2'?'64':this.state.id_com_brand} 
                                                    id_com_department={this.state.id_com_department} 
                                                    label={'Atanacak Personel'} 
                                                    name={'r_id_com_user'}
                                                     onChange={(x) => this.handleChange(x)}/>
                                                       {this.state.id_com_interview_type === '1' && this.state.id_com_interview_subject === '2' ?
                                                        <>
                                                            <SelectList table='view_activity_sl'
                                                                        id_com_interview_type={this.state.id_com_activity}
                                                                        label={'Aktivite Tipi'} name={'id_com_activity'}
                                                                        onChange={(x) => this.handleChange(x)}/>
                                                        </>
                                                        :
                                                        <></>}
                                                    </Col>

                                                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                                    {this.state.id_com_interview_type === '1' && this.state.id_com_interview_subject === '1' ?
                                                        <>
                                                            <SelectList table='com_brand' dealer={true}
                                                                        label={'İlgilendiği Araç Markası'}
                                                                        name={'id_com_brand'}
                                                                        onChange={(x) => this.handleChange(x)}/>
                                                            <SelectList table='com_model'
                                                                        id_com_brand={this.state.id_com_brand}
                                                                        label={'İlgilendiği Araç Modeli'}
                                                                        name={'id_com_model'}
                                                                        onChange={(x) => this.handleChange(x)}/>
                                                        </>
                                                        :
                                                        <></>}
                                                

                                                        <div className="form-group">
                                                        <label>Not : </label>
                                                        <textarea type="text" style={{height: 200}} row='10'
                                                                  value={this.state.content} className="form-control"
                                                                  name="content" onChange={(x) => this.handleChange(x)}
                                                                  required/>
                                                        </div>

                                                    </Col>
                                                  


                                                </Row>
                                                : <>

                                                </>
                                            }

                                        </div>
                                        <div className="panel-footer">
                                            <a className="btn btn-danger" href="/Customer/List"
                                               style={{margin: 5}}>İptal</a>
                                            {/*{this.state.forward==='0'? <button className="btn btn-primary pull-right" style={{margin:5}}  disabled={this.state.disabled}  onClick={()=>this.handleSubmit2()}  >Kaydet / Görüşme Notu Oluştur </button>:<></>}*/}
                                            <button className="btn btn-success pull-right" style={{margin: 5}}
                                                    disabled={this.state.disabled}
                                                    onClick={() => this.handleSubmit()}>Kaydet
                                            </button>
                                            {this.state.phone1 ?
                                                <button
                                                    type="button"
                                                    className="btn btn-default pull-right"
                                                    style={{margin: 5}}
                                                    data-toggle="modal"
                                                    data-target="#kvkkModal"
                                                    onClick={() => this.kvkkSmsSend()}
                                                >KVKK SMS Gönder</button>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="kvkkModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title" id="myModalLabel">KVKK SMS Doğrulama</h4>
                            </div>
                            <div className="modal-body">
                                {this.state.smsid != null ?
                                    <React.Fragment>
                                        <div className={"form-grup"}>
                                            <label>SMS Kodu</label>
                                            <input
                                                type="text"
                                                className={"form-control"}
                                                name="smscode"
                                                placeholder={"Sms Doğrulama Kodu"}
                                                required={true}
                                                onChange={(x) => this.handleChange(x)}
                                            />
                                        </div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className={"alert alert-info text-center"}>
                                            <i className={"fas fa-spin fa-spinner fa-2x"}></i>
                                            <p className={"lead"}>SMS Gönderiliyor. Lütfen bekleyiniz...</p>
                                        </div>
                                    </React.Fragment>
                                }
                                {this.state.kvkkConfResult ?
                                    <div className={"alert alert-success text-center"}>
                                        <i className={"fas fa-check fa-2x"}></i>
                                        <p className={"lead"}>{this.state.kvkkConfResult}</p>
                                        <br/>
                                        <button type={"button"} className={"btn btn-success"}
                                                data-dismiss="modal">Kapat
                                        </button>
                                    </div>
                                    : null
                                }
                            </div>
                            {this.state.smscode ?
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-default" data-dismiss="modal">İptal
                                    </button>
                                    <button
                                        type={"button"}
                                        className="btn btn-primary"
                                        onClick={() => this.kvkkConfirmation()}
                                    >Doğrula</button>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(CustomerAdd);