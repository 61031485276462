
const initialState = {

      api_url : 'https://senturkotomotiv.com.tr/api/api',
      media_url: 'https://senturkotomotiv.com.tr/api/media/docs/',
      id_com_user :'',
      id_com_department:'',
      id_com_brand:'',
      id_com_company:'',
      loginToken:'',
      email:'',
      password:'',
      username:'',
      id_com_user_type:'',


}


const constants = (state = initialState,action) => {
      switch(action.type) {

          case 'LOG_OUT':
            window.location.href = '/';
            return  state=initialState;

          case 'ADD_USERNAME':
              return  {...state, username:action.username}

           case 'ADD_LOGIN_TOKEN':
           return  {...state, loginToken:action.token}

           case 'ADD_ID_COM_USER':
           return  {...state, id_com_user:action.id_com_user}

           case 'ADD_ID_COM_USER_TYPE':
           return  {...state, id_com_user_type:action.id_com_user_type}

           case 'ADD_ID_COM_DEPARTMENT':
           return  {...state, id_com_department:action.id_com_department}

           case 'ADD_ID_COM_BRAND':
           return  {...state, id_com_brand:action.id_com_brand}

           case 'ADD_EMAIL':
           return  {...state, email:action.email}

           case 'ADD_PASSWORD':
           return  {...state, password:action.password}

           case 'ADD_ID_COM_COMPANY':
           return  {...state, id_com_company:action.id_com_company}

           default:
                 return state
      }
}

export default constants;
