import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';

class StockAddNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          
        }
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }
    componentDidMount(){
        
       

        this.setState({
            chasis: this.props.data.SASE2,
            id_com_brand: this.props.id_com_brand,
            comment:this.props.data.MODEL,
            date: this.props.data.FATURA_TARIHI,
            inv_price:  this.props.data.ALIS_TUTARI,
            inv_number:this.props.data.ALIS_FATURA_NO,
            spec:this.props.data.MODEL,
        })

    }

    handleChange(event,selectlist,name) {
        if(selectlist) { 
            this.setState({ [`${name}`]:event.id})
        } else { 
            const value = event.target.value;
            const key = event.target.name;
            this.setState({ [`${key}`]:value})
        }
        // this.setState({disabled:false})
    }


    handleSubmit(event) {
        let d = new Date(); 
        let date = d.toISOString();
        let c_date  = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');

        let insert_body = 
        'token='+this.props.loginToken+
        '&c_date='+c_date+
        '&c_time='+c_time+
        '&id_com_user='+this.props.id_com_user+
        '&type=user&status=0&id_com_stock_type=1';
        

        for (let [key] of Object.entries(this.state)) {
            if(key!=='disabled'){ 
                insert_body +=`&${key}=`+ this.state[`${key}`]
            }
    }
    // console.log(insert_body)
    let com_stock_url=this.props.api_url+'/insert/com_stock';
    fetch(com_stock_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body:insert_body,
        })
        .catch(error=>console.log(error)).then(x=> {
            window.location.href='/stock/add'});

}

    render(){
        return ( 
      
        <React.Fragment>
           <div className="row">
           <div className="col-md-4">
         
               <SelectList table='com_brand' value={this.state.id_com_brand} disabled={true} label={'Marka'} name={'id_com_brand'} onChange={(x)=>this.handleChange(x )} />
               <SelectList table='com_model' id_com_brand={this.props.id_com_brand} label={'Model'} name={'id_com_model'} onChange={(x)=>this.handleChange(x )} />
               <SelectList table='com_gear'  label={'Vites Tipi'} name={'id_com_gear'} onChange={(x)=>this.handleChange(x )} />
                <SelectList table='com_fuel'  label={'Yakıt Tipi'} name={'id_com_fuel'} onChange={(x)=>this.handleChange(x )} />
                <SelectList table='com_color'  label={'Renk'} name={'id_com_color'} onChange={(x)=>this.handleChange(x )} />
              
            </div>
            <div className="col-md-4">
                <SelectList table='com_otv_rate'  label={'ÖTV oranı'} name={'id_com_otv_rate'} onChange={(x)=>this.handleChange(x )} />
                <div className="form-group">
                    <label>Şasi</label>
                    <input type="text" className="form-control" name="chasis" value={this.state.chasis} onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                </div>   
                <div className="form-group">
                    <label>Motor No</label>
                    <input type="text" className="form-control" name="engine_no" onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                </div>   
                <div className="form-group">
                    <label>Spek</label>
                    <input type="text" className="form-control" value={this.state.spec} name="spec" onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                </div>   
         
                <div className="form-group" >
                <label>Notlar : </label>
                <textarea type="text" style={{height:100}} row='10' value={this.state.comment} className="form-control" name="comment" placeholder={'Özel notlar var ise yazınız.'} onChange={(x) => this.handleChange(x)} required/>
                </div>

                </div>
            <div className="col-md-4">
           
            <div className="form-group">
                    <label>Fatura Tarihi</label>
                    <input type="text" className="form-control" name="date"  value={this.state.date}  onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                </div> 
                <div className="form-group">
                    <label>Model Yılı</label>
                    <input type="number" className="form-control" name="model_year" onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                </div>
            <div className="form-group">
                    <label>Alış Faturası</label>
                    <input type="text" className="form-control" name="inv_number" value={this.state.inv_number}   onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                </div> 
                <div className="form-group">
                    <label>Alış Tutarı</label>
                    <input type="text" className="form-control" name="inv_price" value={this.state.inv_price}   onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                </div> 
                <div className="form-group">
                    <label>Liste Fiyatı</label>
                    <input type="text" className="form-control" name="list_price"   onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                </div> 
                </div>
               
            </div>
            <div className="panel-footer">
                                    <a className="btn btn-danger" href="/Stock/add">İptal</a>

                                <button className="btn btn-primary pull-right"
                                disabled={this.state.list_price&&this.state.model_year&&
                                this.state.spec&&this.state.chasis&&this.state.id_com_otv_rate&&
                                this.state.id_com_color&&this.state.id_com_gear&&this.state.id_com_fuel
                                &&this.state.id_com_model
                                ?false:true
                                }
                                 onClick={()=>this.handleSubmit()} 
                                >Kaydet</button>
                                    
                                     </div>
        </React.Fragment>                    


        )}
}



export default connect(mapStateToProps)(StockAddNew);