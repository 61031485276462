import React, {Component} from 'react';
import PropTypes from "prop-types";
class Option extends Component {
    render() {
        const {option,value,optionChange,optKey,optionRemoveRow} = this.props
        return (
            <div className={"row"}>
                <div className={"col-md-7 form-group"}>
                    <label>Yanıt</label>
                    <input type={"text"} className={"form-control"} key={optKey} id={"option"} value={option} onChange={(e) => optionChange(optKey,e)} placeholder={"Yanıt"} />
                </div>
                <div className={"col-md-4 form-group"}>
                    <label>Yanıt Değer</label>
                    <input type={"text"} className={"form-control"} key={optKey} id={"value"} value={value} onChange={(e) => optionChange(optKey,e)} placeholder={"Yanıt Değer"} />
                </div>
                <div className={"col-md-1 form-group"}>
                    <button
                        type={"button"}
                        className={"btn btn-danger btn"}
                        style={{marginTop:25}}
                        onClick={(e) => optionRemoveRow(optKey)}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </button>
                </div>
            </div>
        );
    }
}
Option.defaultProps = {
    option : "",
    value : "",
}
Option.propTypes = {
    option : PropTypes.string.isRequired,
    value : PropTypes.string.isRequired,
}
export default Option;