import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';

class StockEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            data:[],
            pageloader:false,
        }
    }
    componentDidMount(){
     let id_com_stock = this.props.match.params.id_com_stock

     this.setState({ 
        id_com_stock: id_com_stock
    })

    let product_url=this.props.api_url+'/selectRows/view_stock';
    fetch(product_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body:'id='+id_com_stock+'&token='+this.props.loginToken+'&type=user'
        })
        .catch(error=>console.log(error))
        .then(res=>res.json())
        .then(result=>{
            let data = result[0];
        for (let [key, value] of Object.entries(data)) {
        this.setState({ [`${key}`]:value,pageloader:true
            })
        }
        })
      
let com_product_url=this.props.api_url+'/selectRows/com_stock';
fetch(com_product_url,{
        method:'POST',
        cahce: 'no-cache',
        headers:{ 
            'Content-Type':'application/x-www-form-urlencoded'
        },
        body:'id='+id_com_stock+'&token='+this.props.loginToken+'&type=user'
    })
    .catch(error=>console.log(error))
    .then(res=>res.json())
    .then(result2=>{
    this.setState({data:result2[0],pageloader:true})
    })



    }
  
    handleChange(event,selectlist,name) {
        if(selectlist) { 
            this.setState({ [`${name}`]:event.id})
            // console.log('id changed')
        } else { 
            const value = event.target.value;
            const key = event.target.name;
            this.setState({ [`${key}`]:value})
        }
        this.setState({disabled:false,})
    }


    handleSubmit(event) {
        let d = new Date(); 
        let date = d.toISOString();
        let m_date  = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');

        let update_body = 
        'token='+this.props.loginToken+
        '&m_date='+m_date+
        '&m_time='+m_time+
        '&m_id_com_user='+this.props.id_com_user+
        '&type=user';

        for (let [key] of Object.entries(this.state.data)) {
            if(key!=='m_id_com_user'&&key!=='m_date'&&key!=='m_time') {
                update_body +=`&${key}=`+ this.state[`${key}`]
            }
    }
    let com_stock_url=this.props.api_url+'/update/com_stock';

    fetch(com_stock_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body:update_body,
        })
        .catch(error=>console.log(error)).then(x=> {this.setState({disabled:false});
       window.location.reload()
    }
        )
        
}
    render(){
        return ( 
            <div className="main">
            <div className="main-content">
                <div className="container-fluid">
                <div className="panel">
                    <div className="panel-heading">
                        <h3 className="panel-title">Stok Düzenle</h3>
                    </div>
                    
                <div className="panel-body">
                <div className="row">
           <div className="col-md-4">
         
               <SelectList table='com_brand' value={this.state.id_com_brand}   label={'Marka'} name={'id_com_brand'} onChange={(x)=>this.handleChange(x )} />
               <SelectList table='com_model' id_com_brand={this.state.id_com_brand} label={'Model'} name={'id_com_model'} value={this.state.id_com_model}  onChange={(x)=>this.handleChange(x )} />
               <SelectList table='com_gear'  label={'Vites Tipi'} name={'id_com_gear'} value={this.state.id_com_gear}  onChange={(x)=>this.handleChange(x )} />
                <SelectList table='com_fuel'  label={'Yakıt Tipi'} name={'id_com_fuel'} value={this.state.id_com_fuel}  onChange={(x)=>this.handleChange(x )} />
                <SelectList table='com_color'  label={'Renk'} name={'id_com_color'} value={this.state.id_com_color} onChange={(x)=>this.handleChange(x )} />
              
            </div>
            <div className="col-md-4">
                <SelectList table='com_otv_rate'  label={'ÖTV oranı'} name={'id_com_otv_rate'} value={this.state.id_com_otv_rate} onChange={(x)=>this.handleChange(x )} />
                <div className="form-group">
                    <label>Şasi</label>
                    <input type="text" className="form-control" name="chasis" value={this.state.chasis} onChange={(x) => this.handleChange(x)} placeholder="Şasi No" required/>
                </div>   
                <div className="form-group">
                    <label>Motor No</label>
                    <input type="text" className="form-control" name="engine_no" value={this.state.engine_no}  onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                </div>   
                <div className="form-group">
                    <label>Spek</label>
                    <input type="text" className="form-control" value={this.state.spec} name="spec" onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                </div>   
         
                <div className="form-group" >
                <label>Notlar : </label>
                <textarea type="text" style={{height:100}} row='10' value={this.state.comment} className="form-control" name="comment" placeholder={'Özel notlar var ise yazınız.'} onChange={(x) => this.handleChange(x)} required/>
                </div>

                </div>
            <div className="col-md-4">
           
            <div className="form-group">
                    <label>Fatura Tarihi</label>
                    <input type="text" className="form-control" name="date"  value={this.state.date}  onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                </div> 
                <div className="form-group">
                    <label>Model Yılı</label>
                    <input type="number" className="form-control" name="model_year"  value={this.state.model_year} onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                </div>
            <div className="form-group">
                    <label>Alış Faturası</label>
                    <input type="text" className="form-control" name="inv_number" value={this.state.inv_number}   onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                </div> 
                <div className="form-group">
                    <label>Alış Tutarı</label>
                    <input type="text" className="form-control" name="inv_price" value={this.state.inv_price}   onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                </div> 
                <div className="form-group">
                    <label>Liste Fiyatı</label>
                    <input type="text" className="form-control" name="list_price"   value={this.state.list_price} onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                </div> 
                </div>
               
            </div>
            <div className="panel-footer">
                                    <a className="btn btn-danger" href="/Stock/list">İptal</a>
                                <button className="btn btn-primary pull-right"
                                disabled={this.state.disabled}
                                 onClick={()=>this.handleSubmit()} 
                                >Kaydet</button>
                                    
                                     </div>
                </div>
            </div>
        </div>
        </div>
        </div>

        )}
}



export default connect(mapStateToProps)(StockEdit);