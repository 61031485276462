import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import {alertMessage, dateTime, datePrint} from '../../assets.js';

class ColorAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: false,
            data: [],
            actionResult: null
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState(state => {
            const newData = state.data[name] = value;
            return {
                newData
            };
        });
    }

    handleSubmit(event) {
        this.setState({actionResult: alertMessage('loadingData')});
        event.preventDefault();
        const data = new FormData(event.target);
        data.append('token', this.props.loginToken);
        data.append('id_com_user', this.props.id_com_user);
        data.append('created', dateTime());
        var urlencoded = new URLSearchParams();
        urlencoded = data;
        var requestOptions = {
            method: 'POST',
            body: urlencoded
        };
        fetch(this.props.api_url + "/insert/com_color", requestOptions)
            .then(response => response.json())
            .then(result => {
                result.status === true ?
                    this.setState({actionResult: alertMessage('insertSuccess')})
                    :
                    this.setState({actionResult: alertMessage('insertError')})
            })
            .catch(error => console.log('error', error));
    }

    componentDidMount() {

    }

    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3 className="page-title">Renk Seçenekleri</h3>
                                </div>
                                <div className="col-md-6 text-right">
                                    <a href="/color" className="btn btn-default">Renk Seçenekleri Listesi</a>
                                </div>
                                {this.state.actionResult ?
                                    <div className="col-md-12"
                                         dangerouslySetInnerHTML={{__html: this.state.actionResult}}></div>
                                    :
                                    <div></div>
                                }
                                <div className="col-md-12">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="panel">
                                            <div className="panel-heading">
                                                <div className="panel-title">Renk Ekle</div>
                                            </div>
                                            <div className="panel-body">
                                                <div className="form-group">
                                                    <label>Başlık</label>
                                                    <input type="text" className="form-control" name="title" onChange={(e) => this.handleChange(e)} placeholder="Başlık" required/>
                                                </div>
                                            </div>
                                            <div className="panel-footer"></div>
                                        </div>
                                        <button className="btn btn-danger">Kaydet</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(ColorAdd);