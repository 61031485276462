import React from 'react';
import {connect} from 'react-redux';
import DataTable from 'react-data-table-component';
import mapStateToProps from '../../redux/mapStateToProps';

class EditItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            edit: false
        }
        this._EditItem = this._EditItem.bind(this);
    }

    componentDidMount() {
        this.setState({text: this.props.data.text})
    }

    _EditItem() {
        let id = this.props.data.id;
        let text = this.state.text
        let token = this.props.loginToken
        let update = 'id=' + id + '&text=' + text + '&token=' + token + '&type=user';
        fetch(this.props.api_url + '/update/com_depot_shelf_section_part', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: update,
        })
            .then(function (response) {
                console.log('accline edited!')
            })
        this.setState({edit: true, text: ''})
    }

    render() {
        return (
            <div className="col-md-12 margin-top-30">
                <div className="form-group">
                    <input className="form-control" disabled value={this.props.data.id}/>
                </div>
                <div className="form-group">
                    <input disabled={this.state.edit} className="form-control" value={this.state.text} onChange={(x) => this.setState({text: x.target.value.toUpperCase()})}/>
                </div>
                <div className="form-group">
                    <button disabled={this.state.edit} className="btn btn-primary" onClick={() => this._EditItem()}>Kaydet</button>
                </div>
            </div>
        )
    }
}

class Level3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            pageloader: false,
            data: [],
            columns: [
                {
                    name: 'No:',
                    selector: 'id',
                    left: true,
                    width:'100px'
                },
                {
                    name: 'Açıklama',
                    selector: 'text',
                    left: true
                }
            ],
            AddItem: false
        }
        this._Update = this._Update.bind(this);
        this._AddItem = this._AddItem.bind(this);
    }

    componentDidMount() {
        this._Update();
    }

    _Update() {
        let acc_url = this.props.api_url + '/selectRows/com_depot_shelf_section_part';
        fetch(acc_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_depot=' + this.props.id_com_depot +
                '&id_com_company=' + this.props.id_com_company +
                '&id_com_depot_shelf=' + this.props.id_com_depot_shelf +
                '&id_com_depot_shelf_section=' + this.props.data.id +
                '&token=' + this.props.loginToken + '&type=user',
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                this.setState({data: result, pageloader: true})
            })
    }

    async _AddItem() {
        let insert =
            'text=' + this.state.text +
            '&id_com_depot=' + this.props.id_com_depot +
            '&id_com_depot_shelf=' + this.props.id_com_depot_shelf +
            '&id_com_depot_shelf_section=' + this.props.data.id +
            '&id_com_company=' + this.props.id_com_company +
            '&token=' + this.props.loginToken +
            '&type=user';
        let insert_url = this.props.api_url + '/insert/com_depot_shelf_section_part'
        await fetch(insert_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: insert,
        }).then(res => console.log('New ProductLine Added!'))
        this.setState({addItem: false, text: '', data: []})
        this._Update();
    }

    render() {
        return (
            <div style={{background: '#ffb8c5'}}>
                <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    highlightOnHover
                    noHeader
                    responsive
                    //  defaultSortField="id"
                    // dense
                    expandableRows={true}
                    expandableRowsComponent={<EditItem loginToken={this.props.loginToken}
                                                       api_url={this.props.api_url}/>}
                    subHeader={true}
                    subHeaderAlign={'right'}
                    subHeaderComponent={
                        <div className="form-group margin-top-30">
                            <button className="btn btn-default" onClick={() => this.setState({addItem: true})}>Ekle</button>
                        </div>
                    }
                />
                {this.state.addItem ?
                    <div className="col-md-12 margin-top-30">
                        <div className="form-group">
                            <input className="form-control" disabled value={this.state.data.length + 1}/>
                        </div>
                        <div className="form-group">
                            <input className="form-control" placeholder={'Açıklama'} value={this.state.text}
                                   onChange={(x) => this.setState({text: x.target.value.toUpperCase()})}/>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-danger" onClick={() => this.setState({addItem: false, text: ''})}>İptal</button> <button className="btn btn-primary" disabled={this.state.addItem === true && this.state.text === '' ? true : false} onClick={() => this._AddItem()}>Kaydet</button>
                        </div>
                    </div>
                    : <></>}
            </div>
        )
    }
}

class Level2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            pageloader: false,
            data: [],
            columns: [
                {
                    name: 'No:',
                    selector: 'id',
                    left: true,
                    width: '100px'
                },
                {
                    name: 'Açıklama',
                    selector: 'text',
                    left: true
                }
            ],
            AddItem: false
        }
        this._Update = this._Update.bind(this);
        this._AddItem = this._AddItem.bind(this);
    }

    componentDidMount() {
        this._Update();
    }

    _Update() {
        let acc_url = this.props.api_url + '/selectRows/com_depot_shelf_section';
        fetch(acc_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_depot=' + this.props.id_com_depot + '&id_com_company=' + this.props.id_com_company + '&id_com_depot_shelf=' + this.props.data.id + '&token=' + this.props.loginToken + '&type=user'
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                this.setState({data: result, pageloader: true})
            })
    }

    async _AddItem() {
        let insert =
            'text=' + this.state.text +
            '&id_com_depot_shelf=' + this.props.data.id +
            '&id_com_depot=' + this.props.id_com_depot +
            '&id_com_company=' + this.props.id_com_company +
            '&token=' + this.props.loginToken +
            '&type=user';
        let insert_url = this.props.api_url + '/insert/com_depot_shelf_section'
        await fetch(insert_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: insert,
        }).then(res => console.log('New ProductLine Added!'))
        this.setState({addItem: false, text: '', data: []})
        this._Update();
    }

    render() {
        return (
            <div style={{background: 'lightgreen'}}>
                <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    highlightOnHover
                    noHeader
                    responsive
                    // dense
                    expandableRows={true}
                    expandableRowsComponent={<Level3
                        api_url={this.props.api_url}
                        loginToken={this.props.loginToken}
                        id_com_depot={this.props.id_com_depot}
                        id_com_depot_shelf={this.props.data.id}
                        id_com_company={this.props.id_com_company}
                    />}
                    subHeader={true}
                    subHeaderAlign={'right'}
                    subHeaderComponent={
                        <div className="form-group margin-top-30">
                            <button className={"btn btn-default"} onClick={() => this.setState({addItem: true})}>Ekle</button>
                        </div>
                    }
                />
                {this.state.addItem ?
                    <div className="col-md-12 margin-top-30">
                        <div className="form-group">
                            <input className="form-control" disabled value={this.state.data.length + 1} />
                        </div>
                        <div className="form-group">
                            <input className="form-control" placeholder={'Açıklama'} value={this.state.text} onChange={(x) => this.setState({text: x.target.value.toUpperCase()})}/>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-danger" onClick={() => this.setState({addItem: false, text: ''})}>İptal</button> <button className="btn btn-primary" disabled={this.state.addItem === true && this.state.text === '' ? true : false} onClick={() => this._AddItem()}>Kaydet</button>
                        </div>
                    </div>
                    : <></>}
            </div>
        )
    }
}

class Level1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: false,
            data: [],
            columns: [
                {
                    name: 'No:',
                    selector: 'id',
                    left: true,
                    width: '100px'
                },
                {
                    name: 'Açıklama',
                    selector: 'text',
                    left: true
                }
            ],
            AddItem: false
        }
        this._Update = this._Update.bind(this);
        this._AddItem = this._AddItem.bind(this);
    }

    componentDidMount() {
        this._Update();
    }

    _Update() {
        let acc_url = this.props.api_url + '/selectRows/com_depot_shelf';
        fetch(acc_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_depot=' + this.props.data.id + '&id_com_company=' + this.props.id_com_company + '&token=' + this.props.loginToken + '&type=user'
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                this.setState({data: result, pageloader: true})
            })
    }

    async _AddItem() {
        let insert =
            'text=' + this.state.text +
            '&id_com_depot=' + this.props.data.id +
            '&id_com_company=' + this.props.id_com_company +
            '&token=' + this.props.loginToken +
            '&type=user';
        let insert_url = this.props.api_url + '/insert/com_depot_shelf'
        await fetch(insert_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: insert,
        }).then(res => console.log('New ProductLine Added!'))
        this.setState({addItem: false, text: '', data: []})
        this._Update();
    }

    render() {
        return (
            <div style={{background: 'lightblue'}}>
                <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    highlightOnHover
                    noHeader
                    responsive
                    // dense
                    expandableRows={true}
                    expandableRowsComponent={<Level2 api_url={this.props.api_url} loginToken={this.props.loginToken}
                                                     id_com_company={this.props.id_com_company}
                                                     id_com_depot={this.props.data.id}/>}
                    subHeader={true}
                    subHeaderAlign={'right'}
                    subHeaderComponent={
                        <div className="form-group margin-top-30">
                            <button className={"btn btn-default"} onClick={() => this.setState({addItem: true})}>Ekle</button>
                        </div>
                    }
                />
                {this.state.addItem ?
                    <div className="col-md-12 margin-top-30">
                        <div className="form-group">
                            <input className="form-control" disabled value={this.state.data.length + 1}/>
                        </div>
                        <div className="form-group">
                            <input className="form-control" placeholder={'Açıklama'} value={this.state.text} onChange={(x) => this.setState({text: x.target.value.toUpperCase()})}/>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-danger" onClick={() => this.setState({addItem: false, text: ''})}>İptal</button> <button className="btn btn-primary" disabled={this.state.addItem === true && this.state.text === '' ? true : false} onClick={() => this._AddItem()}>Kaydet</button>
                        </div>
                    </div>
                    : <></>}
            </div>
        )
    }
}

class DepotGroupList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageloader: false,
            columns: [
                {
                    name: 'No:',
                    selector: 'id',
                    left: true,
                    width: '100px'
                },
                {
                    name: 'Açıklama',
                    selector: 'text',
                    left: true
                }
            ]
        }
        this._Update = this._Update.bind(this)
    }

    componentDidMount() {
        this._Update();
    }

    _Update() {
        let product_url = this.props.api_url + '/selectRows/com_depot';
        fetch(product_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_company=' + this.props.id_com_company + '&token=' + this.props.loginToken + '&type=user'
        }).catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                this.setState({data: result, pageloader: true})
            })
    }

    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="page-title">Depo Tanımları</h3>
                                </div>
                                <div className="col-md-12">
                                    <div className={"panel"}>
                                        <div className={"panel-heading"}>
                                            <div className={"panel-title"}>Depo</div>
                                        </div>
                                        <div className={"panel-body"}>
                                            <DataTable
                                                columns={this.state.columns}
                                                noHeader={true}
                                                overflowY={false}
                                                overflowYOffset={'100px'}
                                                data={this.state.data}
                                                fixedHeader
                                                highlightOnHover
                                                responsive
                                                striped
                                                // dense
                                                paginationComponentOptions={{
                                                    rowsPerPageText: 'Sayfa başına:',
                                                    rangeSeparatorText: '-',
                                                    noRowsPerPage: false
                                                }}
                                                progressPending={!this.state.pageloader}
                                                pagination={true}
                                                paginationPerPage={20}
                                                defaultSortField={'id'}
                                                expandableRows={true}
                                                expandableRowsComponent={<Level1 api_url={this.props.api_url}
                                                                                 loginToken={this.props.loginToken}
                                                                                 id_com_company={this.props.id_com_company}/>}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(DepotGroupList);