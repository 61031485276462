import React ,{useState,useEffect} from 'react';

import {Divider, Drawer,Typography,Input ,Button,Table,Row,Col,PageHeader} from 'antd';
import { PlaySquareOutlined,StopOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import moment from 'moment';

const {Title,Text} = Typography

const ReportPhoneList = ({visible,OnClose,loginToken})=>{
    const [ListData,setListData]  = useState([]);
    const [FilteredData,setFilteredData]  = useState([]);
    const [SearchItem,setSearchItem]= useState('');
    const [Loading,setLoading]= useState(false);
    const [SelectedRow,setSelectedRow]= useState('');

    const Columns =[
        {
            title:'Tarih',
            // dataIndex:'date',
            render: (row,record)=>row.file_name.split('_')[0],
            sorter: (a, b) => parseInt(a.file_name.split('_')[0])  - parseInt(b.file_name.split('_')[0]),
        },

        {
            title:'Saat',
            dataIndex:'start_time',
            align:'center',

            render: (row,record)=> record.start_time,//   moment().seconds(record.start_time).format('H:mm:ss'),
            sorter: (a, b) =>  parseInt(a.start_time)   -  parseInt(b.start_time) ,
         },
         {
            title:'Arama Tipi',
            dataIndex:'call_type',
            align:'center',
            render: (row,record)=>record.call_type==='1'?'İç Arama':'Dış Arama'
        },
        {
            title:'Cevaplayan',
            dataIndex:'channel_caption',
            align:'center',
            sorter: (a, b) => a.channel_caption   - b.channel_caption ,
         },
         {
            title:'Telefon',
            dataIndex:'number',
            align:'center',

            sorter: (a, b) => a.number   - b.number ,
         },
         {
            title:'Süre (Sn)',
            dataIndex:'duration',
            align:'center',
            sorter: (a, b) => a.duration   - b.duration ,

         },
         {
            title:'Dinle',
            align:'center',

            render: (row,record)=>
             SelectedRow!==record.file_name ?

                    <Button size='small' shape='round' type='primary'   onClick={()=>setSelectedRow(record.file_name)}><PlaySquareOutlined/></Button>
                :
                <Row justify='space-between' gutter={6} align='middle'>
                    <Col >
                        <audio controls autoPlay >
                            <source src={'https://senturkotomotiv.com.tr/api/VRXApi/getRecord/'+record.file_name+'?token='+loginToken} type="audio/ogg"/>
                        </audio>
                    </Col>
                    <Col    >
                        <Button size='small' shape='round' type='primary' danger onClick={()=>setSelectedRow('')}><StopOutlined/></Button>
                    </Col>
                </Row>



         },

    ]




    useEffect(() => {
        const _GetData = ()=>{

            _SendRequest()
        }
       return _GetData();
   }, [visible])


   const _SendRequest = async ( )=>{
         setLoading(true);
    let url = 'https://senturkotomotiv.com.tr/api/VRXApi/getRecordList/';
    let body = '&token='+loginToken+'&type=user';
    let config = {  method:'POST',    cahce: 'no-cache',    headers:{     'Content-Type':'application/x-www-form-urlencoded'     },   body:body}
    try{
        let list = await (await fetch(url,config)).json();
         setListData(list.filter(row=>row.file_name!==''||row.duration!=='0'))
        setLoading(false);
    }
    catch {
        setListData([]);
         setLoading(false);
    }


    }

    const _Filter= (e)=>{
        setSearchItem(e)
       let match_str = new RegExp('('+e+')','i');
       let filteredData = ListData.filter((row)=>{ return row.number.match(match_str)||row.file_name.match(match_str)||row.channel_caption.match(match_str) })
       setFilteredData(filteredData)

   }



    return  (

            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <Row style={{marginBottom:10,padding:20}} gutter={12} justify="start">
                                        <Col md={24} sm={24} xs={24} style={{marginBottom: '5px',}}>
                                            <PageHeader title={'Santral |'} subTitle={'Aramalar'}  />
                                        </Col>
                                        <Col md={24} sm={24} xs={24} style={{padding:20}} >

                                            <Input.Search onChange={(x)=>_Filter(x.target.value)}/>
                                            <Divider></Divider>
                                            <Table  size='small' bordered  columns={Columns} loading={Loading} dataSource={SearchItem===''?ListData:FilteredData} pagination={{pageSize:20}} footer={()=>SearchItem!==''&&ListData.length===0?
                                            <Text type='danger' strong code>Kayıt Bulunamamıştır. Aranan Tel: {SearchItem}</Text>
                                            :
                                            <Text type='danger'  code > {ListData.length} Adet Kayıt Bulunamamıştır.</Text>
                                            }/>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    )
}

export default connect(mapStateToProps)(ReportPhoneList)  ;
