import React from 'react';
import {connect} from 'react-redux';
import DataTable from 'react-data-table-component';
import mapStateToProps from '../../redux/mapStateToProps';
class EditItem extends React.Component {
    constructor(props){
        super(props);
        this.state={
            text:'',
            edit:false
        }
        this._EditItem=this._EditItem.bind(this);
    }
    componentDidMount () {
        this.setState({text:this.props.data.text})
    }
    _EditItem(){
        let id = this.props.data.id;
        let text = this.state.text
        let token = this.props.loginToken
        let update = 'id='+id+'&text='+text+'&token='+token+'&type=user';
        fetch(this.props.api_url+'/update/com_account_plan_group_level4', {
            method: 'POST',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body: update,
        })
            .then(function(response) {
                console.log('accline edited!')
            })
        this.setState({edit:true,text:''})
    }
    render() {
        return (
            <div className="col-md-12 margin-top-30">
                <div className="form-group">
                    <input className="form-control" disabled value={this.props.data.nr} />
                </div>
                <div className="form-group">
                    <input disabled={this.state.edit} className="form-group" value={this.state.text} onChange={(x)=>this.setState({text:x.target.value.toUpperCase()})} />
                </div>
                <div className="form-group">
                    <button  disabled={this.state.edit} className="btn btn-primary" onClick={()=>this._EditItem()}>Kaydet</button>
                </div>
            </div>
        )
    }
}
class Level4 extends React.Component{
    constructor(props){
        super(props);
        this.state={
            pageloader:false,
            addItem:false,
            edit:false,
            data:[],
            text:'',
            columns:[
                {
                    name: 'No:',
                    selector: 'nr',
                    left:true,
                    width:'120px'
                },
                {
                    name: 'Açıklama',
                    selector: 'text',
                    left:true
                }
                ]
        }
        this._Update=this._Update.bind(this);
        this._AddItem=this._AddItem.bind(this);
    }
    componentDidMount() {
        this._Update();
    }
    async _Update() {
        let acc_url=this.props.api_url+'/selectRows/com_account_plan_group_level4';
        await fetch(acc_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body:'id_com_company='+this.props.id_com_company+'&id_com_account_plan_group_level3='+this.props.data.nr+'&token='+this.props.loginToken+'&type=user'
        }).then(res=>res.json())
            .then(result=>{
                this.setState({data:result,pageloader:true})
            })
    }
    async _AddItem() {
        let nr =   this.state.data.length>=9999?
            this.props.data.nr+'.'+(this.state.data.length+1):
            this.state.data.length>=999?
                this.props.data.nr+'.0'+(this.state.data.length+1):
                this.state.data.length>=99?
                    this.props.data.nr+'.00'+(this.state.data.length+1):
                    this.state.data.length>=9?
                        this.props.data.nr+'.000'+(this.state.data.length+1):
                        this.props.data.nr+'.0000'+(this.state.data.length+1);
        let text = this.state.text;
        let id_com_account_plan_group_level3 = this.props.data.nr.substring(0,6);
        let id_com_account_plan_group_level2 = this.props.data.nr.substring(0,3);
        let id_com_account_plan_group_level1 = this.props.data.nr.substring(0,2);
        let id_com_account_plan_main_class = this.props.data.nr.substring(0,1);
        let insert =
            'nr='+ nr+
            '&text='+ text+
            '&id_com_account_plan_group_level3='+ id_com_account_plan_group_level3+
            '&id_com_account_plan_group_level2='+ id_com_account_plan_group_level2+
            '&id_com_account_plan_group_level1='+ id_com_account_plan_group_level1+
            '&id_com_account_plan_main_class='+ id_com_account_plan_main_class+
            '&id_com_company='+this.props.id_com_company+
            '&token='+this.props.loginToken+
            '&type=user';
        let  insert_url =  this.props.api_url+'/insert/com_account_plan_group_level4'
        await fetch(insert_url, {
            method: 'POST',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body: insert,
        }).then(res=> console.log('New AccLine Added!'))
        this.setState({addItem:false,text:'',data:[]})
        this._Update();
    }
    render() {
        return(
            <div style={{backgroundColor:'#ffb8c5'}}>
                <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    noHeader={true}
                    highlightOnHover
                    responsive
                    // dense
                    defaultSortField={'nr'}
                    subHeader={true}
                    subHeaderAlign={'right'}
                    subHeaderComponent={
                        <div className="form-group margin-top-30">
                            <button className="btn btn-default" onClick={()=>this.setState({addItem:true})}>Ekle</button>
                        </div>
                    }
                    expandableRows={true}
                    expandableRowsComponent	={<EditItem  loginToken={this.props.loginToken}  api_url={this.props.api_url}/>}
                />
                {this.state.addItem?
                    <div className="col-md-12 margin-top-30">
                        <div className="form-group">
                            <input className="form-control" disabled value={this.state.data.length>=9999? this.props.data.nr+'.'+(this.state.data.length+1): this.state.data.length>=999? this.props.data.nr+'.0'+(this.state.data.length+1): this.state.data.length>=99? this.props.data.nr+'.00'+(this.state.data.length+1): this.state.data.length>=9? this.props.data.nr+'.000'+(this.state.data.length+1): this.props.data.nr+'.0000'+(this.state.data.length+1)} />
                        </div>
                        <div className="form-group">
                            <input className="form-control" placeholder={'Açıklama'} value={this.state.text} onChange={(x)=>this.setState({text:x.target.value.toUpperCase()})} />
                        </div>
                        <div className="form-group">
                            <button className="btn btn-danger" onClick={()=>this.setState({addItem:false,text:''})}>İptal</button> <button disabled={this.state.addItem===true&&this.state.text===''?true:false} className="btn btn-primary"  onClick={()=>this._AddItem()}>Kaydet</button>
                        </div>
                    </div>
                :
                    <></>
                }
            </div>
        )
    }
}
class Level3 extends React.Component{
    constructor(props){
        super(props);
        this.state={
            pageloader:false,
            addItem:false,
            edit:false,
            data:[],
            text:'',
            columns:[
                {
                    name: 'No:',
                    selector: 'nr',
                    left:true,
                    width:'120px'
                },
                {
                    name: 'Açıklama',
                    selector: 'text',
                    left:true
                }
                ]
        }
        this._Update=this._Update.bind(this);
        this._AddItem=this._AddItem.bind(this);
    }
    componentDidMount() {
        this._Update();
    }
    async _Update() {
        let acc_url=this.props.api_url+'/selectRows/com_account_plan_group_level3';
        await fetch(acc_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body:'id_com_company='+this.props.id_com_company+'&id_com_account_plan_group_level2='+this.props.data.nr+'&token='+this.props.loginToken+'&type=user'
        }).then(res=>res.json())
            .then(result=>{
                this.setState({data:result,pageloader:true})
            })
    }
    async _AddItem() {
        let nr =  this.state.data.length>=9?
            this.props.data.nr+'.'+(this.state.data.length+1):
            this.props.data.nr+'.0'+(this.state.data.length+1);
        let text = this.state.text ;
        let id_com_account_plan_group_level2 = this.props.data.nr.substring(0,3);
        let id_com_account_plan_group_level1 = this.props.data.nr.substring(0,2);
        let id_com_account_plan_main_class = this.props.data.nr.substring(0,1);
        let insert =
            'nr='+ nr+
            '&text='+ text+
            '&id_com_account_plan_group_level2='+ id_com_account_plan_group_level2+
            '&id_com_account_plan_group_level1='+ id_com_account_plan_group_level1+
            '&id_com_account_plan_main_class='+ id_com_account_plan_main_class+
            '&id_com_company='+this.props.id_com_company+
            '&token='+this.props.loginToken+
            '&type=user';
        let  insert_url =  this.props.api_url+'/insert/com_account_plan_group_level3'
        await fetch(insert_url, {
            method: 'POST',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body: insert,
        }).then(res=> console.log('New AccLine Added!'))
        this.setState({addItem:false,text:'',data:[]})
        this._Update();
    }
    render() {
        return(
            <div style={{backgroundColor:'lightyellow'}}>
                <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    noHeader={true}
                    highlightOnHover
                    responsive
                    // dense
                    defaultSortField={'nr'}
                    subHeader={true}
                    subHeaderAlign={'right'}
                    subHeaderComponent={
                        <div className="form-group margin-top-30">
                            <button className="btn btn-default" onClick={()=>this.setState({addItem:true})}>Ekle</button>
                        </div>
                    }
                    expandableRows={true}
                    expandableRowsComponent	={<Level4 api_url={this.props.api_url}  loginToken={this.props.loginToken} id_com_company={this.props.id_com_company}/>}
                />
                {this.state.addItem?
                    <div className="col-md-12 margin-top-30">
                        <div className="form-group">
                            <input className="form-control" disabled value={this.state.data.length>=9? this.props.data.nr+'.'+(this.state.data.length+1): this.props.data.nr+'.0'+(this.state.data.length+1)} />
                        </div>
                        <div className="form-group">
                            <input className="form-control" placeholder={'Açıklama'} value={this.state.text} onChange={(x)=>this.setState({text:x.target.value.toUpperCase()})} />
                        </div>
                        <div className="form-group">
                            <button className="btn btn-danger" onClick={()=>this.setState({addItem:false,text:''})}>İptal</button> <button className="btn btn-primary" disabled={this.state.addItem===true&&this.state.text===''?true:false}  onClick={()=>this._AddItem()}>Kaydet</button>
                        </div>
                    </div>
                :
                    <></>
                }
            </div>
        )
    }
}
class Level2 extends React.Component{
    constructor(props){
        super(props);
        this.state={
            pageloader:false,
            data:[],
            columns:[
                {
                    name: 'No:',
                    selector: 'nr',
                    left:true,
                    width:'120px'
                },
                {
                    name: 'Açıklama',
                    selector: 'text',
                    left:true
                }
                ]
        }
        this._Update=this._Update.bind(this);
    }
    componentDidMount() {
        this._Update();
    }
    _Update() {
        console.log(this.props.data)
        let acc_url=this.props.api_url+'/selectRows/com_account_plan_group_level2';
        fetch(acc_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body:'id_com_account_plan_group_level1='+this.props.data.nr+'&token='+this.props.loginToken+'&type=user'
        }).then(res=>res.json())
            .then(result=>{
                this.setState({data:result,pageloader:true})
                console.log(result)
            })
    }
    render() {
        return(
            <div style={{backgroundColor:'lightgreen'}}>
                <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    noHeader
                    highlightOnHover
                    responsive
                    // dense
                    defaultSortField={'nr'}
                    expandableRows={true}
                    expandableRowsComponent	={
                        <Level3 api_url={this.props.api_url}  loginToken={this.props.loginToken} id_com_company={this.props.id_com_company}/>
                    }
                />
            </div>
        )
    }
}
class Level1 extends React.Component{
    constructor(props){
        super(props);
        this.state={
            pageloader:false,
            data:[],
            columns:[
                {
                    name: 'No:',
                    selector: 'nr',
                    left:true,
                    width:'100px'
                },
                {
                    name: 'Açıklama',
                    selector: 'text',
                    left:true
                }
                ]
        }
        this._Update=this._Update.bind(this);
    }
    componentDidMount() {
        this._Update();
    }
    _Update() {
        console.log(this.props.data)
        let acc_url=this.props.api_url+'/selectRows/com_account_plan_group_level1';
        fetch(acc_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body:'id_com_account_plan_main_class='+this.props.data.nr+'&token='+this.props.loginToken+'&type=user'
        }).then(res=>res.json())
            .then(result=>{
                this.setState({data:result,pageloader:true})
                console.log(result)
            })
    }
    render() {
        return(
            <div className="bg-info">
                <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    highlightOnHover
                    noHeader
                    responsive
                    defaultSortField="nr"
                    // dense
                    expandableRows={true}
                    expandableRowsComponent	={<Level2 api_url={this.props.api_url}  loginToken={this.props.loginToken} id_com_company={this.props.id_com_company}/>}
                />
            </div>
        )
    }
}
class AccList extends React.Component {
    constructor(props){
        super(props);
        this.state={
            pageloader:false,
            data:[],
            columns:[
                {
                    name: 'No:',
                    selector: 'nr',
                    left:true,
                    width:'100px'
                },
                {
                    name: 'Açıklama',
                    selector: 'text',
                    left:true
                }
            ]
        }
        this._Update=this._Update.bind(this);
    }
    componentDidMount() {
        this._Update();
    }
    _Update() {
        let acc_url=this.props.api_url+'/selectRows/com_account_plan_main_class';
        fetch(acc_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body:'&token='+this.props.loginToken+'&type=user'
        }).then(res=>res.json())
            .then(result=>{
                this.setState({data:result,pageloader:true})
            })
    }
    render () {
        return (
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="page-title">Mizan Planı</h3>
                            </div>
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">Mizan Planı</h3>
                                    </div>
                                    <div className="panel-body">
                                        <DataTable
                                            columns={this.state.columns}
                                            noHeader={true}
                                            overflowY={false}
                                            overflowYOffset={'100px'}
                                            data={this.state.data}
                                            fixedHeader
                                            highlightOnHover
                                            responsive
                                            striped
                                            // dense
                                            paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                            pagination={true}
                                            paginationPerPage={20}
                                            defaultSortField={'nr'}
                                            expandableRows={true}
                                            expandableRowsComponent	={
                                                <Level1 api_url={this.props.api_url}  loginToken={this.props.loginToken} id_com_company={this.props.id_com_company}/>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(mapStateToProps)(AccList);