import React, {Component} from 'react';
class Footer extends Component {
    render () {
        return (
            <React.Fragment>
                <div className = "clearfix"></div>
                <footer>
                    <div className="container-fluid">
                        <p className="copyright">&copy; <a href="https://www.mitrotech.net" rel="noreferrer" target="_blank">Mitrotech.Net</a>. Tüm hakları saklıdır.</p>
                    </div>
                </footer>
            </React.Fragment>
    );
	}
}
export default Footer;