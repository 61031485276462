import { Button } from 'antd';
import exportFromJSON from 'export-from-json';


const ExportFromJson =({data,fileName,exportType})=>{
    
    return (
        <Button type='primary'   style={{alignSelf:"center", backgroundColor:'#32a852',color:'white',width:'100px' }}  icon={<i className="fas fa-file-excel"  style={{marginRight:5}}   />} onClick={()=>exportFromJSON({data,fileName,exportType})}>Excel</Button>
    )
}

export default ExportFromJson;