import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
import {alertMessage} from '../../assets.js';

class CustomerEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            disabled: true,
            pageloader: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const id_com_customer = this.props.match.params.id;
        this.setState({
            id_com_customer: id_com_customer
        })

        let product_url = this.props.api_url + '/selectRows/view_customer';
        fetch(product_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id=' + id_com_customer + '&token=' + this.props.loginToken + '&type=user'
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                let data = result[0];
                for (let [key, value] of Object.entries(data)) {
                    this.setState({
                        [`${key}`]: value, pageloader: true
                    })
                }
            })

        let com_product_url = this.props.api_url + '/selectRows/com_customer';
        fetch(com_product_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id=' + id_com_customer + '&token=' + this.props.loginToken + '&type=user'
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result2 => {
                this.setState({data: result2[0]})
            })


    }

    handleChange(event, selectlist, name) {
        if (selectlist) {
            this.setState({[`${name}`]: event.id})
            // console.log('id changed')
        } else {
            const value = event.target.value;
            const key = event.target.name;
            this.setState({[`${key}`]: value})
        }
        this.setState({disabled: false})
    }

    handleSubmit(event) {
        let d = new Date();
        let date = d.toISOString();
        let m_date = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');

        let update_body =
            'token=' + this.props.loginToken +
            '&m_date=' + m_date +
            '&m_time=' + m_time +
            '&m_id_com_user=' + this.props.id_com_user +
            '&type=user&id_com_company=1&status=1';

        for (let [key] of Object.entries(this.state.data)) {
            update_body += `&${key}=` + this.state[`${key}`]
        }

        let com_customer_url = this.props.api_url + '/update/com_customer';
        fetch(com_customer_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: update_body,
        })
            .catch(error => console.log(error)).then(x => {
                this.setState({disabled: false});
                window.location.reload()
            }
        )

    }


    render() {
        return (
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="page-title">Müşteri Kartları</h3>
                            </div>
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">Müşteri Düzenle</h3>
                                    </div>
                                    <div className="panel-body">
                                        {!this.state.pageloader ?
                                            <div className="col-md-12"
                                                 dangerouslySetInnerHTML={{__html: alertMessage('loadingData')}}></div>
                                            :
                                            <>
                                                <SelectList table='com_customer_type' label={'Müşteri Tipi'}
                                                            name={'id_com_customer_type'}
                                                            value={this.state.id_com_customer_type}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                <div className="form-group">
                                                    <label>Firma Adı</label>
                                                    <input type="text" className="form-control" name="company_name"
                                                           value={this.state.company_name}
                                                           onChange={(x) => this.handleChange(x)}
                                                           placeholder="Firma Adı..." required/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Adı</label>
                                                    <input type="text" className="form-control" name="name"
                                                           value={this.state.name}
                                                           onChange={(x) => this.handleChange(x)} placeholder="Adı..."
                                                           required/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Soyadı</label>
                                                    <input type="text" className="form-control" name="surname"
                                                           value={this.state.surname}
                                                           onChange={(x) => this.handleChange(x)}
                                                           placeholder="Soyadı..." required/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Vergi Dairesi</label>
                                                    <input type="text" className="form-control" name="tax_office"
                                                           value={this.state.tax_office}
                                                           onChange={(x) => this.handleChange(x)}
                                                           placeholder="Vergi Dairesi..." required/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Vergi No / TCK No</label>
                                                    <input type="number" className="form-control" name="taxno"
                                                           value={this.state.taxno}
                                                           onChange={(x) => this.handleChange(x)}
                                                           placeholder="Vergi No / TCK No..." required/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Doğum Tarihi</label>
                                                    <input type="date" className="form-control" name="birthday"
                                                           value={this.state.birthday}
                                                           onChange={(x) => this.handleChange(x)}
                                                           placeholder="Doğum Tarihi..." required/>
                                                </div>
                                                <div className="form-group">
                                                    <label>E-Posta</label>
                                                    <input type="email" className="form-control" name="email"
                                                           value={this.state.email}
                                                           onChange={(x) => this.handleChange(x)}
                                                           placeholder="E-Posta..." required/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Tel 1</label>
                                                    <input type="text" className="form-control" name="phone1"
                                                           value={this.state.phone1}
                                                           onChange={(x) => this.handleChange(x)}
                                                           placeholder="Telefon..." required/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Tel 2</label>
                                                    <input type="text" className="form-control" name="phone2"
                                                           value={this.state.phone2}
                                                           onChange={(x) => this.handleChange(x)}
                                                           placeholder="Telefon 2..." required/>
                                                </div>
                                                <SelectList table='com_city' label={'İl'} name={'id_com_city'}
                                                            value={this.state.id_com_city}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                <SelectList table='com_town' id_com_city={this.state.id_com_city}
                                                            label={'İlçe'} name={'id_com_town'}
                                                            value={this.state.id_com_town}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                <div className="form-group">
                                                    <label>Adres</label>
                                                    <textarea type="text" row='10' className="form-control"
                                                              name="adress" value={this.state.adress}
                                                              onChange={(x) => this.handleChange(x)}
                                                              placeholder="Adres..." required/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Posta Kodu</label>
                                                    <input type="number" className="form-control" name="postcode"
                                                           value={this.state.postcode}
                                                           onChange={(x) => this.handleChange(x)}
                                                           placeholder="Posta Kodu..." required/>
                                                </div>
                                                <SelectList table='com_meet' label={'Temas Türü'} name={'id_com_meet'}
                                                            value={this.state.id_com_meet}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                <SelectList table='com_gender' label={'Cinsiyeti'}
                                                            name={'id_com_gender'} value={this.state.id_com_gender}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                <SelectList table='com_education' label={'Eğitim Seviyesi'}
                                                            name={'id_com_education'}
                                                            value={this.state.id_com_education}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                <SelectList table='com_sector' label={'Mesleği / Görevi'}
                                                            name={'id_com_sector'} value={this.state.id_com_sector}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                <div className="form-group">
                                                    <label>Açıklama</label>
                                                    <textarea type="text" row='10' className="form-control"
                                                              name="comment" value={this.state.comment}
                                                              onChange={(x) => this.handleChange(x)}
                                                              placeholder="Açıklama..." required/>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="panel-footer">
                                        <a className="btn btn-danger" href={"/Customer/Detail/"+this.state.id_com_customer}>İptal</a>

                                        <button className="btn btn-primary pull-right"
                                                disabled={this.state.disabled}
                                                onClick={() => this.handleSubmit()}
                                        >Kaydet
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default connect(mapStateToProps)(CustomerEdit);


{/* <form >
                        <fieldset class="uk-fieldset">
                             <SelectList type='text' table='com_customer_type' selectList='true'  
                                placeholder={this.state.id_com_customer_type_name} Label='Müşteri Tipini Seçiniz' onChange={(e)=>{this.handleChange(e,true,'id_com_customer_type')}} 
                                required={true}/>
                            {this.state.id_com_customer_type!=='1'? 
                                    <div className="uk-margin">
                                        <label className="uk-form-label">Firma Adı</label>
                                        <input   className="uk-input " name='company_name'  type='text' 
                                        value={(this.state.company_name)} 
                                        onChange={(e) => this.handleChange(e)} />
                                    </div>: <></>}
                            <div className="uk-margin">
                                 <label className="uk-form-label">Adı</label>
                                <input   className="uk-input " name='name'  type='text' 
                                value={(this.state.name)} 
                                onChange={(e) => this.handleChange(e)} />
                            </div>
                            <div className="uk-margin">
                                 <label className="uk-form-label">Soyadı</label>
                                <input   className="uk-input " name='surname'  type='text' 
                                value={(this.state.surname)} 
                                onChange={(e) => this.handleChange(e)} />
                            </div>
                            <div className="uk-margin">
                            <label className="uk-form-label">Email Adresi</label>
                                <input   className="uk-input " name='email'  type='text' 
                                value={(this.state.email)} 
                                onChange={(e) => this.handleChange(e)} />
                            </div>
                            <div className="uk-margin">
                            <label className="uk-form-label">Vergi No</label>
                                <input   className="uk-input " name='taxno'  type='text' 
                                value={(this.state.taxno)} 
                                onChange={(e) => this.handleChange(e)} />
                            </div>
                            <div className="uk-margin">
                            <label className="uk-form-label">Vergi Dairesi</label>
                                <input   className="uk-input " name='tax_office'  type='text' 
                                value={(this.state.tax_office)} 
                                onChange={(e) => this.handleChange(e)} />
                            </div>
                            <div className="uk-margin">
                            <label className="uk-form-label">Telefon Numarası</label>
                                <input   className="uk-input " name='phone1'  type='text' 
                                value={(this.state.phone1)} 
                                onChange={(e) => this.handleChange(e)} />
                            </div>
                            <SelectList type='text' table='com_gender' selectList='true'  
                                placeholder={this.state.id_com_gender_name}  Label='Cinsiyeti'
                                onChange={(e)=>{this.handleChange(e,true,'id_com_gender')}} 
                                required={true}/>
                            <SelectList type='text' table='com_sector' selectList='true'  
                                placeholder={this.state.id_com_sector_name}   Label='Çalıştığı Sektör'
                                onChange={(e)=>{this.handleChange(e,true,'id_com_sector')}} 
                                required={true}/>
                            <SelectList type='text' table='com_education' selectList='true'  
                                placeholder={this.state.id_com_education_name}  Label='Eğitim Seviyesi'
                                onChange={(e)=>{this.handleChange(e,true,'id_com_education')}} 
                                required={true}/>
                            <SelectList type='text' table='com_meet' selectList='true'  
                                placeholder={this.state.id_com_meet_name}  Label='Temas Türü'
                                onChange={(e)=>{this.handleChange(e,true,'id_com_meet')}} 
                                required={true}/>
                            <SelectList type='text' table='com_city' selectList='true'  
                                placeholder={this.state.id_com_city_name}  Label='Temas Türü'
                                onChange={(e)=>{this.handleChange(e,true,'id_com_city')}} 
                                required={true}/>
                            <SelectList type='text' table='com_town' selectList='true'
                                id_com_city={this.state.id_com_city}   
                                placeholder={this.state.id_com_town_name}  Label='Temas Türü'
                                onChange={(e)=>{this.handleChange(e,true,'id_com_town')}} 
                                required={true}/>
                            <div className="uk-margin">
                            <label className="uk-form-label">Adresi</label>
                                <textarea  rows="5" className="uk-textarea" name='adress'  type='text' 
                                value={(this.state.adress)} 
                                onChange={(e) => this.handleChange(e)} />
                            </div>
                            <div className="uk-margin">
                            <label className="uk-form-label">Posta Kodu</label>
                                <input   className="uk-input " name='postcode'  type='text' 
                                value={(this.state.postcode)} 
                                onChange={(e) => this.handleChange(e)} />
                            </div>
                            <div className="uk-margin">
                            <label className="uk-form-label">Müşteri Açıklaması</label>
                                <textarea  rows="5" className="uk-textarea" name='comment'  type='text' 
                                value={(this.state.comment)} 
                                onChange={(e) => this.handleChange(e)} />
                            </div>
                        </fieldset>
                    </form>
                </div> */
}
