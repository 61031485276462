

const initialState = { 
      log_status:false
}

const logStatus = (state = initialState,action) => { 
      switch(action.type) {
          case 'LOGGED_CHANGE':  
            return  {...state, log_status:!state.log_status}
           default:
             return  state;
      }
}

export default logStatus;