

import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import ApprovallCard from '../utilities/ApprovallCard';
import OrderformDelivery from './Delivery';
import DataTable from 'react-data-table-component';



class OrderformApproval extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            id_com_orderform:'',
            pageloader:false,
            car_summary:0,
            cars_data:[],
            access_summary:0,
            access_data:[],
            swap_summary:0,
            swap_data:[],
            payments_summary:0,
            invoiced:true,
            paid_summary:0, 
            swap_data_columns:[
                    {
                    name: 'Plaka',
                    selector: 'license_plate',
                    left:true,
                    sortable:true,
                    },
                    {
                    name: 'Şase',
                    selector: 'chasis',
                    left:true,
                    sortable:true,
                    },
                    {
                    name: 'Araç',
                    selector: 'id_com_expertise_name',
                    left:true,
                    width:'300px',
                    sortable:true,
                    },
          
                    {
                    name: 'Model Yılı',
                    selector: 'model_year',
                    left:true,
                    sortable:true,
                    },
                    {
                    name: 'Km',
                    selector: 'km',
                    right:true,
                    sortable:true,
                    },
                    {
                    name: 'Satın Alma Fiyatı',
                    selector: 'price',
                    right:true,
                    sortable:true,
                    },
                    {
                    name: 'Durum',
                    selector: 'id_com_expertise_status_name',
                    right:true,
                    sortable:true,
                    },
                    {
                        name: 'Kontrol',
                        cell : row =>(
                            <>
                                <a href={'/expertise/detail/' + row.id_com_expertise}   className="btn btn-info btn-sm"  style={{margin:5}} target='blank_'><i className="fas fa-search-plus"/></a>    
                            </>
                        ),
                        right:true,
                        }
            ],
            payments_data:[],
            payments_summary:0,
            payments_data_columns:[

                {
                name: 'Ödeme Tipi',
                selector: 'id_com_payment_type_name',
                left:true,
                sortable:true,
                },
                {
                name: 'Açıklama',
                selector: 'comment',
                left:true,
                width:'250px',
                sortable:true,
                wrap:true,
                },
                {
                name: 'Tutar',
                selector: 'price',
                right:true,
                sortable:true,
                },
                {
                    name: 'Kontrol',
                    cell : row =>(
                
                        row.status==='0'?
                           
                            <button   type="button" disabled={this.state.id_com_orderform_status==='2'?true:false} className="btn btn-success btn-sm"  style={{margin:5}} onClick={()=>this.Payment_Completed(row.id)} ><i className="fas fa-check"/></button>  
:
                            <button className="btn btn-danger btn-sm" type="button" disabled={true} ><i className="fas fa-check-double"> {row.m_date+' / '+row.m_time}</i></button>
                    ),
                    right:true,
                    }
            ],

            approval_data:[],
            access_data_columns:[
                {
                    name: 'Ödeme Tipi',
                    selector: 'id_com_stock_name',
                    width:'400px',
                    left:true,
                    sortable:true,
                    wrap:true,
                    },
                    {
                    name: 'Açıklama',
                    selector: 'text',
                    left:true,
                    width:'150px',
                    sortable:true,
                    wrap:true,
                    },

                    {
                        name: 'Kontrol',
                        cell : row =>(
                    
                            row.status==='0'?
                               
                                <button   type="button" disabled={this.state.id_com_orderform_status==='2'?true:false} className="btn btn-success btn-sm"  style={{margin:5}} onClick={()=>this.Access_Completed(row.id)} ><i className="fas fa-check"/></button>  
    :
                                <p className="text-danger"   disabled={true} ><i className="fas fa-check-double"> {row.m_date+' / '+row.m_time}</i></p>
                        ),
                        right:true,
                        }
            ],

            summary_columns:[
                {
                    name: 'Şase',
                    selector: 'chasis',
                    left:true,
                    width:'150px',
                    sortable:true,
                    },
                    {
                    name: 'Ötv Matrah',
                    selector: 'otv_matrah',
                    right:true,
                    width:'75px',
                    },
                    {
                    name: 'Ötv Matrah İndirimi',
                    cell : row =>(
                            <p   style={{margin:5}}>{parseFloat(row.otv_matrah_disc1)+parseFloat(row.otv_matrah_disc2)+parseFloat(row.otv_matrah_disc3)}</p>
                    ),
                    right:true,
                    width:'75px',

                    },
                    {
                    name: 'Ötv Tutarı',
                    cell : row =>(
                            <p   style={{margin:5}}>{ row.id_com_otv_rate_name+' / '+row.otv_amount}</p>
                    ),
                    right:true,
                    width:'75px',

                    },
                    {
                    name: 'KDV Matrah',
                    selector: 'kdv_matrah',
                    right:true,
                    width:'75px',
                    },
                    {
                    name: 'KDV Matrah İndirimi',
                    selector: 'kdv_disc',
                    right:true,
                    width:'75px',
                    },
                    {
                    name: 'KDV Tutarı',
                    cell : row =>(
                        <p   style={{margin:5}}>{ '% '+row.kdv_rate+' / '+row.kdv_amount}</p> 
                ),
                width:'75px',

                right:true,
            },
                    {
                    name: 'Aksesuar',
                    selector: 'accessory_total',
                    left:true,
                    width:'75px',
                    },
                    {
                        name: 'Plaka / Bandrol',
                        cell : row =>(
                            <p   style={{margin:5}}>{ row.plate_register+' / '+row.bandrol}</p>
                    ),
                    right:true,
                        width:'75px',
                    },
                    {
                    name: 'Toplam Satış Bedeli',
                    selector: 'calculated_price',
                    right:true,
                    sortable:true,
                    width:'75px',
                    },
                    {
                    name: 'Satış Fiyatı',
                    selector: 'sale_price',
                    right:true,
                    sortable:true,
                     },
                    {
                    name: 'Fiyat Farkı',
                    selector: 'price_difference',
                    right:true,
                    sortable:true,
                     },
                   
                     {
                    name: 'Kontrol',
                    cell : row =>(
                        parseInt(this.state.id_com_orderform_status)>2?
                            row.id_com_orderform_approval_status==='2'?
                            <p   style={{margin:5}} className='text-center text-danger'>{row.id_com_orderform_approval_status_name}</p>

                            :
                        <button className="btn btn-success btn-sm"  type="button"  onClick={()=>this.Appr_Invoiced(row.id)} style={{margin:5}}><i className="fas fa-check"></i></button>

                        :
                        <>
                            {/* <a href={'/orderform/edit/' + row.id} className="btn btn-info btn-sm" disabled={true} style={{margin:5}}><i className="fas fa-edit"/></a> */}
                            <button className="btn btn-danger btn-sm"  type="button"  onClick={()=>this.Appr_Delete(row.id)} style={{margin:5}}><i className="fas fa-window-close"></i></button>

                        </>
                        
                    ),
                    right:true,
                    }
                ]
    };
    this._Submit=this._Submit.bind(this)
    this.Appr_Delete=this.Appr_Delete.bind(this);
    this.Appr_Invoiced=this.Appr_Invoiced.bind(this);
    this._Refuse=this._Refuse.bind(this);
    this.Payment_Completed=this.Payment_Completed.bind(this);
    this._Accounting_Submit=this._Accounting_Submit.bind(this);
    this.Access_Completed=this.Access_Completed.bind(this);
    }


    _Refuse(){

        let d = new Date(); 
        let date = d.toISOString();
        let m_date  = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');
        let orderfom_url = this.props.api_url+'/update/com_orderform';
        let Update_body =  
        'token='+this.props.loginToken+
        '&id='+this.state.id_com_orderform+
        '&m_date='+m_date+
        '&m_time='+m_time+
        '&m_id_com_user='+this.props.id_com_user+
        '&id_com_orderform_status=1&type=user';
    
         fetch(orderfom_url, {
            method: 'POST',
            cache: 'no-cache',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body:Update_body,
        }).catch(error => console.log(error)).then(x=>{ window.location.href='/'})
    }


    Appr_Invoiced(id){
        let d = new Date(); 
        let date = d.toISOString();
        let m_date  = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');
        let access_url = this.props.api_url+'/update/com_orderform_approval';
        let delete_body =  
        'token='+this.props.loginToken+
        '&id='+id+
        '&m_date='+m_date+
        '&m_time='+m_time+
        '&m_id_com_user='+this.props.id_com_user+
        '&id_com_orderform_approval_status=2&type=user'
         fetch(access_url, {
            method: 'POST',
            cache: 'no-cache',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body:delete_body,
        }).catch(error => console.log(error)).then(x=>{ window.location.reload()
        })
    }


    Payment_Completed(id){
        let d = new Date(); 
        let date = d.toISOString();
        let m_date  = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');
        let access_url = this.props.api_url+'/update/com_orderform_payment_lines';
        let delete_body =  
        'token='+this.props.loginToken+
        '&id='+id+
        '&m_date='+m_date+
        '&m_time='+m_time+
        '&m_id_com_user='+this.props.id_com_user+
        '&status=1&type=user'
         fetch(access_url, {
            method: 'POST',
            cache: 'no-cache',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body:delete_body,
        }).catch(error => console.log(error)).then(x=>{ window.location.reload()
        })
    }


    Access_Completed(id){
        let d = new Date(); 
        let date = d.toISOString();
        let m_date  = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');
        let access_url = this.props.api_url+'/update/com_orderform_acces_lines';
        let update_body =  
        'token='+this.props.loginToken+
        '&id='+id+
        '&m_date='+m_date+
        '&m_time='+m_time+
        '&m_id_com_user='+this.props.id_com_user+
        '&status=1&type=user'

        console.log(update_body)
         fetch(access_url, {
            method: 'POST',
            cache: 'no-cache',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body:update_body,
        }).catch(error => console.log(error)).then(x=>{ window.location.reload()
        })
    }


    _Service_Submit(){
        let d = new Date(); 
        let date = d.toISOString();
        let m_date  = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');
        let access_url = this.props.api_url+'/update/com_orderform';
        let delete_body =  
        'token='+this.props.loginToken+
        '&id='+this.state.id_com_orderform+
        '&m_date='+m_date+
        '&m_time='+m_time+
        '&m_id_com_user='+this.props.id_com_user+
        '&id_com_orderform_status=5&type=user'
         fetch(access_url, {
            method: 'POST',
            cache: 'no-cache',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body:delete_body,
        }).catch(error => console.log(error)).then(x=>{ window.location.href='/'
        })
    }

    Appr_Delete(id){
        let access_url = this.props.api_url+'/delete/com_orderform_approval';
        let delete_body =  
        'token='+this.props.loginToken+
        '&id='+id+
        '&type=user'
         fetch(access_url, {
            method: 'POST',
            cache: 'no-cache',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body:delete_body,
        }).catch(error => console.log(error)).then(x=>{ window.location.reload()
        })
      }


async componentDidMount(){
    let id_com_orderform = this.props.match.params.id;
    this.setState({id_com_orderform:id_com_orderform})

    let select_url=this.props.api_url+'/selectRows/view_orderform';
await    fetch(select_url,{
             method:'POST',
             cahce: 'no-cache',
             headers:{ 
               'Content-Type':'application/x-www-form-urlencoded'
             },
             body:  'token='+this.props.loginToken+'&type=user&id='+id_com_orderform
            
         }).then(res=>res.json())
         .then(result=>{

             let data = result[0];
             for (let [key, value] of Object.entries(data)) {
             this.setState({ [`${key}`]:value,pageloader:true
                 })
             }
         })




    let com_car_url=this.props.api_url+'/selectRows/view_orderform_lines';
    await  fetch(com_car_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body:'id_com_orderform='+id_com_orderform+'&token='+this.props.loginToken+'&type=user'
        })
        .catch(error=>console.log(error))
        .then(res=>res.json())

        
        .then(cars_result=>{
            let summary  = 0 ; 
            cars_result.map(row=> summary +=parseFloat(row.price));

            let url_orderform_approval=this.props.api_url+'/selectRows/view_orderform_approval';
            fetch(url_orderform_approval,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                    'Content-Type':'application/x-www-form-urlencoded'
                },
                body:'id_com_orderform='+id_com_orderform+'&token='+this.props.loginToken+'&type=user'
            })
            .catch(error=>console.log(error))
            .then(res=>res.json())
            .then(approval_result=>{
        
                let  filtered_cars_data =cars_result.filter(row => !approval_result.find(row2 => row.id_com_stock === row2.id_com_stock ))
                approval_result.map(row=>
                        {if(row.id_com_orderform_approval_status==='1'){this.setState({invoiced:false})}}
                
                    )
                this.setState({approval_data:approval_result,cars_data:filtered_cars_data,car_summary:summary});
            })



           
        })

        let com_access_url=this.props.api_url+'/selectRows/view_orderform_acces_lines';
        await      fetch(com_access_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                    'Content-Type':'application/x-www-form-urlencoded'
                },
                body:'id_com_orderform='+id_com_orderform+'&token='+this.props.loginToken+'&type=user'
            })
            .catch(error=>console.log(error))
            .then(res=>res.json())
            .then(result=>{
                let summary  = 0 ; 
                let acc_count  = 0 ; 

                result.map(row=> {summary +=parseFloat(row.price); acc_count +=parseFloat(row.status); });


            this.setState({access_data:result,access_summary:summary,acc_count:acc_count});
            })

            let com_swap_url=this.props.api_url+'/selectRows/view_ordeform_expertise_list';
            await  fetch(com_swap_url,{
                    method:'POST',
                    cahce: 'no-cache',
                    headers:{ 
                        'Content-Type':'application/x-www-form-urlencoded'
                    },
                    body:'id_com_orderform='+id_com_orderform+'&token='+this.props.loginToken+'&type=user'
                })
                .catch(error=>console.log(error))
                .then(res=>res.json())
                .then(result=>{
                    let summary  = 0 ; 
                    result.map(row=> summary +=parseFloat(row.price));
                this.setState({swap_data:result,swap_summary:summary});
                })

                let com_payment_url=this.props.api_url+'/selectRows/view_orderform_payments_lines';
                await  fetch(com_payment_url,{
                        method:'POST',
                        cahce: 'no-cache',
                        headers:{ 
                            'Content-Type':'application/x-www-form-urlencoded'
                        },
                        body:'id_com_orderform='+id_com_orderform+'&token='+this.props.loginToken+'&type=user'
                    })
                    .catch(error=>console.log(error))
                    .then(res=>res.json())
                    .then(result=>{
                        let summary  = 0 ; 
                        result.map(row=> summary +=parseFloat(row.price));
                        let paid = 0
                        result.map(row=> {if(row.status==='1'){paid +=parseFloat(row.price)}});

                    this.setState({payments_data:result,payments_summary:summary,paid_summary:paid});
                    })

        let sub_total =  parseFloat(this.state.car_summary)+parseFloat(this.state.access_summary)-parseFloat(this.state.swap_summary)-parseFloat(this.state.payments_summary)
       await             this.setState({sub_total:sub_total})



       
}

_Submit() { 
    let d = new Date(); 
    let date = d.toISOString();
    let m_date  = date.split('T')[0];
    let m_time = d.toLocaleTimeString('tr-TR');
    let orderfom_url = this.props.api_url+'/update/com_orderform';
    let Update_body =  
    'token='+this.props.loginToken+
    '&id='+this.state.id_com_orderform+
    '&m_date='+m_date+
    '&m_time='+m_time+
    '&m_id_com_user='+this.props.id_com_user+
    '&id_com_orderform_status=3&type=user';

     fetch(orderfom_url, {
        method: 'POST',
        cache: 'no-cache',
        headers:  {
            'Content-Type':  'application/x-www-form-urlencoded'
        },
        body:Update_body,
    }).catch(error => console.log(error)).then(x=>{ window.location.href='/orderform/list'})
}


_Accounting_Submit(){
    let d = new Date(); 
    let date = d.toISOString();
    let m_date  = date.split('T')[0];
    let m_time = d.toLocaleTimeString('tr-TR');
    let orderfom_url = this.props.api_url+'/update/com_orderform';
    let Update_body =  
    'token='+this.props.loginToken+
    '&id='+this.state.id_com_orderform+
    '&m_date='+m_date+
    '&m_time='+m_time+
    '&m_id_com_user='+this.props.id_com_user+
    '&id_com_orderform_status=4&type=user';

     fetch(orderfom_url, {
        method: 'POST',
        cache: 'no-cache',
        headers:  {
            'Content-Type':  'application/x-www-form-urlencoded'
        },
        body:Update_body,
    }).catch(error => console.log(error)).then(x=>{ window.location.href='/'})
}
    render() { 
        return (    
<div className="main">
    <div className="main-content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="panel">
                    {parseInt(this.state.id_com_orderform_status)===2||this.props.id_com_department==='8'? 

                    <div className="panel-body">
                    <div className="col-md-6">
                            <h3 className="page-title">Satış Dosyası No : {this.state.id_com_orderform}</h3>
                        </div>
                                <table className="table table-bordered">
                                            <tbody>
                                            <tr>
                                                <th>Dosya Tipi</th>
                                                <td>{this.state.id_com_sale_type_name} </td>                                                      
                                            </tr>
                                            {this.state.id_com_orderform_status==='2'? 
                                            <tr>
                                                <th>Durum</th>
                                                <td style={{color:'red'}}>{this.state.id_com_orderform_status_name} </td>                                                      
                                            </tr>
                                            :<></>}
                                            <tr>
                                                <th>Müşteri Vergi No  / Vergi D.</th>
                                                <td>{this.state.taxno+' / '+this.state.tax_office} </td>                                                      
                                            </tr>
                                            <tr>
                                                <th>Müşteri Adı </th>
                                                <td>{this.state.id_com_customer_company_name===undefined||this.state.id_com_customer_company_name===null||this.state.id_com_customer_company_name==='-'?this.state.id_com_customer_name:this.state.id_com_customer_company_name+' / '+this.state.id_com_customer_name} </td>                                                      
                                            </tr>
                                            <tr>
                                                <th>İletişim Bilgisi  </th>
                                                <td>{' Tel : '+this.state.phone1+' / Email : '+this.state.email} </td>                                                      
                                            </tr>
                                            <tr>
                                                <th>Açan Kullanıcı  </th>
                                                <td>{this.state.id_com_user_name} </td>                                                      
                                            </tr>
                                            </tbody>
                                        </table>
                                        {this.state.cars_data.length>0? 
                                             <>
                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                           {this.state.cars_data.map((row,index)=>
                                                  <li className="nav-item" role="presentation" key={index}>
                                                  <a className="nav-link" id={`tab-${row.id}`}  data-toggle="tab" href={`#tabpane${row.id}`} role="tab" aria-controls={`tabpane${row.id}`}   >{row.chasis}</a>
                                                    </li>
                                           )}
                                           </ul>
                                           <div className="tab-content" id="myTabContent">
                                           {this.state.cars_data.map((row,index)=>
                                                      <div className="tab-pane fade " id={`tabpane${row.id}`} role="tabpanel" aria-labelledby={`tab-${row.id}`} key={index} >
                                                             <ApprovallCard data={row} />                                                                                                         
                                                      </div>
                                              )}
                                            </div>
                                                    </>
                                    :<></>}
                                    <h4 className="page-title" style={{marginTop:10}}>Satış Ozeti : </h4>
   
                                    {this.state.approval_data.length>0?
                                        <DataTable
                                                    columns={this.state.summary_columns}
                                                    data={this.state.approval_data}
                                                    fixedHeader
                                                    noHeader
                                                    overflowY={false}
                                                    overflowYOffset={'100px'}
                                                    highlightOnHover
                                                    responsive
                                                    striped
                                                    dense
                                                    paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                                    pagination={true}
                                                    paginationPerPage={5}
                                                    defaultSortField={'chasis'}
                                                    expandableRows
                                                    expandableRowsComponent={ <AccessList access_data={this.state.access_data} />}
                                                    defaultSortAsc={false}
                                                />
                                        :
                                        <div className="panel-heading ">
                                        <div className="panel-title text-center text-danger">Yukarıda bulunan şasi numaralarına tıklayarak hesaplamayı tamamlayınız.</div>
                                        </div>
                                        }  
                                        {this.state.swap_data.length>0?
                                        <>
                                            <h4 className="page-title">Takas Araçlar : </h4>
                                            <DataTable
                                                    columns={this.state.swap_data_columns}
                                                    data={this.state.swap_data}
                                                    fixedHeader
                                                    noHeader
                                                    overflowY={false}
                                                    overflowYOffset={'100px'}
                                                    highlightOnHover
                                                    responsive
                                                    striped
                                                    dense
                                                    paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                                    pagination={true}
                                                    paginationPerPage={5}
                                                    defaultSortField={'chasis'}
                                                    defaultSortAsc={false}
                                                />
                                            </>
                                        :<></>}  
                                        {this.state.payments_data.length>0?
                                        <>
                                            <h4 className="page-title">Planlanan Ödeme Listesi : </h4>
                                            <DataTable
                                                    columns={this.state.payments_data_columns}
                                                    data={this.state.payments_data}
                                                    fixedHeader
                                                    noHeader
                                                    overflowY={false}
                                                    overflowYOffset={'100px'}
                                                    highlightOnHover
                                                    responsive
                                                    striped
                                                    dense
                                                    paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                                    pagination={true}
                                                    paginationPerPage={5}
                                                    defaultSortField={'id'}
                                                    defaultSortAsc={false}
                                                />
                                            </>
                                        :<></>}  
                                        
                                        <div className="panel-footer">
                                        <a className="btn btn-danger pull-left" 
                                             style={{margin:5}} href='/'>Geri</a> 
                                        <button className="btn btn-warning pull-left" 
                                             disabled={this.state.approval_data.length===0?false:true}
                                             style={{margin:5}} onClick={()=>this._Refuse()} >Red Et</button> 
                                     {this.state.id_com_orderform_status==='3'?
                                     <button className="btn btn-success pull-right" 
                                             disabled={this.state.paid_summary===this.state.payments_summary&&this.state.invoiced===true?false:true}
                                             style={{margin:5}} onClick={()=>this._Accounting_Submit()}>Muhasebe Onayla</button> 
                                     :
                                        <button className="btn btn-primary pull-right" 
                                             disabled={this.state.cars_data.length===0?false:true}
                                             style={{margin:5}} onClick={()=>this._Submit()}>Onayla</button> }

                                            {this.state.cars_data.length>0?
                                                <div className="pull-right text-center text-danger"> {this.state.cars_data.length} Adet Aracın Hesaplamasını Tamamlayınız.</div>
                                                :<></>
                                            }
                                            
                                      </div>  
                                </div>

                                : 
                 
                                this.props.id_com_user_type==='3'&&this.state.id_com_orderform_status==='4'?
                                <div className="panel-body">

                                {this.state.access_data.length>0?
                                        <>
                                        <h3 className="page-title text-center  ">Takılacak Aksesuarlar</h3>

                                            <DataTable
                                                    columns={this.state.access_data_columns}
                                                    data={this.state.access_data}
                                                    fixedHeader
                                                    noHeader
                                                    overflowY={false}
                                                    overflowYOffset={'100px'}
                                                    highlightOnHover
                                                    responsive
                                                    striped
                                                    dense
                                                    paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                                    pagination={true}
                                                    paginationPerPage={5}
                                                    defaultSortField={'id'}
                                                    defaultSortAsc={false}
                                                />
                                            </>
                                        :<></>} 
                                        <div className="panel-footer">
                                        {this.state.acc_count===this.state.access_data.length?
                                        <button className="btn btn-primary pull-right" 
                                             disabled={this.state.cars_data.length===0?false:true}
                                             style={{margin:5}} onClick={()=>this._Service_Submit()}>Teslimata Gönder</button> 
                                        :<></>}
                                </div>

                                </div>

        :

                       this.state.id_com_orderform_status==='5'&& this.props.id_com_user==='3'?

                                <OrderformDelivery id_com_orderform={this.state.id_com_orderform}/>
                                :   
                                <div className="panel-body">
                                <h3 className="page-title text-center text-danger">Görüntüleme Yetkiniz Bulunmamaktadır.</h3>
                            <a className="btn btn-danger" 
                                  href={'/orderform/list'}
                                    >Geri</a>
                                    
                                </div>
                    }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


        )
    }
}




export default connect(mapStateToProps)(OrderformApproval);


class AccessList extends React.Component {
    constructor(props){
      super(props)
      this.state={
        data:[],
        columns:[
            {
                name: 'Araç',
                selector: 'id_com_stock_name',
                left:'true',
                sortable:'true',
                },
          {
            name: 'Aksesuar Açıklaması',
            selector: 'text',
            left:'true',
            sortable:'true',
            },
   
            {
            name: 'Tutarı',
            selector: 'price',
            right:'true',
            sortable:'true',
            },
    
  
        ]
      }
    }
  async componentDidMount(){
      
  let stock_access_data  =   this.props.access_data.filter(row=>row.id_com_stock===this.props.data.id_com_stock)
  this.setState({data:stock_access_data})
  }
    render(){
        return(
  
          <DataTable
          dense 
          noHeader
          highlightOnHover    
          selectableRowsHighlight	
          pagination
          paginationPerPage={5}
          paginationComponentOptions={ {rowsPerPageText: 'Sayfa Başına Satır Sayısı:', rangeSeparatorText: ' -', noRowsPerPage: false, selectAllRowsItem: true, selectAllRowsItemText: 'Tümü' }}
          columns={this.state.columns}
          data={[...this.state.data]}
        />
          )
    }
      }