import React,{useState,useEffect} from 'react';
import {  Col, Row, Divider, PageHeader,Collapse ,Button,Table,Spin,Tooltip} from 'antd';
import {connect} from 'react-redux';
import mapStateToProps from '../../../redux/mapStateToProps'; 
import { CheckCircleFilled, EditOutlined, ExclamationCircleFilled, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import CostGroupAdd from './CostGroupAdd';
import CostGroupEdit from './CostGroupEdit';

 import 'moment/locale/tr'; 


const  formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 2
  })
   
 
 const {Panel} = Collapse;

const CostGroupList = ({api_url,loginToken})=>{

    const [Data,setData] =useState([])
    const [LogoData,setLogoData] =useState([])
    const [Banks,setBanks]=useState([]);
    const [TableData,setTableData]=useState([])
    const [AddModalVisible,setAddModalVisible] = useState(false);
    const [EditModalVisible,setEditModalVisible] = useState(false);
    const [DetailModalVisible,setDetailModalVisible] = useState(false)
    const [ModalData,setModalData] =useState([]); 
    const [SelectedRow,setSelectedRow] = useState({})
    const [SelectedCariCode,setSelectedCariCode] = useState({})
    const [Loading,setLoading] =useState(false); 


const Columns = [
    {
        title:'Id',
        dataIndex:'id',
        align:'center'

    },
    {
        title:'Firma adı ',
        dataIndex:'company_name',
        align:'center'
    },
    {
        title:'Gider Grup adı ',
        dataIndex:'text',
        align:'center'
    },
    {
        title:'Hesap Kodları',
        dataIndex:'cost_codes',
        align:'left',
        wrap:true
    },
    {
        title:'Kontrol',
         align:'right',
         render: row=> 
         row.id!=='x'&&
            <Row gutter={4} justify='end'>
                <Col>
                     <Button type='primary' size='small' shape='round' onClick={()=>{setEditModalVisible(true);setSelectedRow(row)}}  ><EditOutlined/></Button>
                </Col>
            </Row>
        
    },


]

    useEffect(() => {
        const OnLoad =()=>{
            _GetData();
         }       
        return OnLoad();
    }, [])

const _GetData  = async  () => {

    let url =  api_url+'/selectrows/view_cost_plan';
    const response  = await (await   fetch(url,{
            method:'POST',
            cache: 'no-cache',
            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
            body: 'token='+loginToken+'&type=user',
        })).json();
        setData(response)

}



return ( 
    <div className="main">
    <div className="main-content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="panel" style={{padding:20}}>
                        <Row>
                            <Col span={24}>
                            <PageHeader
                                title={'Gider Grupları |'}
                                subTitle={'Liste'}
                                extra={
                                    [
                                        <Button key='add' shape='round'  onClick={()=>setAddModalVisible(true)}   type='default'  disabled={Loading}><PlusCircleOutlined /></Button>
                                    ]
                                }
                            />
                            </Col>
                        </Row>
                        <Divider></Divider>

                        <Row>
                            <Col span={24}>
                                    <Table dataSource={Data} columns={Columns} size='small' bordered rowKey={row=>row.id} loading={Loading} pagination={false}/>
                            </Col>
                        </Row>
                            <CostGroupAdd visible={AddModalVisible} OnClose={()=> setAddModalVisible(false)} />
                            <CostGroupEdit visible={EditModalVisible}  OnClose={()=>{setEditModalVisible(false);setSelectedRow({})}} data={SelectedRow}/>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
}

export default connect(mapStateToProps)(CostGroupList);