import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
import DatePicker from '../utilities/DatePicker';

class InvAdd extends React.Component {
    constructor(props) {
          super(props);
          this.state = { 
            step1:false,
            step2:false,
            step3:false,
            id_com_invoice_type:'',
            id_com_payment:'',
            invoice_date:'',
            inv_number:'',
            doc_number:'',
            due_date_weekend:false,
            searchItem:'',
            customerListData:[],
            CustomerListLoaded:false,
            CustomerSelected:'',
            CustomerDetail:false,
            customerUpdate:true,
            email_status:true,
            phone1_status:true,
            taxno_status:true,
            company_name:'',
            name:'',
            surname:'',
            email:'',
            tax_office:'',
            taxno:'',
            phone1:'',
            id_com_city:'',
            id_com_town:'',
            adress:'',
            postcode:'',
            searchProduct:'',
            productLoaded:false,
            ProductList:[],
            invItems:[],
            List_Amount:0,
            Amount:0,
            Tax_Amount:0,
            Disc_Amount:0,
            Total_Amount:0,
            Total_Cost:0,
            Form_Calculated:false,
            comment:'',
            disable_button:false
          };
this.addDays=this.addDays.bind(this)
this.Search=this.Search.bind(this)
this.CustomerDetail=this.CustomerDetail.bind(this)
this.Verification=this.Verification.bind(this)
this.CustomerUpdate=this.CustomerUpdate.bind(this)
this.SearchProduct=this.SearchProduct.bind(this)
this.ChangeItemQuantity=this.ChangeItemQuantity.bind(this)
this.ChangeItemDiscount=this.ChangeItemDiscount.bind(this)

this._Calculate=this._Calculate.bind(this)
this.Submit=this.Submit.bind(this)

        }


Submit() { 

 
  

  let d = new Date(); 
  let date = d.toISOString();
  let c_date  = date.split('T')[0];
  let c_time = d.toLocaleTimeString('tr-TR');
  let c_id_com_user =  this.props.id_com_user;
  let id_com_company =  this.props.id_com_company;
  let invoice_date = this.state.invoice_date;
  let id_com_invoice_type = this.state.id_com_invoice_type;
  let inv_number = this.state.inv_number;
  let id_com_customer = this.state.CustomerSelected;
  let id_com_payment = this.state.id_com_payment;
  let due_date = this.state.due_date;
  let doc_number = this.state.doc_number;
  let comment = this.state.comment; 
  
  let insert_invoice = 
  'c_date='+ c_date+
  '&invoice_date='+ invoice_date.toISOString().split('T')[0]+
  '&c_time='+ c_time+
  '&id_com_invoice_type='+ id_com_invoice_type+
  '&inv_number='+ inv_number+
  '&id_com_customer='+ id_com_customer+
  '&id_com_company='+ id_com_company+
  '&c_id_com_user='+ c_id_com_user+
  '&doc_number='+ doc_number+
  '&comment='+ comment+
  '&acc_nr=0'+
  '&acc_status=0'+
  '&due_date='+due_date.toISOString().split('T')[0]+
  '&id_com_payment='+ id_com_payment+

  '&token='+this.props.loginToken+
  '&type=user&id_com_invoice_status=1';


  let  insert_url =  this.props.api_url+'/insert/com_invoice'
  let invItems = this.state.invItems;
  
  fetch(insert_url, {
      method: 'POST',
      headers:  { 
        'Content-Type':  'application/x-www-form-urlencoded'
              // 'Content-Type': 'application/json' 
               },
      body: insert_invoice,
    })
    .catch(error=> console.log(error))
    .then(res=> res.json())
    .then(response => {

       let id_com_invoice = response.id

       invItems.map((row)=>{
        let  insert_line =  
        'id_com_invoice='+id_com_invoice+
        '&id_com_product='+ row.id+
        '&quantity='+ row.InvQuantity+
        '&disc_rate='+ row.InvDiscount+
        '&list_price='+ row.list_price+
        '&disc_amount='+ row.Disc_Amount+
        '&net_price='+ row.Amount+
        '&tax_rate='+ row.id_com_tax_rate_rate+
        '&tax_amount='+ row.Tax_Amount+
        '&line_amount='+ row.Total_Amount+
        '&id_com_company='+ id_com_company+
        '&token='+this.props.loginToken+
        '&acc_nr_purchase='+row.acc_nr_purchase+
        '&acc_nr_sale='+row.acc_nr_sale+
        '&acc_nr_cost='+row.acc_nr_cost+
        '&acc_nr_disc='+row.acc_nr_disc+
        '&acc_nr_expense='+row.acc_nr_expense+
        '&acc_nr_income='+row.acc_nr_income+
        '&avg_cost='+row.Avg_Cost+
        '&total_cost='+(parseFloat(row.Avg_Cost)*parseFloat(row.InvQuantity))+
        '&type=user&id_com_invoice_line_status=1';

        let line_url =  this.props.api_url+'/insert/com_invoice_line'
        fetch(line_url, { 
          method: 'POST',
          headers:  { 
            'Content-Type':  'application/x-www-form-urlencoded'
                  // 'Content-Type': 'application/json' 
                   },
          body: insert_line,
        })
        .catch(error=> console.log(error))
        .then( res => {
          window.location.href='/InvList'
          return null
        })

        return null
    })    })





}

_Calculate() { 

let InvItems=this.state.invItems;
  
   let List_Amount=0;
   let Amount=0;
   let Tax_Amount=0;
   let Disc_Amount=0;
   let Total_Amount=0;
   let Total_Cost=0;


   InvItems.map((row)=>{
    List_Amount+=(parseFloat(row.list_price)*parseFloat(row.InvQuantity)) ;
    Amount+=parseFloat(row.Amount);
    Tax_Amount+=parseFloat(row.Tax_Amount);
    Disc_Amount+=parseFloat(row.Disc_Amount);
    Total_Cost+=(parseFloat(row.Avg_Cost)*parseFloat(row.InvQuantity)) ;
    Total_Amount+=parseFloat(row.Total_Amount);
    return null
   })
   
  this.setState({
  List_Amount:Math.round(List_Amount*100)/100,
  Amount:Math.round(Amount*100)/100,  
  Tax_Amount:Math.round(Tax_Amount*100)/100,
  Disc_Amount:Math.round(Disc_Amount*100)/100,
  Total_Cost:Math.round(Total_Cost*100)/100,
  Total_Amount:Math.round(Total_Amount*100)/100
})
         

        }

        ChangeItemQuantity (q,index) { 
          let invItems = this.state.invItems
          invItems[index].InvQuantity = parseFloat(q)

        }
        ChangeItemDiscount (q,index) { 
          let invItems = this.state.invItems
          invItems[index].InvDiscount = parseFloat(q)

        }


        CustomerUpdate(){ 
          let d = new Date(); 
          let date = d.toISOString();
          let m_date  = date.split('T')[0];
          let m_time = d.toLocaleTimeString('tr-TR');
      let id_com_customer=this.state.CustomerSelected;
      let company_name=this.state.company_name;
      let name=this.state.name;
      let surname=this.state.surname;
      let email=this.state.email;
      let tax_office=this.state.tax_office;
      let taxno=this.state.taxno;
      let phone1=this.state.phone1;
      let id_com_city=this.state.id_com_city;
      let id_com_town=this.state.id_com_town;
      let adress=this.state.adress;
      let postcode=this.state.postcode;

    let  update=
    'id='+ id_com_customer+
    '&company_name='+ company_name+
    '&taxno='+ taxno+
    '&tax_office='+ tax_office+
    '&name='+ name+
    '&surname='+surname +
    '&email='+email +
    '&postcode='+postcode +
    '&phone1='+phone1 +
    '&id_com_city='+ id_com_city+
    '&id_com_town='+ id_com_town+
    '&adress='+ adress+
    '&m_id_com_user='+ this.props.id_com_user+
    '&mtime='+ m_time+
    '&mdate='+ m_date+
    '&token='+this.props.loginToken+'&type=user';

let  update_url =  this.props.api_url+'/update/com_customer'

this.setState({customerUpdate:true})
fetch(update_url, {
    method: 'POST',
    headers:  { 
      'Content-Type':  'application/x-www-form-urlencoded'
            // 'Content-Type': 'application/json' 
             },
    body: update,
  })
  .catch(error=> console.log(error))
  .then(res=> console.log('Customer Updated!'))

        }

        async Verification (type,value) { 


          switch(type) {
              case 'taxno':
                  let taxno = value.target.value.trim()
      
                  this.setState({taxno:taxno})
                  let url_taxno =this.props.api_url+'/selectRows/com_customer';
                 // console.log(value.target.value+' '+url_taxno)
                 await fetch(url_taxno,{
                        method:'POST',
                        cahce: 'no-cache',
                        headers:{ 
                          'Content-Type':'application/x-www-form-urlencoded'
                        },
                        body: 'token='+this.props.loginToken+'&type=user&taxno='+taxno+'&id_com_company='+this.props.id_com_company
                    }).then(res=>res.json())
                    .then(result=>{
                              result.length===0&&taxno.length>9?this.setState({taxno_status:true}):this.setState({taxno_status:false})
                    })
      
              break;
      
              case 'phone1':
                  let phone1 = value.target.value.trim()
                  this.setState({phone1:phone1})
                  let url_phone1=this.props.api_url+'/selectRows/com_customer';
                 // console.log(value.target.value+' '+url_taxno)
                    await fetch(url_phone1,{
                        method:'POST',
                        cahce: 'no-cache',
                        headers:{ 
                          'Content-Type':'application/x-www-form-urlencoded'
                        },
                        body: 'token='+this.props.loginToken+'&type=user&phone1='+phone1+'&id_com_company='+this.props.id_com_company
                    }).then(res=>res.json())
                    .then(result=>{
                              result.length===0&&phone1.length===11?this.setState({phone1_status:true}):this.setState({phone1_status:false})
                    })
      
              break;
      
              case 'email':
                  let email = value.target.value.toLowerCase().trim()
                  this.setState({email:email})
               
                  let url_email=this.props.api_url+'/selectRows/com_customer';
                 // console.log(value.target.value+' '+url_taxno)
                    await fetch(url_email,{
                        method:'POST',
                        cahce: 'no-cache',
                        headers:{ 
                          'Content-Type':'application/x-www-form-urlencoded'
                        },
                        body: 'token='+this.props.loginToken+'&type=user&email='+email+'&id_com_company='+this.props.id_com_company
                    }).then(res=>res.json())
                    .then(result=>{
                              result.length===0&&email.includes('@')?this.setState({email_status:true}):this.setState({email_status:false})
                    })
      
              break;
      
              default:
                  
      
          }
      
      
      }

    
      SearchProduct(code) { 

        let product_url = this.props.api_url+'/productSearch/'+this.props.id_com_company;

        fetch(product_url,{
          method:'POST',
          cahce: 'no-cache',
          headers:{ 
            'Content-Type':'application/x-www-form-urlencoded'
          },
          body: 'token='+this.props.loginToken+'&type=user&match='+code
      }).then(res=>res.json())
      .then(result=>{

      let  difference1 =result.filter(row => !this.state.invItems.find(row2 => row.id === row2.id ))

       this.setState({ProductList:difference1,productLoaded:true})
      })


      }
 _excludeItem(index) { 

  let invItems = this.state.invItems;
  invItems.splice(index,1);
   this.setState({invItems:invItems});
  //console.log(this.state.invItems)
  this._Calculate()
}

  _includeItem(id) { 


  let invItems = this.state.invItems;
  let product_url = this.props.api_url+'/selectRows/view_product';
  fetch(product_url,{
    method:'POST',
    cahce: 'no-cache',
    headers:{ 
      'Content-Type':'application/x-www-form-urlencoded'
    },
    body: 'token='+this.props.loginToken+'&type=user&id='+id+'&id_com_company='+this.props.id_com_company
}).then(res=>res.json())
.then(result=>{

 invItems.push(result[0]);

 
 invItems.forEach(row => {

  row.InvQuantity = 1 ;
  row.InvDiscount = 0 ;
  row.Disc_Amount = 0 ;
  row.Avg_Cost = parseFloat(result[0].avg_cost) ;

//   let product_url = this.props.api_url+'/selectRows/view_stock_info';

//  fetch(product_url,{
//     method:'POST',
//     cahce: 'no-cache',
//     headers:{ 
//       'Content-Type':'application/x-www-form-urlencoded'
//     },
//     body: 'token='+this.props.loginToken+'&type=user&id_com_product='+row.id+'&id_com_company='+this.props.id_com_company
// }).then(res=>res.json())
// .then(result=>{
//   if(result.length>0){
//     row.Avg_Cost = 
//     Math.round((
//     parseFloat( result[0].avg_cost)*1
//     )*100)/ 100; 
//    } else {row.Avg_Cost = 0}
// })


   row.Amount = 
      Math.round((
      parseFloat(row.list_price)*1
      )*100)/ 100;
  

   row.Tax_Amount = 
      (Math.round((
      parseFloat(row.list_price)
      *(parseFloat(row.id_com_tax_rate_rate))
      )*100)/ 100);

      row.Total_Cost = 
      Math.round((
        parseFloat(result[0].avg_cost)*1
        )*100)/ 100;

   row.Total_Amount = 
      (Math.round((
      parseFloat(row.list_price)
      *(1+parseFloat(row.id_com_tax_rate_rate))
      )*100)/ 100);

 });

 this.setState({invItems:invItems})
 this._Calculate()

})

//  console.log(this.state.invItems)
}

        async Search() {

          let url = this.props.api_url+'/customerSearch/'+this.props.id_com_company;
          
          fetch(url, {
          method: 'POST',
           cache: 'no-cache',
          headers:  { 
          'Content-Type':  'application/x-www-form-urlencoded'
                // 'Content-Type': 'application/json' 
                  },
          body: 'match='+this.state.searchItem+'&token='+this.props.loginToken+'&type=user',
          })
  
          .then(response => response.json() )
          .then(customerListData=>
              {
                  this.setState({customerListData:customerListData,CustomerListLoaded:true});
              }
              )
              
         
      }
      addDays(date, id_com_payment) {
     


          let select_url=this.props.api_url+'/selectRows/com_payment';
           fetch(select_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
                body:  'token='+this.props.loginToken+'&type=user&id='+id_com_payment
               
            }).then(res=>res.json())
            .then(result=>{
              if(result.length===0){ 
                alert('Lütfen Vade Tarihini Seçiniz')
              } else {
              let newdate = new Date(date)
              
              newdate.setDate(newdate.getDate()+parseInt(result[0].day))
              if(newdate.getDay()===0) {
                 this.setState({due_date_weekend:true})

                  newdate.setDate(newdate.getDate()+1)
                } else if (newdate.getDay()===6) {
                  this.setState({due_date_weekend:true})

                  newdate.setDate(newdate.getDate()+2)
                } else {
                  this.setState({due_date_weekend:false})

                }
                   
              let due_date = newdate.toISOString().split('T')[0]
            this.setState({due_date:due_date});

              }
            
            })
          
        }

        CustomerDetail(id){
          let select_url=this.props.api_url+'/selectRows/view_customer';

         fetch(select_url,{
                  method:'POST',
                  cahce: 'no-cache',
                  headers:{ 
                    'Content-Type':'application/x-www-form-urlencoded'
                  },
                  body:  'token='+this.props.loginToken+'&type=user&id='+id
                 
              }).then(res=>res.json())
              .then(result=>{

                this.setState({
                  company_name:result[0].company_name,
                  name:result[0].name,
                  surname:result[0].surname,
                  email:result[0].email,
                  tax_office:result[0].tax_office,
                  taxno:result[0].taxno,
                  phone1:result[0].phone1,
                  id_com_city:result[0].id_com_city,
                  id_com_town:result[0].id_com_town,
                  adress:result[0].adress,
                  postcode:result[0].postcode

                })
              })

        }

        componentDidMount(){
          let date = new Date();
          let yyyy = date.getFullYear();


          let select_url=this.props.api_url+'/selectRows/com_invoice';

            fetch(select_url,{
                  method:'POST',
                  cahce: 'no-cache',
                  headers:{ 
                    'Content-Type':'application/x-www-form-urlencoded'
                  },
                  body:  'token='+this.props.loginToken+'&type=user&id_com_company='+this.props.id_com_company
                 
              }).then(res=>res.json())
              .then(result=>{
                   //console.log(result.length)

                   let doc_number = 
                   
                   result.length>=9999999?
                  result.length+1:
                   result.length>=999999?
                   '0'+result.length+1:
                   result.length>=99999?
                   '00'+result.length+1:
                   result.length>=9999?
                   '000'+result.length+1:
                   result.length>=999?
                   '0000'+(result.length+1):
                   result.length>=99?
                   '00000'+(result.length+1):
                   result.length>=9?
                   '000000'+(result.length+1):
                   '0000000'+(result.length+1)
                  // console.log(doc_number)
                   this.setState({doc_number:'INV'+yyyy+doc_number})

              })
        }

        render() { 
          return(
              <React.Fragment>
                  <div className="main">
                      <div className="main-content">
                          <div className="container-fluid">
                              <div className="row">
                                  <div className="col-md-12">
                                      <h3 className="page-title">Fiş / Fatura</h3>
                                  </div>
                                  <div className="col-md-12">
                                      <div className="panel">
                                          <div className="panel-heading">
                                              <h3 className="panel-title">Fiş / Fatura Oluştur</h3>
                                          </div>
                                          <div className="panel-body">
                                              <SelectList table="com_invoice_type" Label="Fatura Tipi" onChange={(x)=>this.setState({id_com_invoice_type: x.id})} />
                                              <SelectList table="com_payment" Label="Vade Tipi" onChange={(x)=>this.setState({id_com_payment: x.id,invoice_date:''})} />
                                              <div className="form-group">
                                                  <label>Fatura Tarihi</label>
                                                  <DatePicker    onChange={(x)=>{  this.setState({invoice_date: x});this.addDays(JSON.stringify(x).slice(1,11),this.state.id_com_payment) }}  />
                                                  {/* <input type="date" className="form-control" value={this.state.invoice_date} onChange={(x)=>{this.setState({invoice_date: x.target.value});this.addDays(x.target.value,this.state.id_com_payment)}}/> */}
                                              </div>
                                              <div className="form-group">
                                                  <label>Vade Tarihi</label>
                                                  <DatePicker    disabled={true} value={this.state.due_date} />
                                                  {/* <input className="form-control" disabled value={this.state.due_date} /> */}
                                                  {this.state.due_date_weekend?
                                                      <div className="alert alert-danger">
                                                          <p>Vade Tarihi Haftasonuna Denk Geldiğinden Sonraki İlk İş Günü İşaretlenmiştir.</p>
                                                      </div>
                                                  :
                                                      <></>
                                                  }
                                              </div>
                                              <div className="form-group">
                                                  <label>Belge No</label>
                                                  <input className="form-control" value={this.state.doc_number} placeholder="Belge No" disabled/>
                                              </div>
                                              <div className="form-group">
                                                  <label>Fiş / Fatura No</label>
                                                  <input className="form-control" value={this.state.inv_number} placeholder="Fiş / Fatura No" onChange={(x)=>this.setState({inv_number: x.target.value.toUpperCase()})}/>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="panel">
                                          <div className="panel-heading">
                                              <h3 className="panel-title">Müşteri Bilgileri</h3>
                                          </div>
                                          <div className="panel-body">
                                              { this.state.CustomerSelected?
                                                  <div>
                                                      {this.state.id_com_customer_type!=='1'?
                                                          <div className="form-group">
                                                            <label>Firma Adı</label>
                                                            <input className="form-control" placeholder="Firma Adı" value={this.state.company_name} onChange={(x)=>this.setState({company_name:x.target.value,customerUpdate:false})} />
                                                          </div>
                                                      :
                                                          <></>
                                                      }
                                                      <div className="form-group">
                                                          <label>Adı</label>
                                                          <input className="form-control" placeholder="Adı" value={this.state.name} onChange={(x)=>this.setState({name:x.target.value,customerUpdate:false})} />
                                                      </div>
                                                      <div className="form-group">
                                                          <label>Soyadı</label>
                                                          <input className="form-control" placeholder="Soyadı" value={this.state.surname} onChange={(x)=>this.setState({surname:x.target.value,customerUpdate:false})} />
                                                      </div>
                                                      <div className="form-group">
                                                          <label>E-Posta</label>
                                                          <input type="email" className="form-control" placeholder="E-Posta" value={this.state.email} onChange={(x)=>this.Verification('email',x)}/>
                                                      </div>
                                                      <div className="form-group">
                                                          <label>Vergi Dairesi</label>
                                                          <input value={this.state.tax_office} className="form-control" placeholder="Vergi Dairesi" onChange={(x)=>this.setState({tax_office:x.target.value,customerUpdate:false})} />
                                                      </div>
                                                      <div className="form-group">
                                                          <label>Vergi No</label>
                                                          <input type="number" value={this.state.taxno} className="form-control" placeholder="Vergi No" onChange={(x)=>this.Verification('taxno',x)} />
                                                      </div>
                                                      <div className="form-group">
                                                          <label>Telefon</label>
                                                          <input type="number" value={this.state.phone1} className="form-control" placeholder="Telefon" onChange={(x)=>this.Verification('phone1',x)} />
                                                      </div>
                                                      <SelectList table="com_city" value={this.state.id_com_city} Label="Şehir" onChange={(x)=>this.setState({id_com_city: x.id,customerUpdate:false})} />
                                                      <SelectList table="com_town" value={this.state.id_com_town} id_com_city={this.state.id_com_city} Label="İlçe" onChange={(x)=>this.setState({id_com_town: x.id,customerUpdate:false})}/>
                                                      <div className="form-group">
                                                          <label>Posta Kodu</label>
                                                          <input className="form-control" placeholder="Posta Kodu" value={this.state.postcode} onChange={(x)=>this.setState({postcode:x.target.value,customerUpdate:false})} />
                                                      </div>
                                                      <div className="form-group">
                                                          <label>Adresi</label>
                                                          <textarea className="form-control" rows="5" value={this.state.adress} onChange={(x)=>this.setState({adress: x.target.value,customerUpdate:false})}></textarea>
                                                      </div>
                                                      <div className="form-group">
                                                          <button className="btn btn-danger" onClick={()=>this.setState({
                                                              CustomerSelected:'',
                                                              CustomerDetail:false,
                                                              customerUpdate:true,
                                                              email_status:true,
                                                              phone1_status:true,
                                                              taxno_status:true,
                                                              company_name:'',
                                                              name:'',
                                                              surname:'',
                                                              email:'',
                                                              tax_office:'',
                                                              taxno:'',
                                                              phone1:'',
                                                              id_com_city:'',
                                                              id_com_town:'',
                                                              adress:'',
                                                              postcode:'',
                                                              searchItem:''
                                                          })}>Yeniden Seç</button> <button className="btn btn-primary" onClick={()=>this.CustomerUpdate()} disabled={this.state.customerUpdate}>Güncelle</button>
                                                      </div>
                                                  </div>
                                                  :
                                                  <React.Fragment>
                                                      <div className="form-group">
                                                          <label>Müşteri Seçiniz</label>
                                                          <input value={this.state.searchItem} className="form-control" type="text" placeholder="TC Kimlik No, Telefon, Adı Soyadı" onChange={(x)=> {this.setState({searchItem:x.target.value});this.Search()}} />
                                                      </div>
                                                      {this.state.CustomerListLoaded===true?
                                                          <table className="table table-bordered table-striped">
                                                              <thead>
                                                                  <tr>
                                                                      <th>No</th>
                                                                      <th>Firma Adı</th>
                                                                      <th>Müşteri Adı</th>
                                                                      <th>Tel No</th>
                                                                      <th>Vergi No</th>
                                                                      <th>İşlem</th>
                                                                  </tr>
                                                              </thead>
                                                              <tbody>
                                                              {this.state.searchItem===''?
                                                                  <></>
                                                              :
                                                                  this.state.customerListData.map((row,index)=>
                                                                          <tr key={index} style={{background:this.state.id_com_customer===row.id?'lightgreen':''}} >
                                                                              <td>{row.id} </td>
                                                                              <td>{row.company_name}</td>
                                                                              <td>{row.username}</td>
                                                                              <td>{row.phone1}</td>
                                                                              <td>{row.taxno}</td>
                                                                              <td><button onClick={()=>{this.setState({CustomerSelected:row.id,CustomerDetail:true});this.CustomerDetail(row.id)}} className="btn btn-primary btn-sm">Seç</button></td>
                                                                          </tr>
                                                                      )
                                                              }
                                                              </tbody>
                                                          </table>

                                                          :<></>}
                                                  </React.Fragment>
                                              }
                                          </div>
                                      </div>
                                      <div className="panel">
                                          <div className="panel-heading">
                                              <h3 className="panel-title">Fatura Satırları</h3>
                                          </div>
                                          <div className="panel-body">
                                              <div className="form-group">
                                                  <label>Malzeme Kartı</label>
                                                  <input value={this.state.searchProduct} className="form-control" type="text" placeholder="Malzeme Kodu, Malzeme Adı, OEM Kodu" onChange={(x)=> {this.setState({searchProduct:x.target.value});this.SearchProduct(x.target.value)}} />
                                              </div>
                                              <table className="table table-bordered table-striped">
                                                  <thead>
                                                      <tr>
                                                          <th>No</th>
                                                          <th>Malzeme Kodu</th>
                                                          <th>Oem Kodu</th>
                                                          <th>Sınıf</th>
                                                          <th>Grup</th>
                                                          <th>Malzeme Adı</th>
                                                          <th>Liste Fiyati</th>
                                                          <th>Depo Adı</th>
                                                          <th>Stok Adedi</th>
                                                          <th>Kontrol</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                  {this.state.searchProduct===''?
                                                      <></>
                                                  :
                                                      this.state.ProductList.map((row,index)=>
                                                              <tr key={index}  >
                                                                  <td>{row.id} </td>
                                                                  <td>{row.code}</td>
                                                                  <td>{row.oem_code}</td>
                                                                  <td>{row.id_com_product_type_name}</td>
                                                                  <td>{row.id_com_product_class_name}</td>
                                                                  <td>{row.text}</td>
                                                                  <td>{row.list_price}</td>
                                                                  <td>{row.id_com_depot_name}</td>
                                                                  <td>{row.stock_level}</td>
                                                                  <td>
                                                                      <button disabled={this.state.id_com_invoice_type==='1'?row.list_price===null?true:false:false}
                                                                              onClick={()=>{
                                                                                  this.setState({searchProduct:'',ProductList:[],productLoaded:false});
                                                                                  this._includeItem(row.id)}
                                                                              }
                                                                              className="btn btn-primary btn-sm">{
                                                                                  this.state.id_com_invoice_type==='1'?
                                                                                      parseFloat(row.stock_level)<=0?
                                                                                          'Stok Bulunmamaktadır'
                                                                                  :
                                                                                          row.list_price===null || parseFloat(row.list_price) === 0 ?
                                                                                              'Liste Fiyatı Yok':'Seç':'Seç'
                                                                              }
                                                                      </button>
                                                                  </td>
                                                              </tr>
                                                          )
                                                  }
                                                  </tbody>
                                              </table>
                                          </div>
                                      </div>
                                      {this.state.productLoaded===true?
                                          <div className="alert alert-info text-center">
                                              <i className="fas fa-spinner fa-spin fa-2x"></i>
                                              <p className="lead">Yükleniyor</p>
                                          </div>
                                      :
                                          <></>
                                      }
                                      <div className="panel">
                                          <div className="panel-heading">
                                              <h3 className="panel-title">Fatura Satırları</h3>
                                          </div>
                                          <div className="panel-body">
                                              {this.state.invItems.length>0?
                                                  <table className="table table-bordered table-striped">
                                                      <thead>
                                                      <tr>
                                                          <th>Satır No</th>
                                                          <th>Tip</th>
                                                          <th>Kodu</th>
                                                          <th>Adı</th>
                                                          <th>Adet</th>
                                                          <th>Birim</th>
                                                          <th>Liste Fiyatı</th>
                                                          <th>Tutarı</th>
                                                          <th>İndirim Oranı</th>
                                                          <th>İndirim Tutarı</th>
                                                          <th>İndirimli Tutar</th>
                                                          <th>KDV</th>
                                                          <th>KDV Tutarı</th>
                                                          <th>Toplam Tutar</th>
                                                          <th>İşlem</th>
                                                      </tr>
                                                      </thead>
                                                      <tbody>
                                                      {this.state.invItems.map((row,index)=>
                                                          <tr key={index}>
                                                              <td>{index+1} </td>
                                                              <td>{row.id_com_product_class_name}</td>
                                                              <td>{row.code}</td>
                                                              <td>{row.text}</td>
                                                              <td>
                                                                  <input type="number" value={this.state.invItems[index].InvQuantity} className="form-control" onChange={(x)=>{
                                                                      if(parseFloat(x.target.value)<1){
                                                                          alert('Adet negatif değer veya sıfır olamaz');
                                                                          this.state.invItems[index].InvQuantity = 1;
                                                                      } else {
                                                                          this.state.invItems[index].InvQuantity = x.target.value===''?1:parseFloat(x.target.value);
                                                                      };
                                                                      this.SearchProduct();
                                                                      this.state.invItems[index].Amount =
                                                                          Math.round((
                                                                              parseFloat(row.list_price) * parseFloat(this.state.invItems[index].InvQuantity) * (1-(parseFloat(this.state.invItems[index].InvDiscount)/100))) *100)/ 100
                                                                      this.state.invItems[index].Tax_Amount =
                                                                          Math.round((parseFloat(row.list_price) * parseFloat(this.state.invItems[index].InvQuantity) * (1-(parseFloat(this.state.invItems[index].InvDiscount))/100) * parseFloat(row.id_com_tax_rate_rate))*100)/ 100
                                                                      this.state.invItems[index].Disc_Amount =
                                                                          (Math.round((parseFloat(row.list_price) * parseFloat(this.state.invItems[index].InvQuantity) * (parseFloat(this.state.invItems[index].InvDiscount)/100)) * 100  )/ 100);
                                                                      this.state.invItems[index].Total_Amount =
                                                                          (Math.round( (parseFloat(row.list_price) * parseFloat(this.state.invItems[index].InvQuantity) * (1-(parseFloat(this.state.invItems[index].InvDiscount))/100) * (1+parseFloat(row.id_com_tax_rate_rate)))*100  )/ 100);
                                                                      this._Calculate();
                                                                  }}
                                                                  />
                                                              </td>
                                                              <td>{row.id_com_unit_name}</td>
                                                              {this.state.id_com_invoice_type==='1'?
                                                                  <td>{row.list_price}</td>
                                                              :
                                                                  <td>
                                                                      <input type="number" value={this.state.invItems[index].list_price} className="form-control" onChange={(x)=>{
                                                                          if(parseFloat(x.target.value)<1){
                                                                              alert('Adet negatif değer veya sıfır olamaz');
                                                                              this.state.invItems[index].list_price = 1;
                                                                          } else {
                                                                              this.state.invItems[index].list_price = x.target.value===''?1:parseFloat(x.target.value);
                                                                          };
                                                                          this.SearchProduct();
                                                                          this.state.invItems[index].Amount =
                                                                              Math.round((
                                                                                  parseFloat(row.list_price) * parseFloat(this.state.invItems[index].InvQuantity) * (1-(parseFloat(this.state.invItems[index].InvDiscount)/100))) *100)/ 100
                                                                          this.state.invItems[index].Tax_Amount =
                                                                              Math.round((parseFloat(row.list_price) * parseFloat(this.state.invItems[index].InvQuantity) * (1-(parseFloat(this.state.invItems[index].InvDiscount))/100) * parseFloat(row.id_com_tax_rate_rate))*100)/ 100
                                                                          this.state.invItems[index].Disc_Amount =
                                                                              (Math.round((parseFloat(row.list_price) * parseFloat(this.state.invItems[index].InvQuantity) * (parseFloat(this.state.invItems[index].InvDiscount)/100))*100  )/ 100);
                                                                          this.state.invItems[index].Total_Amount =
                                                                              (Math.round( (parseFloat(row.list_price) * parseFloat(this.state.invItems[index].InvQuantity) * (1-(parseFloat(this.state.invItems[index].InvDiscount))/100) * (1+parseFloat(row.id_com_tax_rate_rate)))*100  )/ 100);
                                                                          this._Calculate();
                                                                      }}
                                                                      />
                                                                  </td>
                                                              }
                                                              <td>
                                                                  <input type="number" value={
                                                                      Math.round((
                                                                          parseFloat(row.list_price) * parseFloat(this.state.invItems[index].InvQuantity)) *100  )/ 100} className="form-control" disabled={true}
                                                                  />
                                                              </td>
                                                              <td>
                                                                  <input type="number" value={
                                                                      this.state.invItems[index].InvDiscount}
                                                                         className="form-control"
                                                                         onChange={(x)=>{
                                                                             if(parseFloat(x.target.value)<0){
                                                                                 alert('İndirim negatif değer olamaz');
                                                                                 this.state.invItems[index].InvDiscount = 0;
                                                                             } else if(parseFloat(x.target.value)>100) {
                                                                                 alert("İndirim oranı 100'den büyük olamaz");
                                                                                 this.state.invItems[index].InvDiscount = 100;
                                                                             } else {
                                                                                 this.state.invItems[index].InvDiscount = x.target.value===''?0:parseFloat(x.target.value); };
                                                                             this.SearchProduct();
                                                                             this.state.invItems[index].Amount =
                                                                                 Math.round((
                                                                                     parseFloat(row.list_price) * parseFloat(this.state.invItems[index].InvQuantity) * (1-(parseFloat(this.state.invItems[index].InvDiscount)/100))) *100)/ 100
                                                                             this.state.invItems[index].Tax_Amount =
                                                                                 Math.round((parseFloat(row.list_price) * parseFloat(this.state.invItems[index].InvQuantity) * (1-(parseFloat(this.state.invItems[index].InvDiscount))/100) * parseFloat(row.id_com_tax_rate_rate))*100)/ 100
                                                                             this.state.invItems[index].Disc_Amount =
                                                                                 (Math.round((parseFloat(row.list_price) * parseFloat(this.state.invItems[index].InvQuantity) * (parseFloat(this.state.invItems[index].InvDiscount)/100))*100  )/ 100);
                                                                             this.state.invItems[index].Total_Amount = (Math.round( (parseFloat(row.list_price) * parseFloat(this.state.invItems[index].InvQuantity) * (1-(parseFloat(this.state.invItems[index].InvDiscount))/100) * (1+parseFloat(row.id_com_tax_rate_rate)))*100  )/ 100);
                                                                             this._Calculate();
                                                                         }
                                                                         }
                                                                  />
                                                              </td>
                                                              <td>
                                                                  <input value={(Math.round((parseFloat(row.list_price) * parseFloat(this.state.invItems[index].InvQuantity) * (parseFloat(this.state.invItems[index].InvDiscount)/100))*100  )/ 100)} className="form-control"  disabled={true} />
                                                              </td>
                                                              <td>
                                                                  <input type="number" value={
                                                                      Math.round((
                                                                          parseFloat(row.list_price) * parseFloat(this.state.invItems[index].InvQuantity) * (1-(parseFloat(this.state.invItems[index].InvDiscount)/100))) *100)/ 100} className="form-control" disabled={true}
                                                                  />
                                                              </td>
                                                              <td>
                                                                  <input value={row.id_com_tax_rate_name} className="form-control" disabled={true} />
                                                              </td>
                                                              <td>
                                                                  <input value={Math.round((parseFloat(row.list_price) * parseFloat(this.state.invItems[index].InvQuantity) * (1-(parseFloat(this.state.invItems[index].InvDiscount))/100) * parseFloat(row.id_com_tax_rate_rate))*100)/ 100} className="form-control" disabled={true} />
                                                              </td>
                                                              <td>
                                                                  <input value={Math.round( (parseFloat(row.list_price) * parseFloat(this.state.invItems[index].InvQuantity) * (1-(parseFloat(this.state.invItems[index].InvDiscount))/100) * (1+parseFloat(row.id_com_tax_rate_rate)))*100  )/ 100} className="form-control" disabled={true} />
                                                              </td>
                                                              <td>
                                                                  <button onClick={()=>{this._excludeItem(index,row.id)}} className="btn btn-primary btn-sm">Çıkar</button>
                                                              </td>
                                                          </tr>
                                                      )
                                                      }
                                                      </tbody>
                                                  </table>
                                              :
                                                  <></>
                                              }
                                          </div>
                                          <div className="panel-heading">
                                              <h3 className="panel-title">Fatura Toplamı</h3>
                                          </div>
                                          <div className="panel-body">
                                              <table className="table table-bordered table-striped">
                                                  <tbody>
                                                  <tr>
                                                      <th width="200">Tutar</th>
                                                      <td className="text-right">{this.state.List_Amount}</td>
                                                  </tr>
                                                  <tr>
                                                      <th>İndirm Tutarı</th>
                                                      <td className="text-right">{this.state.Disc_Amount}</td>
                                                  </tr>
                                                  <tr>
                                                      <th>KDV Matrahı</th>
                                                      <td className="text-right">{this.state.Amount}</td>
                                                  </tr>
                                                  <tr>
                                                      <th>Kdv Tutarı</th>
                                                      <td className="text-right">{this.state.Tax_Amount}</td>
                                                  </tr>
                                                  <tr>
                                                      <th>Toplam Tutar</th>
                                                      <td className="text-right">{this.state.Total_Amount}</td>
                                                  </tr>
                                                  </tbody>
                                              </table>
                                              <div className="form-group">
                                                  <label>Fatura Açıklaması</label>
                                                  <textarea className="form-control" rows="5" cols="100" onChange={(x)=>this.setState({comment:x.target.value})} />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="form-group">
                              <a className="btn btn-danger" href="/InvList">İptal</a> <button className="btn btn-primary" onClick={()=>{this.setState({disable_button:true});this.Submit()}} disabled={this.state.disable_button}>Kaydet</button>
                          </div>
                      </div>
                  </div>
              </React.Fragment>
          )
    }
}
export default connect(mapStateToProps)(InvAdd);