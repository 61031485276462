
import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
import DatePicker from '../utilities/DatePicker';
import {alertMessage} from '../../assets.js';
import DataTable from 'react-data-table-component';


const columns = [

    {
        name: 'Plaka',
        selector: 'license_plate',
         left:true,
        sortable:true
    },
    {
        name: 'Marka',
        selector: 'id_com_brand_name',
         left:true,
        sortable:true
    },
    {
        name: 'Model',
        selector: 'id_com_model_name',
         left:true,
        sortable:true
    },
    {
        name: 'Spek',
        selector: 'spec',
         left:true,
        sortable:true
    },
    {
        name: 'Müşteri',
        selector: 'id_com_customer_name',
         left:true,
        sortable:true
    },
    {
        name: 'Telefon',
        selector: 'phone1',
         left:true,
        sortable:true
    },
    {
        name: 'Müşteri',
         left:true,
        sortable:true,
         cell:(row)=>  <a className="btn btn-primary btn-sm btn-block" type="button" href={'/serviceapp/add/'+row.id}>Seç</a>
    },

]
class ServiceAppAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled:true,
            car_data:[],
            schedule:[],
            id_com_service_app_subject:'',
            km:'',
            CarSearch:false,
            SearchResult:[],
            searchItem:''
        };
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.Search=this.Search.bind(this);

    }

    componentDidMount(){
        let id_com_cars = this.props.match.params.id_com_cars
            this.setState({id_com_cars:id_com_cars})
            let cars_url=this.props.api_url+'/selectRows/view_cars';

          if(id_com_cars!=='0'){
            fetch(cars_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{
                    'Content-Type':'application/x-www-form-urlencoded'
                },
                body:'id='+id_com_cars+'&token='+this.props.loginToken+'&type=user',
            }).then(res=>res.json())
              .then(result=>{
    
         console.log(result[0])
                  this.setState({car_data:result[0],car_data_loaded:true})
            })
          }  else { 

                this.setState({CarSearch:true})

          }
           

    }
    
    handleChange(event,selectlist,name) {
        if(selectlist) { 
            this.setState({ [`${name}`]:event.id})
        } else { 
            const value = event.target.value;
            const key = event.target.name;
            this.setState({ [`${key}`]:value})
        }
        // this.setState({disabled:false})
    }


    handleSubmit(event) {
        let d = new Date(); 
        let date = d.toISOString();
        let c_date  = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');

        let insert_body = 
        'token='+this.props.loginToken+
        '&c_date='+c_date+
        '&c_time='+c_time+
        '&id_com_user='+this.props.id_com_user+
        '&type=user&id_com_service_app_status=1';
    
        for (let [key] of Object.entries(this.state)) {
            if(key!=='disabled'&&key!=='schedule'&&key!=='car_data'&&key!=='car_data_loaded'&&key!=='customerSearch'&&key!=='CarSearch'&&key!=='SearchResult'&&key!=='searchItem'){ 
                insert_body +=`&${key}=`+ this.state[`${key}`]
            }
    }

    console.log(insert_body);
    let com_service_app_url=this.props.api_url+'/insert/com_service_app';
    fetch(com_service_app_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body:insert_body,
        })
        .catch(error=>console.log(error)).then(x=> {
            window.location.href='/Customer/List'});
    }
        
    AppTimer(date){
        let newdate = new Date(date)
        newdate.setDate(newdate.getDate())
        let app_date = newdate.toISOString().split('T')[0]
        this.setState({app_date:app_date})
        let schedule_url=this.props.api_url+'/appTimer/'+this.state.id_com_registered_user+'/'+app_date;
         
        fetch(schedule_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body: 'token='+this.props.loginToken+'&type=user',
        })
        .catch(error=>console.log(error)).then(res=>{
            if(res===undefined){ 
                        return []
            } else {  return res.json()}
        }
         ).then(result=>{
             if(result.length>0){
                this.setState({schedule:result});
             } else { 
                this.setState({schedule:result});

             } 
        })    
    }


    async Search(value) { 

        let match_str = new RegExp('('+value+')','i');

        let url = this.props.api_url+'/selectRows/view_cars';
        fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body: 'token='+this.props.loginToken+'&type=user',
        })
            .catch(error => console.log(error))
            .then(response => response.json() )
            .then(SearchResult=>
                {   
                    let filtered_data = SearchResult.filter(function(row){
                        if(value===''){ 
                            return row

                        }       {
                          return row.license_plate.match(match_str)
                        }
                    })
                    this.setState({SearchResult:filtered_data})
                }
            )
    }

    render() {
        return(
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">  {this.state.CarSearch?'Araç Arayınız':'Randevu Ekle'}</h3>
                                    </div>
                            {this.state.CarSearch?
                                                            
                                <div className="panel-body"> 

                                <div className="form-group">
                                    <input value={this.state.searchItem} className="form-control" type="text" placeholder="Plaka Giriniz..." onChange={(x)=> {this.setState({searchItem:x.target.value});this.Search(x.target.value)}} />
                                </div>

                                {
                                    this.state.searchItem!==''?
                                        this.state.SearchResult.length>0?
                                    <>
                                    <DataTable
                                                        columns={columns}
                                                        data={this.state.SearchResult}
                                                        fixedHeader
                                                        noHeader={true}
                                                        overflowY={false}
                                                        overflowYOffset={'100px'}
                                                        highlightOnHover
                                                        responsive
                                                        striped
                                                        dense
                                                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                                        pagination={true}
                                                        paginationPerPage={20}
                                                        defaultSortField={'license_plate'}
                                                        defaultSortAsc={false}
                                                        noDataComponent={()=><a className="btn btn-primary btn-sm btn-block" type="button" href={'/serviceapp/add/'}>Seç</a>}
                                                        noTableHead={true}
                                                    />
                                    </>
                                :

                                <>

                                <div className="col-md-12"
                                         dangerouslySetInnerHTML={{__html:  alertMessage('NoCarData')}}></div> 


                                </>
                                :<></>
                                
                                
                                }
                                </div>
                                    :<React.Fragment>

                                    <div className="panel-body"> 



                                    <table className="table table-bordered table-striped">
                           
                           <tbody>  
                           {this.state.car_data.id_com_customer_name != null?
                                   <tr>
                                       <th>Müşteri Adı</th>
                                       <td>{this.state.car_data.id_com_customer_name}</td>
                                   </tr>
                               :
                                   <tr>
                                       <th>Müşteri Adı</th>
                                       <td>-</td>
                                   </tr>
                               }
                               {this.state.car_data.phone1!= null?
                                   <tr>
                                       <th>Telefon - Email </th>
                                       <td>{this.state.car_data.phone1 +' - '+this.state.car_data.email }</td>
                                   </tr>
                               :
                                   <tr>
                                       <th>Telefon - Email</th>
                                       <td>-</td>
                                   </tr>
                               }  
                               {this.state.car_data.license_plate != null?
                                   <tr>
                                       <th>Araç Bilgisi</th>
                                       <td>{this.state.car_data.license_plate+' - '+this.state.car_data.id_com_brand_name+' / '+this.state.car_data.id_com_model_name+' '+this.state.car_data.spec+' '+this.state.car_data.id_com_fuel_name+' '+this.state.car_data.id_com_color_name+ ' / Model Yılı : '+this.state.car_data.model_year}</td>
                                   </tr>
                               :
                                   <tr>
                                       <th>Araç Bilgisi</th>
                                       <td>-</td>
                                   </tr>
                               }
                               {this.state.car_data.chasis != null?
                                   <tr>
                                       <th>Şase</th>
                                       <td>{this.state.car_data.chasis}</td>
                                   </tr>
                               :
                                   <tr>
                                       <th>Şase</th>
                                       <td>-</td>
                                   </tr>
                               }
                           </tbody>
                       </table>

                                    
                                    <SelectList table='com_service_app_subject' label={'Randevu Konusu'} name={'id_com_service_app_subject'} onChange={(x)=>this.handleChange(x )} />
                                    <div className="form-group">
                                        <label>Kilometre</label>
                                        <input type="number" className="form-control" name="km" onChange={(x) => this.handleChange(x)} placeholder="Kilometre Bilgisi Giriniz"  />
                                    </div>
                                    <SelectList table='view_user_list_type3' label={'Danışman Seçiniz'} name={'id_com_registered_user'} id_com_department={'2'}  id_com_brand={this.state.car_data.id_com_brand}   onChange={(x)=>this.handleChange(x)}   />
                             
                                  {this.state.id_com_registered_user?
                                    <div className="form-group">
                                                  <label>Randevu Tarihi</label>
                                            <DatePicker    onChange={(x)=>this.AppTimer(x)}  />
                                                  {/* <input type="date" className="form-control" value={this.state.invoice_date} onChange={(x)=>{this.setState({invoice_date: x.target.value});this.addDays(x.target.value,this.state.id_com_payment)}}/> */}
                                        </div>
                                   :<></>}

                                   {this.state.schedule.length>0?
                                    
                                        this.state.schedule.filter(row=> row.status==='0').length===0||this.state.schedule.length===0?
                                        <div className="col-md-12"
                                         dangerouslySetInnerHTML={{__html:  alertMessage('NoFreeTime')}}></div>
                                       : 

                                        <React.Fragment>
                                    <div className="form-group">
                                        <label>{'Randevu Saati'}</label>
                                        <select className="form-control" name={'id_com_service_app_timer'} onChange={(x)=>{this.handleChange(x);this.setState({disabled:false})}} data-title="Seçiniz" data-live-search="true">
                                        <option key={0} value={0}>{"Seçiniz" }</option>
                                            {this.state.schedule.map((row,index)=>
                                            { 
                                                if(row.status==='0'){ 
                                                   return ( <option key={index} value={row.id}  >{row.time+' / '+row.title} </option>)
                                                } else { return null  }
                                            })}
                                        </select>
                                    </div>

                                    <ul>
                                    {this.state.schedule.map((row,index)=>
                                            { 
                                                if(row.status==='1'){ 
                                                   return ( <li key={index} style={{color:'red'}} >{row.time+' / '+row.title} </li>)
                                                } else { return null  }
                                            })}
                                    </ul>
                                    
                                    </React.Fragment>
                                    :<></>
                                   }
                                   
                                    <div className="form-group" >
                                    <label>Müşteri Notları : </label>
                                    <textarea type="text" style={{height:200}} row='20' value={this.state.comment} className="form-control" name="comment" placeholder={'Müşterinin İletmek istediği notlar var ise yazınız.'} onChange={(x) => this.handleChange(x)} required/>
                                     </div>
                                  
                                   
                                    <div className="panel-footer"> 
                                    <a className="btn btn-danger" href="/serviceapp/List">İptal</a>

                                    <button className="btn btn-primary pull-right"
                                    disabled={this.state.disabled===true||this.state.km===''||this.state.id_com_service_app_subject===''?true:false}
                                    onClick={()=>this.handleSubmit()} 
                                    >Kaydet</button>

                                    </div>
                                   
                                    </div>
        </React.Fragment> }
                                </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(mapStateToProps)(ServiceAppAdd);


