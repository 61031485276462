import React from 'react';
import {connect} from 'react-redux';
import '../../../node_modules/react-widgets/dist/css/react-widgets.css'
import { DateTimePicker } from 'react-widgets'
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
Moment.locale('tr')
momentLocalizer()     

class DatePicker extends React.Component{

    constructor(props){
          super(props);
          this.state={
          }
    }
    

render(){

    return ( 
        this.props.TimePicker?
        <DateTimePicker 
        autoFocus={true}
        format='DD-MM-YYYY'
      //  parse={'MM-DD-YYYY'}
       // defaultValue={new Date()}
       placeholder={'Tarih Seçiniz'}
       disabled={this.props.disabled}
        time={true}
        date={false}
        onChange={this.props.onChange}
         value={this.props.value}

          />
        :
        <DateTimePicker 
      autoFocus={true}
      format='DD-MM-YYYY'
    //  parse={'MM-DD-YYYY'}
     // defaultValue={new Date()}
     placeholder={'Tarih Seçiniz'}
     disabled={this.props.disabled}
      time={false}
      onChange={this.props.onChange}
       value={this.props.value}

        />
    )
}




}

const mapStateToProps = (state) => {
    return {
        id_com_company: state.constants.id_com_company,
        user_type:state.constants.user_type,
        api_url:state.constants.api_url,
        loginToken:state.constants.loginToken
    }
}

export default connect(mapStateToProps)(DatePicker)