import React,{useState,useEffect} from 'react';
import { Input, Col, Row, Typography,Divider, message,Form ,Button,Drawer} from 'antd';
import {connect} from 'react-redux';
import mapStateToProps from '../../../redux/mapStateToProps'; 
import 'moment/locale/tr';
import moment from 'moment'; 
import { PlusCircleOutlined } from '@ant-design/icons';
import SelectList from '../../../components/utilities/SelectList';


const BankAccAdd = ({api_url,loginToken,id_com_user,visible,OnClose})=>{ 
const [formData] = Form.useForm();


const _onSubmit = async ()=>{
      const form_data = formData.getFieldsValue();

         let url = api_url+'/insert/com_bank_account';
 
        let body = 'token='+loginToken+'&type=user';

        for ( const [ key,value] of Object.entries(form_data)){
            if(key!=='btn') {
                body +=`&${key}=${value}`
                }
            
        }
         const body_config = { method: 'POST',   cahce: 'no-cache',  headers: {    'Content-Type': 'application/x-www-form-urlencoded'  },  body: body}    
          const result = await (await  fetch(url,body_config)).json() 
  
           if(result){
            message.success( 'Kayıt Edilmiştir.',1).then(OnClose)
         
            } else { 
                message.success( 'Bir Hata Oluştu...',1).then(OnClose)

           }

    formData.resetFields()
}   


return ( 
        <Drawer
        title='Banka Cari Ekle'
        visible={visible}
        onClose={OnClose}
        width='400px'
        style={{marginTop:20}}
         >
            <Form layout='vertical' form={formData} onFinish={_onSubmit}>
                <Form.Item name='id_com_bank' label='Banka Adı' rules={[{required:true,message:'Zorunlu Alan'}]}>
                    <SelectList table='com_bank' />
                </Form.Item>
                <Form.Item name='id_com_bank_account_type' label='Hesap Tipi' rules={[{required:true,message:'Zorunlu Alan'}]} >
                    <SelectList table='com_bank_account_type' />
                </Form.Item>
                <Form.Item name='id_com_currency' label='Döviz Tipi' rules={[{required:true,message:'Zorunlu Alan'}]} >
                    <SelectList table='com_currency' />
                </Form.Item>
                <Form.Item name='text' label='Cari isim' rules={[{required:true,message:'Zorunlu Alan'}]} >
                    <Input type='text' />
                </Form.Item>
                <Form.Item name='cari_kod' label='Cari Kodu' rules={[{required:true,message:'Zorunlu Alan'}]}>
                    <Input type='text' />
                </Form.Item>
                <Divider></Divider>
                <Form.Item name='btn'  >
                    <Row align='middle' justify='end' gutter={12}>
                        <Col>
                            <Button danger onClick={OnClose} htmlType='reset' >Vazgeç</Button>
                        </Col> 
                        <Col>
                            <Button type='primary' htmlType='submit' >Kaydet</Button>
                        </Col> 
                    </Row>

                </Form.Item>

            </Form>
        </Drawer>
    )
}

export default connect(mapStateToProps)(BankAccAdd);
