import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
import DatePicker from '../utilities/DatePicker';
import {alertMessage} from '../../assets.js';
import DataTable from 'react-data-table-component';

class ServiceAppDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_com_service_app: '',
            disabled: true,
            pageloader: false,
            app_data: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.Search=this.Search.bind(this);
    }

    handleChange(event, selectlist, name) {
        if (selectlist) {
            this.setState({[`${name}`]: event.id})
        } else {
            const value = event.target.value;
            const key = event.target.name;
            this.setState({[`${key}`]: value})
        }
        this.setState({disabled: false})
    }

    handleSubmit(event) {
        let d = new Date();
        let date = d.toISOString();
        let m_date = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');
        let update_body =
            'token=' + this.props.loginToken +
            '&id=' + this.state.id_com_service_app +
            '&m_date=' + m_date +
            '&m_time=' + m_time +
            '&m_id_com_user=' + this.props.id_com_user +
            '&type=user&id_com_service_app_status=' + this.state.id_com_service_app_status +
            '&km=' + this.state.km;
        let com_service_app_url = this.props.api_url + '/update/com_service_app';
        fetch(com_service_app_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: update_body,
        })
            .catch(error => console.log(error)).then(x => {
            window.location.reload()
        });
    }

    componentDidMount() {
        let id_com_service_app = this.props.match.params.id
        this.setState({id_com_service_app: id_com_service_app})
        let url = this.props.api_url + '/selectRow/view_service_app';
        fetch(url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'token=' + this.props.loginToken + '&id=' + id_com_service_app + '&type=user',
        })
            .catch(error => console.log(error)).then(res => res.json()).then(result => {
            this.setState({
                app_data: result,
                pageloader: true,
                id_com_service_app_status: result.id_com_service_app_status,
                km: result.km
            })
        })
    }

    render() {
        return (
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">Randevu No: {this.state.id_com_service_app}</h3>
                                    </div>
                                    <div className="panel-body">
                                        <div className="panel-heading">
                                            <h4 className="panel-title">Randevu Bilgileri </h4>
                                        </div>
                                        <table className="table table-bordered table-striped">
                                            <tbody>

                                            {this.state.app_data.app_date != null ?
                                                <tr>
                                                    <th>Randevu Tarih - Saati</th>
                                                    <td>{this.state.app_data.app_date + ' - ' + this.state.app_data.timer}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <th>Randevu Tarih - Saati</th>
                                                    <td>-</td>
                                                </tr>
                                            }
                                            {this.state.app_data.id_com_service_app_subject_name != null ?
                                                <tr>
                                                    <th>Randevu Konusu</th>
                                                    <td>{this.state.app_data.id_com_service_app_subject_name}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <th>Randevu Konusu</th>
                                                    <td>-</td>
                                                </tr>
                                            }
                                            {this.state.app_data.km != null ?
                                                <tr>
                                                    <th>Kilometre</th>
                                                    <td>{this.state.app_data.km}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <th>Kilometre</th>
                                                    <td>-</td>
                                                </tr>
                                            }

                                            {this.state.app_data.comment != null ?
                                                <tr>
                                                    <th>Müşteri Notu</th>
                                                    <td>{this.state.app_data.comment}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <th>Müşteri Notu</th>
                                                    <td>-</td>
                                                </tr>
                                            }
                                            {this.state.app_data.id_user_register_username != null ?
                                                <tr>
                                                    <th>Randevu Atanan / Tarih</th>
                                                    <td>{this.state.app_data.id_user_register_username + ' / ' + this.state.app_data.c_date + ' - ' + this.state.app_data.c_time}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <th>Randevu Atanan / Tarih</th>
                                                    <td>-</td>
                                                </tr>
                                            }
                                            </tbody>
                                        </table>
                                        <div className="panel-heading">
                                            <h4 className="panel-title">Araç Bilgileri </h4>
                                        </div>
                                        <table className="table table-bordered table-striped">
                                            <tbody>
                                                {this.state.app_data.id_com_customer_name != null ?
                                                    <tr>
                                                        <th>Müşteri Adı</th>
                                                        <td>{this.state.app_data.id_com_customer_name}</td>
                                                    </tr>
                                                    :
                                                    <tr>
                                                        <th>Müşteri Adı</th>
                                                        <td>-</td>
                                                    </tr>
                                                }
                                                {this.state.app_data.phone1 != null ?
                                                    <tr>
                                                        <th>Telefon - Email</th>
                                                        <td>{this.state.app_data.phone1 + ' - ' + this.state.app_data.email}</td>
                                                    </tr>
                                                    :
                                                    <tr>
                                                        <th>Telefon - Email</th>
                                                        <td>-</td>
                                                    </tr>
                                                }
                                                {this.state.app_data.license_plate != null ?
                                                    <tr>
                                                        <th>Araç Bilgisi</th>
                                                        <td>{this.state.app_data.license_plate + ' - ' + this.state.app_data.id_com_brand_name + ' / ' + this.state.app_data.id_com_model_name + ' ' + this.state.app_data.spec + ' ' + this.state.app_data.id_com_fuel_name + ' ' + this.state.app_data.id_com_gear_name + ' ' + this.state.app_data.id_com_color_name + ' / Model Yılı : ' + this.state.app_data.model_year}</td>
                                                    </tr>
                                                    :
                                                    <tr>
                                                        <th>Araç Bilgisi</th>
                                                        <td>-</td>
                                                    </tr>
                                                }
                                                {this.state.app_data.chasis != null ?
                                                    <tr>
                                                        <th>Şase</th>
                                                        <td>{this.state.app_data.chasis}</td>
                                                    </tr>
                                                    :
                                                    <tr>
                                                        <th>Şase</th>
                                                        <td>-</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="panel-body">
                                        <div className={"form-group"}>
                                            <label>Güncel Km</label>
                                            <input type={"text"} className={"form-control"}
                                                   name={"km"}
                                                   placeholder={"Güncel Km Bilgisi"}
                                                   value={this.state.km || ''}
                                                   onChange={(x) => this.handleChange(x)}
                                            />
                                        </div>
                                        {parseInt(this.state.app_data.id_com_service_app_status) < 3 ?
                                            <SelectList table='com_service_app_status' label={'Randevu Durumu'}
                                                        name={'id_com_service_app_status'}
                                                        value={this.state.id_com_service_app_status}
                                                        onChange={(x) => this.handleChange(x)}/>
                                            :
                                            <table className="table table-bordered table-striped">
                                                <tbody>
                                                <tr>
                                                    <th>Randevu Durumu</th>
                                                    <td>{this.state.app_data.id_com_service_app_status_name}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                    <div className="panel-footer">
                                        <a className="btn btn-danger" href="/serviceapp/List">İptal</a>
                                        <button className="btn btn-primary pull-right" disabled={this.state.disabled === true ? true : false} onClick={() => this.handleSubmit()}>Kaydet</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(mapStateToProps)(ServiceAppDetails);