import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
import {alertMessage} from '../../assets.js';
import DataTable from 'react-data-table-component';

class InterviewHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked:false,
            interview_data:[],
            interview_loaded:false,
            columns:[
                {
                    name: 'Görüşme No',
                    selector: 'interview_number',
                    left:true,
                    width: '100px'
                },
                {
                    name: 'Tarih',
                    selector: 'c_date',
                    cell : row =>(
                        row.c_date.split('-')[2] + '.' +
                        row.c_date.split('-')[1] + '.' +
                        row.c_date.split('-')[0]
                    ),
                    left:true,
                    width: '120px'
                },
                {
                    name: 'Görüşme Tipi',
                    selector: 'id_com_interview_type_name',
                    left:true
                },
                {
                    name: 'Görüşme Konusu',
                    selector: 'id_com_interview_subject_name',
                    left:true
                },
                {
                    name: 'Temsilci',
                    selector: 'id_com_user_name',
                    left:true,
                    width: '120px'
                },
                
                {
                    name: 'Durum',
                    selector: 'status_name',
                    left:true,
                    width: '120px'
                },
                {
                    name: 'Görüşme Durumu',
                    selector: 'id_com_interview_status_name',
                    wrap:true,
                    left:true
                },
                {
                    name: 'Kontrol',
                    cell : row =>(
                        <a className="btn btn-primary btn-sm btn-block" type="button" href={'/interview/Detail/'+row.id}   target="_blank" >Seç</a>
                    ),
                    left:true,
                    width: '120px'
                }]
        }
    }

    componentDidMount(){

        
        
        let interview_url=this.props.api_url+'/selectRows/view_interview';
        fetch(interview_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body:'id_com_customer='+this.props.id_com_customer+'&token='+this.props.loginToken+'&type=user',
        }).then(res=>res.json())
          .then(result=>{
              
              this.setState({interview_data:result.reverse(),interview_loaded:true})
        })

    }

    render(){
        return( 
            <div className="panel-footer text-center">

       <React.Fragment>
        <a className={!this.state.clicked?"btn btn-primary btn-lg btn-block":"btn btn-success btn-lg btn-block"}
            data-toggle="collapse" 
            href={`#${this.props.id}`}
            role="button" 
            aria-expanded="false" 
            aria-controls={this.props.id} 
            // style={{width: '100%'}}
            onClick={()=>this.setState({clicked:!this.state.clicked})}>
 <i className={!this.state.clicked?"fas fa-plus-circle pull-left":"fas fa-minus-circle pull-left"}  > </i>   {this.props.title}  </a>
    <div className="collapse" id={this.props.id}>
        <div className="panel">

 
        <div className="  panel-body">
                <div className="row"> 
                <DataTable
                    columns={this.state.columns}
                    data={this.state.interview_data}
                    fixedHeader
                    noHeader={true}
                    overflowY={false}
                    overflowYOffset={'100px'}
                    highlightOnHover
                    responsive
                    striped
                    dense
                    noDataComponent={'Geçmiş Görüşme Kaydı Bulunmamaktadır.'}
                    paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                    progressPending={!this.state.interview_loaded}
                    pagination={true}
                    paginationPerPage={20}
                    defaultSortField={'c_date'}
                    defaultSortAsc={false}
                        
                />
                </div>
        </div>
        

        </div>
    </div>       
    </React.Fragment>


            </div>
        )
    }
}


export default connect(mapStateToProps)(InterviewHistory);