
import {
    _Log_Status_Change,
    _Add_Login_Token,
    _Add_Username,
    _Add_Id_Com_Company,
    _Add_Id_Com_User,
    _Add_Password,
    _Add_Email,
    _Add_Id_Com_User_Type,
    _Add_Id_Com_Brand,
    _Add_Id_Com_Department
} from '../redux/actions';

const mapDispatchToProps = dispatch => {
    return {
        _Add_Login_Token: (token) => dispatch(_Add_Login_Token(token)),
        _Log_Status_Change: () => dispatch(_Log_Status_Change()),
        _Add_Id_Com_User: (id_com_user) => dispatch(_Add_Id_Com_User(id_com_user)),
        _Add_Id_Com_Company: (id_com_company) => dispatch(_Add_Id_Com_Company(id_com_company)),
        _Add_Email: (email) => dispatch(_Add_Email(email)),
        _Add_Password: (password) => dispatch(_Add_Password(password)),
        _Add_Username: (username) => dispatch(_Add_Username(username)),
        _Add_Id_Com_User_Type: (id_com_user_type) => dispatch(_Add_Id_Com_User_Type(id_com_user_type)),
        _Add_Id_Com_Department: (id_com_department) => dispatch(_Add_Id_Com_Department(id_com_department)),
        _Add_Id_Com_Brand: (id_com_brand) => dispatch(_Add_Id_Com_Brand(id_com_brand)),

    }
}

export default mapDispatchToProps;