import React,{useEffect,useState} from 'react';
 import {Row,Col,Input, Typography, PageHeader, Divider,Result,Button, Descriptions, message, Table} from 'antd';
import { connect } from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import ExcelUpload from '../../components/utilities/excelupload';
import ExportFromJson from '../../components/utilities/jsontoexport';
import moment from 'moment' 

const {Text,Paragraph } = Typography

const PolUpload = ({api_url,loginToken})=>{

const [Subjects,setSubjects] = useState([])
const [Users,setUsers] = useState([])
const [Loading,setLoading] = useState(true)
const [Finished,setFinished]= useState(false);

const User_columns =[
    {
        title:'id',
        dataIndex:'id'
    },
    {
        title:'Kullanıcı',
        dataIndex:'username',
        align:'center'
    }
]

const Subject_columns =[
    {
        title:'id',
        dataIndex:'id'
    },
    {
        title:'Açıklama',
        dataIndex:'title',
        align:'center'
    }
]


const excel_columns =[
    {
        name:'id_com_interview_subject',
        selector: 'id_com_interview_subject',
        translate:'Görüşme Konusu | Not: Hücret Tipi Metin Olmalıdır.',
        sample:'2'
    },
    {
        name:'c_date',
        selector: 'c_date',
        translate:'Tarih | Not: Hücret Tipi Metin Olmalıdır.',
        sample:'2021-01-01'
    },
    {
        name:'chasis',
        selector: 'chasis',
        translate:'Poliçe No | Not: Hücret Tipi Metin Olmalıdır.',
        sample:'12412412122'
    },
    {
        name:'content',
        selector: 'content',
        translate:'Görüşme Notu | Not: Hücret Tipi Metin Olmalıdır.',
        sample:'Otomatik Aktarim : Lütfen zamanında kaydı kapatınız. 44371967180 _ABİDİN ÖZCAN 16AAY182-HYUNDAI  EC435054 _ 814.55 _ SOMPO JAPAN SİGORTA'
    },
    {
        name:'company_name',
        selector: 'company_name',
        translate:'Firma Adı | Not: Hücret Tipi Metin Olmalıdır.',
        sample:'Şentürk Otomotiv A.Ş'
    },
    {
        name:'name',
        selector: 'name',
        translate:'İsim | Not: Hücret Tipi Metin Olmalıdır.',
        sample:'Kazım'
    },
    {
        name:'surname',
        selector: 'surname',
        translate:'Soyisim | Not: Hücret Tipi Metin Olmalıdır.',
        sample:'Polat'
    }, 
    {
        name:'email',
        selector: 'email',
        translate:'Email | Not: Hücret Tipi Metin Olmalıdır.',        
        sample:'ornekmail@hotmail.com'
    }, 
    {
        name:'phone1',
        selector: 'phone1',
        translate:'Telefon | Not: Hücret Tipi Metin Olmalıdır.',        
        sample:'05348774854'
    }, 
    {
        name:'id_com_user',
        selector: 'id_com_user',
        translate:'Yönlendirilecek Personel | Not: Hücret Tipi Metin Olmalıdır.',        
        sample:'1'
    }, 
];


useEffect(() => {
    const OnLoad =()=>{
        _GetData();
    }
    return OnLoad();
}, [ ]);


const _GetData = async ()=>{
 
    const url1 = api_url+'/selectrows/view_user';
    const body1 = 'token='+loginToken+'&type=user&id_com_department=5'; 
    const config1= {  method:'POST',  cahce: 'no-cache',  headers:{  'Content-Type':'application/x-www-form-urlencoded'   },  body:body1 };

    const url2 = api_url+'/selectrows/com_interview_subject';
    const body2 = 'token='+loginToken+'&type=user&id_com_interview_type=3'; 
    const config2 = {  method:'POST',  cahce: 'no-cache',  headers:{  'Content-Type':'application/x-www-form-urlencoded'   },  body:body2 };

    try{
        const user = await(await(fetch(url1,config1))).json();
        const subject = await(await(fetch(url2,config2))).json();

        setSubjects(subject);
        setUsers(user)
        setLoading(false)
      }
      catch(err){    
        console.log(err);
        message.error('Bir Hata Oluştu. Tekrar Güncelleyiniz.',2);
        setLoading(false)

      }
}

    return ( 
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <Row>
                                        <Col span={24}>
                                            <PageHeader title='Sigorta |' subTitle='Yenileme Poliçe Yükleme' 
                                       
                                            />
                                        </Col>
                                    </Row>
                                    <Divider></Divider>
                                    <Row justify='start'>
                                        <Col span={24}  >
                                            <Result
                                            status={Finished?"success":'warning'}
                                            title="Toplu Görüşme Notu Yükleme Ekranı - Sigorta Yenileme"
                                            subTitle="Otomatik kayıt açmak için örnekteki excel dosyasını doldurarak yükleyiniz."
                                            extra={[  <ExcelUpload  Finished={Finished} onFinished={()=>setFinished(true)}/>  ]}
                                            />
                                        </Col>
                                    </Row>
                                    <Divider></Divider>
                                    <Row justify='start' style={{padding:20}} gutter={30}>
                                        <Col xl={12} lg={12} md={24} sm={24} xs={24}  >
                                            <Descriptions column={1} bordered size='small' title='Excel Sütun Açıklamaları '  
                                            extra={[
                                                <ExportFromJson data={excel_columns} fileName={`ornek_aktarim_dosyasi_${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}`} exportType={'xls'}/>
                                            ]}> 
                                                {excel_columns.map((row,idx)=>
                                                    <Descriptions.Item key={idx} label={row.name}  >
                                                    <Paragraph>   {`${row.translate} `}</Paragraph>
                                                    <Paragraph type='danger' >   {` Örnek Data: ${row.sample} `}</Paragraph>
 
                                                    </Descriptions.Item>
                                                )}
                                            </Descriptions>

                                        </Col>
                                        <Col xl={12} lg={12} md={24} sm={24} xs={24}  >
                                             <Row justify='start' style={{padding:10}} gutter={30}>
                                                <Col span={24} >
                                                    <Divider>Kullanıcılar</Divider>
                                                    <Table dataSource={Users}  columns={User_columns} size='small' pagination={false} loading={Loading} rowKey={row=>row.id}/>
                                                </Col>
                                                <Col span={24} >
                                                    <Divider>Görüşme Konuları</Divider>
                                                    <Table dataSource={Subjects}  columns={Subject_columns} size='small' pagination={false} loading={Loading}  owKey={row=>row.id}/>
                                                </Col>
                                             </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    )
}
export default connect(mapStateToProps)(PolUpload)

