import {combineReducers} from 'redux';
import menuToggle from './menuToggle';
import constants from './constants';
import logStatus from './logged';

const AllReducers =   combineReducers({
      menuToggle:menuToggle,
      constants:constants,
      logStatus:logStatus,
});
export default AllReducers;

