import React ,{useState}from 'react';
import { Input ,Button, Popover, Typography, Row,Tag,  Col, Table, Divider,Form, Radio,Modal, PageHeader, DatePicker,Upload,message, } from 'antd';
import { DeleteOutlined,CloseOutlined,CheckOutlined,QuestionCircleOutlined,PlusOutlined,EditOutlined,UploadOutlined,DownloadOutlined ,FileExcelOutlined} from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import SelectList from '../utilities/SelectList';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';



const  formatter = new Intl.NumberFormat('tr-TR', {
    style: 'decimal',
    currency: 'TRY',
    minimumFractionDigits: 2
  })

  const  formatter_dec = new Intl.NumberFormat('tr-TR', {
    style: 'decimal',
    currency: 'TRY',
    minimumFractionDigits: 4
  })

const { Text,Paragraph } = Typography;

const TransactionList  = ({inter_data,miniscreen,transactions,id_com_car,id_com_user,token,hideAdd,api_url,inv_price})=>{
     const [addForm] = Form.useForm();
    const [editForm] = Form.useForm();

    const [ AddModal,setAddModal] =useState(false);

    const [EditModal,setEditModal] =useState(false);
    const [Loading,setLoading] = useState(false);
    const [DocumentLoaded,setDocumentLoaded] = useState(false);


const Columns =[

    {
        align:'center',
        width:'4rem',
        render: (row,record)=>record.proforma_line==='0' && <Button type='default' size='small' shape='round' onClick={()=>{setEditModal(true);_NormalizeData(record)}} icon={<EditOutlined/>}></Button>
    },
    {
        title:'Ek Dosya',

        align:'center',
        width:'4rem',
        render: (row,record)=>  record.document_id!=='0' &&  <Button type='primary' size='small' shape='round' target='_blank' href={`${process.env.API_URL}download/${record.document_id}?token=${token}`} loading={Loading} icon={<DownloadOutlined  />}></Button>
    },
    {
        title:'Belge Tarihi',
        dataIndex:'doc_date',
        width:'8rem',

        align:'center',
        render: (row,record)=>moment(record.doc_date).format('DD.MM.YYYY')
    },
    {
        title:'Belge Tipi',
        dataIndex:'id_com_transaction_type_name',
        width:'11rem',
        align:'center',
        render : (row,record) => <Text type={record.id_com_interview==='0'?'danger':record.id_com_hospital==='0'?'':'secondary'}>{ `${record.id_com_hospital==='* '?'':'** ' }${record.id_com_transaction_type_name}` }</Text>
    },
    {
        title:'Belge No',
        dataIndex:'doc_number',
        align:'center',
        width:'8rem',

    },
    {
        title:'Açıklama',
        dataIndex:'comment',
        width:'12rem',

        align:'left',
    },
    {
        title:'Alacak',
        align:'center',
        width:'8rem',
        render: (row,record)=>`${formatter.format(record.debit)} TL`
    },
    {
        title:'Borç',
        align:'center',
        width:'8rem',
        render: (row,record)=>`${formatter.format(record.credit)} TL`
    },


    {
        title:'Bakiye',
        align:'right',
        width:'8rem',
        render: (row,record)=><Text type={parseFloat(record.sum_amount)>0?'success':'danger'}  >{`${formatter.format(record.sum_amount)} TL`}</Text>
    },
]



const _NormalizeData = async (values)=>{
    const initial_values = {};
    for ( const [ key,value] of Object.entries(values)){
          switch(key){

             case 'doc_date':
                Object.assign(initial_values,{[`${key}`]:moment(value)})
                break;


             default:
                Object.assign(initial_values,{[`${key}`]:value});
                break;
           }
    }
    editForm.setFieldsValue(initial_values)
 }







const _AddTransaction = async () =>{


    setLoading(true)

    const add_form = addForm.getFieldsValue();
    const d = new Date();
    const date=  moment(d).format('YYYY-MM-DD');
    const time = d.toLocaleTimeString('tr-TR');

    const transaction_url=api_url+'/insert/com_transaction';

     const insert_transaction = {
        id_com_car:id_com_car,
        c_time: time,
        c_date: date,
        c_id_com_user:id_com_user,
        id_com_transaction_type:add_form.id_com_transaction_type,
        doc_date:moment(add_form.doc_date).format('YYYY-MM-DD'),
        comment:add_form.comment,
        amount:add_form.amount,
        sign:add_form.sign,
        doc_number:add_form.doc_number,
     };
     let line_body = 'type=user&token='+token;

     for ( const [ key,value] of Object.entries(insert_transaction)){
         line_body +=`&${key}=${value}`
    }


    const transaction_config = { method: 'POST',   cahce: 'no-cache',  headers: {    'Content-Type': 'application/x-www-form-urlencoded'  },  body: line_body}
    const transaction_result = await (await  fetch(transaction_url,transaction_config)).json()

        if(transaction_result.status){

                message.success({ content: 'Başarıyla Oluşturuldu' ,duration:1 }).then(async ()=>{

                        if(add_form.formfiles!==undefined ) {
                                const formupload = new FormData();
                                formupload.append('token', token);

                                add_form.formfiles.fileList.map(file=>
                                    formupload.append('files[]', file.originFileObj)
                                )
                                formupload.append('show', 0 );
                                formupload.append('resize', '1200px' );
                                formupload.append('component', 'com_transaction'  );
                                formupload.append('item_id', transaction_result.id  );
                                formupload.append('text', 'Cari Belge'  );
                                formupload.append('id_com_user', id_com_user  );
                                formupload.append('id_com_document_type', '14'  );
                                var headers = {
                                'Content-Type': 'multipart/form-data'
                            }
                            try{
                                const docresponse = await  axios.post('https://senturkotomotiv.com.tr/api/api/upload',formupload, {headers: headers})
                                if(docresponse.status===200) {
                                         message.success({ content: 'Başarıyla Oluşturuldu' ,duration:0.5 }).then(()=>{window.location.reload() },1)


                                    } else {
                                        message.error({ content:  'Hata Oluştu.'   ,duration:0.5 }).then(()=>  window.location.reload() ,1);


                                    }
                                }
                            catch(e){
                                    message.error({ content:  'Hata Oluştu.'    ,duration:0.5 }).then(()=>  window.location.reload() ,1);

                                }
                    }
                     message.success({ content: 'Başarıyla Oluşturuldu',duration:0.5 }).then(()=>{window.location.reload() },1)


            })
        } else {
            message.error({ content: 'Hata Oluştu.'   ,duration:0.5 }).then(()=>  window.location.reload() ,1);



        }


}


const _UpdateTransaction = async  () =>{

    setLoading(true)

    const edit_form = editForm.getFieldsValue();
    const d = new Date();
    const date=  moment(d).format('YYYY-MM-DD');
    const time = d.toLocaleTimeString('tr-TR');

    const transaction_url=api_url+'/update/com_transaction';

     const update_json = {
        id:edit_form.id,
        m_time: time,
        m_date: date,
        m_id_com_user:id_com_user,
        id_com_transaction_type:edit_form.id_com_transaction_type,
        doc_date:moment(edit_form.doc_date).format('YYYY-MM-DD'),
        comment:edit_form.comment,
        amount:edit_form.amount,
        sign:edit_form.sign,
        doc_number:edit_form.doc_number,
     };
     let update_line = 'token='+token+'&id='+edit_form.id+'&updateData='+JSON.stringify(update_json);

     let update_body = 'type=user&token='+token;

     for ( const [ key,value] of Object.entries(update_json)){
        update_body +=`&${key}=${value}`
    }


     const transaction_config = { method: 'POST',   cahce: 'no-cache',  headers: {    'Content-Type': 'application/x-www-form-urlencoded'  },  body: update_body}

    try{
        const result = await (await  fetch(transaction_url,transaction_config)).json();

        if(result.status) {
            setEditModal(false)
            message.success({ content: 'Başarıyla Kaydetti.',duration:1 }).then(()=>{window.location.reload() },1)

                setLoading(false)
             } else {
                message.error({ content: 'Hata Oluştu'   ,duration:1 }).then(()=>  window.location.reload() ,1);

            }
        }
    catch(e){
            message.error({ content: 'Hata Oluştu'  ,duration:1 }).then(()=>  window.location.reload() ,1);
            setEditModal(false)
            setLoading(false)
        }


}

const _DeleteTransaction = async () =>{
    setLoading(true)

    const edit_form = editForm.getFieldsValue();
    const d = new Date();
    const date=  moment(d).format('YYYY-MM-DD');
    const time = d.toLocaleTimeString('tr-TR');

    const transaction_url=process.env.API_URL+'/update/com_transaction';

     const update_json = {
        hidden:1,
        m_time: time,
        m_date: date,
        m_id_com_user:id_com_user,

     };

     let update_line = 'token='+token+'&id='+edit_form.id+'&updateData='+JSON.stringify(update_json);
      const transaction_config = { method: 'POST',   cahce: 'no-cache',  headers: {    'Content-Type': 'application/x-www-form-urlencoded'  },  body: update_line}


    try{
        const result = await (await  fetch(transaction_url,transaction_config)).json();
         if(result.status) {
                 setEditModal(false)
                 message.success({ content: 'Başarıyla Oluşturuldu',duration:1 }).then(()=>window.location.reload() ,1)

                setLoading(false)
             } else {
                message.error({ content: 'Hata Oluşturuldu'  ,duration:1 }).then(()=>  window.location.reload() ,1);

            }
        }
    catch(e){
            message.error({ content: 'Hata Oluşturuldu'   ,duration:1 }).then(()=>  window.location.reload() ,1);

            setLoading(false)
        }


}


const _Selector  = async  (e)=>{
const key =e[0].name[0];
const value =e[0].value;

 switch(key){

    default:
    return 1
}




}

    const total_sum = parseFloat(transactions.reduce((a,b)=>a+parseFloat(b.local_credit),0))-parseFloat(transactions.reduce((a,b)=>a+parseFloat(b.local_debit),0))-parseFloat(inv_price);
 return (
    <Row>
        <Col span={24} >
                <PageHeader
                title={<Text type={total_sum>0?'success':'danger'}  >{`Toplam Kar/Zarar : ${formatter.format(total_sum)} TL`}</Text>}
                subTitle={`| Alış Fiyatı : ${formatter.format(inv_price)} TL`}
                    extra={[
                        !hideAdd&& <Button key='documentadd'  size='small' type='default' shape='round'  onClick={()=>setAddModal(true)}   ><PlusOutlined/></Button>,
                    ]}
                />
        </Col>
        <Col span={24} >
            <Table scroll={miniscreen?{ y: 300, x: '100vw' }:{}} dataSource={transactions} columns={Columns} bordered  size='small'

             />
        </Col>
        <Modal
            visible={AddModal}
            width='50%'
            title={'Veri Ekle'}
            onCancel={()=>setAddModal(false)}
            footer={null}
        >
        <Form form={addForm} labelCol={{span:6}} labelAlign='left' onFinish={_AddTransaction} onFieldsChange={_Selector}>
            <Row>
                <Col span={24}>
                    <Form.Item name='id' hidden >
                        <Input type='text'  />
                    </Form.Item>
                    <Form.Item name='doc_date' label={'Belge Tarihi'}>
                        <DatePicker  style={{width:'100%'} } locale={locale}  format='DD.MM.YYYY' />
                    </Form.Item>
                    <Form.Item name='doc_number' label={'Belge No'} >
                        <Input type='text'  />
                    </Form.Item>
                    <Form.Item name='id_com_transaction_type' label={'İşlem Tipi'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                        <SelectList table='view_transaction_type'  />
                    </Form.Item>
                    <Form.Item name='sign' label={'Hareket Tipi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                        <Radio.Group  >
                            <Radio value="0">{'Masraf'}</Radio>
                            <Radio value="1">{'Satış Fiyatı'}</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item name='amount' label={'Tutar'} >
                        <Input type='number' min={0} step={0.01} style={{textAlign:'right'}}  />
                    </Form.Item>
                    <Form.Item name='comment' label={'Açıklama'}>
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Form.Item name='formfiles'  label={'Foto Yükle'}  >
                        <Upload
                        onChange={(e)=>setDocumentLoaded(DocumentLoaded===false?true:false)}
                        onRemove={(e)=>setDocumentLoaded(false)}
                        >
                          <Row justify='center' gutter={12}>
                            <Col   >
                              <Button icon={<UploadOutlined />} disabled={DocumentLoaded}  loading={Loading}>{'Yükle'}</Button>
                            </Col>
                          </Row>
                        </Upload>
                      </Form.Item>
                </Col>
                <Divider></Divider>
                <Col span={24}>
                    <Row justify='end' gutter={12}>
                          <Col>
                                <Button type='default'     icon={<CloseOutlined />} loading={Loading} onClick={()=>{addForm.resetFields();setAddModal(false)}}>{'Vazgeç'}</Button>
                            </Col>
                            <Col>
                                <Button type='primary' htmlType='submit' icon={<CheckOutlined />} loading={Loading}>{'Kaydet'}</Button>
                            </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
        </Modal>
        <Modal
            visible={EditModal}
            width='50%'
            title={'Veri Duzenle'}
            onCancel={()=>setEditModal(false)}
            footer={null}
        >
        <Form form={editForm} labelCol={{span:6}} labelAlign='left' onFinish={_UpdateTransaction}>
            <Row>
                <Col span={24}>
                    <Form.Item name='id' hidden >
                        <Input type='text'  />
                    </Form.Item>
                    <Form.Item name='doc_date' label={'Belge Tarihi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                        <DatePicker  style={{width:'100%'} }  locale={locale} format='DD.MM.YYYY' />
                    </Form.Item>
                    <Form.Item name='doc_number' label={'Belge No'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                        <Input type='text'  />
                    </Form.Item>
                    <Form.Item name='id_com_transaction_type' label={'İşlem Tipi'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                        <SelectList table='view_transaction_type'  />
                    </Form.Item>
                    <Form.Item name='sign' label={'Hareket Tipi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                        <Radio.Group  >
                            <Radio value="0">Gelen </Radio>
                            <Radio value="1">Giden</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name='amount' label={'Tutar'} >
                        <Input type='number' min={0} step={0.01} style={{textAlign:'right'}}  />
                    </Form.Item>
                    <Form.Item name='comment' label={'Açıklama'}>
                        <Input.TextArea rows={4} />
                    </Form.Item>

                </Col>
                <Divider></Divider>
                <Col span={24}>
                    <Row justify='end' gutter={12}>
                          <Col>
                                <Button type='default'     icon={<CloseOutlined />} loading={Loading} onClick={()=>{editForm.resetFields();setEditModal(false)}}>Vazgeç</Button>
                            </Col>
                            <Col>
                                <Button type='primary' danger   icon={<DeleteOutlined />} loading={Loading}  onClick={_DeleteTransaction}>Sil</Button>
                            </Col>
                            <Col>
                                <Button type='primary' htmlType='submit' icon={<CheckOutlined />} loading={Loading}>Kaydet</Button>
                            </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
        </Modal>

    </Row>

)

}

export default TransactionList;
