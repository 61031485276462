import React from 'react';
import {connect} from 'react-redux';
import DataTable from 'react-data-table-component';
import mapStateToProps from '../../redux/mapStateToProps';

class Level2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: false,
            data: [],
            columns: [
                {
                    name: 'No:',
                    selector: 'id',
                    left: true,
                    width: '100px'
                },
                {
                    name: 'Açıklama',
                    selector: 'text',
                    left: true
                }
            ],
            AddItem: false
        }
        this._Update = this._Update.bind(this);
        this._AddItem = this._AddItem.bind(this);

    }
    componentDidMount() {
        this._Update();
    }
    _Update() {
        let acc_url = this.props.api_url + '/selectRows/com_product_sub_class1';
        fetch(acc_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_product_type=' + this.props.data.id_com_product_type + '&token=' + this.props.loginToken + '&type=user&id_com_product_class=' + this.props.data.id
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                this.setState({data: result, pageloader: true})
            })
    }
    async _AddItem() {
        let insert =
            'text=' + this.state.text +
            '&id_com_product_type=' + this.props.data.id_com_product_type +
            '&id_com_product_class=' + this.props.data.id +
            '&token=' + this.props.loginToken +
            '&type=user';
        let insert_url = this.props.api_url + '/insert/com_product_sub_class1'
        await fetch(insert_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: insert,
        }).then(res => console.log('New ProductLine Added!'))
        this.setState({addItem: false, text: '', data: []})
        this._Update();
    }
    render() {
        return (
            <div style={{background: 'lightgreen'}}>
                <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    highlightOnHover
                    noHeader={true}
                    overflowY={false}
                    overflowYOffset={'100px'}
                    responsive
                    // dense
                    subHeader={true}
                    subHeaderAlign={'right'}
                    subHeaderComponent={
                        <div className="form-group margin-top-30">
                            <button className="btn btn-default" onClick={() => this.setState({addItem: true})}>Ekle</button>
                        </div>
                    }
                />
                {this.state.addItem ?
                    <div className="col-md-12 margin-top-30">
                        <div className="form-group">
                            <input className="form-control" disabled value={this.state.data.length + 1}/>
                        </div>
                        <div className="form-group">
                            <input className="form-control" placeholder={'Açıklama'} value={this.state.text} onChange={(x) => this.setState({text: x.target.value.toUpperCase()})}/>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-danger" onClick={() => this.setState({addItem: false, text: ''})}>İptal</button> <button className="btn btn-primary" disabled={this.state.addItem === true && this.state.text === '' ? true : false} onClick={() => this._AddItem()}>Kaydet</button>
                        </div>
                    </div>
                :
                    <></>
                }
            </div>
        )
    }
}
class Level1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: false,
            data: [],
            columns: [
                {
                    name: 'No:',
                    selector: 'id',
                    left: true
                },
                {
                    name: 'Açıklama',
                    selector: 'text',
                    left: true
                }
            ],
            AddItem: false
        }
        this._Update = this._Update.bind(this);
        this._AddItem = this._AddItem.bind(this);
    }
    componentDidMount() {
        this._Update();
    }
    _Update() {
        let acc_url = this.props.api_url + '/selectRows/com_product_class';
        fetch(acc_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_product_type=' + this.props.data.id + '&token=' + this.props.loginToken + '&type=user'
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                this.setState({data: result, pageloader: true})
            })
    }
    async _AddItem() {
        let insert =
            'text=' + this.state.text +
            '&id_com_product_type=' + this.props.data.id +
            '&token=' + this.props.loginToken +
            '&type=user';
        let insert_url = this.props.api_url + '/insert/com_product_class'
        await fetch(insert_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: insert,
        }).then(res => console.log('New ProductLine Added!'))
        this.setState({addItem: false, text: '', data: []})
        this._Update();
    }
    render() {
        return (
            <div style={{background: 'lightblue'}}>
                <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    highlightOnHover
                    noHeader={true}
                    overflowY={false}
                    overflowYOffset={'100px'}
                    responsive
                    // dense
                    expandableRows={true}
                    expandableRowsComponent={
                        <Level2 api_url={this.props.api_url} loginToken={this.props.loginToken} id_com_product_type={this.props.id_com_product_type}/>
                    }
                    subHeader={true}
                    subHeaderAlign={'right'}
                    subHeaderComponent={
                        <div className="form-group margin-top-30">
                            <button className="btn btn-default" onClick={() => this.setState({addItem: true})}>Ekle</button>
                        </div>
                    }
                />
                {this.state.addItem ?
                    <div className="col-md-12 margin-top-30">
                        <div className="form-group">
                            <input className="form-control" disabled value={this.state.data.length + 1}/>
                        </div>
                        <div className="form-group">
                            <input className="form-control" placeholder={'Açıklama'} value={this.state.text} onChange={(x) => this.setState({text: x.target.value.toUpperCase()})}/>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-danger" onClick={() => this.setState({addItem: false, text: ''})}>İptal</button> <button className="btn btn-primary" disabled={this.state.addItem === true && this.state.text === '' ? true : false} onClick={() => this._AddItem()}>Kaydet</button>
                        </div>
                    </div>
                :
                    <></>
                }
            </div>
        )
    }
}
class ProductGroupList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageloader: false,
            columns: [
                {
                    name: 'No:',
                    selector: 'id',
                    left: true,
                    width: '100px'
                },
                {
                    name: 'Açıklama',
                    selector: 'text',
                    left: true
                }
            ]
        }
        this._Update = this._Update.bind(this)
    }
    componentDidMount() {
        this._Update();
    }
    _Update() {
        let product_url = this.props.api_url + '/selectRows/com_product_type';
        fetch(product_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: '&token=' + this.props.loginToken + '&type=user'
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                this.setState({data: result, pageloader: true})
            })
    }
    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="page-title">Malzeme Grupları</h3>
                                </div>
                                <div className="col-md-12">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <div className="panel-title">Malzeme Grupları</div>
                                        </div>
                                        <div className="panel-body">
                                            <DataTable
                                                columns={this.state.columns}
                                                noHeader={true}
                                                overflowY={false}
                                                overflowYOffset={'100px'}
                                                data={this.state.data}
                                                fixedHeader
                                                highlightOnHover
                                                responsive
                                                striped
                                                // dense
                                                paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                                pagination={true}
                                                paginationPerPage={20}
                                                defaultSortField={'id'}
                                                expandableRows={true}
                                                expandableRowsComponent={
                                                    <Level1 api_url={this.props.api_url} loginToken={this.props.loginToken} id_com_company={this.props.id_com_company}/>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(ProductGroupList);