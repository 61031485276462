import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component';
import SelectList from '../utilities/SelectList';
import Axios from "axios";
import moment from "moment";
import { PhoneOutlined} from '@ant-design/icons';
import { Button, Col, Row, Descriptions,Tabs ,Popover} from 'antd';
import { EditOutlined,CheckOutlined,LeftOutlined,SettingOutlined } from '@ant-design/icons';

import PhoneDrawer from './PhoneDrawer';
import Wrapper from '../utilities/Wrapper';

const {TabPane} = Tabs
 const Interview_Columns = [
    {
        name: 'Kontrol',
        cell: row => (
            <a className="btn btn-primary btn-sm btn-block " type="button" href={'/interview/Detail/' + row.id}  target="_blank">Seç</a>
        ),
        center: true,
        width: '100px'
    },
    {
        name: 'Görüşme No',
        selector: 'interview_number',
        center: true,
        width: '110px'
    },
    {
        name: 'Tarih',
        selector: 'c_date',
        cell: row => (
            row.c_date.split('-')[2] + '.' +
            row.c_date.split('-')[1] + '.' +
            row.c_date.split('-')[0]
        ),
        center: true,
        width: '120px'
    },
    {
        name: 'Görüşme Tipi',
        selector: 'id_com_interview_type_name',
        wrap: true,
        left: true
    },
    {
        name: 'Görüşme Konusu',
        selector: 'id_com_interview_subject_name',
        wrap:true,
        left: true
    },
    {
        name: 'Temsilci',
        selector: 'id_com_user_name',
        wrap:true,
        left: true, 
    },

    {
        name: 'Durum',
        selector: 'status_name',
        left: true,
        width: '120px'
    },
    {
        name: 'Görüşme Durumu',
        selector: 'id_com_interview_status_name',
        wrap: true,
        left: true
    } ]
const car_data_columns = [

    {
        name: 'Kontrol',
        cell: row => (
            <a className="btn btn-primary btn-sm btn-block" type="button" href={'/cars/Detail/' + row.id}
               target="_blank">Seç</a>
        ),
        center: true,
        width: '100px'
    },
    {
        name: 'Plaka',
        selector: 'license_plate',
        center: true,
        width: '150px'
    },
    {
        name: 'Marka',
        selector: 'id_com_brand_name',
        center: true,
    },
    {
        name: 'Model',
        selector: 'id_com_model_name',
        center: true,
    },
    {
        name: 'Spek',
        selector: 'spec',
        center: true,
    },
    {
        name: 'Model Yıl',
        selector: 'model_year',
        center: true,
    },
    {
        name: 'Renk',
        selector: 'id_com_color_name',
        center: true,
    }, 
]
class CustomerDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_com_customer:'',
            OpenDrawer:false,
            customer_data: [],
            customerDetailLoaded: false,
            interview_data: [],
            interview_loaded: false,
            car_data: [],
            car_data_loaded: false,
            interview_forward:false,
            smsid: null,
            smscode: null,
            kvkkConfResult:null,
            kvkkConfStatus: false,
            content:'Otomatik olarak tarafınıza atama yapılmıştır. Lütfen en kısa sürede müşteriye dönüş sağlayınız.'
        }
        this.Update = this.Update.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this._Forward=this._Forward.bind(this);
    }
    componentDidMount() {
        this.Update();
    }
    Update() {
        const id_com_customer = this.props.match.params.id ; 
         this.setState({id_com_customer:id_com_customer})

        let customer_url = this.props.api_url + '/selectRows/view_customer';
        fetch(customer_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id=' + id_com_customer+ '&token=' + this.props.loginToken + '&type=user',
        }).then(res => res.json())
            .then(result => {

                this.setState({customer_data: result[0], customerDetailLoaded: true})
            })

        let interview_url = this.props.api_url + '/selectRows/view_interview';
        fetch(interview_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_customer=' + id_com_customer + '&token=' + this.props.loginToken + '&type=user',
        }).then(res => res.json())
            .then(result => {

                this.setState({interview_data: result.reverse(), interview_loaded: true})
            })

        let car_data_url = this.props.api_url + '/selectRows/view_cars';
        fetch(car_data_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_customer=' + id_com_customer+ '&token=' + this.props.loginToken + '&type=user',
        }).then(res => res.json())
            .then(result => {

                this.setState({car_data: result.reverse(), car_data_loaded: true})
        })

    }
    handleChange(event,selectlist,name) {
        if(selectlist) { 
            this.setState({ [`${name}`]:event.id})
        } else { 
            const value = event.target.value;
            const key = event.target.name;
            const type = event.target.type;
                if (type ==='checkbox') { 
                    this.setState({ [`${key}`]:event.target.checked?'1':'0'})
                    console.log(event.target.checked)
                }    else { 
                      this.setState({ [`${key}`]:value})
                }
        }
    }
    _Forward(){
        let id_com_customer = this.props.match.params.id; 
        let d = new Date();
        let date = d.toISOString();
        let c_date = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let interview_number = Math.floor(Math.random() * 10000000) + 1000000;
         let insert_interview_body =
            'token=' + this.props.loginToken +
            '&interview_number=' + interview_number +
            '&c_date=' + c_date +
            '&c_time=' + c_time +
            '&id_com_user=' + this.state.r_id_com_user +
            '&id_com_meet=' + this.state.id_com_meet +
            '&id_com_customer=' + id_com_customer +
            '&id_com_interview_subject=' + this.state.id_com_interview_subject +
            '&id_com_interview_type=' + this.state.id_com_interview_type +
            '&id_com_activity=' + this.state.id_com_activity +
            '&id_com_brand=' + this.state.id_com_brand +
            '&id_com_model=' + this.state.id_com_model +
            '&type=user&status=0&id_com_interview_status=1&forwarded=1';
            // console.log(insert_interview_body);

        let com_interview_url = this.props.api_url + '/insert/com_interview';
        fetch(com_interview_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: insert_interview_body,
        })
            .catch(error => console.log(error)).then(res => res.json()).then(response => {
            let id_com_interview = response.id;
            let insert_lines =
                'token=' + this.props.loginToken +
                '&id_com_interview=' + id_com_interview +
                '&c_date=' + c_date +
                '&c_time=' + c_time +
                '&id_com_meet=' + this.state.id_com_meet +
                '&id_com_user=' + this.props.id_com_user +
                '&content=' + this.state.content+
                '&type=user&testdrive=0&id_com_expertise=0';
            // console.log(insert_lines);

            let com_interview_lines_url = this.props.api_url + '/insert/com_interview_lines';
            fetch(com_interview_lines_url, {
                method: 'POST',
                cahce: 'no-cache',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: insert_lines,
            }).catch(e => console.log(e))
                .then(res => window.location.href = '/')
        })

    }
    kvkkSmsSend = async () => {
        try {
            let postData = {
                // token: this.props.loginToken,
                phone: this.state.customer_data.phone1,
                id_com_customer: this.props.match.params.id,
                id_com_user: this.props.id_com_user
            }
  
            const response = await Axios.post(this.props.api_url + '/kvkkSms', postData)
 
            this.setState({
                smsid: response.data.smsid
            })
        } catch (error) {
            console.error(error);
        }
    }
    kvkkConfirmation = async () => {
        try {
            let postData = {
                // token: this.props.loginToken,
                smscode: this.state.smscode
            }
            const response = await Axios.post(this.props.api_url + '/kvkkConfirmation', postData)
            // console.log(response.data);
            this.setState({
                kvkkConfResult: response.data.message,
                kvkkConfStatus: response.data.status,
                kvkk_confirmation: response.data.kvkk_confirmation
            })
        } catch (error) {
            console.error(error);
        }
    }


    render() {


    const RowStyle = {
        padding:10
    };

    const ColStyle = {
        margin:5
    };


    const content= () =>{
        return ( 
            <div>
                <div>
                    <a className="btn btn-success btn-sm btn-block" type="button"   href={'/cars/add/' + this.props.match.params.id}  style={{margin:5}}>Araç Ekle</a>
                    <a className="btn btn-success btn-sm btn-block" type="button" href={'/expertise/add/' + this.props.match.params.id}  style={{margin:5}}>Ekspertiz Oluştur</a>
                </div>
                <div>
                    <a className="btn btn-success btn-sm btn-block" type="button"  href={'/interview/add/'+this.state.id_com_customer} style={{margin:5}}>Görüşme Ekle</a>
                    {/* <button className="btn btn-success btn-sm btn-block " type="button" onClick={()=>this.setState({interview_forward:true})} style={{margin:5}}>Görüşme Yönlendir</button> */}
                    <button className="btn btn-primary btn-sm btn-block " type="button"  onClick={()=>this.setState({OpenDrawer:true})} style={{margin:5}}>Santral Kayıtları <PhoneOutlined/></button>
                </div>
            </div>
        )
    }

    const ActionButton = () => { 
        
        return ( 
            <Popover  placement="bottomLeft" title={'İşlemler'} content={content} trigger="click">
               <Button key='settings' type='default' icon={<SettingOutlined/>} >İşlem</Button>
            </Popover>
            
        )
    }

        return (
            <React.Fragment>
                <Wrapper title={'Müşteri Kartı'} extra={[ 
                    <Button key='back'  type='default' danger onClick={()=>window.location.href='/Customer/List'} icon={<LeftOutlined/>}>Geri </Button>,
                    !this.state.interview_forward&&<Button key='edit'  type='primary'   onClick={() => window.location.href = '/Customer/Edit/' + this.props.match.params.id} icon={<EditOutlined/>}>Düzenle </Button>,
                     this.state.interview_forward&&<Button key='forward'  type='primary'  onClick={() => this._Forward()} icon={<CheckOutlined/>}>Kaydet </Button>,
                      ActionButton()
                ]} >
                    <Row gutter={12} style={RowStyle}>
                        <Col span={24} style={ColStyle}>
                            <Tabs defaultActiveKey='1' type='card'> 
                                <TabPane key='1'  tab='Müşteri Bilgileri'>
                                    <Row gutter={12} style={RowStyle}>
                                        <Col span={24} style={ColStyle}>
                                            <Descriptions bordered column={4} size='small' layout='vertical' labelStyle={{fontWeight:'bolder'}}>
                                                <Descriptions.Item  span={1} label={'Müşteri Tipi'}>
                                                        {this.state.customer_data.id_com_customer_type_name}
                                                </Descriptions.Item>
                                                <Descriptions.Item  span={3} label={'Adı Soyadı'}>
                                                        {this.state.customer_data.id_com_customer_type === '1' ? this.state.customer_data.username : this.state.customer_data.company_name + ' / Yetkili : ' + this.state.customer_data.username}
                                                </Descriptions.Item>
                                                <Descriptions.Item  span={1} label={'Vergi No'}>
                                                        {this.state.customer_data.taxno}
                                                </Descriptions.Item>
                                                <Descriptions.Item  span={1} label={'E-Posta'}>
                                                        {this.state.customer_data.email}
                                                </Descriptions.Item>
                                                <Descriptions.Item  span={2} label={'Telefon'}>
                                                        <Row justify='space-between'>
                                                            <Col>
                                                                {this.state.customer_data.phone1} 
                                                            </Col>
                                                            <Col>
                                                              
                                                            </Col>
                                                        </Row>
                                                </Descriptions.Item>
                                                <Descriptions.Item  span={4} label={'Adres'}>
                                                    {this.state.customer_data.adress + ' ' + this.state.customer_data.id_com_town_name + ' / ' + this.state.customer_data.id_com_city_name}
                                                </Descriptions.Item>
                                                <Descriptions.Item  span={1} label={'E-Posta'}>
                                                        {this.state.customer_data.email}
                                                </Descriptions.Item>

                                                <Descriptions.Item  span={1} label={'Eğitimi'}>
                                                        {this.state.customer_data.id_com_education_name}
                                                </Descriptions.Item>
                                                <Descriptions.Item  span={1} label={'Meslek'}>
                                                        {this.state.customer_data.id_com_sector_name}
                                                </Descriptions.Item>
                                                <Descriptions.Item  span={1} label={'Doğum Tarihi'}>
                                                        {moment(this.state.customer_data.birthday).format('DD.MM.YYYY')}
                                                </Descriptions.Item>
                                                <Descriptions.Item  span={2} label={'Temas Türü'}>
                                                    {this.state.customer_data.id_com_meet_name}
                                                </Descriptions.Item>
                                                <Descriptions.Item  span={2} label={this.state.customer_data.kvkk_status == 1 ?'KVKK Onay Tarihi':'KVKK Onayı'}>
                                                    {this.state.customer_data.kvkk_status == 1 ? moment(this.state.customer_data.kvkk_comfirmation).format('DD.MM.YYYY HH:mm:ss'):
                                                    this.state.kvkkConfStatus === false?
                                                                            <button
                                                                            type="button"
                                                                            className="btn btn-danger"
                                                                            style={{margin: 5}}
                                                                            data-toggle="modal"
                                                                            data-target="#kvkkModal"
                                                                            onClick={() => this.kvkkSmsSend()}
                                                                            >KVKK SMS Gönder</button>
                                                                        :
                                                                            <div className={"text-success"}>KVKK Onayı Tamamlandı</div>
                                                    }
                                                </Descriptions.Item>
                                                <Descriptions.Item  span={4} label={'Müşteri Notları / Açıklama'}>
                                                        {this.state.customer_data.comment}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane key='2'  tab={"Müşteri Araçları  : " + this.state.car_data.length}>
                                <Row gutter={12} style={RowStyle}>
                                        <Col span={24} style={ColStyle}>
                                            <DataTable
                                                    noHeader
                                                    columns={car_data_columns}
                                                    data={this.state.car_data}
                                                    fixedHeader
                                                    highlightOnHover
                                                    responsive
                                                    defaultSortField="id"
                                                    striped
                                                    dense
                                                    progressPending={!this.state.car_data_loaded}
                                                    pagination={true}
                                                    paginationPerPage={5}
                                                    defaultSortAsc={true}
                                             
                                                />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane key='3'  tab={"Görüşme Notları  : " + this.state.interview_data.length}>
                                    <Row gutter={12} style={RowStyle}>
                                        <Col span={24} style={ColStyle}>
                                            <DataTable
                                                    noHeader
                                                    columns={Interview_Columns}
                                                    data={this.state.interview_data}
                                                    fixedHeader
                                                    highlightOnHover
                                                    responsive
                                                    defaultSortField="id"
                                                    striped
                                                    dense
                                                    progressPending={!this.state.interview_loaded}
                                                    pagination={true}
                                                    paginationPerPage={10}
                                                    defaultSortAsc={true}
                                               
                                                />
                                         </Col>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </Wrapper>
 

                <PhoneDrawer visible={this.state.OpenDrawer} OnClose={()=>this.setState({OpenDrawer:false})} row_data={this.state.customer_data}/>
            <div className="modal fade" id="kvkkModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title" id="myModalLabel">KVKK SMS Doğrulama</h4>
                        </div>
                        <div className="modal-body">
                            {this.state.smsid != null ?
                                <React.Fragment>
                                    <div className={"form-grup"}>
                                        <label>SMS Kodu</label>
                                        <input
                                            type="text"
                                            className={"form-control"}
                                            name="smscode"
                                            placeholder={"Sms Doğrulama Kodu"}
                                            required={true}
                                            onChange={(x) => this.handleChange(x)}
                                        />
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className={"alert alert-info text-center"}>
                                        <i className={"fas fa-spin fa-spinner fa-2x"}></i>
                                        <p className={"lead"}>SMS Gönderiliyor. Lütfen bekleyiniz...</p>
                                    </div>
                                </React.Fragment>
                            }
                            {this.state.kvkkConfResult ?
                                <div className={"alert alert-success text-center"}>
                                    <i className={"fas fa-check fa-2x"}></i>
                                    <p className={"lead"}>{this.state.kvkkConfResult}</p>
                                    <br/>
                                    <button type={"button"} className={"btn btn-success"}
                                            data-dismiss="modal">Kapat
                                    </button>
                                </div>
                                : null
                            }
                        </div>
                        {this.state.smscode ?
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal">İptal
                                </button>
                                <button
                                    type={"button"}
                                    className="btn btn-primary"
                                    onClick={() => this.kvkkConfirmation()}
                                >Doğrula</button>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
            </React.Fragment> 
        )
    }
}
export default connect(mapStateToProps)(CustomerDetail);