const mapStateToProps = (state ) => {
    return {
        id_com_company: state.constants.id_com_company,
        id_com_user: state.constants.id_com_user,
        api_url: state.constants.api_url,
        media_url:state.constants.media_url,
        id_com_user_type:state.constants.id_com_user_type,
        id_com_brand: state.constants.id_com_brand,
        id_com_department: state.constants.id_com_department,
        loginToken:state.constants.loginToken,
        username:state.constants.username,
        email:state.constants.email,
        password:state.constants.password,
        log_status:state.logStatus.log_status,
    }
}
export default mapStateToProps;