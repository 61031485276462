import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import {alertMessage} from '../../assets.js';
import DatePicker from '../utilities/DatePicker';


class AlertAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            saved:false
        }
this.AppTimer=this.AppTimer.bind(this);
this.Submit=this.Submit.bind(this);
    }

    AppTimer(date){
        let newdate = new Date(date)
        newdate.setDate(newdate.getDate())
        let alert_date = newdate.toISOString().split('T')[0]
        this.setState({alert_date:alert_date})

    }

    Submit(){
        let d = new Date();  
        let date = d.toISOString();
        let c_date  = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let id_com_user = this.props.id_com_user;

        let insert_body =  'token='+this.props.loginToken+
        '&id_com_interview='+this.props.id_com_interview+
        '&comment='+this.state.comment+
        '&alert_date='+this.state.alert_date+
        '&c_date='+c_date+
        '&c_time='+c_time+
        '&id_com_user='+id_com_user+
        '&type=user&status=0'

        let alert_url = this.props.api_url+'/insert/com_alert';

        fetch(alert_url, {
            method: 'POST',
            cache: 'no-cache',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body:insert_body,
        })
            .catch(error => console.log(error)).then(x=>{this.setState({saved:true})})


    }


        render(){
            return(
                <>
                {this.state.saved?
                    
                <div className="col-md-12"
                dangerouslySetInnerHTML={{__html: alertMessage('alertConfirm')}}></div>
                :
                <>
                       <div className="form-group" >
                    <label>Hatırlatma Notu : </label>
                    <textarea type="text" style={{height:100}} row='10' value={this.state.comment} className="form-control"   placeholder={'Hatırlatma notunuz var ise yazınız.'} onChange={(x) => this.setState({comment:x.target.value})} required/>
                </div>
                <div className="form-group" >
                    <label>Hatırlatma Tarihi</label>
                    <DatePicker    onChange={(x)=>this.AppTimer(x)}  />
                </div>
         
                <button className="btn btn-success pull-left"
                                  //  disabled={this.state.disabled===true||this.state.km===''||this.state.id_com_service_app_subject===''?true:false}
                onClick={()=>this.Submit()} 
                >Hatırlatma Kaydet</button>
                </>

             
                }
                </>

            )
        }
    }

    export default connect(mapStateToProps)(AlertAdd);
