import React,{useState,useEffect} from 'react';
import {  Col, Row, Table ,Modal,PageHeader,Button,Input} from 'antd'; 
import 'moment/locale/tr';
import moment from 'moment'; 
import ExportFromJson from '../../../components/utilities/jsontoexport';



 const  formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 2
  })
  const  kur_formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 4
  })
  const  formatter_eur = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  })

  const  formatter_usd = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

const BankAccCard = ({visible,OnClose,data,row_data,rates})=>{ 

const [TableData,setTableData] =useState([]);
const [SearchItem,setSearchItem] =useState('');
const [FilteredData,SetFilteredData]=useState([]);

    useEffect(() => {
        const OnLoad =()=>{
            _GetData();
            setSearchItem('')
         }       
        return OnLoad();
    }, [visible])

const _GetData = ()=>{
const filtered = data.filter(row=>row.ACCOUNTCODE===row_data.cari_kod)
const table_data = [];

filtered.map(line=>{
 
    //  const daily_rate = rates.filter(rate=>rate.id_com_currency===row_data.id_com_currency&&moment(rate.curency_date).format('YYYY-MM-DD')===moment(line.DATE_).format('YYYY-MM-DD'))[0]
    
    table_data.push({
        id:line.LOGICALREF,
        cari_kod:row_data.cari_kod,
        date:line.DATE_,
        text:line.LINEEXP,
        SIGN:line.SIGN,
        id_com_currency:row_data.id_com_currency,
        id_com_bank_name:row_data.id_com_bank_name,
        id_com_currency_name:row_data.id_com_currency_name,
        AMOUNT:   line.AMOUNT ,
        TR_AMOUNT:   line.TR_AMOUNT, // /(line.TRRATE==='1.0'?line.SIGN==='1'?daily_rate.purchase:daily_rate.sale:line.TRRATE),
        TRRATE : line.TRRATE //==='1.0'?line.SIGN==='1'?daily_rate.purchase:daily_rate.sale:line.TRRATE,
 
    })
})

setTableData(table_data)

}
const Columns=[
 
    {
        title:'Tarih',
        dataIndex:'date',
        align:'left',
        render : (row,record)=>moment(record.date).format('DD.MM.YYYY')
    },
    {
        title:'Belge No',
        dataIndex:'id',
        align:'center'
    },
    {
        title:'Belge No',
        dataIndex:'SIGN',
        align:'center'
    },
    {
        title:'Açıklama',
        dataIndex:'text',
        align:'center'
    },
    {
        title:'Döviz Tutar',
        dataIndex:'AMOUNT',
        align:'right',
        render : (row,record)=>row_data.id_com_currency_name==='TL'?formatter.format(0):row_data.id_com_currency_name==='USD'?formatter_usd.format(record.AMOUNT):formatter_eur.format(record.AMOUNT)
    },
    {
        title:'Kur',
        dataIndex:'TRRATE',
        align:'right',
        render : (row,record)=>kur_formatter.format(record.TRRATE)
    },

    {
        title:'Tutar',
        dataIndex:'TR_AMOUNT',
        align:'right',
        render : (row,record)=>formatter.format(record.BAKIYE)

    },
    
]

const excel_columns =[
 
    {
        name:'Tarih',
        selector:'date',
    },
    {
        name:'Belge No',
        selector:'id',
    },
    {
        name:'Açıklama',
        selector:'text',
    },
   
    {
        name:'Döviz Tutar',
        selector:'AMOUNT',
    },
    {
        name:'Kur',
        selector:'TRRATE',
    },
    {
        name:'BAKIYE',
        selector:'TR_AMOUNT',
    },

    
]


const _Filter =(e)=>{
    setSearchItem(e)
    let match_str = new RegExp('('+e+')','i');
    let filteredData = TableData.filter((row)=>{ 
        return row.text.match(match_str)||row.date.match(match_str)||row.doc_no.match(match_str) 
    })
     SetFilteredData(filteredData)
}



const _Sort = (a, b) => {
    var nameA = a.date  
    var nameB = b.date   
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
     return 0;
  }

    return ( 
        <Modal
        visible={visible}
        onCancel={OnClose}
        width={'80%'}
        title={<PageHeader title={row_data.cari_kod} 
            extra={[  
                <ExportFromJson  key='excel' data={SearchItem===''?TableData:FilteredData} fileName={`${row_data.cari_kod}_${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}`} exportType={'xls'}/>

            ]}
        />}
        footer={
            <Button danger type='primary' onClick={OnClose}>Kapat</Button>
        }
        >

            <Row>
                <Col span={24}>
                    <Input.Search onChange={(e)=>_Filter(e.target.value)} placeholder='Arama Yapınız.'/>
                </Col>
            </Row>

            <Row>
                <Col span={24} style={{padding:20}}>
                    <Table dataSource={SearchItem===''?TableData.sort(_Sort):FilteredData.sort(_Sort)} columns={Columns} size='small' bordered/>
                </Col>
            </Row>
        </Modal>
    )
}


export default BankAccCard;
