
import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import * as CurrencyFormat from 'react-currency-format';



class AccordionSwap extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            id_com_orderform:'',
            clicked:false,
            add_btn:false,
            invItems:[],
            swap_cars:[],
            swap_data:[],
            swap_cars_list:[]
        };

    this._includeItem=this._includeItem.bind(this);
    this._excludeItem=this._excludeItem.bind(this);
    this._Submit=this._Submit.bind(this);
    }



static getDerivedStateFromProps(nextProps, prevState)
{                     
      return{
        swap_data: nextProps.swap_data,
      }
 }


 componentDidUpdate(prevProps, prevState) {

    if (this.props.swap_data !== prevProps.swap_data||this.props.id_com_interview !== prevProps.id_com_interview) {
      
        this.setState({id_com_orderform:this.props.id_com_orderform,swap_data:this.props.swap_data,id_com_interview:this.props.id_com_interview})
        let url = this.props.api_url+'/selectRows/view_ordeform_expertise_list';
        fetch(url, {
        method: 'POST',
         cache: 'no-cache',
        headers:  { 
        'Content-Type':  'application/x-www-form-urlencoded'
                },
        body:'token='+this.props.loginToken+'&id_com_interview='+this.props.id_com_interview+'&type=user',
        })
        .then(response => response.json() )
        .then(result=> { 
            let  difference =result.filter(row => !this.state.swap_data.find(row2 => row.id === row2.id ))
            this.setState({swap_cars:difference,swap_cars_list:difference})
        })

    } 

}

    componentDidMount(){
        this.setState({id_com_orderform:this.props.id_com_orderform,swap_data:this.props.swap_data})
    }

    _excludeItem(index) { 
        let invItems = this.state.invItems;
        invItems.splice(index,1);
        let swap_cars =  this.state.swap_cars;
        let  difference1 =swap_cars.filter(row => !this.state.invItems.find(row2 => row.id === row2.id ))
        this.setState({invItems:invItems,swap_cars_list:difference1})
      }


      _includeItem(id,index) { 
        let invItems = this.state.invItems;
        let product_url = this.props.api_url+'/selectRows/view_ordeform_expertise_list';
        fetch(product_url,{
          method:'POST',
          cahce: 'no-cache',
          headers:{ 
            'Content-Type':'application/x-www-form-urlencoded'
          },
          body: 'token='+this.props.loginToken+'&type=user&id='+id,
      }).then(res=>res.json())
      .then(result=>{
       invItems.push(result[0]);
        let swap_cars =  this.state.swap_cars
        let  difference1 =swap_cars.filter(row => !this.state.invItems.find(row2 => row.id === row2.id ))
        this.setState({invItems:invItems,swap_cars_list:difference1})
      })
     }

_Submit(){
    let invItems = this.state.invItems;
    let expertise_url = this.props.api_url+'/update/com_expertise';

    try{ 
    invItems.map((row,index)=>{
       let update_line =  
            'id='+row.id_com_expertise+
            '&id_com_orderform='+this.state.id_com_orderform+
            '&token='+this.props.loginToken+
            '&type=user';
        fetch(expertise_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
            body: update_line,
        }).catch(e=>console.log(e)).then(res=>window.location.reload())
    })
} 
catch(error){console.log(error)}

}
    render() { 
        return ( 
    <React.Fragment>
        <a className={!this.state.clicked?"btn btn-primary btn-lg btn-block":"btn btn-success btn-lg btn-block"}
            data-toggle="collapse" 
            href={`#${this.props.id}`}
            role="button" 
            aria-expanded="false" 
            aria-controls={this.props.id} 
            // style={{width: '100%'}}
            onClick={()=>this.setState({clicked:!this.state.clicked})}>
 <i className={!this.state.clicked?"fas fa-plus-circle pull-left":"fas fa-minus-circle pull-left"}  > </i>   {this.props.title} : :   <CurrencyFormat value={Math.round(parseFloat(this.props.summary)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/> </a>
    <div className="collapse" id={this.props.id}>
        <div className="panel">
        <div className="  panel-heading">
        <div className="row">            
        <div className="col-md-6 text-left">
                <div className="panel-title">{this.props.title}</div>
            </div>
        </div>
        <div className="row"> 
       
{!this.state.swap_cars.length>0?
        <div className="panel-heading ">
        <div className="panel-title text-center">Görüşme notlarına konu olan ekspertiz araç kaydı bulunmamaıştır!</div>
        </div>
:
        <table className="table table-bordered table-striped">
            <thead>
                <tr>
                    <th>Plaka</th>
                    <th>Takas Araç</th>
                    <th>Model Yılı</th>
                    <th>Km</th>
                    <th width='150'>Takas Fiyatı</th>
                    <th>Kontrol</th>
                </tr>
            </thead>
            <tbody>
                   {this.state.swap_cars_list.map((row,index)=>{   
            return  (
                    <tr key={index}>
                    <td>{row.license_plate}</td>
                    <td>{row.id_com_expertise_name}</td>
                    <td>{row.model_year}</td>
                    <td>{row.km}</td>
                    <td>{row.price}</td>
                    <td>{row.status==='1'?true:row.price==='0'?<a type='button' disabled={true} className='btn btn-danger btn-xs'>Fiyat Yok</a>:<a type='button' className='btn btn-success btn-xs' onClick={()=>this._includeItem(row.id,index)}>Seç</a>}</td>
                </tr>
                )})}

            </tbody>
            </table>  
            } 
        </div>
        </div>
        <div className="  panel-body">
        {this.state.invItems.length>0?
                <div className="row"> 
                <table className="table table-bordered table-striped">
            <thead>
                <tr>
                    <th>Plaka</th>
                    <th>Takas Araç</th>
                    <th>Model Yılı</th>
                    <th>Km</th>
                    <th width='150'>Takas Fiyatı</th>
                    <th>Kontrol</th>
                </tr>
            </thead>
            <tbody>
            {this.state.invItems.map((row,index)=>{   
            return  (
                    <tr key={index}>
                    <td>{row.license_plate}</td>
                    <td>{row.id_com_expertise_name}</td>
                    <td>{row.model_year}</td>
                    <td>{row.km}</td>
                    <td>{row.price}</td>
                    <td><a disabled={row.status==='1'?true:false} role='button' className='btn btn-danger btn-xs' onClick={()=>this._excludeItem(index)}>Cikar</a></td>
                </tr>
                )})}
            </tbody>
            </table>  
                </div>
                :<></>
                }
                <div className="  panel-footer">
        <button className="btn btn-primary pull-right" 
                                // disabled={this.state.id_com_sale_type===''||this.state.id_com_customer==='0'}
                                  onClick={()=>this._Submit()}
                                //   onClick={()=>console.log(this.state.invItems)}
                                    >Kaydet</button>
        </div>
        </div>
        

        </div>
    </div>       
    </React.Fragment>

)
}
}

export default connect(mapStateToProps)(AccordionSwap);