

import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component'; 
import * as CurrencyFormat from 'react-currency-format';
import moment from 'moment'
import SelectList from '../utilities/SelectList';
import Chart from "react-apexcharts";

class ReportCrm extends React.Component {


    constructor(props){
        super(props);
        this.state={
            pageloader:false,
            poll_data1:[],
            poll_data2:[],
            poll_data3:[],
            interview_7_sub19_sum_data:[],
            interview_7_sub18_sum_data:[],
            data_sub19:[],
            data_sub18:[],
            poll_columns1:[
                {
                name: 'Soru',
                selector: 'question',
                left:true,
                wrap:true,
                width:'180px',
                },
                {
                name: 'Adet',
                selector: 'count',
                right:true,
                  },
                {
                name: 'Ortalama/Evet?',
                 right:true,
                cell:(row)=>
                        row.id_com_poll_questions==='49'?'% '+row.next_service:row.average
                  },
            ],
            poll_columns2:[
                {
                    name: 'Soru',
                    selector: 'question',
                    left:true,
                    wrap:true,
                    width:'180px',
                    },
                    {
                    name: 'Adet',
                    selector: 'count',
                    right:true,
                      },
                    {
                    name: 'Evet?',
                     right:true,
                    cell:(row)=>  '% '+row.next_service 
                      },
            ],
            datatype7:[],
            datatype7_columns:[
                {
                name: 'Miy',
                selector: 'id_com_user_name',
                left:true,
                wrap:true,
                width:'150px',
                },
                {
                name: 'Arama Sonucu',
                selector: 'id_com_interview_status_name',
                left:true,
                wrap:true,
                width:'200px',

                },
                {
                name: 'Adet',
                selector: 'adet',
                left:true,
                wrap:true,
                },
                {
                name: 'Toplam Adet',
                selector: 'toplam_adet',
                left:true,
                wrap:true,
                },
  
                {
                name: 'Oran',
                selector: 'oran',
                left:true,
                wrap:true,
                cell:(row)=>
                             <CurrencyFormat value={parseFloat(row.oran)} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={' % '}/>
                },
            ],
            bar_options :{
                chart: {
                     type: 'bar',
                    events: {
                      click: function(event, chartContext, config) {
                        if( (config.dataPointIndex+1)===0){ 
                          this.setState({current_month:moment().subtract(-1, 'months').month()})
                        } else  { 
                          this.setState({current_month:config.dataPointIndex+1})
                        }
                            // console.log(config.dataPointIndex+1); 
                      }.bind(this),
                    //   dataPointSelection: function(event, chartContext, config){

                    //     console.log(event);
                    // }
                    },
                },
                plotOptions: {
                  bar: {
                    columnWidth:'50%',
                    endingShape: 'rounded',  

                    dataLabels: {
                      position: 'top', // top, center, bottom
                    },  
                    stroke: {
                      width: 2
                    },
                  }
                },
                xaxis: {
                  tickPlacement: 'on',
         
                    categories: [
                      'OCA', 'ŞUB', 'MAR', 'NIS', 'MAY', 'HAZ', 'TEM', 'AGU', 
                        'EYL', 'EKI', 'KAS','ARA'],
                    position: 'top',
                    axisBorder: {
                      show: false
                    },
                    axisTicks: {
                      show: false
                    },
                    crosshairs: {
                      fill: {
                        type: 'gradient',
                        gradient: {
                          colorFrom: '#D8E3F0',
                          colorTo: '#BED1E6',
                          stops: [0, 100],
                          opacityFrom: 0.4,
                          opacityTo: 0.5,
                        }
                      }
                    },

                },
                // title: {
                //     text: 'Arama Adetleri',
                //     align: 'center',
                //     style: {
                //       color: '#444'
                //     }
                            
                //   },
                  dataLabels: { 
                    enabled: false,
                  }, 
                 
                },
                monthly_data :[  ]
        }
        this.handleChange=this.handleChange.bind(this)
    }

    

    handleChange(event,selectlist,name) {
        if(selectlist) { 
             this.setState({ [`${name}`]:event.target.value})
        } 
        else { 

         if(event.target.type==='checkbox'){ 
               event.target.checked?this.setState({[`${event.target.name}`]:'1'}):this.setState({[`${event.target.name}`]:'0'})
                        } else {
            const value = event.target.value;
            const key = event.target.name;
            this.setState({ [`${key}`]:value})
         } 
        }
         
       
         
    }

   async  componentDidMount(){
    // let current_month = moment().subtract(-1, 'months').month();
    // let current_year= moment().subtract(-1, 'months').year();
   var d = new Date();
   var current_month = d.getMonth()+1;
   var current_year = d.getFullYear()
  let interview_7_url=this.props.api_url+'/selectRows/view_report_interview_type7';

  let interview_7_sub_18_url=this.props.api_url+'/selectRows/view_report_interview_type7_sub18';
  let interview_7_sub_19_url=this.props.api_url+'/selectRows/view_report_interview_type7_sub19';

     const data = await( await   fetch(interview_7_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
              body:'token='+this.props.loginToken+'&type=user',
            }).catch(error=>console.log(error))).json();

    const data_sub18 = await( await   fetch(interview_7_sub_18_url,{
      method:'POST',
      cahce: 'no-cache',
      headers:{ 
        'Content-Type':'application/x-www-form-urlencoded'
      },
    body:'token='+this.props.loginToken+'&type=user',
  }).catch(error=>console.log(error))).json();
    
  const data_sub19 = await( await   fetch(interview_7_sub_19_url,{
    method:'POST',
    cahce: 'no-cache',
    headers:{ 
      'Content-Type':'application/x-www-form-urlencoded'
    },
  body:'token='+this.props.loginToken+'&type=user',
}).catch(error=>console.log(error))).json();


    let poll_type1 = this.props.api_url+'/selectRows/view_poll_result_type1';
    let poll_type2 = this.props.api_url+'/selectRows/view_poll_result_type2';
    let poll_type3 = this.props.api_url+'/selectRows/view_poll_result_type3';

    const poll_data1 = await( await   fetch(poll_type1,{
        method:'POST',
        cahce: 'no-cache',
        headers:{ 
          'Content-Type':'application/x-www-form-urlencoded'
        },
      body:'token='+this.props.loginToken+'&type=user',
    }).catch(error=>console.log(error))).json();



    const poll_data2 = await( await   fetch(poll_type2,{
        method:'POST',
        cahce: 'no-cache',
        headers:{ 
          'Content-Type':'application/x-www-form-urlencoded'
        },
      body:'token='+this.props.loginToken+'&type=user',
    }).catch(error=>console.log(error))).json();


    const poll_data3 = await( await   fetch(poll_type3,{
        method:'POST',
        cahce: 'no-cache',
        headers:{ 
          'Content-Type':'application/x-www-form-urlencoded'
        },
      body:'token='+this.props.loginToken+'&type=user',
    }).catch(error=>console.log(error))).json();
    console.log(poll_data3)


    let interview_7_sum_url=this.props.api_url+'/selectRows/view_report_interview_type7_sum';
    const interview_7_sum_data = await( await   fetch(interview_7_sum_url,{
        method:'POST',
        cahce: 'no-cache',
        headers:{ 
          'Content-Type':'application/x-www-form-urlencoded'
        },
      body:'token='+this.props.loginToken+'&type=user&year_='+current_year,
    }).catch(error=>console.log(error))).json();



    let monthly_sale_data = new Array();
    let current_year_data = interview_7_sum_data
    current_year_data.map(row=>{
        monthly_sale_data.push(row.toplam_adet );
    })
 
this.setState({poll_data1:poll_data1,poll_data2:poll_data2,poll_data3:poll_data3,datatype7:data,current_month:current_month,current_year:current_year,pageloader:true,
  data_sub19:data_sub19,
  data_sub18:data_sub18,
    monthly_data:[{'name':'Arama Adedi', 'data':monthly_sale_data}],

})


     }

    render(){
        return( 
<div className="main">
    <div className="main-content">
    <div className="row">
            <div className="col-md-6"  >
                <h2 style={{marginBottom:15}}>Raporlar | <small>Müşteri İlişkileri</small></h2>
                </div>
            <div className="col-md-6"  >   
            <SelectList table='com_month'   label={'Rapor Ayı'}   onChange={(x) => this.handleChange(x,true,'current_month')} value={this.state.current_month}/>
            </div>

            </div>
        <div className="row">
            <div className="col-md-6"  >
                <div className="panel">
                    <div className="panel-heading">
                        <h3 className="panel-title">Bakım Hatırlatma Aramaları : </h3>
                    </div>
                    <div className="panel-body">
                    <DataTable
                        columns={this.state.datatype7_columns}
                        data={this.state.datatype7.filter(row=>parseInt(row.month_)===parseInt(this.state.current_month)&&parseInt(row.year_)===parseInt(this.state.current_year))}
                        fixedHeader
                        noHeader={true}
                        overflowY={false}
                        overflowYOffset={'100px'}
                        highlightOnHover
                        responsive
                        striped
                        dense
                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                        progressPending={!this.state.pageloader}
                    />
                    </div>
                </div>
            </div>
            <div className="col-md-6"  >
                <div className="panel">
                    <div className="panel-heading">
                    <h3 className="panel-title">Ay Bazında Bakım Hatırlatma Arama Adetleri</h3>
                    </div>
                    <div className="panel-body">
                    <Chart   options={this.state.bar_options} series={this.state.monthly_data} type="bar" />

                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6"  >
                <div className="panel">
                    <div className="panel-heading">
                        <h3 className="panel-title">Servis Müş. İlk Bakım Geri Araması : </h3>
                    </div>
                    <div className="panel-body">
                    <DataTable
                        columns={this.state.datatype7_columns}
                        data={this.state.data_sub18.filter(row=>parseInt(row.month_)===parseInt(this.state.current_month)&&parseInt(row.year_)===parseInt(this.state.current_year))}
                        fixedHeader
                        noHeader={true}
                        overflowY={false}
                        overflowYOffset={'100px'}
                        highlightOnHover
                        responsive
                        striped
                        dense
                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                        progressPending={!this.state.pageloader}
                    />
                    </div>
                </div>
            </div>
            <div className="col-md-6"  >
                  <div className="panel">
                      <div className="panel-heading">
                          <h3 className="panel-title">Servis Müş. 5.Yıl Geri Araması : </h3>
                      </div>
                      <div className="panel-body">
                      <DataTable
                          columns={this.state.datatype7_columns}
                          data={this.state.data_sub19.filter(row=>parseInt(row.month_)===parseInt(this.state.current_month)&&parseInt(row.year_)===parseInt(this.state.current_year))}
                          fixedHeader
                          noHeader={true}
                          overflowY={false}
                          overflowYOffset={'100px'}
                          highlightOnHover
                          responsive
                          striped
                          dense
                          paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                          progressPending={!this.state.pageloader}
                      />
                      </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-4"  >
                <div className="panel">
                    <div className="panel-heading">
                    <h3 className="panel-title">Satış Memnuniyet</h3>
                    </div>
                    <div className="panel-body">
                    <DataTable
                        columns={this.state.poll_columns1}
                        data={this.state.poll_data1.filter(row=>parseInt(row.month_)===parseInt(this.state.current_month)&&parseInt(row.year_)===parseInt(this.state.current_year))}
                        fixedHeader
                        noHeader={true}
                        overflowY={false}
                        overflowYOffset={'100px'}
                        highlightOnHover
                        responsive
                        striped
                        dense
                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                        progressPending={!this.state.pageloader}
                    />



                    </div>
                </div>
            </div>
            <div className="col-md-4"  >
                <div className="panel">
                    <div className="panel-heading">
                    <h3 className="panel-title">Servis Memnuniyet</h3>
                    </div>
                    <div className="panel-body">
                    <DataTable
                        columns={this.state.poll_columns1}
                        data={this.state.poll_data2.filter(row=>parseInt(row.month_)===parseInt(this.state.current_month)&&parseInt(row.year_)===parseInt(this.state.current_year))}
                        fixedHeader
                        noHeader={true}
                        overflowY={false}
                        overflowYOffset={'100px'}
                        highlightOnHover
                        responsive
                        striped
                        dense
                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                        progressPending={!this.state.pageloader}
                    />
                    </div>
                </div>
            </div>
            <div className="col-md-4"  >
                <div className="panel">
                    <div className="panel-heading">
                    <h3 className="panel-title">Showroom Memnuniyet</h3>
                    </div>
                    <div className="panel-body">
                    <DataTable
                        columns={this.state.poll_columns2}
                        data={this.state.poll_data3.filter(row=>parseInt(row.month_)===parseInt(this.state.current_month)&&parseInt(row.year_)===parseInt(this.state.current_year))}
                        fixedHeader
                        noHeader={true}
                        overflowY={false}
                        overflowYOffset={'100px'}
                        highlightOnHover
                        responsive
                        striped
                        dense
                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                        progressPending={!this.state.pageloader}
                    />
                    </div>
                </div>
            </div>
        </div>
     </div>
</div>

        )
    }

}

export default connect(mapStateToProps)(ReportCrm);
