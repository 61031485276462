import React from 'react';
import {connect} from 'react-redux';
import DataTable from 'react-data-table-component';
import mapStateToProps from '../../redux/mapStateToProps';
const columns = [
    {
        name: 'Malzeme No',
        selector: 'code',
        left: true
    },
    {
        name: 'Oem Kodu',
        selector: 'oem_code',
        left: true
    },
    {
        name: 'Malzeme Adı',
        selector: 'text',
        left: true
    },
    {
        name: 'Malzeme Tipi',
        selector: 'id_com_product_type_name',
        left: true
    },

    {
        name: 'Sınıfı',
        selector: 'id_com_product_class_name',
        left: true
    },
    {
        name: 'Alt Grup',
        selector: 'id_com_product_sub_class1_name',
        left: true
    },
    {
        name: 'Alt Grup2',
        selector: 'id_com_product_sub_class2_name',
        left: true
    },
    {
        name: 'Marka',
        selector: 'id_com_product_brand_name',
        left: true
    },
    {
        name: 'Kontrol',
        cell: row => (
            <a className="btn btn-primary btn-sm" type="button" href={"/ProductCard/" + row.id}>Seç</a>
        ),
        right: true
    }]
class ProductList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: true,
            data: [],
            filtered: [],
            selectedRow: {},
            SearchItem: ''
        }

        this._Update = this._Update.bind(this);
        this._Filter_Data = this._Filter_Data.bind(this);
    }
    componentDidMount() {
        this._Update()

    }
    _Update() {
        let select_url = this.props.api_url + '/selectRows/view_product';
        let user_body = 'id_com_company=' + this.props.id_com_company + '&token=' + this.props.loginToken + '&type=user';
        fetch(select_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: user_body
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                this.setState({data: result, pageloader: true})
                //console.log(result)
            })
    }
    _Filter_Data(value) {
        this.setState({SearchItem: value.toUpperCase()})
        let data = this.state.data;
        let filtered_data = data.filter(function (row) {
            if (row.code !== null) {
                return row.code.includes(value.toUpperCase())
            } else {
                return null
            }
        })
        this.setState({filtered: filtered_data})
    }
    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3 className="page-title">Malzeme Listesi</h3>
                                </div>
                                <div className="col-md-6 text-right">
                                    <a href={"/ProductAdd"} className="btn btn-primary">Ekle</a>
                                </div>
                                <div className="col-md-12">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <div className="panel-title">Malzeme Listesi ({this.state.data.length})</div>
                                        </div>
                                        <div className="panel-body">
                                            <DataTable
                                                columns={columns}
                                                data={this.state.SearchItem === '' ? this.state.data : this.state.filtered}
                                                fixedHeader
                                                noHeader={true}
                                                overflowY={false}
                                                overflowYOffset={'100px'}
                                                highlightOnHover
                                                responsive
                                                striped
                                                // dense
                                                progressPending={!this.state.pageloader}
                                                pagination={true}
                                                paginationPerPage={20}
                                                defaultSortField={'code'}
                                                defaultSortAsc={false}
                                                subHeader={true}
                                                subHeaderAlign={'right'}
                                                subHeaderComponent={
                                                    <React.Fragment>
                                                        <form className="input-group">
                                                            {this.state.SearchItem !== '' ?
                                                                <span className="input-group-btn"><button className="btn btn-primary" onClick={() => this.setState({SearchItem: '',filtered_data: []})}><i className="fas fa-list"></i></button></span>
                                                            :
                                                                <span className="input-group-addon"><i className="fas fa-search"></i></span>
                                                            }
                                                            <input className="form-control" value={this.state.SearchItem} type="search" placeholder="Arama..." onChange={(x) => this._Filter_Data(x.target.value)}/>
                                                        </form>
                                                    </React.Fragment>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(ProductList);