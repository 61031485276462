import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import {Divider, Input, PageHeader, Table, Typography} from 'antd';
import moment from 'moment';
import ExportFromJson from '../utilities/jsontoexport';

const {Text } =Typography;


class InterviewList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            data: [],
            id_com_interview_type: '',
            loading:true,
            searchItem:'',
            filtered_data:[],

            columns1:[
                {
                    title:'',
                    dataIndex:'',
                    widht:'30px',
                    render:(row,record)=> <a href={'/interview/Detail/'+record.id} target={'blank_'} className="btn btn-primary btn-sm">Seç</a>
                 },
                 {
                    title:'Görüşme Tarihi',
                    dataIndex:'username',
                    align:'left', 
                    render:(row,record)=>  moment(record.c_date).format('DD.MM.YYYY')
                 },
                 {
                    title:'Danışman',
                    dataIndex:'id_com_user_name',
                    align:'left', 
                 },
                 {
                    title:'Firma Adı / Adı Soyadı',
                    dataIndex:'username',
                    align:'left', 
                 },
                 {
                    title:'Görüşme Tipi',
                    dataIndex:'id_com_interview_type_name',
                    align:'left', 
                 },
                 {
                    title:'Konu',
                    dataIndex:'id_com_interview_subject_name',
                    align:'left', 
                 },
                 {
                    title:'Durum',
                    dataIndex:'status_name',
                    align:'left', 
                 },
                 {
                    title:'İlk Temas',
                    dataIndex:'id_com_meet_name',
                    align:'left', 
                 },
                 {
                    title:'Marka',
                    dataIndex:'id_com_brand_name',
                    align:'left', 
                 },

                 {
                    title:'Model',
                    dataIndex:'id_com_model_name',
                    align:'left', 
                 },
            ],

            columns2:[
                {
                    title:'',
                    dataIndex:'',
                    widht:'30px',
                    render:(row,record)=> <a href={'/interview/Detail/'+record.id} target={'blank_'} className="btn btn-primary btn-sm">Seç</a>
                 },
                 {
                    title:'Görüşme Tarihi',
                    dataIndex:'username',
                    align:'left', 
                    render:(row,record)=>  moment(record.c_date).format('DD.MM.YYYY')
                 },
                 {
                    title:'Danışman',
                    dataIndex:'id_com_user_name',
                    align:'left', 
                 },
                 {
                    title:'Firma Adı / Adı Soyadı',
                    dataIndex:'username',
                    align:'left', 
                 },
                 {
                    title:'Görüşme Tipi',
                    dataIndex:'id_com_interview_type_name',
                    align:'left', 
                 },
                 {
                    title:'Konu',
                    dataIndex:'id_com_interview_subject_name',
                    align:'left', 
                 },
                 {
                    title:'Durum',
                    dataIndex:'status_name',
                    align:'left', 
                 },
                 {
                    title:'İlk Temas',
                    dataIndex:'id_com_meet_name',
                    align:'left', 
                 },
             
            ]
        }
        this._Filter = this._Filter.bind(this);
    }
    componentDidMount() {

        const id_com_interview_type = this.props.match.params.id_com_interview_type;
        this.setState({id_com_interview_type: id_com_interview_type})

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("token", this.props.loginToken);


        if (id_com_interview_type != 'All' && id_com_interview_type != 'AllOpen'){
             urlencoded.append("id_com_interview_type", id_com_interview_type)
        }

        if (id_com_interview_type === 'AllOpen'){
            urlencoded.append('status', 0)
        } 


        if([1,2,4,11].includes(parseInt(this.props.id_com_user_type)))
        {
            if(id_com_interview_type!=='3'){ urlencoded.append('id_com_brand',this.props.id_com_brand)}
           
        }else{
            urlencoded.append('id_com_user',this.props.id_com_user)
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        fetch(this.props.api_url + "/selectRows/view_interview", requestOptions)
            .then(response => response.json())
            .then(result => {  
                this.setState({data: result.reverse(), loading: false})
            })
            .catch(error => console.log('error', error));
    }
 

    _Filter(value){
        this.setState({searchItem:value})
        let match_str = new RegExp('('+value+')','i'); 
        let filtered_data = this.state.data.filter((row)=>{ 
          if(value===''){ 
              return row
          }  else     {  
          return   moment(row.c_date).format('DD.MM.YYYY').match(match_str)||row.id_com_meet_name.match(match_str)||row.id_com_brand_name.match(match_str)||row.id_com_model_name.match(match_str)||row.id_com_interview_subject_name.match(match_str) ||row.username.match(match_str) 
          }})
         this.setState({filtered_data:filtered_data})
    }


    render() {

        const selected_columns= ['AllOpen','1','2','7'].includes(this.props.match.params.id_com_interview_type)?this.state.columns1:this.state.columns2
        const data =this.state.searchItem===''?this.state.data:this.state.filtered_data
        return (
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-heading">
                                        <div className={"row"}>
                                        <div className={"col-12"}>
                                            <PageHeader 
                                                title='Görüşme Listesi'
                                                extra={[
                                                    <ExportFromJson data={data} key='2'  fileName={`Gorusme_notlari_${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}`} exportType={'xls'}/>,
                                                    <a href="/interview/add/0" key='1' className="btn btn-primary" style={{marginRight:5}}>Ekle</a>
                                                ]}
                                            />
                                            </div>
                                            
                                          
                                        </div>
                                    </div>
                                    <div className="panel-body">
                                        <Input.Search  placeholder='Müşteri / Marka / Tip / Model ...'   onChange={(x)=>this._Filter(x.target.value)}/> 
                                    </div>
                                    <Divider></Divider>
                                    <div className="panel-body">
                                        <Table dataSource={data} size='small' columns={selected_columns} pagination={{pageSize:10}} loading={this.state.loading} footer={()=><Text code type='danger'>{`${this.state.data.length} Adet Görüşme Satırı Bulunmuştur`}</Text>}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(mapStateToProps)(InterviewList);