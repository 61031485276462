import React ,{useEffect,useState} from 'react';
import {  Col, Row,  Typography,Button,Divider,Checkbox,Form,DatePicker ,Input,PageHeader, message} from 'antd';
import {  CheckCircleFilled ,LoadingOutlined,  CloseCircleFilled} from '@ant-design/icons';
import {connect} from 'react-redux';
import SelectList from '../../../components/utilities/SelectList'
import mapStateToProps from '../../../redux/mapStateToProps';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import moment from 'moment'; 

const {  Text} = Typography;

const  FinancePaymentAdd =({api_url,loginToken,id_com_user})=> {
    const [formData] = Form.useForm();


    useEffect(() => {
        const OnLoad = ()=>{
            _GetNumber();

        };
        return OnLoad();
      
    }, [])

const _GetNumber = async () =>{
    let date = new Date();
    let yyyy = date.getFullYear();
    let select_url= api_url+'/selectRows/com_finance_payment';
    const doc_count = await(await  fetch(select_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
            'Content-Type':'application/x-www-form-urlencoded'
            },
            body:  'token='+loginToken+'&type=user',
        })).json()

        let number = 
        doc_count.length>=9999999?
        doc_count.length+1:
        doc_count.length>=999999?
        '0'+doc_count.length+1:
        doc_count.length>=99999?
        '00'+doc_count.length+1:
        doc_count.length>=9999?
        '000'+doc_count.length+1:
        doc_count.length>=999?
        '0000'+(doc_count.length+1):
        doc_count.length>=99?
        '00000'+(doc_count.length+1):
        doc_count.length>=9?
        '000000'+(doc_count.length+1):
        '0000000'+(doc_count.length+1)

        const  doc_number = 'PAY'+yyyy+number;
        formData.setFieldsValue({doc_number:doc_number})
}


    
 
const   handleSumbit = async () =>{

    let d = new Date(); 
    let c_date  = moment(d).format('YYYY-MM-DD');
    let c_time = d.toLocaleTimeString('tr-TR');
    const form_data = formData.getFieldsValue();

    let  insert_body=
        'token='+ loginToken+
        '&id_com_user='+ id_com_user+
        '&c_date='+c_date +
        '&c_time='+ c_time+
        '&type=user&status=0';

        for (let [key,value] of Object.entries(form_data)) {
            switch(key){
                case 'doc_date':
                    insert_body +=`&${key}=${moment(value).format('YYYY-MM-DD')}`
                    break;
                case 'show':
                    let check = value===true?1:0
                    console.log(value)
                    insert_body +=`&${key}=${check}`
                    break;
                default :
                    insert_body +=`&${key}=${value}`;
                    break;
            }
        }
        console.log(insert_body)

        let com_cash_url=api_url+'/insert/com_finance_payment';
        
        try{
       const result = await (await fetch(com_cash_url,{
              method:'POST',
              cahce: 'no-cache',
              headers:{ 
                'Content-Type':'application/x-www-form-urlencoded'
              },
            body:insert_body,
          })).json()

         if(result.status){window.location.href='/financepayment/list'}
          else { 
             message.error({content:'Hata Oluştu!.',duration:1000})
         }
    
      }
      catch (error) { console.log(error)}
    
    }
 
    const rowStyle = {
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
      };
      const colStyle = {
        marginBottom: '8px',
        padding:20,
      };
      const gutter = 16;
    
        return (
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <Row style={rowStyle} gutter={gutter} justify="start">
                                        <Col md={24} sm={24} xs={24} style={{marginBottom: '5px',}}>
                                            <PageHeader title={'Kayıt Ekle'}/>  
                                        </Col>
                                    </Row>
                                    <Divider></Divider>
                                    <Form form={formData} labelCol={{span:7 }}  labelAlign='left'  onFinish={handleSumbit}>
                                        <Row style={rowStyle} gutter={gutter} justify="start">
                                            <Col span={24}  style={colStyle}>
                                                <Row style={{flexDirection:'row'}}>
                                                    <Col xl={12} lg={12} md={24} sm={24} xs={24} style={colStyle}>
                                                        <Form.Item name={'doc_number'}  label='Belge No'> 
                                                            <Input   type='text'  placeholder={'Kayıt No Giriniz'}  disabled/>
                                                        </Form.Item>
                                                        <Form.Item name={'doc_date'} label='İşlem Tarihi' > 
                                                                <DatePicker locale={locale} style={{width:'100%'}} format='DD.MM.YYYY'/> 
                                                        </Form.Item>
                                                        <Form.Item name={'id_com_cash_pay_type'} label='Hareket Türü Seçiniz' > 
                                                            <SelectList  table={'com_cash_pay_type'}   />
                                                        </Form.Item>
                                                        <Form.Item name={'id_com_finance_payment_type'} label='Hareket Tipi Seçiniz' > 
                                                            <SelectList  table={'com_finance_payment_type'}   />
                                                        </Form.Item>
                                                        <Form.Item name={'amount'} disabled label='Belge No'> 
                                                            <Input   type='number'  placeholder={' Tutarı Giriniz'}  />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xl={12} lg={12} md={24} sm={24} xs={24} style={colStyle}>
                                                        <Form.Item name={'show'} disabled label='Rapor Göster' valuePropName='checked'> 
                                                            <Checkbox   />
                                                        </Form.Item>
                                                        <Form.Item name={'comment'} disabled label='Açıklama'> 
                                                            <Input.TextArea rows={6} type='text'  placeholder={'Var ise notunuzu ekleyiniz.'}  />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Divider />
                                        <Row style={rowStyle} gutter={4} justify="end">
                                            <Col style={colStyle}>
                                                <Button type="primary" htmlType='button'   shape='round'  danger onClick={()=>window.location.href='/financepayment/list'}   > İptal  <CloseCircleFilled  size={30}  />  </Button>
                                             </Col>
                                            <Col style={colStyle}>
                                                 <Button type="primary" htmlType='submit'   shape='round'       > Kaydet  <CheckCircleFilled  size={30}  /></Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
    )
  }
 
export default connect(mapStateToProps)(FinancePaymentAdd);

