import React, {Component} from 'react';
export default class SignUp extends Component {
	constructor(props) { 
        super(props);   
        this.state = {datas:[]};
	}
	render (){
	    return (
            <div> SignUp </div>
        )
	}
}