import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component';

class TaskManager extends React.Component {
    constructor(props) {
    super(props);
        this.state = {
            data:[],
            columns:[
                {
                    name: 'Bölüm',
                    selector: 'id_com_department_name',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Görev Konusu',
                    selector: 'id_com_task_subject_name',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Görev Süresi',
                    selector: 'id_com_task_type_name',
                    left:true,
                    width:'150px',
                    sortable:true
                },


                
                {
                    name: 'Görev',
                    selector: 'comment',
                    wrap:true,                    
                    left:true,
                    sortable:true
                },
                {
                    name: 'Atanan',
                    selector: 'r_id_com_user_name',
                    wrap:true,                    
                    left:true,
                    sortable:true
                },
                {
                    name: 'Marka',
                    selector: 'id_com_brand_name',
                    wrap:true,                    
                    left:true,
                    sortable:true
                },
            ],
        }
        this._Update=this._Update.bind(this)
    }


    componentDidMount(){
        this._Update()
    }


    _Update(){
        let task_url=this.props.api_url+'/selectRows/view_task';
        fetch(task_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
              body:'token='+this.props.loginToken+'&type=user',
            })
            .catch(error=>console.log(error)).then(x=> x.json()).then(result=>{
               this.setState({data:result})
            console.log(result)
            })
            
    }
    render () { 
        return ( 
        <div className="main">
            <div className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel">
                                <div className="panel-heading">
                                    <h3 className="panel-title">Görev Listesi</h3>
                                </div>
                                <div className="panel-body"> 
                                   <DataTable
                                            columns={this.state.columns}
                                            data={this.state.data}
                                            fixedHeader
                                            title={'Genel Sorumluluklar'}
                                            overflowY={false}
                                            overflowYOffset={'100px'}
                                            highlightOnHover
                                            responsive
                                            striped
                                            dense
                                            paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                            pagination={true}
                                            paginationPerPage={10}
                                          //  defaultSortField={'c_date'}
                                            defaultSortAsc={false}

                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




                                )
                                }


}

export default connect(mapStateToProps)(TaskManager);