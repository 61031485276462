import React,{useState,useEffect} from 'react';
import {  Col, Row, Divider, message,Form,Collapse ,Button,Transfer,Input,Drawer, useForm,Spin} from 'antd';
import {connect} from 'react-redux';
import mapStateToProps from '../../../redux/mapStateToProps';
import SelectList from '../../../components/utilities/SelectList';
import 'moment/locale/tr'; 
import moment from 'moment'; 

const  formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 2
  })
   
 
 const {Panel} = Collapse;

const CostGroupAdd = ({api_url,loginToken,visible,OnClose,id_com_user})=>{
    const [formData] = Form.useForm();
    const [MuhPlans,setMuhPlans] = useState([])
    const [Line,SetLine]= useState([])
    const [TargetKeys,SetTargetKeys]= useState([])
    const [InitalKeys,SetInitalKeys]= useState([])
    const [Loading,setLoading] =useState(true);


    const ExtractJson = ()=>{
        let result =''
    
           TargetKeys.map((row,index)=>{
    
                   if(index===0){
                       result +=row;
                   } else {
                       result += `,${row}`;
                   }
                   })
                return result;
    
         
    }


    const _Submit = async ()=>{
        const form_data = formData.getFieldsValue();
    
        let insert_url=api_url+'/insert/com_cost_plan';
        let d = new Date(); 
        let c_date  = moment(d).format('YYYY-MM-DD');
        let c_time = d.toLocaleTimeString('tr-TR');
        const codes = ExtractJson();

        let body ='token='+loginToken+'&type=user&c_id_com_user='+id_com_user+'&c_date='+c_date+'&c_time='+c_time+'&cost_codes='+codes
    
        for (let [key,value] of Object.entries(form_data)) { 
            if(key!=='btn'&&key!=='groups'){
                switch(key) {
                    default : 
                     body += `&${key}=${value}`;
                    break;
                }
            }
        }

         
        const config = {method:'POST',cahce:'no-cache',headers:{'Content-Type':'application/x-www-form-urlencoded'},body:body}
        const result = await (await fetch(insert_url,config)).json()
        if(result.status) {
            message.loading('Kayıt Ediliyor',0.3).then(()=>message.success('Kayıt Edildi',1)).then(e=>window.location.reload())
       } else {
            message.loading('Kayıt Ediliyor',0.3).then(()=>message.error('Hata Oluştu',1))
       }
     
    }


    useEffect(() => {
        const OnLoad =()=>{
            _GetMuhPlan();
         }       
        return OnLoad();
    }, [])


    const HandleChange = (targetKeys) => { 
        SetTargetKeys(targetKeys);
      }


    const _GetMuhPlan =  async () =>{
        let url= api_url+'/logoselectrows/ZZZ_ST_120_EMUHACC_700';
        let body = '&token='+loginToken+'&type=user';
        let config = {  method:'POST',    cahce: 'no-cache',    headers:{     'Content-Type':'application/x-www-form-urlencoded'     },   body:body}
        let response = await (await fetch(url,config)).json() 
 
        setMuhPlans(response)
        const MockData = []
        const targetKeys = [];
     
        response.map((row)=>MockData.push(
             {
                 key: row.CODE,
                 title: ` ${row.CODE} - ${row.DEFINITION_}`,
                 chosen: 0,
               }
        ))
         
         SetLine(MockData)
         SetTargetKeys(targetKeys)
         SetInitalKeys(targetKeys);
         setLoading(false);
       }
    
    

return ( 
    <Drawer
        title='Hesap Grubu Ekle'
        visible={visible}
        onClose={OnClose}
        width='70%'
        style={{marginTop:30}}

         >
            <Form layout='vertical' form={formData} onFinish={_Submit}>
                <Form.Item name='id_com_company' label='Firma Adı'>
                    <SelectList table='view_company_sl'/>
                </Form.Item>
                <Form.Item name='text' label='Gider Grup Adı'>
                    <Input type='text'  />
                </Form.Item>
                
                <Form.Item   label='Hesap Kodları'>
                <Divider>Sınıflama</Divider>
                    {Loading?
                        <Spin >Veri Yukleniyor. Bekleyiniz</Spin>
                    : 
                  
                <Transfer
                    locale={{itemUnit:'Adet',itemsUnit:'Adet',searchPlaceholder:'Sayfa Ara',selectCurrent:'Sayfayı Seç',selectAll:'Tümünü Seç',selectInvert:'Tersini Seç'}}
                    dataSource={Line}
                    showSearch
                    listStyle={{
                    width: '100%',
                    height: '500px',
                    justifyContent:'space-between'
                        }}
                    pagination={true}
                    operations={['Gruba Ekle ','Gruptan Çıkar ']}
                    targetKeys={TargetKeys}
                    onChange={HandleChange}
                    render={item => `${item.title}`}
                    pagination={{pageSize:20}}
                    />

                    }
                </Form.Item>

                <Divider></Divider>
                <Form.Item name='btn'  >
                    <Row align='middle' justify='end' gutter={12}>
                        <Col>
                            <Button danger onClick={OnClose} htmlType='reset' >Vazgeç</Button>
                        </Col> 

                        <Col>
                            <Button type='primary' htmlType='submit' >Kaydet</Button>
                        </Col> 
                    </Row>

                </Form.Item>

            </Form>
        </Drawer>
)
}

export default connect(mapStateToProps)(CostGroupAdd);