import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
import {alertMessage} from '../../assets.js';
import AlertAdd from '../Alerts/AlertAdd';
import FileUpload from '../utilities/FileUpload';
import Axios from "axios";
import { Button,Row,Col,PageHeader} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

class InterviewDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: false,
            body_data: [],
            lines_data: [],
            SwapCar: [],
            id_com_expertise: '',
            testdrive: 0,
            SwapCarDetail: false,
            swapsaved: false,
            Exp_list: false,
            expertise_history: [],
            id_com_interview_status: '',
            alert_data: [],
            car_data: [],
            id_com_cars: '',
            id_com_car_data: [],
            documents: [],
            pollData: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.SwapCar = this.SwapCar.bind(this);
        this.AddSwapCar = this.AddSwapCar.bind(this);
        this.SearchExp = this.SearchExp.bind(this);
        this.CloseAlert = this.CloseAlert.bind(this);
        this.DeleteDocument = this.DeleteDocument.bind(this);
    }
    DeleteDocument(id) {
        let alert_url = this.props.api_url + '/update/com_document';
        let update_body = 'token=' + this.props.loginToken +
            '&id=' + id +
            '&type=user&status=1'

        fetch(alert_url, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: update_body,
        })
            .catch(error => console.log(error)).then(x => {
            window.location.reload()
        })
    }
    CloseAlert(id) {
        let alert_url = this.props.api_url + '/update/com_alert';
        let d = new Date();
        let date = d.toISOString();
        let m_date = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');
        let update_body = 'token=' + this.props.loginToken +
            '&id=' + id +
            '&m_date=' + m_date +
            '&m_time=' + m_time +
            '&type=user&status=1'
        fetch(alert_url, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: update_body,
        })
            .catch(error => console.log(error)).then(x => {
            window.location.reload()
        })
    }
    async SearchExp(x) {
        let str = x.target.value.toString();
        let event = str.toUpperCase();
        let plate = event.replace(' ', '');

        let url = this.props.api_url + '/selectRows/view_expertise';
        fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'license_plate=' + plate + '&token=' + this.props.loginToken,
        })
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(result => {
                    if (result.length > 0) {
                        this.setState({expertise_history: result, Exp_list: true});
                    } else {
                        this.setState({expertise_history: [], Exp_list: false});
                    }
                }
            )
        // view_expertise
    }
    SwapCar(event) {
        event.preventDefault();
        let form = document.getElementById('SwapCarForm');
        let formData = new FormData(form);

        let array = {};
        for (var pair of formData.entries()) {
            let key = pair[0];
            let value = pair[1]
            array[key] = value;
        }
        this.setState({SwapCar: array, SwapCarDetail: true});
    }
    handleChange(event, selectlist, name) {
        if (selectlist) {
            this.setState({[`${name}`]: event.id})
        } else {

            if (event.target.type === 'checkbox') {
                event.target.checked ? this.setState({[`${event.target.name}`]: '1'}) : this.setState({[`${event.target.name}`]: '0'})
            } else {
                const value = event.target.value;
                const key = event.target.name;
                this.setState({[`${key}`]: value})
            }
        }
    }
    AddSwapCar() {
        let d = new Date();
        let date = d.toISOString();
        let c_date = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let swapcar = this.state.SwapCar;
        let id_com_brand = swapcar.id_com_brand;
        let id_com_model = swapcar.id_com_model;
        let id_com_gear = swapcar.id_com_gear;
        let id_com_fuel = swapcar.id_com_fuel;
        let id_com_color = swapcar.id_com_color;
        let km = swapcar.km;
        let model_year = swapcar.model_year;
        let spec = swapcar.spec;
        let str = swapcar.license_plate.toString();
        let event = str.toUpperCase();
        let license_plate = event.replace(' ', '');
        let chasis = swapcar.chasis;
        let demand_price = swapcar.demand_price;
        let comment = swapcar.comment;
        let id_com_customer = this.state.body_data.id_com_customer;

        let insert_car =
            'token=' + this.props.loginToken +
            '&id_com_brand=' + id_com_brand +
            '&id_com_customer=' + id_com_customer +
            '&id_com_model=' + id_com_model +
            '&id_com_gear=' + id_com_gear +
            '&id_com_fuel=' + id_com_fuel +
            '&id_com_color=' + id_com_color +
            '&model_year=' + model_year +
            '&spec=' + spec +
            '&license_plate=' + license_plate +
            '&chasis=' + chasis +
            '&c_date=' + c_date +
            '&c_time=' + c_time +
            '&id_com_user=' + this.props.id_com_user +
            '&type=user';

        // console.log(insert_car);

        let car_list_url = this.props.api_url + '/selectRows/com_cars';

        fetch(car_list_url, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'token=' + this.props.loginToken + '&license_plate=' + license_plate + '&type=user',
        })
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(result => {

                if (result.length > 0) {
                    let id_com_cars = result[0].id;
                    let insert_exp =
                        'token=' + this.props.loginToken +
                        '&id_com_cars=' + id_com_cars +
                        '&demand_price=' + demand_price +
                        '&offer_price=0' +
                        '&comment=' + comment +
                        '&km=' + km +
                        '&id_com_user=' + this.props.id_com_user +
                        '&c_date=' + c_date +
                        '&c_time=' + c_time +
                        '&id_com_expertise_status=1' +
                        '&type=user';
                    let exp_url = this.props.api_url + '/insert/com_expertise';
                    fetch(exp_url, {
                        method: 'POST',
                        cache: 'no-cache',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: insert_exp,
                    }).catch(error => console.log(error)).then(result => result.json()).then(res => this.setState({
                        swapsaved: true,
                        id_com_expertise: res.id
                    }))

                } else {

                    let car_url = this.props.api_url + '/insert/com_cars';
                    fetch(car_url, {
                        method: 'POST',
                        cache: 'no-cache',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: insert_car,
                    })
                        .catch(error => console.log(error))
                        .then(response => response.json())
                        .then(result => {
                            let id_com_cars = result.id;
                            let insert_exp =
                                'token=' + this.props.loginToken +
                                '&id_com_cars=' + id_com_cars +
                                '&demand_price=' + demand_price +
                                '&offer_price=0' +
                                '&comment=' + comment +
                                '&km=' + km +
                                '&id_com_user=' + this.props.id_com_user +
                                '&c_date=' + c_date +
                                '&c_time=' + c_time +
                                '&id_com_expertise_status=1' +
                                '&type=user';
                            let exp_url = this.props.api_url + '/insert/com_expertise';
                            fetch(exp_url, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                },
                                body: insert_exp,
                            }).catch(error => console.log(error)).then(result => result.json()).then(res => this.setState({
                                swapsaved: true,
                                id_com_expertise: res.id
                            }))

                        })

                }


            })


    }
    handleSubmit(event) {
        let d = new Date();
        let date = d.toISOString();
        let c_date = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let id_com_meet = this.state.id_com_meet;


        let insert_lines =
            'token=' + this.props.loginToken +
            '&id_com_interview=' + this.state.id_com_interview +
            '&c_date=' + c_date +
            '&c_time=' + c_time +
            '&id_com_meet=' + id_com_meet +
            '&testdrive=' + this.state.testdrive +
            '&id_com_user=' + this.props.id_com_user +
            '&id_com_expertise=' + this.state.id_com_expertise +
            '&id_com_interview_status=' + this.state.id_com_interview_status +
            '&content=' + this.state.content +
            '&type=user';
// console.log(insert_lines);
        let com_interview_lines_url = this.props.api_url + '/insert/com_interview_lines';
        fetch(com_interview_lines_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: insert_lines,
        }).catch(e => console.log(e))
            .then(res => {
 

                let com_status_url = this.props.api_url + '/selectRows/com_interview_status';
                fetch(com_status_url, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&id=' + this.state.id_com_interview_status + '&type=user',
                }).catch(e => console.log(e)).then(res => res.json()).then(result => {

                    if (result[0].status === '1') {
                        let com_alert_url = this.props.api_url + '/selectRows/com_alert';
                        fetch(com_alert_url, {
                            method: 'POST',
                            cahce: 'no-cache',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            body: 'token=' + this.props.loginToken + '&id_com_interview=' + this.state.id_com_interview + '&status=0&type=user',
                        }).catch(e => console.log(e)).then(res => res.json()).then(result => {

                            result.map((row, index) => {
                                let com_alert_url_update = this.props.api_url + '/update/com_alert';
                                fetch(com_alert_url_update, {
                                    method: 'POST',
                                    cahce: 'no-cache',
                                    headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded'
                                    },
                                    body: 'token=' + this.props.loginToken + '&id=' + row.id + '&status=1',
                                }).catch(e => console.log(e))
                            })
                        })
                    }

                    let d = new Date();
                    let date = d.toISOString();
                    let m_date = date.split('T')[0];
                    let m_time = d.toLocaleTimeString('tr-TR');
                    let body_update_url = this.props.api_url + '/update/com_interview';

                    let body_update =
                        'token=' + this.props.loginToken +
                        '&id=' + this.state.id_com_interview +
                        '&m_id_com_user=' + this.props.id_com_user +
                        '&status=' + result[0].status +
                        '&id_com_interview_status=' + this.state.id_com_interview_status +
                        '&m_date=' + m_date +
                        '&m_time=' + m_time +
                        '&id_com_insurance_expert=' + this.state.id_com_insurance_expert +
                        '&id_com_insurance_company_traffic=' + this.state.id_com_insurance_company_traffic +
                        '&id_com_insurance_company_kasko=' + this.state.id_com_insurance_company_kasko +
                        '&type=user';

                    fetch(body_update_url, {
                        method: 'POST',
                        cahce: 'no-cache',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: body_update,
                    }).catch(e => console.log(e))
                        .then(res => {

                            switch (this.state.body_data.id_com_interview_type) {

                                case '1':
                                    if (this.state.id_com_interview_status === '15') {
                                        window.location.href = '/orderform/add/' + this.state.id_com_interview + '/' + this.state.body_data.id_com_customer
                                    } else {
                                        window.location.href = '/interview/Detail/' + this.state.id_com_interview
                                    }
                                    return null
                                case '2':
                                    if (this.state.id_com_interview_status === '26') {
                                        window.location.href = '/orderform/add/' + this.state.id_com_interview + '/' + this.state.body_data.id_com_customer
                                    } else {
                                        window.location.href = '/interview/Detail/' + this.state.id_com_interview
                                    }
                                    return null
                                case '7':

                                    if (this.state.id_com_interview_status === '34'||this.state.id_com_interview_status === '78'||this.state.id_com_interview_status === '100'||this.state.id_com_interview_status === '111') {
                                        if (this.state.id_com_cars) {
                                            window.location.href = '/ServiceApp/Add/' + this.state.id_com_cars
                                        } else {
                                            window.location.href = '/ServiceApp/Add/0'
                                        }
                                    } else {
                                        window.location.href = '/interview/Detail/' + this.state.id_com_interview
                                    }
                                    return null
                                default:
                                    window.location.href = '/interview/Detail/' + this.state.id_com_interview
                            }

                        })
                })
            })//.then(x=>window.location.href='/interview/Detail/'+this.state.id_com_interview)


    }
    getPollData = async (id) => {
        try {
            const response = await Axios.post(this.props.api_url + '/getPoll/' + id, {
                token: this.props.loginToken
            })
            // console.log(response.data);
            this.setState({
                questionsData: response.data
            })
        } catch (error) {
            console.error(error);
        }
    }
    pollFormSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        let urlencoded = new URLSearchParams();
        urlencoded = data;
        try {
            const response = await Axios.post(this.props.api_url + '/postData', urlencoded)
            this.setState({
                pollData: response.data
            })
            this.pollInsertData(this.props.match.params.id)
            this.setState({
                questionsData: null
            })
        } catch (error) {
            console.error(error);
        }
    }
    pollInsertData = async (id) => {
        const postData = JSON.stringify(this.state.pollData);
        try {
            const response = await Axios.post(
                this.props.api_url + '/pollInsert',
                {
                    pollData: postData, token: this.props.loginToken,id_com_interview:id
                }
            )
            //console.log(response)
        } catch (error) {
            console.error(error);
        }
    }
    pollCheck = async (interview_number,id_com_interview_subject) => {
        let poll_url = this.props.api_url + '/selectRow/com_interview_poll';
        fetch(poll_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'interview_number=' + interview_number + '&token=' + this.props.loginToken + '&type=user'
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                if(result){
                    this.setState({pollCheck: true})
                }else{
                    if (id_com_interview_subject == 14){
                        this.getPollData(1)
                    }else if(id_com_interview_subject == 10){
                        this.getPollData(2)
                    }else if(id_com_interview_subject == 16){
                        this.getPollData(3)
                    }
                    this.setState({pollCheck: false})
                }
            })
    }
    componentDidMount() {
        let id_com_interview = this.props.match.params.id;
        this.setState({id_com_interview: id_com_interview})
        let body_url = this.props.api_url + '/selectRows/view_interview';
        fetch(body_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id=' + id_com_interview + '&token=' + this.props.loginToken + '&type=user'
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                this.pollCheck(result[0].interview_number,result[0].id_com_interview_subject)
                this.setState({
                    body_data: result[0],
                    id_com_cars: result[0].id_com_cars
                })
                let cars_url = this.props.api_url + '/selectRows/view_cars';
                fetch(cars_url, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'id_com_customer=' + result[0].id_com_customer + '&token=' + this.props.loginToken + '&type=user',
                }).then(res => res.json())
                    .then(result => {
                        this.setState({car_data: result})
                    })
                fetch(cars_url, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'id=' + result[0].id_com_cars + '&token=' + this.props.loginToken + '&type=user',
                }).then(res => res.json())
                    .then(cardata => {
                        this.setState({id_com_car_data: cardata[0]})
                    })
            })
        let lines_url = this.props.api_url + '/selectRows/view_interview_lines';
        fetch(lines_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_interview=' + id_com_interview + '&token=' + this.props.loginToken + '&type=user'
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                this.setState({lines_data: result})
            })
        let media_url = this.props.api_url + '/selectRows/view_document';
        fetch(media_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_interview=' + id_com_interview + '&token=' + this.props.loginToken + '&type=user'
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                // console.log(result)
                this.setState({documents: result})
            })
        let alert_url = this.props.api_url + '/selectRows/com_alert';
        fetch(alert_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_interview=' + id_com_interview + '&token=' + this.props.loginToken + '&type=user&status=0'
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                this.setState({alert_data: result})
            })
    }

    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                          
                                <div className="col-md-12">
                                    <div className="panel">
                                    <PageHeader title={ `Görüşme Detayı /  No: ${this.state.body_data.interview_number} / ${this.state.body_data.status_name}`} 
                                        extra={[
                                            <button key='edit' onClick={()=>window.location.href="/interview/interview_edit_body/" + this.state.id_com_interview}  disabled={this.state.body_data.status === '1' ? true : false} className="btn btn-success">Duzenle</button>

                                        ]}

                                    />
                            
                                        <div className="panel-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th>Görüşme Tarihi</th>
                                                    <th>Açan Kullanıcı</th>
                                                    <th>Departman</th>
                                                    <th>Konu</th>
                                                    <th>Marka</th>
                                                    <th>Model</th>
                                                   {this.state.body_data.id_com_interview_type==='3'&& <th>Şasi / Poliçe</th>} 
                                                    <th>Görüşme Durumu</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{this.state.body_data.c_date} </td>
                                                    <td>{this.state.body_data.id_com_user_name}</td>
                                                    <td>{this.state.body_data.id_com_interview_type_name} </td>
                                                    <td>{this.state.body_data.id_com_interview_subject_name} </td>
                                                    <td>{this.state.body_data.id_com_brand_name}</td>
                                                    <td>{this.state.body_data.id_com_model_name}</td>
                                                     {this.state.body_data.id_com_interview_type==='3'&&  <td>{this.state.body_data.inter_chasis}</td>} 
                                                    <td>{this.state.body_data.id_com_interview_status_name}</td>
                                                </tr>
                                                </tbody>
                                            </table>

                                            <h4 className='text-center'>Müşteri Bilgileri </h4>
                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th>Müşteri</th>
                                                    <th>Telefon</th>
                                                    <th>Email</th>
                                                    <th>Kvkk</th>
                                                    <th width='100px'>Müşteri Kartı</th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{this.state.body_data.username}</td>
                                                    <td>{this.state.body_data.phone1}</td>
                                                    <td>{this.state.body_data.email}</td>
                                                    <td>{this.state.body_data.kvkk_status==='1'?'Var':'Yok'}</td>
                                                    <td><Button type='primary' shape='round' size='small' href={'/Customer/Detail/'+this.state.body_data.id_com_customer} target='blank_'> <SearchOutlined/></Button></td>

                                                </tr>
                                                </tbody>
                                            </table>
                                            {['5','7'].includes(this.state.body_data.id_com_interview_type) ? <>
                                                <h4 className='text-center'>Araç Bilgileri </h4>
                                                <table className="table table-bordered">
                                                    <thead>
                                                    <tr>
                                                        <th>Plaka</th>
                                                        <th>Marka</th>
                                                        <th>Model</th>
                                                        <th>Spec</th>
                                                        <th>Model Yılı</th>
                                                        <th>Şase</th>
                                                        <th>Teslimat Tarihi</th>

                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>{this.state.body_data.license_plate}</td>

                                                        <td>{this.state.id_com_car_data.id_com_brand_name}</td>
                                                        <td>{this.state.id_com_car_data.id_com_model_name}</td>
                                                        <td>{this.state.id_com_car_data.spec}</td>
                                                        <td>{this.state.id_com_car_data.model_year}</td>
                                                        <td>{this.state.id_com_car_data.chasis}</td>
                                                        <td style={{color: 'red'}}>{this.state.body_data.delivery_date}</td>

                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </> : <></>}
                                            {this.state.body_data.id_com_interview_subject === '19' ? <>
                                                <h4 className='text-center'>Araç Bilgileri </h4>
                                                <table className="table table-bordered">
                                                    <thead>
                                                    <tr>
                                                        <th>Plaka</th>
                                                        <th>Marka</th>
                                                        <th>Model</th>
                                                        <th>Spec</th>
                                                        <th>Model Yılı</th>
                                                        <th>Şase</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>{this.state.body_data.license_plate}</td>
                                                        <td>{this.state.id_com_car_data.id_com_brand_name}</td>
                                                        <td>{this.state.id_com_car_data.id_com_model_name}</td>
                                                        <td>{this.state.id_com_car_data.spec}</td>
                                                        <td>{this.state.id_com_car_data.model_year}</td>
                                                        <td>{this.state.id_com_car_data.chasis}</td>
 
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </> : <></>}
                                        </div>
                                        <div className="panel-body">
                                            <h3 className='text-center'>Görüşme Satırları </h3>
                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th width='50'>No</th>
                                                    <th width='150'>Tarih / Saat</th>
                                                    <th width='100'>Temas Türü</th>
                                                    {this.state.body_data.id_com_interview_type === '5' ?
                                                        <th width='100'>Durum</th> : <></>}

                                                    <th width='150'>Kullanıcı</th>
                                                    <th width='75'>Test Sürüşü</th>
                                                    <th width='75'>Ekspertiz</th>
                                                    <th width='400'>Görüşme Notu</th>
                                                    <th width='50'>Kontrol</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.lines_data.map((row, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1} </td>
                                                        <td>{row.c_date + ' / ' + row.c_time} </td>
                                                        <td>{row.id_com_meet_name} </td>
                                                        {this.state.body_data.id_com_interview_type === '5' ?
                                                            <th width='100'>{row.id_com_interview_status_name} </th> : <></>}
                                                        <td>{row.id_com_user_name} </td>
                                                        <td>{row.testdrive === '1' ? 'Yapıldı' : 'Yapılmadı'} </td>
                                                        <td>{row.id_com_expertise !== '0' ?
                                                            <a href={'/expertise/detail/' + row.id_com_expertise}
                                                               target="_blank"
                                                               className="btn btn-default btn-sm ml-md-5">Evet</a> : 'Hayır'}  </td>
                                                        <td>{row.content} </td>
                                                        <td><a
                                                            disabled={this.state.body_data.status === '1' || this.props.id_com_user !== this.state.body_data.id_com_user ? true : false}
                                                            href={'/interview/interview_edit_lines/' + row.id}
                                                            className="btn btn-danger btn-sm">Güncelle</a></td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                        {this.state.body_data.status === '1' ? <></> : this.state.body_data.id_com_interview_type === '5'|| this.state.body_data.id_com_interview_type === '3' ?
                                            <FileUpload id={'fileUpload'}
                                                        id_com_interview={this.state.id_com_interview}/> : <></>}
                                      
                                        {this.state.body_data.id_com_interview_type === '5' || this.state.body_data.id_com_interview_type === '3'?
                                            <div className="panel-body">
                                                <h3 className='text-center'>Döküman Listesi </h3>
                                                <table className="table table-bordered">
                                                    <thead>
                                                    <tr>
                                                        <th width='50'>No</th>
                                                        <th width='150'>Döküman Tipi</th>
                                                        <th width='150'>Döküman Adı</th>
                                                        <th width='150'>Yükleme Tarihi</th>
                                                        <th width='50'>Kontrol</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.documents.map((row, index) =>
                                                        <tr>
                                                            <td>{index + 1} </td>
                                                            <td>{row.id_com_document_type_name} </td>
                                                            <td>{row.filename} </td>
                                                            <td>{row.c_date + ' / ' + row.c_time} </td>
                                                            <td><a href={this.props.media_url + row.filename}
                                                                   className="btn btn-success btn-sm" target="_blank"
                                                                   style={{margin: 5}}>Görüntüle</a>
                                                                {this.state.body_data.status === '1' ? <></> :
                                                                    <button style={{margin: 5}}
                                                                            onClick={() => this.DeleteDocument(row.id)}
                                                                            className="btn btn-danger btn-sm">Sil</button>}
                                                            </td>
                                                        </tr>
                                                    )}

                                                    </tbody>
                                                </table>

                                            </div>
                                            : <></>}

                                        {this.state.body_data.status === '0' ?
                                            <div className="panel-body">
                                                <h3 className='text-center'>Yeni Not Girişi</h3>
                                                {this.state.body_data.id_com_interview_type === '1' || this.state.body_data.id_com_interview_type === '2' ? <>
                                                    <div className="form-check">
                                                        <input className="form-check-input" name='testdrive' type="checkbox"
                                                               id='testdrive1' onChange={(x) => this.handleChange(x)}/>
                                                        <label className="form-check-label" style={{marginLeft: 5}}> Test
                                                            Sürüşü Yapıldı. </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" name='expertise' type="checkbox"
                                                               id='testdrive1' onChange={(x) => this.handleChange(x)}/>
                                                        <label className="form-check-label" style={{
                                                            marginLeft: 5,
                                                            color: this.state.swapsaved ? 'green' : ''
                                                        }}> Takas Talebi
                                                            Var. {this.state.swapsaved ? 'Takas Talebi Gönderilmiştir.' : ''} </label>
                                                    </div>
                                                </> : <></>}
                                                {this.state.expertise === '1' && this.state.swapsaved === false ?
                                                    <div className="panel">
                                                        <div className="panel-heading">
                                                            <h3 className="panel-title">Takas Araç Bilgileri</h3>
                                                        </div>
                                                        <div className="panel-body">
                                                            {this.state.SwapCarDetail ?
                                                                <>
                                                                    <div className="col-md-12"
                                                                         dangerouslySetInnerHTML={{__html: alertMessage('requestConfirm')}}></div>
                                                                    <button
                                                                        type='submit'
                                                                        value="Submit"
                                                                        className="btn btn-primary pull-right"
                                                                        onClick={() => this.AddSwapCar()}
                                                                    >Onayla
                                                                    </button>
                                                                    <button
                                                                        type='submit'
                                                                        value="Submit"
                                                                        className="btn btn-danger pull-left"
                                                                        onClick={() => this.setState({
                                                                            SwapCar: [],
                                                                            SwapCarDetail: false
                                                                        })}
                                                                    >Temizle
                                                                    </button>
                                                                </>
                                                                :
                                                                <form onSubmit={(event) => this.SwapCar(event)}
                                                                      method="post" id='SwapCarForm'>
                                                                    <div className="form-group">
                                                                        <label>Araç Plakası</label>
                                                                        <input type="text" className="form-control"
                                                                               name="license_plate"
                                                                               onChange={(e) => this.SearchExp(e)}
                                                                               placeholder="Başlık..." required/>
                                                                    </div>

                                                                    {
                                                                        !this.state.expertise_history.length > 0 ?
                                                                            <>
                                                                                <SelectList table='com_brand'
                                                                                            label={'Takas Araç Markası'}
                                                                                            name={'id_com_brand'}
                                                                                            onChange={(x) => this.setState({id_com_swap_car_brand: x.target.value})}/>
                                                                                <SelectList table='com_model'
                                                                                            id_com_brand={this.state.id_com_swap_car_brand}
                                                                                            label={'Takas Araç Modeli'}
                                                                                            name={'id_com_model'}/>
                                                                                <div className="form-group">
                                                                                    <label>Model Yılı</label>
                                                                                    <input type="text"
                                                                                           className="form-control"
                                                                                           name="model_year"
                                                                                           placeholder="Başlık..."
                                                                                           required/>
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <label>Şase</label>
                                                                                    <input type="text"
                                                                                           className="form-control"
                                                                                           name="chasis"
                                                                                           placeholder="Başlık..."/>
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <label>Araç Özellikleri / Spec </label>
                                                                                    <input type="text"
                                                                                           className="form-control"
                                                                                           name="spec"
                                                                                           placeholder="Başlık..."
                                                                                           required/>
                                                                                </div>
                                                                                <SelectList table='com_gear'
                                                                                            label={'Vites Tipi'}
                                                                                            name={'id_com_gear'}/>
                                                                                <SelectList table='com_fuel'
                                                                                            label={'Yakıt Tipi'}
                                                                                            name={'id_com_fuel'}/>
                                                                                <SelectList table='com_color' label={'Renk'}
                                                                                            name={'id_com_color'}/>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <h3 className='text-center'>Ekspertiz
                                                                                    Listesi </h3>

                                                                                <table
                                                                                    className="table table-bordered table-striped">
                                                                                    <thead>
                                                                                    <tr className="active">
                                                                                        <th width="80">Talep Tarihi</th>
                                                                                        <th>Marka</th>
                                                                                        <th>Model</th>
                                                                                        <th>Spec</th>
                                                                                        <th>Yakıt / Vites</th>
                                                                                        <th>Model Yılı</th>
                                                                                        <th>Km</th>
                                                                                        <th>Km</th>
                                                                                        <th>Talep Fiyat</th>
                                                                                        <th>Teklif</th>
                                                                                        <th>Teklif Tarihi</th>
                                                                                        <th>Durum</th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                    {this.state.expertise_history.map((row, index) => {
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td>{row.c_date} </td>
                                                                                                <td>{row.id_com_brand_name}</td>
                                                                                                <td>{row.id_com_model_name}</td>
                                                                                                <td>{row.spec}</td>
                                                                                                <td>{row.id_com_fuel_name + ' / ' + row.id_com_gear_name}</td>
                                                                                                <td>{row.model_year}</td>
                                                                                                <td>{row.license_plate}</td>
                                                                                                <td>{row.km}</td>
                                                                                                <td>{row.demand_price}</td>
                                                                                                <td>{row.offer_price}</td>
                                                                                                <td>{row.m_date}</td>
                                                                                                <td>{row.id_com_expertise_status_name}</td>
                                                                                            </tr>)
                                                                                    })}
                                                                                    </tbody>
                                                                                </table>
                                                                            </>
                                                                    }
                                                                    <div className="form-group">
                                                                        <label>Kilometre</label>
                                                                        <input type="text" className="form-control"
                                                                               name="km" placeholder="Başlık..." required/>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Talep Edilen Fiyat</label>
                                                                        <input type="text" className="form-control"
                                                                               name="demand_price" placeholder="Başlık..."
                                                                               required/>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Hasar Bilgisi </label>
                                                                        <input type="text" className="form-control"
                                                                               name="comment" placeholder="Başlık..."
                                                                               required/>
                                                                    </div>
                                                                    <button
                                                                        type='submit'
                                                                        value="Submit"
                                                                        className="btn btn-primary pull-right"
                                                                        //  onClick={()=>this.handleSubmit()}
                                                                        // onClick={()=>console.log(this.state)}
                                                                    >Talep Gönder
                                                                    </button>
                                                                </form>
                                                            }
                                                        </div>
                                                    </div>
                                                    : <></>}
                                                <div className="form-group">
                                                    <label>Not : </label>
                                                    <textarea type="text" style={{height: 200}} row='20'
                                                              className="form-control" name="content"
                                                              onChange={(x) => this.handleChange(x)}/>
                                                </div>
                                                {this.state.questionsData ?
                                                    <div className={"form-group"}>
                                                        <button
                                                            type="button" className="btn btn-danger"
                                                            data-toggle="modal" data-target="#pollModal">
                                                            {this.state.questionsData.title}
                                                        </button>
                                                    </div>
                                                    : null
                                                }
                                                <SelectList table='com_meet' label={'Temas Türü'} name={'id_com_meet'}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                {this.state.body_data.id_com_interview_type === '5' ?
                                                    <SelectList table='com_insurance_expert'
                                                                value={this.state.body_data.id_com_insurance_expert}
                                                                label={'Atanan Eksper'} name={'id_com_insurance_expert'}
                                                                onChange={(x) => this.handleChange(x)}/>
                                                    : <></>
                                                }

                                                {this.state.body_data.id_com_interview_type === '7' ?
                                                <SelectList table='com_interview_status' bySubject={true} id_com_interview_subject={this.state.body_data.id_com_interview_subject}
                                                            id_com_interview_type={this.state.body_data.id_com_interview_type}
                                                            label={'Durum'} name={'id_com_interview_status'}
                                                            onChange={(x) => this.handleChange(x)}/>:
                                                <SelectList table='com_interview_status' bySubject={false}  
                                                            id_com_interview_type={this.state.body_data.id_com_interview_type}
                                                            label={'Durum'} name={'id_com_interview_status'}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                }
                                                
                                                {this.state.id_com_interview_status === '34'||this.state.id_com_interview_status === '78'||this.state.id_com_interview_status === '100'||this.state.id_com_interview_status === '111' ?
                                                    
                                                    this.state.car_data.length > 0 ?
                                                        <SelectList table='view_cars_sl'
                                                                    id_com_customer={this.state.body_data.id_com_customer}
                                                                    label={'Arac Seçiniz'} name={'id_com_cars'}
                                                                    onChange={(x) => this.handleChange(x)}/>
                                                        :
                                                        <div className="col-md-12"
                                                             dangerouslySetInnerHTML={{__html: alertMessage('NoCarListed')}}>
                                                                   
                                                             </div>:<></>
                                                }

                                                {this.state.id_com_interview_status === '126'||
                                                this.state.id_com_interview_status === '135'||
                                                this.state.id_com_interview_status === '136'||
                                                this.state.id_com_interview_status === '144'||
                                                this.state.id_com_interview_status === '153'  ?

                                                   <>
                                                    {this.state.body_data.id_com_interview_subject==='28'||this.state.body_data.id_com_interview_subject==='5'?
                                                    <SelectList table='com_insurance_company'
                                                                    label={'Trafik Sigorta Firması Seçiniz  | Şentürkten yapıldıysa boş geçiniz'} name={'id_com_insurance_company_traffic'}
                                                                    onChange={(x) => this.handleChange(x)}/>   :<></>
                                                    }
                                                        

                                                         <SelectList table='com_insurance_company'
                                                                    label={
                                                                        this.state.body_data.id_com_interview_subject==='28'||this.state.body_data.id_com_interview_subject==='5'?
                                                                        'Kasko Sigorta Firması Seçiniz  | Şentürkten yapıldıysa boş geçiniz':
                                                                        'Sigorta Firması Seçiniz '
                                                                        } name={'id_com_insurance_company_kasko'}
                                                                    onChange={(x) => this.handleChange(x)}/>  
                                                    </>:<></>
                                                }
                                                 <div className="form-group">
                                                    {this.state.alert_data.length > 0 ?
                                                        <table className="table table-bordered">
                                                            <thead>
                                                            <tr>
                                                                <th width='150'>Hatırlatma Tarihi</th>
                                                                <th width='200'>Görüşme Notu:</th>
                                                                <th width='50'>Kontrol</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.alert_data.map((row, index) =>
                                                                <tr key={index}>
                                                                    <td>{row.alert_date} </td>
                                                                    <td>{row.comment} </td>
                                                                    <td>
                                                                        <button
                                                                            disabled={this.props.id_com_user === row.id_com_user ? false : true}
                                                                            className="btn btn-danger btn-sm"
                                                                            onClick={() => this.CloseAlert(row.id)}>Kapat
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )}

                                                            </tbody>
                                                        </table>
                                                        : <AlertAdd id_com_interview={this.state.id_com_interview}/>}
                                                </div>
                                                <div className="form-group">
                                                    <button className="btn btn-primary pull-right"
                                                            disabled={this.state.id_com_interview_status === '' ? true : false}
                                                            onClick={() => this.handleSubmit()}
                                                        // onClick={()=>console.log(this.state)}
                                                    >Görüşmeyi Kaydet
                                                    </button>
                                                </div>
                                            </div>
                                            : <></>}
                                        <div className="panel-footer text-center">
                                            Toplam {this.state.lines_data.length} adet satır bulundu.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="pollModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog modal-lg" role="document">
                        <form onSubmit={this.pollFormSubmit}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                    {this.state.questionsData?
                                        <h4 className="modal-title" id="myModalLabel">{this.state.questionsData.title}</h4>
                                        :
                                        null
                                    }
                                </div>
                                <div className="modal-body">
                                    {this.state.questionsData?
                                        <React.Fragment>
                                            <input type={"hidden"} name={"id_com_poll"} value={this.state.questionsData.id} />
                                            {
                                                this.state.questionsData.questions.map((question,key) => {
                                                    if (question.type == 'radio'){
                                                        return(
                                                            <div className={"form-group"} key={key}>
                                                                <input type={"hidden"} name={"id_com_poll_questions[]"} value={question.id} />
                                                                <label>{question.question}</label>
                                                                <br />
                                                                <select className={"form-control"} name={"id_com_poll_options[]"}>
                                                                    {
                                                                        question.options.map((option,key) => {
                                                                            return(
                                                                                <option value={option.id} key={key}>{option.option}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                                <hr />
                                                            </div>
                                                        )
                                                    }else if(question.type == 'textarea'){
                                                        return(
                                                            <div className={"form-group"} key={key}>
                                                                <input type={"hidden"} name={"id_com_poll_questions[]"} value={question.id} />
                                                                <label>{question.question}</label>
                                                                <textarea className={"form-control"} name={"id_com_poll_options[]"} placeholder={question.options[0].option}></textarea>
                                                                <hr />
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </React.Fragment>
                                        :
                                        null
                                    }
                                    {this.state.pollData.id_com_poll?
                                        <div className={"alert alert-success text-center"}>
                                            <i className={"fas fa-check fa-2x"}></i>
                                            <p className={"lead"}>Anket başarıyla tamamlandı. Görüşme notunu kaydedip sonlandırabilirsiniz.</p>
                                            <br />
                                            <button type={"button"} className={"btn btn-success"} data-dismiss="modal">Kapat</button>
                                        </div>
                                        : null
                                    }
                                </div>
                                {this.state.questionsData ?
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default" data-dismiss="modal">İptal
                                        </button>
                                        <button className="btn btn-primary">Anketi Tamamla</button>
                                    </div>
                                    : null
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(InterviewDetail);

