
import React,{useEffect, useState} from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component'; 
 import moment from 'moment' 
import {  Col, Row, Spin,Typography,Button,Divider,Empty,Menu,Dropdown ,Modal,Tabs,Table,PageHeader ,Input,DatePicker,Select} from 'antd'; 
import ExportFromJson from '../utilities/jsontoexport';


const { Title ,Text} = Typography;

const ReportReception = ({api_url,loginToken}) => { 

    const [Data,SetData] = useState([])
    const [Days,SetDays] = useState([])
    const [Brands,SetBrands] = useState([])
    const [DetailData,SetDetailData] = useState([])
    const [Loading,setLoading]=useState(false);
    

    const [Meets,SetMeets] = useState([])
    const [Pageloader,SetPageloader] = useState(true)
    const today = moment(new Date).format('YYYY-MM-DD')
    const [Today,SetToday] =useState(today)//'2021-01-12')

    const customStyle = { 
        headRow: {
            style: {
              backgroundColor: 'black',
              minHeight: '80px',
              overflow:'visible',

            },
            denseStyle: {
                minHeight: '35px',
                overflow:'visible',

            },
          },
          headCells: {
            style: {
                fontSize:'16px',
                fontWeight: 700,
                color:'white',
                paddingLeft: '16px',
                paddingRight: '16px',
                overflow:'visible',

            },
          },
          cells: {
            style: {
                overflow:'visible', 
            },
        },
        rows: {
            style: {
                color:'rgba(6, 76, 138,.85)',
                 fontSize:'13px',
                borderStyle: 'solid',
                borderWidth: '1px',
                overflow:'visible',
                borderColor:'rgba(6, 76, 138,.15)',
                 '&:last-child': {
                    fontStyle:'normal',
                    overflow:'visible',
                    backgroundColor:'gray',
                    fontSize:'13px',
                    color:'white',
                    fontWeight: 700,
                 },
            },
        denseStyle: {
          minHeight: '35px',
          overflow:'visible',
        },
      }
    }


    const Columns_excel =[
        
        {
            name:'No',
            selector:'interview_number',
         },
         {
            name:'saat',
            selector:'c_time',
         },
        {
            name:'Marka',
            selector:'id_com_brand_name',
          },     
         {
            name:'Model',
            selector:'id_com_model_name',
          },

        {
            name:'Konu',
            selector:'id_com_interview_subject_name',
          },
 
        {
            name:'Temas',
            selector:'id_com_meet_name',
          },
         {
            name:'Temsilci',
            selector:'id_com_user_name',
          },
         
         {
            name:'Yönlendirildi',
            selector:'forwarded' 
         },
         {
            name:'Müşteri',
            selector:'username',
          },
         {
            name:'Telefon',
            selector:'phone',
          },
         {
            name:'Sonuç',
            selector:'id_com_interview_status_name',
          },
       
    ]
    

    const Columns = [
  
        {
            title:'No',
            dataIndex:'interview_number',
            align:'left',
        },
        {
            title:'Saat',
            dataIndex:'c_time',
            align:'center',
         },
        {
            title:'Marka',
            dataIndex:'id_com_brand_name',
            align:'center',
         },     
         {
            title:'Model',
            dataIndex:'id_com_model_name',
            align:'center',
         },

        {
            title:'Konu',
            dataIndex:'id_com_interview_subject_name',
            align:'center',
         },
 
        {
            title:'Temas',
            dataIndex:'id_com_meet_name',
            align:'center',
         },
         {
            title:'Temsilci',
            dataIndex:'id_com_user_name',
            align:'center',
         },
         
         {
            title:'Yönlendirildi',
             align:'center',
            render:(row,record,index)=> record.forwarded==='1'?'Evet':'Hayır'
         },
         {
            title:'Müşteri',
            dataIndex:'username',
            align:'left',
         },
         {
            title:'Telefon',
            dataIndex:'phone',
            align:'center',
         },
         {
            title:'Sonuç',
            dataIndex:'id_com_interview_status_name',
            align:'center',
         },
         {
            title:'Detay',
            align:'center',
            render:(row,record)=><Button type='primary' shape='round' size='small' target='_blank' href={'/interview/Detail/'+record.id}>Git</Button>
            
         },
    ]


    const columns = [
            {
                name: 'Temsilci',
                selector: 'id_com_user_name',
                left:true,
                wrap:true,
                width:'150px',
            },
            {
                name: 'Temas Türü',
                selector: 'id_com_meet_name',
                center:true,
                wrap:true,
            },
            {
                name: 'Adet',
                selector: 'adet',
                center:true,
                wrap:true,
            },
            {
                name: 'Yönlendirilen',
                selector: 'forwarded',
                center:true,
                wrap:true,
            },
            {
                name: 'Açık',
                selector: 'status_0',
                center:true,
                wrap:true,
            },
            {
                name: 'Kapalı',
                selector: 'status_1',
                right:true,
                wrap:true,
            },
    ]

    useEffect(() => {
        const FetchData = () =>{
            _GetData(Today);
        }
        return FetchData()
    }, [])

const Empty = () => 
   
        <div className="alert alert-info" role="alert">
            <h4 className="alert-heading text-center ">{moment(Today).format('DD.MM.YYYY')} tarihinde kayıt bulunamamıştır.</h4>
            <p className="text-center">Lütfen Daha Sonradan Yeniden Deneyin.</p>
          </div>
     

    const _GetData = async (date)=>{
        setLoading(true)
        SetToday(date)
        const url = api_url+'/selectrows/view_interview_report_reception1';
        const body =  'type=user&token='+loginToken;
        const config = {     method:'POST',   cahce: 'no-cache',   headers:{   'Content-Type':'application/x-www-form-urlencoded'   },  body:body,  }
        const result = await( await   fetch(url,config).catch(error=>console.log(error))).json();
        const data =  result.filter(row=>row.c_date===date)

        const url1 = api_url+'/selectrows/view_interview_report_reception_detail';
        const body1 =  'type=user&token='+loginToken;
        const config1 = {     method:'POST',   cahce: 'no-cache',   headers:{   'Content-Type':'application/x-www-form-urlencoded'   },  body:body1,  }
        const result1 = await( await   fetch(url1,config1).catch(error=>console.log(error))).json();
        const data1=  result1.filter(row=>row.c_date===date)



        let days  = [];
        let brands = [];
        let meets = [];
        result.map(day=>  days.push(day.c_date))

        data.map(row => {
            brands.push(row.id_com_brand_name)
            meets.push(row.id_com_meet_name)
        });

        SetDays([...new Set(days)])
        SetBrands([...new Set(brands)])
        SetMeets([...new Set(meets)])
        SetData(data);
        console.log(data1[0])
        SetDetailData(data1);
        SetPageloader(false);
        setLoading(false)

    }


const HeadRenderer = ()=>  {

    const HeadData_brands =[];
    const HeadData_meets =[];

    Brands.map((brand,index)=>{
        let filtered_data  = Data.filter(row=>row.id_com_brand_name===brand);
        HeadData_brands.push({
        id_com_user_name:'-',
        id_com_meet_name:brand,
        status_1:filtered_data.reduce((a,b)=>a+parseFloat(b.status_1),0),
        status_0:filtered_data.reduce((a,b)=>a+parseFloat(b.status_0),0),
        forwarded:filtered_data.reduce((a,b)=>a+parseFloat(b.forwarded),0),
        adet:filtered_data.reduce((a,b)=>a+parseFloat(b.adet),0),
    })

    })
    HeadData_brands.push({
        c_date:'',
        id_com_brand:'all',
        id_com_user: "0",
        id_com_user_name:'Toplam',
        id_com_meet:'all',
        id_com_meet_name:'-',
        status_1:HeadData_brands.reduce((a,b)=>a+parseFloat(b.status_1),0),
        status_0:HeadData_brands.reduce((a,b)=>a+parseFloat(b.status_0),0),
        forwarded:HeadData_brands.reduce((a,b)=>a+parseFloat(b.forwarded),0),
        adet:HeadData_brands.reduce((a,b)=>a+parseFloat(b.adet),0),
    })

    Meets.map((meet,index)=>{
        let filtered_data2  = Data.filter(row=>row.id_com_meet_name===meet);
        HeadData_meets.push({
        id_com_user_name:'-',
        id_com_meet_name:meet,
        status_1:filtered_data2.reduce((a,b)=>a+parseFloat(b.status_1),0),
        status_0:filtered_data2.reduce((a,b)=>a+parseFloat(b.status_0),0),
        forwarded:filtered_data2.reduce((a,b)=>a+parseFloat(b.forwarded),0),
        adet:filtered_data2.reduce((a,b)=>a+parseFloat(b.adet),0),
    })

    })
    HeadData_meets.push({
        c_date:'',
        id_com_brand:'all',
        id_com_user: "0",
        id_com_user_name:'Toplam',
        id_com_meet:'all',
        id_com_meet_name:'-',
        status_1:HeadData_meets.reduce((a,b)=>a+parseFloat(b.status_1),0),
        status_0:HeadData_meets.reduce((a,b)=>a+parseFloat(b.status_0),0),
        forwarded:HeadData_meets.reduce((a,b)=>a+parseFloat(b.forwarded),0),
        adet:HeadData_meets.reduce((a,b)=>a+parseFloat(b.adet),0),
    })
    return (
           <>
        <div key='0' className="row">
            <div className="col-md-12"  >
                <div className="panel">
                    <div className="panel-heading">
                        <h3 className="panel-title">Günlük Kayıtlar |  Tüm Markalar </h3>
                    </div>
                    <div className="panel-body">
                    <DataTable
                        customStyles={customStyle}
                        columns={columns}
                        data={HeadData_brands}
                        fixedHeader
                        noHeader={true}
                        highlightOnHover
                        responsive
                        striped
                        dense
                        noDataComponent={Loading?null:Empty()}
                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                        progressPending={Loading}
                    />
                    </div>

                </div>
            </div>
        </div>
        <div key='4' className="row">
            <div className="col-md-12"  >
                <div className="panel">
                    <div className="panel-heading">
                        <h3 className="panel-title">Günlük Kayıtlar |  Tüm Markalar </h3>
                    </div>
                    <div className="panel-body">
                    <DataTable
                        customStyles={customStyle}
                        columns={columns}
                        data={HeadData_meets}
                        fixedHeader
                        noHeader={true}
                        highlightOnHover
                        responsive
                        striped
                        dense
                        noDataComponent={Loading?null:Empty()}
                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                        progressPending={Loading}
                    />
                    </div>

                </div>
            </div>
        </div>
        </>
        )
    }
          
      

    return ( 
            <div className="main">
                <div className="main-content">
                    <div className="row"> 
                        <div className="col-md-12"  >
                            <div className="panel">
                                    <PageHeader title='Raporlar | '  subTitle='Günlük Resepsiyon Kayıtları'  
                                        extra={[
                                            <Row gutter={16} align='middle'>
                                                <Col>
                                                    <select className="form-control"   onChange={(e)=>{_GetData(e.target.value)}}  data-title="Seçiniz" data-live-search="true" value={Today}>
                                                        <option key={0} value={0}>{"Seçiniz"}</option>
                                                        {Days.reverse().map((row, index) =>
                                                            <option key={index} value={row}>{moment(row).format('DD.MM.YYYY')} </option>
                                                        )}
                                                    </select>
                                                </Col>
                                                <Col>
                                                     <ExportFromJson data={DetailData} fileName={`reception_list_${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}`} exportType={'xls'}/>
                                                 </Col>
                                            </Row>
                                        

                                        ]}
                                    />
 
                            </div>
                        </div>
                    
                       
                    </div>
                    {Data.length===0 && Empty()}
                    {HeadRenderer()}
                    {Brands.map((brand,index)=>{
                         let Filtered_data  = Data.filter(row=>row.id_com_brand_name===brand);
                                Filtered_data.push({
                                    c_date:'',
                                    id_com_brand:'all',
                                    id_com_user: "0",
                                    id_com_user_name:'Toplam',
                                    id_com_meet:'all',
                                    id_com_meet_name:'-',
                                    status_1:Filtered_data.reduce((a,b)=>a+parseFloat(b.status_1),0),
                                    status_0:Filtered_data.reduce((a,b)=>a+parseFloat(b.status_0),0),
                                    forwarded:Filtered_data.reduce((a,b)=>a+parseFloat(b.forwarded),0),
                                    adet:Filtered_data.reduce((a,b)=>a+parseFloat(b.adet),0),
                                })

                                const MeetData =[];

                                Meets.map((meet,index)=>{
                                    let filtered_meet_data  = Data.filter(row=>row.id_com_meet_name===meet&&row.id_com_brand_name===brand);
                                    MeetData.push({
                                    id_com_user_name:'-',
                                    id_com_meet_name:meet,
                                    status_1:filtered_meet_data.reduce((a,b)=>a+parseFloat(b.status_1),0),
                                    status_0:filtered_meet_data.reduce((a,b)=>a+parseFloat(b.status_0),0),
                                    forwarded:filtered_meet_data.reduce((a,b)=>a+parseFloat(b.forwarded),0),
                                    adet:filtered_meet_data.reduce((a,b)=>a+parseFloat(b.adet),0),
                                })

                                })
                                MeetData.push({
                                    c_date:'',
                                    id_com_brand:'all',
                                    id_com_user: "0",
                                    id_com_user_name:'Toplam',
                                    id_com_meet:'all',
                                    id_com_meet_name:'-',
                                    status_1:MeetData.reduce((a,b)=>a+parseFloat(b.status_1),0),
                                    status_0:MeetData.reduce((a,b)=>a+parseFloat(b.status_0),0),
                                    forwarded:MeetData.reduce((a,b)=>a+parseFloat(b.forwarded),0),
                                    adet:MeetData.reduce((a,b)=>a+parseFloat(b.adet),0),
                                })


 
                        return (   
                            <div  key={index} className="row">
                                <div className="col-md-12"  >
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h3 className="panel-title">Günlük Kayıtlar |  {brand } </h3>
                                        </div>
                                        <div className="panel-body">
                                        <DataTable
                                            customStyles={customStyle}
                                            columns={columns}
                                            data={Filtered_data}
                                            fixedHeader
                                            noHeader={true}
                                            highlightOnHover
                                            responsive
                                            striped
                                            dense
                                            noDataComponent={Loading?null:Empty()}
                                            paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                            progressPending={Loading}
                                        />
                                        </div>
                                        <div className="panel-body">
                                        <DataTable
                                            customStyles={customStyle}
                                            columns={columns}
                                            data={MeetData}
                                            fixedHeader
                                            noHeader={true}
                                            highlightOnHover
                                            responsive
                                            striped
                                            dense
                                            noDataComponent={Loading?null:Empty()}
                                            paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                            progressPending={Loading}
                                        />
                                        </div>

                                        <div className="panel-body">
                                            <Row>
                                                <Col span={24}  style={{padding:30}}>
                                                        <Table  dataSource={DetailData.filter(row=>row.id_com_brand_name===brand)} loading={Loading}
                                                        columns={Columns} size='small' rowKey={row=>row.id} pagination={{pageSize:10}} bordered 
                                                        footer={()=><Text code>{Data.length} adet kayıt bulunmaktadır.</Text>}/>
                                                </Col>
                                        </Row>
                                        </div>


                                    </div>
                                </div>
                            </div>
 
                         )
                    })}
                 
                </div>
            </div>

            )
}

export default connect(mapStateToProps)(ReportReception);
