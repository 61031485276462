import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
class StoreCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoader: false,
            stock_line_body: [],
            stored_quantity: 0,
            damaged: 0,
            missing: 0,
            id_com_depot: '',
            depot_part_number: ''
        }
        this._Update = this._Update.bind(this)
        this._Submit = this._Submit.bind(this)
    }
    _Submit() {
        let d = new Date();
        let date = d.toISOString();
        let m_date = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');
        let m_id_com_user = this.props.id_com_user;
        let missing = this.state.missing;
        let damaged = this.state.damaged;
        let stored_quantity = parseFloat(this.state.stored_quantity) - parseFloat(this.state.damaged) - parseFloat(this.state.missing);
        let id_depot_part_number = this.state.id_depot_part_number;
        let id = this.state.stock_line_body.id;
        let update =
            'id=' + id +
            '&m_date=' + m_date +
            '&m_time=' + m_time +
            '&m_id_com_user=' + m_id_com_user +
            '&missing=' + missing +
            '&damaged=' + damaged +
            '&stored_quantity=' + stored_quantity +
            '&id_depot_part_number=' + id_depot_part_number +
            '&token=' + this.props.loginToken + '&type=user&stored=1';
        let update_url = this.props.api_url + '/update/com_stock_lines'
        fetch(update_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: update,
        })
            .catch(error => console.log(error))
            .then(res => window.location.href = '/DepotStore')
    }
    componentDidMount() {
        this._Update();
    }
    async _Update() {
        let id_com_stock_lines = this.props.match.params.StoreId
        let id_com_depot = this.props.match.params.DepotId
        this.setState({
            id_com_stock_lines: this.props.match.params.StoreId,
            id_com_depot: this.props.match.params.DepotId
        })
        let url_stock_line = this.props.api_url + '/selectRows/view_stock_lines';
        let stock_line_body = 'id=' + id_com_stock_lines + '&token=' + this.props.loginToken + '&type=user&id_com_company=' + this.props.id_com_company + '&id_com_depot=' + id_com_depot;
        await fetch(url_stock_line, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: stock_line_body
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                this.setState({
                    stored_quantity: parseFloat(result[0].purchase_quantity),
                    stock_line_body: result[0],
                    pageloader: true
                })
            })
    }
    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="page-title">Fatura Detayı</h3>
                                </div>
                                <div className="col-md-12">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <div className="panel-title">Fatura Detayı</div>
                                        </div>
                                        <div className="panel-body">
                                            <table className="table table-bordered table-striped">
                                                <tbody>
                                                    <tr>
                                                        <th width="200">Fatura Bilgileri</th>
                                                        <td>{this.state.stock_line_body.invoice_date + ' / ' + this.state.stock_line_body.inv_number + ' / ' + this.state.stock_line_body.company_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Malzeme Bilgileri</th>
                                                        <td>{this.state.stock_line_body.id_com_product_class_name + ' / ' + this.state.stock_line_body.code + ' / ' + this.state.stock_line_body.id_com_product_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Alım Depo / Alım Adedi</th>
                                                        <td>{this.state.stock_line_body.id_com_depot_name + ' / ' + this.state.stock_line_body.purchase_quantity}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Alım Tutarı</th>
                                                        <td>{this.state.stock_line_body.purchase_price}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Ort Maliyeti</th>
                                                        <td>{(parseFloat(this.state.stock_line_body.stock_level) - parseFloat(this.state.stock_line_body.purchase_quantity)) > 0 ? this.state.stock_line_body.avg_cost : 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Stok Adedi</th>
                                                        <td>{parseFloat(this.state.stock_line_body.stock_level) - parseFloat(this.state.stock_line_body.purchase_quantity)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="form-group">
                                                <label>Hasarlı Adet</label>
                                                <input type="number" className="form-control" value={this.state.damaged} onChange={(x) => this.setState({damaged: x.target.value})}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Eksik Adet</label>
                                                <input type="number" className="form-control" value={this.state.missing} onChange={(x) => {
                                                    if (parseFloat(x.target.value) < 0) {
                                                        alert('Negatif değer alamaz!');
                                                        this.setState({missing: 0})
                                                    } else if (parseFloat(x.target.value) > parseFloat(this.state.stored_quantity)) {
                                                        alert('Kabul değerinden fazla olamaz!');
                                                        this.setState({missing: 0})
                                                    } else {
                                                        this.setState({missing: x.target.value})
                                                    }
                                                }}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Kabul Adedi</label>
                                                <input disabled={true} type="number" className="form-control" value={parseFloat(this.state.stored_quantity) - parseFloat(this.state.damaged) - parseFloat(this.state.missing)}/>
                                            </div>
                                            <div className="form-group">
                                                <SelectList id_com_depot={this.state.id_com_depot} Label="Depo Rafı" id_com_company={this.props.id_com_company} table='view_depot_selectList' onChange={(x) => this.setState({id_depot_part_number: x.id})} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <a className="btn btn-danger" href="/DepotStore">İptal</a> <button className="btn btn-primary" onClick={() => this._Submit()}>Kaydet</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(StoreCard);