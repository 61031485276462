import React,{useEffect,useState} from 'react';
import {  PageHeader,Col, Row, Spin,Typography,Button,Divider,Form,Modal,Descriptions,Input,DatePicker} from 'antd';
import DataTable from 'react-data-table-component';
import { RightOutlined ,CaretLeftFilled,  CloseOutlined, PlusCircleOutlined,   CheckCircleOutlined, EditOutlined} from '@ant-design/icons';
import {connect} from 'react-redux';
  import * as CurrencyFormat from 'react-currency-format';
import mapStateToProps from '../../../redux/mapStateToProps';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import moment from 'moment'; 
import ExportFromJson from '../../../components/utilities/jsontoexport';
 
const {  Text } = Typography;

class CreditDetail extends React.Component{
    constructor ( props ) { 
        super(props);
        this.state={
            line_data:[],
            modal_visible:false,
            modal_visible_edit:false,
            pageloader:false,
            no_line_data:true,
            line_columns:[
                {
                    name:'No',
                    selector:'line_no',
                    left:true,
                    width:'5em',
                    cell:(row,index)=>
                    <Text>{index+1} </Text> 
                },
                {
                    name:'Taksit Tarihi',
                    selector:'payment_date',
                    center:true,
                    width:'12em',
                    cell: row=>moment(row.payment_date).format('DD.MM.YYYY')
                },
                {
                    name:'Taksit Tutarı',
                    selector:'payment_amount',
                     center:true,
                    cell:(row,index)=>
                    <CurrencyFormat value={Math.round(parseFloat(row.payment_amount)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' '+row.id_com_currency_name}/>
                 },
                 {
                    name:'Anapara',
                    selector:'capital',
                    center:true,
                    cell:(row,index)=>
                    <CurrencyFormat value={Math.round(parseFloat(row.capital)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' '+row.id_com_currency_name}/>

                 },
                 {
                    name:'Faiz',
                    selector:'interest',
                    center:true,
                    cell:(row,index)=>
                           <CurrencyFormat value={Math.round(parseFloat(row.interest)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' '+row.id_com_currency_name}/>
                 },
                 {
                    name:'KKDF + BSMV ',
                    selector:'bsmv',
                    center:true,
                    cell:(row,index)=>
                    <CurrencyFormat value={Math.round(parseFloat(row.bsmv)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' '+row.id_com_currency_name}/>
                },
                //  {
                //     name:'BSMV',
                //     selector:'kkdf',

                //      center:true,
                //     cell:(row,index)=>
                //     <CurrencyFormat value={Math.round(parseFloat(row.kkdf)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' '+row.id_com_currency_name}/>
                //  },
        
                {
                    name:'Ödeme Durumu',
                    selector:'status',
                     right:true,
                    cell:(row,index)=>
                    <Text style={{color:row.status==='0'?'red':'black'}}>{row.status==='0'?'Ödenmedi':'Ödendi'}</Text>
                },
                {
                    name: 'Kontrol',
                    right:'true',
                     cell:(row,index)=>  <>

                            <Button type="primary" shape="round" size='small' style={{marginRight:1}} disabled={row.status==='1'?true:false} onClick={()=>this.setState({modal_visible:true,selected_line_id:row.id})}><RightOutlined /></Button> 
                            <Button type="primary" shape="round" size='small'  style={{marginRight:1}} danger disabled={row.status==='1'?true:false} onClick={()=>this.setState({modal_visible_edit:true,selected_line_id:row.id})}><EditOutlined /></Button> 

                            </>
                    } 
            ],

            columns:[
                {
                    name:'No',
                    selector:'line_no',
                    left:true,
                    width:'5em',
                    cell:(row,index)=>
                    <Text>{index+1} </Text> 
                },
                {
                    name:'Taksit Tarihi',
                    center:true,
                    width:'12em',
                     cell:(row,index)=>

                     <DatePicker  locale={locale} onChange={(x)=>{ this.state.line_data[index].payment_date=moment(x).format('YYYY-MM-DD')}} />


                },
                {
                    name:'Taksit Tutarı',
                     center:true,
                    cell:(row,index)=>
                            <Input size="small"  placeholder={'Taksit Tutarı Giriniz'}   onChange={(x)=>this.state.line_data[index].payment_amount=x.target.value}/>
                     
                 },
                 {
                    name:'Anapara',
                    center:true,
                    cell:(row,index)=>
                             <Input size="small"  placeholder={'Anapara Tutarı Giriniz'}   onChange={(x)=>this.state.line_data[index].capital=x.target.value}/>
                 },
                 {
                    name:'Faiz',
                    center:true,
                    cell:(row,index)=>
                             <Input size="small"  placeholder={'Faiz Tutarı Giriniz'}   onChange={(x)=>this.state.line_data[index].interest=x.target.value}/>
                 },
                 {
                    name:'BSMV',
                    center:true,
                    cell:(row,index)=>
                             <Input size="small"  placeholder={'BSMV Tutarı Giriniz'}   onChange={(x)=>this.state.line_data[index].kkdf=x.target.value}/>
                 },
                 {
                    name:'KKDF',
                     center:true,
                    cell:(row,index)=>
                             <Input size="small"  placeholder={'KKDF Tutarı Giriniz'}   onChange={(x)=>this.state.line_data[index].bsmv=x.target.value}/>
                 },
           
                {
                    name: 'Kontrol',
                    right:'true',
                    width:'5em',
                    cell:(row,index)=>  <>

                     
                                <Button type="primary" shape="round" size='small' danger onClick={()=>{
                                    let lines = this.state.line_data ;
                                   lines.splice(index,1);
                                    this.setState({line_data:[...lines]})

                                } }
                                
                                ><CloseOutlined /></Button> 

                      
                            </>
                    } 
            ]
        }
        this.Submit=this.Submit.bind(this)
    }

    Submit(){
        let d = new Date(); 
        let date = d.toISOString();
        let c_date  = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let id_com_credits = this.props.match.params.id;
        let insert_url=this.props.api_url+'/insert/com_credits_line';
        this.state.line_data.map((row,index)=>{
     
try{
    let insert_body = 'token='+this.props.loginToken+
    '&id_com_credits='+id_com_credits+
    '&c_date='+c_date+
    '&c_time='+c_time+
    '&id_com_user='+this.props.id_com_user+
    '&id_com_bank='+this.state.data.id_com_bank+
    '&line_no='+(index+1)+
    '&payment_date='+row.payment_date+
    '&payment_amount='+row.payment_amount+
    '&id_com_currency='+this.state.data.id_com_currency+
    '&kkdf='+row.kkdf+
    '&interest='+row.interest+
    '&bsmv='+row.bsmv+
    '&capital='+row.capital+
    '&type=user&status=0';
 console.log(insert_body)
    fetch(insert_url,{
        method:'POST',
        cahce: 'no-cache',
        headers:{ 
            'Content-Type':'application/x-www-form-urlencoded'
        },
        body:  insert_body
    }).then(x=>window.location.reload())
}
catch(e){console.log(e)}
finally{
    console.log('line inserted!')
}
           
return null
        })



    }


    componentDidMount(){
    let id_com_credits = this.props.match.params.id;
    let select_url=this.props.api_url+'/selectRows/view_credits';
    let credits_line_url=this.props.api_url+'/selectRows/view_credits_line';

    fetch(select_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body:  'token='+this.props.loginToken+'&id='+id_com_credits+'&type=user'
        })
        .catch(error => console.log(error))
        .then(res=>res.json())
        .then(result=>{
            for (let [key,value] of Object.entries(result[0])) {
                   this.setState({[`${key}`]:value})
               }
            this.setState({data:result[0],pageloader:true})

        })
        fetch(credits_line_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body:  'token='+this.props.loginToken+'&id_com_credits='+id_com_credits+'&type=user'
        })
        .catch(error => console.log(error))
        .then(res=>res.json())
        .then(result=>{
            this.setState({line_data:result,pageloader:true})

            if(result.length>0) {
                this.setState({no_line_data:false})
            }

        })
    }



    render () { 
        const rowStyle = {
            width: '100%',
            display: 'flex',
            flexFlow: 'row wrap',
          };
          const colStyle = {
            marginBottom: '16px',
            padding:20
          };
          const gutter = 16;
        
        return ( 
           <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">

                <Row style={rowStyle} gutter={gutter} justify="start">
                    <Col md={24} sm={24} xs={24} style={{marginBottom: '5px',}}>
                        <PageHeader title={'Krediler |'} subTitle={'Detay'}  row_data={this.state.data}/>  
                    </Col>
                    <Col md={24} sm={24} xs={24} style={colStyle}>
                    <Descriptions bordered  size={'small'}  column={3}   >
                    <Descriptions.Item label="Sözleşme No">{this.state.credit_number}</Descriptions.Item>
                    <Descriptions.Item label="Banka Adı">{ this.state.id_com_bank_name}</Descriptions.Item>
                    <Descriptions.Item label="Başlangıç Tarihi">{moment(this.state.start_date).format('DD.MM.YYYY') }</Descriptions.Item>
                    <Descriptions.Item label="Kredi Tipi">{this.state.id_com_credit_type_name}</Descriptions.Item>
                    <Descriptions.Item label="Kredi Tutarı" span={2}> 
                    <CurrencyFormat value={Math.round(parseFloat(this.state.amount)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' '+this.state.id_com_currency_name}/>
                    </Descriptions.Item>
                    <Descriptions.Item label="Vade"  >{this.state.period}</Descriptions.Item>
                    <Descriptions.Item label="Faiz Oranı"  >{this.state.interest}</Descriptions.Item>
                    <Descriptions.Item label="Kredi Ödeme Tipi">{this.state.id_com_credit_payment_type_name}</Descriptions.Item>
                    <Descriptions.Item label="Ödenen Taksit Sayısı"  >{this.state.paid_period}</Descriptions.Item>
                    <Descriptions.Item label="Kalan Taksit Sayısı"  >{this.state.remaining_period}</Descriptions.Item>
                    <Descriptions.Item label="Toplam Geri Ödeme">
                    <CurrencyFormat value={Math.round(parseFloat(this.state.total_return)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' '+this.state.id_com_currency_name}/>

                    </Descriptions.Item>
    </Descriptions> 
                    </Col>
                    <Col md={24} sm={24} xs={24} style={colStyle}>
                     <DataTable
                    style={{minHeight:350}}
                                                dense 
                            noHeader
                            highlightOnHover    
                            selectableRowsHighlight	
                            paginationComponentOptions={ {rowsPerPageText: 'Sayfa Başına Satır Sayısı:', rangeSeparatorText: ' -', noRowsPerPage: false, selectAllRowsItem: true, selectAllRowsItemText: 'Tümü' }}
                            columns={this.state.no_line_data?this.state.columns:this.state.line_columns}
                            data={ this.state.line_data}
                            progressPending={!this.state.pageloader}
                            noDataComponent={<Text>Satır Eklemek İçin Artı Düğmesine Basınız.</Text>}
                             progressComponent={<Spin size='large' style={{margin:20}}/>}
                            subHeader
                            subHeaderAlign='right'
                            subHeaderComponent={
                                <>
                                    {this.state.no_line_data? 
                                        <>
                                   { this.state.SaveLine? <Button type='primary' style={{backgroundColor:'#32a852',color:'white',margin:5}} shape='round' size='small' onClick={()=>this.Submit()}><CheckCircleOutlined/> Kaydet</Button>:<></>}
                                    <Button type='primary' shape='round' size='small' onClick={()=>{ let lines=this.state.line_data ; lines.push({}); this.setState({SaveLine:true,line_data:[...lines]})}}><PlusCircleOutlined/></Button>
                                        </>
                                        : 
                                    <ExportFromJson data={this.state.line_data} fileName={`Kredi_Odeme_Hareketler_${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}`} exportType={'xls'}/>

                                    }
                                </>
                            }
                            />
 
                    </Col>

                          </Row>
                          <Divider/>
                <Row   justify="space-between">
                    <Col  style={colStyle}>
                        <Button 
                        shape="round" size='small'
                        danger type="primary" href='/credit/list'>
                        <CaretLeftFilled size={24}/> Geri
                        </Button> 
                    </Col>
                </Row>

            <PaymentModal 
            close={()=>this.setState({modal_visible:false,selected_line_id:''})} 
            api_url={this.props.api_url}
            id_com_user={this.props.id_com_user} 
            modal_visible={this.state.modal_visible} 
            id={this.state.selected_line_id}
             loginToken={this.props.loginToken}/>

            <EditModal 
            close={()=>this.setState({modal_visible_edit:false,selected_line_id:''})} 
            api_url={this.props.api_url}
            id_com_user={this.props.id_com_user} 
            modal_visible={this.state.modal_visible_edit} 
            id={this.state.selected_line_id}
             loginToken={this.props.loginToken}/>
                      </div>

                     </div>

                     </div>

                     </div>
                     </div>
                     </div>

        )
    }
}


export default connect(mapStateToProps)(CreditDetail);



class PaymentModal extends React.Component{
    constructor ( props ) { 
        super(props);
        this.state={}
        this.handleOk=this.handleOk.bind(this)
    }


    handleChange(event,selectlist,name) {
        if(selectlist) { 
            this.setState({ [`${name}`]:event})
            // console.log('id changed')
        } else { 
            const value = event.target.value;
            const key = event.target.name;
            this.setState({ [`${key}`]:value})
        }
      }


  handleOk(){
    let d = new Date(); 
    let date = d.toISOString();
    let m_date  = date.split('T')[0];
    let m_time = d.toLocaleTimeString('tr-TR')

    let update_body = 
    'token='+this.props.loginToken+
    '&id='+this.props.id+
    '&m_id_com_user='+this.props.id_com_user+
    '&m_date='+m_date+
    '&m_time='+m_time+
    '&payment_no='+this.state.payment_no+
    '&paid_amount='+this.state.paid_amount+
    '&type=user&status=1';
let com_credits_line_url=this.props.api_url+'/update/com_credits_line';
fetch(com_credits_line_url,{
        method:'POST',
        cahce: 'no-cache',
        headers:{ 
          'Content-Type':'application/x-www-form-urlencoded'
        },
      body:update_body,
    })
    .catch(error=>console.log(error)).then(x=>{ 
    window.location.reload()
})
  
}
  
    render(){
        return ( 
            <Modal
            visible={this.props.modal_visible}
            title="Ödeme Detayı"
            onOk={this.props.close}
            onCancel={this.props.close}
            footer={[
              <Button key="back" onClick={this.props.close}>
                Kapat
              </Button>,
              <Button key="submit" 
              disabled={this.state.payment_no&&this.state.paid_amount?false:true}
              type="primary" onClick={()=>this.handleOk()}>
                Kaydet
              </Button>,
            ]}
          >
            <Form.Item  colon labelAlign='left' labelCol={{span:7 }}    hasFeedback label='Fiş No'      >
            <Input size="large"  name={'payment_no'} value={this.state.payment_no}   placeholder={'Fiş No Giriniz'} onChange={(x)=>this.handleChange(x)}/>
            </Form.Item>
            <Form.Item  colon labelAlign='left' labelCol={{span:7 }}    hasFeedback label='Tutar'      >
            <Input size="large"  name={'paid_amount'} value={this.state.paid_amount}   placeholder={'Tutar Giriniz'} onChange={(x)=>this.handleChange(x)}/>
            </Form.Item>
          </Modal>
        )
    }
}

 
const  EditModal = ({close,api_url,id_com_user,modal_visible,id,loginToken})=> {
  const [editForm] =Form.useForm();
  const  handleChange = (event,selectlist,name) => {
        if(selectlist) { 
            this.setState({ [`${name}`]:event,disabled:false})
        } else { 
            const value = event.target.value;
            const key = event.target.name;
            this.setState({ [`${key}`]:value,disabled:false})   
        }
      }

    useEffect(() => {
        const OnLoad = ()=>{
            _Update()
        }
        return  OnLoad()
    }, [id])

 
    const _Update = async ()=> {
            console.log(id)
 if(id!==undefined){
     let initial_values = {};
     let com_credits_line_url= api_url+'/selectRows/com_credits_line';
       const data = await (await fetch(com_credits_line_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
              body: 'token='+ loginToken+'&id='+id+'&type=user',
            })).json() 

        if(data.length>0) {
            for (let [key, value] of Object.entries(data[0])) {
                        if(key==='payment_date'){
                            Object.assign(initial_values,{[key]:moment(value)})
                        } else { 
                            Object.assign(initial_values,{[key]:value})
                        }
                    }
                }
            editForm.setFieldsValue(initial_values)
         }
    } 


  const handleOk = async () => {

    let d = new Date(); 
    let date = d.toISOString();
    let m_date  = date.split('T')[0];
    let m_time = d.toLocaleTimeString('tr-TR')

    let update_body = 
    'token='+loginToken+
    '&id='+id+
    '&m_id_com_user='+id_com_user+
    '&m_date='+m_date+
    '&m_time='+m_time+
    '&type=user';

    let edit_form = editForm.getFieldsValue();

    for (let [key, value] of Object.entries(edit_form)) {
        if(key==='payment_date'){
            update_body += `&${key}=${moment(value).format('YYYY-DD-MM')}`
        } else { 
            update_body += `&${key}=${value}`
        }
    }

let com_credits_line_url=api_url+'/update/com_credits_line';

const response = await (await fetch(com_credits_line_url,{
        method:'POST',
        cahce: 'no-cache',
        headers:{ 
          'Content-Type':'application/x-www-form-urlencoded'
        },
      body:update_body,
    })).json() 

  response &&  window.location.reload()
 
  
}
   
        return ( 
            <Modal
            visible={modal_visible}
            title={"Taksit Detayı  : "+id}
            onCancel={close}
            footer={[
              <Button key="back" onClick={close}>
                Kapat
              </Button>,
              <Button key="submit" 
               type="primary" onClick={ handleOk }>
                Güncelle
              </Button>,
            ]}
          >
            <Form form={editForm}>
                <Form.Item   name='payment_date' labelAlign='left' labelCol={{span:7 }}   label='Taksit Tarihi'      >
                    <DatePicker  locale={locale}  style={{width:'100%'}}  format='DD.MM.YYYY'/>
                </Form.Item>
                <Form.Item   name={'payment_amount'}  labelAlign='left' labelCol={{span:7 }}      label='Taksit Tutarı'      >
                    <Input    type='number' />
                </Form.Item>
                <Form.Item   name={'capital'} labelAlign='left' labelCol={{span:7 }}      label='Anapara Tutarı'      >
                    <Input  type='number' />
                </Form.Item>
                <Form.Item  name={'interest'} labelAlign='left' labelCol={{span:7 }}      label='Faiz Tutarı'      >
                    <Input    type='number'/>
                </Form.Item>
                <Form.Item  name={'bsmv'} labelAlign='left' labelCol={{span:7 }}      label='BSMV + KKDF'      >
                    <Input    type='number' />
                </Form.Item>
            </Form>
          </Modal>
        )
    }
 