
export const _Menu_Toggle = () => { 
 return {
type : 'MENU_TOGGLE'
 }
}


export const _Remove_Constant = () => { 
      return {
     type : 'REMOVE_CONSTANT'
      }
     }

export const _Add_Login_Token = (token)=>  { 
return { 
type: 'ADD_LOGIN_TOKEN',
token:token
}
}
export const _Add_Username = (username)=>  {
      return {
            type: 'ADD_USERNAME',
            username:username
      }
}
export const _Add_Id_Com_User = (id_com_user)=>  { 
      return { 
      type: 'ADD_ID_COM_USER',
      id_com_user:id_com_user
      }
      }     

export const _Add_Id_Com_User_Type = (id_com_user_type)=>  { 
      return { 
      type: 'ADD_ID_COM_USER_TYPE',
      id_com_user_type:id_com_user_type
      }
      }     
      
export const _Add_Id_Com_Brand = (id_com_brand)=>  { 
      return { 
      type: 'ADD_ID_COM_BRAND',
      id_com_brand:id_com_brand
      }
      }     
export const _Add_Id_Com_Department = (id_com_department)=>  { 
      return { 
      type: 'ADD_ID_COM_DEPARTMENT',
      id_com_department:id_com_department
      }
      }     
                                              
export const _Add_Id_Com_Company = (id_com_company)=>  { 
      return { 
      type: 'ADD_ID_COM_COMPANY',
      id_com_company:id_com_company
      }
      } 
export const _Add_Password = (password)=>  { 
      return { 
      type: 'ADD_PASSWORD',
      password:password
      }
      } 
export const _Add_Email = (email)=>  { 
      return { 
      type: 'ADD_EMAIL',
      email:email
      }
      } 

export const _Log_Out = ()=>  { 
      return { 
      type: 'LOG_OUT',
      }
      }      

export const _Log_Status_Change = ()=>  { 
      return { 
      type: 'LOGGED_CHANGE'
      }
      }     

