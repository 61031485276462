import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
import DatePicker from '../utilities/DatePicker';

class ProductionAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ProductionId: '',
            ProductionNumber: '',
            comment: '',
            searchProduct: '',
            ProductList: [],
            productLoaded: false,
            invItems: [],
            Production_Quantity: 0,
            linesLoaded: false,
            ProductionLines: [],
            total_cost: 0,
            list_price: '',
            delivery_date: '',
            id_com_depot: '',
            stock_control: true

        };
        this._SearchProduct = this._SearchProduct.bind(this)
        this._Calculate = this._Calculate.bind(this)
        this._excludeItem = this._excludeItem.bind(this)
        this._includeItem = this._includeItem.bind(this)
        this._Submit = this._Submit.bind(this)
        this._Stock_Control = this._Stock_Control.bind(this)
    }

    _Submit() {
        let d = new Date();
        let date = d.toISOString();
        let c_date = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let c_id_com_user = this.props.id_com_user;
        let id_com_company = this.props.id_com_company;
        let production_number = this.state.ProductionNumber;
        let quantity = this.state.Production_Quantity;
        let id_com_set = this.state.ProductionId;
        let delivery_date = this.state.delivery_date;
        let comment = this.state.comment;

        let body = 'id_com_set=' + id_com_set +
            '&production_number=' + production_number +
            '&quantity=' + quantity +
            '&id_com_depot=' + this.state.id_com_depot +
            '&c_date=' + c_date +
            '&c_time=' + c_time +
            '&c_id_com_user=' + c_id_com_user +
            '&id_com_company=' + id_com_company +
            '&delivery_date=' + delivery_date +
            '&comment=' + comment +
            '&token=' + this.props.loginToken + '&type=user&id_com_production_status=1';
        let body_url = this.props.api_url + '/insert/com_production'
        let line_url = this.props.api_url + '/insert/com_production_lines'
        //let stock_url = this.props.api_url+'/insert/com_stock_lines'

        fetch(body_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                // 'Content-Type': 'application/json'
            },
            body: body,
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                const id_com_production = result.id;

                this.state.ProductionLines.map(row => {
                    let id_com_product = row.id;
                    let demand = Math.round(parseFloat(row.quantity) * parseFloat(this.state.Production_Quantity) * 100) / 100;
                    let avg_cost = row.avg_cost;

                    let line_body =
                        'id_com_production=' + id_com_production +
                        '&id_com_set=' + id_com_set +
                        '&id_com_product=' + id_com_product +
                        '&demand=' + demand +
                        '&avg_cost=' + avg_cost +
                        '&id_com_company=' + id_com_company +
                        '&token=' + this.props.loginToken + '&type=user&completed=0';

                    fetch(line_url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                            // 'Content-Type': 'application/json' 
                        },
                        body: line_body,
                    }).catch(error => console.log(error))

                    let stock_url = this.props.api_url + '/insert/com_stock_lines'

                    fetch(stock_url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                            // 'Content-Type': 'application/json' 
                        },
                        body: 'c_date=' + c_date +
                            '&doc_date=' + c_date +
                            '&id_com_depot=' + this.state.id_com_depot +
                            '&c_id_com_user=' + c_id_com_user +
                            '&id_com_production=' + id_com_production +
                            '&c_time=' + c_time +
                            '&production_number=' + production_number +
                            '&id_com_product=' + row.id_com_product +
                            '&id_com_company=' + this.props.id_com_company +
                            '&sale_quantity=' + demand +
                            '&sale_price=0' +
                            '&token=' + this.props.loginToken +
                            '&type=user&purchase_quantity=0&purchase_price=0&stored=0&id_depot_part_number=0',
                    })
                        .catch(error => console.log(error))


                })
                window.location.href = '/ProductionList'

            })

    }


    async _Stock_Control(value) {

        let control_array = []
        this.state.ProductionLines.map(row => {
            Math.round(parseFloat(row.quantity) * parseFloat(value) * 100) / 100 > row.stock_level ?
                control_array.push({
                    id: row.id_com_product,
                    status: false
                }) : control_array.push({row: row.id_com_product, status: true})
        })
        console.log(control_array)
        let status = control_array.filter(row => row.status === false)
        await this.setState({stock_control: status.length > 0 ? true : false})
    }


    componentDidMount() {
        // this.setState({ProductionId:this.props.match.params.ProductionId})

        let number_url = this.props.api_url + '/selectRows/com_production';
        let date = new Date();
        let yyyy = date.getFullYear();

        fetch(number_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_company=' + this.props.id_com_company + '&token=' + this.props.loginToken + '&type=user'
        }).catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {

                let id_com_company = parseInt(this.props.id_com_company)
                let company_number =
                    id_com_company >= 9999999 ?
                        id_com_company :
                        id_com_company >= 999999 ?
                            '0' + id_com_company :
                            id_com_company >= 99999 ?
                                '00' + id_com_company :
                                id_com_company >= 9999 ?
                                    '000' + id_com_company :
                                    id_com_company >= 999 ?
                                        '0000' + (id_com_company) :
                                        id_com_company >= 99 ?
                                            '00000' + (id_com_company) :
                                            id_com_company >= 9 ?
                                                '000000' + (id_com_company) :
                                                '0000000' + (id_com_company)

                let doc_number =
                    result.length >= 9999999 ?
                        result.length + 1 :
                        result.length >= 999999 ?
                            '0' + result.length + 1 :
                            result.length >= 99999 ?
                                '00' + result.length + 1 :
                                result.length >= 9999 ?
                                    '000' + result.length + 1 :
                                    result.length >= 999 ?
                                        '0000' + (result.length + 1) :
                                        result.length >= 99 ?
                                            '00000' + (result.length + 1) :
                                            result.length >= 9 ?
                                                '000000' + (result.length + 1) :
                                                '0000000' + (result.length + 1)
                // console.log('PRD'+company_number+yyyy+doc_number)
                this.setState({ProductionNumber: 'PRD' + company_number + yyyy + doc_number})
            })

    }


    _Calculate(id) {


        let line_url = this.props.api_url + '/selectRows/view_product_set_lines';
        //console.log('id_com_set='+id+'&id_com_company='+this.props.id_com_company+'&token='+this.props.loginToken+'&type=user')
        fetch(line_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_set=' + id + '&id_com_company=' + this.props.id_com_company + '&token=' + this.props.loginToken + '&type=user' + '&id_com_depot=' + this.state.id_com_depot
        }).catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                this.setState({ProductionLines: result, linesLoaded: true})
                console.log(result)
            })


    }

    _excludeItem(index) {

        let invItems = this.state.invItems;
        invItems.splice(index, 1);
        this.setState({invItems: [], Production_Quantity: 0, list_price: '', ProductionId: '', ProductList: []});


        //console.log(this.state.invItems)
        this._Calculate()
    }

    _includeItem(id) {


        let invItems = this.state.invItems;

        let product_url = this.props.api_url + '/selectRows/view_product';

        fetch(product_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'token=' + this.props.loginToken + '&type=user&id=' + id + '&id_com_company=' + this.props.id_com_company
        }).then(res => res.json())
            .then(result => {
                invItems.push(result[0]);
                invItems.forEach(row => {
                    row.InvQuantity = 1;
                });

                this.setState({
                    invItems: invItems,
                    Production_Quantity: 0,
                    list_price: invItems[0].list_price,
                    ProductionId: invItems[0].id
                })
                this._Calculate(id)

            })

        // console.log(this.state.invItems)
    }

    _SearchProduct(code) {

        let product_url = this.props.api_url + '/productSearch/' + this.props.id_com_company;

        fetch(product_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'token=' + this.props.loginToken + '&type=user&match=' + code
        }).then(res => res.json())
            .then(result => {

                let difference = result.filter(row => !this.state.invItems.find(row2 => row.id === row2.id) && row.id_com_product_type === '4')

                this.setState({ProductList: difference, productLoaded: true})
            })


    }

    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="page-title">Üretim Kartı</h3>
                                </div>
                                <div className="col-md-12">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <div className="panel-title">Üretim Oluşturma</div>
                                        </div>
                                        <div className="panel-body">
                                            <div className="form-group">
                                                <label>Üretim No: {this.state.ProductionNumber}</label>
                                            </div>
                                            <SelectList required={true} Label="Depo" id_com_company={this.props.id_com_company} table={'com_depot'} value={this.state.id_com_depot} onChange={(x) => this.setState({id_com_depot: x.id})}/>
                                            <div className="form-group">
                                                <label>Açıklama</label>
                                                <textarea className="form-control" placeholder="Açıklama" rows="5" value={this.state.comment} onChange={(x) => this.setState({comment: x.target.value})}></textarea>
                                            </div>
                                            {this.state.invItems.length === 0 ? this.state.id_com_depot !== '' ?
                                                <React.Fragment>
                                                    <div className="form-group">
                                                        <label>Malzeme Kartı</label>
                                                        <input value={this.state.searchProduct} className="form-control" type="text" placeholder="Malzeme Kodu, Malzeme Adı, OEM Kodu"  onChange={(x) => { this.setState({searchProduct: x.target.value}); this._SearchProduct(x.target.value) }} />
                                                    </div>
                                                    {this.state.searchProduct!==''?
                                                        <div>
                                                            {this.state.ProductList.length===0?'Malzeme Bulunamadı':
                                                                <table className="table table-bordered table-striped">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Grup</th>
                                                                        <th>Kodu</th>
                                                                        <th>Adı</th>
                                                                        <th>Depo Adı</th>
                                                                        <th>Stok Adedi</th>
                                                                        <th>İşlem</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {this.state.ProductList.map((row, index) =>
                                                                        <tr key={index}>
                                                                            <td>{row.id_com_product_class_name}</td>
                                                                            <td>{row.code}</td>
                                                                            <td>{row.text}</td>
                                                                            <td>{row.id_com_depot_name}</td>
                                                                            <td>{row.stock_level}</td>
                                                                            <td><button disabled={this.state.id_com_invoice_type === '1' ? row.list_price === null ? true : false : false} onClick={() => {this.setState({searchProduct: '', ProductList: [], productLoaded: false});this._includeItem(row.id)}} className="btn btn-primary btn-sm">{this.state.id_com_invoice_type === '1' ? parseFloat(row.stock_level) <= 0 ? 'Stok Bulunmamaktadır' : row.list_price === null || parseFloat(row.list_price) === 0 ? 'Liste Fiyatı Yok' : 'Seç' : 'Seç'}</button></td>
                                                                        </tr>
                                                                    )
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            }
                                                        </div>
                                                    :
                                                        <></>
                                                    }
                                                </React.Fragment> : <></>
                                                :
                                                <div>
                                                    <table className="table table-bordered table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th>Satır No</th>
                                                            <th>Tip</th>
                                                            <th>Kodu</th>
                                                            <th>Adı</th>
                                                            <th>Adet</th>
                                                            <th>Paket Tipi</th>
                                                            <th>İşlem</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.invItems.map((row, index) =>
                                                            <tr key={index}>
                                                                <td>{index + 1} </td>
                                                                <td>{row.id_com_product_class_name}</td>
                                                                <td>{row.code}</td>
                                                                <td>{row.text}</td>
                                                                <td><input type="number" value={this.state.Production_Quantity} className="form-control" onChange={(x) => {
                                                                    this._Stock_Control(x.target.value);
                                                                    if (parseFloat(x.target.value) < 1) {
                                                                        alert('Adet Negatif veya Sıfır Değerde Olamaz');
                                                                        this.setState({Production_Quantity: 1});
                                                                    } else {
                                                                        this.setState({Production_Quantity: x.target.value === '' ? 1 : parseFloat(x.target.value)})
                                                                    };
                                                                    let total_cost = 0;
                                                                    this.state.ProductionLines.forEach(row => {
                                                                        total_cost += (parseFloat(row.avg_cost) * parseFloat(x.target.value))
                                                                    });
                                                                    console.log(total_cost);
                                                                    this.setState({total_cost: total_cost});}}/>
                                                                </td>
                                                                <td>{row.id_com_unit_name}</td>
                                                                <td>
                                                                    <button onClick={() => {this._excludeItem(index, row.id)}} className="btn btn-primary btn-sm">Çıkar</button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                            <table className="table table-bordered table-striped">
                                                <thead>
                                                <tr>
                                                    <th>Satır No</th>
                                                    <th>Kodu</th>
                                                    <th>Adı</th>
                                                    <th> Birim Adet</th>
                                                    <th> Toplam Adet</th>
                                                    <th> Ort.Maliyet</th>
                                                    <th> Top.Maliyet</th>
                                                    <th> Stok Durumu</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.ProductionLines.map((row, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1} </td>
                                                        <td>{row.id_com_product_code}</td>
                                                        <td>{row.id_com_product_name}</td>
                                                        <td>{row.quantity}</td>
                                                        <td>{Math.round(parseFloat(row.quantity) * parseFloat(this.state.Production_Quantity) * 100) / 100}</td>
                                                        <td>{row.avg_cost}</td>
                                                        <td>{Math.round(parseFloat(row.avg_cost) * parseFloat(this.state.Production_Quantity) * 100) / 100}</td>
                                                        <td style={{color: Math.round(parseFloat(row.quantity) * parseFloat(this.state.Production_Quantity) * 100) / 100 > row.stock_level ? 'red' : 'lightgreen'}}>{Math.round(parseFloat(row.quantity) * parseFloat(this.state.Production_Quantity) * 100) / 100 > row.stock_level ? 'Yetersiz Stok' : 'Stok Uygun'}</td>
                                                    </tr>
                                                )
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="panel-heading">
                                            <h3 className="panel-title">Üretim Özeti</h3>
                                        </div>
                                        <div className="panel-body">
                                            <div className="form-group">
                                                <label>Ortalama Ürün Maliyeti</label>
                                                <p>{Math.round(parseFloat(this.state.total_cost) / parseFloat(this.state.Production_Quantity) * 100) / 100}</p>
                                            </div>
                                            <div className="form-group">
                                                <label>Liste Fiyatı</label>
                                                <p>{this.state.list_price}</p>
                                            </div>
                                            <div className="form-group">
                                                <label>Teslim Tarihi</label>
                                                <DatePicker   onChange={(x)=>this.setState({delivery_date:x.toISOString().split('T')[0]})}/>

                                               {/* // <input type='date' className="form-control" value={this.state.delivery_date} onChange={(x) => {this.setState({delivery_date: x.target.value})}}/> */}
                                            </div>
                                        </div>
                                    </div>
                                    <a className="btn btn-danger" href="/ProductionList">İptal</a> <button disabled={this.state.stock_control} className="btn btn-primary" onClick={() => this._Submit()}>Kaydet</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(ProductionAdd);