import React from 'react';
import {_Log_Out,_Log_Status_Change} from '../redux/actions';
import {connect} from 'react-redux';
import mapStateToProps from '../redux/mapStateToProps';
class NavBar extends React.Component {
    constructor(props){
        super(props);
        this.state={}
    }
    render(){
        return (
            <nav className="navbar navbar-default navbar-fixed-top">
                <div className="brand">
                    <a href="/"><img src="/assets/img/logo.png" alt="SerVix" width="130" className="img-responsive logo" /></a>
                </div>
                <div className="container-fluid">
                    <div className="navbar-btn">
                        <button type="button" className="btn-toggle-fullwidth"><i className="lnr lnr-arrow-left-circle"></i></button>
                    </div>
                    <div id="navbar-menu">
                        <ul className="nav navbar-nav navbar-right">
                            <li className="dropdown">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown"><i className="lnr lnr-user"></i><span>{this.props.username}</span> <i className="icon-submenu lnr lnr-chevron-down"></i></a>
                                <ul className="dropdown-menu">
                                    <li><a href="#"><i className="lnr lnr-cog"></i><span>Hesap Ayarları</span></a></li>
                                    <li><a href="#" onClick={()=>{this.props._Log_Out();this.props._Log_Status_Change()}}><i className="lnr lnr-exit"></i><span>Çıkış</span></a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}
const mapDispatchToProps = dispatch => {
    return {
        _Log_Out : ()=> dispatch(_Log_Out()),
        _Log_Status_Change : ()=> dispatch(_Log_Status_Change())
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(NavBar);
