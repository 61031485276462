import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import {alertMessage,dateTime,datePrint} from '../../assets.js';
import InputMask from "react-input-mask";
import {randomPassword} from "../../assets";
class UserEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: false,
            data: [],
            manager: [],
            usertype: [],
            location: [],
            brand: [],
            department: [],
            education: [],
            gender: [],
            actionResult: null,
            id: this.props.match.params.id
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState(state => {
            const newData = state.data[name] = value;
            return {
                newData
            };
        });
    }

    passwordGenerator(event){
        const randPass = randomPassword();
        this.setState(state => {
            const newData = state.data['new_password'] = randPass;
            return {
                newData
            };
        });
    }

    handleSubmit(event) {
        this.setState({actionResult:alertMessage('loadingData')});
        event.preventDefault();
        const data = new FormData(event.target);
        data.append('token',this.props.loginToken);
        data.append('id_com_user',this.props.id_com_user);
        data.append('modified',dateTime());
        var urlencoded = new URLSearchParams();
        urlencoded = data;
        var requestOptions = {
            method: 'POST',
            body: urlencoded
        };
        fetch(this.props.api_url + "/update/com_user", requestOptions)
            .then(response => response.json())
            .then(result =>
            {result.status === true?
                this.setState({actionResult:alertMessage('updateSuccess')})
                :
                this.setState({actionResult:alertMessage('updateError')})
            })
            .catch(error => console.log('error', error));
    }
    componentDidMount() {
        /*
             User Data
        */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("token", this.props.loginToken);
        urlencoded.append("id", this.state.id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(this.props.api_url + "/selectRow/view_user", requestOptions)
            .then(response => response.json())
            .then(result =>
                this.setState({data:result})
            )
            .catch(error => console.log('error', error));
        /*
             Manager User Data
        */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("token", this.props.loginToken);
        urlencoded.append("id_com_usertype", 2);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(this.props.api_url + "/selectRows/view_user", requestOptions)
            .then(response => response.json())
            .then(result =>
                this.setState({manager:result})
            )
            .catch(error => console.log('error', error));
        /*
            User Type Data
         */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("token", this.props.loginToken);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(this.props.api_url + "/selectRows/com_usertype", requestOptions)
            .then(response => response.json())
            .then(result =>
                this.setState({usertype:result})
            )
            .catch(error => console.log('error', error));
        /*
            Location Data
         */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("token", this.props.loginToken);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(this.props.api_url + "/selectRows/com_location", requestOptions)
            .then(response => response.json())
            .then(result =>
                this.setState({location:result})
            )
            .catch(error => console.log('error', error));
        /*
            Brand Data
         */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("token", this.props.loginToken);
        urlencoded.append("dealer", 1);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(this.props.api_url + "/selectRows/com_brand", requestOptions)
            .then(response => response.json())
            .then(result =>
                this.setState({brand:result})
            )
            .catch(error => console.log('error', error));
        /*
            Department Data
         */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("token", this.props.loginToken);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(this.props.api_url + "/selectRows/com_department", requestOptions)
            .then(response => response.json())
            .then(result =>
                this.setState({department:result})
            )
            .catch(error => console.log('error', error));
        /*
            Education Data
         */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("token", this.props.loginToken);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(this.props.api_url + "/selectRows/com_education", requestOptions)
            .then(response => response.json())
            .then(result =>
                this.setState({education:result})
            )
            .catch(error => console.log('error', error));
        /*
            Gender Data
         */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("token", this.props.loginToken);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(this.props.api_url + "/selectRows/com_gender", requestOptions)
            .then(response => response.json())
            .then(result =>
                this.setState({gender:result})
            )
            .catch(error => console.log('error', error));
    }
    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3 className="page-title">Kullanıcılar</h3>
                                </div>
                                <div className="col-md-6 text-right">
                                    <a href="/user" className="btn btn-default">Kullanıcı Listesi</a>
                                </div>
                                {this.state.actionResult?
                                    <div className="col-md-12"dangerouslySetInnerHTML={{__html: this.state.actionResult}}></div>
                                    :
                                    <div></div>
                                }
                                <div className="col-md-12">
                                    {this.state.data.length == 0 ?
                                        <div className="col-md-12"dangerouslySetInnerHTML={{__html: alertMessage('loadingData')}}></div>
                                        :
                                        <form onSubmit={this.handleSubmit}>
                                            <input type="hidden" name="id" value={this.state.data.id} />
                                            <div className="panel">
                                                <div className="panel-heading">
                                                    <div className="panel-title">Kullanıcı Güncelle</div>
                                                </div>
                                                <div className="panel-body">
                                                    <div className="form-group">
                                                        <label>TC Kimlik No</label>
                                                        <InputMask mask="99999999999" className="form-control" name="tckn" value={this.state.data.tckn} onChange={(e) => this.handleChange(e)} placeholder="TC Kimlik No"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>İşe Başlama Tarihi</label>
                                                        <InputMask mask="99.99.9999" className="form-control" name="startdate" value={datePrint(this.state.data.startdate)} onChange={(e) => this.handleChange(e)} placeholder="gg.aa.yyyy"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Doğum Tarihi</label>
                                                        <InputMask mask="99.99.9999" className="form-control" name="birthday" value={datePrint(this.state.data.birthday)} onChange={(e) => this.handleChange(e)} placeholder="gg.aa.yyyy"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Adı</label>
                                                        <input type="text" className="form-control" name="name" value={this.state.data.name} onChange={(e) => this.handleChange(e)} placeholder="Adı" required/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Soyadı</label>
                                                        <input type="text" className="form-control" name="surname" value={this.state.data.surname} onChange={(e) => this.handleChange(e)} placeholder="Soyadı" required/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>E-Posta</label>
                                                        <input type="email" className="form-control" name="email" value={this.state.data.email} onChange={(e) => this.handleChange(e)} placeholder="E-Posta" required/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Cep Telefonu</label>
                                                        <InputMask mask="0(999) 999 99 99" className="form-control" value={this.state.data.phone} name="phone" onChange={(e) => this.handleChange(e)} placeholder="Cep Telefonu"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Dahili Hat</label>
                                                        <InputMask mask="9999" className="form-control" value={this.state.data.internal} name="internal" onChange={(e) => this.handleChange(e)} placeholder="Dahili Hat"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Durum</label>
                                                        <select className="form-control selectpicker" name="status" defaultValue={this.state.data.status} onChange={(e) => this.handleChange(e)} data-title="Seçiniz" data-live-search="true">
                                                            <option value="1">Aktif</option>
                                                            <option value="0">Pasif</option>
                                                        </select>
                                                    </div>
                                                    {this.state.education.length == 0?
                                                        <div className="form-group"dangerouslySetInnerHTML={{__html: alertMessage('loadingData')}}></div>
                                                        :
                                                        <div className="form-group">
                                                            <label>Eğitim</label>
                                                            <select className="form-control selectpicker" name="id_com_education" defaultValue={this.state.data.id_com_education} onChange={(e) => this.handleChange(e)} data-title="Seçiniz" data-live-search="true">
                                                                {this.state.education.map((row,index)=>
                                                                    <option key={index} value={row.id}>{row.title}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    }
                                                    {this.state.gender.length == 0?
                                                        <div className="form-group"dangerouslySetInnerHTML={{__html: alertMessage('loadingData')}}></div>
                                                        :
                                                        <div className="form-group">
                                                            <label>Cinsiyet</label>
                                                            <select className="form-control selectpicker" name="id_com_gender" defaultValue={this.state.data.id_com_gender} onChange={(e) => this.handleChange(e)} data-title="Seçiniz" data-live-search="true">
                                                                {this.state.gender.map((row,index)=>
                                                                    <option key={index} value={row.id}>{row.title}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    }
                                                    {this.state.manager.length == 0?
                                                        <div className="form-group"dangerouslySetInnerHTML={{__html: alertMessage('loadingData')}}></div>
                                                        :
                                                        <div className="form-group">
                                                            <label>Bölüm Müdürü</label>
                                                            <select className="form-control selectpicker" name="id_com_user_manager" defaultValue={this.state.data.id_com_user_manager} onChange={(e) => this.handleChange(e)} data-title="Seçiniz" data-live-search="true">
                                                                {this.state.manager.map((row,index)=>
                                                                    <option key={index} value={row.id} data-subtext={"(" + row.brand + " - " + row.department + ")"}>{row.name} {row.surname}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    }
                                                    {this.state.usertype.length == 0?
                                                        <div className="form-group"dangerouslySetInnerHTML={{__html: alertMessage('loadingData')}}></div>
                                                        :
                                                        <div className="form-group">
                                                            <label>Kullanıcı Grubu</label>
                                                            <select className="form-control selectpicker" name="id_com_usertype" defaultValue={this.state.data.id_com_usertype} onChange={(e) => this.handleChange(e)} data-title="Seçiniz" data-live-search="true" required>
                                                                {this.state.usertype.map((row,index)=>
                                                                    <option key={index} value={row.id}>{row.title}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    }
                                                    {this.state.location.length == 0?
                                                        <div className="form-group"dangerouslySetInnerHTML={{__html: alertMessage('loadingData')}}></div>
                                                        :
                                                        <div className="form-group">
                                                            <label>Lokasyon</label>
                                                            <select className="form-control selectpicker" name="id_com_location" defaultValue={this.state.data.id_com_location} onChange={(e) => this.handleChange(e)} data-title="Seçiniz" data-live-search="true">
                                                                {this.state.location.map((row,index)=>
                                                                    <option key={index} value={row.id}>{row.title}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    }
                                                    {this.state.brand.length == 0?
                                                        <div className="form-group"dangerouslySetInnerHTML={{__html: alertMessage('loadingData')}}></div>
                                                        :
                                                        <div className="form-group">
                                                            <label>Marka</label>
                                                            <select className="form-control selectpicker" name="id_com_brand" defaultValue={this.state.data.id_com_brand} onChange={(e) => this.handleChange(e)} data-title="Seçiniz" data-live-search="true">
                                                                {this.state.brand.map((row,index)=>
                                                                    <option key={index} value={row.id}>{row.title}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    }
                                                    {this.state.department.length == 0?
                                                        <div className="form-group"dangerouslySetInnerHTML={{__html: alertMessage('loadingData')}}></div>
                                                        :
                                                        <div className="form-group">
                                                            <label>Departman</label>
                                                            <select className="form-control selectpicker" name="id_com_department" defaultValue={this.state.data.id_com_department} onChange={(e) => this.handleChange(e)} data-title="Seçiniz" data-live-search="true">
                                                                {this.state.department.map((row,index)=>
                                                                    <option key={index} value={row.id}>{row.title}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    }
                                                    <div className="form-group row">
                                                        <div className="col-md-12">
                                                            <label>Şifre</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <input type="password" className="form-control" name="password" value={this.state.data.new_password? this.state.data.new_password : ""} onChange={(e) => this.handleChange(e)} autoComplete="new-password" placeholder="Şifre"  />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" value={this.state.data.new_password? this.state.data.new_password : ""} readOnly disabled placeholder="Şifre" />
                                                                <span className="input-group-btn">
                                                                <button type="button" className="btn btn-primary" onClick={(e) => this.passwordGenerator(e)}>Şifre Oluştur</button>
                                                            </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn btn-danger">Güncelle</button>
                                        </form>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(UserEdit);