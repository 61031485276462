import React,{useState,useEffect} from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import {Divider, Row,Col,Table, Input} from 'antd';
import Wrapper from '../utilities/Wrapper';

const OrderformList = ({api_url,loginToken,id_com_brand})=>{

    const [SearchItem,setSearchItem] = useState('')
    const [Loading,setLoading] = useState(false)
    const [Data,setData] = useState([])
    const [FilteredData,setFilteredData] = useState([])

    
 
    const   columns=[
                {
                    title: 'Kontrol',
                    render : (row,record) =>(
                        <>
                            <a href={'/orderform/edit/' + record.id} className="btn btn-primary btn-sm">Seç</a>
                        </>
                    ),
                    width:'100px',
                    align:'center',
                },
                {
                    title: 'No',
                    dataIndex: 'id',
                    align:'center',
                    width:'75px',
                    sorter: (a, b) => parseInt(a.id)  - parseInt(b.id),

                   
                },
                {
                    title: 'Durumu',
                    dataIndex: 'id_com_orderform_status_name',
                    align:'center',
                 },
                {
                    title: 'Oluşturulma Tarihi',
                    width:'120px',
                    sorter: (a, b) => parseInt(moment(a.c_date).format('YYYYMMDD'))  - parseInt(moment(b.c_date).format('YYYYMMDD')) ,
                    render : (row,record) =>(
                            <p   style={{margin:5}}>{moment(record.c_date).format('DD.MM.YYYY')}</p>
                    ),
                    align:'center',
                },
                
                {
                    title: 'Firma / Müşteri Adı',
                    render : (row,record) =>(
                            <p   style={{margin:5}}>{record.id_com_customer_company_name===undefined||record.id_com_customer_company_name===null||record.id_com_customer_company_name==='-'?record.id_com_customer_name:record.id_com_customer_company_name+' / '+record.id_com_customer_name}</p>
                ),
                left:true,
                },
                {
                    title: 'Temsilci',
                    dataIndex: 'id_com_user_name',
                    align:'center',
                },
                {
                    title: 'Teslim Tarihi',
                    width:'120px',
                    render :(row,record) =>(
                            <p   style={{margin:5}}>{record.delivery_date===undefined?'-':moment(row.delivery_date).format('DD.MM.YYYY')}</p>
                    ),
                    align:'center',
                },
    
         
        ]
       
 

    useEffect(() => {
        _GetData();
    },[] )

    const _GetData = async ()=> {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("token", loginToken);
        urlencoded.append("id_com_user_brand",id_com_brand);
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
    
        try {
       const response = await(await (fetch(api_url + "/selectRows/view_orderform", requestOptions))).json()
        setData(response.reverse())

        }
        catch(e) { 
            console.log(e)

        }
           
    }


   const  _Filter_Data = (value) => {
        let match_str = new RegExp('('+value+')','i');
        setSearchItem(value)
         let filtered_data = Data.filter(function(row){
    
            if(value===''){ 
                return row
            }       {
              return row.id.match(match_str)||row.id_com_orderform_status_name.match(match_str)||row.id_com_user_name.match(match_str)||row.id_com_customer_company_name.match(match_str)||row.id_com_customer_name.match(match_str)  
    
            }
        })
       setFilteredData(filtered_data)
    }


    
     
    return ( 
        <Wrapper title='Satış Formu Listesi'
            extra={[   <a key='new' href="/orderform/add/0/0" className="btn btn-info pull-right">Ekle</a>   ]}>
                <Row  >
                    <Col span={24} style={{padding:20}} >
                        <Input.Search  value={SearchItem} type="search" placeholder="Şasi / Fatura No / Marka / Model Arama..." onChange={(x)=>_Filter_Data(x.target.value)}/>
                        <Divider></Divider>
                    </Col>
                    <Col span={24} style={{padding:20}}  >
                        <Table size='small' bordered dataSource={SearchItem===''?Data:FilteredData}  columns={columns} loading={Loading}/>
                    </Col>

                </Row>
        </Wrapper> 
    )
}

 


export default connect(mapStateToProps)(OrderformList);