import React ,{useState,useEffect}from 'react';
import {  Col, Row, Divider, PageHeader,Table } from 'antd'; 
import {connect} from 'react-redux';
import mapStateToProps from '../../../redux/mapStateToProps'; 
import moment from 'moment';
import ExportFromJson from '../../../components/utilities/jsontoexport';
 

const  formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 2
  })


const CheckList = ({api_url,loginToken,id_com_user}) => {
     const [LogoData,setLogoData] = useState([])
    const [Status,setStatus] = useState([])
    const [Period,setPeriod] = useState([])
    const [Customer,setCustomer] = useState([])
    const [Banks,setBanks] = useState([])
    const [PeriodTable,setPeriodTable] = useState([])
    const [PeriodColumns,setPeriodColumns] = useState([])

    const Columns =[
        {
            title:'Yıl',
            dataIndex:'YEAR_',
            align:'left',

         },
         {
            title:'Ay',
            dataIndex:'MONTH_',
            align:'center',
         },
        {
            title:'Hafta',
            dataIndex:'WEEK_',
            align:'center',
         },
         {
            title:'Vade',
            dataIndex:'VADE',
            align:'center',
            render : (row,records,index) =>moment(records.VADE).format('DD.MM.YYYY')
         },
         {
            title:'Banka',
            dataIndex:'BANKNAME',
            align:'center',
         },
         {
            title:'Portfoy No',
            dataIndex:'PORTFOYNO',
            align:'center',
         },
         {
            title:'Seri No',
            dataIndex:'SERINO',
            align:'center',
         },
         {
            title:'Borçlu',
            dataIndex:'BORCLU',
            align:'center',
         },
         {
            title:'Ünvan',
            dataIndex:'UNVAN',
            align:'center',
         },
      
         {
            title:'Durum',
            dataIndex:'DURUM',
            align:'center',
 
         },
         {
            title:'Kur',
             align:'center',
            render: row=>   formatter.format(row.KUR)  
         },
         {
            title:'Tutar',
             align:'center',
             sorter: (a, b) =>  a.TL_AMOUNT-b.TL_AMOUNT ,
            render: row=>   formatter.format(row.TL_AMOUNT)  
         },
    ]

    const Columns_excel=[
        {
            name:'Yıl',
            selector:'YEAR_', 
         },
         {
            name:'Ay',
            selector:'MONTH_', 
         },
        {
            name:'Hafta',
            selector:'WEEK_', 
         },
         {
            name:'Vade',
            selector:'VADE', 
         },
         {
            name:'Banka',
            selector:'BANKNAME', 
         },
         {
            name:'Portfoy No',
            selector:'PORTFOYNO', 
         },
         {
            name:'Seri No',
            selector:'SERINO', 
         },
         {
            name:'Borçlu',
            selector:'BORCLU', 
         },
         {
            name:'Ünvan',
            selector:'UNVAN', 
         },
      
         {
            name:'Durum',
            selector:'DURUM', 
         },
         {
            name:'Kur',
            selector:'KUR', 
         },
         {
            name:'Tutar',
            selector:'TL_AMOUNT',
           
         },
    ]

    const rowStyle = {
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
      };
      const colStyle = {
        marginBottom: '16px',
      };
      const gutter = 16;

      useEffect(() => {
        const OnLoad =  ()=>{
            _GetLogoData();
        }
        return OnLoad();
     }, [])



       

      const   _GetLogoData = async () =>{
        let emuhac_sql=api_url+'/logoSelectRows/ZZZ_ST_VIEW_CEK_DURUM_102';
        const line_data = await(await fetch(emuhac_sql,{
                 method:'POST',
                 cahce: 'no-cache',
                 headers:{ 
                   'Content-Type':'application/x-www-form-urlencoded'
                 },
               body: 'token='+loginToken+'&type=user',
             })).json()

             line_data.sort(function(a, b) {
                var nameA = a.VADE;  
                var nameB = b.VADE;  
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
              
                 return 0;
              });
              let filtered = line_data.filter(row=>parseInt(row.YEAR_)>=parseInt(moment(new Date()).format('YYYY')))
              setLogoData(filtered)
             _DataTranspose(filtered)
     }

     const _DataTranspose = async (data) =>{


        let status = [];
        let period = [];
        let customers = [];
        let Banks = [];
        
        data.map(line=>{
            status.push(line.DURUM);
            period.push(`${line.YEAR_}-${line.MONTH_}`);
            customers.push(line.BORCLU)
            Banks.push(line.BANKNAME)
        });
        
        
        let u_status=[...new Set(status)];
        let u_period=[...new Set(period)];
        let u_customers=[...new Set(customers)];
        let u_banks =[...new Set(Banks)];
        setStatus(u_status);
        setPeriod(u_period);
        setCustomer(u_customers);
        setBanks(u_banks);
         
        let initial_columns=[
            {
                title:'Durum',
                dataIndex:'status',
                align:'left',
                width:'250px'
            }
        ]
        
        let period_table = []
        
        
        
         
        u_status.sort().map(status=>{
                let line_row = {
                    status:status
                }
        
                  u_period.map(periods=>{
                    let status_filtered = data.filter(row=>row.DURUM===status&&`${row.YEAR_}-${row.MONTH_}`===periods);
                    Object.assign(line_row,{ [`${periods}_sum`]:status_filtered.reduce((a,b)=>a+parseFloat(b.TL_AMOUNT  ),0) })
                })
                Object.assign(line_row,{[`row_sum`]:data.filter(row=>row.DURUM===status).reduce((a,b)=>a+parseFloat(b.TL_AMOUNT  ),0) })
                period_table.push(line_row)
        })
        
        u_period.map(periods=>{
            initial_columns.push({
                title:periods,
                align:'center',
                width:'200px',
                render: row=>   formatter.format(row[`${periods}_sum`])  
            });
        })
        
        initial_columns.push({
            title:'Toplam',
            align:'center',
            width:'200px',
            render: row=>   formatter.format(row[`row_sum`]) 
        })
        
        let sum_row ={ 
            status:'Toplam',
        }
        u_period.map(periods=>{
            let filtered = data.filter(row=>`${row.YEAR_}-${row.MONTH_}`===periods);
            Object.assign(sum_row,{ [`${periods}_sum`]:filtered.reduce((a,b)=>a+parseFloat(b.TL_AMOUNT  ),0) })
        })
        Object.assign(sum_row,{[`row_sum`]:data.reduce((a,b)=>a+parseFloat(b.TL_AMOUNT  ),0) })
        
         
        period_table.push(sum_row)
            setPeriodTable(period_table);
            setPeriodColumns(initial_columns);
        }



return (
    <div className="main">
        <div className="main-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="panel" style={{padding:20}}>
                            <Row style={rowStyle} gutter={gutter} justify="start">
                                <Col span={24} style={{marginBottom: '5px',}}>
                                    <PageHeader title='Çekler | ' subTitle='Liste'   /> 
                                </Col>
                            </Row>
                            <Divider>Özet Tablo</Divider>
                            <Row style={rowStyle} gutter={gutter} justify="start">
                                <Col span={24} style={{marginBottom: '5px',}}>
                                    <Table size='small' dataSource={PeriodTable} columns={PeriodColumns} bordered rowKey={row=>row.status}/>
                                </Col>
                            </Row>
                            <Divider>Detay Tablo</Divider>
                            <Row style={rowStyle} gutter={gutter} justify="start">
                                <Col span={24} style={{marginBottom: '5px',}}>
                                    <PageHeader extra={[  
                                        <ExportFromJson data={LogoData} fileName={`musteri_cekler_${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}`} exportType={'xls'}/>
                                    ]}/>
                                </Col>
                                <Col span={24} style={{marginBottom: '5px',}}>
                                    <Table size='small' dataSource={LogoData} columns={Columns} bordered rowKey={row=>row.PORTFOYNO}/>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)


}


export default connect(mapStateToProps)(CheckList);
