import React,{useState,useEffect} from 'react';
import { Input, Col, Row, Typography,Divider, PageHeader,Collapse ,Button,Table,Tabs, Spin} from 'antd';
import {connect} from 'react-redux';
import mapStateToProps from '../../../redux/mapStateToProps'; 
import 'moment/locale/tr';
import moment from 'moment'; 
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import BankAdd from './BankAdd';
import BankCard from './BankCard';
import ExportFromJson from '../../../components/utilities/jsontoexport';

const  formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 2
  })
  
  const  formatter_eur = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  })

  const  formatter_usd = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

const { Title ,Text} = Typography;
const {Panel} = Collapse
const  {TabPane} =Tabs


const BankAccountList = ({api_url,loginToken,id_com_user})=>{

    const [Data,setData] =useState([])
    const [LogoData,setLogoData] =useState([])
    const [Currencies,setCurrencies]=useState([]);
    const [Banks,setBanks]=useState([]);
    const [TableData,setTableData]=useState([])
    const [AddModalVisible,setAddModalVisible] = useState(false);
    const [DetailModal,setDetailModal] = useState(false);
    const [SelectedCari,setSelectedCari]=useState({});
    const [RateData,setRateData]=useState([]);

    const [Loading,setLoading] =useState(false)
    const [FilteredData,SetFilteredData]=useState([]);
    const [SearchItem,setSearchItem]=useState('');
    const [SelectedRow,setSelectedRow] = useState({});



    
    const Columns = [
        {
            title:'Cari Kod',
            dataIndex:'cari_kod',
            align:'left'
        },
        {
            title:'Banka',
            dataIndex:'id_com_bank_name',
            align:'left'
        },
        {
            title:'Hesap Tipi',
            dataIndex:'id_com_bank_account_type_name',
            align:'center'
        },
        {
            title:'Açıklama',
            dataIndex:'text',
            align:'center'
        },
        {
            title:'Toplam Döviz',
            dataIndex:'total_sum_d',
            align:'right',
            render : (row,record)=>record.id_com_currency_name==='TL'?formatter.format(0):record.id_com_currency_name==='USD'?formatter_usd.format(record.total_sum_d):formatter_eur.format(record.total_sum_d)
        },
        {
            title:'Toplam TL',
            dataIndex:'total_sum_tl',
            align:'right',
            render : (row,record)=>formatter.format(record.total_sum_tl)
        },
        {
            title:'Detay',
            dataIndex:'total_sum_tl',
            align:'right',
            render : (row,record)=>record.id_com_bank_name!=='Toplam'&&<Button type='primary' shape='round' size='small' onClick={()=>{setDetailModal(true);setSelectedCari(record)}} ><SearchOutlined/></Button>
        }
    ]

   const Columns2 = [
 
        {
            title:'Banka Hesapları',
            dataIndex:'id_com_bank_name',
            align:'left'
        },
        {
            title:'Toplam Döviz',
            dataIndex:'total_sum_d',
            align:'right',
            render : (row,record)=>record.id_com_currency_name==='TL'?formatter.format(0):record.id_com_currency_name==='USD'?formatter_usd.format(record.total_sum_d):formatter_eur.format(record.total_sum_d)
        },
        {
            title:'Toplam Mizan TL',
            dataIndex:'total_sum_tl',
            align:'right',
            render : (row,record)=>formatter.format(record.total_sum_tl)
        },
        {
            title:'Toplam Güncel TL',
            dataIndex:'current_sum_tl',
            align:'right',
            render : (row,record)=>formatter.format(record.current_sum_tl)
        },
    ]


    useEffect(() => {
        const OnLoad =()=>{
            _GetBankAccount();
         }       
        return OnLoad();
    }, [])

    const _GetBankAccount= async ()=>{
        setLoading(true)

        let url =  api_url+'/selectrows/view_bank_account';
        let response  = await (await   fetch(url,{
                method:'POST',
                cache: 'no-cache',
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                body: 'token='+loginToken+'&type=user',
            })).json();
            setData(response)

            let currency = []
            let bank = []

            const table_data = []
            const logo_data = await _GetLogoLines();
             // const rates = await _Get_Rates();

              response.map(line=>{
                currency.push(line.id_com_currency_name);
                bank.push(line.id_com_bank_name);
                const TL = logo_data.filter(row=>row.ACCOUNTCODE===line.cari_kod).reduce((a,b)=>a+parseFloat(b.TR_AMOUNT),0);
                
                const D = logo_data.filter(row=>row.ACCOUNTCODE===line.cari_kod).reduce((a,b)=>a+parseFloat(b.AMOUNT),0);
                
                table_data.push({
                    id:line.id,
                    text:line.text,
                    cari_kod:line.cari_kod,
                    id_com_bank_account_type:line.id_com_bank_account_type,
                    id_com_bank_account_type_name:line.id_com_bank_account_type_name,
                    id_com_currency:line.id_com_currency,
                    id_com_bank_name:line.id_com_bank_name,
                    id_com_currency_name:line.id_com_currency_name,
                    total_sum_tl: TL,
                    total_sum_d:D
                })
            })


            let u_currency = [...new Set(currency)];
            let u_bank = [...new Set(bank)];

            setCurrencies(u_currency);
            setBanks(u_bank.sort());
            setTableData(table_data);
            setLoading(false)

          }
    const _SummaryTable = ()=>{
        let table=[]
        Currencies.map((currency,idx)=>{
            let filtered = TableData.filter(line=>line.id_com_currency_name===currency) 
            // let id_com_currency = currency==='TL'?'1':currency==='USD'?'2':'3'
         
             //const today_rate = RateData.filter(rate=>rate.id_com_currency===id_com_currency&&moment(rate.curency_date).format('YYYY-MM-DD')===moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'))
            //  let rate = currency==='TL'?1:today_rate[0].sale

            table.push({
                id:'type1'+currency,
                id_com_currency:currency,
                id_com_bank_account_type:'1',
                id_com_bank_name:`${currency} - Mevduat`,
                id_com_currency_name:currency,
                total_sum_tl: filtered.filter(line=>line.id_com_bank_account_type==='1').reduce((a,b)=>a+parseFloat(b.total_sum_tl),0),
                total_sum_d:filtered.filter(line=>line.id_com_bank_account_type==='1').reduce((a,b)=>a+parseFloat(b.total_sum_d),0),
                current_sum_tl:filtered.filter(line=>line.id_com_bank_account_type==='1').reduce((a,b)=>a+parseFloat(b.total_sum_d),0) 
            })
            table.push({
                id:'type2'+currency,
                id_com_currency:currency,
                id_com_bank_account_type:'2',
                id_com_bank_name:`${currency} - Vadeli`,
                id_com_currency_name:currency,
                total_sum_tl: filtered.filter(line=>line.id_com_bank_account_type==='2').reduce((a,b)=>a+parseFloat(b.total_sum_tl),0),
                total_sum_d:filtered.filter(line=>line.id_com_bank_account_type==='2').reduce((a,b)=>a+parseFloat(b.total_sum_d),0),
                current_sum_tl:filtered.filter(line=>line.id_com_bank_account_type==='2').reduce((a,b)=>a+parseFloat(b.total_sum_d),0) 
            })
        })

        // table.push({
        //         id:'type3',
        //          id_com_bank_account_type:'3',
        //         id_com_bank_name:'Toplam',
        //          total_sum_tl: table.reduce((a,b)=>a+parseFloat(b.total_sum_tl),0),
        //          total_sum_d:table.reduce((a,b)=>a+parseFloat(b.total_sum_d)/2,0),
        //          current_sum_tl:table.reduce((a,b)=>a+parseFloat(b.current_sum_tl),0)
        //     })
           
 
            return ( 
                <Table dataSource={table} columns={Columns2} size='small' bordered rowKey={row=>row.id} loading={Loading} pagination={false} />
            )
    }


    const _GetLogoLines= async ()=>{

        let url =  api_url+'/logoSelectRows/ZZZ_ST_EMFLINE_102_banks';
        let response  = await (await   fetch(url,{
                method:'POST',
                cache: 'no-cache',
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                body: 'token='+loginToken+'&type=user',
            })).json();
            setLogoData(response)

            return response
    }

    // const _Get_Rates = async ()=>{
    //     let url =  api_url+'/SelectRows/view_currency_rate';
    //     let response  = await (await   fetch(url,{
    //             method:'POST',
    //             cache: 'no-cache',
    //             headers:{'Content-Type': 'application/x-www-form-urlencoded'},
    //             body: 'token='+loginToken+'&type=user',
    //         })).json();
    //         setRateData(response)

            

    //         return response
    // }


return ( 
    <div className="main">
    <div className="main-content">
        <div className="container-fluid">
            <div className="row">
            <div className="col-md-12">
                              <div className="panel" style={{padding:20}}>
        <Row>
            <Col span={24}>
            <PageHeader
                title={'Bankalar |'}
                subTitle={'Liste'}
                extra={
                    [
                        // <Text code type='danger'>Toplam Adet  :  {Data.length} | Toplam Çek Adedi :  {formatter.format(Data.reduce((a,b)=>a+parseFloat(b.TUTAR),0))} </Text>,
                         <ExportFromJson data={SearchItem===''?Data:FilteredData} fileName={`firma_cekleri_${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}`} exportType={'xls'}/>,
                        <Button key='add' shape='round'  onClick={()=>setAddModalVisible(true)}   type='default'  ><PlusCircleOutlined /></Button>
                    ]
                }
            />
            </Col>
        </Row>
         {Loading?
        <Row justify='center' align='middle'>
        <Divider></Divider>
            <Col span={1} style={{justifyContent:'center'}}>
                <Spin size='large' style={{justifySelf:'center'}} tip='Veriler Yükleniyor...'/>
            </Col>
        </Row>
        :
        <Row>
        <Divider>Özetler</Divider>
            <Col span={24}>
                {   _SummaryTable()   }
            </Col>
        <Divider></Divider>
            <Col span={24}>
                <Tabs type='card' >
                    {Currencies.map((currency,idx)=>{
                            let filtered = TableData.filter(line=>line.id_com_currency_name===currency) 
                            filtered.push({
                                id:'type1',
                                id_com_currency:currency,
                                id_com_bank_account_type:'1',
                                id_com_bank_name:'Toplam',
                                id_com_currency_name:currency,
                                total_sum_tl: filtered.filter(line=>line.id_com_bank_account_type==='1').reduce((a,b)=>a+parseFloat(b.total_sum_tl),0),
                                total_sum_d:filtered.filter(line=>line.id_com_bank_account_type==='1').reduce((a,b)=>a+parseFloat(b.total_sum_d),0),
                            })
                            filtered.push({
                                id:'type2',
                                id_com_currency:currency,
                                id_com_bank_account_type:'2',
                                id_com_bank_name:'Toplam',
                                id_com_currency_name:currency,
                                total_sum_tl: filtered.filter(line=>line.id_com_bank_account_type==='2').reduce((a,b)=>a+parseFloat(b.total_sum_tl),0),
                                total_sum_d:filtered.filter(line=>line.id_com_bank_account_type==='2').reduce((a,b)=>a+parseFloat(b.total_sum_d),0),
                            })
                            filtered.push({
                                id:'type3',
                                id_com_currency:currency,
                                id_com_bank_account_type:'3',
                                id_com_bank_name:'Toplam',
                                id_com_currency_name:currency,
                                total_sum_tl: filtered.reduce((a,b)=>a+parseFloat(b.total_sum_tl)/2,0),
                                total_sum_d:filtered.reduce((a,b)=>a+parseFloat(b.total_sum_d)/2,0),
                            })


                        const summary = currency==='TL'?formatter.format(filtered.reduce((a,b)=>a+parseFloat(b.total_sum_tl)/3,0)):currency==='USD'?formatter_usd.format(filtered.reduce((a,b)=>a+parseFloat(b.total_sum_d)/3,0)):formatter_eur.format(filtered.reduce((a,b)=>a+parseFloat(b.total_sum_d)/3,0))
                        return (
                            <TabPane tab={`${currency} | Toplam : ${summary}`} key={idx} >
                                    <Divider>Toplam</Divider>
                                    <Table dataSource={filtered.filter(row=>row.id_com_bank_account_type==='3')} columns={Columns} size='small' bordered rowKey={row=>row.id} loading={Loading} pagination={false} />
                                        <Divider>Mevudat</Divider>
                                    <Table dataSource={filtered.filter(row=>row.id_com_bank_account_type==='1')} columns={Columns} size='small' bordered rowKey={row=>row.id} loading={Loading} pagination={false} />
                                    <Divider>Vadeli</Divider>
                                    <Table dataSource={filtered.filter(row=>row.id_com_bank_account_type==='2')} columns={Columns} size='small' bordered rowKey={row=>row.id} loading={Loading} pagination={false} />
                            </TabPane>
                        )
                    })}
                </Tabs>
            </Col>
        </Row>  }
        <BankAdd visible={AddModalVisible}  OnClose={()=>{setAddModalVisible(false);_GetBankAccount()}}/>
        <BankCard visible={DetailModal} OnClose={()=>{setDetailModal(false);setSelectedCari({})}} row_data={SelectedCari} data={LogoData} rates={RateData}/>
 
       </div>
       </div>
       </div>
       </div>
       </div>
       </div>
)
}

export default connect(mapStateToProps)(BankAccountList);