import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component';
import StockAddNew from './AddNew';
import * as CurrencyFormat from 'react-currency-format';
import {Button,Table, Typography} from 'antd';


const {Text} =Typography;

class StockAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: false,
            step1:true,
            SearchItem:'',
            data: [],
            new_data:[],
            filtered:[],
            filtered:false,
            sqlStock:[],
            columns:[
                    {
              title:'Kontrol',
                    width:'100px',
                    render : (row,record) =>(
                        <>
                            <button className="btn btn-success btn-sm" type="button"  onClick={()=>this.setState({step1:false,new_data:record})} style={{margin:5}}><i className="fas fa-plus"></i> </button>
                        </>
                    ),
                    right:true,
                    },
                
                    {
              title:'Şasi',
                   dataIndex:'SASE2', 
                    },
             
                    {
              title:'Model',
                   dataIndex:'MODEL', 
                    },
                
                    {
              title:'Alış Fıyatı',
                   dataIndex:'ALIS_TUTARI',
                   align:'right',
                    render : (row,record) =>  <CurrencyFormat value={Math.round(parseFloat(record.ALIS_TUTARI)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/>

                    },
            ]
        }
        this._Filter_Data=this._Filter_Data.bind(this)
        this.testConnection=this.testConnection.bind(this)
    }
    componentDidMount(){
        let stock_sql=this.props.api_url+'/logoSelectRows/ZZZ_VIEW_ARAC_STOK'
     fetch(stock_sql,{
        method:'POST',
        cahce: 'no-cache',
        headers:{ 
          'Content-Type':'application/x-www-form-urlencoded'
        },
      body:'token='+this.props.loginToken+'&type=user&ID_COM_BRAND='+this.props.id_com_brand,
    })
    .catch(error=>console.log(error)).then(x=> x.json()).then(result=>{
            console.log(result)
            let  cardata= result.filter(row=>row.ID_COM_BRAND===this.props.id_com_brand)

            let stock_mysql=this.props.api_url+'/SelectRows/view_stock'
    fetch(stock_mysql,{
        method:'POST',
        cahce: 'no-cache',
        headers:{ 
          'Content-Type':'application/x-www-form-urlencoded'
        },
      body:'token='+this.props.loginToken+'&id_com_brand='+this.props.id_com_brand+'&id_com_stock_type=1',
    })
    .catch(error=>console.log(error)).then(x=> x.json()).then(mysql_data=>{

        let  difference1 =cardata.filter(row => !mysql_data.find(row2 => row.SASE2 === row2.chasis ))


        this.setState({data:difference1,pageloader:true})
    })
            
        })
    }
    _Filter_Data(value) {
        let match_str = new RegExp('('+value+')','i');
        this.setState({SearchItem:value})
        let data = this.state.data;
        let filtered_data = data.filter(function(row){

            if(value===''){ 
                return row
            }       {
              return row.ARAC_MARKA.match(match_str)||row.ARAC_SPEC.match(match_str)||row.SASE.match(match_str)||row.ARAC_MODEL.match(match_str)||row.ALIS_FATURA_NO.match(match_str)

            }
        })
        this.setState({filtered:filtered_data})
    }

    testConnection(){
        console.log('test started');
        let stock_sql=this.props.api_url+'/test'
        console.log(stock_sql);
        console.log(this.props.loginToken)
        fetch(stock_sql,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded',
              'Accept':'application/json'
            },
          body:'token='+this.props.loginToken//+'&ID_COM_BRAND='+this.props.id_com_brand,
        })
        .catch(error=>console.log(error)).then(x=> x.json()).then(result=>{
          console.log(result)
            })
    }

    render(){
        return ( 
            <div className="main">
            <div className="main-content">
                <div className="container-fluid">
                <div className="panel">
                <div className="panel-heading">
                                <h3 className="panel-title">Tiger Stok Listesi</h3>
                                <Button   key='sql' onClick={()=>this.testConnection()}>test  SQL </Button>

                                {this.state.step1?<a type='button' className='btn btn-info btn-sm pull-right' onClick={()=>this.setState({step1:false})}     >Tahsis Stok Ekle</a>:<></>}

                            </div>
                    
                <div className="panel-body">
                    <div className="row">
                    {this.state.step1?
                    <div className="col-md-12">

                        <input className="form-control" value={this.state.SearchItem} type="search" placeholder="Şasi / Fatura No / Marka / Model Arama..." onChange={(x)=>this._Filter_Data(x.target.value)} style={{margin:5}}/>
                       <Table  
                        size='small' bordered
                        dataSource={this.state.SearchItem===''?this.state.data:this.state.filtered}
                        columns={this.state.columns}

                        footer={()=><Text code type='danger'>{`${this.state.SearchItem===''?this.state.data.length:this.state.filtered.length} Adet Satır Bulunmuştur.`}</Text>}
                       />
                     
                  </div>                    
                        :                    
                        <div className="col-md-12">
                                <StockAddNew data={this.state.new_data} />
                        </div>                    
                    }
                   
                    {this.state.step1?  <div className="panel-footer">
                     <a className="btn btn-danger" href="/Stock/List">Geri</a> 
                     </div>                    
 :<></>}
                        </div>                    
                    </div>
                </div>
            </div>
        </div>
        </div>

        )}
}



export default connect(mapStateToProps)(StockAdd);