import React ,{useState}from 'react';
import { Input ,Button, Popover, Typography, Row,Tag,  Col, Table, Divider,Form, Card,Modal, PageHeader, List,Upload,message, } from 'antd';
import { DeleteOutlined,CloseOutlined,CheckOutlined,SearchOutlined,PlusOutlined,EditOutlined,UploadOutlined,DownloadOutlined ,FileExcelOutlined} from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import SelectList from '../utilities/SelectList';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';



const  formatter = new Intl.NumberFormat('tr-TR', {
    style: 'decimal',
    currency: 'TRY',
    minimumFractionDigits: 2
  })

  const  formatter_dec = new Intl.NumberFormat('tr-TR', {
    style: 'decimal',
    currency: 'TRY',
    minimumFractionDigits: 4
  })

const { Text,Paragraph } = Typography;

const GalleryList  = ({inter_data,miniscreen,documents,id_com_car,id_com_user,token,hideAdd,api_url,media_url})=>{
     const [addForm] = Form.useForm();
    const [editForm] = Form.useForm();

    const [ AddModal,setAddModal] =useState(false);

    const [EditModal,setEditModal] =useState(false);
    const [Loading,setLoading] = useState(false);
    const [DocumentLoaded,setDocumentLoaded] = useState(false);
const _AddDocument = async ()=>{


    setLoading(true)

    const add_form = addForm.getFieldsValue();


                    if(add_form.formfiles!==undefined ) {
                                const formupload = new FormData();
                                formupload.append('token', token);

                                add_form.formfiles.fileList.map(file=>
                                    formupload.append('files[]', file.originFileObj)
                                )
                                formupload.append('show', 0 );
                                formupload.append('resize', '1200px' );
                                formupload.append('component', 'com_stock'  );
                                formupload.append('item_id', id_com_car  );
                                formupload.append('text', 'Ikinci El Foto'  );
                                formupload.append('id_com_user', id_com_user  );
                                formupload.append('id_com_document_type', '6'  );
                                var headers = {
                                'Content-Type': 'multipart/form-data'
                            }
                            try{
                                const docresponse = await  axios.post('https://senturkotomotiv.com.tr/api/api/upload',formupload, {headers: headers})
                                if(docresponse.status===200) {
                                         message.success({ content: 'Başarıyla Oluşturuldu' ,duration:0.5 }).then(()=>{window.location.reload() },1)
                                    } else {
                                        message.error({ content:  'Hata Oluştu.'   ,duration:0.5 }).then(()=>  window.location.reload() ,1);

                                    }
                                }
                            catch(e){
                                    message.error({ content:  'Hata Oluştu.'    ,duration:0.5 }).then(()=>  window.location.reload() ,1);

                                }
                    }




}

const _DeleteDoc = async (id)=>{

setLoading(true)
    let d = new Date();
    let date = d.toISOString();
    let m_date = date.split('T')[0];
    let m_time = d.toLocaleTimeString('tr-TR');


const url= api_url+'/update/view_documents';
const body = 'token='+token+'&type=user&hidden=1&id='+id+'&m_date='+m_date+'&m_time='+m_time+'&m_id_com_user='+id_com_user;
const config = { method:'POST',  cahce: 'no-cache', headers:{ 'Content-Type':'application/x-www-form-urlencoded' },body:body}

try {
    const response = await(await fetch(url,config)).json();
    message.success({ content: 'Başarıyla Silindi' ,duration:0.5 }).then(()=>{window.location.reload() },1)

}

catch(e){
    console.log(e)
    message.error({ content:  'Hata Oluştu.'   ,duration:0.5 }).then(()=>  window.location.reload() ,1);
}

}


const _ChangeOrder = async (value,id)=>{
    console.log(value,id);

    let d = new Date();
    let date = d.toISOString();
    let m_date = date.split('T')[0];
    let m_time = d.toLocaleTimeString('tr-TR');


const url= api_url+'/update/com_documents';
const body = 'token='+token+'&type=user&id='+id+'&m_date='+m_date+'&m_time='+m_time+'&m_id_com_user='+id_com_user+'&order_by='+value;
const config = { method:'POST',  cahce: 'no-cache', headers:{ 'Content-Type':'application/x-www-form-urlencoded' },body:body}

try {
    const response = await(await fetch(url,config)).json();
    console.log(response)
    message.success({ content: 'Galeri Sırası Güncellendi' ,duration:0.5 }) 
}

catch(e){
    console.log(e)
    message.error({ content:  'Hata Oluştu.'   ,duration:0.5 })
}


}

const GalleryList = ()=>
 <List
    grid={{
      gutter: 16,
      xs: 1,
      sm: 2,
      md: 3,
      lg: 4,
      xl: 4,
      xxl: 8,
    }}
    dataSource={documents.sort((a,b)=>parseInt(a.order_by)-parseInt(b.order_by))}
    rowKey={row=>row.id}
    renderItem={item => (
      <List.Item>
        <Card
            cover={<img alt={item.name} src={'https://senturkotomotiv.com.tr/api/media/com_stock/'+item.name }

            />}
        actions={[
            <Button type='primary' danger onClick={()=>_DeleteDoc(item.id)} icon={<DeleteOutlined/>} loading={Loading}>Sil</Button>,
            <Button type='primary'   href={'https://senturkotomotiv.com.tr/api/media/com_stock/'+item.name } target='blank_' loading={Loading}><SearchOutlined/></Button>, 
                                     
        ]}
         title={item.title}>
            <Paragraph>{moment(item.c_date).format('DD.MM.YYYY')}</Paragraph>
            <Paragraph>Gösterim Sırası:</Paragraph>
            <Paragraph><Input type={'number'} placeholder={item.order_by} onChange={(x)=>_ChangeOrder(x.target.value,item.id)} /></Paragraph>
         </Card>
      </List.Item>
    )}
  />


    return (
        <Row>
            <Col span={24} >
            <PageHeader
                     extra={[
                        !hideAdd&& <Button key='documentadd'  size='small' type='default' shape='round'  onClick={()=>setAddModal(true)}   ><PlusOutlined/></Button>,
                    ]}
                />

            </Col>
            <Col span={24} >
                {GalleryList()}
            </Col>
            <Modal
            visible={AddModal}
            width='50%'
            title={'Fotoğraf Ekle'}
            onCancel={()=>setAddModal(false)}
            footer={null}
        >
            <Form form={addForm} labelCol={{span:6}} labelAlign='left' onFinish={_AddDocument} >
                <Row>
                    <Col span={24}>
                    <Form.Item name='formfiles'  label={'Foto Yükle'}  >
                        <Upload  multiple={true } style={'100%'}
                        >
                          <Row justify='center' gutter={12}>
                            <Col  span={24} >
                              <Button icon={<UploadOutlined />} disabled={DocumentLoaded}  loading={Loading}>{'Fotoğraf Seç'}</Button>
                            </Col>
                          </Row>
                        </Upload>
                      </Form.Item>
                    </Col>
                <Divider></Divider>
                    <Col span={24}>
                        <Row justify='end' gutter={12}>
                            <Col>
                                <Button type='default'     icon={<CloseOutlined />} loading={Loading} onClick={()=>{addForm.resetFields();setAddModal(false)}}>{'Vazgeç'}</Button>
                            </Col>
                            <Col>
                                <Button type='primary' htmlType='submit' icon={<CheckOutlined />} loading={Loading}>{'Kaydet'}</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            </Modal>

        </Row>
        )


}

export default GalleryList;
