import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
import DataTable from 'react-data-table-component';
import moment from 'moment';



class OrderformAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: false,
            id_com_interview:'',
            id_com_customer:'',
            customer_raw_data: [],
            customer_search: '',
            customer_list_data_loaded:false,
            customer_list_data:[],
            customer_detail:false,
            edit_customer_btn:false,
            id_com_sale_type:'',
            columns:[
                {
                name: 'No',
                selector: 'id',
                left:true,
                width:'75px',
                sortable:true
                },
               
                {
                name: 'Firma Adı',
                 cell : row =>(
                        <p   style={{margin:5}}>{row.company_name+' / '+row.username}</p>
                ),
                left:true,
                width:'500px'
                },
                {
                name: 'Tel No',
                selector: 'phone1',
                left:true,
                 sortable:true
                },
                {
                name: 'Vergi No',
                selector: 'taxno',
                left:true,
                 sortable:true
                },
                {
                    name: 'Kontrol',
                    cell : row =>(
                        <>
                         <button onClick={()=>{this.setState({id_com_customer:row.id,customer_detail:true});this.CustomerDetail(row.id)}} className="btn btn-primary btn-sm">Seç</button>
                        </>
                    ),
                    right:true,
                }
            ]

        }



        this.CustomerSearch=this.CustomerSearch.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.Customer_Update=this.Customer_Update.bind(this);
        this.Submit=this.Submit.bind(this);
        
    }

    componentDidMount(){
      let id_com_interview =   this.props.match.params.id_com_interview
      let id_com_customer = this.props.match.params.id_com_customer
      this.setState({id_com_interview:id_com_interview,id_com_customer:id_com_customer})
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      var urlencoded = new URLSearchParams();
      urlencoded.append("token", this.props.loginToken);

      if(id_com_customer!=='0'){
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        urlencoded.append("id", id_com_customer);
        fetch(this.props.api_url + "/selectRows/view_customer", requestOptions)
            .then(response => response.json())
            .then(result =>{
                let data = result[0];
                for (let [key, value] of Object.entries(data)) {
                this.setState({ [`${key}`]:value,pageloader:true,customer_detail:true
                    })
                }
            })
            .catch(error => console.log('error', error));

            fetch(this.props.api_url + "/selectRows/com_customer", requestOptions)
            .then(response => response.json())
            .then(result =>{
                
                this.setState({customer_raw_data:result[0]})
            })
            .catch(error => console.log('error', error));





      } else { 
        return null
    }
    }

    handleChange(event,selectlist,name) {
                if(selectlist) { 
                    this.setState({ [`${name}`]:event.id})
                } 
                else { 
        
                 if(event.target.type==='checkbox'){ 
                       event.target.checked?this.setState({[`${event.target.name}`]:'1'}):this.setState({[`${event.target.name}`]:'0'})
                                } else {
                    const value = event.target.value;
                    const key = event.target.name;
                    this.setState({ [`${key}`]:value})
                 } }
                 
                 if(event.target.name!=='id_com_sale_type') { 
                    this.setState({edit_customer_btn:true})
                 } else { 
                     return null
                 }
            }

    async CustomerSearch() {

        let url = this.props.api_url+'/customerSearch/';
        
        fetch(url, {
        method: 'POST',
         cache: 'no-cache',
        headers:  { 
        'Content-Type':  'application/x-www-form-urlencoded'
                },
        body: 'match='+this.state.customer_search+'&token='+this.props.loginToken+'&type=user',
        })

        .then(response => response.json() )
        .then(result=>
            {
                this.setState({customer_list_data:result,customer_list_data_loaded:true});
            }
            )
    }

    Customer_Update(event) {
        let d = new Date(); 
        let date = d.toISOString();
        let m_date  = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');

        let update_body = 
        'token='+this.props.loginToken+
        '&m_date='+m_date+
        '&m_time='+m_time+
        '&m_id_com_user='+this.props.id_com_user+
        '&type=user&id_com_company=1&status=1';

        for (let [key] of Object.entries(this.state.customer_raw_data)) {
            update_body +=`&${key}=`+ this.state[`${key}`]
    }
     let com_customer_url=this.props.api_url+'/update/com_customer';
    fetch(com_customer_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body:update_body,
        })
        .catch(error=>console.log(error)).then(x=> {this.setState({edit_customer_btn:false});
    }
        )
        
}


    CustomerDetail(id){
        let select_url=this.props.api_url+'/selectRows/view_customer';
       fetch(select_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
                body:  'token='+this.props.loginToken+'&type=user&id='+id
               
            }).then(res=>res.json())
            .then(result=>{
                let data = result[0];
                for (let [key, value] of Object.entries(data)) {
                this.setState({ [`${key}`]:value,pageloader:true
                    })
                }
            })
      }

      Submit(){

        let d = new Date();  
        let time = d.toLocaleTimeString('tr-TR');

      let insert_body = 
      'id_com_sale_type='+this.state.id_com_sale_type+
      '&id_com_user='+this.props.id_com_user+
      '&c_date='+moment(d).format('YYYY-MM-DD')+
      '&c_time='+time+
      '&id_com_customer='+this.state.id_com_customer+
      '&id_com_interview='+this.state.id_com_interview+
      '&token='+this.props.loginToken+'&type=user&id_com_orderform_status=1';
 let com_orderform_url=this.props.api_url+'/insert/com_orderform';
    fetch(com_orderform_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body:insert_body,
        }).catch(error=>console.log(error)).then(res=>res.json()).then(result=>
           window.location.href='/orderform/edit/'+result.id);
      }

   


    
render ( ) { 
    return ( 
        <div className="main">
            <div className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel">
                                <div className="panel-body">
                                {!this.state.customer_detail?
                                    <div>
                                <div className="form-group">
                                    <label>Müşteri Seçiniz</label>
                                    <input value={this.state.customer_search} name='customer_search' className="form-control" type="text" placeholder="TC Kimlik No, Telefon, Adı Soyadı" onChange={(event)=> {this.handleChange(event);this.CustomerSearch()}} />
                                </div>
                                <div>

                                {this.state.customer_list_data_loaded===true?

                                            <DataTable
                                                    columns={this.state.columns}
                                                    data={ this.state.customer_list_data }
                                                    fixedHeader
                                                    noHeader
                                                    overflowY={false}
                                                    overflowYOffset={'100px'}
                                                    highlightOnHover
                                                    responsive
                                                    striped
                                                    dense
                                                    paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                                   // progressPending={!this.state.pageloader}
                                                    pagination={true}
                                                    paginationPerPage={5}
                                                />
                                                          
                                                          :<></>}
                                                          </div>
                                                          </div>
                                :
                                <div>
                        
                                    <SelectList table='com_customer_type' label={'Müşteri Tipi'} name={'id_com_customer_type'} value={this.state.id_com_customer_type} onChange={(x)=>this.handleChange(x)} />
                                     <div className="form-group">
                                        <label>Firma Adı</label>
                                        <input type="text" className="form-control" name="company_name" value={this.state.company_name} onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                                    </div>        
                                     <div className="form-group">
                                        <label>Adı</label>
                                        <input type="text" className="form-control" name="name" value={this.state.name}  onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                                    </div>
                                    <div className="form-group">
                                        <label>Soyadı</label>
                                        <input type="text" className="form-control" name="surname" value={this.state.surname}  onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                                    </div>
                                    <div className="form-group">
                                        <label>Vergi Dairesi</label>
                                        <input type="text" className="form-control" name="tax_office" value={this.state.tax_office}  onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                                    </div>
                                    <div className="form-group">
                                        <label>Vergi No / TCK No</label>
                                        <input type="number" className="form-control" name="taxno" value={this.state.taxno}  onChange={(x) => this.Verification(x)} placeholder="Başlık..." required/>
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="email" className="form-control" name="email" value={this.state.email}  onChange={(x) => this.Verification(x)} placeholder="Başlık..." required/>
                                    </div>
                                    <div className="form-group">
                                        <label>Tel 1</label>
                                        <input type="text" className="form-control" name="phone1" value={this.state.phone1}  onChange={(x) => this.Verification(x)} placeholder="Başlık..." required/>
                                    </div>
                                    <SelectList table='com_city' label={'İl'} name={'id_com_city'} value={this.state.id_com_city} onChange={(x)=>this.handleChange(x)} />
                                    <SelectList table='com_town' id_com_city={this.state.id_com_city} label={'İlçe'} name={'id_com_town'} value={this.state.id_com_town} onChange={(x)=>this.handleChange(x)} />
                                    <div className="form-group">
                                        <label>Adres</label>
                                        <textarea type="text" row='10' className="form-control" name="adress" value={this.state.adress}  onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                                    </div>
                                    <div className="form-group">
                                        <label>Posta Kodu</label>
                                        <input type="number" className="form-control" name="postcode" value={this.state.postcode}  onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                                    </div>
                                    <SelectList table='com_meet' label={'Temas Türü'} name={'id_com_meet'} value={this.state.id_com_meet} onChange={(x)=>this.handleChange(x)} />
                                    <div className="form-group">
                                        <label>Açıklama</label>
                                        <textarea type="text" row='10' className="form-control" name="comment" value={this.state.comment} onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                                    </div>
                                    {this.state.edit_customer_btn?
                                    <button className="btn btn-success pull-right" 
                                 onClick={(event)=>this.Customer_Update(event)}
                                    > Müşteri Kartını Güncelle</button> :<></>}
                                </div>
                                }
                                <div className="panel-body">
                                <SelectList table='com_sale_type' dealer={true} label={'Satış Dosya Tipi'} name={'id_com_sale_type'} onChange={(x)=>this.handleChange(x)} />
                                </div>


                                </div>
                                <div className="panel-footer">
                                <a className="btn btn-danger" 
                                  href={'/orderform/list'}
                                    >Geri</a>
                                <button className="btn btn-primary pull-right" 
                                disabled={this.state.id_com_sale_type===''||this.state.id_com_customer==='0'}
                                 onClick={()=>this.Submit()}
                                    >Oluştur</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}


}

export default connect(mapStateToProps)(OrderformAdd);