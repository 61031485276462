import React ,{useState,useEffect}from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import Wrapper from '../utilities/Wrapper';
import {Row,Col,Button,Descriptions,Tag,Form,Input, DatePicker,Divider, Switch,message,Tabs, PageHeader, Typography, Checkbox, Spin} from 'antd';
import { EditOutlined,UserOutlined,SearchOutlined,LeftOutlined} from '@ant-design/icons';
import * as CurrencyFormat from "react-currency-format";

import moment from 'moment';
import SelectList from '../utilities/SelectList';

import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';

import TransactionList from './TransactionList';
import GalleryList from './GalleryList';
import ExpertList from './ExpertList'; 

const {TabPane} = Tabs;
const {Text,Paragraph}=Typography;
const  UsedCarDetail = ({match,api_url,loginToken,id_com_user,media_url})=> {

const [detailForm]=Form.useForm()
const [Data,setData] = useState([])
const [Loading,setLoading] = useState(false)
const [Id,setId] = useState('')
const [addStockModal,setaddStockModal] = useState(false)
const [IdComBrand,setIdComBrand] = useState('')
const [Types,setTypes] = useState([]);
const [DetailData,setDetailData] = useState([]);
const [TransactionsData,setTransactionsData] = useState([]);
const [Documents,setDocuments] = useState([]);
const [CounterLine,setCounterLine] = useState({})

useEffect(() => {
    const _onLoad = ()=>{

        _getData()
    }
   return _onLoad();
}, [])


const _getData = async ()=>{
    setLoading(true)
    const id = match.params.id;
    setId(id)
    const view_body_url= api_url+'/selectRows/view_stock';
    const body = 'token='+loginToken+'&type=user&id='+id;
    const config = { method:'POST',  cahce: 'no-cache', headers:{ 'Content-Type':'application/x-www-form-urlencoded' },body:body}

    try { 
        const response = await(await fetch(view_body_url,config)).json();
        setIdComBrand(response[0].id_com_brand)
 
        const details_data = response[0].id_com_detail.split(',')
        let detail_obj = {}
        details_data.map(row=>{
            detail_obj[`${row}`]=true
        })

        detailForm.setFieldsValue(detail_obj)

        setData(response[0])
        _GetTransactions(id)
        _GetDocuments(id)
        _GetCounter(id)
        _GetDetails()
    }   

    catch(e){
        console.log(e)
    }
}  

const _GetDetails = async ()=>{

    const view_body_url= api_url+'/selectRows/view_stock_detail';
    const body = '&token='+loginToken+'&type=user';
    const config = { method:'POST',  cahce: 'no-cache', headers:{ 'Content-Type':'application/x-www-form-urlencoded' },body:body}

    try { 
        const response = await(await fetch(view_body_url,config)).json();
        const Types = []
        response.map(row=>{
            Types.push(row.id_com_stock_detail_type_name)
        })
        const u_Types = [...new Set(Types)]
        setTypes(u_Types)
        setDetailData(response)
        setLoading(false)
    }   

    catch(e){
        console.log(e)
    }

}
 
 
const _ChangeWebStatus = async (status)=>{
 
    const show_web = status?1:0;
    const url= api_url+'/update/com_stock';
    const body = '&token='+loginToken+'&type=user&id='+Id+'&show_web='+show_web;
    const config = { method:'POST',  cahce: 'no-cache', headers:{ 'Content-Type':'application/x-www-form-urlencoded' },body:body}
 
    try { 
        const response = await(await fetch(url,config)).json();
        console.log(response)
        if(response.status){
            _getData()
        }
     }   

    catch(e){
        console.log(e)
    }

}

const _GetTransactions = async (id) =>{
 
    const url= api_url+'/selectRows/view_transaction';
    const body = 'token='+loginToken+'&type=user&id_com_car='+id;
    const config = { method:'POST',  cahce: 'no-cache', headers:{ 'Content-Type':'application/x-www-form-urlencoded' },body:body}

    try { 
        const response = await(await fetch(url,config)).json();
        setTransactionsData(response)
    }   

    catch(e){
        console.log(e)
    }


}
 


const _GetDocuments = async (id) =>{
        const url= api_url+'/selectRows/view_documents';
        const body = 'token='+loginToken+'&type=user&component=com_stock&item_id='+id;
        const config = { method:'POST',  cahce: 'no-cache', headers:{ 'Content-Type':'application/x-www-form-urlencoded' },body:body}
    
        try { 
            const response = await(await fetch(url,config)).json();
            setDocuments(response)
        }   
    
        catch(e){
            console.log(e)
        }
    
    
    }
     
    


const _GetCounter = async (id) =>{
    const url= api_url+'/selectRows/view_web_analytics';
    const body = 'token='+loginToken+'&type=user&id_com_stock='+id;
    const config = { method:'POST',  cahce: 'no-cache', headers:{ 'Content-Type':'application/x-www-form-urlencoded' },body:body}

    try { 
        const response = await(await fetch(url,config)).json();
         if(response.legnth>0){
            setCounterLine(response[0])

        } else { 
            setCounterLine({
                count_:0,
                url:'/'
            })

        }
    }   

    catch(e){
        console.log(e)
    }


} 


    


const _SaveDetail = async ()=>{
    const form_data = detailForm.getFieldsValue()
     const details = []
    for ( const [ key,value] of Object.entries(form_data)){ 
        if(value===true){
            details.push(key)
        }
    }
    let str_detail ='';
    if(details.length>0){


        details.map((row,idx)=>{
            if(idx===0){
                str_detail +=`${row}`
            } else { 
                str_detail +=`,${row}`
            }
        })
    }
    else { 
        str_detail +='0'
    }


    const url= api_url+'/update/com_stock';
    const body = '&token='+loginToken+'&type=user&id='+Id+'&id_com_detail='+str_detail;
    const config = { method:'POST',  cahce: 'no-cache', headers:{ 'Content-Type':'application/x-www-form-urlencoded' },body:body}
 
    try { 
        const response = await(await fetch(url,config)).json();
     }   

    catch(e){
        console.log(e)
    }
 }


 const Spinner = ()=> 
    <Row style={{height:500,width:'100%'}} justify='center'>
        <Col span={4} style={{padding:10,height:'100%',marginTop:200}}> 
            <Spin size='large' tip={'Data Yükleniyor'}/>
        </Col>
    </Row>
        return ( 
            <Wrapper subtitle={`Araç Dosya No : ${Data.doc_number}`} title={'İkinci El Stok Kartı |'} extra={!Loading&&[
                 <Button type='default' key='back' danger  onClick={()=>window.history.back()} icon={<LeftOutlined  />} >Geri</Button>,
                 <Button type='default' key='edit'  href={'/usedcars/Edit/' +Data.id} icon={<EditOutlined/>} >Düzenle</Button>,

             ]}>
             {Loading?Spinner():
                <Row>
                    <Col span={24} style={{padding:10}}> 
                        <Tabs defaultActiveKey='1' type='card'>
                            <TabPane key='1' tab='Araç Bilgileri'>
                                <Row>
                                    <Col span={24} style={{padding:20}}>
                                            <PageHeader  title={ <>
                                                    <Tag key='tag' color='red'  >{Data.id_com_stock_status_name }</Tag>
                                                    <Text key='counter' style={{fontSize:14}}>  | Web Gösterim Adedi : {CounterLine.count_}</Text>
                                                   
                                                    {/* <Paragraph key='counter'></Paragraph>
                                                    <Paragraph key='url'>{CounterLine.url}</Paragraph> */}
                                                    </>
                                            }
                                                extra={[
                                                    <Switch  key='web_show' checkedChildren="Web Yayında" unCheckedChildren="Web Yayında Değil" onChange={(e)=>_ChangeWebStatus(e)} defaultChecked={Data.show_web==='1'?true:false} />,
                                                    Data.show_web==='1'&& <Button size='small' type='primary' shape='round' href={CounterLine.url} target='blank_' style={{marginLeft:10}}><SearchOutlined/>ilana Git</Button>
                                                ]}
                                            />
                                               
                                           <Descriptions size='small' bordered  layout='vertical' labelStyle={{fontWeight:'bold'}} column={5} >
                                                <Descriptions.Item span={1} label='Araç Tipi / Kasa Tipi'>
                                                    {Data.id_com_stock_class_name+' - '+Data.id_com_stock_group_name} 
                                                </Descriptions.Item>
                                                <Descriptions.Item span={2} label='Araç Bilgileri'>
                                                    {Data.id_com_brand_name+' - '+Data.id_com_model_name_+' '+Data.spec} 
                                                </Descriptions.Item>
                                                <Descriptions.Item span={1} label='Model Yılı'>
                                                    {Data.model_year} 
                                                </Descriptions.Item>
                                                <Descriptions.Item span={1} label='Satış Listeleme Fiyatı'>
                                                    <CurrencyFormat value={Math.round(parseFloat(Data.list_price)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/>
                                                </Descriptions.Item>
                                                <Descriptions.Item span={1} label='Şase'>
                                                    {Data.chasis} 
                                                </Descriptions.Item>
                                                <Descriptions.Item span={1} label='Kilometre '>
                                                    {Data.km}
                                                </Descriptions.Item>
                                                <Descriptions.Item span={1} label='Plaka '>
                                                    {Data.license_plate}
                                                </Descriptions.Item>
                                                <Descriptions.Item span={2} label='Araç Bilgileri 2 '>
                                                    {' Vites Tipi : '+Data.id_com_gear_name+' / Yakıt Tipi: '+Data.id_com_fuel_name+' / Renk: '+Data.id_com_color_name}
                                                </Descriptions.Item>
                                                <Descriptions.Item span={1} label='Satın Alma Tarihi'>
                                                    {moment(Data.legal_date).format('DD.MM.YYYY')}
                                                </Descriptions.Item>
                                                <Descriptions.Item span={1} label='Alım Tipi - Nedeni'>
                                                    {Data.id_com_stock_purchase_type_name+' - '+Data.id_com_stock_purchase_reason_name} 
                                                </Descriptions.Item>
                                                <Descriptions.Item span={1} label='Belge No'>
                                                    {Data.inv_number}
                                                </Descriptions.Item>
                                                <Descriptions.Item span={1} label='Satın Alınan Müşteri Bilgisi'>
                                                    {Data.id_com_purchase_company_name+' - '+Data.id_com_purchase_customer_name}
                                                    <Button type='primary' shape='round' size='small' style={{marginLeft:10}} icon={<UserOutlined/>} href={'/customer/detail/'+Data.id_com_purchase_customer}></Button>
                                                </Descriptions.Item>
                                                <Descriptions.Item span={1} label='Alış Fiyatı'>
                                                    <CurrencyFormat value={Math.round(parseFloat(Data.inv_price)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/>
                                                </Descriptions.Item>
                                                <Descriptions.Item span={1} label='Bagaj Hacmi (m3)'>
                                                    {Data.trunk_capacity}
                                                </Descriptions.Item>
                                                <Descriptions.Item span={1} label='Çekiş Gücü (Hp)'>
                                                    {Data.hp}
                                                </Descriptions.Item>
                                                <Descriptions.Item span={1} label='Motor Hacmi (cc)'>
                                                    {Data.engine_power}
                                                </Descriptions.Item>
                                                <Descriptions.Item span={1} label='Şehir içi YT'>
                                                    {Data.consumption_1}
                                                </Descriptions.Item>
                                                <Descriptions.Item span={1} label='Şehir Dışı YT'>
                                                    {Data.consumption_2}
                                                </Descriptions.Item>
                                                <Descriptions.Item span={3} label='Hasar Bilgisi '>
                                                    {Data.damage_report} 
                                                </Descriptions.Item>
                                                <Descriptions.Item span={2} label='Notlar '>
                                                    {Data.comment} 
                                                </Descriptions.Item>
                                            </Descriptions>
                                    </Col> 
                                </Row>
                            </TabPane>
                            <TabPane key='2' tab='Galeri'>
                                <Row>
                                    <Col span={24} style={{padding:20}}>
                                        <GalleryList id_com_car={Id} documents={Documents} id_com_user={id_com_user } token={loginToken} api_url={api_url} media_url={media_url} />
                                    </Col> 
                                </Row>
                            </TabPane>
                            <TabPane key='3' tab='Ekspertiz Raporu'>
                                <Row>
                                    <Col span={24} style={{padding:20}}>    
                                            <ExpertList api_url={api_url} token={loginToken} id_com_user={id_com_user} id={Id} expert_form={Data.expert_form} />
                                    </Col> 
                                </Row>
                            </TabPane>
                            <TabPane key='4' tab='Donanım - Opsiyonlar'>
                                <Row>
                                    <Col span={24} style={{padding:20}}>
                                        <Form form={detailForm} size='small' layout='inline' labelAlign='left' labelCol={{span:12}} onFieldsChange={_SaveDetail}>
                                            {Types.map((types,idx)=>{
                                                return(
                                                    <React.Fragment key={idx}>
                                                        <Divider>{types}</Divider>
                                                        {
                                                            DetailData.filter(row=>row.id_com_stock_detail_type_name===types).map((detail,index)=>{
                                                                return (
                                                                    <Col xl={8} lg={8} md={12} sm={24} xs={24} style={{borderWidth:2,borderColor:'black',padding:10}}>
                                                                        <Form.Item name={detail.id} label={detail.title} valuePropName='checked'>
                                                                            <Checkbox style={{float:'right'}}/>
                                                                        </Form.Item>
                                                                    </Col> 
                                                                )
                                                            })
                                                        }
                                                    </React.Fragment>
                                                    )
                                            })}
                                        </Form>
                                    </Col>  
                                </Row>
                            </TabPane>
                            <TabPane key='5' tab='Stok Maliyetleri'>
                                <Row>
                                    <Col span={24} style={{padding:20}}>
                                        <TransactionList transactions={TransactionsData} inv_price={Data.inv_price} inter_data={[]} id_com_car={Id} id_com_user={id_com_user } token={loginToken} api_url={api_url}  />
                                    </Col> 
                                </Row>
                            </TabPane>
                        </Tabs>
                    </Col> 
                </Row>}
            </Wrapper>
         

        )
    }
 

export default connect(mapStateToProps)(UsedCarDetail);
 