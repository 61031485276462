import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
import DataTable from 'react-data-table-component';
import DatePicker from '../utilities/DatePicker';
class FeedBackAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customerListData: [],
            CustomerListLoaded: false,
            searchItem: '',
            customerDetail: false,
            selectedCustomer: '',
            customerData: [],
            carsData: [],
            id_com_brand: '',
            id_com_department: '',
            comment: '',
            columns: [
                {
                    name: 'No',
                    selector: 'id',
                    sortable: true,
                    left: true,
                },
                {
                    name: 'Firma  Adı',
                    selector: 'company_name',
                    sortable: true,
                    left: true,
                },
                {
                    name: 'Müşteri  Adı',
                    selector: 'username',
                    sortable: true,
                    left: true,
                },
                {
                    name: 'Telefon',
                    selector: 'phone1',
                    sortable: true,
                    left: true,
                },
                {
                    name: 'Vergi No',
                    selector: 'taxno',
                    sortable: true,
                    left: true,
                },
                {
                    name: 'Kontrol',
                    cell: (row) => <button onClick={() => this.CustomerSelected(row.id)}
                                           className="btn btn-primary btn-sm btn-block">Seç</button>
                }
            ]
        }
        this.handleChange = this.handleChange.bind(this);
        this.DatePick = this.DatePick.bind(this);
        this.Search = this.Search.bind(this);
        this.CustomerSelected = this.CustomerSelected.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(event, selectlist, name) {
        if (selectlist) {
            this.setState({[`${name}`]: event.id})
        } else {
            const value = event.target.value;
            const key = event.target.name;
            this.setState({[`${key}`]: value})
        }
    }
    async Search(value) {
        this.setState({searchItem: value})
        let url = this.props.api_url + '/customerSearch';
        fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'match=' + value + '&token=' + this.props.loginToken,
        })
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(customerListData => {
                    this.setState({customerListData: customerListData, CustomerListLoaded: true});
                }
            )
    }
    async CustomerSelected(x) {
        this.setState({selectedCustomer: x, customerDetail: true, searchItem: ''})
        let customer_url = this.props.api_url + '/selectRows/view_customer';
        let cars_url = this.props.api_url + '/selectRows/view_cars';
        fetch(customer_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id=' + x + '&token=' + this.props.loginToken + '&type=user',
        }).then(res => res.json())
            .then(result => {
                // console.log(result)
                this.setState({customerData: result[0]})
            })
    }
    handleSubmit() {
        let d = new Date();
        let date = d.toISOString();
        let c_date = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let insert_body =
            'token=' + this.props.loginToken +
            '&c_date=' + c_date +
            '&c_time=' + c_time +
            '&c_id_com_user=' + this.props.id_com_user +
            '&id_com_customer=' + this.state.selectedCustomer +
            '&type=user&id_com_company=1&id_com_feedback_status=1';
        for (let [key] of Object.entries(this.state)) {
            if (key !== 'disabled' && key !== 'columns' && key !== 'customerListData' && key !== 'CustomerListLoaded' && key !== 'searchItem' && key !== 'customerDetail' && key !== 'selectedCustomer' && key !== 'customerData' && key !== 'carsData'
            ) {
                insert_body += `&${key}=` + this.state[`${key}`]
            }
        }
        let com_feedback_url = this.props.api_url + '/insert/com_feedback';
        fetch(com_feedback_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: insert_body,
        })
            .catch(error => console.log(error)).then(e => e.json()).then(result => {
            if (result.status) {
                window.location.href = '/Feedback/Details/' + result.id
            }
        })
    }
    DatePick(value, type) {
        switch (type) {
            case 'date':
                let newdate = new Date(value)
                newdate.setDate(newdate.getDate())
                let dist_date = newdate.toISOString().split('T')[0]
                this.setState({dist_date: dist_date})
                return null
            case 'time':
                this.setState({dist_time: value.target.value})
                return null
            default:
                return null
        }
    }
    render() {
        return (
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="page-title">Başvuru Ekle</h3>
                            </div>
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">Müşteri Seçiniz</h3>
                                    </div>
                                    <div className="panel-body">
                                        {this.state.selectedCustomer === '' ?
                                            <React.Fragment>
                                                <div className="form-group">
                                                    <input value={this.state.searchItem} className="form-control"
                                                           type="text" placeholder="TC Kimlik No, Telefon, Adı Soyadı"
                                                           onChange={(x) => this.Search(x.target.value)}/>
                                                </div>

                                                {this.state.searchItem !== '' ?
                                                    <DataTable
                                                        data={this.state.customerListData}
                                                        columns={this.state.columns}
                                                        highlightOnHover
                                                        responsive
                                                        striped
                                                        dense
                                                        pagination
                                                        paginationPerPage={10}

                                                    />
                                                :
                                                    <></>
                                                }
                                            </React.Fragment>
                                        :
                                            <></>
                                        }
                                        {this.state.selectedCustomer !== '' ?
                                            <React.Fragment>
                                                <table className="table table-bordered table-striped">
                                                    <tbody>
                                                    {this.state.customerData.username != null ?
                                                        <tr>
                                                            <th>Müşteri Adı</th>
                                                            <td>{this.state.customerData.username}</td>
                                                        </tr>
                                                        :
                                                        <tr>
                                                            <th>Müşteri Adı</th>
                                                            <td>-</td>
                                                        </tr>
                                                    }
                                                    {this.state.customerData.phone1 != null ?
                                                        <tr>
                                                            <th>Telefon - Email</th>
                                                            <td>{this.state.customerData.phone1 + ' - ' + this.state.customerData.email}</td>
                                                        </tr>
                                                        :
                                                        <tr>
                                                            <th>Telefon - Email</th>
                                                            <td>-</td>
                                                        </tr>
                                                    }
                                                    </tbody>
                                                </table>
                                                <div className="form-group">
                                                    <label>Başvuru Metni</label>
                                                    <textarea type="text" className="form-control" name="comment"
                                                              onChange={(x) => this.handleChange(x)}
                                                              placeholder="Başlık..." required/>
                                                </div>
                                                <SelectList table='com_feedback_type' label={'Başvuru Tipi'}
                                                            name={'id_com_feedback_type'}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                <SelectList table='com_importance' label={'Önem Derecesi'}
                                                            name={'id_com_importance'}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                <SelectList table='com_meet' label={'Temas Türü'}
                                                            name={'id_com_meet'}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                {this.state.id_com_meet === '15' ?
                                                    <>
                                                        <div className="form-group">
                                                            <label>Distributor Aktarım Tarihi</label>
                                                            <DatePicker onChange={(x) => this.DatePick(x, 'date')}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Distributor Aktarım Saati</label>
                                                            <input type='time' className={'form-control'}
                                                                   onChange={(x) => this.DatePick(x, 'time')}/>
                                                        </div>
                                                    </>
                                                    : <></>
                                                }
                                                <SelectList table='view_cars_sl'
                                                            id_com_customer={this.state.selectedCustomer}
                                                            label={'Arac Seçiniz'} name={'id_com_cars'}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                <SelectList table='com_feedback_subject' label={'Konu Seçiniz 1'}
                                                            name={'id_com_feedback_subject1'}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                <SelectList table='com_feedback_subject' label={'Konu Seçiniz 2'}
                                                            name={'id_com_feedback_subject2'}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                <SelectList table='com_feedback_subject' label={'Konu Seçiniz 3'}
                                                            name={'id_com_feedback_subject3'}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                <SelectList table='view_brand_sl' label={'Marka Seçiniz'}
                                                            name={'id_com_brand'}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                <SelectList table='com_department' label={'Departman Seçiniz'}
                                                            name={'id_com_department'}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                <SelectList table='view_user_sl' label={'Atanacak Yetkili 1'}
                                                            name={'r_id_com_user1'}
                                                            id_com_brand={this.state.id_com_brand}
                                                            id_com_department={this.state.id_com_department}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                <SelectList table='view_user_sl' label={'Atanacak Yetkili 2'}
                                                            name={'r_id_com_user2'}
                                                            id_com_brand={this.state.id_com_brand}
                                                            id_com_department={this.state.id_com_department}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                <SelectList table='view_user_sl' label={'Atanacak Yetkili 3'}
                                                            name={'r_id_com_user3'}
                                                            id_com_brand={this.state.id_com_brand}
                                                            id_com_department={this.state.id_com_department}
                                                            onChange={(x) => this.handleChange(x)}/>
                                            </React.Fragment>
                                        :
                                            <></>
                                        }
                                        <div className="panel-footer">
                                            <a className="btn btn-danger" href="/Feedback/List">İptal</a>
                                            <button className="btn btn-primary pull-right"
                                                    disabled={this.state.comment !== '' && this.state.id_com_feedback_type && this.state.id_com_brand && this.state.id_com_feedback_subject1 && this.state.id_com_department && this.state.r_id_com_user1 && this.state.id_com_importance && this.state.id_com_meet ? false : true}
                                                    onClick={() => this.handleSubmit()}>Kaydet</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(mapStateToProps)(FeedBackAdd);