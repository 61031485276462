import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
class DepotCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoader: false,
            stock_line_body: [],
            stored_quantity: 0,
            damaged: 0,
            missing: 0,
            id_com_depot: '',
            id_depot_part_number: '',
            dispatch: ''
        }
        this._Update = this._Update.bind(this)
        this._Submit = this._Submit.bind(this)

    }

    _Submit() {
        let d = new Date();
        let date = d.toISOString();
        let m_date = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');
        let m_id_com_user = this.props.id_com_user;
        let id_depot_part_number = this.state.id_depot_part_number;
        let id = this.state.stock_line_body.id;
        let waiting = parseFloat(this.state.waiting);
        let dispatch = parseFloat(this.state.dispatch);

        let update =
            'id=' + id +
            '&m_date=' + m_date +
            '&m_time=' + m_time +
            '&m_id_com_user=' + m_id_com_user +
            '&id_depot_part_number=' + id_depot_part_number +
            '&dispatch=' + (dispatch + waiting) +
            '&token=' + this.props.loginToken + '&type=user';


        let update_url = this.props.api_url + '/update/com_stock_lines'

        console.log(update)
        fetch(update_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: update,
        })
            .catch(error => console.log(error))
            .then(res => window.location.href = '/DepotSale')

    }

    componentDidMount() {
        this._Update();
    }

    async _Update() {
        let id_com_stock_lines = this.props.match.params.SaleId
        let id_com_depot = this.props.match.params.DepotId


        this.setState({
            id_com_stock_lines: this.props.match.params.SaleId,
            id_com_depot: this.props.match.params.DepotId
        })

        let url_stock_line = this.props.api_url + '/selectRows/view_stock_lines';
        let stock_line_body = 'id=' + id_com_stock_lines + '&token=' + this.props.loginToken + '&type=user&id_com_company=' + this.props.id_com_company + '&id_com_depot=' + id_com_depot;


        await fetch(url_stock_line, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: stock_line_body
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {

                let data = result.filter(row => parseFloat(row.waiting))

                this.setState({
                    sale_quantity: parseFloat(data[0].sale_quantity),
                    stock_line_body: data[0],
                    id_com_product: data[0].id_com_product,
                    dispatch: parseFloat(data[0].dispatch),
                    waiting: parseFloat(data[0].waiting),
                    pageloader: true
                })
                console.log(data[0])

            })
    }

    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="page-header">
                                        <div className="page-title">Fatura Detayı</div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <div className="panel-title">Fatura Detayı</div>
                                        </div>
                                        <div className="panel-body">
                                            <table className="table table-bordered table-striped">
                                                <tbody>
                                                    <tr>
                                                        <th width="200">Fatura Bilgileri</th>
                                                        <td>{this.state.stock_line_body.invoice_date + ' / ' + this.state.stock_line_body.inv_number + ' / ' + this.state.stock_line_body.company_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Malzeme Bilgileri</th>
                                                        <td>{this.state.stock_line_body.id_com_product_class_name + ' / ' + this.state.stock_line_body.code + ' / ' + this.state.stock_line_body.id_com_product_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Satış Depo / Satış Adedi</th>
                                                        <td>{this.state.stock_line_body.id_com_depot_name + ' / ' + this.state.stock_line_body.sale_quantity}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Teslim Edilen / Bekleyen Adet</th>
                                                        <td>{this.state.stock_line_body.dispatch + '  -  ' + this.state.stock_line_body.waiting}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Satış Tutarı</th>
                                                        <td>{this.state.stock_line_body.sale_price}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Ort Maliyeti</th>
                                                        <td>{(parseFloat(this.state.stock_line_body.stock_level) - parseFloat(this.state.stock_line_body.purchase_quantity)) > 0 ? this.state.stock_line_body.avg_cost : 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Kalan Stok Adedi</th>
                                                        <td>{parseFloat(this.state.stock_line_body.stock_level) - parseFloat(this.state.stock_line_body.purchase_quantity)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Depo Rafı</th>
                                                        <td><SelectList id_com_product={this.state.id_com_product} id_com_depot={this.state.id_com_depot} id_com_company={this.props.id_com_company} table='view_depot_part_list' onChange={(x) => this.setState({id_depot_part_number: x.id})}/></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Çıkış Adedi</th>
                                                        <td><input type='number' className="uk-input " value={this.state.waiting}
                                                                   onChange={(x) => {
                                                                       if (parseFloat(x.target.value) < 0) {
                                                                           alert('Negatif Değer Alamaz!');
                                                                           this.setState({waiting: this.state.stock_line_body.waiting})
                                                                       } else if (parseFloat(x.target.value) >= parseFloat(this.state.stock_line_body.waiting)) {
                                                                           alert('Satış Adedinden Fazla Olamaz!');
                                                                           this.setState({waiting: this.state.stock_line_body.waiting})
                                                                       } else {
                                                                           this.setState({waiting: x.target.value})
                                                                       }
                                                                   }}/>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <a className="btn btn-danger" href='/DepotSale'>İptal</a> <button disabled={this.state.id_depot_part_number === '' || parseFloat(this.state.waiting) === 0 ? true : false} className="btn btn-primary" onClick={() => this._Submit()}>Kaydet</button>                                                               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(DepotCard);