import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component';
import SelectList from '../utilities/SelectList';

class TaskList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: false,
            clicked:false,
            type1_data:[],
            type2_data: [],
            filtered:false,
            filtered_data1:[],
            filtered_data2:[],
            id_com_task_status:[],
            id_com_task_subject:[],
            id_com_user:[],
            id_com_task_type:[],
            type1:[
                {
                    name: 'No',
                    selector: 'id',
                    width: '50px',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Görev Tipi',
                    selector: 'id_com_task_type_name',
                    left:true,
                    width: '100px',
                    sortable:true
                },
                {
                    name: 'Görev Konusu',
                    selector: 'id_com_task_subject_name',
                    left:true,
                    sortable:true,
                    width: '75px',

                },
                {
                    name: 'Görev Tanımı',
                    selector: 'comment',
                    left:true,
                    width: '600px',
                    wrap:true,
                    sortable:true
                },
                {
                    name: 'Görev Sorumlusu',
                    selector: 'r_id_com_user_name',
                    left:true,
                    wrap:true,
                    sortable:true
                },
                {
                    name: 'Yönetici',
                    selector: 'manager',
                    left:true,
                    wrap:true,
                    width: '150px',
                    sortable:true
                },
                {
                    name: 'Durum',
                    selector: 'id_com_task_status_name',
                    left:true,
                    sortable:true
                },

                {
                    name: 'Kontrol',
                    cell : row =>(
                        <>
                         <a className="btn btn-primary btn-sm" type="button" href={'/task/edit/'+row.id} style={{margin:5}}><i class="fas fa-edit"></i></a>

                        </>
                    ),
                    right:true,
                }
            ],
            type2:[
                {
                    name: 'No',
                    selector: 'id',
                    width: '75px',
                    left:true,
                    sortable:true
                },

                {
                    name: 'Görev Konusu',
                    selector: 'id_com_task_subject_name',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Görev Tanımı',
                    selector: 'comment',
                    left:true,
                    wrap:true,
                    width: '600px',
                    sortable:true
                },
                {
                    name: 'Görev Sorumlusu',
                    selector: 'r_id_com_user_name',
                    left:true,
                    wrap:true,
                    sortable:true
                },
                {
                    name: 'Hedef Tamamlanma Tarihi',
                    selector: 'target_date',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Atayan',
                    selector: 'id_com_user_name',
                    left:true,
                    wrap:true,
                    sortable:true
                },
                {
                    name: 'Durumu',
                    left:true,
                    sortable:true,
                    cell : row =>(
                        row.status==='0'?
                        row.id_com_task_status_name
                        :
                        row.end_date+' / Tarihinde Tamamlandı.'
                    ),
                },
                {
                    name: 'Kontrol',
                    cell : row =>(
                        row.status==='1'?
                        <button className="btn btn-danger btn-sm" type="button" disabled={true} ><i class="fas fa-check-double"></i></button>
                        : <>
                        <button className="btn btn-success btn-sm" type="button" onClick={()=>this._CompleteTask(row.id)} style={{margin:5}}><i class="fas fa-check"></i></button>
                        <a className="btn btn-primary btn-sm" type="button" href={'/task/edit/'+row.id} style={{margin:5}}><i class="fas fa-edit"></i></a>
                        </>
                    ),
                    right   :true,
                }
            ]
        }
        this._Update=this._Update.bind(this)
        this.handleChange=this.handleChange.bind(this)
        this.Filter_Data=this.Filter_Data.bind(this)
    }

    componentDidMount(){
        this._Update()
    }

    _CompleteTask(id){
        let d = new Date(); 
        let date = d.toISOString();
        let end_date  = date.split('T')[0];
        let end_time = d.toLocaleTimeString('tr-TR');

        let task_url=this.props.api_url+'/update/com_task';
        fetch(task_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body:'token='+this.props.loginToken+
          '&id='+id+
          '&end_date='+end_date+
          '&end_time='+end_time+
          '&id_com_task_status=3&status=1&type=user',
        })
        .catch(error=>console.log(error)).then(x=>window.location.reload())
    }

 


    handleChange(event,selectlist,name) {        
        if(selectlist) { 
            this.setState({ [`${name}`]:event.id})
        } 
        else { 

         if(event.target.type==='checkbox'){ 
               event.target.checked?this.setState({[`${event.target.name}`]:'1'}):this.setState({[`${event.target.name}`]:'0'})
                        } else {
            const value = event.target.value;
            const key = event.target.name;
            this.setState({ [`${key}`]:value})
         } }
    }
    _Update(){
        let task_url=this.props.api_url+'/selectRows/view_task';
        fetch(task_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
              body:'token='+this.props.loginToken+'&id_com_user='+this.props.id_com_user+'&type=user',
            })
            .catch(error=>console.log(error)).then(x=> x.json()).then(result=>{
                
               let  type2_data =  result.filter(row=>row.id_com_task_type==='0')
               let  type1_data =  result.filter(row=>row.id_com_task_type!=='0')

                this.setState({data:result,
                    type1_data:type1_data,
                    type2_data:type2_data,
                    pageloader:true})
            
            
            })
            
    }

    Filter_Data(){

        let type1_data = this.state.type1_data
        let type2_data = this.state.type2_data



        let  difference1_type1 =this.state.id_com_task_type.length===0?type1_data:type1_data.filter(row => this.state.id_com_task_type.find(row2 => row.id_com_task_type === row2.id ));
        let  difference2_type1 =this.state.id_com_task_subject.length===0?difference1_type1:difference1_type1.filter(row => this.state.id_com_task_subject.find(row2 => row.id_com_task_subject === row2.id ))
        let  difference3_type1 =this.state.id_com_user.length===0?difference2_type1:difference2_type1.filter(row => this.state.id_com_user.find(row2 => row.r_id_com_user === row2.id ))
        let  difference4_type1 =this.state.id_com_task_status.length===0?difference3_type1:difference3_type1.filter(row => this.state.id_com_task_status.find(row2 => row.id_com_task_status === row2.id ))

         
        let  difference2_type2 =this.state.id_com_task_subject.length===0?type2_data:type2_data.filter(row => this.state.id_com_task_subject.find(row2 => row.id_com_task_subject === row2.id ))
        let  difference3_type2 =this.state.id_com_user.length===0?difference2_type2:difference2_type2.filter(row => this.state.id_com_user.find(row2 => row.r_id_com_user === row2.id ))
        let  difference4_type2 =this.state.id_com_task_status.length===0?difference3_type2:difference3_type2.filter(row => this.state.id_com_task_status.find(row2 => row.id_com_task_status === row2.id ))

         this.setState({filtered:true,filtered_data1:difference4_type1,filtered_data2:difference4_type2})
    }


        render () { 
            return ( 
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">Görev Listesi</h3>
                                        <a className='btn btn-info btn-sm pull-right' href='/task/add'    style={{margin:5}}> Ekle</a>
                                        <a className={!this.state.clicked?"btn btn-primary btn-sm pull-right":"btn btn-success btn-sm pull-right"}
                                                data-toggle="collapse" 
                                                href={`#filter`}
                                                role="button" 
                                                aria-expanded="false" 
                                                aria-controls={this.props.id} 
                                                onClick={()=>this.setState({clicked:!this.state.clicked})}
                                                style={{margin:5}}
                                                >Filtre Getir   
                                    <i className={!this.state.clicked?"fas fa-plus-circle  ":"fas fa-minus-circle  "}  style={{marginLeft:5}} > </i>    </a>
                                    </div>

                                    <div className="panel-body"> 
                                    <div className="panel-body"> 
                                    <div  className="collapse" id='filter'> 
                                    <div className="row">
                                    <div className="col-md-3">
                                    <SelectList table='com_task_type'  multiple={true}  Label={'Yenileme Zamanı'}   onChange={(x)=>{this.setState({id_com_task_type: x});}}   />
                                    </div>
                                    <div className="col-md-3">
                                    <SelectList table='com_task_subject'  multiple={true}  Label={'Görev Konusu'}   onChange={(x)=>{this.setState({id_com_task_subject: x});}}  />
                                    </div>
                                    <div className="col-md-3">
                                    <SelectList table='view_user_sl2' id_com_user_manager={this.props.id_com_user} multiple={true} Label={'Atanan Yetkililer'}  onChange={(x)=>{this.setState({id_com_user: x});}}    />
                                    </div>
                                    <div className="col-md-3">
                                    <SelectList table='com_task_status' multiple={true} Label={'Durum'}   onChange={(x)=>{this.setState({id_com_task_status: x});}}    />
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-12">
                                    
                                     <button className={this.state.filtered? "btn btn-danger btn-lg btn-block ":"btn btn-primary btn-lg btn-block "} type="button" onClick={()=>this.state.filtered?this.setState({
                                         filtered:false,id_com_task_status:[],id_com_user:[],id_com_task_subject:[],id_com_task_type:[]
                                     }) :this.Filter_Data()}><i class={this.state.filtered?"fas fa-times":"fas fa-search"} style={{marginRight:10}}></i>{this.state.filtered?'Filtreyi Temizle' :'Filtrele'}</button>
                                    
                                     </div>
                                    </div>
                                    </div>
                                    </div>
                                    <DataTable
                                                        columns={this.state.type1}
                                                        data={this.state.filtered?this.state.filtered_data1:this.state.type1_data}
                                                        fixedHeader
                                                        title={'Genel Sorumluluklar'}
                                                        overflowY={false}
                                                        overflowYOffset={'100px'}
                                                        highlightOnHover
                                                        responsive
                                                        striped
                                                        dense
                                                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                                        progressPending={!this.state.pageloader}
                                                        pagination={true}
                                                        paginationPerPage={10}
                                                        defaultSortField={'c_date'}
                                                        defaultSortAsc={false}

                                                    />
                                    <DataTable
                                                        columns={this.state.type2}
                                                        data={this.state.filtered?this.state.filtered_data2:this.state.type2_data}
                                                        fixedHeader
                                                        title={'Özel Görevler'}
                                                        overflowY={false}
                                                        overflowYOffset={'100px'}
                                                        highlightOnHover
                                                        responsive
                                                        striped
                                                        dense
                                                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                                        progressPending={!this.state.pageloader}
                                                        pagination={true}
                                                        paginationPerPage={10}
                                                        defaultSortField={'c_date'}
                                                        defaultSortAsc={false}
                                                    />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    )
}
}

export default connect(mapStateToProps)(TaskList);