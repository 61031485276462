import React ,{useState,useEffect}from 'react';
import { Input ,Button, Popover, Typography, Row,Radio,  Col, Table, Divider,Form, Card,Modal, PageHeader, List,Upload,message, } from 'antd';
import { DeleteOutlined,CloseOutlined,CheckOutlined,QuestionCircleOutlined,PlusOutlined,EditOutlined,UploadOutlined,DownloadOutlined ,FileExcelOutlined} from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios'; 
import SelectList from '../utilities/SelectList';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';


const ExpertList = ({api_url,token,id_com_user,id,expert_form})=>{

const [Loading,setLoading] = useState(false);
const [expertForm]=Form.useForm()
const options =[
    {
        name:'lfbumper',
        title:'Sol Ön Çamurluk',
        value:0,
    },
    {
        name:'lfdoor',
        title:'Sol Ön Kapı',
        value:0,

    },
    {
        name:'lrdoor',
        title:'Sol Arka Kapı',
        value:0,

    },
    {
        name:'lrbumper',
        title:'Sol Arka Çamurluk',
        value:0,

    },
    {
        name:'rfbumper',
        title:'Sağ Ön  Çamurluk',
        value:0,

    },


    {
        name:'rfdoor',
        title:'Sağ Ön Kapı',
        value:0,

    },
    {
        name:'rbdoor',
        title:'Sağ Arka Kapı',
        value:0,

    },
    {
        name:'rrbumper',
        title:'Sağ Arka Çamurluk',
        value:0,

    },
    {
        name:'fbumper',
        title:'Ön Tampon',
        value:0,
    },
  
    {
        name:'fhood',
        title:'Kaput',
        value:0,

    },

    {
        name:'roof',
        title:'Tavan',
        value:0,
    },
 
    {
        name:'rbumper',
        title:'Arka Tampon',
        value:0,

    },
    {
        name:'tailgate',
        title:'Bagaj Kapısı',
        value:0,

    },
  
  

]


useEffect(() => {
    const _onLoad = ()=>{
        const parsed = JSON.parse(expert_form)
             expertForm.setFieldsValue(parsed)
    }
   return _onLoad();
}, [])


const _Save = async ()=>{

  
        setLoading(true)
            let d = new Date();
            let date = d.toISOString();
            let m_date = date.split('T')[0];
            let m_time = d.toLocaleTimeString('tr-TR');
        
            const expertise_form =expertForm.getFieldsValue()
        
        const url= api_url+'/update/com_stock';
        const body = 'token='+token+'&type=user&id='+id+'&m_date='+m_date+'&m_time='+m_time+'&m_id_com_user='+id_com_user+'&expert_form='+JSON.stringify(expertise_form);
        const config = { method:'POST',  cahce: 'no-cache', headers:{ 'Content-Type':'application/x-www-form-urlencoded' },body:body}
        
        try { 
            const response = await(await fetch(url,config)).json();
            message.success({ content: 'Başarıyla Kayıt Edildi' ,duration:0.5 }).then(()=>{window.location.reload() },1)
        
        }   
        
        catch(e){
            console.log(e)
            message.error({ content:  'Hata Oluştu.'   ,duration:0.5 }).then(()=>  window.location.reload() ,1);
        }
        
         
}

    return ( 

        <Row>
            <Col span={24}>
        <PageHeader 
        subTitle='Not : Boş alanlar orjinal olarak işaretlenecektir.'
             extra={[
                <Button key='save' type='primary' size='small' onClick={_Save} loading={Loading}>Kaydet</Button>,
             ]}
        />
        </Col> 
            <Col span={24}>
            <Form form={expertForm} labelAlign='left'   layout='vertical' size='small' >
            <Row gutter={12} justify='center'>   
             {options.map((row,idx)=>
            
                <Col key={idx} xl={5} lg={5} md={12} sm={24} xs={24} style={{margin:5,backgroundColor:'lightgray',borderRadius:20,padding:10}}>
                    <Form.Item label={row.title} name={row.name}  > 
                    <Radio.Group size="small"   buttonStyle="solid"  >
                        <Radio.Button value="1">Boya</Radio.Button>
                        <Radio.Button value="2">Değişen</Radio.Button>
                        <Radio.Button value="3">Lokal Boya</Radio.Button>
                        <Radio.Button value="4">Çizik</Radio.Button>
                        <Radio.Button value="5">Göcük</Radio.Button>
                        <Radio.Button value="6">Orjinal</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                </Col>
            )}

            </Row>
        </Form>
            </Col>
        </Row> 
       

    )
}

export default ExpertList;