
import React,{useEffect, useState} from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component'; 
 import moment from 'moment' 
import ExportFromJson from '../utilities/jsontoexport';
import { PageHeader,Select } from 'antd';

const { Option } = Select;

const ReportServiceAppMonthly= ({api_url,loginToken}) => { 

    const [Data,SetData] = useState([])
    const [Months,SetMonths] = useState([1,2,3,4,5,6,7,8,9,10,11,12]);

    const [Years,SetYears] = useState([2021,2022,2023,2024,2025,2026])
    const [CurrentMonth,SetCurrentMonth] = useState('')
    const [CurrentYear,SetCurrentYear] = useState('')
 


    const [Brands,SetBrands] = useState([])
    const [Subjects,SetSubjects] = useState([])
    const [ServiceApp,SetServiceApp]= useState([])
    const [Pageloader,SetPageloader] = useState(true)
 
    const customStyle = { 
        headRow: {
            style: {
              backgroundColor: 'black',
              minHeight: '80px',
              overflow:'visible',

            },
            denseStyle: {
                minHeight: '35px',
                overflow:'visible',

            },
          },
          headCells: {
            style: {
                fontSize:'16px',
                fontWeight: 700,
                color:'white',
                paddingLeft: '16px',
                paddingRight: '16px',
                overflow:'visible',

            },
          },
          cells: {
            style: {
                overflow:'visible', 
            },
        },
        rows: {
            style: {
                color:'rgba(6, 76, 138,.85)',
                 fontSize:'13px',
                borderStyle: 'solid',
                borderWidth: '1px',
                overflow:'visible',
                borderColor:'rgba(6, 76, 138,.15)',
                 '&:last-child': {
                    fontStyle:'normal',
                    overflow:'visible',
                    backgroundColor:'gray',
                    fontSize:'13px',
                    color:'white',
                    fontWeight: 700,
                 },
            },
        denseStyle: {
          minHeight: '35px',
          overflow:'visible',
        },
      }
    }

    const customStyle2 = {

    }
    const columns_serviceapp = [
        {
            name: 'Randevu Tarihi',
            selector: 'app_date',
            left:true,
            wrap:true,
            width:'100px',
        },
        {
            name: 'Randevu Saati',
            selector: 'timer',
            left:true,
            wrap:true,
            width:'100px',
        },
        {
            name: 'Marka',
            selector: 'id_com_brand_name',
            center:true,
            wrap:true,
         },
        {
            name: 'Danışman',
            selector: 'id_user_register_username',
            center:true,
            wrap:true,
         },
        {
            name: 'Müşteri',
            selector: 'id_com_customer_name',
            center:true,
            wrap:true,
        },
        {
            name: 'Plaka',
            selector: 'license_plate',
            center:true,
            wrap:true,
        },
        {
            name: 'Konu',
            selector: 'id_com_model_name',
            center:true,
            wrap:true,
        },
 
        {
            name: 'Konu',
            selector: 'id_com_service_app_subject_name',
            center:true,
            wrap:true,
        },
        {
            name: 'Not',
            selector: 'comment',
            center:true,
            wrap:true,
        },
        {
            name: 'Durum',
            selector: 'id_com_service_app_status_name',
            right:true,
            wrap:true,
        },
    ]
    const columns = [
            {
                name: 'Danışman',
                selector: 'id_user_register_username',
                left:true,
                wrap:true,
                width:'150px',
       
            },
            {
                name: 'Konu',
                selector: 'id_com_service_app_subject_name',
                center:true,
                wrap:true,
                width:'250px',

            },
            {
                name: 'Beklemede',
                selector: 'Beklemede',
                center:true,
                wrap:true,
            },
            {
                name: 'İşlem Yapılıyor',
                selector: 'İşlem_Yapılıyor',
                center:true,
                wrap:true,
            },
            {
                name: 'Tamamlandı',
                selector: 'Tamamlandı',
                center:true,
                wrap:true,
            },
            {
                name: 'İptal',
                selector: 'İptal',
                center:true,
                wrap:true,
            },
            {
                name: 'Gelmedi',
                selector: 'Gelmedi',
                center:true,
                wrap:true,
            },
            {
                name: 'Faturalandı',
                selector: 'Faturalandı',
                center:true,
                wrap:true,
            },
            {
                name: 'Toplam Adet',
                selector: 'Toplam_Adet',
                right:true,
                wrap:true,
            },
    ]

    useEffect(() => {
        const FetchData = () =>{
            let currentmonth = moment(new Date()).month()+1;
            let currentyear = moment(new Date()).year();
            console.log(currentmonth);
            console.log(currentyear)

            _GetData(currentmonth,currentyear);
        }
        return FetchData()
    }, [])

const Empty = () => 
   
        <div className="alert alert-info" role="alert">
            <h4 className="alert-heading text-center ">Kayıt bulunamamıştır.</h4>
            <p className="text-center">Lütfen Daha Sonradan Yeniden Deneyin.</p>
          </div>
     

    const _GetData = async (month,year)=>{
        SetPageloader(true)
        SetCurrentMonth(month);
        SetCurrentYear(year);
        const url = api_url+'/selectrows/view_report_service_app_monthly';
        const body =  'type=user&token='+loginToken+'&month_='+month+'&year_='+year;
        const config = {     method:'POST',   cahce: 'no-cache',   headers:{   'Content-Type':'application/x-www-form-urlencoded'   },  body:body,  }
        const result = await( await   fetch(url,config).catch(error=>console.log(error))).json();
        // const data =  result.filter(row=>row.month_===month&&row.year_===year)
 
        const url2= api_url+'/selectrows/view_service_app';
        const body2 =  'type=user&token='+loginToken+'&month_='+month+'&year_='+year;
        const config2 = {     method:'POST',   cahce: 'no-cache',   headers:{   'Content-Type':'application/x-www-form-urlencoded'   },  body:body2,  }
        const service_app = await( await   fetch(url2,config2).catch(error=>console.log(error))).json();
 

       
        let brands = [];
        let subjects = [];
     

        result.map(row => {
            brands.push(row.id_com_brand_name)
            subjects.push(row.id_com_service_app_subject_name)
        });
 
        SetBrands([...new Set(brands)])
        SetSubjects([...new Set(subjects)])
        SetData(result);
        SetServiceApp(service_app);
        SetPageloader(false)
    }


const HeadRenderer = ()=>  {

    const HeadData_brands =[];
    const HeadData_subjects =[];

    Brands.map((brand,index)=>{
        let filtered_data  = Data.filter(row=>row.id_com_brand_name===brand);
        HeadData_brands.push({
        id_user_register_username:brand,
        Beklemede:filtered_data.reduce((a,b)=>a+parseFloat(b.Beklemede),0),
        İşlem_Yapılıyor:filtered_data.reduce((a,b)=>a+parseFloat(b.İşlem_Yapılıyor),0),
        Tamamlandı:filtered_data.reduce((a,b)=>a+parseFloat(b.Tamamlandı),0),
        İptal:filtered_data.reduce((a,b)=>a+parseFloat(b.İptal),0),
        Gelmedi:filtered_data.reduce((a,b)=>a+parseFloat(b.Gelmedi),0),
        Faturalandı:filtered_data.reduce((a,b)=>a+parseFloat(b.Faturalandı),0),
        Toplam_Adet:filtered_data.reduce((a,b)=>a+parseFloat(b.Toplam_Adet),0),

    })

    })
    HeadData_brands.push({
        app_date:'',
        id_com_brand:'all',
        id_user_register_username:'Toplam',
        id_com_meet:'all',
        id_com_service_app_subject_name:'-',
        Beklemede:HeadData_brands.reduce((a,b)=>a+parseFloat(b.Beklemede),0),
        İşlem_Yapılıyor:HeadData_brands.reduce((a,b)=>a+parseFloat(b.İşlem_Yapılıyor),0),
        Tamamlandı:HeadData_brands.reduce((a,b)=>a+parseFloat(b.Tamamlandı),0),
        İptal:HeadData_brands.reduce((a,b)=>a+parseFloat(b.İptal),0),
        Gelmedi:HeadData_brands.reduce((a,b)=>a+parseFloat(b.Gelmedi),0),
        Faturalandı:HeadData_brands.reduce((a,b)=>a+parseFloat(b.Faturalandı),0),
        Toplam_Adet:HeadData_brands.reduce((a,b)=>a+parseFloat(b.Toplam_Adet),0),
       
    })

    Subjects.sort().map((subject,index)=>{
        let filtered_data2  = Data.filter(row=>row.id_com_service_app_subject_name===subject);
        HeadData_subjects.push({
        id_user_register_username:'-',
        id_com_service_app_subject_name:subject,
        Beklemede:filtered_data2.reduce((a,b)=>a+parseFloat(b.Beklemede),0),
        İşlem_Yapılıyor:filtered_data2.reduce((a,b)=>a+parseFloat(b.İşlem_Yapılıyor),0),
        Tamamlandı:filtered_data2.reduce((a,b)=>a+parseFloat(b.Tamamlandı),0),
        İptal:filtered_data2.reduce((a,b)=>a+parseFloat(b.İptal),0),
        Gelmedi:filtered_data2.reduce((a,b)=>a+parseFloat(b.Gelmedi),0),
        Faturalandı:filtered_data2.reduce((a,b)=>a+parseFloat(b.Faturalandı),0),
        Toplam_Adet:filtered_data2.reduce((a,b)=>a+parseFloat(b.Toplam_Adet),0),
    })

    })
    HeadData_subjects.sort().push({
        app_date:'',
        id_com_brand:'all',
        id_user_register_username:'Toplam',
        id_com_meet:'all',
        id_com_service_app_subject_name:'-',        
        Beklemede:HeadData_subjects.reduce((a,b)=>a+parseFloat(b.Beklemede),0),
        İşlem_Yapılıyor:HeadData_subjects.reduce((a,b)=>a+parseFloat(b.İşlem_Yapılıyor),0),
        Tamamlandı:HeadData_subjects.reduce((a,b)=>a+parseFloat(b.Tamamlandı),0),
        İptal:HeadData_subjects.reduce((a,b)=>a+parseFloat(b.İptal),0),
        Gelmedi:HeadData_subjects.reduce((a,b)=>a+parseFloat(b.Gelmedi),0),
        Faturalandı:HeadData_subjects.reduce((a,b)=>a+parseFloat(b.Faturalandı),0),
        Toplam_Adet:HeadData_subjects.reduce((a,b)=>a+parseFloat(b.Toplam_Adet),0),
 
    })
    return (
           <>
        <div key='0' className="row">
            <div className="col-md-12"  >
                <div className="panel">
                    <div className="panel-heading">
                        <h3 className="panel-title">Aylık Randevular |  Tüm Markalar </h3>
                    </div>
                    <div className="panel-body">
                    <DataTable
                        customStyles={customStyle}
                        columns={columns}
                        data={HeadData_brands}
                        fixedHeader
                        noHeader={true}
                        highlightOnHover
                        responsive
                        striped
                        dense
                        noDataComponent={Empty()}
                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                        progressPending={Pageloader}
                        progressComponent={'Veriler Yükleniyor'}
                    />
                    </div>

                </div>
            </div>
        </div>
        <div key='4' className="row">
            <div className="col-md-12"  >
                <div className="panel">
                    <div className="panel-heading">
                        <h3 className="panel-title">Aylık Randevular |  Tüm Markalar </h3>
                    </div>
                    <div className="panel-body">
                    <DataTable
                        customStyles={customStyle}
                        columns={columns}
                        data={HeadData_subjects}
                        fixedHeader
                        noHeader={true}
                        highlightOnHover
                        responsive
                        striped
                        dense
                        noDataComponent={Empty()}
                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                        progressPending={Pageloader}
                        progressComponent={'Veriler Yükleniyor'}

                    />
                    </div>

                </div>
            </div>
        </div>
        </>
        )
    }
          
    const SelecPicker = ({selectData,Change,disabled,Value,width}) => { 

        return (
        <Select style={{width:width}} onChange={Change}   disabled={disabled} defaultValue={Value} >
              {
                selectData.map((row,index)=>{
                    return ( 
                        <Option key={index}   value={row}>{row}</Option>
                    )
                })
             }
    </Select>
        )
    }
 
    return ( 
            <div className="main">
                <div className="main-content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="panel">
                        <PageHeader title='Raporlar | ' subTitle='Aylık Randevu Kayıtları'
                            extra={[
                                <SelecPicker key='select1' selectData={Months} Change={(x)=>{_GetData(x,CurrentYear)}} Value={CurrentMonth} width={'150px'}/>,
                                <SelecPicker key='select2' selectData={Years} Change={(x)=>{_GetData(CurrentMonth,x)}} Value={CurrentYear} width={'200px'}/>,
                                 <ExportFromJson data={ServiceApp} fileName={`Gunluk_Randevular_${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}`} exportType={'xls'}/>

                            ]}

                        />
                        </div>
                    </div>
                </div>

        
                    {Data.length===0 && Empty()}
                    {HeadRenderer()}
                    {Brands.map((brand,index)=>{
                         let Filtered_data  = Data.filter(row=>row.id_com_brand_name===brand);
                                Filtered_data.push({
                                    app_date:'',
                                    id_com_brand:'all',
                                    id_user_register_username:'Toplam',
                                    id_com_meet:'all',
                                    id_com_meet_name:'-',
                                    Beklemede:Filtered_data.reduce((a,b)=>a+parseFloat(b.Beklemede),0),
                                    İşlem_Yapılıyor:Filtered_data.reduce((a,b)=>a+parseFloat(b.İşlem_Yapılıyor),0),
                                    Tamamlandı:Filtered_data.reduce((a,b)=>a+parseFloat(b.Tamamlandı),0),
                                    İptal:Filtered_data.reduce((a,b)=>a+parseFloat(b.İptal),0),
                                    Gelmedi:Filtered_data.reduce((a,b)=>a+parseFloat(b.Gelmedi),0),
                                    Faturalandı:Filtered_data.reduce((a,b)=>a+parseFloat(b.Faturalandı),0),
                                    Toplam_Adet:Filtered_data.reduce((a,b)=>a+parseFloat(b.Toplam_Adet),0),
                                })

                                const SubjectData =[];

                                Subjects.map((subject,index)=>{
                                    let filtered_subject_data  = Data.filter(row=>row.id_com_service_app_subject_name===subject&&row.id_com_brand_name===brand);
                                    SubjectData.push({
                                    id_user_register_username:'-',
                                    id_com_service_app_subject_name:subject,
                                    Beklemede:filtered_subject_data.reduce((a,b)=>a+parseFloat(b.Beklemede),0),
                                    İşlem_Yapılıyor:filtered_subject_data.reduce((a,b)=>a+parseFloat(b.İşlem_Yapılıyor),0),
                                    Tamamlandı:filtered_subject_data.reduce((a,b)=>a+parseFloat(b.Tamamlandı),0),
                                    İptal:filtered_subject_data.reduce((a,b)=>a+parseFloat(b.İptal),0),
                                    Gelmedi:filtered_subject_data.reduce((a,b)=>a+parseFloat(b.Gelmedi),0),
                                    Faturalandı:filtered_subject_data.reduce((a,b)=>a+parseFloat(b.Faturalandı),0),
                                    Toplam_Adet:filtered_subject_data.reduce((a,b)=>a+parseFloat(b.Toplam_Adet),0),
                                })

                                })

                                SubjectData.push({
                                    app_date:'',
                                    id_com_brand:'all',
                                    id_com_user: "0",
                                    id_user_register_username:'Toplam',
                                    id_com_meet:'all',
                                    id_com_service_app_subject_name:'-',
                                    Beklemede:Filtered_data.reduce((a,b)=>a+parseFloat(b.Beklemede),0),
                                    İşlem_Yapılıyor:Filtered_data.reduce((a,b)=>a+parseFloat(b.İşlem_Yapılıyor),0),
                                    Tamamlandı:Filtered_data.reduce((a,b)=>a+parseFloat(b.Tamamlandı),0),
                                    İptal:Filtered_data.reduce((a,b)=>a+parseFloat(b.İptal),0),
                                    Gelmedi:Filtered_data.reduce((a,b)=>a+parseFloat(b.Gelmedi),0),
                                    Faturalandı:Filtered_data.reduce((a,b)=>a+parseFloat(b.Faturalandı),0),
                                    Toplam_Adet:Filtered_data.reduce((a,b)=>a+parseFloat(b.Toplam_Adet),0),
                                })


 
                        return (   
                            <div  key={index} className="row">
                                <div className="col-md-12"  >
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h3 className="panel-title">Aylık Randevular |  {brand } </h3>
                                        </div>
                                        <div className="panel-body">
                                        <DataTable
                                            customStyles={customStyle}
                                            columns={columns}
                                            data={Filtered_data}
                                            fixedHeader
                                            noHeader={true}
                                            highlightOnHover
                                            responsive
                                            striped
                                            dense
                                            noDataComponent={Empty()}
                                            paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                            progressPending={Pageloader}
                                            progressComponent={'Veriler Yükleniyor'}

                                        />
                                        </div>
                                      
                                         
                                    </div>
                                </div>
                            </div>
 
                         )
                    })}
                 
                </div>
            </div>

            )
}

export default connect(mapStateToProps)(ReportServiceAppMonthly);
 