
    import React from 'react';
    import {connect} from 'react-redux';
    import mapStateToProps from '../../redux/mapStateToProps';
    import SelectList from './SelectList';


    class ApprovallCard extends React.Component {


        constructor(props) {
            super(props);
            this.state = { 
                cars_data:[],
                kdv_rate:18,
                disc_inv_price:0,
                accessory_total:0,
                plate_register:0,
                otv_matrah_disc1:0,
                otv_matrah_disc2:0,
                otv_matrah_disc3:0,
                kdv_discount:0,
                bandrol:0,
                comment:''
            }
            this._Submit=this._Submit.bind(this);
        }

    async componentDidMount(){

        let com_car_url=this.props.api_url+'/selectRows/view_stock';
        await  fetch(com_car_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                    'Content-Type':'application/x-www-form-urlencoded'
                },
                body:'id='+this.props.data.id_com_stock+'&token='+this.props.loginToken+'&type=user'
            })
            .catch(error=>console.log(error))
            .then(res=>res.json())
            .then(result=>{

            let otv_matrah = parseFloat(result[0].inv_price)
            let otv_amount =  parseFloat(result[0].inv_price)*parseFloat(result[0].otv_rate)
            let kdv_matrah = otv_amount+parseFloat(result[0].inv_price);
            let kdv_amount= kdv_matrah*(this.state.kdv_rate/100);

            this.setState({
                cars_data:result[0],
                purchase_price:result[0].inv_price,
                otv_rate:result[0].otv_rate,
                otv_matrah:otv_matrah,
                otv_amount:otv_amount,
                kdv_matrah: Math.round(kdv_matrah*100)/100,
                kdv_amount : Math.round(kdv_amount*100)/100,
                id_com_otv_rate:result[0].id_com_otv_rate
            });
            })

            let com_access_url=this.props.api_url+'/selectRows/view_orderform_acces_lines';
      fetch(com_access_url,{
                    method:'POST',
                    cahce: 'no-cache',
                    headers:{ 
                        'Content-Type':'application/x-www-form-urlencoded'
                    },
                    body:'id_com_stock='+this.props.data.id_com_stock+'&token='+this.props.loginToken+'&type=user'
                })
                .catch(error=>console.log(error))
                .then(res=>
                    {  
            
                        if(res===undefined){
                            return  {}
                        } else { 
                          return  res.json()
                        }
                    }
                   )
                .then(result=>{

                    let   accessory_total = 0
                        if(result.length>0) {
                            result.map((row,index)=>{
                                accessory_total +=parseFloat(row.price)
                            })
                        } 
              
                    this.setState({accessory_total:accessory_total})
                })

    }

    _Submit(){
        let d = new Date(); 
        let date = d.toISOString();
        let c_date  = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let id_com_orderform = this.props.data.id_com_orderform;
        let id_com_stock = this.props.data.id_com_stock;
        let purchase_price = this.props.data.inv_price;
        let otv_matrah_disc1 =  this.state.otv_matrah_disc1;
        let otv_matrah_disc2 =  this.state.otv_matrah_disc2;
        let otv_matrah_disc3 =  this.state.otv_matrah_disc3;
        let kdv_rate = this.state.kdv_rate;
        let otv_matrah = this.state.purchase_price
        let otv_matrah_last  =  Math.round((
            parseFloat(this.state.purchase_price)
            -parseFloat(this.state.otv_matrah_disc1)
            -parseFloat(this.state.otv_matrah_disc2)
            -parseFloat(this.state.otv_matrah_disc3)
        )*100)/100;

        let id_com_otv_rate = this.state.id_com_otv_rate;

        let otv_amount = Math.round((
            parseFloat(this.state.purchase_price)
            -parseFloat(this.state.otv_matrah_disc1)
            -parseFloat(this.state.otv_matrah_disc2)
            -parseFloat(this.state.otv_matrah_disc3)
             )*parseFloat(this.state.otv_rate)*100)/100;

        let kdv_matrah = 
        Math.round(
        (( parseFloat(this.state.purchase_price)
          -parseFloat(this.state.otv_matrah_disc1)
          -parseFloat(this.state.otv_matrah_disc2)
          -parseFloat(this.state.otv_matrah_disc3)
         ) *(1+parseFloat(this.state.otv_rate))) *100)/100;
        
        let kdv_disc = this.state.kdv_discount;

        let kdv_amount   = 

        Math.round(
            ((
         ( parseFloat(this.state.purchase_price)
          -parseFloat(this.state.otv_matrah_disc1)
          -parseFloat(this.state.otv_matrah_disc2)
          -parseFloat(this.state.otv_matrah_disc3)
         ) *(1+parseFloat(this.state.otv_rate))
         -parseFloat(this.state.kdv_discount)
         )*(parseFloat(this.state.kdv_rate)/100)
         )*100)/100
         
         let total_amount = 
         Math.round(
            ((
         ( parseFloat(this.state.purchase_price)
          -parseFloat(this.state.otv_matrah_disc1)
          -parseFloat(this.state.otv_matrah_disc2)
          -parseFloat(this.state.otv_matrah_disc3)
         ) *(1+parseFloat(this.state.otv_rate))
         -parseFloat(this.state.kdv_discount)
         )*(1+(parseFloat(this.state.kdv_rate)/100))
         )*100)/100


         let accessory_total   =  this.state.accessory_total;

         let plate_register   =  this.state.plate_register;
         let bandrol   =  this.state.bandrol;
         let calculated_price = 
         Math.round(
            ((
         (( parseFloat(this.state.purchase_price)
          -parseFloat(this.state.otv_matrah_disc1)
          -parseFloat(this.state.otv_matrah_disc2)
          -parseFloat(this.state.otv_matrah_disc3)
         )*(1+parseFloat(this.state.otv_rate))
         -parseFloat(this.state.kdv_discount)
         )*(1+(parseFloat(this.state.kdv_rate)/100)))
         +(
          +parseFloat(this.state.bandrol)
          +parseFloat(this.state.plate_register)
          +parseFloat(this.state.accessory_total)
         )
         )
         *100)/100
         let sale_price   =  this.props.data.price;
         let price_difference = 
         Math.round(  (this.props.data.price- ( Math.round(
            ((
         (( parseFloat(this.state.purchase_price)
          -parseFloat(this.state.otv_matrah_disc1)
          -parseFloat(this.state.otv_matrah_disc2)
          -parseFloat(this.state.otv_matrah_disc3)
         )*(1+parseFloat(this.state.otv_rate))
         -parseFloat(this.state.kdv_discount)
         )*(1+(parseFloat(this.state.kdv_rate)/100)))
         +(
          +parseFloat(this.state.bandrol)
          +parseFloat(this.state.plate_register)
          +parseFloat(this.state.accessory_total)
         )
         )
         *100)/100))*100)/100

         let comment = this.state.comment;        

        let insert_body = 
        'token='+this.props.loginToken+
        '&c_date='+c_date+
        '&c_time='+c_time+
        '&c_id_com_user='+this.props.id_com_user+
        '&id_com_orderform='+id_com_orderform+
        '&id_com_stock='+id_com_stock+
        '&purchase_price='+purchase_price+
        '&otv_matrah='+otv_matrah+
        '&otv_matrah_disc1='+otv_matrah_disc1+
        '&otv_matrah_disc2='+otv_matrah_disc2+
        '&otv_matrah_disc3='+otv_matrah_disc3+
        '&otv_matrah_last='+otv_matrah_last+
        '&id_com_otv_rate='+id_com_otv_rate+
        '&otv_amount='+otv_amount+
        '&kdv_rate='+kdv_rate+
        '&kdv_matrah='+kdv_matrah+
        '&kdv_disc='+kdv_disc+
        '&kdv_amount='+kdv_amount+
        '&total_amount='+total_amount+
        '&accessory_total='+accessory_total+
        '&bandrol='+bandrol+
        '&plate_register='+plate_register+
        '&calculated_price='+calculated_price+
        '&sale_price='+sale_price+
        '&price_difference='+price_difference+
        '&comment='+comment+
        '&type=user&id_com_orderform_approval_status=1';


 
        let ordeform_approval_url=this.props.api_url+'/insert/com_orderform_approval';
        fetch(ordeform_approval_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
              body:insert_body,
            })
            .catch(error=>console.log(error)).then(res=>window.location.reload())
    }



    handleChange(event,selectlist,name) {        
        if(selectlist) { 
            this.setState({ [`${name}`]:event.id})
        } 
        else { 

        if(event.target.type==='checkbox'){ 
            event.target.checked?this.setState({[`${event.target.name}`]:'1'}):this.setState({[`${event.target.name}`]:'0'})
                        } else {
            const value = event.target.value;
            const key = event.target.name;
            this.setState({ [`${key}`]:value})
        } }
    }
        render() { 
            return ( 
                <div className="panel">
                    <div className="panel-body">
                    
                    <table className="table table-bordered">
                            <tbody>
                            <tr>
                                <th> 
                              Araç Bilgisi
                                </th>
                                <td style={{textAlign:"right"}} >  

                                {this.state.cars_data.spec}
                                </td>                                                      
                            </tr>
                            <tr>
                                <th> 
                                <label>Alış Fiyatı</label>
                                </th>
                                <td>  
                                <input type="text" className="form-control" disabled={true}  value={this.props.data.inv_price} style={{textAlign:"right"}} />
                                </td>                                                      
                            </tr>
                            <tr>
                                <th> 
                                  <label>Ötv Öncesi Matrah</label>
                                </th>
                                <td>  
                                <input type="text" className="form-control" name="purchase_price"  value={this.state.purchase_price} style={{textAlign:"right"}} 
                                onChange={(x) => this.handleChange(x)} 
                                placeholder="Başlık..." />
                                </td>                                                      
                            </tr>
                            <tr>
                                <th> 
                                <label>Kampanya İndirimi 1</label>
                                </th>
                                <td>  
                                <input type="text" 
                                className="form-control" 
                                name="otv_matrah_disc1"  
                                value={this.state.otv_matrah_disc1}
                                style={{textAlign:"right"}} 
                                onChange={(x) => this.handleChange(x)} 
                                placeholder="Başlık..." />
                                </td>                                                      
                            </tr>
                            <tr>
                                <th> 
                                <label>Kampanya İndirimi 2</label>
                                </th>
                                <td>  
                                <input type="text" className="form-control" name="otv_matrah_disc2"  
                                value={this.state.otv_matrah_disc2} style={{textAlign:"right"}}  
                                 onChange={(x) => this.handleChange(x)} 
                                 placeholder="Başlık..." />
                                </td>                                                      
                            </tr>
                            <tr>
                                <th> 
                                <label>Kampanya İndirimi 3</label>
                                </th>
                                <td>  
                                <input type="text" className="form-control" name="otv_matrah_disc3"  
                                value={this.state.otv_matrah_disc3} style={{textAlign:"right"}} 
                                onChange={(x) => this.handleChange(x)} 
                                placeholder="Başlık..." />
                                </td>                                                      
                            </tr>
                            <tr>
                                <th> 
                                <label>ÖTV Matrahı</label>
                                </th>
                                <td>  
                                <input type="text" disabled={true} className="form-control" 
                                value={Math.round((
                                    parseFloat(this.state.purchase_price)
                                    -parseFloat(this.state.otv_matrah_disc1)
                                    -parseFloat(this.state.otv_matrah_disc2)
                                    -parseFloat(this.state.otv_matrah_disc3)
                                )*100)/100
                                } 
                                style={{textAlign:"right"}} 
                                placeholder="Başlık..." />
                                </td>                                                      
                            </tr>
                            <tr>
                                <th> 
                                <label>Ötv</label>
                                </th>
                                <td>  
                                <SelectList table='com_otv_rate'  label='Ötv Oranı' name={'id_com_otv_rate'} 
                                value={this.state.id_com_otv_rate} 
                                onChange={(x)=>{    

  let com_otv_url=this.props.api_url+'/selectRows/com_otv_rate';
          fetch(com_otv_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                    'Content-Type':'application/x-www-form-urlencoded'
                },
                body:'id='+x.target.value+'&token='+this.props.loginToken+'&type=user'
            })
            .catch(error=>console.log(error))
            .then(res=>res.json())
            .then(result=>{
          
                       this.setState({
                           otv_rate:result[0].rate,
                           id_com_otv_rate:result[0].id,
                           })
            })
                                }} 
                                />
                                <input type="text" className="form-control" name="otv_amount" disabled={true} 
                                value={Math.round((
                                    parseFloat(this.state.purchase_price)
                                    -parseFloat(this.state.otv_matrah_disc1)
                                    -parseFloat(this.state.otv_matrah_disc2)
                                    -parseFloat(this.state.otv_matrah_disc3)
                                     )*parseFloat(this.state.otv_rate)*100)/100} 
                                style={{textAlign:"right"}} 
                                 placeholder="Başlık..." />
                                </td>                                                      
                            </tr>
                         
                            <tr>
                                <th> 
                                <label>Kdv</label>
                                </th>
                                <td>  
                                <label>Kdv Matrahı</label>
                                <input type="text" className="form-control"  disabled={true} 
                                value={
                                    Math.round(
                                      (
                                   ( parseFloat(this.state.purchase_price)
                                    -parseFloat(this.state.otv_matrah_disc1)
                                    -parseFloat(this.state.otv_matrah_disc2)
                                    -parseFloat(this.state.otv_matrah_disc3)
                                   ) *(1+parseFloat(this.state.otv_rate)))
                                     
                                     *100)/100
                                } 
                                style={{textAlign:"right"}} />
                                <label>Kdv İndirimi</label>
                                <input type="text" className="form-control" name="kdv_discount"  value={this.state.kdv_discount}
                                 style={{textAlign:"right"}} onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                             
                                <label>Kdv Oranı</label>
                                <input type="text" className="form-control" name="kdv_rate"  value={this.state.kdv_rate} style={{textAlign:"right"}}
                                     onChange={(x) => this.handleChange(x)} />
                                <label>Kdv Tutarı</label>
                                <input type="text" className="form-control" disabled={true}
                                 value={
                                    Math.round(
                                      ((
                                   ( parseFloat(this.state.purchase_price)
                                    -parseFloat(this.state.otv_matrah_disc1)
                                    -parseFloat(this.state.otv_matrah_disc2)
                                    -parseFloat(this.state.otv_matrah_disc3)
                                   ) *(1+parseFloat(this.state.otv_rate))
                                   -parseFloat(this.state.kdv_discount)
                                   )*(parseFloat(this.state.kdv_rate)/100)
                                   )*100)/100

                                 } 
                                
                                style={{textAlign:"right"}} />

                            </td>                                                      
                            </tr>
                            <tr>
                                <th> 
                                <label>Toplam Araç Bedeli</label>
                                </th>
                                <td>  
                                <input type="text" className="form-control" disabled={true}   
                                value={
                                 Math.round(
                                      ((
                                   ( parseFloat(this.state.purchase_price)
                                    -parseFloat(this.state.otv_matrah_disc1)
                                    -parseFloat(this.state.otv_matrah_disc2)
                                    -parseFloat(this.state.otv_matrah_disc3)
                                   ) *(1+parseFloat(this.state.otv_rate))
                                   -parseFloat(this.state.kdv_discount)
                                   )*(1+(parseFloat(this.state.kdv_rate)/100))
                                   )*100)/100
                                } style={{textAlign:"right"}}  />
                                </td>                                                      
                            </tr>
                            <tr>
                                <th> 
                                <label>Aksesuar Bedeli</label>
                                </th>
                                <td>  
                                <input type="text" className="form-control" disabled={true} name="accessory_total"  value={this.state.accessory_total} style={{textAlign:"right"}} placeholder="Başlık..."  />
                                </td>                                                      
                            </tr>
                            <tr>
                                <th> 
                                <label>Plaka Tescil Bedeli</label>
                                </th>
                                <td>  
                                <input type="text" className="form-control"  name="plate_register"  value={this.state.plate_register}
                                     onChange={(x) => this.handleChange(x)} 
                                 style={{textAlign:"right"}} placeholder="Plaka Tescil Bedeli Giriniz..."  />
                                </td>                                                      
                            </tr>
                            <tr>
                                <th> 
                                <label>Bandrol Bedeli</label>
                                </th>
                                <td>  
                                <input type="text" className="form-control"  name="bandrol"  value={this.state.bandrol} style={{textAlign:"right"}} 
                               onChange={(x) => this.handleChange(x)} 
                                placeholder="Bandrol Bedeli Giriniz..."  />
                                </td>                                                      
                            </tr>
                            <tr>
                                <th> 
                                <label>Toplam Satış Bedeli</label>
                                </th>
                                <td>  
                                <input type="text" className="form-control"  name="bandrol"  value={
                                  Math.round(
                                      ((
                                   (( parseFloat(this.state.purchase_price)
                                    -parseFloat(this.state.otv_matrah_disc1)
                                    -parseFloat(this.state.otv_matrah_disc2)
                                    -parseFloat(this.state.otv_matrah_disc3)
                                   )*(1+parseFloat(this.state.otv_rate))
                                   -parseFloat(this.state.kdv_discount)
                                   )*(1+(parseFloat(this.state.kdv_rate)/100)))
                                   +(
                                    +parseFloat(this.state.bandrol)
                                    +parseFloat(this.state.plate_register)
                                    +parseFloat(this.state.accessory_total)
                                   )
                                   )
                                   *100)/100
                                } style={{textAlign:"right"}} placeholder="Bandrol Bedeli Giriniz..."  />
                                </td>                                                      
                            </tr>
                            <tr>
                                <th> 
                                <label>Satış Fiyatı</label>
                                </th>
                                <td>  
                                <input type="text" disabled={true} className="form-control" name="sale_price"  value={this.props.data.price} style={{textAlign:"right"}} onChange={(x) => this.handleChange(x)} placeholder="Başlık..."  />
                                </td>                                                      
                            </tr>
                            <tr>
                                <th> 
                                <label>Fiyat Farkı</label>
                                </th>
                                <td>  
                                <input type="text" className="form-control" name="phone1"  value={
                  Math.round(  (this.props.data.price- ( Math.round(
                                      ((
                                   (( parseFloat(this.state.purchase_price)
                                    -parseFloat(this.state.otv_matrah_disc1)
                                    -parseFloat(this.state.otv_matrah_disc2)
                                    -parseFloat(this.state.otv_matrah_disc3)
                                   )*(1+parseFloat(this.state.otv_rate))
                                   -parseFloat(this.state.kdv_discount)
                                   )*(1+(parseFloat(this.state.kdv_rate)/100)))
                                   +(
                                    +parseFloat(this.state.bandrol)
                                    +parseFloat(this.state.plate_register)
                                    +parseFloat(this.state.accessory_total)
                                   )
                                   )
                                   *100)/100))*100)/100
                                } style={{textAlign:"right"}} onChange={(x) => this.handleChange(x)} placeholder="Başlık..."  />
                                </td>                                                      
                            </tr>
                            </tbody>
                    </table>
                        <div className="form-group">
                            <label>Açıklama</label>
                            <textarea type="text" cols className="form-control" name="comment" onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                        </div>
                    <div className="panel-footer">
                        <button className="btn btn-success pull-right" style={{margin:5}} onClick={()=>this._Submit()}>Kaydet</button> 
                     </div>              
                    </div>  
                </div>
            )
        }



    }

    export default connect(mapStateToProps)(ApprovallCard);