import React,{useState,useEffect} from 'react';
import {  Col, Row, Divider, PageHeader,Table, Typography,Select} from 'antd';
import {connect} from 'react-redux';
import mapStateToProps from '../../../redux/mapStateToProps'; 
import 'moment/locale/tr'; 
import moment from 'moment';
 
const  formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 0
  })
   
const {Text} = Typography;
const {Option} =Select;

const FinancialCost = ({api_url,loginToken})=>{
    const [SummrizedData,setSummrizedData] = useState([]);
    const [CostPlan,setCostPlan]= useState([]);
    const [LogoData,setLogoData] =useState([])
    const [Loading,setLoading] = useState(true)
    const Months = [1,2,3,4,5,6,7,8,9,10,11,12];
    const [AllColumns,setAllColumns] =useState([])
    const [ExcelColumns,setExcelColumns] =useState([])
    const Years = [2020,2021,2022,2023,2024];
    const [SelectedYear,setSelectedYear] = useState()
  

    useEffect(() => {
        const OnLoad =()=>{
            const current_year = moment(new Date()).year()
            setSelectedYear(current_year)
            _GetAccountGroup(current_year);
         
          }       
        return OnLoad();
    }, [])



    const _GetAccountGroup= async (current_year)=>{
        setLoading(true)
       
        let url =  api_url+'/selectrows/view_cost_plan';
        const response  = await (await   fetch(url,{
                method:'POST',
                cache: 'no-cache',
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                body: 'token='+loginToken+'&type=user',
            })).json();
             setCostPlan(response)
            _GetLogoLines(response,current_year)
         }
 
 




        const _GetLogoLines = async (costplan,current_year)=>{
            setLoading(true)

             let filter_array = [];
            
            costplan.map(group=>{
                  filter_array.push({'text':group.text,'value':group.text})
                  return null
                })
             
              const Columns = [
                {
                    title:'Harcama Grubu',
                    dataIndex:'name',
                    fixed:'left',
                    filters: filter_array,
                    filterMultiple: true,
                    onFilter: (value, record) => record.name.indexOf(value) === 0,
                },
        
            ]
            const excell_columns = [
                {
                    name:'Harcama Grubu',
                    selector:'name',
                },
        
            ]
        
            let url =  api_url+'/logoselectrows/ZZZ_ST_EMFLINE_700_COSTS';

            const body = 'token='+loginToken+'&type=user';
             const logo_result  =    await (await   fetch(url,{  method:'POST',   cache: 'no-cache',    headers:{'Content-Type': 'application/x-www-form-urlencoded'},   body: body, })).json();
          
            const summarized_data = []
            costplan.map((line,idx)=>{
                 const groupedcodes = line.cost_codes.split(',')
                const filtered_result =  logo_result.filter(row => groupedcodes.find(row2 => row.ACCOUNTCODE === row2&& parseInt(row.YEAR_)===current_year))
                let group_row = {
                    name:line.text,
                    codes:groupedcodes,
                }


                Months.map(month=>{
                    const month_filtered = filtered_result.filter(row=>parseFloat(row.MONTH_)===month);
                    Object.assign(group_row,{[`${month}. Ay`]:month_filtered.reduce((a,b)=>a+parseFloat(b.BAKIYE),0)})
                })
                Object.assign(group_row,{Toplam:filtered_result.reduce((a,b)=>a+parseFloat(b.BAKIYE),0)})
                
                summarized_data.push(group_row);

            })
            Months.map(month=>{
            Columns.push({
                title:`${month} . Ay`,
                dataIndex:`${month}. Ay`,
                align:'right',
                sorter: (a, b) => a[`${month}. Ay`] - b[`${month}. Ay`],
                render : (row,record) => formatter.format(record[`${month}. Ay`])
            })
            excell_columns.push({
                name:`${month} . Ay`,
                selector:`${month}. Ay`,
            })
        
        })

            Columns.push({
                title:'Toplam',
                dataIndex:'Toplam',
                align:'right',
                render : (row,record) => formatter.format(record.Toplam),
                sorter: (a, b) => a.Toplam - b.Toplam,

            })
            excell_columns.push({
                name:'Toplam',
                selector:'Toplam',
            })

            setExcelColumns(excell_columns)
            setAllColumns(Columns);
            setSummrizedData(summarized_data)
            setLogoData(logo_result)
            setLoading(false)

        }

    const SelecPicker = ({selectData,Change,disabled,Value,width}) => { 

            return (
            <Select style={{width:width}} onChange={Change}   disabled={disabled} defaultValue={Value}  >
                  {
                    selectData.map((row,index)=>{
                        return ( 
                            <Option key={index}   value={row} style={{textAlign:'center'}}>{row}</Option>
                        )
                    })
                 }
            </Select>
            )
        }

return (
    <div className="main">
    <div className="main-content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="panel" style={{padding:20}}>
                        <Row>
                            <Col span={24}>
                            <PageHeader
                                title={'Giderler |'}
                                subTitle={'Liste'}
                            extra={[
                                <SelecPicker key='select1' selectData={Years} Change={(x)=>_GetLogoLines(CostPlan,x)} width='150px' Value={SelectedYear}/>,

                            ]}
                            />
                            </Col>
                        </Row>
                            
                        <Divider></Divider>
                        <Row>
                            <Col span={24}>
                                <Table dataSource={SummrizedData} columns={AllColumns} size='small' loading={Loading} locale={{filterConfirm:'Filtrele',filterReset:'Sıfırla'}}  rowKey={row=>row.name} bordered  scroll={{   y: 850 }} 
                                footer={
                                        ()=><Text type='danger' code>   Son Güncelleme : { SummrizedData.length>0? moment(new Date()).format('DD.MM.YYYY HH:MM'):'Data Güncelleniyor'}</Text>
                                    }
                                summary={PageData=>{
                                    return (
                                    
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell align='left'>
                                                    <Text style={{fontSize:14}} strong >Toplam </Text>
                                                </Table.Summary.Cell>
                                                    {Months.map((month,idx)=>
                                                        <Table.Summary.Cell align='right'>
                                                            <Text style={{fontSize:14}} strong >{formatter.format(PageData.reduce((a,b)=>a+parseFloat(b[`${month}. Ay`]),0))} </Text>
                                                        </Table.Summary.Cell>
                                                    
                                                    )}
                                                <Table.Summary.Cell align='right'>
                                                    <Text style={{fontSize:14}} strong >{formatter.format(PageData.reduce((a,b)=>a+parseFloat(b[`Toplam`]),0))} </Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        )
                                    
                                    }}  > 
                                </Table>
                            </Col>
                        </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
) 
}

export default connect(mapStateToProps)(FinancialCost);