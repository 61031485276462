import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
import {alertMessage, dateTime} from '../../assets.js';
import DataTable from 'react-data-table-component';
import InterviewHistory from './InterviewHistory';
import DatePicker from '../utilities/DatePicker';
import Axios from "axios";
import Option from "../PollQuestions/Option";
import {Row,Col, PageHeader} from 'antd'
class InterviewAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: false,
            customer_data: [],
            customer_data_filtered:[],
            SwapCar: [],
            searchItem: '',
            id_com_expertise: '',
            testdrive: 0,
            step1: true,
            step2: false,
            SwapCarDetail: false,
            swapsaved: false,
            traffic: false,
            kasko: false,
            Exp_list: false,
            forward:'0',
            expertise_history: [],
            r_id_com_user:'0',
            pollData: [],
            columns: [
                {
                    name: 'Kontrol',
                    center: true,   
                    width:'100px',
                    cell: (row) => <button
                        onClick={() => this.setState({id_com_customer: row.id, step1: false, step2: true})}
                        className="btn btn-primary btn-sm btn-block">Seç</button>
                },
                {
                    name: 'Firma  Adı',
                    selector: 'company_name',
                    sortable: true,
                    left: true,   
                    wrap:true,
                    width:'350px'

                },
                {
                    name: 'Müşteri  Adı',
                    selector: 'username',
                    sortable: true,
                    center: true,   
                },
                {
                    name: 'Telefon',
                    selector: 'phone1',
                    width:'150px',
                    sortable: true,
                    wrap:true,
                    center: true,   
                },
                {
                    name: 'Vergi No',
                    selector: 'taxno',
                    sortable: true,
                    center: true,  
                    width:'150px',
                    wrap: true, 
                },
      
            ]
        }
        this.Search = this.Search.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this._Filter=this._Filter.bind(this)
        this.AddSwapCar = this.AddSwapCar.bind(this);
        this.SearchExp = this.SearchExp.bind(this);
        this.DatePick = this.DatePick.bind(this);
    }
    DatePick(value, type) {
        switch (type) {
            case 'date':
                let newdate = new Date(value)
                newdate.setDate(newdate.getDate())
                let delivery_date = newdate.toISOString().split('T')[0]
                this.setState({delivery_date: delivery_date})
                return null
            case 'time':
                this.setState({dist_time: value.target.value})
                return null
            default:
                return null
        }
    }


    async SearchExp(x) {
        let str = x.target.value.toString();
        let event = str.toUpperCase();
        let plate = event.replace(' ', '');

        let url = this.props.api_url +'/selectRows/view_expertise';
        fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'license_plate=' + plate + '&token=' + this.props.loginToken,
        })
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(result => {
                    if (result.length > 0) {
                         this.setState({expertise_history: result, Exp_list: true});
                    } else {
                        this.setState({expertise_history: [], Exp_list: false});
                    }
                }
            )
        // view_expertise
    }




    async Search() {
        let url = this.props.api_url + '/customerSearch';
        fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'match=' + this.state.searchItem + '&token=' + this.props.loginToken,
        })
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(result => {
                    this.setState({customer_data: result, CustomerListLoaded: true});
                }
            )
    }

    SwapCar(event) {
        event.preventDefault();
        let form = document.getElementById('SwapCarForm');
        let formData = new FormData(form);

        let array = {};
        for (var pair of formData.entries()) {
            let key = pair[0];
            let value = pair[1]
            array[key] = value;
        }
        this.setState({SwapCar: array, SwapCarDetail: true});
    }
    handleChange(event, selectlist, name) {
        if (event.target.name == 'id_com_interview_subject'){
            if (event.target.value == 14){
                this.getPollData(1)
            }else if(event.target.value == 10){
                this.getPollData(2)
            }else if(event.target.value == 16){
                this.getPollData(3)
            }else{
                this.setState({questionsData: null})
            }
        }
        if (selectlist) {
     
            this.setState({[`${name}`]: event.id})
        } else {

            if (event.target.type === 'checkbox') {
                event.target.checked ? this.setState({[`${event.target.name}`]: '1'}) : this.setState({[`${event.target.name}`]: '0'})
            } else {
                const value = event.target.value;
                const key = event.target.name;
                this.setState({[`${key}`]: value})
            }
        }
    }
    AddSwapCar() {
        let d = new Date();
        let date = d.toISOString();
        let c_date = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let swapcar = this.state.SwapCar;
        let id_com_brand = swapcar.id_com_brand;
        let id_com_model = swapcar.id_com_model;
        let id_com_gear = swapcar.id_com_gear;
        let id_com_fuel = swapcar.id_com_fuel;
        let id_com_color = swapcar.id_com_color;
        let km = swapcar.km;
        let model_year = swapcar.model_year;
        let spec = swapcar.spec;
        let str = swapcar.license_plate.toString();
        let event = str.toUpperCase();
        let license_plate = event.replace(' ', '');
        let chasis = swapcar.chasis;
        let demand_price = swapcar.demand_price;
        let comment = swapcar.comment;
        let id_com_customer = this.state.id_com_customer;

        let insert_car =
            'token=' + this.props.loginToken +
            '&id_com_brand=' + id_com_brand +
            '&id_com_customer=' + id_com_customer +
            '&id_com_model=' + id_com_model +
            '&id_com_gear=' + id_com_gear +
            '&id_com_fuel=' + id_com_fuel +
            '&id_com_color=' + id_com_color +
            '&model_year=' + model_year +
            '&spec=' + spec +
            '&license_plate=' + license_plate +
            '&chasis=' + chasis +
            '&c_date=' + c_date +
            '&c_time=' + c_time +
            '&id_com_user=' + this.props.id_com_user +
            '&type=user';
        // console.log(insert_car);
        let car_list_url = this.props.api_url + '/selectRows/com_cars';
        fetch(car_list_url, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'token=' + this.props.loginToken + '&license_plate=' + license_plate + '&type=user',
        })
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(result => {

                if (result.length > 0) {

                    let id_com_cars = result[0].id;
                    let insert_exp =
                        'token=' + this.props.loginToken +
                        '&id_com_cars=' + id_com_cars +
                        '&demand_price=' + demand_price +
                        '&offer_price=0' +
                        '&comment=' + comment +
                        '&km=' + km +
                        '&id_com_user=' + this.props.id_com_user +
                        '&c_date=' + c_date +
                        '&c_time=' + c_time +
                        '&id_com_expertise_status=1' +
                        '&type=user';
                    let exp_url = this.props.api_url + '/insert/com_expertise';
                    fetch(exp_url, {
                        method: 'POST',
                        cache: 'no-cache',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: insert_exp,
                    }).catch(error => console.log(error)).then(result => result.json()).then(res => this.setState({
                        swapsaved: true,
                        id_com_expertise: res.id
                    }))
                } else {
                    let car_url = this.props.api_url + '/insert/com_cars';
                    fetch(car_url, {
                        method: 'POST',
                        cache: 'no-cache',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: insert_car,
                    })
                        .catch(error => console.log(error))
                        .then(response => response.json())
                        .then(result => {
                            let id_com_cars = result.id;
                            let insert_exp =
                                'token=' + this.props.loginToken +
                                '&id_com_cars=' + id_com_cars +
                                '&demand_price=' + demand_price +
                                '&offer_price=0' +
                                '&comment=' + comment +
                                '&km=' + km +
                                '&id_com_user=' + this.props.id_com_user +
                                '&c_date=' + c_date +
                                '&c_time=' + c_time +
                                '&id_com_expertise_status=1' +
                                '&type=user';
                            let exp_url = this.props.api_url + '/insert/com_expertise';
                            fetch(exp_url, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                },
                                body: insert_exp,
                            }).catch(error => console.log(error)).then(result => result.json()).then(res => this.setState({
                                swapsaved: true,
                                id_com_expertise: res.id
                            }))
                        })
                }
            })
    }
    handleSubmit(event) {
       
        let d = new Date();
        let date = d.toISOString();
        let c_date = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let interview_number = Math.floor(Math.random() * 10000000) + 1000000;
        let id_com_meet = this.state.id_com_meet;
        const user=  this.state.forward==='1'? this.state.r_id_com_user : this.props.id_com_user ;

        let insert_body =
            'token=' + this.props.loginToken +
            '&interview_number=' + interview_number +
            '&c_date=' + c_date +
            '&c_time=' + c_time +
            '&id_com_user=' +user +
            '&id_com_meet=' + id_com_meet +
            '&id_com_customer=' + this.state.id_com_customer +
            '&chasis=' + this.state.chasis +
            '&id_com_interview_subject=' + this.state.id_com_interview_subject +
            '&id_com_interview_type=' + this.state.id_com_interview_type +
            '&id_com_activity=' + this.state.id_com_activity +
            '&id_com_brand=' + this.state.id_com_brand +
            '&id_com_model=' + this.state.id_com_model +
            '&kasko=' + this.state.kasko +
            '&traffic=' + this.state.traffic +
            '&id_com_cars=' + this.state.id_com_cars +
            '&delivery_date=' + this.state.delivery_date +
            '&forwarded='+this.state.forward+
            '&id_com_insurance_company=' + this.state.id_com_insurance_company +
            '&type=user&status=0&id_com_interview_status=1';

            let com_interview_url = this.props.api_url + '/insert/com_interview';
        fetch(com_interview_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: insert_body,
        })
            .catch(error => console.log(error)).then(res => res.json()).then(response => {

            let id_com_interview = response.id;

            if (this.state.pollData.id_com_poll){
                this.pollInsertData(id_com_interview)
            }
             const user2=  this.state.forward==='1'? this.state.r_id_com_user : this.props.id_com_user ;

            let insert_lines =
                'token=' + this.props.loginToken +
                '&id_com_interview=' + id_com_interview +
                '&c_date=' + c_date +
                '&c_time=' + c_time +
                '&id_com_meet=' + id_com_meet +
                '&testdrive=' + this.state.testdrive +
                '&id_com_user=' + user2 +
                '&id_com_expertise=' + this.state.id_com_expertise +
                '&content=' + this.state.content +
                '&type=user';
             let com_interview_lines_url = this.props.api_url + '/insert/com_interview_lines';

            fetch(com_interview_lines_url, {
                method: 'POST',
                cahce: 'no-cache',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: insert_lines,
            }).catch(e => console.log(e))
                .then(res => window.location.href = '/interview/Detail/'+id_com_interview)


        })
    }
    getPollData = async (id) => {
        try {
            const response = await Axios.post(this.props.api_url + '/getPoll/' + id, {
                token: this.props.loginToken
            }) 
            this.setState({
                questionsData: response.data
            })
        } catch (error) {
            console.error(error);
        }
    }
    pollFormSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        let urlencoded = new URLSearchParams();
        urlencoded = data;
        try {
            const response = await Axios.post(this.props.api_url + '/postData', urlencoded)
            this.setState({
                pollData: response.data
            })
            this.setState({
                questionsData: null
            })
        } catch (error) {
            console.error(error);
        }
    }
    pollInsertData = async (id) => {
        const postData = JSON.stringify(this.state.pollData);
        try {
            const response = await Axios.post(
                this.props.api_url + '/pollInsert',
                {
                        pollData: postData, token: this.props.loginToken,id_com_interview:id
                    }
                ) 
        } catch (error) {
            console.error(error);
        }
    }

    componentDidMount(){
        const id_com_customer = this.props.match.params.id;

        if(id_com_customer==='0'){ 
            this.GetCustomerList();
        } else { 
            this.setState({id_com_customer: id_com_customer, step1: false, step2: true})
        }
    }

     GetCustomerList = async () =>{
         let url = this.props.api_url+'/getCustomers';
         const customer_list = await( await   fetch(url, {
                method: 'POST',
                cache: 'no-cache',
                headers:  {
                    'Content-Type':  'application/x-www-form-urlencoded'
                },
                body: 'type=user&token='+this.props.loginToken,
            }) ).json()
 
        this.setState({customer_data:customer_list,CustomerListLoaded:true})

    }


   
  _Filter(value) { 
    this.setState({searchItem:value});

    let match_str = new RegExp('('+value+')','i');
    let data = this.state.customer_data;

    let filtered_data = data.filter((row)=>{

      if(value===''){ 
          return row
      }    else   {
         return row.company_name.match(match_str)||row.username.match(match_str)  ||row.phone1.match(match_str)
       }

    })
     this.setState({customer_data_filtered:filtered_data})
  }




    render() {
        return (
            <React.Fragment>
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* <div className="col-md-6 text-right">
                            {this.state.step1?<a href="/interview/add" className="btn btn-primary">Ekle</a>:<></>}
                        </div> */}
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-body">
                                        {this.state.step1 ?
                                            <>
                                                 <div className="form-group">
                                                    <input value={this.state.searchItem} className="form-control"
                                                           type="text" placeholder="TC Kimlik No, Telefon, Adı Soyadı"
                                                           onChange={(x) => {  this._Filter(x.target.value)  }}/>
                                                </div>
                                         
                                                            <DataTable
                                                                data={this.state.searchItem===''?this.state.customer_data:this.state.customer_data_filtered}
                                                                columns={this.state.columns}
                                                                highlightOnHover
                                                                responsive
                                                                striped
                                                                dense
                                                                pagination
                                                                paginationPerPage={10}
                                                                progressPending={!this.state.CustomerListLoaded}
                                                                progressComponent={  <h3>Müşteriler Yükleniyor ... </h3>  }

                                                            />
 
 
                                         </>
                                            : <></>}

                                        {this.state.step2  &&
                                            <Row gutter={12} justify='space-between'>  
                                                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                                    <SelectList table='com_interview_type' label={'Görüşme Tipi'}
                                                                name={'id_com_interview_type'}
                                                                onChange={(x) => this.handleChange(x)}/>
                                                    <SelectList table='com_interview_subject'
                                                                id_com_interview_type={this.state.id_com_interview_type}
                                                                label={'Görüşme Konusunu Seçiniz'}
                                                                name={'id_com_interview_subject'}
                                                                onChange={(x) => this.handleChange(x)}/>

                                                    {this.state.id_com_interview_type === '1' && this.state.id_com_interview_subject === '1'  &&
                                                        <>
                                                            <SelectList table='com_brand' dealer={true}
                                                                        label={'İlgilendiği Araç Markası'}
                                                                        name={'id_com_brand'}
                                                                        onChange={(x) => this.handleChange(x)}/>
                                                            <SelectList table='com_model'
                                                                        id_com_brand={this.state.id_com_brand}
                                                                        label={'İlgilendiği Araç Modeli'}
                                                                        name={'id_com_model'}
                                                                        onChange={(x) => this.handleChange(x)}/>
                                                        </> }
                                                        {this.state.id_com_interview_subject === '12'  &&
                                                    <>
                                                        <div className="form-check">
                                                            <input className="form-check-input" name='traffic'
                                                                   type="checkbox" id='traffic'
                                                                   onChange={(x) => this.handleChange(x)}/>
                                                            <label className="form-check-label" style={{marginLeft: 5}}>Trafik
                                                                Sigorta</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" name='kasko'
                                                                   type="checkbox" id='kasko'
                                                                   onChange={(x) => this.handleChange(x)}/>
                                                            <label className="form-check-label" style={{marginLeft: 5}}>Kasko
                                                                Sigorta</label>
                                                        </div>
                                                    </> }
                                                {this.state.id_com_interview_type === '3' &&
                                                <div className="form-group">
                                                    <label>Şase  / Poliçe</label>
                                                    <input type="text"
                                                            className="form-control"
                                                            name="chasis"
                                                            placeholder="Başlık..."
                                                            onChange={(x) => this.handleChange(x)}
                                                            required/>
                                                </div>
                                                  
                                               }
                                                {this.state.id_com_interview_type === '5' &&
                                                    <>
                                                        <SelectList table='view_cars_sl'
                                                                    id_com_customer={this.state.id_com_customer}
                                                                    label={'Arac Seçiniz'} name={'id_com_cars'}
                                                                    onChange={(x) => this.handleChange(x)}/>
                                                        <SelectList table='com_insurance_company'
                                                                    label={'Sigorta Firması Seçiniz'}
                                                                    name={'id_com_insurance_company'}
                                                                    onChange={(x) => this.handleChange(x)}/>
                                                        <div className="form-group">
                                                            <label>Teslimat Tarihi</label>
                                                            <DatePicker onChange={(x) => this.DatePick(x, 'date')}/>
                                                        </div>
                                                    </>
 
                                                }
                                                {(['19','9','18'].includes(this.state.id_com_interview_subject) )&& <>
                                                  
                                                  <SelectList table='view_cars_sl'
                                                              id_com_customer={this.state.id_com_customer}
                                                              label={'Arac Seçiniz'} name={'id_com_cars'}
                                                              onChange={(x) => this.handleChange(x)}/>
                                                 
                                               </>
                                          }

                                                <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="check1"
                                                       name='forward'   onChange={(x) => this.handleChange(x)}
                                                   />
                                                    <label className="form-check-label" htmlFor="check1" style={{marginLeft: 10,}}>Görüşme
                                                        Ataması Yap</label>
                                                </div>

                                                {this.state.forward === '1' &&
                                                    <>
                                                    <SelectList table='com_department' label={'Departman'} name={'id_com_department'}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                        
                                                        <SelectList table='view_user_sl' 
                                                            id_com_brand={this.state.id_com_interview_type==='4'?'61':this.state.id_com_interview_type==='2'?'64':this.state.id_com_brand} 
                                                            id_com_department={this.state.id_com_department} 
                                                            label={'Atanacak Personel'} 
                                                            name={'r_id_com_user'}
                                                            onChange={(x) => this.handleChange(x)}/>
                                                    </>
                                                }
 

                                                  

                                                </Col>
                                                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                                {this.state.id_com_interview_type === '1' && this.state.id_com_interview_subject === '2' &&
                                                  
                                                        <SelectList table='view_activity_sl'
                                                                    id_com_interview_type={this.state.id_com_activity}
                                                                    label={'Aktivite Tipi'} name={'id_com_activity'}
                                                                    onChange={(x) => this.handleChange(x)}/>
                                                     }
                                                    <SelectList table='com_meet' label={'Temas Türü'} name={'id_com_meet'}
                                                            onChange={(x) => this.handleChange(x)}/>

                                                    <div className="form-group">
                                                    <label>Not : </label>
                                                    <textarea type="text" style={{height: 200}} row='20'
                                                              className="form-control" name="content"
                                                              onChange={(x) => this.handleChange(x)} required/>
                                                </div>
                                               
                                                </Col>
                                                {this.state.questionsData &&
                                                    <div className={"form-group"}>
                                                        <button
                                                            type="button" className="btn btn-danger"
                                                            data-toggle="modal" data-target="#pollModal">
                                                            {this.state.questionsData.title}
                                                        </button>
                                                    </div> 
                                                }

                                            </Row>

                                        } 

                                    </div>

                                    {this.state.step2 ?
                                        <InterviewHistory id_com_customer={this.state.id_com_customer}
                                                          title={'Müşteri Geçmişi'} id='interview_history'/>
                                        : <></>}
                                    {this.state.step2 ?
                                        <div className="panel-footer">
                                            <button className="btn btn-danger"
                                                    onClick={() => window.history.back()}
                                            >Geri</button>
                                            <button className="btn btn-primary pull-right"
                                                    disabled={this.state.disabled}
                                                    onClick={() => this.handleSubmit()}
                                                // onClick={()=>console.log(this.state)}
                                            >Kaydet</button>
                                        </div>
                                        :
                                        <div className="panel-footer text-center">
                                            Lütfen Müşteriyi Seçiniz!
                                        </div>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="pollModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog modal-lg" role="document">
                    <form onSubmit={this.pollFormSubmit}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                {this.state.questionsData?
                                    <h4 className="modal-title" id="myModalLabel">{this.state.questionsData.title}</h4>
                                    :
                                    null
                                }
                            </div>
                            <div className="modal-body">
                                {this.state.questionsData?
                                    <React.Fragment>
                                        <input type={"hidden"} name={"id_com_poll"} value={this.state.questionsData.id} />
                                        {
                                            this.state.questionsData.questions.map((question,key) => {
                                                if (question.type == 'radio'){
                                                    return(
                                                        <div className={"form-group"} key={key}>
                                                            <input type={"hidden"} name={"id_com_poll_questions[]"} value={question.id} />
                                                            <label>{question.question}</label>
                                                            <br />
                                                            <select className={"form-control"} name={"id_com_poll_options[]"}>
                                                                {
                                                                    question.options.map((option,key) => {
                                                                        return(
                                                                            <option value={option.id} key={key}>{option.option}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                            <hr />
                                                        </div>
                                                    )
                                                }else if(question.type == 'textarea'){
                                                    return(
                                                        <div className={"form-group"} key={key}>
                                                            <input type={"hidden"} name={"id_com_poll_questions[]"} value={question.id} />
                                                            <label>{question.question}</label>
                                                            <textarea className={"form-control"} name={"id_com_poll_options[]"} placeholder={question.options[0].option}></textarea>
                                                            <hr />
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </React.Fragment>
                                :
                                    null
                                }
                                {this.state.pollData.id_com_poll?
                                    <div className={"alert alert-success text-center"}>
                                        <i className={"fas fa-check fa-2x"}></i>
                                        <p className={"lead"}>Anket başarıyla tamamlandı. Görüşme notunu kaydedip sonlandırabilirsiniz.</p>
                                        <br />
                                        <button type={"button"} className={"btn btn-success"} data-dismiss="modal">Kapat</button>
                                    </div>
                                    : null
                                }
                            </div>
                            {this.state.questionsData ?
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-default" data-dismiss="modal">İptal
                                    </button>
                                    <button className="btn btn-primary">Anketi Tamamla</button>
                                </div>
                                : null
                            }
                        </div>
                    </form>
                </div>
            </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(InterviewAdd);