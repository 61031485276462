import React from 'react';
import {connect} from 'react-redux';
import '../../../node_modules/react-widgets/dist/css/react-widgets.css'
import {DropdownList} from 'react-widgets'
import {Multiselect} from 'react-widgets'


class SelectList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data2: [],
            table: '',
            id_com_oil_brand: '',
            id_com_city: '',
            id_com_product_type: '',
            id_com_product_class: '',
            id_com_product_sub_class1: '',
            id_com_product_sub_class2: '',
            id_com_interview_type: '',
            id_com_customer: '',
            id_com_brand: '',
            id_com_department: '',
            id_com_user_manager: '',
            id_com_interview_subject:'',
            bySubject:false,
            dealer:true,

        }
        this.Update = this.Update.bind(this);
    }


    componentDidMount() {
        this.Update();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            table: nextProps.table,
            id_com_oil_brand: nextProps.id_com_oil_brand,
            id_com_city: nextProps.id_com_city,
            id_com_product_type: nextProps.id_com_product_type,
            id_com_product_class: nextProps.id_com_product_class,
            id_com_product_sub_class1: nextProps.id_com_product_sub_class1,
            id_com_product_sub_class2: nextProps.id_com_product_sub_class2,
            id_com_interview_type: nextProps.id_com_interview_type,
            id_com_customer: nextProps.id_com_customer,
            id_com_brand: nextProps.id_com_brand,
            id_com_department: nextProps.id_com_department,
            id_com_user_manager: nextProps.id_com_user_manager,
            bySubject: nextProps.bySubject,
            id_com_interview_subject: nextProps.id_com_interview_subject,
            dealer:nextProps.dealer
        }
    }


    componentDidUpdate(prevProps, prevState) {

        if (this.props.id_com_oil_brand !== prevProps.id_com_oil_brand) {
            this.Update();
        } else if (this.props.id_com_city !== prevProps.id_com_city) {
            this.Update();
        } else if (this.props.id_com_product_type !== prevProps.id_com_product_type) {
            this.Update();
        } else if (this.props.id_com_product_class !== prevProps.id_com_product_class) {
            this.Update();
        } else if (this.props.id_com_product_sub_class1 !== prevProps.id_com_product_sub_class1) {
            this.Update();
        } else if (this.props.id_com_product_sub_class2 !== prevProps.id_com_product_sub_class2) {
            this.Update();
        } else if (this.props.accnr !== prevProps.accnr) {
            this.Update();

        } else if (this.props.customer_id !== prevProps.customer_id) {
            this.Update();
        } else if (this.props.id_com_depot !== prevProps.id_com_depot) {
            this.Update();
        } else if (this.props.id_com_product !== prevProps.id_com_product) {
            this.Update();
        } else if (this.props.id_com_brand !== prevProps.id_com_brand) {
            this.Update();
        } else if (this.props.id_com_interview_type !== prevProps.id_com_interview_type) {
            this.Update();
        } else if (this.props.id_com_customer !== prevProps.id_com_customer) {
            this.Update();
        } else if (this.props.id_com_brand !== prevProps.id_com_brand) {
            this.Update();
        } else if (this.props.id_com_department !== prevProps.id_com_department) {
            this.Update();
        } else if (this.props.id_com_user_manager !== prevProps.id_com_user_manager) {
            this.Update();
        } else if (this.props.id_com_interview_subject !== prevProps.id_com_interview_subject) {
            this.Update();
        }else if (this.props.bySubject !== prevProps.bySubject) {
            this.Update();
        }else if (this.props.dealer !== prevProps.dealer) {
            this.Update();
        }

        
         
    }


    async Update() {
        switch (this.state.table) {


            case 'com_oil_product':
                let url1 = this.props.api_url + '/selectRows/' + this.props.table;
                const data1 = await fetch(url1, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&id_com_oil_brand=' + this.state.id_com_oil_brand
                }).then(res => res.json()).then(result => {
                    return result;
                })
                this.setState({data: data1})

                break;
            case 'com_town':
                let url2 = this.props.api_url + '/selectRows/' + this.props.table;

                const data2 = await fetch(url2, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&id_com_city=' + this.state.id_com_city
                }).then(res => res.json()).then(result => {
                    return result;
                })
                this.setState({data: data2})

                break;
            case 'com_product_class':
                let url3 = this.props.api_url + '/selectRows/' + this.props.table;

                const data3 = await fetch(url3, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&id_com_product_type=' + this.state.id_com_product_type
                }).then(res => res.json()).then(result => {
                    return result;
                })
                this.setState({data: data3})

                break;
            case 'com_product_sub_class1':
                let url4 = this.props.api_url + '/selectRows/' + this.props.table;

                const data4 = await fetch(url4, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&id_com_product_type=' + this.state.id_com_product_type + '&id_com_product_class=' + this.state.id_com_product_class
                }).then(res => res.json()).then(result => {
                    return result;
                })
                this.setState({data: data4})

                break;
            case 'com_product_sub_class2':
                let url5 = this.props.api_url + '/selectRows/' + this.props.table;

                const data5 = await fetch(url5, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&id_com_product_type=' + this.state.id_com_product_type + '&id_com_product_class=' + this.state.id_com_product_class + '&id_com_product_sub_class1=' + this.state.id_com_product_sub_class1
                }).then(res => res.json()).then(result => {
                    return result;
                })
                this.setState({data: data5})

                break;
            case 'com_account_plan_group_level4':
                let url6 = this.props.api_url + '/selectRows/' + this.props.table;

                let body_120_320 = 'token=' + this.props.loginToken + '&type=user&id_com_account_plan_group_level2=' + this.props.accnr + '&id_com_company=' + this.props.id_com_company + '&id_com_customer=' + this.props.customer_id
                let body_general = 'token=' + this.props.loginToken + '&type=user&id_com_account_plan_group_level2=' + this.props.accnr + '&id_com_company=' + this.props.id_com_company;
                //    console.log(body_general)
                let body_10 = 'token=' + this.props.loginToken + '&type=user&id_com_account_plan_group_level1=' + this.props.accnr + '&id_com_company=' + this.props.id_com_company;

                let body_7 = 'token=' + this.props.loginToken + '&type=user&id_com_account_plan_main_class=' + this.props.accnr + '&id_com_company=' + this.props.id_com_company;

                //   console.log(this.props.id_com_invoice_type)
                //if( this.props.id_com_invoice_type==='5'||this.props.id_com_invoice_type==='6'){console.log(body_10)}

                const data6 = await fetch(url6, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: this.props.id_com_invoice_type === '5' || this.props.id_com_invoice_type === '6' || this.props.id_com_product_class === '16' ? body_10 : this.props.id_com_product_class === '17' ? body_7 :
                        this.props.accnr === '120' || this.props.accnr === '320' ? body_120_320 : body_general
                }).then(res => res.json()).then(result => {
                    //    console.log(result)

                    return result;
                })
                this.setState({data: data6})
                // console.log(data6)
                break;

            case 'com_depot':
                let url8 = this.props.api_url + '/selectRows/' + this.props.table;
                const data8 = await fetch(url8, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&id_com_company=' + this.props.id_com_company
                }).then(res => res.json()).then(result => {
                    return result;
                })
                this.setState({data: data8})

                break;
            case 'view_depot_selectList':
                let url9 = this.props.api_url + '/selectRows/' + this.props.table;
                const data9 = await fetch(url9, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&id_com_company=' + this.props.id_com_company + '&id_com_depot=' + this.props.id_com_depot
                }).then(res => res.json()).then(result => {
                    return result;
                })
                this.setState({data: data9})

                break;
            case 'view_depot_part_list':
                //   console.log( 'token='+this.props.loginToken+'&type=user&id_com_company='+this.props.id_com_company+'&id_com_depot='+this.props.id_com_depot+'&id_com_product='+this.props.id_com_product)
                let url10 = this.props.api_url + '/selectRows/view_depot_part_list';
                const data10 = await fetch(url10, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&id_com_company=' + this.props.id_com_company + '&id_com_depot=' + this.props.id_com_depot + '&id_com_product=' + this.props.id_com_product,
                }).then(res => res.json()).then(result => {
                    return result;
                })
                this.setState({data: data10})

                break;
            case 'com_brand':
                //console.log( 'token='+this.props.loginToken+'&type=user&id_com_company='+this.props.id_com_company+'&id_com_brand='+this.props.id_com_brand)
                let url13 = this.props.api_url + '/selectRows/' + this.props.table;
                const data13 = await fetch(url13, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: this.props.dealer ? 'token=' + this.props.loginToken + '&type=user&dealer=1' : 'token=' + this.props.loginToken + '&type=user',
                }).then(res => res.json()).then(result => {
                    return result;
                })
                this.setState({data: data13})

                break;
            case 'com_model':
                // console.log( 'token='+this.props.loginToken+'&type=user&id_com_company='+this.props.id_com_company+'&id_com_brand='+this.props.id_com_brand)
                let url11 = this.props.api_url + '/selectRows/' + this.props.table;
                const data11 = await fetch(url11, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&id_com_brand=' + this.props.id_com_brand,
                }).then(res => res.json()).then(result => {
                    return result;
                })
                this.setState({data: data11})

                break;
            case 'com_interview_subject':
                //console.log( 'token='+this.props.loginToken+'&type=user&id_com_company='+this.props.id_com_company+'&id_com_brand='+this.props.id_com_brand)
                let url12 = this.props.api_url + '/selectRows/' + this.props.table;
                const data12 = await fetch(url12, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&id_com_interview_type=' + this.props.id_com_interview_type,
                }).then(res => res.json()).then(result => {
                    return result;
                })
                this.setState({data: data12})

                break;
            case 'com_interview_status':
                //console.log( 'token='+this.props.loginToken+'&type=user&id_com_company='+this.props.id_com_company+'&id_com_brand='+this.props.id_com_brand)
                let url14 = this.props.api_url + '/selectRows/' + this.props.table;
                let type_body='token=' + this.props.loginToken + '&type=user&lock=0&id_com_interview_subject=0&id_com_interview_type=' + this.props.id_com_interview_type;
                let subject_body = 'token=' + this.props.loginToken + '&type=user&lock=0&id_com_interview_type=' + this.props.id_com_interview_type+'&id_com_interview_subject=' + this.props.id_com_interview_subject;
                const data14 = await fetch(url14, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: this.props.bySubject?subject_body:type_body,
                }).then(res => res.json()).then(result => {
                    return result;
                })

                this.setState({data: data14})

                break;
            case 'view_campaign':
                //console.log( 'token='+this.props.loginToken+'&type=user&id_com_company='+this.props.id_com_company+'&id_com_brand='+this.props.id_com_brand)
                let url15 = this.props.api_url + '/selectRows/' + this.props.table;
                await fetch(url15, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&id_com_brand=' + this.props.id_com_brand,
                }).then(res => res.json()).then(result => {
                    this.setState({data: result})
                })

                break;
            case 'view_user_list_type3':
                //console.log( 'token='+this.props.loginToken+'&type=user&id_com_company='+this.props.id_com_company+'&id_com_brand='+this.props.id_com_brand)
                let url16 = this.props.api_url + '/selectRows/' + this.props.table;
                await fetch(url16, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&id_com_brand=' + this.props.id_com_brand + '&id_com_department=' + this.props.id_com_department,
                }).then(res => res.json()).then(result => {
                    this.setState({data: result})
                })

                break;
            case 'view_cars_sl':
                //console.log( 'token='+this.props.loginToken+'&type=user&id_com_company='+this.props.id_com_company+'&id_com_brand='+this.props.id_com_brand)
                let url17 = this.props.api_url + '/selectRows/' + this.props.table;
                await fetch(url17, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&id_com_customer=' + this.props.id_com_customer,
                }).then(res => res.json()).then(result => {
                    this.setState({data: result})
                })
                break;


            case 'view_user_sl':
                //console.log( 'token='+this.props.loginToken+'&type=user&id_com_company='+this.props.id_com_company+'&id_com_brand='+this.props.id_com_brand)
                let url18 = this.props.api_url + '/selectRows/' + this.props.table;
                await fetch(url18, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&id_com_brand=' + this.props.id_com_brand + '&id_com_department=' + this.props.id_com_department,
                }).then(res => res.json()).then(result => {
                    this.setState({data: result})
                })
                break;
            case 'view_user_sl2':
                //console.log( 'token='+this.props.loginToken+'&type=user&id_com_company='+this.props.id_com_company+'&id_com_brand='+this.props.id_com_brand)
                let url19 = this.props.api_url + '/selectRows/' + this.props.table;
                await fetch(url19, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&id_com_user_manager=' + this.props.id_com_user_manager,
                }).then(res => res.json()).then(result => {
                    this.setState({data: result})
                })
                break;
                case 'view_user_sl_all':
                    //console.log( 'token='+this.props.loginToken+'&type=user&id_com_company='+this.props.id_com_company+'&id_com_brand='+this.props.id_com_brand)
                    let url20 = this.props.api_url + '/selectRows/view_user_sl';
                    await fetch(url20, {
                        method: 'POST',
                        cahce: 'no-cache',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: 'token=' + this.props.loginToken + '&type=user',
                    }).then(res => res.json()).then(result => {
                        this.setState({data: result})
                    })
                    break;

                    case 'view_customer_sl':
                        //console.log( 'token='+this.props.loginToken+'&type=user&id_com_company='+this.props.id_com_company+'&id_com_brand='+this.props.id_com_brand)
                        let url21 = this.props.api_url + '/selectRows/view_customer_sl';
                        await fetch(url21, {
                            method: 'POST',
                            cahce: 'no-cache',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            body: 'token=' + this.props.loginToken + '&type=user',
                        }).then(res => res.json()).then(result => {
                            this.setState({data: result})
                        })
                        break;

                    
            default:
                let urldefault = this.props.api_url + '/selectRows/' + this.props.table;
                //     console.log(urldefault)
                //   console.log('token='+this.props.loginToken+'&type=user')
                await fetch(urldefault, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user'
                }).then(res => res.json()).then(result => {
                    this.setState({data: result});
                    // console.log(result)
                })

            // let data=[];
            // datadefault.map(row=>data.push(row.title));
        }
        //  console.log(this.state.id_com_city)

    }
    render() {
        return (
            <div className="form-group">
                {this.props.noLabel ? <></> : <label>{this.props.Label}</label>}
                {this.props.multiple ?
                    <Multiselect
                        textField='title'
                        disabled={this.props.disabled}
                        onChange={this.props.onChange}
                        data={this.state.data}

                    /> :
                    <React.Fragment>
                            {this.props.noLabel ? <></> :<label style={{color:this.props.color}}>{this.props.label}</label>}
                            <select className="form-control" name={this.props.name} onChange={this.props.onChange}
                                    value={this.props.value} data-title="Seçiniz" data-live-search="true"
                                    disabled={this.props.disabled}>
                                <option key={0} value={0}>{"Seçiniz"}</option>
                                    {this.state.data.map((row, index) =>
                                        <option key={index} value={row.id}>{row.title} </option>
                                    )}
                            </select>
                    </React.Fragment>
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        id_com_company: state.constants.id_com_company,
        user_type: state.constants.user_type,
        api_url: state.constants.api_url,
        loginToken: state.constants.loginToken
    }
}
export default connect(mapStateToProps)(SelectList)