

import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component';



class OrderformDelivery extends React.Component {


    constructor(props){
        super(props);
        this.state={

        }
    }

    componentDidMount(){
        let id_com_orderform = this.props.id_com_orderform;
        this.setState({id_com_orderform:id_com_orderform})
    }
    render() {
        return ( 
            <div className="panel-body">
                            <h3 className="page-title">Teslimat  : </h3>

            </div>
        )
    }


}




export default connect(mapStateToProps)(OrderformDelivery);