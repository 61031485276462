import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
import {alertMessage, dateTime} from '../../assets.js';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import {Row,Col,Divider } from 'antd'; 
 
class   ExpertiseAdd extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            searchItem:'',
            expertise_history:[],
            SwapCar:[],
            SwapCarDetail:false,
            Exp_list:false,
            customerListData:[],
            filtered_line_data:[],
            pageloader:true,
            id_com_customer:'',
            columns:[
                {
                    name: 'Kontrol',
                    right: true,   
                    width:'100px',
                    cell: (row)=><button onClick={()=>this.setState({id_com_customer:row.id})} className="btn btn-primary btn-sm btn-block" style={{margin:5}}>Seç</button>
                },
               
                {
                    name: 'Firma  Adı',
                    selector: 'company_name',
                    sortable: true,
                    left: true,   
                    wrap:true,
                    width:'250px'

                },
                {
                    name: 'Müşteri  Adı',
                    selector: 'username',
                    wrap:true,
                    sortable: true,
                    center: true,   
                    width:'150px'

                },
                {
                    name: 'Telefon',
                    selector: 'phone1',
                    sortable: true,
                    center: true,   
                },
                
            ]
        }
        this.handleChange=this.handleChange.bind(this);
         this.GetCustomerList=this.GetCustomerList.bind(this);
        this.SwapCar = this.SwapCar.bind(this);
        this.SearchExp=this.SearchExp.bind(this);
        this.AddSwapCar=this.AddSwapCar.bind(this);
    }


componentDidMount(){
    let id_com_customer = this.props.match.params.id_com_customer
    id_com_customer!=='0'&&  this.setState({id_com_customer:id_com_customer});

    this.GetCustomerList();
}

    handleChange(event, selectlist, name) {
        if (selectlist) {
            this.setState({[`${name}`]: event.id})
        } else {
            const value = event.target.value;
            const key = event.target.name;
            const type = event.target.type;
            if (type === 'checkbox') {
                this.setState({[`${key}`]: event.target.checked ? '1' : '0'})
             } else {
                this.setState({[`${key}`]: value})
            }

        }
    }

    SwapCar(event) {
        event.preventDefault();
        let form = document.getElementById('SwapCarForm');
        let formData = new FormData(form);

        let array = {};
        for (var pair of formData.entries()) {
            let key = pair[0];
            let value = pair[1]
            array[key] = value;
        }
        this.setState({SwapCar: array, SwapCarDetail: true});
    }

    async SearchExp(x) {
        let str = x.target.value.toString();
        let event = str.toUpperCase();
        let plate = event.replace(' ', '');

        let url = this.props.api_url + '/selectRows/view_expertise';
        fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'license_plate=' + plate + '&token=' + this.props.loginToken,
        })
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(result => {
                     if (result.length > 0) {
                         this.setState({expertise_history: result, Exp_list: true,license_plate:plate,id_com_cars:result[0].id});
                    } else {
                        this.setState({expertise_history: [], Exp_list: false,license_plate:''});
                    }
                }
            )
     }


    // async handleSubmit(){
    //     let d = new Date();
    //     let date = d.toISOString();
    //     let c_date = date.split('T')[0];
    //     let c_time = d.toLocaleTimeString('tr-TR');

    //     let insert_body =
    //         'token=' + this.props.loginToken +
    //         '&c_date=' + c_date +
    //         '&c_time=' + c_time +
    //         '&id_com_user=' + this.props.id_com_user +
    //         '&type=user';
    //     for (let [key] of Object.entries(this.state)) {
           
    //             insert_body += `&${key}=` + this.state[`${key}`]
          
    //     }
    //     let com_customer_url = this.props.api_url + '/insert/com_customer';
    //     const response = await ( await ( fetch(com_customer_url, {
    //         method: 'POST',
    //         cahce: 'no-cache',
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         body: insert_body,
    //     }))).json();
    //     console.log(response)
    // }

            GetCustomerList = async () =>{
                let url = this.props.api_url+'/selectrows/view_customer';
            const customer_list = await( await   fetch(url, {
                    method: 'POST',
                    cache: 'no-cache',
                    headers:  {
                        'Content-Type':  'application/x-www-form-urlencoded'
                    },
                    body: 'type=user&token='+this.props.loginToken,
                }) ).json()

            this.setState({customerListData:customer_list,pageloader:false})

        }



        _Filter(value) { 
        this.setState({searchItem:value});

        let match_str = new RegExp('('+value+')','i');
        let data = this.state.customerListData;

        let filtered_data = data.filter((row)=>{

        if(value===''){ 
            return row
        }    else   {
            return row.company_name.match(match_str)||row.username.match(match_str)  ||row.phone1.match(match_str)
        }

        })
        this.setState({filtered_line_data:filtered_data})
        }


    SwapCarControl () { 


    }
 
    AddSwapCar() {

        let d = new Date();
        let date = d.toISOString();
        let c_date = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let swapcar = this.state.SwapCar;
        let id_com_brand = swapcar.id_com_brand;
        let id_com_model = swapcar.id_com_model;
        let id_com_gear = swapcar.id_com_gear;
        let id_com_fuel = swapcar.id_com_fuel;
        let id_com_color = swapcar.id_com_color;
        let km = swapcar.km;
        let model_year = swapcar.model_year;
        let spec = swapcar.spec;
        let str = swapcar.license_plate.toString();
        let event = str.toUpperCase();
        let license_plate = event.replace(' ', '');
        let chasis = swapcar.chasis;
        let demand_price = swapcar.demand_price;
        let comment = swapcar.comment;
        const id_com_customer = this.state.id_com_customer;

        let insert_car =
            'token=' + this.props.loginToken +
            '&id_com_customer=' + id_com_customer +
            '&id_com_brand=' + id_com_brand +
            '&id_com_model=' + id_com_model +
            '&id_com_gear=' + id_com_gear +
            '&id_com_fuel=' + id_com_fuel +
            '&id_com_color=' + id_com_color +
            '&model_year=' + model_year +
            '&spec=' + spec +
            '&license_plate=' + license_plate +
            '&chasis=' + chasis +
            '&c_date=' + c_date +
            '&c_time=' + c_time +
            '&id_com_user=' + this.props.id_com_user +
            '&type=user';
        // console.log(insert_car);
        let car_list_url = this.props.api_url + '/selectRows/com_cars';

        fetch(car_list_url, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'token=' + this.props.loginToken + '&license_plate=' + license_plate + '&type=user',
        })
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(result => {

                if (result.length > 0) {

                    let id_com_cars = result[0].id;
                    let insert_exp =
                        'token=' + this.props.loginToken +
                        '&id_com_requested_customer=' + id_com_customer +
                        '&id_com_cars=' + id_com_cars +
                        '&demand_price=' + demand_price +
                        '&offer_price=0' +
                        '&comment=' + comment +
                        '&km=' + km +
                        '&id_com_user=' + this.props.id_com_user +
                        '&c_date=' + c_date +
                        '&c_time=' + c_time +
                        '&id_com_expertise_status=1' +
                        '&type=user';
                    let exp_url = this.props.api_url + '/insert/com_expertise';
                    fetch(exp_url, {
                        method: 'POST',
                        cache: 'no-cache',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: insert_exp,
                    }).then(res=>window.location.href='/expertise/list')
                } else {
                    let car_url = this.props.api_url + '/insert/com_cars';
                    fetch(car_url, {
                        method: 'POST',
                        cache: 'no-cache',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: insert_car,
                    })
                        .catch(error => console.log(error))
                        .then(response => response.json())
                        .then(result => {
                            let id_com_cars = result.id;
                            let insert_exp =
                                'token=' + this.props.loginToken +
                                '&id_com_cars=' + id_com_cars +
                                '&demand_price=' + demand_price +
                                '&offer_price=0' +
                                '&comment=' + comment +
                                '&km=' + km +
                                '&id_com_user=' + this.props.id_com_user +
                                '&c_date=' + c_date +
                                '&c_time=' + c_time +
                                '&id_com_expertise_status=1' +
                                '&type=user';
                            let exp_url = this.props.api_url + '/insert/com_expertise';
                            fetch(exp_url, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                },
                                body: insert_exp,
                            }).then(res=>window.location.href='/expertise/list')

                        })
                }
            })
    }





render(){
    return ( 
        <div className="main">
        <div className="main-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="panel">
                        {this.state.id_com_customer===''?
                                     <div className="panel-body">
                                        <div className="panel-heading">
                                            <h3 className="panel-title">Müşteri Seçiniz</h3>
                                        </div>
                                        <div className="panel-body">
                                            <div className="form-group">
                                                <input value={this.state.searchItem} className="form-control" type="text" placeholder="TC Kimlik No, Telefon, Adı Soyadı" onChange={(x)=> {this._Filter(x.target.value)}} />
                                            </div>
                                                <DataTable 
                                                    data={this.state.searchItem===''?this.state.customerListData:this.state.filtered_line_data}
                                                    columns={this.state.columns}
                                                    highlightOnHover	
                                                    responsive
                                                    striped
                                                    dense
                                                    pagination
                                                    progressPending={this.state.pageloader}
                                                    progressComponent={  <h3>Yükleniyor ... </h3>  }
                                                    paginationPerPage={10}
                                                    noDataComponent={
                                                        <div className="alert alert-info" role="alert">
                                                            <h4 className="alert-heading text-center ">Aradığınız müşteri bulunamamıştır.</h4>
                                                            <p className="text-center">Lütfen Yeniden Deneyin veya Yeni Müşteri Oluşturunuz.</p>
                                                        <hr/>
                                                            <p className="mb-0 text-center">Müşterinin çift kayıt oluşturmaması adına lütfen aynı müşteriden olmadığını kontrol ediniz.</p>
                                                        <div className="col-md-6 col-md-offset-4" style={{marginTop:10}} >
                                                            <a className="btn btn-danger pull-center" href="/Customer/Add"  style={{margin:5}}>Yeni Müşteri Oluştur</a>
                                                        </div>
                                                        </div>
                                                    }
                                                
                                                />
                                         </div>
                                    </div>
 
                                                    :
                            <div className="panel-body">
                            

                            
                            <h4 className='text-center'>Ekspertiz Bilgileri </h4>
                            <Divider></Divider>

                            {this.state.SwapCarDetail ?
                                                <>
                                                                            <div className="col-md-12"
                                                                                 dangerouslySetInnerHTML={{__html: alertMessage('requestConfirm')}}></div>
                                                                            <button
                                                                                type='submit'
                                                                                value="Submit"
                                                                                className="btn btn-primary pull-right"
                                                                                onClick={() => this.AddSwapCar()}
                                                                            >Onayla
                                                                            </button>
                                                                            <button
                                                                                type='submit'
                                                                                value="Submit"
                                                                                className="btn btn-danger pull-left"
                                                                                onClick={() => window.location.reload()}
                                                                            >Temizle
                                                                            </button>
                                                                        </>
                                                                        :
                                        <form onSubmit={(event) => this.SwapCar(event)}
                                        method="post" id='SwapCarForm'>
                                            <Row gutter={12}>
                                                <Col xl={12} lg={12} md={24} sm={24} xs={24} >
                                                <div className="form-group">
                                                
                                                <label>Araç Plakası</label>
                                                <input type="text"
                                                        className="form-control"
                                                        name="license_plate"
                                                        onChange={(e) => this.SearchExp(e)}
                                                        placeholder="Başlık..."
                                                        required/>
                                                </div>
                                                <div className="form-group">
                                                <label>Talep Edilen Fiyat</label>
                                                <input type="text"
                                                        className="form-control"
                                                        name="demand_price"
                                                        placeholder="Başlık..."
                                                        required/>
                                                </div>
                                            </Col>
                                            <Col xl={12} lg={12} md={24} sm={24} xs={24} >
                                                <div className="form-group">
                                                <label>Kilometre</label>
                                                <input type="text"
                                                        className="form-control"
                                                        name="km" placeholder="Başlık..."
                                                        required/>
                                                </div>

                                                <div className="form-group">
                                                <label>Hasar Bilgisi </label>
                                                <input type="text"
                                                        className="form-control"
                                                        name="comment"
                                                        placeholder="Başlık..."
                                                        required/>
                                                </div>


                                            </Col>

                                        </Row>
                                   

                                        {
                                        !this.state.expertise_history.length > 0 ?<>
                                            <Divider>Diğer Bilgiler</Divider>

                                                <Row gutter={12}>
                                                    <Col xl={12} lg={12} md={24} sm={24} xs={24} >
                                                        <div className="form-group">
                                                            <label>Şase</label>
                                                            <input type="text"
                                                                    className="form-control"
                                                                    name="chasis"
                                                                    placeholder="Başlık..."/>
                                                        </div>
                                                        <SelectList table='com_brand'
                                                                    label={'Araç Markası'}
                                                                    name={'id_com_brand'}
                                                                    onChange={(x) => this.setState({id_com_swap_car_brand: x.target.value})}/>
                                                        <SelectList table='com_model'
                                                                    id_com_brand={this.state.id_com_swap_car_brand}
                                                                    label={'Araç Modeli'}
                                                                    name={'id_com_model'}/>


                                                        <div className="form-group">
                                                            <label>Araç Özellikleri /
                                                                Spec </label>
                                                            <input type="text"
                                                                    className="form-control"
                                                                    name="spec"
                                                                    placeholder="Başlık..."
                                                                    required/>
                                                        </div>

                                                    </Col>
                                                    <Col xl={12} lg={12} md={24} sm={24} xs={24} >
                                                        <div className="form-group">
                                                            <label>Model Yılı</label>
                                                            <input type="text"
                                                                    className="form-control"
                                                                    name="model_year"
                                                                    placeholder="Başlık..."
                                                                    required/>
                                                        </div>
                                                        <SelectList table='com_gear'
                                                                    label={'Vites Tipi'}
                                                                    name={'id_com_gear'}/>
                                                        <SelectList table='com_fuel'
                                                                    label={'Yakıt Tipi'}
                                                                    name={'id_com_fuel'}/>
                                                        <SelectList table='com_color'
                                                                    label={'Renk'}
                                                                    name={'id_com_color'}/>

                                                    </Col>
                                                </Row>
                                            </>
                                            :
                                            <>
                                                <h5 className='text-center'>Ekspertiz Geçmişi </h5>

                                                <table
                                                    className="table table-bordered table-striped">
                                                    <thead>
                                                    <tr className="active">
                                                        <th width="80">Talep
                                                            Tarihi
                                                        </th>
                                                        <th>Marka</th>
                                                        <th>Model</th>
                                                        <th>Spec</th>
                                                        <th>Yakıt / Vites</th>
                                                        <th>Model Yılı</th>
                                                        <th>Km</th>
                                                        <th>Km</th>
                                                        <th>Talep Fiyat</th>
                                                        <th>Teklif</th>
                                                        <th>Değişiklik Tarihi</th>
                                                        <th>Durum</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.expertise_history.map((row, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{moment(row.c_date).format('DD.MM.YYYY')} </td>
                                                                <td>{row.id_com_brand_name}</td>
                                                                <td>{row.id_com_model_name}</td>
                                                                <td>{row.spec}</td>
                                                                <td>{row.id_com_fuel_name + ' / ' + row.id_com_gear_name}</td>
                                                                <td>{row.model_year}</td>
                                                                <td>{row.license_plate}</td>
                                                                <td>{row.km}</td>
                                                                <td>{row.demand_price}</td>
                                                                <td>{row.offer_price}</td>
                                                                <td>{moment(row.m_date).format('DD.MM.YYYY')} </td>
                                                                <td>{row.id_com_expertise_status_name}</td>
                                                            </tr>)
                                                    })}
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                        <Divider></Divider>
                                        <Row justify='end' gutter={12}>
                                            <Col>
                                                <button  type='reset'  value="reset"  className="btn btn-danger  " onClick={()=>window.history.back()}>İptal </button>
                                            </Col>
                                            <Col>
                                                <button  type='submit'  value="Submit"  className="btn btn-primary " >Kaydet </button>
                                            </Col>
                                        </Row>
                                        </form>
                            }
                                    </div>  
                        }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


    )
}
}

export default connect(mapStateToProps)(ExpertiseAdd);



// <div className="form-group">
// <label>Plaka</label>
// <input type="text" className="form-control" name="license_plate"   onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
//  </div>
// <SelectList table='com_brand' label={'Araç Markası'} name={'id_com_brand'} value={this.state.id_com_brand} onChange={(x)=>this.handleChange(x)} />
// <SelectList table='com_model' id_com_brand={this.state.id_com_brand}  label={'Araç Modeli'} name={'id_com_model'} value={this.state.id_com_model} onChange={(x)=>this.handleChange(x)} />
// <SelectList table='com_gear'  label={'Vites Tipi'} name={'id_com_gear'} value={this.state.id_com_gear} onChange={(x)=>this.handleChange(x)} />
// <SelectList table='com_fuel'  label={'Yakıt Tipi'} name={'id_com_fuel'} value={this.state.id_com_fuel} onChange={(x)=>this.handleChange(x)} />
// <SelectList table='com_color'  label={'Renk'} name={'id_com_color'} value={this.state.id_com_color} onChange={(x)=>this.handleChange(x)} />
//  <div className="form-group">
// <label>Km</label>
// <input type="text" className="form-control" name="km" value={this.state.km}  onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
//  </div>
//  <div className="form-group">
// <label>Şase</label>
// <input type="text" className="form-control" name="chasis" value={this.state.chasis}  onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
//  </div>
 
//  <div className="form-group">
// <label>Teklif Fiyatı</label>
// <input type="text" className="form-control" name="offer_price" value={this.state.offer_price}  onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
//  </div>
//  <div className="form-group" >
//     <label>Hasar Bilgisi  </label>
//     <textarea type="text" style={{height:200}} row='20' value={this.state.comment} className="form-control" name="comment" onChange={(x) => this.handleChange(x)} required/>
//  </div>

//  <SelectList table='com_expertise_status'  label={'Ekspertiz'} name={'id_com_expertise_status'} value={this.state.id_com_expertise_status} onChange={(x)=>this.handleChange(x)} />


// <div className="panel-footer">
// <a className="btn btn-danger pull-left" href={"/Expertise/List"}>İptal</a>
// <button className="btn btn-primary pull-right"
// disabled={this.state.disabled}
// onClick={()=>this.handleSubmit()} 
// >Kaydet</button>     

// </div>