import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import CustomerDetail from './Detail';
import DataTable from 'react-data-table-component';
import { Button, Col, Row, Popover} from 'antd';

import { EditOutlined,CheckOutlined,LeftOutlined,SettingOutlined } from '@ant-design/icons';


class CustomerList extends React.Component {
    constructor(props){
        super(props);
        this.state={
            pageloader:true,
            customerListData:[],
            filtered_line_data:[],
            searchItem:'',
            selectedCustomer:'',
            customerDetail:false,
            columns:[
                {
                    name: 'Kontrol',
                    center: true,    
                    cell : row=> {
                        return ( 
                            <Row justify='space-between' gutter={6} style={{margin:5}}>
                                <Col span={24}>
                                    <Popover  placement="bottomLeft" title={'İşlemler'} content={()=>
                                     <div>
                                        <div>
                                            <a className="btn btn-success btn-sm btn-block" type="button"  href={'/interview/add/'+row.id} style={{margin:5}}>Görüşme Oluştur</a>
                                            <a className="btn btn-success btn-sm btn-block" type="button" href={'/expertise/add/' +row.id}  style={{margin:5}}>Ekspertiz Oluştur</a>
                                            <a className="btn btn-success btn-sm btn-block"   href={'/Customer/Detail/'+row.id} style={{margin:5}}>Müşteri Kartı</a>
                                        </div> 
                                    </div>
                                    } trigger="click">
                                        <Button key='settings' block type='primary' icon={<SettingOutlined/>} >İşlem</Button>
                                    </Popover>
                                </Col>

                            </Row>


                        )
                    }

                 },
                {
                    name: 'Firma  Adı',
                    selector: 'company_name',
                    sortable: true,
                    left: true,   
                    wrap:true,
                    width:'400px'

                },
                {
                    name: 'Müşteri  Adı',
                    selector: 'username',
                    sortable: true,
                    center: true,   
                },
                {
                    name: 'Telefon',
                    selector: 'phone1',
                    sortable: true,
                    center: true,   
                },
                {
                    name: 'Vergi No',
                    selector: 'taxno',
                    sortable: true,
                    center: true,   
                },
       
            ]
        }

         this.GetCustomerList=  this.GetCustomerList.bind(this)
    }

    componentDidMount () {

        this.GetCustomerList();

    }

     GetCustomerList = async () =>{
        let url = this.props.api_url+'/getCustomers';
        const customer_list = await( await   fetch(url, {
                method: 'POST',
                cache: 'no-cache',
                headers:  {
                    'Content-Type':  'application/x-www-form-urlencoded'
                },
                body: 'type=user&token='+this.props.loginToken,
            }) ).json()
        this.setState({customerListData:customer_list,pageloader:false})
    }


   
  _Filter(value) { 
    this.setState({searchItem:value});
    let match_str = new RegExp('('+value+')','i');
    let data = this.state.customerListData;
    let filtered_data = data.filter((row)=>{
      if(value===''){ 
          return row
      }    else   {
         return row.company_name.match(match_str)||row.username.match(match_str)  ||row.phone1.match(match_str)
       }

    })
     this.setState({filtered_line_data:filtered_data})
  }



    render() {
        return (
            // Step 1 Start
            this.state.customerDetail?
                <CustomerDetail id_com_customer={this.state.selectedCustomer} back={()=>this.setState({selectedCustomer:'',customerDetail:false})} />
            :
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h3 className="panel-title">Müşteri Seçiniz</h3>
                                        </div>
                                        <div className="panel-body">
                                            <div className="form-group">
                                                <input value={this.state.searchItem} className="form-control" type="text" placeholder="TC Kimlik No, Telefon, Adı Soyadı" onChange={(x)=> {this._Filter(x.target.value)}} />
                                            </div>
                                                <DataTable 
                                                    data={this.state.searchItem===''?this.state.customerListData:this.state.filtered_line_data}
                                                    columns={this.state.columns}
                                                    highlightOnHover	
                                                    responsive
                                                    striped
                                                    dense
                                                    pagination
                                                    progressPending={this.state.pageloader}
                                                    progressComponent={  <h3>Yükleniyor ... </h3>  }
                                                    paginationPerPage={10}
                                                    noDataComponent={
                                                        <div className="alert alert-info" role="alert">
                                                            <h4 className="alert-heading text-center ">Aradığınız müşteri bulunamamıştır.</h4>
                                                            <p className="text-center">Lütfen Yeniden Deneyin veya Yeni Müşteri Oluşturunuz.</p>
                                                        <hr/>
                                                            <p className="mb-0 text-center">Müşterinin çift kayıt oluşturmaması adına lütfen aynı müşteriden olmadığını kontrol ediniz.</p>
                                                        <div className="col-md-6 col-md-offset-4" style={{marginTop:10}} >
                                                            <a className="btn btn-danger pull-center" href="/Customer/Add"  style={{margin:5}}>Yeni Müşteri Oluştur</a>
                                                        </div>
                                                        </div>
                                                    }
                                                
                                                />
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}
export default connect(mapStateToProps)(CustomerList);


                                      