import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import AccordionCars from '../utilities/AccordionCars';
import AccordionAccess from '../utilities/AccordionAccess';
import AccordionSwap from '../utilities/AccordionSwap';
import AccordionPayments from '../utilities/AccordionPayments';
import DatePicker from '../utilities/DatePicker';
import * as CurrencyFormat from 'react-currency-format';
import moment from 'moment';

class OrderformEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            id_com_orderform:'',
            pageloader:false,
            car_summary:0,
            cars_data:[],
            access_summary:0,
            access_data:[],
            swap_summary:0,
            swap_data:[],
            payments_data:[],
            payments_summary:0,
            sub_total:0

    };
    this._jsonExtract=this._jsonExtract.bind(this);
    this.Submit=this.Submit.bind(this);
    this.DatePick=this.DatePick.bind(this);
    this.Car_Delet=this.Car_Delete.bind(this);
    this.Acces_Delete=this.Acces_Delete.bind(this);
    this.Payment_Delete=this.Payment_Delete.bind(this);
    this.Swap_Delete=this.Swap_Delete.bind(this);
    this.Delete_Order=this.Delete_Order.bind(this);
    }

   async  componentDidMount(){
        let id_com_orderform = this.props.match.params.id;
        this.setState({id_com_orderform:id_com_orderform})

        let select_url=this.props.api_url+'/selectRows/view_orderform';
    await    fetch(select_url,{
                 method:'POST',
                 cahce: 'no-cache',
                 headers:{ 
                   'Content-Type':'application/x-www-form-urlencoded'
                 },
                 body:  'token='+this.props.loginToken+'&type=user&id='+id_com_orderform
                
             }).then(res=>res.json())
             .then(result=>{
                console.log(result)

                 let data = result[0];
                 for (let [key, value] of Object.entries(data)) {
                 this.setState({ [`${key}`]:value,pageloader:true
                     })
                 }
             })


        let com_car_url=this.props.api_url+'/selectRows/view_orderform_lines';
        await  fetch(com_car_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                    'Content-Type':'application/x-www-form-urlencoded'
                },
                body:'id_com_orderform='+id_com_orderform+'&token='+this.props.loginToken+'&type=user'
            })
            .catch(error=>console.log(error))
            .then(res=>res.json())
            .then(result=>{
                let summary  = 0 ; 
                result.map(row=> summary +=parseFloat(row.price));
            this.setState({cars_data:result,car_summary:summary});
            })



            

            let com_access_url=this.props.api_url+'/selectRows/view_orderform_acces_lines';
            await      fetch(com_access_url,{
                    method:'POST',
                    cahce: 'no-cache',
                    headers:{ 
                        'Content-Type':'application/x-www-form-urlencoded'
                    },
                    body:'id_com_orderform='+id_com_orderform+'&token='+this.props.loginToken+'&type=user'
                })
                .catch(error=>console.log(error))
                .then(res=>res.json())
                .then(result=>{
                    let summary  = 0 ; 
                    result.map(row=> summary +=parseFloat(row.price));
                this.setState({access_data:result,access_summary:summary});
                })
    
                let com_swap_url=this.props.api_url+'/selectRows/view_ordeform_expertise_list';
                await  fetch(com_swap_url,{
                        method:'POST',
                        cahce: 'no-cache',
                        headers:{ 
                            'Content-Type':'application/x-www-form-urlencoded'
                        },
                        body:'id_com_orderform='+id_com_orderform+'&token='+this.props.loginToken+'&type=user'
                    })
                    .catch(error=>console.log(error))
                    .then(res=>res.json())
                    .then(result=>{
                        let summary  = 0 ; 
                        result.map(row=> summary +=parseFloat(row.price));
                        console.log(result)
                    this.setState({swap_data:result,swap_summary:summary});
                    })

                    let com_payment_url=this.props.api_url+'/selectRows/view_orderform_payments_lines';
                    await  fetch(com_payment_url,{
                            method:'POST',
                            cahce: 'no-cache',
                            headers:{ 
                                'Content-Type':'application/x-www-form-urlencoded'
                            },
                            body:'id_com_orderform='+id_com_orderform+'&token='+this.props.loginToken+'&type=user'
                        })
                        .catch(error=>console.log(error))
                        .then(res=>res.json())
                        .then(result=>{
                            let summary  = 0 ; 
                            result.map(row=> summary +=parseFloat(row.price));
                        this.setState({payments_data:result,payments_summary:summary});
                        })
    
            let sub_total =  parseFloat(this.state.car_summary)+parseFloat(this.state.access_summary)-parseFloat(this.state.swap_summary)-parseFloat(this.state.payments_summary)
           await             this.setState({sub_total:sub_total})
    }

    _jsonExtract(json){
        let str = '';
        let array = JSON.parse(json)
        array.map((row,index)=> {if(index===0){ str +=row.title}else{str +=','+row.title}})
        return str;
    }

    Submit(){

        let d = new Date(); 
        let date = d.toISOString();
        let m_date  = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');

        let update_body = 
        'token='+this.props.loginToken+
        '&id='+this.state.id_com_orderform+
        '&m_date='+m_date+
        '&m_time='+m_time+
        '&m_id_com_user='+this.props.id_com_user+
        '&delivery_date='+this.state.delivery_date+
        '&type=user&id_com_orderform_status=2';
        let com_orderform_url=this.props.api_url+'/update/com_orderform';
        fetch(com_orderform_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
              body:update_body,
            })
            .catch(error=>console.log(error)).then(x=> window.location.reload())
        
    }

    DatePick(value,type){

        switch(type){
            case 'date':
                let newdate = new Date(value)
                newdate.setDate(newdate.getDate())
                let delivery_date = newdate.toISOString().split('T')[0]
this.setState({delivery_date:delivery_date})       
         return null
            case 'time':
               this.setState({dist_time:value.target.value})       
                return null
            default:
                return null
            }
 
      }


      Car_Delete(id,id_com_stock){

        let line_url = this.props.api_url+'/delete/com_orderform_lines';
        let delete_body =  
        'token='+this.props.loginToken+
        '&id='+id+
        '&type=user'

        fetch(line_url, {
            method: 'POST',
            cache: 'no-cache',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body:delete_body,
        }).catch(error => console.log(error)).then(x=>{
            
        let stock_url = this.props.api_url+'/update/com_stock';
        let update_body =  
        'token='+this.props.loginToken+
        '&id='+id_com_stock+
        '&type=user&id_com_orderform=&status=0'

        fetch(stock_url, {
            method: 'POST',
            cache: 'no-cache',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body:update_body,
        }).catch(error => console.log(error)).then(x=>{window.location.reload()})
        
      })
    }
      

      Acces_Delete(id){
        let access_url = this.props.api_url+'/delete/com_orderform_acces_lines';
        let delete_body =  
        'token='+this.props.loginToken+
        '&id='+id+
        '&type=user'
         fetch(access_url, {
            method: 'POST',
            cache: 'no-cache',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body:delete_body,
        }).catch(error => console.log(error)).then(x=>{ window.location.reload()
        })
      }

      Payment_Delete(id){
        let access_url = this.props.api_url+'/delete/com_orderform_payment_lines';
        let delete_body =  
        'token='+this.props.loginToken+
        '&id='+id+
        '&type=user'
         fetch(access_url, {
            method: 'POST',
            cache: 'no-cache',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body:delete_body,
        }).catch(error => console.log(error)).then(x=>{ window.location.reload()
        })
      }

      Swap_Delete(id){

        let expertise_url = this.props.api_url+'/update/com_expertise';
        let update_body =  
        'token='+this.props.loginToken+
        '&id='+id+
        '&type=user&id_com_orderform='
        console.log(update_body)
        fetch(expertise_url, {
            method: 'POST',
            cache: 'no-cache',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body:update_body,
        }).catch(error => console.log(error)).then(x=>{window.location.reload()
        })

      }


      Delete_Order(){
        let access_url = this.props.api_url+'/delete/com_orderform';
        let delete_body =  
        'token='+this.props.loginToken+
        '&id='+this.state.id_com_orderform+
        '&type=user'
         fetch(access_url, {
            method: 'POST',
            cache: 'no-cache',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body:delete_body,
        }).catch(error => console.log(error)).then(x=>{ window.location.href='/orderform/list'
        })
      }

    render() { 
        return ( 
            <div className="main">
            <div className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <h3 className="page-title">Satış Dosyası No : {this.state.id_com_orderform}</h3>
                        </div>
                        <div className="col-md-12">
                            <div className="panel">

                                <div className="panel-body">
                                <table className="table table-bordered">
                                            <tbody>
                                            <tr>
                                                <th>Dosya Tipi</th>
                                                <td>{this.state.id_com_sale_type_name} </td>                                                      
                                            </tr>
                                            {this.state.id_com_orderform_status==='2'? 
                                            <tr>
                                                <th>Durum</th>
                                                <td style={{color:'red'}}>{this.state.id_com_orderform_status_name} </td>                                                      
                                            </tr>
                                            :<></>}

                                            <tr>
                                                <th>Müşteri Adı </th>
                                                <td>{this.state.id_com_customer_company_name===undefined||this.state.id_com_customer_company_name===null||this.state.id_com_customer_company_name==='-'?this.state.id_com_customer_name:this.state.id_com_customer_company_name+' / '+this.state.id_com_customer_name} </td>                                                      
                                            </tr>
                                            <tr>
                                                <th>İletişim Bilgisi  </th>
                                                <td>{' Tel : '+this.state.phone1+' / Email : '+this.state.email} </td>                                                      
                                            </tr>
                                            <tr>
                                                <th>Açan Kullanıcı  </th>
                                                <td>{this.state.id_com_user_name} </td>                                                      
                                            </tr>
                                            </tbody>
                                        </table>

                                </div>
                                <div className="panel-body">
                                {parseInt(this.state.id_com_orderform_status)>1? <></>:
                                    <AccordionCars id='cars' title={'Araçlar'} summary={this.state.car_summary} id_com_sale_type={this.state.id_com_sale_type} table={'view_stock'} id_com_orderform={this.props.match.params.id}/>    
                                }
                                    {this.state.cars_data.length>0? 
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Saşe</th>
                                                    <th>Araç</th>
                                                    <th>Yakıt / Vites</th>
                                                    <th>Ötv Muaf ?</th>
                                                    <th width='250'>Kampanya</th>
                                                    <th>Satış Fiyatı</th>

                                                    <th>Kontrol</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.cars_data.map((row,index)=>{   
                                                return  (
                                                        <tr key={index}>
                                                        <td>{row.chasis}</td>
                                                        <td>{row.id_com_brand_name+' / '+row.id_com_model_name+' / '+row.spec}</td>
                                                        <td>{row.id_com_fuel_name+' / '+row.id_com_gear_name}</td>
                                                        <td>{row.otv_status_name}</td>
                                                        <td>{ row.id_com_campaigns==='0'?'-':this._jsonExtract(row.id_com_campaigns)} </td>
                                                        <td><CurrencyFormat value={Math.round(parseFloat(row.price)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/></td>
                                                        <td>
                                                        {parseInt(this.state.id_com_orderform_status)>1?  <></>:
                                                        <button    type='button' className='btn btn-danger btn-xs'  onClick={()=>this.Car_Delete(row.id,row.id_com_stock)} >Sil</button>}
                                                        
                                            
                                                        
                                                        </td>
                                                    </tr>
                                                    )})}
                                            </tbody>
                                            </table>   
                                    :<></>}
                                </div>
                                <div className="panel-body">
                                    {!this.state.cars_data.length>0?
                                        <div className="panel-heading ">
                                        <div className="panel-title text-center">Aksesuar seçimi için lütfen önce araç bağlayınız!</div>
                                        </div>
                                    :<>
                                   {parseInt(this.state.id_com_orderform_status)<6?  <></>:
                                    <AccordionAccess id='accessories' title={'Aksesuarlar'} summary={this.state.access_summary}  cars_data={this.state.cars_data} id_com_sale_type={this.state.id_com_sale_type}   id_com_orderform={this.props.match.params.id}/>    
                                      }
                                    {this.state.access_data.length>0? 
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Araç</th>
                                                    <th>Aksesuar Adı</th>
                                                    <th width='150'>Fiyatı</th>
                                                    <th>Kontrol</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.access_data.map((row,index)=>{   
                                                return  (
                                                        <tr key={index}>
                                                        <td>{row.id_com_stock_name}</td>
                                                        <td>{row.text}</td>
                                                        <td><CurrencyFormat value={Math.round(parseFloat(row.price)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/></td>
                                                        <td> {parseInt(this.state.id_com_orderform_status)>1?  <></>:
                                                        <button type='button' className='btn btn-danger btn-xs' onClick={()=>this.Acces_Delete(row.id)}>Sil</button>}</td>
                                                    </tr>
                                                    )})}
                                            </tbody>
                                            </table>   
                                    :<></>}
                                    </>}
                                </div>
                                <div className="panel-body">
                                {parseInt(this.state.id_com_orderform_status)>1?   <></>:

                                    <AccordionSwap id='swapcars' title={'Takas Araçlar'}  summary={this.state.swap_summary} id_com_interview={this.state.id_com_interview}  swap_data={this.state.swap_data} id_com_orderform={this.props.match.params.id}/>    
                                }
                                    {this.state.swap_data.length>0? 
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Plaka</th>
                                                    <th>Takas Araç</th>
                                                    <th>Model Yılı</th>
                                                    <th>Km</th>
                                                    <th width='150'>Takas Fiyatı</th>
                                                    <th>Kontrol</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.swap_data.map((row,index)=>{   
                                                return  (
                                                        <tr key={index}>
                                                        <td>{row.license_plate}</td>
                                                        <td>{row.id_com_expertise_name}</td>
                                                        <td>{row.model_year}</td>
                                                        <td>{row.km}</td>

                                                        <td><CurrencyFormat value={Math.round(parseFloat(row.price)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/></td>
                                                        <td>
                                                        {parseInt(this.state.id_com_orderform_status)>1? 
                                                            row.status==='1'? <button className="btn btn-danger btn-sm" type="button" disabled={true} ><i className="fas fa-check-double"> {row.m_date+' / '+row.m_time}</i></button>:<></>

                                                        :
                                                        <button type='button' className='btn btn-danger btn-xs' onClick={()=>this.Swap_Delete(row.id_com_expertise)}>Sil</button>}</td>
                                                    </tr>
                                                    )})}
                                            </tbody>
                                            </table>   
                                    :<></>}
                                 </div> 
                                <div className="panel-body">  
                                {parseInt(this.state.id_com_orderform_status)>1?  <></>:
                              
                                <AccordionPayments id='payments' title={'Ödemeler'}  summary={this.state.payments_summary} id_com_orderform={this.props.match.params.id}/>    
                                }
                                    {this.state.payments_data.length>0? 
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Ödeme Tipi</th>
                                                    <th>Banka</th>
                                                    <th>İşlem Tarihi</th>
                                                    <th width='150'>Tutar</th>
                                                    <th width='250'>Açıklama</th>
                                                    <th >Kontrol</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.payments_data.map((row,index)=>{   
                                                return  (
                                                        <tr key={index}>
                                                        <td>{row.id_com_payment_type_name}</td>
                                                        <td>{row.id_com_bank_name}</td>
                                                        <td>{moment(row.est_payment_date).format('DD.MM.YYYY')}</td>

                                                        <td><CurrencyFormat value={Math.round(parseFloat(row.price)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/></td>
                                                        <td>{row.comment}</td>
                                                        <td  >{parseInt(this.state.id_com_orderform_status)>1? 
                                                        row.status==='1'? <button className="btn btn-danger btn-sm  " type="button" disabled={true} ><i className="fas fa-check-double"> {row.m_date+' / '+row.m_time}</i></button>:<></>
                                                        
                                                        :
                                                        <button type='button' className='btn btn-danger btn-xs' onClick={()=>this.Payment_Delete(row.id)}>Sil</button>}</td>
                                                    </tr>
                                                    )})}
                                            </tbody>
                                            </table>   
                                    :<></>}
                                </div>     
                                <div className="panel-body">
                                       
                                {this.state.sub_total!==0?<h5>Toplam Kalan Tutar :   <CurrencyFormat value={Math.round(parseFloat(this.state.sub_total)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/></h5>:
                                    parseInt(this.state.id_com_orderform_status)>1?
                                        <div className="form-group">
                                        <label>Teslimat Tarihi</label>
                                            <input type='date' className='form-control' value={this.state.delivery_date}   disabled={true} />
                                        </div>
                                                    :
                                                    this.state.cars_data.length>0?
                                          <div className="form-group">
                                        <label>Teslimat Tarihi</label>
                                            <DatePicker    onChange={(x)=>this.DatePick(x,'date')} />
                                        </div>:null 
                                }
                                 </div>   
                                <div className="panel-footer">
                                <a className="btn btn-danger" 
                                  href={'/orderform/list'}
                                    >Geri</a>
           
                              

                              {parseInt(this.state.id_com_orderform_status)>1?
                                <></>:
                                <button className="btn btn-success pull-right" 
                                 style={{margine:5}}
                                disabled={this.state.sub_total===0&&this.state.cars_data.length!==0?false:true}
                                  onClick={()=>this.Submit()}
                                     >{this.state.sub_total===0?"Onay'a Gönder":'Kalan Tutar Sıfırlanmadı'}</button>
                              }
                              <button className="btn btn-danger pull-right" 
                              style={{margine:5}}
                                disabled={this.state.cars_data.length===0&&this.state.payments_data.length===0&&this.state.swap_data.length===0&&this.state.access_data.length===0?false:true}
                                  onClick={()=>this.Delete_Order()}
                                     >Sil</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(OrderformEdit);