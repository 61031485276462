import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
 
class Interview_edit_body extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled:true,
            data:[]
        };
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }

    componentDidMount(){
        const id = this.props.match.params.id;
        this.setState({ 
            id: id
        })

        let view_body_url=this.props.api_url+'/selectRows/view_interview';
        fetch(view_body_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
              body:'id='+id+'&token='+this.props.loginToken+'&type=user'
            })
            .catch(error=>console.log(error))
            .then(res=>res.json())
            .then(result=>{
                let data = result[0];
            for (let [key, value] of Object.entries(data)) {
            this.setState({ [`${key}`]:value,pageloader:true
                })
            }
            })
          
    let com_body_url=this.props.api_url+'/selectRows/com_interview';
    fetch(com_body_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body:'id='+id+'&token='+this.props.loginToken+'&type=user'
        })
        .catch(error=>console.log(error))
        .then(res=>res.json())
        .then(result2=>{
        this.setState({data:result2[0]})
        })




    }

    // handleChange(event,selectlist,name) {
    //     if(selectlist) { 
    //         this.setState({ [`${name}`]:event.id})
    //         // console.log('id changed')
    //     } else { 
    //         const value = event.target.value;
    //         const key = event.target.name;
    //         this.setState({ [`${key}`]:value})
    //     }
    //     this.setState({disabled:false})
    // }

    handleChange(event,selectlist,name) {
        // console.log(event.target)
        
                if(selectlist) { 
                    this.setState({ [`${name}`]:event.id})
                } 
                else { 
        
                 if(event.target.type==='checkbox'){ 
                       event.target.checked?this.setState({[`${event.target.name}`]:'1'}):this.setState({[`${event.target.name}`]:'0'})
                                } else {
                    const value = event.target.value;
                    const key = event.target.name;
                    this.setState({ [`${key}`]:value})
                 } }
                 this.setState({disabled:false})

            }

   async handleSubmit(event) {
        let d = new Date(); 
        let date = d.toISOString();
        let m_date  = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');


        let update_body = 
        'token='+this.props.loginToken+
        '&type=user';

        await this.setState({m_date:m_date,m_id_com_user:this.props.id_com_user,m_time:m_time})

        for (let [key] of Object.entries(this.state.data)) {
            update_body +=`&${key}=`+ this.state[`${key}`]
    }

 
    let com_body_url=this.props.api_url+'/update/com_interview';
    fetch(com_body_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body:update_body,
        })
        .catch(error=>console.log(error)).then(x=> {
        this.setState({disabled:false});
        window.location.href='/interview/Detail/'+this.state.id;
    })
}



    render() { 
        return (
            <div className="main">
            <div className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel">
                                <div className="panel-heading">
                                    <div className="panel-title">Görüşme Detayı</div>
                                </div>
                                <div className="panel-body">
                                <div className="form-group">
                                        <label>Kayıt Tarihi</label>
                                        <input type="date" className="form-control" name="c_date" value={this.state.c_date}  onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                                    </div>
                                <SelectList table='com_interview_type' label={'Görüşme Tipi'} name={'id_com_interview_type'} value={this.state.id_com_interview_type} onChange={(x)=>this.handleChange(x)} />
                                <SelectList table='com_interview_subject' label={'Görüşme Tipi'} name={'id_com_interview_subject'}  id_com_interview_type={this.state.id_com_interview_type} value={this.state.id_com_interview_subject} onChange={(x)=>this.handleChange(x)} />
                                <SelectList table='com_brand' label={'Marka'} name={'id_com_brand'} value={this.state.id_com_brand} onChange={(x)=>this.handleChange(x)} />
                                <SelectList table='com_model' label={'Model'} name={'id_com_model'}  id_com_brand={this.state.id_com_brand} value={this.state.id_com_model} onChange={(x)=>this.handleChange(x)} />
                                <SelectList table='com_meet' label={'Temas Türü'} name={'id_com_meet'}    value={this.state.id_com_meet} onChange={(x)=>this.handleChange(x)} />
                                    {['5','7'].includes(this.state.id_com_interview_type) && 
                                        <SelectList table='view_cars_sl'    id_com_customer={this.state.id_com_customer}  label={'Arac Seçiniz'} name={'id_com_cars'}   value={this.state.id_com_cars}   onChange={(x) => this.handleChange(x)}/>
                                    }
                               {this.state.id_com_interview_type==='5'?<>
                                <SelectList table='com_insurance_expert' id_com_interview_type={this.state.id_com_insurance_expert} label={'Eksper Seçiniz'} name={'id_com_insurance_expert'}    value={this.state.id_com_interview_status} onChange={(x)=>this.handleChange(x)} />
                                <SelectList table='com_insurance_company' id_com_interview_type={this.state.com_insurance_company} label={'Sigorta Firması'} name={'id_com_insurance_company'}    value={this.state.id_com_interview_status} onChange={(x)=>this.handleChange(x)} />
                                <div className="form-check" >
                                                  <input className="form-check-input"  name='traffic' type="checkbox" id='traffic' onChange={(x)=>this.handleChange(x)}/>
                                                  <label className="form-check-label" style={{marginLeft:5}} >Trafik Sigorta</label>
                                            </div>
                                            <div className="form-check" >
                                                  <input className="form-check-input"  name='kasko' type="checkbox" id='kasko' onChange={(x)=>this.handleChange(x)}/>
                                                  <label className="form-check-label" style={{marginLeft:5}} >Kasko Sigorta</label>
                                            </div>
                                            </>
                             :<></>
                               }
                                <SelectList table='com_interview_status' id_com_interview_type={this.state.id_com_interview_type} label={'Görüşme Kapanış Durumu'} name={'id_com_interview_status'}    value={this.state.id_com_interview_status} onChange={(x)=>this.handleChange(x)} />

                                <div className="form-group">
                                    <label>Görüşme Durumu</label>
                                    <select className="form-control" name='status' onChange={(x)=>this.handleChange(x)} value={this.state.status} data-title="Seçiniz" data-live-search="true">
                                    <option key={1} value={0}>{'Açık'}</option>
                                    <option key={2} value={1} >{'Kapalı'} </option>
                                    </select>
                                </div>
                               
                                <div className="panel-footer">
                                <a className="btn btn-danger pull-left" href={"/interview/Detail/"+this.state.id}>İptal</a>
                                <button className="btn btn-primary pull-right"
                                    disabled={this.state.disabled}
                                    onClick={()=>this.handleSubmit()} 
                                    >Kaydet</button>                               
                                </div>
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
          </div>

          </div>



        )
    }



}


export default connect(mapStateToProps)(Interview_edit_body);