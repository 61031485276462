import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import mapStateToProps from './redux/mapStateToProps';
import mapDispatchToProps from './redux/mapDispatchToProps';


import Navbar from './Layout/NavBar';
import SideBar from './Layout/SideBar';
import Footer from './Layout/Footer';
import LoginForm from './components/LoginForm';
import HomeScreen from './components/HomeScreen';
import SignUp from './components/SignUp';
// import AppList from './components/Appointments/AppList';
// import AppAdd from './components/Appointments/AppAdd';
// import AppCard from './components/Appointments/AppCard';
// import AppDetails from './components/Appointments/AppDetails';
import AppConfirm from './components/Appointments/AppConfirm';
import AccList from './components/Accounting/AccList';
import ProductGroupList from './components/Product/ProductGroupList';
import ProductList from './components/Product/ProductList';
import ProductAdd from './components/Product/ProductAdd';
import ProductSearch from './components/Product/ProductSearch';
import InvList from './components/Accounting/InvList';
import InvAdd from './components/Accounting/InvAdd';
import InvCard from './components/Accounting/InvCard';
import DepotGroupList from './components/Depot/DepotGroupList';
import DepotStore from './components/Depot/DepotStore';
import DepotSale from './components/Depot/DepotSale';
import DepotCard from './components/Depot/DepotCard';
import StoreCard from './components/Depot/StoreCard';
import SetList from './components/Production/SetList';
import SetCard from './components/Production/SetCard';
import ProductionAdd from './components/Production/ProductionAdd';
import ProductionList from './components/Production/ProductionList';
import ProductionCard from './components/Production/ProductionCard';
/* Department */
import DepartmentAdd from './components/Department/Add';
import DepartmentEdit from './components/Department/Edit';
import DepartmentList from './components/Department/List';
/* Location */
import LocationAdd from './components/Location/Add';
import LocationEdit from './components/Location/Edit';
import LocationList from './components/Location/List';
/* Brand */
import BrandAdd from './components/Brand/Add';
import BrandEdit from './components/Brand/Edit';
import BrandList from './components/Brand/List';
/* Model */
import ModelAdd from './components/Model/Add';
import ModelEdit from './components/Model/Edit';
import ModelList from './components/Model/List';
/* Fuel */
import FuelAdd from './components/Fuel/Add';
import FuelEdit from './components/Fuel/Edit';
import FuelList from './components/Fuel/List';
/* Gear */
import GearAdd from './components/Gear/Add';
import GearEdit from './components/Gear/Edit';
import GearList from './components/Gear/List';
/* Color */
import ColorAdd from './components/Color/Add';
import ColorEdit from './components/Color/Edit';
import ColorList from './components/Color/List';
/* Job */
import JobAdd from './components/Job/Add';
import JobEdit from './components/Job/Edit';
import JobList from './components/Job/List';
/* Job */
import SectorAdd from './components/Sector/Add';
import SectorEdit from './components/Sector/Edit';
import SectorList from './components/Sector/List';
/* Meet */
import MeetAdd from './components/Meet/Add';
import MeetEdit from './components/Meet/Edit';
import MeetList from './components/Meet/List';
/* Interview Subject */
import InterviewAdd from './components/Interview/Add';
import Interview_edit_body from './components/Interview/Edit_body';
import Interview_edit_lines from './components/Interview/Edit_lines';

import InterviewList from './components/Interview/List';
import InterviewDetail from './components/Interview/Detail';
import InterviewListAll from './components/Interview/BACKUP/ListAll';
import InterviewListOpen from './components/Interview/BACKUP/ListOpen';

/* Interview Subject */
import InterviewSubjectAdd from './components/InterviewSubject/Add';
import InterviewSubjectEdit from './components/InterviewSubject/Edit';
import InterviewSubjectList from './components/InterviewSubject/List';
/* Interview Status */
import InterviewStatusAdd from './components/InterviewStatus/Add';
import InterviewStatusEdit from './components/InterviewStatus/Edit';
import InterviewStatusList from './components/InterviewStatus/List';
/* User Type */
import UsertypeAdd from './components/Usertype/Add';
import UsertypeEdit from './components/Usertype/Edit';
import UsertypeList from './components/Usertype/List';
/* User */
import UserAdd from './components/User/Add';
import UserEdit from './components/User/Edit';
import UserList from './components/User/List';
/* Customer */
import CustomerList from './components/Customer/List';
import CustomerAdd from './components/Customer/Add';
import CustomerEdit from './components/Customer/Edit';
import CustomerDetail from './components/Customer/Detail';
/* Customer */
import CarsDetail from './components/Cars/Detail';
import CarsAdd from './components/Cars/Add';
import CarsEdit from './components/Cars/Edit';

/* Expertise */
import ExpertiseList from './components/Expertise/List';
import ExpertiseEdit from './components/Expertise/Edit';
import ExpertiseDetail from './components/Expertise/Detail';
import ExpertiseAdd from './components/Expertise/Add';
/* Orderform */
import OrderformList from './components/Orderform/List';
import OrderformAdd from './components/Orderform/Add';
import OrderformEdit from './components/Orderform/Edit';
import OrderformApproval from './components/Orderform/Approval';
import OrderformDelivery from './components/Orderform/Delivery';

/* ServiceApp */
import ServiceAppAdd from './components/ServiceApp/Add';
import ServiceAppDetails from './components/ServiceApp/Details';
import ServiceAppList from './components/ServiceApp/List';

/* Feedback */
import FeedBackAdd from './components/FeedBack/Add';
import FeedBackDetails from './components/FeedBack/Details';
import FeedBackEdit from './components/FeedBack/Edit';
import FeedBackList from './components/FeedBack/List';
/* Stock */
import StockList from './components/Stock/List';
import StockAdd from './components/Stock/Add';
import StockEdit from './components/Stock/Edit';
/* Task */
import TaskList from './components/Task/List';
import TaskAdd from './components/Task/Add';
import TaskEdit from './components/Task/Edit';
import TaskListPersonel from './components/Task/ListPersonel';
import TaskManager from './components/Task/TaskManager';
/* Poll */
import PollAdd from "./components/Poll/Add";
import PollEdit from "./components/Poll/Edit";
import PollList from "./components/Poll/List";
import PollQuestionsAdd from "./components/PollQuestions/Add";
/* Reports */
import ReportCrm from './components/Report/report.crm';
import ReportSale from './components/Report/report.sale';
import ReportReception from './components/Report/report.reception';
import ReportServiceApp from './components/Report/report.serviceapp';
import ReportCrmT2 from './components/Report/report.crm.t2';

/* Credits */

import CreditList from './views/Finance/Credits/Credits.list';
import CreditAdd from './views/Finance/Credits/Credits.add';
import CreditDetail from './views/Finance/Credits/Credits.detail';

/* Finance Payment */

import FinancePaymentList from './views/Finance/Payments/Payments.list';
import FinancePaymentAdd from './views/Finance/Payments/Payments.add';
import reportCrmT5 from './components/Report/report.crm.t3';
import reportCrmT3 from './components/Report/report.crm.t3';
import reportCrmT3S7 from './components/Report/report.crm.t3.s7';
import reportServiceappmonthly from './components/Report/report.serviceappmonthly';
import reportCrmT1 from './components/Report/report.crm.t1';
import reportPhonelist from './components/Report/report.phonelist';

// sigorta 

import PolUpload from './views/Insurance/PolUpload';
import reportCrmT1Forward from './components/Report/report.crm.t1.forward';
import BankAccountList from './views/Finance/Banks/BankAccountList';
import DebitsList from './views/Finance/Debits/DebitsList';
import FinancialCost from './views/Finance/Cost/FinancialCost';
import CostGroupList from './views/Finance/Cost/CostGroupList';
import CheckList from './views/Finance/Checks/CheckList';
 

// Transfer 

import TransferCrmAll from './components/Report/transfer.crmall';

// UsedCars 

import UsedCarDetail from './components/UsedCars/Detail';
import UsedCarList from './components/UsedCars/List';
import UsedCarEdit from './components/UsedCars/Edit';

// Webform 
import webformList from './components/WebForm/webform.list';




class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datas: [],
            loggedin: false
        };
    }

    componentDidMount() {
        
        let url = this.props.api_url + '/loginCheck';
        if (this.props.password === '' || this.props.email === '') {
            this.setState({loggedin: false})
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("email", this.props.email);
            urlencoded.append("password", this.props.password);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            fetch(url, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status) {
                        this.props._Add_Login_Token(result.token);
                        this.props._Add_Id_Com_User(result.id);
                        this.props._Add_Email(result.email);
                        this.props._Add_Password(result.password);
                    } else {
                        alert('Kullanıcı Bilgilerinizi ve şifrenizi kontrol ediniz!');
                        this.props._Log_Status_Change();
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    render() {
        return (
            <Router>
                {!this.props.log_status ?
                    <Route exact path="/" component={LoginForm}/>
                    :
                    <React.Fragment>
                        <Navbar/>
                        <SideBar/>
                        <Route exact path="/" component={HomeScreen}/>

                        <Route exact path="/location/add" component={LocationAdd}/>
                        <Route exact path="/location/edit/:id" component={LocationEdit}/>
                        <Route exact path="/location" component={LocationList}/>

                        <Route exact path="/department/add" component={DepartmentAdd}/>
                        <Route exact path="/department/edit/:id" component={DepartmentEdit}/>
                        <Route exact path="/department" component={DepartmentList}/>

                        <Route exact path="/brand/add" component={BrandAdd}/>
                        <Route exact path="/brand/edit/:id" component={BrandEdit}/>
                        <Route exact path="/brand" component={BrandList}/>

                        <Route exact path="/model/add" component={ModelAdd}/>
                        <Route exact path="/model/edit/:id" component={ModelEdit}/>
                        <Route exact path="/model" component={ModelList}/>

                        <Route exact path="/fuel/add" component={FuelAdd}/>
                        <Route exact path="/fuel/edit/:id" component={FuelEdit}/>
                        <Route exact path="/fuel" component={FuelList}/>

                        <Route exact path="/gear/add" component={GearAdd}/>
                        <Route exact path="/gear/edit/:id" component={GearEdit}/>
                        <Route exact path="/gear" component={GearList}/>

                        <Route exact path="/color/add" component={ColorAdd}/>
                        <Route exact path="/color/edit/:id" component={ColorEdit}/>
                        <Route exact path="/color" component={ColorList}/>

                        <Route exact path="/job/add" component={JobAdd}/>
                        <Route exact path="/job/edit/:id" component={JobEdit}/>
                        <Route exact path="/job" component={JobList}/>

                        <Route exact path="/sector/add" component={SectorAdd}/>
                        <Route exact path="/sector/edit/:id" component={SectorEdit}/>
                        <Route exact path="/sector" component={SectorList}/>

                        <Route exact path="/meet/add" component={MeetAdd}/>
                        <Route exact path="/meet/edit/:id" component={MeetEdit}/>
                        <Route exact path="/meet" component={MeetList}/>

                        <Route exact path="/interview-subject/add" component={InterviewSubjectAdd}/>
                        <Route exact path="/interview-subject/edit/:id" component={InterviewSubjectEdit}/>
                        <Route exact path="/interview-subject" component={InterviewSubjectList}/>

                        <Route exact path="/interview/add/:id" component={InterviewAdd}/>
                        <Route exact path="/interview/interview_edit_body/:id" component={Interview_edit_body}/>
                        <Route exact path="/interview/interview_edit_lines/:id" component={Interview_edit_lines}/>

                        <Route exact path="/interview/List" component={InterviewList} />
                        <Route exact path="/interview/List/:id_com_interview_type" component={InterviewList} />
                        <Route exact path="/interview/ListOpen" component={InterviewListOpen}/>
                        <Route exact path="/interview/ListAll" component={InterviewListAll}/>

                        <Route exact path="/interview/Detail/:id" component={InterviewDetail}/>

                        <Route exact path="/interview-status/add" component={InterviewStatusAdd}/>
                        <Route exact path="/interview-status/edit/:id" component={InterviewStatusEdit}/>
                        <Route exact path="/interview-status" component={InterviewStatusList}/>
                     
                        {(this.props.id_com_user_type === '2' || this.props.id_com_user_type === '4' || this.props.id_com_user_type === '1') &&
                            <>
                        <Route exact path="/user-type/add" component={UsertypeAdd}/>
                        <Route exact path="/user-type/edit/:id" component={UsertypeEdit}/>
                        <Route exact path="/user-type" component={UsertypeList}/>

                        <Route exact path="/user/add" component={UserAdd}/>
                        <Route exact path="/user/edit/:id" component={UserEdit}/>
                        <Route exact path="/user" component={UserList}/>
                           </> }
                           
                        <Route exact path="/Customer/List" component={CustomerList}/>
                        <Route exact path="/Customer/Add" component={CustomerAdd}/>
                        <Route exact path="/Customer/Edit/:id" component={CustomerEdit}/>
                        {/* <Route exact path="/Customer/Detail" component={CustomerDetail}/> */}
                        <Route exact path="/Customer/Detail/:id" component={CustomerDetail}/>

                        <Route exact path="/expertise/list" component={ExpertiseList}/>
                        <Route exact path="/expertise/edit/:id" component={ExpertiseEdit}/>
                        <Route exact path="/expertise/detail/:id" component={ExpertiseDetail}/>
                        <Route exact path="/expertise/add/:id_com_customer" component={ExpertiseAdd}/>
                        
                        <Route exact path="/orderform/list" component={OrderformList}/>
                        <Route exact path="/orderform/add/:id_com_interview/:id_com_customer" component={OrderformAdd}/>
                        <Route exact path="/orderform/edit/:id" component={OrderformEdit}/>
                        <Route exact path="/orderform/approval/:id" component={OrderformApproval}/>
                        <Route exact path="/orderform/delivery/:id" component={OrderformDelivery}/>

                        <Route exact path="/reports/crmreport" component={ReportCrm}/>
                        <Route exact path="/reports/salereport" component={ReportSale}/>
                        <Route exact path="/reports/reception" component={ReportReception}/>
                        <Route exact path="/reports/serviceapp" component={ReportServiceApp}/>
                        <Route exact path="/reports/reportserviceappmonthly" component={reportServiceappmonthly}/>

                        <Route exact path="/reports/reportcrmt1" component={reportCrmT1}/>
                        <Route exact path="/reports/reportcrmt2" component={ReportCrmT2}/>
                        <Route exact path="/reports/reportcrmt3" component={reportCrmT3}/>
                        <Route exact path="/reports/reportcrmt3s7" component={reportCrmT3S7}/>
                        <Route exact path="/reports/ReportCrmT1Forward" component={reportCrmT1Forward}/>

                        <Route exact path="/usedcars/list" component={UsedCarList} />
                        <Route exact path="/usedcars/detail/:id" component={UsedCarDetail} />
                        <Route exact path="/usedcars/edit/:id" component={UsedCarEdit} />

                        <Route exact path="/poll/add" component={PollAdd} />
                        <Route exact path="/poll/edit/:id" component={PollEdit} />
                        <Route exact path="/poll" component={PollList} />

                        <Route exact path="/pollquestions/add/:id" component={PollQuestionsAdd} />

                        <Route exact path="/task/add" component={TaskAdd}/>
                        {(this.props.id_com_user_type === '2' || this.props.id_com_user_type === '4' || this.props.id_com_user_type === '1' )?
                            <>
                                <Route exact path="/task/edit/:id_com_task" component={TaskEdit}/>
                                <Route exact path="/task/list" component={TaskList}/>
                                <Route exact path="/task/manager" component={TaskManager}/>
                                <Route exact path='/reports/phonelist' component={reportPhonelist} />
                            </>
                            : <></>
                        }
                        <Route exact path="/task/list_personel" component={TaskListPersonel}/>

                        <Route exact path="/cars/detail/:id" component={CarsDetail}/>
                        <Route exact path="/cars/edit/:id" component={CarsEdit}/>
                        <Route exact path="/cars/add/:id_com_customer" component={CarsAdd}/>

                        <Route exact path="/serviceapp/add/:id_com_cars" component={ServiceAppAdd}/>
                        {/* <Route exact path="/serviceapp/edit/:id" component={ServiceAppEdit}/> */}
                        <Route exact path="/serviceapp/list" component={ServiceAppList}/>
                        <Route exact path="/serviceapp/details/:id" component={ServiceAppDetails}/>
                        <Route exact path="/feedback/add" component={FeedBackAdd}/>
                        <Route exact path="/feedback/details/:id_com_feedback" component={FeedBackDetails}/>
                        <Route exact path="/feedback/edit/:id_com_feedback" component={FeedBackEdit}/>
                        <Route exact path="/feedback/list" component={FeedBackList}/>
                        <Route exact path="/stock/list" component={StockList}/>
                        {
                           (this.props.id_com_user_type === '2' || this.props.id_com_user_type === '4' || this.props.id_com_user_type === '1' || this.props.id_com_user_type === '12'|| this.props.id_com_user_type === '10') ?
                                <>
                                    <Route exact path="/stock/add" component={StockAdd}/>
                                    <Route exact path="/stock/edit/:id_com_stock" component={StockEdit}/>
                                    <Route exact path="/transfer/crmall" component={TransferCrmAll}/>
                                
                                </>
                            :
                                <></>
                        }
                        <Route exact path="/webform/list/:id" component={webformList}/>

                        
                        {
                            (this.props.id_com_user_type === '2' || this.props.id_com_user_type === '4' || this.props.id_com_user_type === '1' )?
                            <>
                                <Route exact path="/credit/list" component={CreditList}/>
                                <Route exact path="/credit/add" component={CreditAdd}/>
                                <Route exact path="/creditsdetail/:id" component={CreditDetail}/>
                                <Route exact path="/financepayment/list" component={FinancePaymentList}/>
                                <Route exact path="/financepayment/add" component={FinancePaymentAdd}/>
                                <Route exact path="/bank/bankcacclist" component={BankAccountList}/>
                                <Route exact path="/debits/list" component={DebitsList}/>
                                <Route exact path="/financialcost/list" component={FinancialCost}/>
                                <Route exact path="/costgroup/list" component={CostGroupList}/>
                                <Route exact path="/check/list" component={CheckList}/>



                                
                                 
                            </>
                        :
                                <></>
                        }

                        <Route exact path="/insurance/polupload" component={PolUpload}/>




                        {/*
                        <Route exact path="/signup" component={SignUp}/>
                        <Route exact path="/AppList" component={AppList}/>
                        <Route exact path="/AppAdd" component={AppAdd}/>
                        <Route exact path="/AppCard/:AppId" component={AppCard}/>
                        <Route exact path="/AppDetails/:AppId" component={AppDetails}/>
                        <Route exact path="/AppConfirm" component={AppConfirm}/>
                        <Route exact path="/AccList" component={AccList}/> */}
                        <Route exact path="/ProductGroupList" component={ProductGroupList}/>
                        <Route exact path="/ProductList" component={ProductList}/>
                        <Route exact path="/ProductAdd" component={ProductAdd}/>
                        <Route exact path="/ProductSearch" component={ProductSearch}/>
                        <Route exact path="/InvAdd" component={InvAdd}/>
                        <Route exact path="/InvList" component={InvList}/>
                        <Route exact path="/InvCard/:InvId" component={InvCard}/>
                        <Route exact path="/DepotGroupList" component={DepotGroupList}/>
                        <Route exact path="/DepotStore" component={DepotStore}/>
                        <Route exact path="/StoreCard/:StoreId/:DepotId" component={StoreCard}/>
                        <Route exact path="/DepotSale" component={DepotSale}/>
                        <Route exact path="/DepotCard/:SaleId/:DepotId" component={DepotCard}/>
                        <Route exact path="/SetList" component={SetList}/>
                        <Route exact path="/SetCard/:SetId" component={SetCard}/>
                        <Route exact path="/ProductionList" component={ProductionList}/>
                        <Route exact path="/ProductionAdd" component={ProductionAdd}/>
                        <Route exact path="/ProductionCard/:ProductionId" component={ProductionCard}/>
                        <Footer/>
                    </React.Fragment>
                }
            </Router>
        );
    }
}

// const mapDispatchToProps = dispatch => {
//     return {
//         _Add_Login_Token: (token) => dispatch(_Add_Login_Token(token)),
//         _Log_Status_Change: () => dispatch(_Log_Status_Change()),
//         _Add_Id_Com_User: (id_com_user) => dispatch(_Add_Id_Com_User(id_com_user)),
//         _Add_Id_Com_Company: (id_com_company) => dispatch(_Add_Id_Com_Company(id_com_company)),
//         _Log_Out: () => dispatch(_Log_Out()),
//         _Add_Email: (email) => dispatch(_Add_Email(email)),
//         _Add_Password: (password) => dispatch(_Add_Password(password)),
//     }
// }

// const mapStateToProps = (state) => {
//     return {
//         logStatus: state.logStatus,
//         api_url: state.constants.api_url,
//         loginToken: state.constants.loginToken,
//         email: state.constants.email,
//         password: state.constants.password,
//         id_com_user_type: state.constants.id_com_user_type
//     }
// }
export default connect(mapStateToProps, mapDispatchToProps)(App);