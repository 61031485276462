import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';




class CarsAdd extends React.Component {
    constructor(props){
        super(props);
        this.state={
            id_com_customer:'',
            license_plate:'',
            customer_data:[],
            customer_data_loaded:false,
            disabled:true,

            }
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.Verification=this.Verification.bind(this);

    }
    componentDidMount () {
        let id_com_customer = this.props.match.params.id_com_customer
        this.setState({id_com_customer:id_com_customer})
        }



    handleChange(event,selectlist,name) {
        if(selectlist) { 
            this.setState({ [`${name}`]:event.id})
        } else { 
            const value = event.target.value;
            const key = event.target.name;
            this.setState({ [`${key}`]:value})
        }
    }



    handleSubmit(event) {
        let d = new Date(); 
        let date = d.toISOString();
        let c_date  = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
    
        let insert_body = 
        'token='+this.props.loginToken+
        '&c_date='+c_date+
        '&c_time='+c_time+
        '&id_com_user='+this.props.id_com_user+
        '&type=user';
        for (let [key] of Object.entries(this.state)) {
            if(key!=='disabled'&&key!=='customer_data_loaded'&&key!=='customer_data'&&key!=='id_com_cars'   ){ 
                insert_body +=`&${key}=`+ this.state[`${key}`]
            }
    }
    let com_cars_url=this.props.api_url+'/insert/com_cars';
    fetch(com_cars_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body:insert_body,
        })
        .catch(error=>console.log(error)).then(x=> x.json()).then(res=>  window.location.href='/cars/detail/'+res.id)
    }
        


    // AddSwapCar(){
    //     let d = new Date();  
    //     let date = d.toISOString();
    //     let c_date  = date.split('T')[0];
    //     let c_time = d.toLocaleTimeString('tr-TR');
    //     let swapcar = this.state.SwapCar;
    //     let id_com_brand = swapcar.id_com_brand;
    //     let id_com_model = swapcar.id_com_model;
    //     let id_com_gear = swapcar.id_com_gear;
    //     let id_com_fuel = swapcar.id_com_fuel;
    //     let id_com_color = swapcar.id_com_color;
    //     let km = swapcar.km;
    //     let model_year = swapcar.model_year;
    //     let spec = swapcar.spec;
    //     let  str = swapcar.license_plate.toString();
    //     let event = str.toUpperCase();
    //     let license_plate = event.replace(' ','');
    //     let chasis = swapcar.chasis;
    //      let comment = swapcar.comment;
    //     let id_com_customer = this.state.customer_data[0].id;

    //     let insert_car = 
    //     'token='+this.props.loginToken+
    //     '&id_com_brand='+id_com_brand+
    //     '&id_com_customer='+id_com_customer+
    //     '&id_com_model='+id_com_model+
    //     '&id_com_gear='+id_com_gear+
    //     '&id_com_fuel='+id_com_fuel+
    //     '&id_com_color='+id_com_color+
    //     '&model_year='+model_year+
    //     '&spec='+spec+
    //     '&license_plate='+license_plate+
    //     '&chasis='+chasis+
    //     '&c_date='+c_date+
    //     '&c_time='+c_time+
    //     '&id_com_user='+this.props.id_com_user+
    //     '&type=user';

    //     let car_url = this.props.api_url+'/insert/com_cars';
    //     fetch(car_url, {
    //         method: 'POST',
    //         cache: 'no-cache',
    //         headers:  {
    //             'Content-Type':  'application/x-www-form-urlencoded'
    //         },
    //         body: insert_car,
    //     })
    //         .catch(error => console.log(error))
    //         .then(response => response.json() )
    //         .then(result=>
    //             {  return result })

    // }

          

    // }
 
    async Verification (e) {
        const name = e.target.name;
        const value = e.target.value;
       var element =  document.getElementsByName(name)[0];

        switch(name) {
            case 'license_plate':
                let license_plate = value.trim()
                let url_cars =this.props.api_url+'/selectRows/com_cars';
                await fetch(url_cars,{
                    method:'POST',
                    cahce: 'no-cache',
                    headers:{
                        'Content-Type':'application/x-www-form-urlencoded'
                    },
                    body: 'token='+this.props.loginToken+'&type=user&license_plate='+license_plate,
                }).then(res=>res.json())
                    .then(result=>{
                        if(result.length>0){
                            this.setState({ [`${name}`]:'',disabled:true,id_com_cars:result[0].id});         
                            element.setAttribute("style", "border-color: red;");
                        }
                            else {                       
                            this.setState({ [`${name}`]:license_plate,id_com_cars:'',disabled:false});
                            element.setAttribute("style", "border-color: green;border-width: 2;")
                            }
                    })
                break;
             
                        default:
                            return null
        }
    }


     render( ){
        return(
<div className="main">
    <div className="main-content">
        <div className="container-fluid">
            <div className="row">
 
                <div className="col-md-12">
                    <div className="panel"  >
                    <div className="col-md-6">
                            <div className="panel-heading">
                                    <h3 className="panel-title">Araç Ekle</h3>
                            </div>
                        </div>

                        <div className="col-md-6">
                        </div>
                        <div className="col-12">
                                    <div className="panel-body">
                                    <div className="form-group">
                                        <label>Plaka</label>
                                        <input type="text" className="form-control" name="license_plate" onChange={(x) => this.Verification(x)} placeholder="Araç plakası" required/>
                                       {/* {this.state.license_plate===''this.state.disabled?<a style={{color:'red'}} type='button' href={'/cars/detail/'+this.state.id_com_cars}>Plaka Daha Önce Kayıt Edilmiştir. Tıklayınız... </a>:<></>} */}
                                    </div>
                                    <div className="form-group">
                                        <label>Şase</label>
                                        <input type="text" className="form-control" name="chasis" onChange={(x) => this.handleChange(x)} placeholder="Şase Bilgisi"  />
                                    </div>
                                    <div className="form-group">
                                        <label>Model Yılı</label>
                                        <input type="text" className="form-control" name="model_year" onChange={(x) => this.handleChange(x)} placeholder="Model Yılı Giriniz"  />
                                    </div>
                                    <SelectList table='com_brand' label={'Marka'} name={'id_com_brand'}  onChange={(x)=>this.handleChange(x)} />
                                    <SelectList table='com_model' label={'Model'} name={'id_com_model'}  id_com_brand={this.state.id_com_brand}  onChange={(x)=>this.handleChange(x)} />
                                    <SelectList table='com_gear' label={'Vites'}  name={'id_com_gear'} onChange={(x)=>this.handleChange(x)} />
                                    <SelectList table='com_fuel' label={'Yakıt'}  name={'id_com_fuel'} onChange={(x)=>this.handleChange(x)} />
                                    <SelectList table='com_color' label={'Renk'}  name={'id_com_color'} onChange={(x)=>this.handleChange(x)} />
                                     <div className="form-group">
                                        <label>Spek</label>
                                        <input type="text" className="form-control" name="spec" onChange={(x) => this.handleChange(x)} placeholder="Araç spec giriniz Örnk: Elite Smart 1.4"  />
                                    </div>
                                    <div className="form-group">
                                        <label>Belge No</label>
                                        <input type="text" className="form-control" name="doc_number" onChange={(x) => this.handleChange(x)} placeholder="Ruhsat Belge No"  />
                                    </div>

                                </div>

                                <div className="panel-footer">
                                <a className="btn btn-danger" href="/Customer/List">İptal</a>

                                    <button className="btn btn-primary pull-right"
                                    disabled={this.state.disabled}
                                    onClick={()=>this.handleSubmit()} 
                                    >Kaydet</button>
                                   
                                   </div>
                                   </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
     }}

     export default connect(mapStateToProps)(CarsAdd);