import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../../redux/mapStateToProps';
import DataTable from 'react-data-table-component';

class InterviewListAll extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: false,
            data: [],
            filtered:[],
            searchItem:'',
            columns:[
                {
                    name: 'Görüşme No',
                    selector: 'interview_number',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Görüşme Tarihi',
                    selector: 'c_date',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Görüşme Tipi',
                    selector: 'id_com_interview_type_name',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Konu',
                    selector: 'id_com_interview_subject_name',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Marka',
                    selector: 'id_com_brand_name',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Model',
                    selector: 'id_com_model_name',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Durum',
                    selector: 'status_name',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Müşteri',
                    selector: 'username',
                    left:true,
                    wrap:true,
                    width:'200px',
                    
                    sortable:true
                },
                {
                    name: 'Görüşme Durumu',
                    selector: 'id_com_interview_status_name',
                    left:true,
                    sortable:true,
                    wrap:true
                },
                {
                    name: 'Görüşme Kontrol',
                    right:true,
                    cell: (row)=> row.lock == 1?
                    '-'
                :
                     <a href={'/interview/Detail/' + row.id} className="btn btn-primary btn-sm">Seç</a>
                },
            ]
        }
        this.Search=this.Search.bind(this);
    }

componentDidMount(){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("token", this.props.loginToken);
   urlencoded.append("id_com_user", this.props.id_com_user);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    fetch(this.props.api_url + "/selectRows/view_interview", requestOptions)
        .then(response => response.json())
        .then(result =>{
            this.setState({data:result.reverse(),pageloader:true})
        })
        .catch(error => console.log('error', error));
}

async Search(x) {
    let match_str = new RegExp('('+x+')','i');
    this.setState({SearchItem:x})

    if(x===''){
        this.setState({searchItem:'',data:this.state.data})
    } else { 
        let filtered_data = this.state.data.filter(row=>{
            if(x===''){ 
                return row
            }       {
              return row.username.match(match_str) ||row.phone1.match(match_str)||row.interview_number.match(match_str)||row.id_com_user_name.match(match_str)//||row.id_com_brand_name.match(match_str)
              ||row.id_com_interview_subject_name.match(match_str) ||row.id_com_interview_type_name.match(match_str)||row.id_com_interview_status_name.match(match_str) 

            }
        })
        this.setState({filtered:filtered_data})
    }

}
    render(){
        return ( 
            <div className="main">
            <div className="main-content">
                <div className="container-fluid">
                    <div className="row">
 
                        <div className="col-md-12">
                            <div className="panel">
                                <div className="panel-heading">
                                    <div className="panel-title">Tüm Görüşmelerim</div>
                                    {/* <a href="/interview/add" className="btn btn-info pull-right">Ekle</a> */}
                                </div>
                                <div className="panel-body">
                                         <div className="form-group" style={{marginTop:10}}>
                                            <input value={this.state.searchItem} className="form-control" type="text" placeholder="Telefon, Adı Soyadı,Görüşme No,Marka, Durum" onChange={(x)=> {this.setState({searchItem:x.target.value});this.Search(x.target.value)}} />
                                        </div>
                                        <DataTable
                                columns={this.state.columns}
                                data={this.state.searchItem===''?this.state.data:this.state.filtered}
                                noHeader={true}
                                highlightOnHover
                                responsive
                                striped
                                dense
                                pagination={true}
                                paginationPerPage={20}
                                defaultSortField={'c_date'}
                                defaultSortAsc={false}
                                />
                                        </div>
                                         <div className="panel-footer text-center">
                                            Toplam {this.state.data.length} adet kayıt bulundu.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


        )
    }
}

export default connect(mapStateToProps)(InterviewListAll);