import React,{useState} from 'react';
import {Col, Row, PageHeader, Typography,Button ,Form,Input,DatePicker, Divider} from 'antd';
import {connect} from 'react-redux';
import mapStateToProps from '../../../redux/mapStateToProps';
import SelectList from '../../../components/utilities/SelectList';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import moment from 'moment'; 

const {Text} = Typography; 

const  CreditAdd = ({api_url,loginToken,id_com_company,id_com_user}) => {

    const [formData] = Form.useForm();
    const [Submitted,setSubmitted] = useState(false);

    const  handleSumbit = async ()=>{
        setSubmitted(true);
        const form_data = formData.getFieldsValue();

        let d = new Date(); 
        let c_date  = moment(d).format('YYYY-MM-DD');
        let c_time = d.toLocaleTimeString('tr-TR');
    
    let  insert_body =
        'token='+ loginToken+
        '&id_com_company='+  id_com_company+
        '&id_com_user='+  id_com_user+
        '&c_date='+c_date +
        '&c_time='+ c_time+
        '&type=user&status=0';

        for (let [key,value] of Object.entries(form_data)) {
            if  (key==='end_date'||key==='start_date') {
            insert_body +=`&${key}=${moment(value).format('YYYY-MM-DD')}`
           } else { 
            insert_body +=`&${key}=${value}`
           }
        }
          let com_credits_url= api_url+'/insert/com_credits';
        
        try{
       
        const insert = await ( await fetch(com_credits_url,{
              method:'POST',
              cahce: 'no-cache',
              headers:{ 
                'Content-Type':'application/x-www-form-urlencoded'
              },
            body:insert_body,
          })).json()

 
          const line_subject = {
              period : form_data.period,
              id_com_credits : insert.id,
              start_date : moment(form_data.start_date).format('YYYY-MM-DD'),
              id_com_bank : form_data.id_com_bank,
              payment_amount:form_data.payment,
              id_com_user:id_com_user,
              id_com_currency : form_data.id_com_currency

          }
        
            // 
       
            _Line_Insert(line_subject);


      }
      catch (error) { console.log(error)}
    
    }


    const _Line_Insert =async (data) =>{

        const { 
            period  ,
            id_com_credits  ,
            start_date  ,
            id_com_bank  ,
            payment_amount ,
            id_com_user ,
            id_com_currency 
        } = data;

        const d = new Date(); 
        const c_date  = moment(d).format('YYYY-MM-DD');
        const c_time = d.toLocaleTimeString('tr-TR');
    

        for (let i = 1;i<=period;i++){
            let com_credits_line_url= api_url+'/insert/com_credits_line';

            let  insert_body =
                'token='+ loginToken+
                '&id_com_credits='+ id_com_credits+
                '&line_no='+  i+
                '&id_com_bank='+  id_com_bank+
                '&payment_date='+  start_date+
                '&c_date='+c_date +
                '&c_time='+ c_time+
                '&payment_amount='+  payment_amount+
                '&id_com_currency='+  id_com_currency+
                '&id_com_user='+  id_com_user+
                '&type=user&status=0';

 
            const insert = await ( await fetch(com_credits_line_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                    'Content-Type':'application/x-www-form-urlencoded'
                },
                body:insert_body,
            })).json()
            if(parseInt(period)===parseInt(i)){ window.location.href='/creditsdetail/'+id_com_credits}
             
        }



    }

 
    const rowStyle = {
        width: '100%', 
      };
    const colStyle = {
        marginBottom: '8px',
        padding:50
      };
    const gutter = 16;

        return (
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <Row style={rowStyle} gutter={gutter} justify='center' align='middle' >
                                        <Col md={24} sm={24} xs={24}  >
                                            <PageHeader title={'Krediler |'} subTitle='Ekle'/>  
                                        </Col>
                                        <Divider></Divider>
                                        <Col md={24} sm={24} xs={24} >
                                            <Form form={formData} >
                                                <Row style={rowStyle} gutter={gutter} justify="start">
                                                    <Col md={12} sm={12} xs={24} style={colStyle}>
                                                        <Form.Item  name={'id_com_bank'}   labelAlign='left' labelCol={{span:7 }}    hasFeedback label={'Banka Seçiniz'}       >
                                                            <SelectList  table={'com_bank'}    />
                                                        </Form.Item>
                                                        <Form.Item  name={'id_com_credit_type'}   labelAlign='left' labelCol={{span:7 }}    hasFeedback label={'Kredi Tipi Seçiniz'}       >
                                                            <SelectList   table={'com_credit_type'}  />
                                                        </Form.Item>
                                                        <Form.Item  name={'id_com_credit_payment_type'}   labelAlign='left' labelCol={{span:7 }}    hasFeedback label={'Ödeme Tipi Seçiniz'}       >
                                                            <SelectList  table={'com_credit_payment_type'}    />
                                                        </Form.Item>
                                                        <Form.Item  name={'id_com_currency'}   labelAlign='left' labelCol={{span:7 }}    hasFeedback label={'Döviz Tipi Seçiniz'}       >
                                                            <SelectList  table={'com_currency'}    />
                                                        </Form.Item>
                                                        <Form.Item  name={'credit_number'}   labelAlign='left' labelCol={{span:7 }}    hasFeedback label='Kredi Sözleşme No'      >
                                                            <Input  placeholder={'Kredi Sözleşme No Giriniz'}  />
                                                        </Form.Item>
                                                        <Form.Item    name={'amount'}  labelAlign='left' labelCol={{span:7 }}    hasFeedback label='Kredi Tutarı'      >
                                                            <Input    type='number'  placeholder={'Kredi Tutarı Giriniz'}  />
                                                        </Form.Item>
                                                        <Form.Item  colon labelAlign='left' name={'interest'} labelCol={{span:7 }}    hasFeedback label='Faiz Oranı'      >
                                                            <Input      placeholder={'Faiz Oranı Giriniz'}/>
                                                        </Form.Item>
                                                        {/* <Form.Item  colon labelAlign='left' name={'bsmv'} labelCol={{span:7 }}    hasFeedback label='BSMV'      >
                                                            <Input       placeholder={'BSMV Giriniz'}/>
                                                        </Form.Item>
                                                        <Form.Item  colon labelAlign='left'  name={'kkdf'}  labelCol={{span:7 }}    hasFeedback label='KKDF'      >
                                                            <Input   placeholder={'KKDF Giriniz'}/>
                                                        </Form.Item> */}
                                                        <Form.Item  colon labelAlign='left'  name={'commision'} labelCol={{span:7 }}    hasFeedback label='Banka Komisyonu'      >
                                                            <Input     placeholder={'TBanka Komisyon Tutarı Giriniz'}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={12} sm={12} xs={24} style={colStyle}>
                                                        <Form.Item  name={'period'} labelAlign='left' labelCol={{span:7 }}    hasFeedback label='Taksit Sayısı'      >
                                                            <Input   type='number'  placeholder={'Taksit Sayısı Giriniz'}/>
                                                        </Form.Item>
                                                        <Form.Item  name={'payment'} labelAlign='left' labelCol={{span:7 }}    hasFeedback label='Taksit Tutarı'      >
                                                            <Input    type='number'     placeholder={'Taksit Tutarı Giriniz'}/>
                                                        </Form.Item>
                                                        {/* <Form.Item  name={'total_return'} labelAlign='left' labelCol={{span:7 }}   hasFeedback label='Top. Geri Ödeme'      >
                                                            <Input     placeholder={'Toplam Geri Ödeme Tutarı Giriniz'}/>
                                                        </Form.Item> */}
                                                        <Form.Item  name={'start_date'} labelAlign='left' labelCol={{span:7 }}   hasFeedback label='Başlangıç Tarihi'      >
                                                            <DatePicker  style={{width:'100%'}} format='DD.MM.YYYY'  locale={locale}  placeholder='Tarih Seçiniz'/>
                                                        </Form.Item>
                                                        <Form.Item  name={'end_date'} labelAlign='left' labelCol={{span:7 }}   hasFeedback label='Bitiş Tarihi'      >
                                                            <DatePicker   style={{width:'100%'}} format='DD.MM.YYYY'  locale={locale}  placeholder='Tarih Seçiniz'/>
                                                        </Form.Item>
                                                        <Form.Item   name={'comment'}  labelAlign='left' labelCol={{span:7 }}   hasFeedback label='Açıklama'      >
                                                            <Input.TextArea rows={6}        placeholder={'Var ise notunuzu ekleyiniz.'}   />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                        <Divider></Divider>
                                        <Row style={rowStyle} gutter={4} justify="end">
                                            <Col style={{marginBottom:10}} >
                                                    <Button type="primary"   shape='round'  danger onClick={()=>window.location.href='/credit/list'} > İptal   </Button>
                                            </Col>
                                            <Col style={{marginBottom:10}}>
                                                    <Button type="primary"   shape='round'  onClick={()=>handleSumbit()} loading={Submitted} > Kaydet   </Button>
                                            </Col>
                                        </Row> 
                                    </Row> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  )
}


export default connect(mapStateToProps)(CreditAdd);
