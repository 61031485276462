import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
class ProductAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_com_product_type: '',
            code: '',
            code_status: false,
            id_com_product_class: '',
            text: '',
            id_com_tax_rate: '',
            id_com_unit: '',
            quantity: '1',
            id_com_product_sub_class1: '',
            id_com_product_sub_class2: '',
            id_com_product_brand: '',
            oem_code: '',
            brut_weight: '',
            net_weight: '',
            volume: '',
            expiration_day: '',
            comment: '',
            acc_nr: '',
            product_acc_type: '0',
            acc_nr_cost: '',
            acc_nr_disc: '',
            mann_code:'',
            id_com_brand:[],
        };
        this.Submit = this.Submit.bind(this)
        this.Verification = this.Verification.bind(this)
        this._arraytoString = this._arraytoString.bind(this)

    }

    _arraytoString(value){
        const brands= [];
        value.map(row => brands.push(row.id));
        const id_com_groups = [...new Set(brands)];
            return(id_com_groups)
           // console.log(id_com_groups)
}

    async Verification(type, value) {
        switch (type) {
            case 'code':
                let code = value.target.value.trim()
                this.setState({code: code.toUpperCase()})
                let url_code = this.props.api_url + '/selectRows/com_product';
                // console.log(value.target.value+' '+url_taxno)
                await fetch(url_code, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'token=' + this.props.loginToken + '&type=user&code=' + code.toUpperCase() + '&id_com_company=' + this.props.id_com_company
                }).then(res => res.json())
                    .then(result => {
                        result.length === 0 ? this.setState({code_status: true}) : this.setState({code_status: false})
                    })
                break;
            default:
        }
    }
    async Submit() {
        let d = new Date();
        let date = d.toISOString();
        let c_date = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let c_id_com_user = this.props.id_com_user;
        let id_com_company = this.props.id_com_company;
        let id_com_product_type = this.state.id_com_product_type;
        let id_com_product_class = this.state.id_com_product_class;
        let id_com_product_sub_class1 = this.state.id_com_product_sub_class1;
        let id_com_product_sub_class2 = this.state.id_com_product_sub_class2;
        let id_com_product_brand = this.state.id_com_product_brand;
        let code = this.state.code;
        let oem_code = this.state.oem_code;
        let text = this.state.text;
        let id_com_tax_rate = this.state.id_com_tax_rate;
        let id_com_unit = this.state.id_com_unit;
        let quantity = this.state.quantity;
        let brut_weight = this.state.brut_weight;
        let net_weight = this.state.net_weight;
        let volume = this.state.volume;
        let expiration_day = this.state.expiration_day;
        let comment = this.state.comment;
        let acc_nr_purchase = this.state.acc_nr_purchase;
        let acc_nr_sale = this.state.acc_nr_sale;
        let acc_nr_cost = this.state.acc_nr_cost;
        let acc_nr_disc = this.state.acc_nr_disc;
        let acc_nr_expense = this.state.acc_nr_expense;
        let acc_nr_income = this.state.acc_nr_income;
        let insert =
            'c_date=' + c_date +
            '&c_time=' + c_time +
            '&c_id_com_user=' + c_id_com_user +
            '&id_com_product_type=' + id_com_product_type +
            '&id_com_product_class=' + id_com_product_class +
            '&id_com_product_sub_class1=' + id_com_product_sub_class1 +
            '&id_com_product_sub_class2=' + id_com_product_sub_class2 +
            '&id_com_product_brand=' + id_com_product_brand +
            '&code=' + code +
            '&oem_code=' + oem_code +
            '&text=' + text +
            '&acc_nr_purchase=' + acc_nr_purchase +
            '&acc_nr_sale=' + acc_nr_sale +
            '&acc_nr_cost=' + acc_nr_cost +
            '&acc_nr_disc=' + acc_nr_disc +
            '&acc_nr_expense=' + acc_nr_expense +
            '&acc_nr_income=' + acc_nr_income +
            '&id_com_tax_rate=' + id_com_tax_rate +
            '&id_com_unit=' + id_com_unit +
            '&quantity=' + quantity +
            '&brut_weight=' + brut_weight +
            '&net_weight=' + net_weight +
            '&volume=' + volume +
            '&expiration_day=' + expiration_day +
            '&comment=' + comment +
            '&status=1' +
            '&token=' + this.props.loginToken + '&type=user' +
            '&id_com_company=' + id_com_company;
        let insert_url = this.props.api_url + '/insert/com_product'
        fetch(insert_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: insert,
        })
            .catch(error => console.log(error))
            .then(res => {
                return res.json()
            })
            .then(response => {
                console.log('Product Added!');
                let insert_price_url = this.props.api_url + '/insert/com_price_list'
                let insert_price =
                    'c_date=' + c_date +
                    '&c_time=' + c_time +
                    '&c_id_com_user=' + c_id_com_user +
                    '&id_com_product=' + response.id +
                    '&price=' + this.state.price +
                    '&start_date=' + c_date +
                    '&token=' + this.props.loginToken + '&type=user';
                fetch(insert_price_url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: insert_price,
                })
                    .catch(error => console.log(error))
                window.location.href = '/ProductList'
            })
    }
    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="page-title">Malzemeler</h3>
                                </div>
                                <div className="col-md-12">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <div className="panel-title">Malzeme Ekle</div>
                                        </div>
                                        <div className="panel-body">
                                            <SelectList table="com_product_type" Label="Malzeme Tipi" onChange={(x) => this.setState({id_com_product_type: x.id})} required={true}/>
                                            <SelectList table="com_product_class" id_com_product_type={this.state.id_com_product_type} Label="Malzeme Sınıfı" onChange={(x) => this.setState({id_com_product_class: x.id})} required={true}/>
                                            <SelectList table="com_product_sub_class1" id_com_product_type={this.state.id_com_product_type} id_com_product_class={this.state.id_com_product_class} Label="Malzeme Alt Grup1 Seçiniz" onChange={(x) => this.setState({id_com_product_sub_class1: x.id})}/>
                                            <SelectList table="com_product_sub_class2" id_com_product_type={this.state.id_com_product_type} id_com_product_class={this.state.id_com_product_class} id_com_product_sub_class1={this.state.id_com_product_sub_class1} Label="Malzeme Alt Grup2 Seçiniz" onChange={(x) => this.setState({id_com_product_sub_class2: x.id})}/>
                                            <SelectList table="com_product_brand" Label="Malzeme Markasını Seçiniz" onChange={(x) => this.setState({id_com_product_brand: x.id})}/>
                                            <SelectList table='com_brand' Label='Araç Markasını Seçiniz' onChange={(x)=>{this.setState({id_com_brand: this._arraytoString(x)});}} multiple={true}/>
                                            <div className="form-group">
                                                <label>Malzeme Kodu</label>
                                                <input value={this.state.code} className="form-control" onChange={(x) => this.Verification('code', x)} />
                                            </div>
                                            <div className="form-group">
                                                <label>Üretici (OEM) Kodu</label>
                                                <input className="form-control" onChange={(x) => this.setState({oem_code: x.target.value})} />
                                            </div>
                                            <div className="form-group">
                                                <label>Malzeme Adı</label>
                                                <input className="form-control" onChange={(x) => this.setState({text: x.target.value})} />
                                            </div>
                                            <SelectList table="com_tax_rate" Label="Vergi Oranını" onChange={(x) => this.setState({id_com_tax_rate: x.id})} required={true} />
                                            <SelectList table="com_unit" Label="Birim Tipi" onChange={(x) => this.setState({id_com_unit: x.id})} required={true} />
                                            <div className="form-group">
                                                <label>Paket Adedi</label>
                                                <input value={this.state.quantity} type={'number'} onChange={(x) => this.setState({quantity: x.target.value})} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label>Brüt Ağırlık</label>
                                                <input className="form-control" type={'number'} onChange={(x) => this.setState({brut_weight: x.target.value})}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Net Ağırlık</label>
                                                <input className="form-control" type={'number'} onChange={(x) => this.setState({net_weight: x.target.value})}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Hacim</label>
                                                <input className="form-control" type={'number'} onChange={(x) => this.setState({volume: x.target.value})}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Son Kullanma Tarihi (Gün Sayısı)</label>
                                                <input className="form-control" type={'number'} onChange={(x) => this.setState({expiration_day: x.target.value})}/>
                                            </div>
                                            <label className="fancy-radio">
                                                <input type="radio" name="radio2" value='0' defaultChecked onClick={(x) => {this.setState({product_acc_type: x.target.value})}}/>
                                                <span><i></i>Ticari Mal</span>
                                            </label>
                                            <label className="fancy-radio">
                                                <input type="radio" name="radio2" value='1' onClick={(x) => {this.setState({product_acc_type: x.target.value})}}/>
                                                <span><i></i>Demirbaş</span>
                                            </label>
                                            {this.state.id_com_product_type !== '3' ?
                                                <React.Fragment>
                                                    <SelectList table='com_account_plan_group_level4' accnr={this.state.product_acc_type === '0' ? '153' : '255'} Label='Muhasebe Deposu' onChange={(x) => this.setState({acc_nr_purchase: x.id})} required={this.state.id_com_product_type === '1' ? true : false}/>
                                                    <SelectList table='com_account_plan_group_level4' accnr={'600'} Label='Satış Kodu' onChange={(x) => this.setState({acc_nr_sale: x.id})} required={this.state.id_com_product_type === '1' ? true : false}/>
                                                    <SelectList table='com_account_plan_group_level4' accnr={'611'} Label='İskontu Kodu' onChange={(x) => this.setState({acc_nr_disc: x.id})} required={this.state.id_com_product_type === '1' ? true : false}/>
                                                    <SelectList table='com_account_plan_group_level4' accnr={'621'} Label='SMM Kodu' onChange={(x) => this.setState({acc_nr_cost: x.id})} required={this.state.id_com_product_type === '1' ? true : false}/>
                                                </React.Fragment>
                                            :
                                                <></>
                                            }
                                            {this.state.id_com_product_class === '16' ?
                                                <SelectList table='com_account_plan_group_level4' accnr={'10'} Label='Gelir Kodu' id_com_product_class={this.state.id_com_product_class} onChange={(x) => this.setState({acc_nr_income: x.id})} required={this.state.id_com_product_type === '1' ? true : this.state.id_com_product_type === '3' ? true : false}/>
                                            :
                                                <></>
                                            }
                                            {this.state.id_com_product_class === '17' ?
                                                <SelectList table='com_account_plan_group_level4' accnr={'7'} Label='Gider Kodu' id_com_product_class={this.state.id_com_product_class} onChange={(x) => this.setState({acc_nr_expense: x.id})} required={this.state.id_com_product_type === '1' ? true : this.state.id_com_product_type === '3' ? true : false}/>
                                            :
                                                <></>
                                            }
                                            <div className="form-group">
                                                <label>Satış Liste Fiyatı</label>
                                                <input className="form-control" type={'number'} onChange={(x) => this.setState({price: x.target.value})} required/>
                                            </div>
                                            <div className="form-group">
                                                <label>Açıklama</label>
                                                <textarea className="form-control" rows="5" onChange={(x) => this.setState({comment: x.target.value})}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <a className="btn btn-danger" href='/ProductList'>İptal</a> <button className="btn btn-primary" onClick={() => this.Submit()} disabled={this.state.code === '' || this.state.id_com_product_type === '' || this.state.id_com_product_class === '' || this.state.text === '' || this.state.id_com_tax_rate === '' || this.state.id_com_unit === '' || this.state.quantity === '' || this.state.price === '' || this.state.code_status === false ? true : false}>Kaydet</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(ProductAdd);
