
import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';

import * as CurrencyFormat from 'react-currency-format';
import {DatePicker} from 'antd'
import moment from 'moment';

import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';

class AccordionPayments extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            payment_types:[],
            banklist:[],
            invItems:[],
        }
        this._excludeItem=this._excludeItem.bind(this);
    }

    _excludeItem(index) { 
        let invItems = this.state.invItems;
        invItems.splice(index,1);
         this.setState({invItems:invItems});
      }

      componentDidMount(){

        let payment_url = this.props.api_url+'/selectRows/com_payment_type';

        fetch(payment_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
            body:   '&token='+this.props.loginToken+'&type=user',
        }).catch(e=>console.log(e)).then(res=>res.json()).then(result=> this.setState({payment_types:result}))

        let bank_url = this.props.api_url+'/selectRows/com_bank';

        fetch(bank_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
            body:   '&token='+this.props.loginToken+'&type=user',
        }).catch(e=>console.log(e)).then(res=>res.json()).then(result=> this.setState({banklist:result}))


      }

      _Submit(){
        let invItems = this.state.invItems;
        let payments_url = this.props.api_url+'/insert/com_orderform_payment_lines';
        let d = new Date();  
        let date = d.toISOString();
        let c_date  = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
    
        try{ 
        invItems.map((row)=>{
           let insert_line=  
                'comment='+row.comment+
                '&price='+row.price+
                '&id_com_payment_type='+row.id_com_payment_type+
                '&id_com_bank='+row.id_com_bank+
                '&est_payment_date='+row.est_payment_date+
                '&id_com_orderform='+this.props.id_com_orderform+
                '&id_com_user='+ this.props.id_com_user+
                '&token='+this.props.loginToken+
                '&type=user';

                console.log(insert_line)
                    fetch(payments_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
                body: insert_line,
            }).catch(e=>console.log(e))//.then(res=> window.location.reload())
        })
    } 
    catch(error){console.log(error)}
  
    }

   

    render() { 
        return ( 
    <React.Fragment>
        <a className={!this.state.clicked?"btn btn-primary btn-lg btn-block":"btn btn-success btn-lg btn-block"}
            data-toggle="collapse" 
            href={`#${this.props.id}`}
            role="button" 
            aria-expanded="false" 
            aria-controls={this.props.id} 
            // style={{width: '100%'}}
            onClick={()=>this.setState({clicked:!this.state.clicked})}>
 <i className={!this.state.clicked?"fas fa-plus-circle pull-left":"fas fa-minus-circle pull-left"}  > </i>   {this.props.title} :  <CurrencyFormat value={Math.round(parseFloat(this.props.summary)*100)/100} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} suffix={' TL'}/> </a>
    <div className="collapse" id={this.props.id}>
        <div className="panel">
        <div className="  panel-heading">
        <div className="row">            
        <div className="col-md-6 text-left">
                <div className="panel-title">{this.props.title}</div>
            </div>
            <div className="col-md-6 text-right">
                <a type="button" className="btn btn-secondary" onClick={()=> { 
                    let array = this.state.invItems;
                    array.push([]);this.setState({invItems:array})}}><i className="fas fa-plus-circle pull-left"  > </i>  Ekle</a>
            </div>
        </div>

        </div>
        <div className="  panel-body">
                <div className="row"> 
      {this.state.invItems.length>0?
       <table className="table table-bordered table-striped">
            <thead>
                <tr>
                    <th>Ödeme Tipi</th>
                    <th>Banka</th>
                    <th>İşlem Tarihi</th>
                    <th width='150'>Tutar</th>
                    <th width='250'>Açıklama</th>
                    <th>Kontrol</th>

                </tr>
            </thead>
            <tbody>
            {this.state.invItems.map((row,index)=>{   
            return  (
                    <tr key={index}>
                    <td>
                    <select className="form-control"   onChange={(x)=>this.state.invItems[index].id_com_payment_type=x.target.value} value={this.state.invItems[index].id_com_payment_type} data-live-search="true">
                            <option key={0} value={0}>{'Seçiniz'}</option>
                        {this.state.payment_types.map((row,index)=>
                            <option key={index+1} value={row.id}>{row.title}</option>
                        )}
                    </select>
                    </td>
                    <td>
                    <select className="form-control"   onChange={(x)=>this.state.invItems[index].id_com_bank=x.target.value} value={this.state.invItems[index].id_com_bank} data-live-search="true">
                            <option key={0} value={0}>{'Seçiniz'}</option>
                        {this.state.banklist.map((row,index)=>
                            <option key={index+1} value={row.id}>{row.title}</option>
                        )}
                    </select>
                    </td>
                    <td>
                     <DatePicker  onChange={(value)=>this.state.invItems[index].est_payment_date =moment(value).format('YYYY-MM-DD')} locale={locale} format='DD.MM.YYYY' />
                    </td>
                    <td>
                    <input type="text" className="form-control"  value={this.state.invItems[index].price} onChange={(x)=>this.state.invItems[index].price =x.target.value} required/>
                    </td>
                    <td><input type="number" step={1} min={0} className="form-control"  value={this.state.invItems[index].comment} onChange={(x)=>this.state.invItems[index].comment =x.target.value} required/></td>
                 
                    <td><a disabled={row.status==='1'?true:false} role='button' className='btn btn-danger btn-xs' onClick={()=>this._excludeItem(index)}>Cikar</a></td>
                </tr>
                )})}
            </tbody>
            </table>  
            :<></>}
                </div>
            
                <div className="  panel-footer">
        <button className="btn btn-primary pull-right" 
                                // disabled={this.state.id_com_sale_type===''||this.state.id_com_customer==='0'}
                                  onClick={()=>this._Submit()}
                                // onClick={()=>console.log(this.state)}
                                    >Kaydet</button>
        </div>
        </div>
        

        </div>
    </div>       
    </React.Fragment>
)
}
}

export default connect(mapStateToProps)(AccordionPayments);