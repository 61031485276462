import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
import DataTable from 'react-data-table-component';
const depot_columns = [
    {
        name: 'Kod',
        selector: 'code',
        left: true
    }, {
        name: 'Malzeme  Adı',
        selector: 'id_com_product_name',
        grow: 2,
        left: true
    }, {
        name: 'Talep Adet',
        selector: 'sale_quantity',
        right: true
    }, {
        name: 'Teslim Edilen',
        selector: 'dispatch',
        right: true
    }, {
        name: 'Bekleyen',
        selector: 'waiting',
        right: true,
        grow: 2,
        cell: row => (
            <div><span className={parseFloat(row.waiting) === 0 ? "uk-text-success" : "uk-text-danger"} type="button"
                       href={'/InvCard/' + row.id}>{parseFloat(row.waiting) === 0 ? 'Tamamlandı' : row.waiting}</span>
            </div>
        ),
    }]
class ProductionCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_com_production: '',
            body_data: [],
            line_data: [],
            stock_data: [],
            part_status: 0,
            ProdStarted: false,
            Completed: 0,
            ticket_id: null,
            total_cost: 0,
            add_cost: 0,
            salary: 0
        }
        this._Update = this._Update.bind(this)
        this._Prod_Start = this._Prod_Start.bind(this)
        this._Prod_Stop = this._Prod_Stop.bind(this)
    }
    _Prod_Start() {
        this.setState({ProdStarted: true})
        let d = new Date();
        let date = d.toISOString();
        let c_date = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let c_id_com_user = this.props.id_com_user;
        let start_ticket_body =
            'id_com_production=' + this.state.id_com_production +
            '&c_id_com_user=' + c_id_com_user +
            '&id_com_company=' + this.props.id_com_company +
            '&c_date=' + c_date +
            '&c_time=' + c_time +
            '&start_time=' + c_time +
            '&token=' + this.props.loginToken +
            '&type=user&status=0';
        fetch(this.props.api_url + '/insert/com_production_ticket', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: start_ticket_body,
        }).catch(function (e) {
            console.log('JobStart : ' + e)
        }).then(function () {
            console.log('job started!')
        })
        let update0 =
            'id=' + this.state.id_com_production +
            '&token=' + this.props.loginToken +
            '&type=user&id_com_production_status=3';
        fetch(this.props.api_url + '/update/com_production', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: update0,
        }).catch(e => console.log('update1 : ' + e)).then(function () {})
    }
    _Prod_Stop() {
        let d = new Date();
        let date = d.toISOString();
        let c_date = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let m_id_com_user = this.props.id_com_user;
        let url_ticket = this.props.api_url + '/selectRows/view_production_ticket_active';
        fetch(url_ticket, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_production=' + this.state.id_com_production + '&token=' + this.props.loginToken + '&type=user'
        }).then(res => res.json()).then(result => {
            // console.log(result[0].id)
            let update1 =
                'id=' + result[0].id +
                '&m_id_com_user=' + m_id_com_user +
                '&m_date=' + c_date +
                '&m_time=' + c_time +
                '&stop_time=' + c_time +
                '&token=' + this.props.loginToken +
                '&type=user&status=1';
            fetch(this.props.api_url + '/update/com_production_ticket', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: update1,
            }).catch(e => console.log('update1 : ' + e)).then(function (response) {});
            let update0 =
                'id=' + this.state.id_com_production +
                '&token=' + this.props.loginToken +
                '&type=user&id_com_production_status=4';
            fetch(this.props.api_url + '/update/com_production', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: update0,
            }).catch(e => console.log('update1 : ' + e)).then(function () {}).then(res => this.setState({ProdStarted: false})
            )
        })
    }
    _Prod_Finish() {
        let d = new Date();
        let date = d.toISOString();
        let c_date = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let m_id_com_user = this.props.id_com_user;
        let url_ticket = this.props.api_url + '/selectRows/view_production_ticket_active';
        fetch(url_ticket, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_production=' + this.state.id_com_production + '&token=' + this.props.loginToken + '&type=user'
        }).then(res => res.json()).then(result => {
            let update1 =
                'id=' + result[0].id +
                '&m_id_com_user=' + m_id_com_user +
                '&m_date=' + c_date +
                '&m_time=' + c_time +
                '&stop_time=' + c_time +
                '&token=' + this.props.loginToken +
                '&type=user&status=1';
            fetch(this.props.api_url + '/update/com_production_ticket', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: update1,
            }).catch(e => console.log('ticket update :' + e)).then(function (response) {}).then(result => {
                let url_ticket_summary = this.props.api_url + '/selectRows/view_production_ticket_summary';
                fetch(url_ticket_summary, {
                    method: 'POST',
                    cahce: 'no-cache',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'id_com_production=' + this.state.id_com_production + '&token=' + this.props.loginToken + '&type=user'
                }).then(res => res.json()).then(result => {
                    let salary_total = parseFloat(result[0].total_hour) * parseFloat(this.state.salary)
                    let add_cost_total = salary_total + parseFloat(this.state.add_cost) + parseFloat(this.state.total_cost)
                    let update_finish =
                        'id=' + this.state.id_com_production +
                        '&avg_cost=' + add_cost_total +
                        '&completed=' + this.state.Completed +
                        '&token=' + this.props.loginToken +
                        '&type=user&id_com_production_status=5';
                    fetch(this.props.api_url + '/update/com_production', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: update_finish,
                    })
                        .catch(e => console.log(e))
                    let stockline_url = this.props.api_url + '/insert/com_stock_lines'
                    let stock_body = 'c_date=' + c_date +
                        '&doc_date=' + c_date +
                        '&c_id_com_user=' + this.props.id_com_user +
                        '&id_com_depot=' + this.state.body_data.id_com_depot +
                        '&c_time=' + c_time +
                        '&id_com_product=' + this.state.body_data.id_com_set +
                        '&production_number=' + this.state.body_data.production_number +
                        '&id_com_company=' + this.props.id_com_company +
                        '&purchase_quantity=' + this.state.Completed +
                        '&purchase_price=' + Math.round((add_cost_total / parseFloat(this.state.Completed)) * 100) / 100 +
                        '&id_com_production=' + this.state.id_com_production +
                        '&token=' + this.props.loginToken +
                        '&type=user&sale_quantity=0&sale_price=0&stored=0&id_depot_part_number=0';
                    fetch(stockline_url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: stock_body,
                    })
                        .catch(error => console.log(error))
                })
            })
        })
    }
    componentDidMount() {
        this._Update();
    }
    _Update() {
        let id_com_production = this.props.match.params.ProductionId
        this.setState({id_com_production: id_com_production})
        let body_url = this.props.api_url + '/selectRows/view_production';
        fetch(body_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id=' + id_com_production + '&id_com_company=' + this.props.id_com_company + '&token=' + this.props.loginToken + '&type=user'
        }).then(res => res.json())
            .then(result => {

                this.setState({body_data: result[0]})
                this.state.body_data.c_date = this.state.body_data.c_date.split('-')[2] + '.' + this.state.body_data.c_date.split('-')[1] + '.' + this.state.body_data.c_date.split('-')[0];
                this.state.body_data.delivery_date = this.state.body_data.delivery_date.split('-')[2] + '.' + this.state.body_data.delivery_date.split('-')[1] + '.' + this.state.body_data.delivery_date.split('-')[0];
            })

        let line_url = this.props.api_url + '/selectRows/view_production_lines';
        fetch(line_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_production=' + id_com_production + '&id_com_company=' + this.props.id_com_company + '&token=' + this.props.loginToken + '&type=user'
        }).then(res => res.json())
            .then(result => {

                this.setState({line_data: result})
            })
        let part_status = 0;
        let total_cost = 0;
        let stock_url = this.props.api_url + '/selectRows/view_stock_lines';
        fetch(stock_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_production=' + id_com_production + '&id_com_company=' + this.props.id_com_company + '&token=' + this.props.loginToken + '&type=user'
        }).then(res => res.json())
            .then(result => {

                result.map(row => {
                    part_status += parseFloat(row.waiting);
                    total_cost += parseFloat(row.avg_cost) * parseFloat(row.sale_quantity)
                })
                this.setState({
                    stock_data: result,
                    part_status: parseFloat(part_status),
                    total_cost: (Math.round(parseFloat(total_cost) * 100) / 100)
                })
            })


        let url_ticket = this.props.api_url + '/selectRows/view_production_ticket_active';
        fetch(url_ticket, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_production=' + id_com_production + '&token=' + this.props.loginToken + '&type=user'

        }).then(res => res.json()).then(result => {
            // console.log(result);
            result.length === 0 ? this.setState({
                ticket_id: null,
                ProdStarted: false
            }) : this.setState({ticket_id: result[0].id, ProdStarted: true});

        })
    }
    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="page-title">Üretim Kartı</h3>
                                </div>
                                <div className="col-md-12">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <div className="panel-title">Üretim Kartı / {this.state.body_data.status_name}</div>
                                        </div>
                                        <div className="panel-body">
                                            <table className="table table-bordered table-striped">
                                                <tbody>
                                                    <tr>
                                                        <th width="200">Üretim No</th>
                                                        <td>{this.state.body_data.production_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Oluşturan / Tarih / Saat</th>
                                                        <td>{this.state.body_data.c_id_com_username + ' / ' + this.state.body_data.c_date + ' / ' + this.state.body_data.c_time}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Talep Bilgisi</th>
                                                        <td>
                                                            {'Malzeme Kodu: ' + this.state.body_data.code}
                                                            <br/>
                                                            {'Malzeme Adı: ' + this.state.body_data.id_com_set_name}
                                                            <br/>
                                                            {'Adet: ' + this.state.body_data.quantity}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Teslim Tarihi</th>
                                                        <td>{this.state.body_data.delivery_date}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Depo Parça Durumu</th>
                                                        <td>
                                                            <DataTable
                                                                columns={depot_columns}
                                                                data={this.state.stock_data}
                                                                fixedHeader
                                                                noHeader={true}
                                                                overflowY={false}
                                                                overflowYOffset={'75px'}
                                                                highlightOnHover
                                                                responsive
                                                                striped
                                                                // dense
                                                                paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                                                pagination={true}
                                                                paginationPerPage={20}
                                                                defaultSortField={'c_date'}
                                                                defaultSortAsc={false}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <button className="btn btn-danger" onClick={() => window.location.href = '/ProductionList'}>İptal</button> {this.state.ProdStarted ? <button className="btn btn-default" onClick={() => this._Prod_Stop()}>Üretime Durdur</button> :this.state.body_data.status === '5' ? <></> :  <button className="btn btn-primary" disabled={parseFloat(this.state.part_status) === 0 ? false : true} onClick={() => this._Prod_Start()}>Üretime Başlat</button>}
                                    {this.state.ProdStarted ?
                                        <React.Fragment>
                                            <div className="panel margin-top-30">
                                                <div className="panel-body">
                                                    <table className="table table-bordered table-striped">
                                                        <tbody>
                                                        <tr>
                                                            <th width="200">Teslim Tarihi</th>
                                                            <td>{this.state.body_data.delivery_date}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Üretim Miktarı</th>
                                                            <td><input type='number' className="form-control"
                                                                       value={this.state.Completed}
                                                                       onChange={(x) => this.setState({Completed: x.target.value})}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>İşçilik Saat Ücreti</th>
                                                            <td><input type="number" className="form-control"
                                                                       value={this.state.salary}
                                                                       onChange={(x) => this.setState({salary: x.target.value})}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Paketleme ve Diğer Giderler Toplam</th>
                                                            <td><input type="number" className="form-control"
                                                                       value={this.state.add_cost}
                                                                       onChange={(x) => this.setState({add_cost: x.target.value})}/>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <button className = "btn btn-danger" disabled={parseFloat(this.state.Completed) === 0 ? true : false} onClick={() => this._Prod_Finish()}>Üretimi Tamamla</button>
                                        </React.Fragment>
                                        :
                                            <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(ProductionCard);