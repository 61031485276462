import React from 'react';
import {connect} from 'react-redux';
import DataTable from 'react-data-table-component';
import mapStateToProps from '../../redux/mapStateToProps';
const columns = [
    {
        name: 'Sınıf',
        selector: 'id_com_product_class_name',
        left: true
    },
    {
        name: 'Alt Grup',
        selector: 'id_com_product_sub_class1_name',
        left: true
    },

    {
        name: 'Malzeme Kodu',
        selector: 'code',
        left: true
    },
    {
        name: 'Malzeme Adı',
        selector: 'text',
        left: true
    },
    {
        name: 'Kontrol',
        cell: row => (
            <a className="btn btn-primary btn-sm" type="button" href={'/SetCard/' + row.id}>Seç</a>
        ),
        right: true
    }]
class SetList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageloader: false,
            SearchItem: ''
        }
        this._Update = this._Update.bind(this)
    }
    componentDidMount() {
        this._Update()
    }
    _Update() {
        let product_url = this.props.api_url + '/selectRows/view_product';
        fetch(product_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_company=' + this.props.id_com_company + '&token=' + this.props.loginToken + '&type=user&id_com_product_type=4'
        }).catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                console.log(result)
                this.setState({data: result, pageloader: true})
            })
    }
    render() {
        return (<React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="page-title">Üretim</h3>
                                </div>
                                <div className="col-md-12">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <div className="panel-title">Set / Üretim Listesi</div>
                                        </div>
                                        <div className="panel-body">
                                            <DataTable
                                                columns={columns}
                                                data={this.state.SearchItem === '' ? this.state.data : this.state.filtered}
                                                fixedHeader
                                                noHeader={true}
                                                overflowY={false}
                                                overflowYOffset={'100px'}
                                                highlightOnHover
                                                responsive
                                                striped
                                                // dense
                                                paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                                progressPending={!this.state.pageloader}
                                                pagination={true}
                                                paginationPerPage={20}
                                                defaultSortField={'c_date'}
                                                defaultSortAsc={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(SetList);