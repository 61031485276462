import React from 'react';
import {connect} from 'react-redux';
import DataTable from 'react-data-table-component';
import mapStateToProps from '../../redux/mapStateToProps';
const columns = [
    {
        name: 'Üretim No:',
        selector: 'production_number',
        grow: 2,
        sortable: true,
        left: true
    },
    {
        name: 'Malzeme Kodu',
        selector: 'code',
        left: true
    },
    {
        name: 'Malzeme Adı',
        selector: 'id_com_set_name',
        left: true
    },
    {
        name: 'Adet',
        selector: 'quantity',
        right: true,
        compact: true
    }, {
        name: 'Teslim Tarihi',
        selector: 'delivery_date',
        right: true
    },
    , {
        name: 'Durum',
        selector: 'status_name',
        right: true
    },
    {
        name: 'Kontrol',
        cell: row => (
            <button className="btn btn-primary" disabled={row.status === '5' ? true : false} type="button" onClick={() => window.location.href = '/ProductionCard/' + row.id}>Seç </button>
        ),
        right: true
    }]
class SetList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageloader: false,
            SearchItem: ''
        }
        this._Update = this._Update.bind(this)
    }
    componentDidMount() {
        this._Update()
    }
    _Update() {
        let product_url = this.props.api_url + '/selectRows/view_production';
        fetch(product_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_company=' + this.props.id_com_company + '&token=' + this.props.loginToken + '&type=user'
        }).catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                console.log(result)

                this.setState({data: result, pageloader: true})
            })
    }
    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="page-title">Üretim</h3>
                                </div>
                                <div className="col-md-12">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <div className="panel-title">Üretim Listesi</div>
                                        </div>
                                        <div className="panel-body">
                                            <DataTable
                                                columns={columns}
                                                data={this.state.SearchItem === '' ? this.state.data : this.state.filtered}
                                                fixedHeader
                                                noHeader={true}
                                                overflowY={false}
                                                overflowYOffset={'100px'}
                                                highlightOnHover
                                                responsive
                                                striped
                                                // dense
                                                paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                                progressPending={!this.state.pageloader}
                                                pagination={true}
                                                paginationPerPage={20}
                                                defaultSortField={'c_date'}
                                                defaultSortAsc={false}
                                                subHeader={true}
                                                subHeaderAlign={'right'}
                                                subHeaderComponent={
                                                    <React.Fragment>
                                                       <a href="/ProductionAdd" className="btn btn-primary">Oluştur </a>
                                                    </React.Fragment>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(SetList);