import React,{useState,useEffect} from 'react';
import {  Col, Row,  Typography,Button,Divider, Tabs,Table,PageHeader,DatePicker ,Form ,Select} from 'antd'; 
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import moment from 'moment'; 
import Chart from "react-apexcharts";
import ExportFromJson from '../utilities/jsontoexport';
import Wrapper from '../utilities/Wrapper';
import { SearchOutlined,DownloadOutlined } from '@ant-design/icons'; 
import SelectList from '../utilities/SelectList';
 
const { Title ,Text} = Typography;
const { TabPane} = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;


const  TransferCrmAll =({api_url,loginToken})=> {

    const [searchForm] = Form.useForm();
    const [Data,SetData] = useState([]);
    const [Completed,setCompleted] = useState(false);
    const [clearFilter,setclearFilter] = useState(false);
    const [Loading,setLoading] = useState(false);
    const [IdComInterviewType,setIdComInterviewType] = useState('0')
    const [IdComBrand,setIdComBrand] = useState('0')
    const [IdComInterviewSubject,setIdComInterviewSubject]= useState('0')

    const excel_columns  = [
        {name:'tarih',selector:'c_date'},
        {name:'saat',selector:'c_time'},
        {name:'sase',selector:'chasis'},
        {name:'firma',selector:'company_name'},
        {name:'yorum',selector:'customer_comment'},
        {name:'teslimat tarihi',selector:'delivery_date'},
        {name:'email',selector:'email'},
        {name:'forwarded',selector:'forwarded'},
        {name:'id',selector:'id'},
        {name:'aktivite',selector:'id_com_activity_name'},
        {name:'marka',selector:'id_com_brand_name'},
        {name:'arac markasi',selector:'id_com_cars_brand_name'},
        {name:'arac modeli',selector:'id_com_cars_model_name'},
        {name:'musteri id',selector:'id_com_customer'},
        {name:'etk_status',selector:'id_com_etk_status'},
        {name:'sigorta firmasi',selector:'id_com_insurance_company_name'},
        {name:'expert adi ',selector:'id_com_insurance_expert_name'},
        {name:'durum',selector:'id_com_interview_status_name'},
        {name:'konu',selector:'id_com_interview_subject_name'},
        {name:'tip',selector:'id_com_interview_type_name'},
        {name:'temas',selector:'id_com_meet_name'},
        {name:'arac mdeli',selector:'id_com_model_name'},
        {name:'servis randevu id',selector:'id_com_service_app'},
        {name:'stok id',selector:'id_com_stock'},
        {name:'temsilci id',selector:'id_com_user_name'},
        {name:'sigorta hedef disi',selector:'ins_exclude'},
        {name:'sigorta kasko',selector:'ins_kasko'},
        {name:'marka kasko',selector:'ins_marka_kasko'},
        {name:'yenileme',selector:'ins_renew'},
        {name:'gorusme no',selector:'ins_traffic'},
        {name:'gorusme sase',selector:'inter_chasis'},
        {name:'gorusme no ',selector:'interview_number'},
        {name:'kasko',selector:'kasko'},
        {name:'kvkk durum',selector:'kvkk_status'},
        {name:'son yorum',selector:'last_comment_id_com_user'},
        {name:'plaka',selector:'license_plate'},
        {name:'satir adedi',selector:'lines_counted'},
        {name:'son yorum yapan',selector:'ls_id_com_user_name'},
        {name:'model yili',selector:'model_year'},
        {name:'ay',selector:'month_'},
        {name:'next_forward',selector:'next_forwarded'},
        {name:'telefon',selector:'phone1'},
        {name:'durum',selector:'status_name'},
        {name:'test surusu',selector:'testdrive'},
        {name:'trafik',selector:'traffic'},
        {name:'kullanici',selector:'username'},
        {name:'yil',selector:'year_'},
    ]

    
    const _GetData = async ()=>{
            setLoading(true);
            const form_data = searchForm.getFieldsValue()

            let url= api_url+'/selectrows/view_interview';
            let body = 'token='+loginToken+'&type=user&id_com_interview_type='+form_data.id_com_interview_type;
            let config = {  method:'POST',    cahce: 'no-cache',    headers:{     'Content-Type':'application/x-www-form-urlencoded'     },   body: body }
          
          try {
            const result = await (await fetch(url,config)).json(); 
            var arr_meet; 
            var arr_model;
            var arr_year;
            var arr_month; 
            var arr_user; 
            var arr_status;

            for ( const [ key,value] of Object.entries(form_data)){ 
                const TypeOf = typeof value;
                switch(key){
                        case 'id_com_meet': 
                            if(TypeOf==='object'||value!==undefined){ 
                            const arr1 = []
                                value.map((row)=>{
                                    arr1.push(row.id)
                                })
                            arr_meet=arr1;
                            }  
                        break;
                        case 'id_com_model': 
                        if(TypeOf==='object'||value!==undefined){ 
                        const arr2 = []
                            value.map((row)=>{
                                arr2.push(row.id)
                            })
                        arr_model=arr2;
                        }  
                     break;
                    case 'id_com_user': 
                        if(TypeOf==='object'||value!==undefined){ 
                        const arr3 = []
                            value.map((row)=>{
                                arr3.push(row.id)
                            })
                        arr_user=arr3;
                        }  
                     break;

                    case 'id_com_year': 
                        if(TypeOf==='object'||value!==undefined){ 
                         const arr4 = []
                            value.map((row)=>{
                                console.log(row.title)

                                arr4.push(row.title)
                            })
                        arr_year=arr4;
                        }  
                     break;

                    case 'id_com_month': 
                        if(TypeOf==='object'||value!==undefined){ 
                        const arr5 = []
                            value.map((row)=>{
                                arr5.push(row.id)
                            })
                        arr_month=arr5;
                        }  
                     break;
                     case 'id_com_interview_status': 
                     if(TypeOf==='object'||value!==undefined){ 
                     const arr6 = []
                         value.map((row)=>{
                             arr6.push(row.id)
                         })
                     arr_status=arr6;
                     }  
                  break;
                        default : 
                        break;
                }
            }
 
            // SetData(result)

           const filtered = result.filter(lines=> form_data.id_com_interview_subject===undefined?lines:lines.id_com_interview_subject===form_data.id_com_interview_subject )
           .filter(lines=> form_data.id_com_brand===undefined?lines:lines.id_com_brand===form_data.id_com_brand )
            .filter(row=> form_data.id_com_interview_status===undefined||form_data.id_com_interview_status.length===0?row:arr_status.includes(row.id_com_interview_status)) 
            .filter(row=> form_data.id_com_user===undefined||form_data.id_com_user.length===0?row:arr_user.includes(row.id_com_user))
            .filter(row=> form_data.id_com_meet===undefined||form_data.id_com_meet.length===0?row:arr_meet.includes(row.id_com_meet)) 
            .filter(row=> form_data.id_com_month===undefined||form_data.id_com_month.length===0?row:arr_month.includes(row.month_))
            .filter(row=> form_data.id_com_model===undefined||form_data.id_com_model.length===0?row:arr_model.includes(row.id_com_model)) 
            .filter(row=> form_data.id_com_year===undefined||form_data.id_com_year.length===0?row:arr_year.includes(row.year_)) 
 
            SetData(filtered)

            setLoading(false);
            setCompleted(true)
           }

           catch(e){
               console.log(e)
           }


    }

    const _onFieldsChange = (e)=>{
        const key = e[0].name[0];
        const value = e[0].value;
 
        switch(key){
            case 'id_com_interview_type':
                    setIdComInterviewType(value)
             break;
            case 'id_com_brand':
                setIdComBrand(value)
            break;
            case 'id_com_interview_subject':
                setIdComInterviewSubject(value)
            break;
            default: 
            return null;
        }

    }


    return ( 

        <Wrapper title='Görüşme Notu Aktarım'>
           <Divider></Divider>
            <Row justify='start'>
                <Col span={24} style={{padding:15}}>
                    <Form  form={searchForm}  name="register"  onFinish={_GetData} size='small' layout='vertical'   onFieldsChange={_onFieldsChange} labelAlign='left' labelCol={{span:10}}>
                           <Row gutter={12}>
                                <Col lx={8} lg={8} md={12} sm={24} xs={24}> 
                                <Form.Item label={'Görüşme Tipi'}   name={'id_com_interview_type'} rules={[{required:true,message:'Zorunlu alan'}]} >
                                        <SelectList table='com_interview_type' noLabel />
                                </Form.Item>
                                <Form.Item label={'Konu'}  name={'id_com_interview_subject'} >
                                        <SelectList table='com_interview_subject'   id_com_interview_type={IdComInterviewType} noLabel/>
                                </Form.Item>
                                <Form.Item label={'Temas Türü'}   name={'id_com_meet'} >
                                    <SelectList table='com_meet'  noLabel multiple={true}  />
                                </Form.Item>
                                </Col>
                                <Col lx={8} lg={8} md={12} sm={24} xs={24}> 
                                <Form.Item label={'Araç Markası'}   name={'id_com_brand'} >
                                    <SelectList table='com_brand' dealer={IdComInterviewType==='2'?false:true}   noLabel/>
                                </Form.Item>

                                <Form.Item label={'Araç Modeli'}   name={'id_com_model'} >
                                    <SelectList table='com_model' id_com_brand={IdComBrand} dealer={true}  multiple={true}  noLabel/>
                                </Form.Item>
                                <Form.Item label={'Temsilci'}   name={'id_com_user'} >
                                    <SelectList table='view_user_sl_all'  noLabel multiple={true}  />
                                </Form.Item>
                                </Col>
                                <Col lx={8} lg={8} md={12} sm={24} xs={24}> 
                                <Form.Item label={'Yıl'}   name={'id_com_year'} >
                                    <SelectList table='com_year'  noLabel multiple={true}  />
                                </Form.Item>
                                <Form.Item label={'Ay'}   name={'id_com_month'} >
                                    <SelectList table='com_month'  noLabel multiple={true}  />
                                </Form.Item>
                                <Form.Item label={'Durum'}   name={'id_com_interview_status'} >
                                    <SelectList table='com_interview_status'  id_com_interview_subject={IdComInterviewSubject} id_com_interview_type={IdComInterviewType} noLabel multiple={true} bySubject={true} />
                                </Form.Item>
                                </Col>
                           </Row>
                        <Divider></Divider>
                        <Row justify='end'>
                            <Form.Item    name={'btn'} >
                                 {Completed&& <ExportFromJson data={Data} fileName={`Gorusme_notlari_aktarim${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}`} exportType={'xls'}/>}

                                <Button    type={clearFilter?'primary':'default'}  danger style={{ margin: '0 8px' }} onClick={()=>{searchForm.setFieldsValue();setCompleted(false)}}  loading={Loading}>   Temizle </Button>
                                <Button type="primary"  htmlType='submit' icon={<DownloadOutlined/>}  loading={Loading} disabled={clearFilter} > Raporu Çalıştır  </Button> 
                            </Form.Item>
                        </Row>
                    </Form>

                </Col>
            </Row>
        </Wrapper>

    )
}

export default connect(mapStateToProps)(TransferCrmAll)