import React ,{useState,useEffect}from 'react';
import { Input ,Button, Popover, Typography, Row,Radio,  Col, Table, Divider,Form, Card,Modal,Checkbox,DatePicker, PageHeader, List,Upload,message,Spin,Tag,Tabs } from 'antd';
import { DeleteOutlined,CloseOutlined,SearchOutlined,CheckOutlined,PlusOutlined,RetweetOutlined,EditOutlined,UploadOutlined,DownloadOutlined ,FileExcelOutlined} from '@ant-design/icons';
import moment from 'moment';
import SelectList from '../utilities/SelectList';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import Wrapper from '../utilities/Wrapper';
import mapStateToProps from '../../redux/mapStateToProps';
import {connect} from 'react-redux';
import ExportFromJson from '../utilities/jsontoexport';

const {Text,Paragraph} =Typography;
const {TabPane} = Tabs
const WebFormList = ({api_url,loginToken,match,id_com_user})=>{ 
    const [Loading,setLoading] = useState(false);
    const [Data,setData] = useState([]); 
    const [SearchItem,setSearchItem] = useState('');
    const [FilteredData,setFilteredData] = useState([]);
    const [Status,setStatus]= useState([]);
    const [Brands,setBrands]= useState([]);

    

    const Columns=[
        {
            title:'İşlem',
            dataIndex:'',
            align:'center',
            render: (row,record)=> 
            record.id_com_webform_status==='1' &&
            <Row justify='center' gutter={12} >
                <Col style={{marginBottom:10}} >
                    <Button type='primary' size='small' shape='round'    onClick={()=>_Action(record,'2')} loading={Loading}><CheckOutlined/></Button>
                </Col>
                <Col style={{marginBottom:10}} >
                    <Button type='primary' size='small' shape='round'  danger onClick={()=>_Action(record,'3')} loading={Loading}><CloseOutlined/>Spam</Button>
                </Col>
            </Row>
        },
        {
            title:'Tarih / Saat',
             align:'center',
            render : (row,record)=> 
            <Paragraph>{`${moment(record.c_date).format('DD.MM.YYYY')} - ${record.c_time}`}</Paragraph>

        },
         
        {
            title:'Form',
            dataIndex:'webform_type',
            align:'center',
            render : (row,record)=> <>
                <Paragraph>{record.webform_type}  
                        { record.id_com_stock!=='0'&&record.id_com_webform_type==='1' ? <Button shape='round' type='default' size='small' target={'blank_'} href={'/usedcars/detail/'+record.id_com_stock} style={{marginLeft:10}} icon={<SearchOutlined/>}> Araç Detayı</Button> :null}
                </Paragraph>
                <Paragraph>
                         {record.web_form_subject}             
                </Paragraph>
                </>
        },
        {
            title:'Tip',
            dataIndex:'id_com_interview_type_name',
            align:'center'
        },
        {
            title:'Konu',
            dataIndex:'id_com_interview_subject_name',
            align:'center'
        },
        {
            title:'Marka',
            dataIndex:'brand',
            align:'center'
        },
        {
            title:'Model',
            dataIndex:'model',
            align:'center'
        },
        {
            title:'Musteri',
             align:'center',
            render : (row,record)=>record.customer_name+' '+record.customer_surname
        },
        {
            title:'Telefon',
            dataIndex:'customer_phone',
            align:'center'
        },
        {
            title:'Email',
            dataIndex:'customer_email',
            align:'center'
        },
        {
            title:'Mesaj',
            dataIndex:'message',
            align:'center'
        },
        {
            title:'İşlem Yapan', 
            align:'center',
            render : (row,record)=>
            <Row>
                <Col span={24}>
                    <Paragraph>{record.m_id_com_user_name}</Paragraph>
                    <Paragraph>{`${moment(record.m_date).format('DD.MM.YYYY')} - ${record.m_time}`}</Paragraph>
                </Col>
            </Row>
 
        },
    ]
 
    const WebFormTypes =[
        {
            type:'1',
            name:'Sıfır Satış',
            arr:['1']
        },
        {
            type:'2',
            name:'Ikinci El',
            arr:['2']
        },
        {
            type:'3',
            name:'Sigorta',
            arr:['3']
        },
        {
            type:'4',
            name:'Servis Randevu',
            arr:['5','6','7','8']
        }
    ]

    useEffect(() => {
        const _onLoad = ()=>{
            _getData()
        }
       return _onLoad();
    }, [])

    const _getData = async () =>{
        setLoading(true)
        const view_body_url= api_url+'/selectRows/view_webform';
        const body = 'token='+loginToken+'&type=user';
        const config = { method:'POST',  cahce: 'no-cache', headers:{ 'Content-Type':'application/x-www-form-urlencoded' },body:body}
        try { 


            const response = await(await fetch(view_body_url,config)).json();
    
            const filter_array = WebFormTypes.filter(status=>status.type===match.params.id)[0].arr
             const filtered_response = response.filter(lines=>filter_array.includes(lines.id_com_interview_type))
            const status =[];
            const brand =[];

            filtered_response.map(row=>{
                status.push(row.id_com_webform_status_name)
                brand.push(row.brand)

            });
            const u_status = [...new Set(status)]
            const u_brand= [...new Set(brand)]

            setStatus(u_status.sort());
            setBrands(u_brand);
            setData(filtered_response)
            setLoading(false)
        }
        catch(e){
            console.log(e)
        }
    }

 

 

const _Action = async (data,status)=>{

    setLoading(true)
    let d = new Date(); 
    let date = moment(d).format('YYYY-MM-DD')
    let time = d.toLocaleTimeString('tr-TR');
    const view_body_url= api_url+'/update/com_webform';
    let body = 'type=user&token='+loginToken+'&id='+data.id+'&m_id_com_user='+id_com_user+'&m_time='+time+'&m_date='+date+'&id_com_webform_status='+status;
    const config = { method:'POST',  cahce: 'no-cache', headers:{ 'Content-Type':'application/x-www-form-urlencoded' },body:body}

    try { 
        const response = await(await fetch(view_body_url,config)).json();
    


 if(response.status){

            message.success('Spam Olarak Kayıt Edildi',1).then(()=>window.location.reload())
          
        }
        else { 
            message.error('Bir Hata Oluştu',1).then(()=>window.location.reload())
        } 

    }   
    catch(e){
        console.log(e)
    }
}

    const _Filter= (e)=>{
        setSearchItem(e)
       let match_str = new RegExp('('+e+')','i');
       let filteredData = Data.filter((row)=>{ 
           return  row.webform_type.match(match_str)||row.customer_email.match(match_str)||row.customer_phone.match(match_str)||row.model.match(match_str)||row.customer_name.match(match_str)||row.customer_surname.match(match_str)  
        })
       setFilteredData(filteredData)
   }
 
        return ( 
            <Wrapper title={`Web Form Listesi`}  extra={!Loading&&[
                  <ExportFromJson data={SearchItem===''?Data:FilteredData} fileName={`web_formlar_${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}`} exportType={'xls'}/>,
                  <Button type='default' key='refresh'  loading={Loading}   onClick={_getData} icon={<RetweetOutlined/>}>Güncelle</Button>,  
             ]}>
            <Row>
                <Col span={24}  style={{padding:20}}>
                    <Input.Search disabled={Loading} placeholder='Musteri, Email, Telefon, Model' onChange={(x)=>_Filter(x.target.value)}/>
                </Col>
            </Row>
            <Row justify='center' align='middle'>
            {!Data.length>0?
                    <Col span={8}  style={{padding:20}}>
                        <Paragraph code type='danger' >Web Form Bulunmamaktadır. Son Güncelleme :  {moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}</Paragraph>
                    </Col>
              
             :Brands.map(brand=>
                <Col span={24}  style={{padding:20}}> 
                    <Divider>{brand} - {SearchItem===''?Data.filter(line=>line.brand===brand).length:FilteredData.filter(line=>line.brand===brand).length} Adet</Divider>
                    <Tabs type='card' defaultActiveKey={0} tabPosition='left' >
                        {Status.map((row,idx)=>
                            <TabPane key={idx} tabKey={idx} tab={`${row}  (${SearchItem===''?Data.filter(line=>line.id_com_webform_status_name===row&&line.brand===brand).length:FilteredData.filter(line=>line.id_com_webform_status_name===row&&line.brand===brand).length})` }  >
                                    <Table bordered size='small' dataSource={SearchItem===''?Data.filter(line=>line.id_com_webform_status_name===row&&line.brand===brand):FilteredData.filter(line=>line.id_com_webform_status_name===row&&line.brand===brand)} columns={Columns} loading={Loading} /> 
                            </TabPane>
                        )}
                    </Tabs>
                </Col>
            
            )}
              
            </Row> 

            </Wrapper>

    )
}


export default connect(mapStateToProps)(WebFormList);
