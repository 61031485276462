import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
import {alertMessage} from '../../assets.js';

class Interview_edit_lines extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled:true,
            data:[]
        };
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }

    componentDidMount(){
        const id = this.props.match.params.id;
        this.setState({ 
            id: id
        })

        let view_lines_url=this.props.api_url+'/selectRows/view_interview_lines';
        fetch(view_lines_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
              body:'id='+id+'&token='+this.props.loginToken+'&type=user'
            })
            .catch(error=>console.log(error))
            .then(res=>res.json())
            .then(result=>{
                let data = result[0];
            for (let [key, value] of Object.entries(data)) {
            this.setState({ [`${key}`]:value,pageloader:true
                })
            }
            })
          
    let com_lines_url=this.props.api_url+'/selectRows/com_interview_lines';
    fetch(com_lines_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body:'id='+id+'&token='+this.props.loginToken+'&type=user'
        })
        .catch(error=>console.log(error))
        .then(res=>res.json())
        .then(result2=>{
        this.setState({data:result2[0]})
        })




    }

    handleChange(event,selectlist,name) {
        if(selectlist) { 
            this.setState({ [`${name}`]:event.id})
            // console.log('id changed')
        } else { 
            const value = event.target.value;
            const key = event.target.name;
            this.setState({ [`${key}`]:value})
        }
        this.setState({disabled:false})
    }

   async handleSubmit(event) {
        let d = new Date(); 
        let date = d.toISOString();
        let m_date  = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');


        let update_body = 
        'token='+this.props.loginToken+
        '&m_date='+m_date+
        '&m_time='+m_time+
        '&m_id_com_user='+this.props.id_com_user+
        '&type=user';
        await this.setState({m_date:m_date,m_id_com_user:this.props.id_com_user,m_time:m_time})

        for (let [key] of Object.entries(this.state.data)) {
            update_body +=`&${key}=`+ this.state[`${key}`]
    }

 
    let com_lines_url=this.props.api_url+'/update/com_interview_lines';
    fetch(com_lines_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body:update_body,
        })
        .catch(error=>console.log(error)).then(x=> {this.setState({disabled:false});
        window.location.href="/interview/Detail/"+this.state.id_com_interview
    }
        )
}



    render() { 
        return (
            <div className="main">
            <div className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel">
                                <div className="panel-heading">
                                    <div className="panel-title">Görüşme Satır Düzenleme Ekranı</div>
                                </div>
                                <div className="panel-body">
                                <SelectList table='com_meet' label={'Temas Türü'} name={'id_com_meet'}    value={this.state.id_com_meet} onChange={(x)=>this.handleChange(x)} />
                                <div className="form-group" >
                                    <label>Not : </label>
                                    <textarea type="text" style={{height:200}} row='20' value={this.state.content} className="form-control" name="content" onChange={(x) => this.handleChange(x)} required/>
                              </div>

                                <div className="panel-footer">
                                <a className="btn btn-danger pull-left" href={"/interview/Detail/"+this.state.id_com_interview}>İptal</a>
                                <button className="btn btn-primary pull-right"
                                    disabled={this.state.disabled}
                                    onClick={()=>this.handleSubmit()} 
                                    >Kaydet</button>                               
                                </div>
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
          </div>

          </div>


        )
    }



}


export default connect(mapStateToProps)(Interview_edit_lines);