import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component';

const car_expertise_columns =[
    {
        name: 'Tarih',
        selector: 'c_date',
        left:true
    },
    {
        name: 'Teklif Fiyatı',
        selector: 'offer_price',
        left:true
    },
    {
        name: 'Açıklama',
        selector: 'comment',
        left:true
    },
    {
        name: 'Teklifi Veren',
        selector: 'id_com_user_name',
        left:true,
        width: '120px'
    },
    {
        name: 'Durum',
        selector: 'id_com_expertise_status_name',
        left:true,
        width: '120px'
    },
    {
        name: 'Kontrol',
        cell : row =>(
            <a className="btn btn-primary btn-sm btn-block" type="button" target='_blank' href={'/expertise/Detail/'+row.id}>Seç</a>
        ),
        left:true,
        width: '120px'
    }
]


const serivce_app_columns =[
    {
        name: 'Tarih',
        selector: 'app_date',
        left:true
    },
    {
        name: 'Saat',
        selector: 'app_time',
        left:true
    },
    {
        name: 'Randevu Konusu',
        selector: 'id_com_service_app_subject_name',
        left:true
    },
    {
        name: 'Danışman',
        selector: 'id_user_register_username',
        left:true,
        width: '120px'
    },
    {
        name: 'Durum',
        selector: 'id_com_service_app_status_name',
        left:true,
        width: '120px'
    },

    {
        name: 'Kontrol',
        cell : row =>(
            <a className="btn btn-primary btn-sm btn-block" type="button" target='_blank' href={'/serviceapp/detail/'+row.id}>Seç</a>
        ),
        left:true,
        width: '120px'
    }
]



class CarDetail extends React.Component {
    constructor(props){
        super(props);
        this.state={
            id_com_cars:'',
            car_data:[],
            car_data_loaded:false,
            car_expertise_data:[],
            car_expertise_data_loaded:false,
            service_app_data:[],
            service_app_data_loaded:false,


            }
        this.Update=this.Update.bind(this);
    }
    componentDidMount () {
        this.Update();
    }
    Update(){
        let id_com_cars = this.props.match.params.id
        this.setState({id_com_cars:id_com_cars})
        let cars_url=this.props.api_url+'/selectRows/view_cars';
        fetch(cars_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body:'id='+id_com_cars+'&token='+this.props.loginToken+'&type=user',
        }).then(res=>res.json())
          .then(result=>{

console.log(result[0])
              this.setState({car_data:result[0],car_data_loaded:true})
        })
 


        let service_app_url=this.props.api_url+'/selectRows/view_service_app';
        fetch(service_app_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body:'id_com_cars='+id_com_cars+'&token='+this.props.loginToken+'&type=user',
        }).then(res=>res.json())
          .then(result=>{
              this.setState({service_app_data:result,service_app_data_loaded:true})
        })

        let cars_expertise=this.props.api_url+'/selectRows/view_expertise';
        fetch(cars_expertise,{
            method:'POST',
            cahce: 'no-cache',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body:'id_com_cars='+id_com_cars+'&token='+this.props.loginToken+'&type=user',
        }).then(res=>res.json())
          .then(result=>{
              this.setState({car_expertise_data:result,car_expertise_data_loaded:true})
        })

     } 

    render( ){
        return(
<div className="main">
    <div className="main-content">
        <div className="container-fluid">
            <div className="row">
 
                <div className="col-md-12">
                    <div className="panel"  >
                        <div className="col-md-6">
                            <div className="panel-heading">
                                <h3 className="panel-title">Araç Kartı</h3>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="panel-heading">
                                    <a className="btn btn-primary pull-right"
                                        href={'/cars/Edit/' + this.state.id_com_cars}>Düzenle
                                    </a>
                            </div>
                        </div>
                            <div className="panel-body">
                           <table className="table table-bordered table-striped">
                           
                                            <tbody>  
                                            {this.state.car_data.id_com_customer_name != null?
                                                    <tr>
                                                        <th>Müşteri Adı</th>
                                                        <td>{this.state.car_data.id_com_customer_name}</td>
                                                    </tr>
                                                :
                                                    <tr>
                                                        <th>Müşteri Adı</th>
                                                        <td>-</td>
                                                    </tr>
                                                }
                                                {this.state.car_data.phone1!= null?
                                                    <tr>
                                                        <th>Telefon - Email </th>
                                                        <td>{this.state.car_data.phone1 +' - '+this.state.car_data.email }</td>
                                                    </tr>
                                                :
                                                    <tr>
                                                        <th>Telefon - Email</th>
                                                        <td>-</td>
                                                    </tr>
                                                }  
                                                {this.state.car_data.license_plate != null?
                                                    <tr>
                                                        <th>Plaka</th>
                                                        <td>{this.state.car_data.license_plate}</td>
                                                    </tr>
                                                :
                                                    <tr>
                                                        <th>Plaka</th>
                                                        <td>-</td>
                                                    </tr>
                                                }
                                                {this.state.car_data.chasis != null?
                                                    <tr>
                                                        <th>Şase</th>
                                                        <td>{this.state.car_data.chasis}</td>
                                                    </tr>
                                                :
                                                    <tr>
                                                        <th>Şase</th>
                                                        <td>-</td>
                                                    </tr>
                                                }
 
                                                {this.state.car_data.id_com_brand_name != null?
                                                    <tr>
                                                        <th>Marka</th>
                                                        <td>{this.state.car_data.id_com_brand_name}</td>
                                                    </tr>
                                                :
                                                    <tr>
                                                        <th>Marka</th>
                                                        <td>-</td>
                                                    </tr>
                                                }
                                                {this.state.car_data.id_com_model_name != null?
                                                    <tr>
                                                        <th>Model</th>
                                                        <td>{this.state.car_data.id_com_model_name}</td>
                                                    </tr>
                                                :
                                                    <tr>
                                                        <th>Model</th>
                                                        <td>-</td>
                                                    </tr>
                                                }
                                                {this.state.car_data.spec != null?
                                                    <tr>
                                                        <th>Spek</th>
                                                        <td>{this.state.car_data.spec}</td>
                                                    </tr>
                                                :
                                                    <tr>
                                                        <th>Spek</th>
                                                        <td>-</td>
                                                    </tr>
                                                }
                                                {this.state.car_data.id_com_color_name != null?
                                                    <tr>
                                                        <th>Renk</th>
                                                        <td>{this.state.car_data.id_com_color_name}</td>
                                                    </tr>
                                                :
                                                    <tr>
                                                        <th>Renk</th>
                                                        <td>-</td>
                                                    </tr>
                                                }
                                                {this.state.car_data.id_com_fuel_name != null?
                                                    <tr>
                                                        <th>Yakıt - Vites</th>
                                                        <td>{this.state.car_data.id_com_fuel_name +' / '+this.state.car_data.id_com_gear_name }</td>
                                                    </tr>
                                                :
                                                    <tr>
                                                        <th>Yakıt - Vites</th>
                                                        <td>-</td>
                                                    </tr>
                                                }
                                                {this.state.car_data.model_year != null?
                                                    <tr>
                                                        <th>Model Yılı</th>
                                                        <td>{this.state.car_data.model_year}</td>
                                                    </tr>
                                                :
                                                    <tr>
                                                        <th>Model Yılı</th>
                                                        <td>-</td>
                                                    </tr>
                                                }
  
                                            </tbody>
                                        </table>
                         <div className="panel-body">
                                    <DataTable 
                                            title={"Ekspertizler"}
                                            columns={car_expertise_columns}
                                            data={this.state.car_expertise_data}
                                            fixedHeader
                                            highlightOnHover
                                            responsive
                                            defaultSortField="id"
                                            striped
                                            dense
                                            progressPending={!this.state.car_expertise_data_loaded}
                                            pagination={true}
                                            paginationPerPage={5}
                                            defaultSortAsc={true} 
                                            />                                   
                                     </div>
                                     <div className="panel-body">
                                    <DataTable 
                                            title={"Randevular"}
                                            columns={serivce_app_columns}
                                            data={this.state.service_app_data}
                                            fixedHeader
                                            highlightOnHover
                                            responsive
                                            defaultSortField="id"
                                            striped
                                            dense
                                            progressPending={!this.state.service_app_data_loaded}
                                            pagination={true}
                                            paginationPerPage={5}
                                            defaultSortAsc={true} 
                                            subHeader={true}
                                            subHeaderAlign={'right'}
                                            subHeaderComponent={
                                                <a className="btn btn-danger btn-sm " type="button"  href={'/serviceapp/add/'+this.state.id_com_cars}>Ekle</a>
                                                }
                                            />                                   
                                     </div>
                                        </div>
                                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

        )
    }
}
    export default connect(mapStateToProps)(CarDetail);