import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';
import {alertMessage} from '../../assets.js';

class CarsEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            disabled: true,
            pageloader: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const id_com_cars = this.props.match.params.id;
        let d = new Date();
        let date = d.toISOString();
        let m_date = date.split('T')[0];
        let m_time = d.toLocaleTimeString('tr-TR');


        this.setState({
            id_com_cars: id_com_cars,
            m_date:m_date,
            m_time:m_time,
            m_id_com_user:this.props.id_com_user,
        })

        let product_url = this.props.api_url + '/selectRows/com_cars';
        fetch(product_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id=' + id_com_cars + '&token=' + this.props.loginToken + '&type=user'
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
                let data = result[0];
                for (let [key, value] of Object.entries(data)) {
                    if(key!=='m_time'&&key!=='m_date'&&key!=='m_id_com_user'){
                        this.setState({
                            [`${key}`]: value, pageloader: true
                        })
                    }
                   
                }
            })

        let com_product_url = this.props.api_url + '/selectRows/com_cars';
        fetch(com_product_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id=' + id_com_cars + '&token=' + this.props.loginToken + '&type=user'
        })
            .catch(error => console.log(error))
            .then(res => res.json())
            .then(result2 => {
                this.setState({data: result2[0]})
            })


    }

    handleChange(event, selectlist, name) {
        if (selectlist) {
            this.setState({[`${name}`]: event.id})
        } else {
            const value = event.target.value;
            const key = event.target.name;
            this.setState({[`${key}`]: value})
        }
        this.setState({disabled: false})
    }

    handleSubmit(event) {
       

        let update_body =
            'token=' + this.props.loginToken +
     
            '&type=user';

        for (let [key] of Object.entries(this.state.data)) {
            update_body += `&${key}=` + this.state[`${key}`]
        }

        console.log(update_body)

        let com_cars_url = this.props.api_url + '/update/com_cars';
        fetch(com_cars_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: update_body,
        })
            .catch(error => console.log(error)).then(x => {
                this.setState({disabled: false});
                window.location.reload()
            }
        )

    }


    render() {
        return (
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                     
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">Araç Kartı Düzenle</h3>
                                    </div>
                                    <div className="panel-body">
                                        {!this.state.pageloader ?
                                            <div className="col-md-12"
                                                 dangerouslySetInnerHTML={{__html: alertMessage('loadingData')}}></div>
                                            :
                                            <>
                                            <div className="form-group">
                                        <label>Plaka</label>
                                        <input type="text" className="form-control" name="license_plate" onChange={(x) => this.handleChange(x)} placeholder="Araç plakası" required value={this.state.license_plate}/>
                                       {/* {this.state.license_plate===''this.state.disabled?<a style={{color:'red'}} type='button' href={'/cars/detail/'+this.state.id_com_cars}>Plaka Daha Önce Kayıt Edilmiştir. Tıklayınız... </a>:<></>} */}
                                    </div>
                                    <div className="form-group">
                                        <label>Şase</label>
                                        <input type="text" className="form-control" name="chasis" onChange={(x) => this.handleChange(x)} placeholder="Şase Bilgisi"  value={this.state.chasis}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Model Yılı</label>
                                        <input type="text" className="form-control" name="model_year" onChange={(x) => this.handleChange(x)} placeholder="Model Yılı Giriniz"  value={this.state.model_year}/>
                                    </div>
                                    <SelectList table='com_brand' label={'Marka'} name={'id_com_brand'}  onChange={(x)=>this.handleChange(x)} value={this.state.id_com_brand}/>
                                    <SelectList table='com_model' label={'Model'} name={'id_com_model'}  id_com_brand={this.state.id_com_brand}  onChange={(x)=>this.handleChange(x)}  value={this.state.id_com_model}/>
                                    <SelectList table='com_gear' label={'Vites'}  name={'id_com_gear'} onChange={(x)=>this.handleChange(x)} value={this.state.id_com_gear}/>
                                    <SelectList table='com_fuel' label={'Yakıt'}  name={'id_com_fuel'} onChange={(x)=>this.handleChange(x)}  value={this.state.id_com_fuel}/>
                                    <SelectList table='com_color' label={'Renk'}  name={'id_com_color'} onChange={(x)=>this.handleChange(x)}  value={this.state.id_com_color} />
                                     <div className="form-group">
                                        <label>Spek</label>
                                        <input type="text" className="form-control" name="spec" onChange={(x) => this.handleChange(x)} placeholder="Araç spec giriniz Örnk: Elite Smart 1.4"  value={this.state.spec} />
                                    </div>
                                    <div className="form-group">
                                        <label>Belge No</label>
                                        <input type="text" className="form-control" name="doc_number" onChange={(x) => this.handleChange(x)} placeholder="Ruhsat Belge No" value={this.state.doc_number}  />
                                    </div>
                                            </>
                                        }
                                    </div>
                                    <div className="panel-footer">
                                        <a className="btn btn-danger" href="/Customer/List">İptal</a>

                                        <button className="btn btn-primary pull-right"
                                                disabled={this.state.disabled}
                                                onClick={() => this.handleSubmit()}
                                        >Kaydet
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default connect(mapStateToProps)(CarsEdit);

