import React ,{useState,useEffect}from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import Wrapper from '../utilities/Wrapper';
import {Row,Col,Button,Descriptions,Tag,Form,Input, DatePicker,Divider, Switch,message,Tabs, PageHeader, Typography, Checkbox, Spin} from 'antd';
import { EditOutlined,CheckOutlined,CloseOutlined,LeftOutlined} from '@ant-design/icons';
import * as CurrencyFormat from "react-currency-format";

import moment from 'moment';
import SelectList from '../utilities/SelectList';

import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';

import TransactionList from './TransactionList';
import GalleryList from './GalleryList';
import ExpertList from './ExpertList';
import NewSelectList from '../utilities/newSelectlist';



const UsedCarEdit =  ({match,api_url,loginToken,id_com_user}) =>{

const [editForm] = Form.useForm();

const [Id,setId] =useState('')
const [Loading,setLoading] = useState(false)
const [IdComBrand,setIdComBrand] = useState('')




useEffect(() => {
    const _onLoad = ()=>{
        _getData()
    }
   return _onLoad();
}, [])


const _getData = async ()=>{

     const id = match.params.id;
    setId(id)
    const view_body_url= api_url+'/selectRows/com_stock';
    const body = 'token='+loginToken+'&type=user&id='+id;
    const config = { method:'POST',  cahce: 'no-cache', headers:{ 'Content-Type':'application/x-www-form-urlencoded' },body:body}

    try { 
        const response = await(await fetch(view_body_url,config)).json();
        setIdComBrand(response[0].id_com_brand)
 
         let initial_values = {}

         for ( const [ key,value] of Object.entries(response[0])){ 
            switch(key){
                case 'date':
                    Object.assign(initial_values,{[`${key}`]:moment(value)})
                    break;
                case 'legal_date':
                    Object.assign(initial_values,{[`${key}`]:moment(value)})
                    break;
                case 'max_sale_date':
                    Object.assign(initial_values,{[`${key}`]:moment(value)})
                    break;
                case 'is_invoiced':
                    Object.assign(initial_values,{[`${key}`]:value==='1'?true:false})
                    break;
                case 'is_in_warranty':
                    Object.assign(initial_values,{[`${key}`]:value==='1'?true:false})
                    break;
                case 'spare_key':
                    Object.assign(initial_values,{[`${key}`]:value==='1'?true:false})
                    break;

                default :
                Object.assign(initial_values,{[`${key}`]:value})
 
                break;
            }
               
          
        }


       await  editForm.setFieldsValue(initial_values)
 
        setLoading(false)
    }   

    catch(e){
        console.log(e)
    }

}




const _Selector =(e)=>{
    const key = e[0].name[0];
    const value= e[0].value;
    
    switch(key) {
            case 'id_com_brand':
                setIdComBrand(value)
            break;
    
    
        default:
            break
    }
    }
    

const _Update = async ()=>{

    setLoading(true)
    const form_data =  editForm.getFieldsValue();

     let d = new Date(); 
    let date = moment(d).format('YYYY-MM-DD')
    let time = d.toLocaleTimeString('tr-TR');

    const view_body_url= api_url+'/update/com_stock';
    let body = 'type=user&token='+loginToken+'&id='+Id+'&m_id_com_user='+id_com_user+'&m_time='+time+'&m_date='+date;


    for ( const [ key,value] of Object.entries(form_data)){ 
       
       if(key!=='m_id_com_user'&&key!=='m_time'&&key!=='m_date')
        switch(key){
            case 'date':
                body +=`&${key}=${moment(value).format('YYYY-MM-DD')}`
                break;
            case 'legal_date':
                body +=`&${key}=${moment(value).format('YYYY-MM-DD')}`
                break;
            case 'max_sale_date':
                body +=`&${key}=${moment(value).format('YYYY-MM-DD')}`
                break;
            case 'is_invoiced':
                body +=`&${key}=${value?1:0}`
                break;
            case 'is_in_warranty':
                body +=`&${key}=${value?1:0}`
                break;
            case 'spare_key':
                body +=`&${key}=${value?1:0}`
                break;
            default :
                body +=`&${key}=${value}`
            break;
        }


    }
  
    const config = { method:'POST',  cahce: 'no-cache', headers:{ 'Content-Type':'application/x-www-form-urlencoded' },body:body}

    try { 
        const response = await(await fetch(view_body_url,config)).json();
        console.log(response )     


 if(response.status){

                message.success('Araç Güncellenmiştir',1).then(()=>window.location.href='/usedcars/detail/'+Id)
          
        }
        else { 
            message.error('Bir Hata Oluştu',1).then(()=>window.location.reload())
        } 

    }   

    catch(e){
        console.log(e)
    }



}

 

    return ( 
        <Wrapper title={`İkinci El Stok |`} subtitle='Düzenle' >
            <Divider></Divider>
                 <Row>
                    <Col span={24} style={{padding:20}}> 
                    <Form form={editForm} onFinish={_Update} size='small'  labelAlign='left' onFieldsChange={_Selector}  labelCol={{span:9}}>
                        <Row gutter={20}>
                            <Col xl={8} lg={8} md={12} xs={24} sm={24} >
                                <Form.Item name='doc_number'   label={'Araç Dosya No'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input type='text'/>
                                </Form.Item>
                                <Form.Item name='id_com_stock_class'   label={'Araç Sınıfı'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                                    <SelectList table='com_stock_class'  noLabel/>
                                </Form.Item>
                                <Form.Item name='id_com_stock_group'   label={'Kasa Tipi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <SelectList table='com_stock_group'  noLabel/>
                                </Form.Item>
                                <Form.Item name='id_com_brand'   label={'Araç Markası'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <SelectList table='com_brand'  noLabel/>
                                </Form.Item>
                                <Form.Item name='id_com_model'   label={'Araç Modeli'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <SelectList table='com_model' id_com_brand={IdComBrand}  noLabel/>
                                </Form.Item>
                                <Form.Item name='model_year'   label={'Model Yılı'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input type='number'/>
                                </Form.Item>
                                <Form.Item name='spec'   label={'Spec - Donanım'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input type='text'/>
                                </Form.Item>
                                <Form.Item name='engine_power'   label={'Motor Hacmi (cc)'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input type='number'/>
                                </Form.Item>
                                <Form.Item name='hp'   label={'Çekiş Gücü (Hp)'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input type='number'/>
                                </Form.Item>
                                <Form.Item name='is_in_warranty'   label={'Garantili '} valuePropName='checked' >
                                    <Checkbox >Evet</Checkbox>
                                </Form.Item>
                                <Form.Item name='spare_key'   label={'Yedek Anahtar'} valuePropName='checked' >
                                    <Checkbox >Var</Checkbox>
                                </Form.Item>
                              
                            </Col>
                            <Col xl={8} lg={8} md={12} xs={24} sm={24} >

                                <Form.Item name='chasis'   label={'Şase No'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input type='text'/>
                                </Form.Item>
                                <Form.Item name='engine_no'   label={'Motor No'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                                    <Input type='text'/>
                                </Form.Item>
                                <Form.Item name='license_plate'   label={'Plaka'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                                    <Input type='text'/>
                                </Form.Item>
                                <Form.Item name='km'   label={'Kilometre'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                                    <Input type='number' min={0}/>
                                </Form.Item>
                                <Form.Item name='id_com_color'   label={'Renk'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                                    <SelectList table='com_color'   noLabel/>
                                </Form.Item>
                                <Form.Item name='id_com_gear'   label={'Vites Tipi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <SelectList table='com_gear'   noLabel/>
                                </Form.Item>
                                <Form.Item name='id_com_fuel'   label={'Yakıt Tipi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <SelectList table='com_fuel'   noLabel/>
                                </Form.Item>
                                <Form.Item name='consumption_1'   label={'Şehir içi YT'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                                    <Input type='number' min={0} step={0.01}/>
                                </Form.Item>
                                <Form.Item name='consumption_2'   label={'Şehir Dışı YT'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                                    <Input type='number' min={0} step={0.01}/>
                                </Form.Item>
                                <Form.Item name='trunk_capacity'   label={'Bagaj Hacmi (m3)'} rules={[{required:true,message:'Zorunlu Alan'}]} >
                                    <Input type='number' min={0} step={0.01}/>
                                </Form.Item>
                            </Col>
                            <Col xl={8} lg={8} md={12} xs={24} sm={24} >
                                <Form.Item name='damage_report'   label={'Hasar Bilgileri'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input.TextArea rows={10}  />
                                </Form.Item>
                                <Form.Item name='comment'   label={'Notlar'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input.TextArea rows={10}  />
                                </Form.Item>
                            </Col>
                            <Divider>Fiyat Bilgileri</Divider>
                            <Col xl={8} lg={8} md={12} xs={24} sm={24} >
                                <Form.Item name='id_com_stock_purchase_type'   label={'Alım Tipi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <SelectList table='com_stock_purchase_type'   noLabel/>
                                </Form.Item>
                                <Form.Item name='id_com_stock_purchase_reason'   label={'Alım Sebebi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <SelectList table='com_stock_purchase_reason'   noLabel/>
                                </Form.Item>
                                <Form.Item name='id_com_kdv_rate'   label={'Kdv Oranı'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <SelectList table='com_kdv_rate'    noLabel/>
                                </Form.Item>
                            </Col>
                            <Col xl={8} lg={8} md={12} xs={24} sm={24} >
                                <Form.Item name='date'   label={'Alış Tarihi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <DatePicker  style={{width:'100%'}} locale={locale} format='DD.MM.YYYY' />
                                </Form.Item>
                                <Form.Item name='legal_date'   label={'Noter Tarihi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <DatePicker  style={{width:'100%'}} locale={locale} format='DD.MM.YYYY' />
                                </Form.Item>
                                <Form.Item name='max_sale_date'   label={'Max Satış Tarihi'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <DatePicker  style={{width:'100%'}} locale={locale} format='DD.MM.YYYY' />
                                </Form.Item>
                                <Form.Item name='id_com_purchase_customer'   label={'Satın Alınan'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <NewSelectList table='view_customer_sl'/>
                                </Form.Item>
                            </Col>
                            <Col xl={8} lg={8} md={12} xs={24} sm={24} >
                                <Form.Item name='inv_number'   label={'Noter Belge No'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input type='text'/>
                                </Form.Item>
                                <Form.Item name='inv_price'   label={'Alış Fiyatı'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input type='number' min={0} step={0.01}/>
                                </Form.Item>
                                <Form.Item name='list_price'   label={'Listeleme Fiyatı'} rules={[{required:true,message:'Zorunlu Alan'}]}>
                                    <Input type='number' min={0} step={0.01}/>
                                </Form.Item>
                                <Form.Item name='is_invoiced'   label={'Faturalı '} valuePropName='checked' >
                                    <Checkbox  />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Divider></Divider>
                        <Row gutter={20} justify='end'>
                            <Col >
                                <Button type='primary ' size='middle' icon={<CloseOutlined/>} onClick={()=>window.history.back()} danger loading={Loading}> Vazgeç</Button>
                            </Col>
                            <Col >
                                <Button type='primary ' htmlType='submit' size='middle' icon={<CheckOutlined/>} loading={Loading}> Kaydet</Button>
                            </Col>
                        </Row>
                    </Form>
                    </Col>
                </Row>
        </Wrapper>
    )


}


export default connect(mapStateToProps)(UsedCarEdit)