import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component';

class TaskListPersonel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: false,
            type1_data:[],
            type2_data: [],
            type1:[
                {
                    name: 'No',
                    selector: 'id',
                    width: '50px',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Görev Tipi',
                    selector: 'id_com_task_type_name',
                    left:true,
                    width: '100px',
                    sortable:true
                },
                {
                    name: 'Görev Konusu',
                    selector: 'id_com_task_subject_name',
                    left:true,
                    sortable:true,
                    width: '75px',

                },
                {
                    name: 'Görev Tanımı',
                    selector: 'comment',
                    left:true,
                    width: '700px',
                    wrap:true,
                    sortable:true
                },
                {
                    name: 'Görev Sorumlusu',
                    selector: 'r_id_com_user_name',
                    left:true,
                    width: '150px',
                    sortable:true
                },
                {
                    name: 'Yönetici',
                    selector: 'manager',
                    left:true,
                    width: '150px',
                    sortable:true
                }
            ],
            type2:[
                {
                    name: 'No',
                    selector: 'id',
                    width: '75px',
                    left:true,
                    sortable:true
                },

                {
                    name: 'Görev Konusu',
                    selector: 'id_com_task_subject_name',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Görev Tanımı',
                    selector: 'comment',
                    left:true,
                    wrap:true,
                    width: '600px',
                    sortable:true
                },
                {
                    name: 'Görev Sorumlusu',
                    selector: 'r_id_com_user_name',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Hedef Tamamlanma Tarihi',
                    selector: 'target_date',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Atayan',
                    selector: 'id_com_user_name',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Durumu',
                    left:true,
                    sortable:true,
                    cell : row =>(
                        row.status==='0'?
                        row.id_com_task_status_name
                        :
                        row.end_date+' / Tarihinde Tamamlandı.'
                    ),
                },
                {
                    name: 'Kontrol',
                    cell : row =>(
                        row.status==='1'?
                        <button className="btn btn-danger btn-sm" type="button" disabled={true} ><i class="fas fa-check-double"></i></button>
                        : <>
                        <button className="btn btn-success btn-sm" type="button" onClick={()=>this._CompleteTask(row.id)} style={{margin:5}}><i class="fas fa-check"></i></button>
                        </>
                    ),
                    right   :true,
                }
            ]
        }
        this._Update=this._Update.bind(this)
    }

    componentDidMount(){
        this._Update()
    }

    _CompleteTask(id){
        let d = new Date(); 
        let date = d.toISOString();
        let end_date  = date.split('T')[0];
        let end_time = d.toLocaleTimeString('tr-TR');

        let task_url=this.props.api_url+'/update/com_task';
        fetch(task_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body:'token='+this.props.loginToken+
          '&id='+id+
          '&end_date='+end_date+
          '&end_time='+end_time+
          '&id_com_task_status=3&status=1&type=user',
        })
        .catch(error=>console.log(error)).then(x=>window.location.reload())
    }


    _Update(){
        let task_url=this.props.api_url+'/selectRows/view_task';
        fetch(task_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
              body:'token='+this.props.loginToken+'&r_id_com_user='+this.props.id_com_user+'&type=user',
            })
            .catch(error=>console.log(error)).then(x=> x.json()).then(result=>{
                
               let  type2_data =  result.filter(row=>row.id_com_task_type==='0')
               let  type1_data =  result.filter(row=>row.id_com_task_type!=='0')

                this.setState({data:result,
                    type1_data:type1_data,
                    type2_data:type2_data,
                    pageloader:true})
            
            
            })
            
    }

        render () { 
            return ( 
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">Görev Listesi</h3>
                                    </div>
                                    <div className="panel-body"> 
                                    <DataTable
                                                        columns={this.state.type1}
                                                        data={this.state.type1_data}
                                                        fixedHeader
                                                        title={'Genel Sorumluluklar'}
                                                        overflowY={false}
                                                        overflowYOffset={'100px'}
                                                        highlightOnHover
                                                        responsive
                                                        striped
                                                        dense
                                                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                                        progressPending={!this.state.pageloader}
                                                        pagination={true}
                                                        paginationPerPage={20}
                                                        defaultSortField={'c_date'}
                                                        defaultSortAsc={false}

                                                    />
                                    <DataTable
                                                        columns={this.state.type2}
                                                        data={this.state.type2_data}
                                                        fixedHeader
                                                        title={'Özel Görevler'}
                                                        overflowY={false}
                                                        overflowYOffset={'100px'}
                                                        highlightOnHover
                                                        responsive
                                                        striped
                                                        dense
                                                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                                        progressPending={!this.state.pageloader}
                                                        pagination={true}
                                                        paginationPerPage={20}
                                                        defaultSortField={'c_date'}
                                                        defaultSortAsc={false}

                                                    />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    )
}
}

export default connect(mapStateToProps)(TaskListPersonel);