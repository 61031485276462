import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import {alertMessage,dateTime,datePrint} from '../../assets.js';
import $ from "jquery";
import dt from 'datatables.net-bs';

class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: false,
            data: []
        }
    }
    componentDidMount() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("token", this.props.loginToken);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(this.props.api_url + "/selectRows/view_user", requestOptions)
            .then(response => response.json())
            .then(result =>
                this.setState({data:result})
            )
            .catch(error => console.log('error', error));
    }
    componentDidUpdate() {
        $(document).ready( function () {
            $('#datatable').DataTable( {
                "language": {
                    "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Turkish.json",
                },
                "ordering": false
            } );
        });
    }
    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3 className="page-title">Kullanıcılar</h3>
                                </div>
                                <div className="col-md-6 text-right">
                                    <a href="/user/add" className="btn btn-primary">Ekle</a>
                                </div>
                                <div className="col-md-12">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <div className="panel-title">Kullanıcı Listesi</div>
                                        </div>
                                        <div className="panel-body">
                                            <table className="table table-bordered" id={"datatable"}>
                                                <thead>
                                                <tr>
                                                    <th>Adı Soyadı</th>
                                                    <th width="120">Marka</th>
                                                    <th width="200">Departman</th>
                                                    <th width="200">Kullanıcı Grubu</th>
                                                    <th width="80">Durum</th>
                                                    <th width="100">Kontrol</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.data.map((row,index)=>
                                                    <tr key={index}>
                                                        <td>{row.name} {row.surname} </td>
                                                        {row.brand != null?
                                                            <td>{row.brand}</td>
                                                            :
                                                            <td>-</td>
                                                        }
                                                        {row.department != null?
                                                            <td>{row.department}</td>
                                                            :
                                                            <td>-</td>
                                                        }
                                                        <td>{row.usertype}</td>
                                                        {row.status == 1?
                                                            <td className="text-center"><span className="label label-success">Aktif</span></td>
                                                            :
                                                            <td className="text-center"><span className="label label-danger">Pasif</span></td>
                                                        }
                                                        <td><a href={'/user/edit/' + row.id} className="btn btn-default btn-sm">Güncelle</a></td>
                                                    </tr>

                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="panel-footer text-center">
                                            Toplam {this.state.data.length} adet kayıt bulundu.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(UserList);