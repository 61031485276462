import React from 'react';
import {connect} from 'react-redux';
import DataTable from 'react-data-table-component';
import mapStateToProps from '../../redux/mapStateToProps';

const columns = [
    {
        name: 'Tarih',
        selector: 'invoice_date',
        left: true
    },
    {
        name: 'Belge No',
        selector: 'doc_number',
        left: true
    },
    {
        name: 'Fatura No',
        selector: 'inv_number',
        left: true
    },

    {
        name: 'Malzeme Adı',
        selector: 'id_com_product_name',
        left: true
    },
    {
        name: 'Depo Adı',
        selector: 'id_com_depot_name',
        left: true
    },
    {
        name: 'Alış Adedi',
        selector: 'purchase_quantity',
        left: true
    },
    {
        name: 'Kontrol',
        cell: row => (
            <a className="uk-button uk-button-primary uk-button-small" type="button"
               href={'/StoreCard/' + row.id + '/' + row.id_com_depot}>Seç</a>
        ),
        right: true
    }]

class DepotStore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageloader: false,
            SearchItem: ''
        }
        this._Update = this._Update.bind(this)
        this._Filter_Data = this._Filter_Data.bind(this)
    }
    componentDidMount() {
        this._Update()
    }
    _Update() {
        let product_url = this.props.api_url + '/selectRows/view_stock_lines';
        fetch(product_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_company=' + this.props.id_com_company + '&token=' + this.props.loginToken + '&type=user&stored_status=0'
        }).catch(error => console.log(error))
            .then(res => res.json())
            .then(result => {
               // console.log(result)
                let filtered = result.filter(row =>
                    row.id_com_invoice_type !== '2' && row.purchase_quantity !== '0');
                this.setState({data: filtered, pageloader: true})
            })
    }
    _Filter_Data(value) {
        this.setState({SearchItem: value})
        let data = this.state.data;
        let filtered_data = data.filter(function (row) {
            if (row.inv_number !== null) {
                return row.inv_number.includes(value.toUpperCase())
            } else {
                return null
            }
        })
        this.setState({filtered: filtered_data})
    }
    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="page-title">Depo Kabul</h3>
                                </div>
                                <div className="col-md-12">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <div className="panel-title">Kabul Edilecek Malzeme Listesi / Toplam Kalem ({this.state.data.length})</div>
                                        </div>
                                        <div className="panel-body">
                                            <DataTable
                                                columns={columns}
                                                data={this.state.SearchItem === '' ? this.state.data : this.state.filtered}
                                                fixedHeader
                                                noHeader={true}
                                                overflowY={false}
                                                overflowYOffset={'100px'}
                                                highlightOnHover
                                                responsive
                                                striped
                                                // dense
                                                paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                                progressPending={!this.state.pageloader}
                                                pagination={true}
                                                paginationPerPage={20}
                                                defaultSortField={'invoice_date'}
                                                defaultSortAsc={false}
                                                subHeader={true}
                                                subHeaderAlign={'right'}
                                                subHeaderComponent={
                                                    <React.Fragment>
                                                        <form className="input-group">
                                                            {this.state.SearchItem !== '' ?
                                                                <span className="input-group-btn"><button className="btn btn-primary" onClick={() => this.setState({SearchItem: '',filtered_data: []})}><i className="fas fa-list"></i></button></span>
                                                                :
                                                                <span className="input-group-addon"><i className="fas fa-search"></i></span>
                                                            }
                                                            <input className="form-control" value={this.state.SearchItem} type="search" placeholder="Arama..." onChange={(x) => this._Filter_Data(x.target.value)}/>
                                                        </form>
                                                    </React.Fragment>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(DepotStore);