import React ,{useState,useEffect} from 'react'; 
import {  Select } from 'antd'; 
import { connect } from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';

const { Option ,OptGroup} = Select;

const NewSelectList = (props) => { 
    
     const {
        loginToken,
        api_url,
        onChange,
        placeHolder,
        table,
        id_com_country,
        id_com_town,
        id_com_city, 
        id_com_user_type,
        disabled,
        value,
        multi,
        DisableSorting, 
        all, 
        wGroup,
        Exclude, 
        id_com_user
      } = props;

    const [Data,SetData ] = useState([]);
    const [SearchItem,SetSearchItem] = useState('')
    const [FilteredData,SetFilteredData] = useState([]);


    useEffect(() => {
         _Update();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
         _Update();
    }, [id_com_city]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        _Update();
    }, [id_com_town]);// eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        _Update();
    }, [id_com_country]);// eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        _Update();
    }, [id_com_user_type]);// eslint-disable-line react-hooks/exhaustive-deps

 
    useEffect(() => {
        _Update();
    }, [Exclude]);// eslint-disable-line react-hooks/exhaustive-deps
 
    useEffect(() => {
        _Update();
    }, [id_com_user]);// eslint-disable-line react-hooks/exhaustive-deps
 

  const  _Update = async () =>{
 
    const url = api_url+'/selectrows/'+table;
    const body = 'token='+loginToken+'&type=user';
    console.log(body)
    const config= {  method:'POST',  cahce: 'no-cache',  headers:{  'Content-Type':'application/x-www-form-urlencoded'   },  body:body };
    const response = await(await(fetch(url,config))).json();
     const _Sort = (a, b) => {
        var nameA = a.title.toUpperCase(); // ignore upper and lowercase
        var nameB = b.title.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
         return 0;
      }
    
    const excluded_data = Exclude===undefined?response:response.filter(row1=> !Exclude.find(row2=>row1.id===row2.id))
 
    switch(table){
        case 'view_city':
            DisableSorting? SetData(all?excluded_data:excluded_data.filter(row=>row.id_com_country===id_com_country)):
             SetData(all?excluded_data:excluded_data.filter(row=>row.id_com_country===id_com_country).sort(_Sort));
             break;
        
        case 'view_town':
            DisableSorting?  SetData(all?excluded_data:excluded_data.filter(row=>row.id_com_city===id_com_city)):
            SetData(all?excluded_data:excluded_data.filter(row=>row.id_com_city===id_com_city).sort(_Sort));
            break;

        case 'view_user_sl':
            DisableSorting?  SetData(all?excluded_data.filter(row=>row.id_com_user_type!=='3'):excluded_data.filter(row=>row.id_com_user_type===id_com_user_type)):
            SetData(all?excluded_data.filter(row=>row.id_com_user_type!=='3'):excluded_data.filter(row=>row.id_com_user_type===id_com_user_type).sort(_Sort));
            break;
        
        case 'view_customer_sl' : 
                 DisableSorting?SetData(excluded_data):SetData(excluded_data.sort(_Sort));
        break;


            
        

         default: 

               DisableSorting?SetData(excluded_data):SetData(excluded_data.sort(_Sort));

           break;
        }
    }

      

const _Filter= (e)=>{
     SetSearchItem(e)
    let match_str = new RegExp('('+e+')','i');
    let filteredData = Data.filter((row)=>{ return row.title.match(match_str) })
     SetFilteredData(filteredData)
 
}

const value_type = typeof value;
const select_value = !multi?value: value_type==='string'?value.split(','):value

if(wGroup)
    {
         const groups = []
        Data.map(row=>groups.push(row._Group))
        const u_groups=[...new Set(groups)]
         return ( 
            <Select  size='small'  
                    mode={multi?'multiple':''}
                    style={{ width: '100%',textAlign:'right' }} onChange={onChange} placeholder={placeHolder} filterOption={false} showSearch={true} onSearch={_Filter}  disabled={disabled} value={select_value}>
                           {u_groups.map((group_name,idx)=>{
                                const group_data  =   SearchItem===''?Data.filter(row=>row._Group===group_name):FilteredData.filter(row=>row._Group===group_name)
                               return (
                                <OptGroup key={idx} label={group_name}>
                                    { 
                                        group_data.map((row)=>{
                                                return ( 
                                                    <Option key={row.id} name={row.title} value={row.id}>{row.title}</Option>
                                                )
                                            })
                                    }
                                </OptGroup>
                               )
                           }


                           )}
                           
                    </Select>
        
            )

    } 
else{
    return ( 

        <Select  size='small' 
        mode={multi?'multiple':''}
        style={{ width: '100%',textAlign:'right' }} onChange={onChange} placeholder={placeHolder} filterOption={false} showSearch={true} onSearch={_Filter}  disabled={disabled} value={select_value}>
                <Option value={'0'}>Seçiniz</Option>
                    { SearchItem===''?
                    Data.map((row,index)=>{
                        return ( 
                            <Option key={index+1} name={row.title} value={row.id}>{row.title}</Option>
                        )
                    })
                    :
                    FilteredData.map((row,index)=>{
                        return ( 
                            <Option key={index+1} name={row.title} value={row.id}>{row.title}</Option>
                        )
                    })
                    
                    }
        </Select>
    
        )

}

}

export default connect(mapStateToProps)(NewSelectList);

 