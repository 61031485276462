import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component';
const detail_columns = [{
    name: 'Tarih',
    selector: 'invoice_date',
    left: true
}, {
    name: 'Depo',
    selector: 'id_com_depot_name',
    left: true
}, {
    name: 'Fatura Tipi',
    selector: 'id_com_invoice_type_name',
    left: true
}, {
    name: 'Fatura No',
    selector: 'inv_number',
    grow: 2,
    left: true
}, {
    name: 'Fatura Tipi',
    selector: 'id_com_invoice_type_name',
    left: true
}, {
    name: 'Fatura Durumu',
    selector: 'id_com_invoice_status_name',
    left: true
}, {
    name: 'Alım Adedi',
    selector: 'purchase_quantity',
    left: true
}, {
    name: 'Alım Tutarı',
    selector: 'purchase_price',
    left: true
}, {
    name: 'Depo Rafı',
    selector: 'id_depot_part_number_name',
    grow: 2,
    left: true
}, {
    name: 'Satış Adedi',
    selector: 'sale_quantity',
    left: true
}, {
    name: 'Satış Tutarı',
    selector: 'sale_price',
    left: true
}, {
    name: 'Müşteri Adı',
    selector: 'company_name',
    left: true
}, {
    name: 'Yetkili',
    selector: 'username',
    left: true
}]
class ProductSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoader: false,
            searchItem: '',
            ProductList: [],
            show_detail: false,
            productLoaded:true,
            selectedProduct: '',
            detail_data: []
        }
        this.SearchProduct = this.SearchProduct.bind(this)
        this._show_detail = this._show_detail.bind(this)
    }
    _show_detail(id) {
        let filtered_list = this.state.ProductList.filter(row => row.id === id)
        this.setState({ProductList: filtered_list})
        let detail_url = this.props.api_url + '/selectRows/view_stock_lines';
        fetch(detail_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'id_com_product=' + id + '&id_com_company=' + this.props.id_com_company + '&token=' + this.props.loginToken + '&type=user'
        }).then(res => res.json())
            .then(result => {
                this.setState({detail_data: result})
                //    console.log(result)
            })
    }
    SearchProduct() {
        let product_url = this.props.api_url + '/productSearch/' + this.props.id_com_company;
        fetch(product_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'token=' + this.props.loginToken + '&type=user&match=' + this.state.searchItem + '&id_com_product_type=1'
        }).then(res => res.json())
            .then(result => {
                let data = result.filter(row => row.id_com_product_type === '1' || row.id_com_product_type === '4')
                this.setState({ProductList: data, productLoaded: true})
            })
        if (this.searchItem === '') {
            this.setState({ProductList: '', productLoaded: false, avg_cost: '',})
        } else {
            return null
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="page-title">Parça Sorgula</h3>
                                </div>
                                <div className="col-md-12">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <div className="panel-title">Parça Sorgula</div>
                                        </div>
                                        <div className="panel-body">
                                            <div className="form-group">
                                                <input value={this.state.searchItem} className="form-control" type="text" placeholder="Malzeme Adı - Malzeme Kodu" onChange={(x) => {this.setState({searchItem: x.target.value});this.SearchProduct()}}/>
                                            </div>
                                            {this.state.productLoaded === true ?
                                                <table className="table table-bordered table-striped ">
                                                    <thead>
                                                    <tr>
                                                        <th>Malzeme Kodu</th>
                                                        <th>Oem Kodu</th>
                                                        <th>Sınıf</th>
                                                        <th>Grup</th>
                                                        <th>Malzeme Adı</th>
                                                        <th>Depo Adı</th>
                                                        <th>Stok Adedi</th>
                                                        <th>Liste Fiyati (KDV hariç)</th>
                                                        <th>Kontrol</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.searchItem === '' ?
                                                        <></>
                                                    :this.state.ProductList.map((row, index) =>
                                                        <tr key={index}>
                                                            <td>{row.code}</td>
                                                            <td>{row.oem_code}</td>
                                                            <td>{row.id_com_product_type_name}</td>
                                                            <td>{row.id_com_product_class_name}</td>
                                                            <td>{row.text}</td>
                                                            <td>{row.id_com_depot_name}</td>
                                                            <td>{row.stock_level}</td>
                                                            <td>{row.list_price + ' TL'}</td>
                                                            <td><button type="button" onClick={() => {this.setState({show_detail: true,productLoaded:false, selectedProduct: row.id});this._show_detail(row.id)}} className="btn btn-primary btn-sm btn-block">Detay</button></td>
                                                        </tr>
                                                        )
                                                    }
                                                    </tbody>
                                                </table>
                                            :
                                                <></>
                                            }
                                            {!this.state.show_detail ?
                                                    <></>
                                                :
                                                <div>
                                                    <table className="table table-bordered table-striped">
                                                        <thead>
                                                            <tr className="active">
                                                                <th colSpan="10">Parça Detayı</th>
                                                            </tr>
                                                            <tr>
                                                                <th>Malzeme Kodu</th>
                                                                <th>Oem Kodu</th>
                                                                <th>Sınıf</th>
                                                                <th>Grup</th>
                                                                <th>Malzeme Adı</th>
                                                                <th>Depo Adı</th>
                                                                <th>Stok Adedi</th>
                                                                <th>Liste Fiyati (KDV hariç)</th>
                                                                <th>Toplam Maliyet</th>
                                                                <th>Ortalama Maliyet</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.searchItem === '' ?
                                                                <></>
                                                            :this.state.ProductList.map((row, index) =>
                                                                <tr key={index}>
                                                                    <td>{row.code}</td>
                                                                    <td>{row.oem_code}</td>
                                                                    <td>{row.id_com_product_type_name}</td>
                                                                    <td>{row.id_com_product_class_name}</td>
                                                                    <td>{row.text}</td>
                                                                    <td>{row.id_com_depot_name}</td>
                                                                    <td>{row.stock_level}</td>
                                                                    <td>{row.list_price + ' TL'}</td>
                                                                    <td>{Math.round((parseFloat(row.avg_cost) * parseFloat(row.stock_level)) * 100) / 100 + ' TL'}</td>
                                                                    <td>{row.avg_cost + ' TL'}</td>
                                                                </tr>
                                                                )
                                                            }
                                                            </tbody>
                                                        </table>
                                                        <DataTable
                                                            title={"Parça Hareketleri"}
                                                            columns={detail_columns}
                                                            data={this.state.detail_data}
                                                            fixedHeader
                                                            noHeader={true}
                                                            overflowY={false}
                                                            overflowYOffset={'100px'}
                                                            highlightOnHover
                                                            responsive
                                                            defaultSortField="invoice_date"
                                                            striped
                                                            // dense
                                                            paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                                            pagination={true}
                                                            paginationPerPage={20}
                                                            defaultSortAsc={true}
                                                        />
                                                    <div className="form-group margin-top-30">
                                                        <button className="btn btn-danger" onClick={() => this.setState({show_detail: false, productLoaded:true, selectedProduct: '', searchItem: '', detail_data: [], ProductList: [],})}>Yeni Arama</button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(ProductSearch);       