import React,{useState,useEffect} from 'react';
import {  Col, Row, Spin,Typography,Button,Divider,Empty,Menu,Dropdown ,Modal,Tabs,Table,PageHeader ,Input,DatePicker,Select, Tag} from 'antd'; 
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import 'moment/locale/tr';
import moment from 'moment'
import ExportFromJson from '../utilities/jsontoexport';
 import { RetweetOutlined} from '@ant-design/icons';
 
const { Title ,Text} = Typography;
const { TabPane} = Tabs;
const { Option } = Select;


const  ReportCrmT1Forward =({api_url,loginToken})=> {
    const [Data,setData] = useState([]);
    const [FilteredData,SetFilteredData]= useState([]);
    const Months = [1,2,3,4,5,6,7,8,9,10,11,12];
    const Years = [2019,2020,2021,2022,2023,2024];
    const [Brands,setBrands] = useState([])
    const [SelectedMonth,setSelectedMonth] = useState('Tümü');
    const [SelectedYear,setSelectedYear] = useState('Tümü');
    const [SelectedBrand,setSelectedBrand] = useState('Tümü');
    const [Loading,setLoading]  = useState(true);
    const [UserData,setUserData] = useState([])
    const [MeetData,setMeetData] = useState([])
    const [SearchItem,SetSearchItem] = useState('');
 
    const Columns =[
        {
            title:'Başlık',
            dataIndex:'name',
            fixed:'left',
        },
        {
            title:'Yönlendirilen',
            dataIndex:'forwarded',
            align:'center',
        },
        {
            title:'Hatırlatılan',
            dataIndex:'next_fowarded',
            align:'center',
        },
        {
            title:'Cevaplanan',
            dataIndex:'responsed',
            align:'center',
        },
        {
            title:'Açık',
            dataIndex:'open',
            align:'center',
        },
        {
            title:'Kapatılan',
            dataIndex:'closed',
            align:'center',
        },
        {
            title:'Kvkk Onay',
            dataIndex:'kvkk',
            align:'center',
        },

        
    ]
    const TableColumn =[ 
            {
                title:'No',
                dataIndex:'interview_number',
                align:'left',
            },
            {
                title:'Marka',
                dataIndex:'id_com_brand_name',
                align:'center',
            },
            {
                title:'Model',
                dataIndex:'id_com_model_name',
                align:'center',
            },
            {
                title:'Durum',
                dataIndex:'status_name',
                align:'left',
            },
            {
                title:'Tekrar Yönlendirilen',
                 align:'center',
                 render : (row,record) => record.next_forwarded==='1' && <Tag color="red">Evet</Tag>

            },
            {
                title:'Tarih',
                dataIndex:'c_date',
                 align:'center',
                 render : (row,record) => moment(record.c_date).format('DD.MM.YYYY')

             },
             {
                title:'Saat',
                dataIndex:'c_time',
                 align:'center',
 
             },
            {
                title:'Temas',
                dataIndex:'id_com_meet_name',
                align:'center',
             },
             {
                title:'Temsilci',
                dataIndex:'id_com_user_name',
                align:'center',
             },

             {
                title:'Müşteri',
                dataIndex:'username',
                align:'left',
             },
             {
                title:'Kvkk',
                dataIndex:'kvkk_status', 
                align:'center',
                render : (row,record) => record.kvkk_status==='1' ? <Tag color="green">Var</Tag>:<Tag color="red">Yok</Tag>

             },
             {
                title:'Sonuç',
                dataIndex:'id_com_interview_status_name',
                align:'center',
             },
             {
                title:'Git',
                align:'center',
                render : (row,record) => <Button type='primary' shape='round' size='small' href={'/interview/Detail/'+record.id} target='blank_'>Seç</Button>
             },
              
        ]
        const excelColumns =[ 
            {
                name:'No',
                selector:'interview_number',
             },
            {
                name:'Marka',
                selector:'id_com_brand_name',
             },
            {
                name:'Model',
                selector:'id_com_model_name',
             },
            {
                name:'Durum',
                selector:'status_name',
             },
            {
                name:'Tekrar Yönlendirilen',
                selector:'status_name',
 
            },
            {
                name:'Tarih',
                selector:'c_date', 

             },
             {
                name:'Saat',
                selector:'c_time', 
 
             },
            {
                name:'Temas',
                selector:'id_com_meet_name', 
             },
             {
                name:'Temsilci',
                selector:'id_com_user_name', 
             },

             {
                name:'Müşteri',
                selector:'username', 
             },
             {
                name:'Telefon',
                selector:'phone1', 
             },
             {
                name:'Kvkk',
                selector:'kvkk_status', 
             },
             
             {
                name:'Sonuç',
                selector:'id_com_interview_status_name', 
             },
                    
        ]

    useEffect(() => {
        const FetchData = ()=>{
            const year = moment(new Date()).format('YYYY');
            const month =  new Date().getMonth()+1
            _GetData(month,year,'Tümü')
        }
       return FetchData();
   }, [])

   const _GetData =  async (month,year,id_com_brand_name) =>{
    setLoading(true)
    setSelectedYear(year);
    setSelectedMonth(month); 
    setSelectedBrand(id_com_brand_name)
    let url= api_url+'/selectrows/view_interview_forwarded';
    let body1 = 'token='+loginToken+'&type=user&year_='+year+'&month_='+month;
    let body2 = 'token='+loginToken+'&type=user&year_='+year+'&month_='+month+'&id_com_brand_name='+id_com_brand_name;
    let config = {  method:'POST',    cahce: 'no-cache',    headers:{     'Content-Type':'application/x-www-form-urlencoded'     },   body: id_com_brand_name==='Tümü'?body1:body2 }
    let result = await (await fetch(url,config)).json(); 
    const brands = ['Tümü'];
    const users = [];
    const status = [];
    const meet = [];

    result.map(row=>{
        brands.push(row.id_com_brand_name);
        users.push(row.id_com_user_name);
        status.push(row.id_com_interview_status_name);
        meet.push(row.id_com_meet_name);
    })
    const u_users = [...new Set(users)]
    const u_status = [...new Set(status)]
    const u_meet = [...new Set(meet)]
    _Transpose(result,u_users,u_meet);
    setBrands([...new Set(brands)])
    setLoading(false)
   }

   const _Transpose = (result,u_users,u_meet)=>{

    let user_data = []
    let meet_data = []
    
    u_users.map(user=>{
       const  user_filtered =  result.filter(row=>row.id_com_user_name===user) 
         let row = {
            name:user
        }

        Object.assign(row,{
            forwarded:user_filtered.filter(row=>row.forwarded==='1').length,
            next_fowarded: user_filtered.filter(row=>row.next_forwarded==='1').length,
            responsed: user_filtered.filter(row=>row.ls_id_com_user_name===user).length,
            open:user_filtered.filter(row=>row.status==='0').length,                   
            closed: user_filtered.filter(row=>row.status==='1').length, 
            kvkk:user_filtered.filter(row=>row.kvkk_status==='1').length,                   

        })
        user_data.push(row)
    })

    u_meet.map(meet=>{
        const  meet_filtered =  result.filter(row=>row.id_com_meet_name===meet) 
          let row = {
             name:meet
         }
 
         Object.assign(row,{
             forwarded:meet_filtered.filter(row=>row.forwarded==='1').length,
             next_fowarded: meet_filtered.filter(row=>row.next_forwarded==='1').length,
             open:meet_filtered.filter(row=>row.status==='0').length,      
             kvkk:meet_filtered.filter(row=>row.kvkk_status==='1').length,                   

             closed: meet_filtered.filter(row=>row.status==='1').length,
         })
         meet_data.push(row)
     })


    setData(result.reverse());
    setUserData(user_data);
    setMeetData(meet_data);

   }

 

const _Filter =(e)=>{
    SetSearchItem(e)
    let match_str = new RegExp('('+e+')','i');
    let filteredData = Data.filter((row)=>{ 
        return row.interview_number.match(match_str)||row.id_com_brand_name.match(match_str)||row.id_com_model_name.match(match_str)||row.status_name.match(match_str)||row.id_com_meet_name.match(match_str)||row.id_com_user_name.match(match_str)||row.username.match(match_str)||row.id_com_interview_status_name.match(match_str)
    })
    SetFilteredData(filteredData)
}

const SelecPicker = ({selectData,Change,disabled,Value,width}) => { 

    return (
    <Select style={{width:width}} onChange={Change}   disabled={disabled} defaultValue={Value}  >
          {
            selectData.map((row,index)=>{
                return ( 
                    <Option key={index}   value={row}>{row}</Option>
                )
            })
         }
</Select>
    )
}

    return(
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <Row>
                                        <Col span={24}>
                                            <PageHeader title='Sıfır Satış |' subTitle='Yönlendirilen Görüşmeler' 
                                            extra={[
                                                <SelecPicker key='select1' selectData={Months} Change={(x)=>{_GetData(x,SelectedYear,SelectedBrand)}} Value={SelectedMonth} width='150px'/>,
                                                <SelecPicker key='select2' selectData={Years} Change={(x)=>{_GetData(SelectedMonth,x,SelectedBrand)}} Value={SelectedYear} width='150px'/>,
                                                <SelecPicker key='select3' selectData={Brands} Change={(x)=>{_GetData(SelectedMonth,SelectedYear,x)}} Value={SelectedBrand} width='150px'/>,
                                                <Button type='primary' shape='round' onClick={()=>_GetData(SelectedMonth,SelectedYear,SelectedBrand)} loading={Loading} icon={<RetweetOutlined />}>Güncelle</Button>
                                            ]}
                                            />
                                        </Col>
                                    </Row>
                                    <Divider></Divider>
                                    <Row justify='center' align='top' gutter={24} style={{padding:20}}>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24} >
                                            <Table dataSource={UserData} size='small' pagination={false} columns={Columns} loading={Loading}
                                                footer={()=><Text code danger>{moment(new Date()).format('DD.MM.YYYY HH:SS')} tarihinde güncellenmiştir. </Text>}
                                                summary={pageData =>
                                                    <Table.Summary.Row style={{backgroundColor:'#bdbebf'}}>
                                                        <Table.Summary.Cell     align='right'><Text style={{fontSize:14,textAlign:'right'}} strong >TOPLAM</Text></Table.Summary.Cell>

                                                        {Columns.map((row,idx)=>{
                                                            if(idx!==0){
                                                                return ( 
                                                                    <Table.Summary.Cell align='center'> <Text style={{fontSize:14,textAlign:'center'}} strong >{pageData.reduce((a,b)=>a+parseFloat(b[row.dataIndex]),0)}  </Text></Table.Summary.Cell>
                                                                )
                                                            }
                                                        })}
                                                    </Table.Summary.Row>

                                                }
                                            />
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24} >
                                            <Table dataSource={MeetData} size='small' pagination={false} columns={Columns}  loading={Loading}
                                                    footer={()=><Text code danger>{moment(new Date()).format('DD.MM.YYYY HH:SS')} tarihinde güncellenmiştir. </Text>}
                                                    summary={pageData =>
                                                    <Table.Summary.Row style={{backgroundColor:'#bdbebf'}}>
                                                        <Table.Summary.Cell     align='right'><Text style={{fontSize:14,textAlign:'right'}} strong >TOPLAM</Text></Table.Summary.Cell>
                                                        {Columns.map((row,idx)=>{
                                                            if(idx!==0){
                                                                return ( 
                                                                    <Table.Summary.Cell align='center'> <Text style={{fontSize:14,textAlign:'center'}} strong >{pageData.reduce((a,b)=>a+parseFloat(b[row.dataIndex]),0)}  </Text></Table.Summary.Cell>
                                                                )
                                                            }
                                                        })}
                                                    </Table.Summary.Row>

                                                }

                                            />
                                        </Col>
                                    </Row>
                                     <PageHeader   
                                        extra={[ 
                                            <ExportFromJson data={SearchItem===''?Data:FilteredData} fileName={`Yonlendirilen_Gorusmeler_${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}`} exportType={'xls'}/>

                                        ]}
                                     
                                     subTitle='Görüşmeler'/>
                                    <Row justify='center' align='top' gutter={24} style={{padding:20}}>
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24} >
                                             <Input.Search  placeholder='Arama Yapınız'  onChange={(x)=>_Filter(x.target.value)} loading={SearchItem!==''}/>
                                        </Col>
                                    </Row>
                                    <Row justify='center' align='top' gutter={24} style={{padding:20}}>
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24} >
                                            <Table dataSource={SearchItem===''?Data:FilteredData} size='small' pagination={{pageSize:10}} columns={TableColumn}  loading={Loading} 
                                                  footer={()=><Text code danger>{moment(new Date()).format('DD.MM.YYYY HH:SS')} tarihinde güncellenmiştir. </Text>}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default connect(mapStateToProps)(ReportCrmT1Forward);