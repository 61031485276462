import React from 'react';
import {connect} from 'react-redux';
import DataTable from 'react-data-table-component';
import mapStateToProps from '../../redux/mapStateToProps';
const columns =[
      {
          name: 'Randevu No',
          selector: 'id',
          left:true,
          width:'100px'
      },
      {
          name: 'Tarih',
          selector: 'app_date',
          left:true,
          cell : row =>(
              row.app_date.split('-')[2] + '.' +
              row.app_date.split('-')[1] + '.' +
              row.app_date.split('-')[0]
          ),
      },
      {
          name: 'Saat',
          selector: 'app_time',
          left:true
      },
      {
          name: 'Islem Tipi',
          selector: 'id_com_service_app_type_name',
          left:true
      },
      {
          name: 'Ruhsat Sahibi',
          selector: 'id_owner_username',
          left:true
      },
      {
          name: 'Plaka',
          selector: 'plate_number',
          left:true
      },
      {
          name: 'Marka',
          selector: 'id_com_brand_name',
          left:true
      },
      {
          name: 'Model',
          selector: 'id_com_model_name',
          left:true
      },
      {
          name: 'Durum',
          selector: 'id_com_service_app_status_name',
          left:true
      }]
class ApproveComponent extends React.Component{
    constructor(props){
        super(props);
        this.state={}
        this._Confirm=this._Confirm.bind(this);
        this._Decline=this._Decline.bind(this)
    }
    _Decline(){
        const id_com_user =   this.props.id_com_user;
        let d = new Date();
        const date = d.toISOString();
        const m_date  = date.split('T')[0];
        const m_time = d.toLocaleTimeString('tr-TR');
        const id_owner_pushToken = this.props.data.id_owner_pushToken;
        const plate_number = this.props.data.plate_number;
        const update_decline=
            'id='+ this.props.data.id+
            '&id_com_service_app_status=5'+
            '&id_com_user='+id_com_user+
            '&m_id_com_user='+id_com_user+
            '&token='+this.props.loginToken+
            '&type=user'+
            '&m_date='+m_date+
            '&m_time='+m_time;
        const reject_url= this.props.api_url+'/update/com_service_app';
        let notification_url = this.props.api_url+'/notification';
        console.log(this.props.data)
        fetch(reject_url, {
            method: 'POST',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body: update_decline,
        })
            .catch(error=> console.log(error))
            .then(function(res){
                if(id_owner_pushToken!=null) {
                    let  body =  plate_number+' plakalı aracınızın randevusu red edilmiştir.';
                    let title  =  'Randevunuz Red Edilmiştir! / '+m_time;
                    let json = JSON.stringify({
                        to: id_owner_pushToken,
                        title: title,
                        body: body
                    })
                    fetch(notification_url, {
                        method: 'POST',
                        headers:  {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: 'payload='+json,
                    }).then(function(response) {
                        console.log('notification to customer sent!')
                    })} else {
                    console.log('no customer token')
                }
                window.location.reload();
            })
    }
    _Confirm(){
        const id_com_user =   this.props.id_com_user;
        let d = new Date();
        const date = d.toISOString();
        const m_date  = date.split('T')[0];
        const m_time = d.toLocaleTimeString('tr-TR');
        const id_owner_pushToken = this.props.data.id_owner_pushToken;
        const plate_number = this.props.data.plate_number;
        const update_submit=
            'id='+ this.props.data.id+
            '&id_com_user='+id_com_user+
            '&m_id_com_user='+id_com_user+
            '&token='+this.props.loginToken+
            '&m_date='+m_date+
            '&m_time='+m_time+
            '&type=user';
        const confirm_url= this.props.api_url+'/update/com_service_app'
        let notification_url = this.props.api_url+'/notification'
        console.log(this.props.data)
        fetch(confirm_url, {
            method: 'POST',
            headers:  {
                'Content-Type':  'application/x-www-form-urlencoded'
            },
            body: update_submit,
        })
            .catch(error=> console.log(error))
            .then(function(res){
                if(id_owner_pushToken!=null) {
                    let body = plate_number +' plakalı aracınızın randevusu onaylanmıştır.';
                    let title  =  'Randevunuz onaylanmıştır! / '+m_time;
                    let json = JSON.stringify({
                        to: id_owner_pushToken,
                        title: title,
                        body: body
                    })
                    fetch(notification_url, {
                        method: 'POST',
                        headers:  {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: 'payload='+json,
                    }).then(function(response) {
                        console.log('notification to customer sent!')
                    })} else {
                    console.log('no customer token')
                }
            })
        window.location.reload();
    }
    render() {
        return (
            <div className="panel">
                <div className="panel-body text-center">
                    <p className="lead">{this.props.data.id} numaralı randevu ile ilgili işlem yapınız.</p>
                    <button type="button" className="btn btn-danger" onClick={()=>this._Decline()}> Reddet</button> <button type="button" className="btn btn-primary" onClick={()=>this._Confirm()}> Onayla</button>
                </div>
            </div>
        )
    }
}
class AppConfirm extends React.Component {
    constructor(props){
        super(props);
        this.state={
            pageloader:false,
            data:[],
        }
        this._Update=this._Update.bind(this)
    }
    componentDidMount() {
        this._Update();
    }
    _Update(){
        let select_url=this.props.api_url+'/selectRows/view_service_app';
        let postData=
            'id_com_user=0'+
            '&id_com_service_app_status=1'+
            '&token='+this.props.loginToken+'&type=user'+
            '&id_com_company='+this.props.id_com_company;
        fetch(select_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            },
            body: postData
        }).then(res=>res.json())
            .then(result=>{
                this.setState({data:result,pageloader:true})
            })
    }
    render () {
        return(
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">Onay Bekleyen Randevular <span className="badge">{this.state.data.length}</span></h3>
                                    </div>
                                    <div className="panel-body">
                                        <DataTable
                                            noTableHead={true}
                                            columns={columns}
                                            data={this.state.data}
                                            fixedHeader
                                            overflowY
                                            highlightOnHover
                                            responsive
                                            defaultSortField="app_date"
                                            striped
                                            // dense
                                            paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                            progressPending={!this.state.pageloader}
                                            pagination={true}
                                            expandableRows={true}
                                            expandableRowsComponent={<ApproveComponent id_com_user={this.props.id_com_user} loginToken={this.props.loginToken} api_url={this.props.api_url}/>}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(mapStateToProps)(AppConfirm);