import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import $ from 'jquery';
import dt from 'datatables.net-bs';
import moment from "moment";
class ServiceAppList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageloader: false,
            data: []
        }
        this._Update = this._Update.bind(this);
    }
    componentDidMount() {
        this._Update();
    }
    _Update() {
        let service_app_url = this.props.api_url + '/selectRows/view_service_app';

        fetch(service_app_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'token=' + this.props.loginToken + '&type=user&id_com_brand=' + this.props.id_com_brand,
        })
            .catch(error => console.log(error)).then(x => x.json()).then(result => {
            this.setState({data: result, pageloader: true})
        })
    }
    componentDidUpdate() {
        $(document).ready( function () {
            $('#datatable').DataTable( {
                "language": {
                    "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Turkish.json",
                },
                "ordering": false
            } );
        });
    }
    render() {
        return (
            <div className="main">
                <div className="main-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <h3 className="page-title">Servis Randevuları</h3>
                            </div>
                            <div className="col-md-6 text-right">
                                <a href="/ServiceApp/Add/0" className="btn btn-primary">Ekle</a>
                            </div>
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">Randevu Listesi</h3>
                                    </div>
                                    <div className="panel-body">
                                        <table className="table table-bordered restable" id={"datatable"}>
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Müşteri Adı</th>
                                                    <th>Araç Bilgisi</th>
                                                    <th>Plaka</th>
                                                    <th>Randevu Tipi</th>
                                                    <th>Randevu Tarihi</th>
                                                    <th>Randevu Saati</th>
                                                    <th>Durum</th>
                                                    <th>Danışman</th>
                                                    <th>Kontrol</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.data.map((row,index)=>
                                                <tr key={index}>
                                                    <td>{row.id}</td>
                                                    <td>{row.id_com_customer_name}</td>
                                                    <td>{row.id_com_brand_name + ' - ' + row.id_com_model_name + ' / ' + row.model_year}</td>
                                                    <td>{row.license_plate}</td>
                                                    <td>{row.id_com_service_app_subject_name}</td>
                                                    <td>{moment(row.app_date,'YYYY-MM-DD').format('DD.MM.YYYY')}</td>
                                                    <td>{row.timer}</td>
                                                    <td><span className={row.id_com_service_app_status==='1'?'label label-warning':row.id_com_service_app_status==='2'?'label label-success':'label label-danger'}> {row.id_com_service_app_status_name}</span></td>
                                                    <td>{row.id_user_register_username}</td>
                                                    <td><a href={'/ServiceApp/Details/' + row.id} className="btn btn-primary btn-block btn-sm">Seç</a></td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(mapStateToProps)(ServiceAppList);