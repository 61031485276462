

import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component'; 
import * as CurrencyFormat from 'react-currency-format';
import moment from 'moment'
import SelectList from '../utilities/SelectList';
import Chart from "react-apexcharts";
import MetricsSaleReport from '../../components/utilities/Metris.salereport';

class ReportSale  extends React.Component {


    constructor(props){
        super(props);
        this.state={
            pageloader:false,
            
            datatype1:[],
            data_sum:[],
            data_sum_columns:[
              {
                name: 'Arama Sonucu',
                selector: 'id_com_interview_status_name',
                left:true,
                wrap:true,
                width:'200px',

                },
                {
                name: 'Adet',
                selector: 'adet',
                left:true,
                wrap:true,
                },  
  
                {
                name: 'Oran',
                selector: 'oran',
                left:true,
                wrap:true,
                cell:(row)=>
                             <CurrencyFormat value={parseFloat(row.oran)} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={' % '}/>
                },
            ],
            datatype1_columns:[
                {
                name: 'Miy',
                selector: 'id_com_user_name',
                left:true,
                wrap:true,
                width:'150px',
                },
                {
                name: 'Adet',
                selector: 'adet',
                left:true,
                wrap:true,
                },
                {
                name: 'Oran',
                selector: 'oran',
                left:true,
                wrap:true,
                cell:(row)=>
                             <CurrencyFormat value={parseFloat(row.oran)} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={' % '}/>
                },
                {
                name: 'Test Sürüşü',
                selector: 'testdrive',
                left:true,
                wrap:true,
                },
                {
                  name: 'Satış',
                  selector: 'satis',
                  left:true,
                  wrap:true,
                  },
            ],
            data_sum_meet:[],
            data_sum_meet_columns:[
             
                {
                name: 'Temas Türü',
                selector: 'id_com_meet_name',
                left:true,
                wrap:true,
                width:'150px',
                },
                {
                name: 'Adet',
                selector: 'adet',
                left:true,
                wrap:true,
                },
                {
                  name: 'Oran',
                  selector: 'oran',
                  left:true,
                  wrap:true,
                  cell:(row)=>
                               <CurrencyFormat value={parseFloat(row.oran)} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={' % '}/>
                  },
                {
                name: 'Test Sürüşü',
                selector: 'testdrive',
                left:true,
                wrap:true,
                },
                {
                name: 'Satış',
                selector: 'satis',
                left:true,
                wrap:true,
                },
                {
                name: 'Toplam Adet',
                selector: 'toplam_adet',
                left:true,
                wrap:true,
                },
  
      

            ],
            data_monthly:[],
            stocks:[],
            stocks_column:[
              {
              name: 'Marka',
              selector: 'id_com_brand_name',
              left:true,
              wrap:true,
              },
              {
              name: 'Model',
              selector: 'id_com_model_name',
              left:true,
              wrap:true,
              },
              {
              name: 'Stokta',
              selector: 'stokta',
              left:true,
              wrap:true,
              },
              {
              name: 'Bağlantı',
              selector: 'baglanti',
              left:true,
              wrap:true,
              },
              {
              name: 'Toplam Stok',
              selector: 'toplam',
              left:true,
              wrap:true,
              },
            ],
            monthly_data_kia:[],
            monthly_data_hyundai:[],
            bar_options :{
                chart: {
                     type: 'bar',
                    events: {
                      click: function(event, chartContext, config) {
                        if( (config.dataPointIndex+1)===0){ 
                          this.setState({current_month:moment().subtract(-1, 'months').month()})
                        } else  { 
                          this.setState({current_month:config.dataPointIndex+1})
                        }
                            // console.log(config.dataPointIndex+1); 
                      }.bind(this),
                    //   dataPointSelection: function(event, chartContext, config){

                    //     console.log(event);
                    // }
                    },
                },
                plotOptions: {
                  bar: {
                    columnWidth:'50%',
                    endingShape: 'rounded',  

                    dataLabels: {
                      position: 'top', // top, center, bottom
                    },  
                    stroke: {
                      width: 2
                    },
                  }
                },
                xaxis: {
                  tickPlacement: 'on',
         
                    categories: [  'OCA', 'ŞUB', 'MAR', 'NIS', 'MAY', 'HAZ', 'TEM', 'AGU',   'EYL', 'EKI', 'KAS','ARA'],
                    position: 'top',
                    axisBorder: {
                      show: false
                    },
                    axisTicks: {
                      show: false
                    },
                    crosshairs: {
                      fill: {
                        type: 'gradient',
                        gradient: {
                          colorFrom: '#D8E3F0',
                          colorTo: '#BED1E6',
                          stops: [0, 100],
                          opacityFrom: 0.4,
                          opacityTo: 0.5,
                        }
                      }
                    },

                },
                // title: {
                //     text: 'Arama Adetleri',
                //     align: 'center',
                //     style: {
                //       color: '#444'
                //     }
                            
                //   },
                  dataLabels: { 
                    enabled: false,
                  }, 
                 
                },
                monthly_data :[  ]
        }
        this.handleChange=this.handleChange.bind(this)
    }

    

    handleChange(event,selectlist,name) {
        if(selectlist) { 
             this.setState({ [`${name}`]:event.target.value})
        } 
        else { 

         if(event.target.type==='checkbox'){ 
               event.target.checked?this.setState({[`${event.target.name}`]:'1'}):this.setState({[`${event.target.name}`]:'0'})
                        } else {
            const value = event.target.value;
            const key = event.target.name;
            this.setState({ [`${key}`]:value})
         } 
        }
         
       
         
    }

   async  componentDidMount(){
    // let current_month = moment().subtract(-1, 'months').month();
    // let current_year= moment().subtract(-1, 'months').year();
   var d = new Date();
   var current_month = d.getMonth()+1
   var current_year = d.getFullYear()
    let interview_1_url=this.props.api_url+'/selectRows/view_report_interview_type1';

     const data = await( await   fetch(interview_1_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
              body:'token='+this.props.loginToken+'&type=user',
            }).catch(error=>console.log(error))).json();


    let interview_1_sum_url=this.props.api_url+'/selectRows/view_report_interview_type1_sum';

    const data_sum = await( await   fetch(interview_1_sum_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
              body:'token='+this.props.loginToken+'&type=user',
            }).catch(error=>console.log(error))).json();

    let interview_1_sum_meet_url=this.props.api_url+'/selectRows/view_report_interview_type1_meet_sum';

    const data_sum_meet = await( await   fetch(interview_1_sum_meet_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
              body:'token='+this.props.loginToken+'&type=user',
            }).catch(error=>console.log(error))).json();


      let interview_1_monthly_url=this.props.api_url+'/selectRows/view_report_interview_type1_monthly';

      const data_monthly = await( await   fetch(interview_1_monthly_url,{
                  method:'POST',
                  cahce: 'no-cache',
                  headers:{ 
                    'Content-Type':'application/x-www-form-urlencoded'
                  },
                body:'token='+this.props.loginToken+'&type=user&year_='+current_year,
              }).catch(error=>console.log(error))).json();
    
              
    
    let stocks_url=this.props.api_url+'/selectRows/view_stock_report';

    const stocks = await( await   fetch(stocks_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
                body:'token='+this.props.loginToken+'&type=user',
              }).catch(error=>console.log(error))).json();




    let monthly_data_kia = new Array();
    let monthly_data_hyundai = new Array();

    data_monthly.map(row=>{

      if(row.id_com_brand==='20') { 
        monthly_data_hyundai.push(row.toplam_adet );

      } else if(row.id_com_brand==='25')
      {
        monthly_data_kia.push(row.toplam_adet );
      }
    })
 
this.setState({data_sum_meet:data_sum_meet,datatype1:data,data_sum:data_sum,current_month:current_month,current_year:current_year,pageloader:true,id_com_brand:this.props.id_com_brand,
     monthly_data_kia:[{'name':'Kia', 'data':monthly_data_kia}],monthly_data_hyundai:[{'name':'Hyundai', 'data':monthly_data_hyundai}],stocks:stocks,data_monthly:data_monthly

})


        //view_report_interview_type7
    }

    render(){
        return( 
<div className="main">
    <div className="main-content">
    

    
    <div className="row">
            <div className="col-md-6"  >
                <h2 style={{marginBottom:15}}>Raporlar | <small>Satış Görüşmeler</small></h2>
                </div>
            <div className="col-md-3"  >   
                <SelectList table='com_month'   label={'Rapor Ayı'}   onChange={(x) => this.handleChange(x,true,'current_month')} value={this.state.current_month}/>
             </div>
            <div className="col-md-3"  >   
                 <SelectList table='view_brand_sl' label={'Marka Seçiniz'}   onChange={(x) => this.handleChange(x,true,'id_com_brand')} value={this.state.id_com_brand}/>
            </div>
            </div>
            <div className="row">
            <div className="panel">
            <div className="panel-body">

                      <div className="col-md-12"  >
                          <MetricsSaleReport 
                           interview_number={this.state.data_monthly.filter(row=>row.id_com_brand===this.state.id_com_brand&&parseInt(row.month_)===parseInt(this.state.current_month)).map(rows=>rows.toplam_adet).reduce( (a, b) =>  parseFloat(a) + parseFloat(b), 0)} 
                           open_number={this.state.data_monthly.filter(row=>row.id_com_brand===this.state.id_com_brand&&parseInt(row.month_)===parseInt(this.state.current_month)).map(rows=>rows.open).reduce( (a, b) =>  parseFloat(a) + parseFloat(b), 0)}   
                           stock_number={this.state.stocks.filter(row=>row.id_com_brand===this.state.id_com_brand).map(rows=>rows.toplam).reduce( (a, b) =>  parseFloat(a) + parseFloat(b), 0)} 
                           potential_sale_number={this.state.stocks.filter(row=>row.id_com_brand===this.state.id_com_brand).map(rows=>rows.baglanti).reduce( (a, b) =>  parseFloat(a) + parseFloat(b), 0)}/>
                        </div>
                    </div>
              </div>
              </div>


        <div className="row">
            <div className="col-md-6"  >
                <div className="panel">
                
                    <div className="panel-heading">
                        <h3 className="panel-title">Görüşme Notları</h3>
                    </div>
                    <div className="panel-body">
                    <DataTable
                        columns={this.state.datatype1_columns}
                        data={this.state.datatype1.filter(row=>parseInt(row.month_)===parseInt(this.state.current_month)&&parseInt(row.year_)===parseInt(this.state.current_year)&&parseInt(row.id_com_brand)===parseInt(this.state.id_com_brand))}
                        fixedHeader
                        noHeader={true}
                        overflowY={false}
                        overflowYOffset={'100px'}
                        highlightOnHover
                        responsive
                        striped
                        dense
                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                        progressPending={!this.state.pageloader}
                    />  
                    </div>
                </div>
            </div>
            <div className="col-md-6"  >
            <div className="row">
            <div className="col-md-12"  >
            <div className="panel">
                    <div className="panel-heading">
                    <h3 className="panel-title">Stoklar</h3>
                    </div>
                    <div className="panel-body">
                      <DataTable
                          columns={this.state.stocks_column}
                          data={this.state.stocks.filter(row=>parseInt(row.id_com_brand )===parseInt(this.state.id_com_brand))}
                          fixedHeader
                          noHeader={true}
                          overflowY={false}
                          overflowYOffset={'100px'}
                          highlightOnHover
                          responsive
                          striped
                          dense
                          paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                          progressPending={!this.state.pageloader}
                      />
                    </div>
                </div>
              </div>
            </div>


            </div>
        </div>
        <div className="row">
            <div className="col-md-6"  >
            <div className="row">
            <div className="col-md-12"  >
            <div className="panel">
                    <div className="panel-heading">
                    <h3 className="panel-title">Temas Türlerine Göre Kırılım</h3>
                    </div>
                    <div className="panel-body">
                      <DataTable
                          columns={this.state.data_sum_meet_columns}
                          data={this.state.data_sum_meet.filter(row=>parseInt(row.month_)===parseInt(this.state.current_month)&&parseInt(row.year_)===parseInt(this.state.current_year)&&parseInt(row.id_com_brand)===parseInt(this.state.id_com_brand))}
                          fixedHeader
                          noHeader={true}
                          overflowY={false}
                          overflowYOffset={'100px'}
                          highlightOnHover
                          responsive
                          striped
                          dense
                          paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                          progressPending={!this.state.pageloader}
                      />
                    </div>
                </div>
              </div>
            </div>
            <div className="row">
            <div className="col-md-12"  >
                <div className="panel">
                    <div className="panel-heading">
                    <h3 className="panel-title">Arama  Sonuçlarına Göre Kırılım</h3>
                    </div>
                    <div className="panel-body">
                    <DataTable
                        columns={this.state.data_sum_columns}
                        data={this.state.data_sum.filter(row=>parseInt(row.month_)===parseInt(this.state.current_month)&&parseInt(row.year_)===parseInt(this.state.current_year)&&parseInt(row.id_com_brand)===parseInt(this.state.id_com_brand))}
                        fixedHeader
                        noHeader={true}
                        overflowY={false}
                        overflowYOffset={'100px'}
                        highlightOnHover
                        responsive
                        striped
                        dense
                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                        progressPending={!this.state.pageloader}
                    />
                    </div>
                </div>
              </div>
            </div>

            </div>
            <div className="col-md-6"  >
                <div className="panel">
                    <div className="panel-heading">
                    <h3 className="panel-title">Dağılım - Aylık</h3>
                    </div>
                    <div className="panel-body">
                      <Chart   options={this.state.bar_options} series={this.state.id_com_brand==='25'?this.state.monthly_data_kia:this.state.monthly_data_hyundai} type="bar" />
                    </div>
                </div>
            </div>


        </div>
        <div className="row">
            

        </div>
     </div>
</div>




        )
    }

}

export default connect(mapStateToProps)(ReportSale);
