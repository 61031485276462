import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../redux/mapStateToProps';
import DataTable from 'react-data-table-component';
import Metrics from './utilities/Metrics';



 class HomeScreen extends React.Component {
    constructor(props){
        super(props);
        this.state={
            interview_data:[],
            forwarded_data:[],
            forced_forwarded_data:[],
            interview_columns:[
                {
                    name: 'Görüşme Kontrol',
                    center:true,
                    cell: (row)=> row.lock == 1?
                    '-'
                :
                     <a href={'/interview/Detail/' + row.id}  target='blank_' className="btn btn-primary btn-sm">Seç</a>
                },
                {
                    name: 'Görüşme No',
                    selector: 'interview_number',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Tarih',
                    selector: 'c_date',
                    left:true,
                    sortable:true
                },

              
                {
                    name: 'Marka',
                    selector: 'id_com_brand_name',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Model',
                    selector: 'id_com_model_name',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Müşteri',
                    selector: 'username',
                    wrap:true,
                    left:true,
                    sortable:true
                },
                {
                    name: 'Görüşme Durumu',
                    selector: 'id_com_interview_status_name',
                    wrap:true,
                    left:true,
                    sortable:true
                },

            ],
            interview_columns_usertype2:[
                {
                    name: 'Görüşme Kontrol',
                    center:true,
                    cell: (row)=> row.lock == 1?
                    '-'
                :
                     <a href={'/interview/Detail/' + row.id} target='blank_' className="btn btn-primary btn-sm">Seç</a>
                },
                {
                    name: 'Görüşme No',
                    selector: 'interview_number',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Tarih',
                    selector: 'c_date',
                    left:true,
                    sortable:true
                },

              
                {
                    name: 'Marka',
                    selector: 'id_com_brand_name',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Model',
                    selector: 'id_com_model_name',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Temsilci',
                    selector: 'id_com_user_name',
                    wrap:true,
                    left:true,
                    sortable:true
                },
                {
                    name: 'Müşteri',
                    selector: 'username',
                    wrap:true,
                    left:true,
                    sortable:true
                },
                {
                    name: 'Görüşme Durumu',
                    selector: 'id_com_interview_status_name',
                    wrap:true,
                    left:true,
                    sortable:true
                },
    
            ],

            feedback_data:[],
            feedback_columns:[
                {
                    name: 'Kontrol',
                    cell : row =>(
                        <a className="btn btn-primary btn-sm btn-block" type="button" href={'/FeedBack/Details/'+row.id}>Seç</a>
                    ),
                    center:true,
                    width: "100px"
                },
                {
                    name: 'No',
                    selector: 'id',
                    width: '75px',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Tarihi',
                    selector: 'c_date',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Saati',
                    selector: 'c_time',
                    left:true,
                    sortable:true,
                    width: '75px',
            
                },
                {
                    name: 'Başvuru Tipi',
                    selector: 'id_com_feedback_type_name',
                    left:true,
                    sortable:true,
                    wrap:true,
                    width: '100px',
                },
                {
                    name: 'Başvuru Konusu',
                    selector: 'subjects',
                    left:true,
                    sortable:true,
                    wrap:true,
                    width: '200px',
                },
                {
                    name: 'Kaynak',
                    selector: 'id_com_meet_name',
                    left:true,
                    sortable:true,
                    wrap:true,
                    width: '100px',
                },
               
                {
                    name: 'Müşteri',
                    selector: 'id_com_customer_name',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Plaka',
                    selector: 'license_plate',
                    left:true,
                    sortable:true
                },
                {
                    name: 'Durum',
                    left:true,
                    cell : row =>(
                        <p style={{color:row.id_com_feedback_status==='1'?'orange':row.id_com_feedback_status==='4'?'green':'red'}}> {row.id_com_feedback_status_name}</p>
                    ),
                },
                
            
            
                {
                    name: 'Kontrol',
                    cell : row =>(
                        <a className="btn btn-primary btn-sm btn-block" type="button" href={'/FeedBack/Details/'+row.id}>Seç</a>
                    ),
                    center:true,
                    width: "100px"
                }
                ],
                alert_data:[],
                alert_columns:[
                    {
                        name: 'Kontrol',
                        cell : row =>(
                            <a className="btn btn-primary btn-sm btn-block" type="button"  target='blank_' href={'/interview/Detail/'+row.id_com_interview}>Seç</a>
                        ),
                        left:true,
                        width: "100px"
                    },
                    {
                        name: 'Hatırlatma Tarihi',
                        selector: 'alert_date',
                        left:true,
                        sortable:true
                    },
                    {
                        name: 'Not',
                        selector: 'comment',
                        left:true,
                        sortable:true
                    },
                    

                ],
                data_service_app:[],
                data_service_app_columns:[
                    {
                        name: 'Kontrol',
                        cell : row =>(
                            <a className="btn btn-primary btn-sm btn-block" type="button" href={'/ServiceApp/Details/'+row.id}>Seç</a>
                        ),
                        center:true,
                        width: "100px"
                    },
                    {
                        name: 'Randevu Saati',
                        selector: 'time',
                        left:true,
                        sortable:true
                    },
                    {
                        name: 'Plaka',
                        selector: 'license_plate',
                        left:true,
                        sortable:true
                    },
                    {
                        name: 'İşlem',
                        selector: 'id_com_service_app_subject_name',
                        left:true,
                        sortable:true
                    },       
                     {
                        name: 'Durum',
                        selector: 'id_com_service_app_status_name',
                        left:true,
                        sortable:true
                    },


                ],
                data_task:[],
                data_task_columns:[
                    {
                        name: 'Kontrol',
                        cell : row =>(
                            row.status==='1'?
                            <button className="btn btn-danger btn-sm" type="button" disabled={true} ><i className="fas fa-check-double"></i></button>
                            : <>
                            <button className="btn btn-success btn-sm" type="button" onClick={()=>this._CompleteTask(row.id)} style={{margin:5}}><i className="fas fa-check"></i></button>
                            </>
                        ),
                        center   :true,
                    }
,
                    {
                        name: 'No',
                        selector: 'id',
                        width: '75px',
                        left:true,
                        sortable:true
                    },
    
                    {
                        name: 'Görev Konusu',
                        selector: 'id_com_task_subject_name',
                        left:true,
                        sortable:true
                    },
                    {
                        name: 'Görev Tanımı',
                        selector: 'comment',
                        left:true,
                        wrap:true,
                        width: '400px',
                        sortable:true
                    },
      
                    {
                        name: 'Hedef Tamamlanma Tarihi',
                        selector: 'target_date',
                        left:true,
                        sortable:true
                    },
                    {
                        name: 'Atayan',
                        selector: 'id_com_user_name',
                        left:true,
                        sortable:true
                    },
                    {
                        name: 'Durumu',
                        left:true,
                        sortable:true,
                        cell : row =>(
                            row.status==='0'?
                            row.id_com_task_status_name
                            :
                            row.end_date+' / Tarihinde Tamamlandı.'
                        ),
                    },

                ],
                feedback_waiting:[],
                  data_orderform:[],
                  data_orderform_columns:[
                    {
                        name: 'Kontrol',
                        cell : row =>(
                            <a className="btn btn-primary btn-sm btn-block" type="button" href={'/Orderform/Approval/'+row.id}>Seç</a>
                        ),
                        center:true,
                        width: "100px"
                    },
                      {
                        name: 'Onaya Gönderen',
                        selector: 'id_com_user_name',
                        left:true,
                        sortable:true
                      },
                      {
                        name: 'Müşteri',
                        selector: 'id_com_customer_name',
                        left:true,
                        sortable:true
                      },

                      {
                        name: 'Onaya Gönderim Tarihi',
                        selector: 'm_date',
                        left:true,
                        sortable:true
                      },
                      {
                        name: 'Durum',
                        selector: 'id_com_orderform_status_name',
                        left:true,
                        sortable:true
                      },
                      
                  ]
        }
        this.data_interview=this.data_interview.bind(this);
        this.data_feedback=this.data_feedback.bind(this);
        this.data_alert=this.data_alert.bind(this);
        this.data_service_app=this.data_service_app.bind(this);
        this.data_task=this.data_task.bind(this);
        this._CompleteTask=this._CompleteTask.bind(this);
        this.data_waiting=this.data_waiting.bind(this)
        this.data_orderform=this.data_orderform.bind(this)
    }
async componentDidMount(){

   this.data_interview();
   this.data_feedback();
   this.data_alert();
   this.data_service_app();
   this.data_task();
   this.data_waiting();
   this.data_orderform();
    }


    _CompleteTask(id){
        let d = new Date(); 
        let date = d.toISOString();
        let end_date  = date.split('T')[0];
        let end_time = d.toLocaleTimeString('tr-TR');

        let task_url=this.props.api_url+'/update/com_task';
        fetch(task_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body:'token='+this.props.loginToken+
          '&id='+id+
          '&end_date='+end_date+
          '&end_time='+end_time+
          '&id_com_task_status=3&status=1&type=user',
        })
        .catch(error=>console.log(error)).then(x=>window.location.reload())
    }

data_interview(){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("token", this.props.loginToken);
    urlencoded.append("status", '0');

    if(this.props.id_com_user_type==='2'){
        urlencoded.append("id_com_brand", this.props.id_com_brand);

    } else  {
        urlencoded.append("id_com_user", this.props.id_com_user);
    }


    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    fetch(this.props.api_url + "/selectRows/view_interview", requestOptions)
        .then(response => response.json())
        .then(result =>{
             let forwarded_data = result.filter(row=>row.forwarded==='1'&&row.lines_counted==='1') 

             let forced_forwarded_data1 = result.filter(row=>row.forwarded==='1'&&row.last_comment_id_com_user!==this.props.id_com_user&&parseInt(row.lines_counted)>1)
             let forced_forwarded_data2 = result.filter(row=>row.forwarded==='1'&&row.last_comment_id_com_user!==row.id_com_user&&parseInt(row.lines_counted)>1)
 
             let  difference1 =result.filter(row => !forwarded_data.find(row2 => row.id === row2.id ))
             let  difference2 =difference1.filter(row => !forced_forwarded_data1.find(row2 => row.id === row2.id ))

            this.setState({interview_data:difference2.reverse(),forwarded_data:forwarded_data,forced_forwarded_data:this.props.id_com_user_type==='2'?forced_forwarded_data2:forced_forwarded_data1,pageloader:true})
        })
        .catch(error => console.log('error', error));
}


data_alert(){
    let d = new Date(); 
    let date = d.toISOString();
    let today  = date.split('T')[0];

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("token", this.props.loginToken);
    urlencoded.append("id_com_user", this.props.id_com_user);
    urlencoded.append("status", '0');
     urlencoded.append("alert_date", today);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    fetch(this.props.api_url + "/selectRows/com_alert", requestOptions)
        .then(response => response.json())
        .then(result =>{
            this.setState({alert_data:result.reverse(),pageloader:true})
        })
        .catch(error => console.log('error', error));
}
data_feedback(){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("token", this.props.loginToken);
    urlencoded.append("r_id_com_user1", this.props.id_com_user);
    // urlencoded.append("id_com_feedback_status", '1');

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    fetch(this.props.api_url + "/selectRows/view_feedback", requestOptions)
        .then(response => response.json())
        .then(result =>{
            let filtered = result.filter(row=>row.id_com_feedback_status==='1'||row.id_com_feedback_status==='2')
            this.setState({feedback_data:filtered.reverse(),pageloader:true})
        })
        .catch(error => console.log('error', error));
}


data_waiting(){
    let feedback_url=this.props.api_url+'/selectRows/view_feedback';

    fetch(feedback_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body:'token='+this.props.loginToken+'&id_com_feedback_status=3&id_com_confirm_user='+this.props.id_com_user+'&type=user',
        })
        .catch(error=>console.log(error)).then(x=> x.json()).then(result=>{this.setState({feedback_waiting:result,pageloader:true})})
}



data_service_app(){
    let d = new Date(); 
    let date = d.toISOString();
    let app_date  = date.split('T')[0];

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("token", this.props.loginToken);
    urlencoded.append("id_com_registered_user", this.props.id_com_user);
    urlencoded.append('app_date', app_date);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    fetch(this.props.api_url + "/selectRows/view_service_app", requestOptions)
        .then(response => response.json())
        .then(result =>{
          //  let filtered = result.filter(row=>row.id_com_feedback_status==='1'||row.id_com_feedback_status==='2')
            this.setState({data_service_app:result,pageloader:true})
        })
        .catch(error => console.log('error', error));
}




data_orderform(){
    let d = new Date(); 
    let date = d.toISOString();
    let app_date  = date.split('T')[0];
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("token", this.props.loginToken);
    urlencoded.append("type", 'user');
    // urlencoded.append("id_com_user_brand", this.props.id_com_brand)

    if(this.props.id_com_user_type==='6'||this.props.id_com_user_type==='12'){
        urlencoded.append("id_com_orderform_status", '3');

    } else if (this.props.id_com_user_type==='3'&&this.props.id_com_department==='2') 
    {
        urlencoded.append("id_com_orderform_status", '4');
    }else if (this.props.id_com_user_type==='3'&&this.props.id_com_department==='1') 
    {
        urlencoded.append("id_com_orderform_status", '5');
    }
    else { 
         urlencoded.append("id_com_orderform_status", '2');
        urlencoded.append("id_com_user_manager", this.props.id_com_user)
    }

    // switch(this.props.id_com_user_type){
    //     case '6' : 
    //     urlencoded.append("id_com_orderform_status", '3');
    //     case '12' : 
    //     urlencoded.append("id_com_orderform_status", '3');
    //     case '3' : 
    //     urlencoded.append("id_com_orderform_status", '4');
    //     default :
    //     urlencoded.append("id_com_orderform_status", '2');
    //     urlencoded.append("id_com_user_manager", this.props.id_com_user)
    // }
    // urlencoded.append("id_com_orderform_status", '4');
    

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    // console.log(requestOptions)
// for (let [key, value] of Object.entries(urlencoded)) {
//     console.log(key +': ' +value  );
// }
    fetch(this.props.api_url + "/selectRows/view_orderform", requestOptions)
        .then(response => response.json())
        .then(result =>{
            console.log(result)
            this.setState({data_orderform:result,pageloader:true})
        })
        .catch(error => console.log('error', error));
}


data_task (){
 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("token", this.props.loginToken);
    urlencoded.append("r_id_com_user", this.props.id_com_user);
    urlencoded.append("status",'0');
    urlencoded.append("id_com_task_type",'0');

     var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    fetch(this.props.api_url + "/selectRows/view_task", requestOptions)
        .then(response => response.json())
        .then(result =>{
          //  let filtered = result.filter(row=>row.id_com_feedback_status==='1'||row.id_com_feedback_status==='2')
            this.setState({data_task:result,pageloader:true})
        })
        .catch(error => console.log('error', error));
}
    render(){
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-content">
                        <div className="container-fluid">
                            <div className="panel panel-headline">
                                <div className="panel-heading">
                                    <h3 className="panel-title">Dashboard </h3>
                                </div>
                                <Metrics
                                interview_number={this.state.interview_data.length}
                                feedback_number={this.state.feedback_data.length}
                                alert_number={this.state.alert_data.length}
                                service_app_number={this.state.data_service_app.length}
                                />
                                <div className="panel-body">
                        
                                {this.state.alert_data.length>0?
                                <div className="row">
                                        <div className="col-md-12">
                                             <DataTable
                                columns={this.state.alert_columns}
                                data={this.state.alert_data}
                                title={'Açık Hatırlatmalarım'}
                                fixedHeader
                                highlightOnHover
                                responsive
                                striped
                                dense
                                pagination={true}
                                paginationPerPage={5}
                                defaultSortField={'alert_date'}
                                defaultSortAsc={false}
                                />
                                        </div>
                                      
                                    </div>
                                    :<></>}
                                    {this.state.forced_forwarded_data.length>0?
                                    <div className="row">
                                        <div className="col-md-12">

                                             <DataTable
                                columns={this.props.id_com_user_type==='2'?this.state.interview_columns_usertype2:this.state.interview_columns}
                                data={this.state.forced_forwarded_data}
                                title={this.props.id_com_user_type==='2'?'Yönlendirilen ve Hatırlatılan Görüşme Notları':'Yönlendirilen  ve Hatırlatılan  Görüşme Notlarım'}
                                fixedHeader
                                highlightOnHover
                                responsive
                                striped
                                dense
                                pagination={true}
                                paginationPerPage={5}
                                defaultSortField={'c_date'}
                                defaultSortAsc={false}
                                />

                                        </div>
                                      
                                    </div>
                                    :<></>}
                                    {this.state.forwarded_data.length>0?
                                    <div className="row">
                                        <div className="col-md-12">

                                             <DataTable
                                columns={this.props.id_com_user_type==='2'?this.state.interview_columns_usertype2:this.state.interview_columns}
                                data={this.state.forwarded_data}
                                title={this.props.id_com_user_type==='2'?'Yönlendirilen Görüşme Notları':'Yönlendirilen Görüşme Notlarım'}
                                fixedHeader
                                highlightOnHover
                                responsive
                                striped
                                dense
                                pagination={true}
                                paginationPerPage={5}
                                defaultSortField={'c_date'}
                                defaultSortAsc={false}
                                />

                                        </div>
                                      
                                    </div>
                                    :<></>}
                                    {this.state.interview_data.length>0?
                                    <div className="row">
                                        <div className="col-md-12">

                                             <DataTable
                                columns={this.props.id_com_user_type==='2'?this.state.interview_columns_usertype2:this.state.interview_columns}
                                data={this.state.interview_data}
                                title={'Açık Görüşme Notlarım'}
                                fixedHeader
                                highlightOnHover
                                responsive
                                striped
                                dense
                                pagination={true}
                                paginationPerPage={5}
                                defaultSortField={'c_date'}
                                defaultSortAsc={false}
                                />

                                        </div>
                                      
                                    </div>
                                    :<></>}
                                    {this.state.feedback_data.length>0?
                                    <div className="row">
                                        <div className="col-md-12">
                                             <DataTable
                                columns={this.state.feedback_columns}
                                data={this.state.feedback_data}
                                title={'Açık Başvurularım'}
                                fixedHeader
                                highlightOnHover
                                responsive
                                striped
                                dense
                                pagination={true}
                                paginationPerPage={5}
                                defaultSortField={'c_date'}
                                defaultSortAsc={false}
                                />

                                        </div>
                                        </div>
                                        :<></>}
                                        {this.state.feedback_waiting.length>0?
                                    <div className="row">
                                        <div className="col-md-12">
                                             <DataTable
                                columns={this.state.feedback_columns}
                                data={this.state.feedback_waiting}
                                title={'Onay Bekleyen Başvurular'}
                                fixedHeader
                                highlightOnHover
                                responsive
                                striped
                                dense
                                pagination={true}
                                paginationPerPage={5}
                                defaultSortField={'c_date'}
                                defaultSortAsc={false}
                                />

                                        </div>
                                        </div>
                                        :<></>}
                                        {this.state.data_service_app.length>0?
                                            <div className="row">

                                <div className="col-md-12">
                                        <DataTable
                                        columns={this.state.data_service_app_columns}
                                        data={this.state.data_service_app}
                                        title={'Bugünkü Randevularım'}
                                        fixedHeader
                                        highlightOnHover
                                        responsive
                                        striped
                                        dense
                                        pagination={true}
                                        paginationPerPage={5}
                                        defaultSortField={'time'}
                                        defaultSortAsc={false}
                                        />
                                        </div>
                                        </div>

                                        :<></>}
                                        {this.state.data_task.length>0?
                                            <div className="row">

                                <div className="col-md-12">
                                        <DataTable
                                        columns={this.state.data_task_columns}
                                        data={this.state.data_task}
                                        title={'Aktif Görevlerim'}
                                        fixedHeader
                                        highlightOnHover
                                        responsive
                                        striped
                                        dense
                                        pagination={true}
                                        paginationPerPage={5}
                                        defaultSortField={'target_date'}
                                        defaultSortAsc={false}
                                        />
                                        </div>
                                        </div>

                                        :<></>}
                                        {this.state.data_orderform.length>0?
                                            <div className="row">

                                <div className="col-md-12">
                                        <DataTable
                                        columns={this.state.data_orderform_columns}
                                        data={this.state.data_orderform}
                                        title={'Onay Bekleyen Föyler'}
                                        fixedHeader
                                        highlightOnHover
                                        responsive
                                        striped
                                        dense
                                        pagination={true}
                                        paginationPerPage={5}
                                        defaultSortField={'c_date'}
                                        defaultSortAsc={false}
                                        />
                                        </div>
                                        </div>

                                        :<></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </React.Fragment>
        )
    }
}
export default connect(mapStateToProps)(HomeScreen);