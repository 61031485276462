
import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component';



class Metrics extends React.Component {
    constructor(props){
        super(props);
        this.state={
            metrics_config:[],
            interview_number:'',
            feedback_number:'',
            alert_number:'',
            service_app_number:''
        }
        this._Update=this._Update.bind(this)
    }



componentDidMount(){
this._Update()
}



_Update(){

    let metrics_config=[
        {
            number:this.props.feedback_number,
            icon:'fa fa-tasks',
            title:'Başvurular'
        },
        {
            number:this.props.alert_number,
            icon:'fa fa-phone-alt',
            title:'Hatırlatmalar'
        },
        {
            number:this.props.service_app_number,
            icon:'fa fa-calendar-check',
            title:'Randevu Adedi'
        },
        {
            number: this.props.interview_number ,
            icon:'fa fa-comment-dots',
            title:'Açık Görüşmeler',
        },
    ]

    this.setState({
        metrics_config:metrics_config
    })
}


componentDidUpdate(prevProps, prevState) {

    if (this.props.interview_number !== prevProps.interview_number) {
        this._Update();
    }else     if (this.props.feedback_number !== prevProps.feedback_number) {
        this._Update();
    } else     if (this.props.alert_number !== prevProps.alert_number) {
        this._Update();
    } else     if (this.props.service_app_number !== prevProps.service_app_number) {
        this._Update();
    }

}



render(){
    return ( 

        <React.Fragment>
                <div className="panel-body">
                    <div className="row">
                        {this.state.metrics_config.map((row,index)=>
                        <div className="col-md-3" key={index}>
                            <div className="metric">
                                <span className="icon"><i className={row.icon}></i></span>
                                <p>
                                    <span className="number">{row.number}</span>
                                    <span className="title">{row.title}</span>
                                </p>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
        </React.Fragment>
    )
}
}

export default connect(mapStateToProps)(Metrics);