import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component';
import SelectList from '../utilities/SelectList';
import DatePicker from '../utilities/DatePicker';
import {alertMessage} from '../../assets.js';


class TaskEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data:[],
            disabled:true,
        }
        this.handleChange=this.handleChange.bind(this);
        this.Timer=this.Timer.bind(this);
        this._arraytoString=this._arraytoString.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this._DeleteTask=this._DeleteTask.bind(this);
    }
    handleChange(event,selectlist,name) {        
                if(selectlist) { 
                    this.setState({ [`${name}`]:event.id})
                } 
                else { 
        
                 if(event.target.type==='checkbox'){ 
                       event.target.checked?this.setState({[`${event.target.name}`]:'1'}):this.setState({[`${event.target.name}`]:'0'})
                                } else {
                    const value = event.target.value;
                    const key = event.target.name;
                    this.setState({ [`${key}`]:value})
                 } }
                 this.setState({disabled:false})

            }





        
            Timer(date){
                let newdate = new Date(date)
                newdate.setDate(newdate.getDate())
                let app_date = newdate.toISOString().split('T')[0]
                this.setState({target_date:app_date,disabled:false})
            }

            _arraytoString(value){
                const brands= [];
                value.map(row => brands.push(row.id));
                const id_com_groups = [...new Set(brands)];
                    return(id_com_groups)
                   // console.log(id_com_groups)
        }

        componentDidMount(){


            let id_com_task = this.props.match.params.id_com_task;

            this.setState({ 
                id_com_task: id_com_task
            })
    
            let product_url=this.props.api_url+'/selectRows/view_task';
            fetch(product_url,{
                    method:'POST',
                    cahce: 'no-cache',
                    headers:{ 
                      'Content-Type':'application/x-www-form-urlencoded'
                    },
                  body:'id='+id_com_task+'&token='+this.props.loginToken+'&type=user'
                })
                .catch(error=>console.log(error))
                .then(res=>res.json())
                .then(result=>{
                    let data = result[0];
                for (let [key, value] of Object.entries(data)) {
                this.setState({ [`${key}`]:value,pageloader:true
                    })
                }
                let d = new Date(); 
                let date = d.toISOString();
                let m_id_com_user=this.props.id_com_user;
                let m_date  = date.split('T')[0];
                let m_time = d.toLocaleTimeString('tr-TR');


                this.setState({timing_type:data.id_com_task_type==='0'?'0':'1',
                            m_id_com_user:m_id_com_user,
                            m_date:m_date,
                            m_time:m_time,
                            })
                })

                let com_product_url=this.props.api_url+'/selectRows/com_task';
                fetch(com_product_url,{
                        method:'POST',
                        cahce: 'no-cache',
                        headers:{ 
                            'Content-Type':'application/x-www-form-urlencoded'
                        },
                        body:'id='+id_com_task+'&token='+this.props.loginToken+'&type=user'
                    })
                    .catch(error=>console.log(error))
                    .then(res=>res.json())
                    .then(result2=>{
                    this.setState({data:result2[0]})
                    })

        }
        
        
        handleSubmit(event) {
            
         

            let update_body = 
            'token='+this.props.loginToken+

            '&type=user';

            
            for (let [key] of Object.entries(this.state.data)) {

                update_body +=`&${key}=`+ this.state[`${key}`]
        }

        let com_customer_url=this.props.api_url+'/update/com_task';
        fetch(com_customer_url,{
                method:'POST',
                cahce: 'no-cache',
                headers:{ 
                  'Content-Type':'application/x-www-form-urlencoded'
                },
              body:update_body,
            })
            .catch(error=>console.log(error)).then(x=> {this.setState({disabled:false});
            window.location.reload()
        }
            )
            
    }
    _DeleteTask(){
        let d = new Date(); 
        let date = d.toISOString();
        let end_date  = date.split('T')[0];
        let end_time = d.toLocaleTimeString('tr-TR');

        let task_url=this.props.api_url+'/update/com_task';
        fetch(task_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body:'token='+this.props.loginToken+
          '&id='+this.state.id_com_task+
          '&end_date='+end_date+
          '&end_time='+end_time+
          '&hidden=1&type=user',
        })
        .catch(error=>console.log(error)).then(x=>window.location.href='/task/list')
    }


    render () { 
        return ( 
        <div className="main">
            <div className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel">
                                <div className="panel-heading">
                                    <h3 className="panel-title">Görev Düzenle</h3>
                                </div>
                                <div className="panel-body"> 
                                <SelectList table='view_brand_sl' value={this.state.id_com_brand} label={'Marka'} name={'id_com_brand'} onChange={(x)=>this.handleChange(x )} />
                                <SelectList table='com_department'  value={this.state.id_com_department} label={'Departman Seçiniz'} name={'id_com_department'} onChange={(x)=>this.handleChange(x)} />
                                {this.state.timing_type==='1'?
                                <SelectList table='com_task_type' label={'Yenileme Zamanı'} name={'id_com_task_type'} value={this.state.id_com_task_type} onChange={(x)=>this.handleChange(x )} />
                                :
                                <div className="form-check" >
                                <label className="form-check-label" style={{marginLeft:5,  }} > Hedef Tarihi  : {this.state.target_date}</label>

                                <DatePicker    onChange={(x)=>this.Timer(x)}  />
                                </div>

                                }
                                <SelectList table='com_task_subject' label={'Görev Konusu'} value={this.state.id_com_task_subject}  name={'id_com_task_subject'} onChange={(x)=>this.handleChange(x )} />

                                <div className="form-group">
                                    <label>Görev Tanımı</label>
                                    <textarea type="text" cols className="form-control" value={this.state.comment}   name="comment" onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                                </div>
                                {this.state.id_com_brand&&this.state.id_com_department?
                                <>
                                <SelectList table='view_user_sl'  Label={'Atanan Yetkili'} value={this.state.r_id_com_user}  id_com_brand={this.state.id_com_brand}  name={'r_id_com_user'}  id_com_department={this.state.id_com_department} onChange={(x)=>this.handleChange(x )}    />

                                </>
                                    :<></>
                                }
                                <SelectList table='com_task_status'  Label={'Durum'} value={this.state.id_com_task_status}     name={'id_com_task_status'}   onChange={(x)=>this.handleChange(x )}    />

                                </div>
                                <div className="panel-footer">
                                <a className="btn btn-primary" 
                                  href={'/task/list'}
                                    >Geri</a>
                                <button className="btn btn-success pull-right" 
                                 onClick={()=>this.handleSubmit()}
                                 style={{margin:5}}
                                 disabled={this.state.disabled}

                                    >Güncelle</button>
                                            <button className="btn btn-danger pull-right" 
                                 onClick={()=>this._DeleteTask()}
                                 style={{margin:5}}
                                    >Sil</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

)
}
}

export default connect(mapStateToProps)(TaskEdit);