import React, { useState } from 'react';
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import {  Typography,Upload, Row,Col, Button} from 'antd';
import { connect } from 'react-redux';
import mapStateToProps from '../../../redux/mapStateToProps';
import { UploadOutlined ,CheckCircleOutlined} from '@ant-design/icons';

import moment from 'moment';

const {Text } = Typography

const ExcelUpload = ({api_url,loginToken,Finished,onFinished})=>{
    const [Columns,setColumns]=useState([])
    const [Rows,setRows]=useState([])
    const [Completed,setCompleted]=useState(false)
    const [Count,setCount] = useState(0);
    const [Transfer,setTransfer]= useState(false);
 
    const _Upload =(event)=>{
             let fileObj = event.file.originFileObj;
             ExcelRenderer(fileObj, (err, resp) => { 
                const columns = [];
                const data = [];
                
                if(err){
                  console.log(err);            
                }
                else{
                    resp.rows.map((line,idx)=> {                
                    const row_data={
                        id_com_interview_type:'3',
                        id_com_interview_status:'1',
                        status:'0',
                        forwarded:'1', 
                        id_com_meet:'18'
                    };
                        if(idx!==0)  {
                                resp.rows[0].map((cols,index)=>{
                                    if(cols==='c_date') {
                                        Object.assign(row_data,{[`${cols}`]:`${moment(line[index]).format('YYYY-MM-DD')}`})
                                    } else { 
                                        Object.assign(row_data,{[`${cols}`]:`${line[index]}`})
                                    }

                                })
                                data.push(row_data)
                            } else  if(idx===0){
                              line.map((cols,index)=>{
                                    columns.push({
                                        title:cols,
                                        dataIndex:cols
                                    })
                                })
                             }
                       
                        })

                    } 
                    console.log(data)
                    setRows(data)
                    setColumns(columns);
                    setCompleted(true);
                }
            );  
    }


    const _CheckCustomer = async ()=>{
        const phones = []
        Rows.map(row=>phones.push(row.phone1));

        const u_phones=[...new Set(phones)]

        u_phones.map( async (cust_phone)=>{
            const customer_url = api_url+'/selectrows/com_customer';
            const body1 = 'token='+loginToken+'&type=user&phone1='+cust_phone; 
            const config1= {  method:'POST',  cahce: 'no-cache',  headers:{  'Content-Type':'application/x-www-form-urlencoded'   },  body:body1 };

            try {
                    const user_control = await(await(fetch(customer_url,config1))).json();
                    if(user_control.length===0){
                        await  _CreateCustomer(Rows.filter(line=>line.phone1===cust_phone)[0]);
                    } 
            }
            catch(err) {
                console.log(err)
            }
        })
            }

    const _Submit = async () =>{
        setTransfer(true);
        const d = new Date();
        const c_time = d.toLocaleTimeString('tr-TR');

        
        const promises =    Rows.map(async (row,index)=>{
            new Promise(resolve =>{

                setTimeout( async () => {
                    const customer_url = api_url+'/selectrows/com_customer';
                    const body1 = 'token='+loginToken+'&type=user&phone1='+row.phone1; 
                    const config1= {  method:'POST',  cahce: 'no-cache',  headers:{  'Content-Type':'application/x-www-form-urlencoded'   },  body:body1 };
       
                   try {
                       const user_control = await(await(fetch(customer_url,config1))).json();
        
                       if(user_control.length>0){
                            const id_com_customer = user_control[0].id;
                            Object.assign(row,{id_com_customer:id_com_customer,c_time:c_time})
                           _CreateInterview(row)
                       } 
                       else { 
                            const id_com_customer =    _CreateCustomer(row);
        
                           Object.assign(row,{id_com_customer:id_com_customer,c_time:c_time})
                           _CreateInterview(row)
                       }
                   }
                   catch(err) {
                       console.log(err)
                   }
                   setCount(index+1); 
                   resolve()
                },250 )
     
        })


        })

        Promise.all(promises).then(()=>onFinished(true))

    // }
    }
 

    const _CreateCustomer = async ({name,surname,phone1,email,company_name,id_com_user,id_com_meet})=>{

        const d = new Date();
        const c_date = moment(d).format('YYYY-MM-DD');
        const c_time = d.toLocaleTimeString('tr-TR');
        const customer_url = api_url+'/insert/com_customer';
        let body1 = 'token='+loginToken+'&type=user&status=1&id_com_customer_type=1&phone1='+phone1+'&company_name='+company_name+'&email='+email+'&surname='+surname+'&name='+name+'&id_com_meet='+id_com_meet+'&c_time='+c_time+'&c_date='+c_date+'&id_com_user='+id_com_user;         
         const config1= {  method:'POST',  cahce: 'no-cache',  headers:{  'Content-Type':'application/x-www-form-urlencoded'   },  body:body1 };
        try {
            const user_result = await(await(fetch(customer_url,config1))).json();

            return user_result.id;
        }
        catch(err) {
            console.log(err)
        }
    }

    const _CreateInterview = async (row)=>{

        const interview_url = api_url+'/insert/com_interview';
        const interview_number = Math.floor(Math.random() * (9999999999 - 1000000000 + 1)) + 1000000000
         let body = 'token='+loginToken+'&type=user&interview_number=AKT'+interview_number;

            delete row.name;
            delete row.surname;
            delete row.phone1;
            delete row.company_name;
            delete row.company_name;
            delete row.email;
         for (const [key,value] of Object.entries(row)){
                if(key!=='content') {
                    body+=`&${key}=${value}`
                }
     
        }
  
        const config= {  method:'POST',  cahce: 'no-cache',  headers:{  'Content-Type':'application/x-www-form-urlencoded'   },  body:body};
 
        try {
            const inter_result = await(await(fetch(interview_url,config))).json();
 
                if(inter_result.status) {
 
                        const {c_date,c_time,id_com_meet,id_com_interview_status,content,id_com_user } = row
                        
                        let id_com_interview = inter_result.id;
                        const line_url = api_url+'/insert/com_interview_lines';
                        let body2 = 'token='+loginToken+'&type=user'+
                        '&c_date='+c_date+
                        '&c_time='+c_time+
                        '&id_com_interview='+id_com_interview+
                        '&id_com_meet='+id_com_meet+
                        '&id_com_interview_status='+id_com_interview_status+
                        '&content='+content+
                        '&id_com_user='+id_com_user;
                        const config2= {  method:'POST',  cahce: 'no-cache',  headers:{  'Content-Type':'application/x-www-form-urlencoded'   },  body:body2};
                        try {
                            const line_result = await(await(fetch(line_url,config2))).json();
                         }
                        catch(err){
                            console.log(err)
                        }

                } 

        }
        catch(err) {
            console.log(err)
        }
    }

    return( 
        !Finished?
        !Completed?
        <Upload     namw='file' onChange={_Upload} >
            <Button icon={<UploadOutlined />}>Yükleme için Dosya Seçiniz</Button>
        </Upload>
        :
        !Transfer?
        <Button type='primary' icon={<CheckCircleOutlined/>} onClick={()=>_Submit()} > {Rows.length} adet satır yüklenmiştir. Aktarımı Başlat</Button> 
        :<Text>Aktarım Başladı {Count}</Text>
        :
        <React.Fragment>
            <Text >Aktarım Tamamlanmıştır. {Count} Adet kayıt oluşturulmuştur.</Text>
            <Button type='primary' danger onClick={()=>window.location.reload()} > Yeni Aktarım Başlat.</Button> 
        </React.Fragment>


       
    )
}

export default connect(mapStateToProps)(ExcelUpload)