import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import SelectList from '../utilities/SelectList';

class ExpertiseEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled:true,
            data:[]
        };
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }



componentDidMount(){
    const id_com_expertise = this.props.match.params.id;
    this.setState({ 
        id_com_expertise: id_com_expertise
    })

    let view_body_url=this.props.api_url+'/selectRows/view_expertise';
    fetch(view_body_url,{
            method:'POST',
            cahce: 'no-cache',
            headers:{ 
              'Content-Type':'application/x-www-form-urlencoded'
            },
          body:'id='+id_com_expertise+'&token='+this.props.loginToken+'&type=user'
        })
        .catch(error=>console.log(error))
        .then(res=>res.json())
        .then(result=>{
            let data = result[0];
        for (let [key, value] of Object.entries(data)) {
        this.setState({ [`${key}`]:value,pageloader:true
            })
        }
        })
      
let com_body_url=this.props.api_url+'/selectRows/com_expertise';
fetch(com_body_url,{
        method:'POST',
        cahce: 'no-cache',
        headers:{ 
            'Content-Type':'application/x-www-form-urlencoded'
        },
        body:'id='+id_com_expertise+'&token='+this.props.loginToken+'&type=user'
    })
    .catch(error=>console.log(error))
    .then(res=>res.json())
    .then(result2=>{
    this.setState({data:result2[0]})
    })

}


handleChange(event,selectlist,name) {
    if(selectlist) { 
        this.setState({ [`${name}`]:event.id})
        // console.log('id changed')
    } else { 
        const value = event.target.value;
        const key = event.target.name;
        this.setState({ [`${key}`]:value})
    }
    this.setState({disabled:false})
}

async handleSubmit(event) {
    let d = new Date(); 
    let date = d.toISOString();
    let m_date  = date.split('T')[0];
    let m_time = d.toLocaleTimeString('tr-TR');


    let update_body = 
    'token='+this.props.loginToken+
    '&type=user';

await this.setState({m_date:m_date,m_id_com_user:this.props.id_com_user,m_time:m_time})
    for (let [key] of Object.entries(this.state.data)) {
            
        update_body +=`&${key}=`+ this.state[`${key}`]
}
// console.log(update_body);

let com_body_url=this.props.api_url+'/update/com_expertise';
fetch(com_body_url,{
        method:'POST',
        cahce: 'no-cache',
        headers:{ 
          'Content-Type':'application/x-www-form-urlencoded'
        },
      body:update_body,
    })
    .catch(error=>console.log(error)).then(x=> {
    this.setState({disabled:false});
    window.location.href='/expertise/detail/'+this.state.data.id
})
}




    render(){
        return ( 
            <div className="main">
            <div className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel">
                                <div className="panel-heading">
                                    <div className="panel-title">Ekspertiz No : {this.state.id_com_expertise}</div>
                                </div>
                                <div className="panel-body">
                                <h3 className='text-center'>Görüşme Satırları </h3>
                                        <table className="table table-bordered">
                                            <tbody>
                                            <tr>
                                                <th>Talep Tarihi</th>
                                                <td>{this.state.data.c_date+' / '+this.state.data.c_time} </td>                                                      
                                            </tr>
                                            <tr>
                                                <th>Talep Eden</th>
                                                <td>{this.state.id_com_user_name} </td>                                                      
                                            </tr>
                                            <tr>
                                                <th>Müşteri Bilgisi </th>
                                                <td>{this.state.id_com_customer_name+' /  Tel : '+this.state.phone1+' / Email : '+this.state.phone1} </td>                                                      
                                            </tr>
                                            <tr>
                                                <th>Talep Edilen Fiyat </th>
                                                <td>{this.state.demand_price} </td>                                                      
                                            </tr>
                                            </tbody>
                                        </table>
                                        <div className="form-group">
                                        <label>Plaka</label>
                                        <input type="text" className="form-control" name="license_plate" value={this.state.license_plate}  onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                                         </div>
                                        <SelectList table='com_brand' label={'Araç Markası'} name={'id_com_brand'} value={this.state.id_com_brand} onChange={(x)=>this.handleChange(x)} />
                                        <SelectList table='com_model' id_com_brand={this.state.id_com_brand}  label={'Araç Modeli'} name={'id_com_model'} value={this.state.id_com_model} onChange={(x)=>this.handleChange(x)} />
                                        <SelectList table='com_gear'  label={'Vites Tipi'} name={'id_com_gear'} value={this.state.id_com_gear} onChange={(x)=>this.handleChange(x)} />
                                        <SelectList table='com_fuel'  label={'Yakıt Tipi'} name={'id_com_fuel'} value={this.state.id_com_fuel} onChange={(x)=>this.handleChange(x)} />
                                        <SelectList table='com_color'  label={'Renk'} name={'id_com_color'} value={this.state.id_com_color} onChange={(x)=>this.handleChange(x)} />
                                         <div className="form-group">
                                        <label>Km</label>
                                        <input type="text" className="form-control" name="km" value={this.state.km}  onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                                         </div>
                                         <div className="form-group">
                                        <label>Şase</label>
                                        <input type="text" className="form-control" name="chasis" value={this.state.chasis}  onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                                         </div>
                                         
                                         <div className="form-group">
                                        <label>Teklif Fiyatı</label>
                                        <input type="text" className="form-control" name="offer_price" value={this.state.offer_price}  onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                                         </div>
                                         <div className="form-group" >
                                            <label>Hasar Bilgisi  </label>
                                            <textarea type="text" style={{height:200}} row='20' value={this.state.comment} className="form-control" name="comment" onChange={(x) => this.handleChange(x)} required/>
                                         </div>

                                         <SelectList table='com_expertise_status'  label={'Ekspertiz'} name={'id_com_expertise_status'} value={this.state.id_com_expertise_status} onChange={(x)=>this.handleChange(x)} />


                                     <div className="panel-footer">
                                       <a className="btn btn-danger pull-left" href={"/Expertise/List"}>İptal</a>
                                    <button className="btn btn-primary pull-right"
                                    disabled={this.state.disabled}
                                    onClick={()=>this.handleSubmit()} 
                                    >Kaydet</button>     

                                        </div>
                                </div>
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


        )
    }
}

export default connect(mapStateToProps)(ExpertiseEdit);