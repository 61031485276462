import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component';
import SelectList from '../utilities/SelectList';
import DatePicker from '../utilities/DatePicker';
import {alertMessage} from '../../assets.js';


class TaskAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timing_type:'0'
        }
        this.handleChange=this.handleChange.bind(this);
        this.Timer=this.Timer.bind(this);
        this._arraytoString=this._arraytoString.bind(this);
        this.Submit=this.Submit.bind(this);

        

    }
    handleChange(event,selectlist,name) {        
                if(selectlist) { 
                    this.setState({ [`${name}`]:event.id})
                } 
                else { 
        
                 if(event.target.type==='checkbox'){ 
                       event.target.checked?this.setState({[`${event.target.name}`]:'1'}):this.setState({[`${event.target.name}`]:'0'})
                                } else {
                    const value = event.target.value;
                    const key = event.target.name;
                    this.setState({ [`${key}`]:value})
                 } }
            }
        
            Timer(date){
                let newdate = new Date(date)
                newdate.setDate(newdate.getDate())
                let app_date = newdate.toISOString().split('T')[0]
                this.setState({target_date:app_date})
            }

            _arraytoString(value){
                const brands= [];
                value.map(row => brands.push(row.id));
                const id_com_groups = [...new Set(brands)];
                    return(id_com_groups)
                   // console.log(id_com_groups)
        }

        Submit(){
                let d = new Date(); 
                let date = d.toISOString();
                let c_date  = date.split('T')[0];
                let c_time = d.toLocaleTimeString('tr-TR');
            


                this.state.id_com_users.map((row,index)=>{

                    let id_com_task_type =this.state.id_com_task_type?this.state.id_com_task_type:'0';
                    let insert_body = 
                    'token='+this.props.loginToken+
                    '&c_date='+c_date+
                    '&c_time='+c_time+
                    '&r_id_com_user='+row+
                    '&id_com_task_type='+id_com_task_type+
                    '&id_com_user='+this.props.id_com_user+
                    '&type=user&id_com_task_status=1&status=0&hidden=0';
    
                    for (let [key] of Object.entries(this.state)) {
                        if(key!=='timing_type'&&key!=='id_com_users' //&&key!=='customer_data'&&key!=='id_com_cars'  
                         ){ 
                            insert_body +=`&${key}=`+ this.state[`${key}`]
                        }
                }
                // console.log(insert_body)
                let com_cars_url=this.props.api_url+'/insert/com_task';
                fetch(com_cars_url,{
                        method:'POST',
                        cahce: 'no-cache',
                        headers:{ 
                          'Content-Type':'application/x-www-form-urlencoded'
                        },
                      body:insert_body,
                    })
                    .catch(error=>console.log(error))
                    .then(x=>window.location.href='/task/list')


                })
             


        }

    render () { 
        return ( 
        <div className="main">
            <div className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel">
                                <div className="panel-heading">
                                    <h3 className="panel-title">Görev Ekle</h3>
                                </div>
                                <div className="panel-body"> 
                                <SelectList table='view_brand_sl' label={'Marka'} name={'id_com_brand'} onChange={(x)=>this.handleChange(x )} />
                                <SelectList table='com_department'   label={'Departman Seçiniz'} name={'id_com_department'} onChange={(x)=>this.handleChange(x)} />
                                <div className="form-check" >
                                        <input className="form-check-input" name='timing_type' type="checkbox" onChange={(x)=>this.handleChange(x)}/>
                                        <label className="form-check-label" style={{marginLeft:5,  }} > Yinelenen Görev </label>
                                </div>
                                {this.state.timing_type==='1'?
                                <SelectList table='com_task_type' label={'Yenileme Zamanı'} name={'id_com_task_type'} onChange={(x)=>this.handleChange(x )} />
                                :
                                <div className="form-check" >
                                <label className="form-check-label" style={{marginLeft:5,  }} > Hedef Tarihi </label>

                                <DatePicker    onChange={(x)=>this.Timer(x)}  />
                                </div>

                                }
                                <SelectList table='com_task_subject' label={'Görev Konusu'} name={'id_com_task_subject'} onChange={(x)=>this.handleChange(x )} />

                                <div className="form-group">
                                    <label>Görev Tanımı</label>
                                    <textarea type="text" cols className="form-control" name="comment" onChange={(x) => this.handleChange(x)} placeholder="Başlık..." required/>
                                </div>
                                {this.state.id_com_brand&&this.state.id_com_department?
                                <>
                                <SelectList table='view_user_sl' multiple={true} Label={'Atanacak Yetkililer'} id_com_brand={this.state.id_com_brand}   id_com_department={this.state.id_com_department} onChange={(x)=>{this.setState({id_com_users: this._arraytoString(x)});}}    />
                               
                                </>
                                    :<></>
                                }
                                
                                </div>
                                <div className="panel-footer">
                                <a className="btn btn-danger" 
                                  href={'/task/list'}
                                    >Geri</a>
                                <button className="btn btn-primary pull-right" 
                                disabled={this.state.id_com_sale_type===''||this.state.id_com_customer==='0'}
                                 onClick={()=>this.Submit()}
                                    >Oluştur</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

)
}
}

export default connect(mapStateToProps)(TaskAdd);