import React from 'react';
import {connect} from 'react-redux';
import mapStateToProps from '../../redux/mapStateToProps';
import DataTable from 'react-data-table-component';
const columns = [
    {
        name: 'Malzeme Adı',
        selector: 'id_com_product_name',
        left: true
    },
    {
        name: 'Set Adedi',
        selector: 'quantity',
        center: true
    }]
class Update extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quantity: 0,
            edit: false
        }
        this._EditItem = this._EditItem.bind(this);
    }
    componentDidMount() {
        this.setState({quantity: this.props.data.quantity})
    }
    _EditItem() {
        let id = this.props.data.id;
        let quantity = this.state.quantity
        let token = this.props.loginToken
        let update = 'id=' + id + '&quantity=' + quantity + '&token=' + token + '&type=user';
        fetch(this.props.api_url + '/update/com_product_set_lines', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: update,
        })
            .then(function (response) {
                window.location.reload()
            })
        this.setState({edit: true, text: ''})
    }
    render() {
        return (
            <div className="uk-card-default  uk-margin">
                <dl className="uk-description-list uk-description-list-divider">
                    <dt style={{fontWeight: 'bold'}}>{this.props.data.id_com_product_name}</dt>
                    <dd><input type='number' disabled={this.state.edit} className="uk-input "
                               value={this.state.quantity}
                               onChange={(x) => this.setState({quantity: parseFloat(x.target.value)})}/></dd>
                </dl>
                <div>
                    <button disabled={this.state.edit} className="btn btn-primary "
                            onClick={() => this._EditItem()}>Kaydet
                    </button>
                </div>
            </div>
        )
    }
}

class SetCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invItems: [],
            pageloader: false,
            searchProduct: '',
            productLoaded: false,
            detail_list: [],
            detail_loaded: false,

        }
        this._SearchProduct = this._SearchProduct.bind(this)
        this._excludeItem = this._excludeItem.bind(this)
        this._includeItem = this._includeItem.bind(this)
        this._Calculate = this._Calculate.bind(this)
        this._Submit = this._Submit.bind(this)
        this._Update = this._Update.bind(this)
    }
    componentDidMount() {
        this._Update()
    }
    _Update() {
        let detail_url = this.props.api_url + '/selectRows/view_product_set_lines';
        let body = 'id_com_company=' + this.props.id_com_company + '&id_com_set=' + this.props.match.params.SetId + '&token=' + this.props.loginToken + '&type=user';
        fetch(detail_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: body,
        })
            .then(res => res.json())
            .then(result => {
                this.setState({detail_list: result, detail_loaded: true})
            })
    }
    _Submit() {
        let d = new Date();
        let date = d.toISOString();
        let c_date = date.split('T')[0];
        let c_time = d.toLocaleTimeString('tr-TR');
        let c_id_com_user = this.props.id_com_user;
        let id_com_company = this.props.id_com_company;
        let url = this.props.api_url + '/insert/com_product_set_lines'
        this.state.invItems.map(row => {
            let body = 'id_com_product=' + row.id +
                '&quantity=' + row.InvQuantity +
                '&id_com_set=' + this.props.match.params.SetId +
                '&c_date=' + c_date +
                '&c_time=' + c_time +
                '&c_id_com_user=' + c_id_com_user +
                '&id_com_company=' + id_com_company +
                '&token=' + this.props.loginToken + '&type=user';
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: body,
            })
                .catch(error => console.log(error))
                .then(res => window.location.href = '/SetList')
        })
    }
    _Calculate() {
        let InvItems = this.state.invItems;
        let List_Amount = 0;
        let Amount = 0;
        let Tax_Amount = 0;
        let Disc_Amount = 0;
        let Total_Amount = 0;
        let Total_Cost = 0;
        InvItems.map((row) => {
            List_Amount += (parseFloat(row.list_price) * parseFloat(row.InvQuantity));
            Amount += parseFloat(row.Amount);
            Tax_Amount += parseFloat(row.Tax_Amount);
            Disc_Amount += parseFloat(row.Disc_Amount);
            Total_Cost += (parseFloat(row.Avg_Cost) * parseFloat(row.InvQuantity));
            Total_Amount += parseFloat(row.Total_Amount);
            return null
        })
        this.setState({
            List_Amount: Math.round(List_Amount * 100) / 100,
            Amount: Math.round(Amount * 100) / 100,
            Tax_Amount: Math.round(Tax_Amount * 100) / 100,
            Disc_Amount: Math.round(Disc_Amount * 100) / 100,
            Total_Cost: Math.round(Total_Cost * 100) / 100,
            Total_Amount: Math.round(Total_Amount * 100) / 100
        })
    }
    _excludeItem(index) {
        let invItems = this.state.invItems;
        invItems.splice(index, 1);
        this.setState({invItems: invItems});
        this._Calculate()
    }
    _includeItem(id) {
        let invItems = this.state.invItems;
        let product_url = this.props.api_url + '/selectRows/view_product';
        fetch(product_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'token=' + this.props.loginToken + '&type=user&id=' + id + '&id_com_company=' + this.props.id_com_company
        }).then(res => res.json())
            .then(result => {
                invItems.push(result[0]);
                invItems.forEach(row => {
                    row.InvQuantity = 1;
                });
                this.setState({invItems: invItems})
                this._Calculate()
            })
    }
    _SearchProduct(code) {
        let product_url = this.props.api_url + '/productSearch/' + this.props.id_com_company;
        fetch(product_url, {
            method: 'POST',
            cahce: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'token=' + this.props.loginToken + '&type=user&match=' + code
        }).then(res => res.json())
            .then(result => {

                let difference1 = result.filter(row => !this.state.invItems.find(row2 => row.id === row2.id))

                this.setState({ProductList: difference1, productLoaded: true})
            })
    }
    render() {
        return (
                    <React.Fragment>
                        <div className="main">
                            <div className="main-content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h3 className="page-title">Üretim</h3>
                                        </div>
                                        <div className="col-md-12">
                                        { this.state.detail_list.length>0 ?
                                            <React.Fragment>
                                            <div className="panel">
                                                <div className="panel-heading">
                                                    <div className="panel-title">Malzeme Listesi ({this.state.detail_list.length})</div>
                                                </div>
                                                <div className="panel-body">
                                                    <DataTable
                                                        columns={columns}
                                                        data={this.state.detail_list}
                                                        fixedHeader
                                                        noHeader={true}
                                                        overflowY={false}
                                                        overflowYOffset={'100px'}
                                                        highlightOnHover
                                                        responsive
                                                        striped
                                                        // dense
                                                        paginationComponentOptions={{ rowsPerPageText: 'Sayfa başına:', rangeSeparatorText: '-', noRowsPerPage: false }}
                                                        pagination={true}
                                                        paginationPerPage={20}
                                                        defaultSortField={'id'}
                                                        defaultSortAsc={false}
                                                        expandableRows={true}
                                                        expandableRowsComponent={
                                                            <Update api_url={this.props.api_url} loginToken={this.props.loginToken}/>
                                                        }
                                                    />
                                                </div>
                                              
                                            </div>
                                            <div className="form-group">
                                                    <a className="btn btn-danger" href="/SetList">İptal</a>
                                                </div>
                                               </React.Fragment>
                                        :
                                            <div className="panel">
                                                <div className="panel-heading">
                                                    <h3 className="panel-title">Set / Üretim Satırları</h3>
                                                </div>
                                                <div className="panel-body">
                                                    <div className="form-group">
                                                        <label>Malzeme Kartı</label>
                                                        <input value={this.state.searchProduct} className="form-control" type="text" placeholder="Malzeme Kodu, Malzeme Adı, OEM kodu" onChange={(x) => {this.setState({searchProduct: x.target.value});this._SearchProduct(x.target.value)}}/>
                                                    </div>
                                                    {this.state.productLoaded ?
                                                        <React.Fragment>
                                                            <table className="table table-bordered table-striped">
                                                                <thead>
                                                                <tr>
                                                                    <th>No</th>
                                                                    <th>Malzeme Kodu</th>
                                                                    <th>Oem Kodu</th>
                                                                    <th>Sınıf</th>
                                                                    <th>Grup</th>
                                                                    <th>Malzeme Adı</th>
                                                                    <th>Liste Fiyati</th>
                                                                    <th>Depo Adı</th>
                                                                    <th>Stok Adedi</th>
                                                                    <th>İşlem</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {this.state.searchProduct === '' ?
                                                                    <></>
                                                                :
                                                                    this.state.ProductList.map((row, index) =>
                                                                        <tr key={index}>
                                                                            <td>{row.id} </td>
                                                                            <td>{row.code}</td>
                                                                            <td>{row.oem_code}</td>
                                                                            <td>{row.id_com_product_type_name}</td>
                                                                            <td>{row.id_com_product_class_name}</td>
                                                                            <td>{row.text}</td>
                                                                            <td>{row.list_price}</td>
                                                                            <td>{row.id_com_depot_name}</td>
                                                                            <td>{row.stock_level}</td>
                                                                            <td>
                                                                                <button disabled={this.state.id_com_invoice_type === '1' ? row.list_price === null ? true : false : false} onClick={() => {
                                                                                    this.setState({
                                                                                        searchProduct: '',
                                                                                        ProductList: [],
                                                                                        productLoaded: false
                                                                                    });
                                                                                    this._includeItem(row.id)
                                                                                }} className="btn btn-primary btn-sm">{this.state.id_com_invoice_type === '1' ? parseFloat(row.stock_level) <= 0 ? 'Stok Bulunmamaktadır' : row.list_price === null || parseFloat(row.list_price) === 0 ? 'Liste Fiyatı Yok' : 'Seç' : 'Seç'}</button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </React.Fragment>
                                                    :
                                                        <></>
                                                    }
                                                    {this.state.invItems.length > 0 ?
                                                        <React.Fragment>
                                                            <table className="table table-bordered table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Satır No</th>
                                                                        <th>Tip</th>
                                                                        <th>Kodu</th>
                                                                        <th>Adı</th>
                                                                        <th>Adet</th>
                                                                        <th>Paket Tipi</th>
                                                                        <th>İşlem</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {this.state.invItems.map((row, index) =>
                                                                    <tr key={index}>
                                                                        <td>{index + 1} </td>
                                                                        <td>{row.id_com_product_class_name}</td>
                                                                        <td>{row.code}</td>
                                                                        <td>{row.text}</td>
                                                                        <td>
                                                                            <input type="number" value={this.state.invItems[index].InvQuantity} className="form-control" onChange={(x) => {
                                                                                       if (parseFloat(x.target.value) < 1) {
                                                                                           alert('Adet Negatif veya Sıfır Değerde Olamaz');
                                                                                           this.state.invItems[index].InvQuantity = 1;
                                                                                       } else {
                                                                                           this.state.invItems[index].InvQuantity = x.target.value === '' ? 1 : parseFloat(x.target.value);
                                                                                       };
                                                                                       this._Calculate();}} />
                                                                        </td>
                                                                        <td>{row.id_com_unit_name}</td>
                                                                        <td><button onClick={() => {this._excludeItem(index, row.id)}} className="btn btn-primary btn-sm">Çıkar</button></td>
                                                                    </tr>
                                                                )}
                                                                </tbody>
                                                            </table>
                                                            <div className="form-group">
                                                                <a className="btn btn-danger" href='/SetList'>İptal</a> <button disabled={this.state.invItems.length === 0 ? true : false} className="btn btn-primary" onClick={() => this._Submit()}>Kaydet</button>
                                                            </div>
                                                        </React.Fragment>
                                                    :
                                                        <></>
                                                    }
                                                </div>
                                            </div>
                                        }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
         
              
        )
    }
}
export default connect(mapStateToProps)(SetCard);