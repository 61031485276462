import React, {Component} from 'react';
import Option from "./Option";
class Options extends Component {
    render() {
        const {options,optionChange,optionRemoveRow} = this.props
        return (
            <div>
                {
                    options.map((option,key) => {
                        return(
                            <Option key={key} optKey={key} option={option.option} value={option.value} optionChange={optionChange} optionRemoveRow={optionRemoveRow} />
                        )
                    })
                }
            </div>
        );
    }
}
export default Options;